import React from 'react';
import styled from 'styled-components';

import {
    Layout,
    Typography,
    Image,
    Chip,
    Tooltip,
} from 'ui-components';

/** @typedef {import('../__types').NavBreadCrumbItem} NavBreadCrumbItem */

const NavIcon = styled(Image)`
    height: 25px;
    width: 25px;
`;

const NavTitle = styled(Typography)`
    max-width: 30vw;
`;

/** @type {React.FC<NavBreadCrumbItem>} */
const NavBreadCrumbItem = ({
    text,
    link,
    currentRoute,
    icon,
    chip,
    titleTooltip,
    iconTooltip,
    chipTooltip,
}) => (
    <Layout flex alignItems="center">

        {icon && (
            <Layout flex alignItems="center" spacing="mr-2">
                <Tooltip
                    {...(iconTooltip ? { content: iconTooltip } : { open: false })}
                >
                    {typeof icon === 'string' ? (
                        <NavIcon
                            size="contain"
                            repeat="no-repeat"
                            position="center"
                            src={icon}
                        />
                    ) : (
                        icon
                    )}
                </Tooltip>
            </Layout>
        )}

        <Tooltip
            {...(titleTooltip ? { content: titleTooltip } : { open: false })}
        >
            <NavTitle
                spacing="m-0"
                noWrap
                variant="subtitle2"
                link={link !== currentRoute && link}
            >
                {text}
            </NavTitle>
        </Tooltip>

        {chip && (
            <Tooltip
                {...(chipTooltip ? { content: chipTooltip } : { open: false })}
            >
                <Chip spacing="ml-2" label={chip} size="sm" />
            </Tooltip>
        )}
    </Layout>
);

export default NavBreadCrumbItem;
