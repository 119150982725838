/* eslint-disable complexity */
import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInput } from 'ui-components';

import { useSession } from '../../shared/context-providers';
import { useRequest } from '../../lib/ajax';
import gotoUrl from '../../lib/goto-url';

/** @typedef { import('../../app/__types').PageProps } PageProps */

/**
 * @typedef { object } Props
 * @prop { string } token
 */

/**
 * @typedef { object } ReturnProps
 * @prop { string } firstName
 * @prop { string } lastName
 * @prop { string } password
 * @prop { string } email
 * @prop { string } username
 * @prop { string } databaseName
 * @prop { boolean } inviteeHasAccount
 * @prop { boolean } firstNameIsValid
 * @prop { boolean } lastNameIsValid
 * @prop { boolean } passwordIsValid
 * @prop { boolean } usernameIsValid
 * @prop { boolean } tokenIsValid
 * @prop { boolean } inviteIsLoading
 * @prop { boolean } acceptIsLoading
 * @prop { boolean } acceptDisabled
 * @prop { { message: string } } acceptError
 * @prop { string } acceptLabel
 * @prop { string } acceptCallToAction
 * @prop { (value: string) => void } onFirstNameChanged
 * @prop { (value: string) => void } onLastNameChanged
 * @prop { (value: string) => void } onPasswordChanged
 * @prop { (value: string) => void } onUsernameChanged
 * @prop { (valid: boolean) => void } onPasswordIsValidChanged
 * @prop { (valid: boolean) => void } onUsernameIsValidChanged
 * @prop { () => void } onGoogleClicked
 * @prop { () => void } onAcceptClicked
 * @prop { () => void } onEnterPressed
 */

/** @type { (props: Props) => ReturnProps } */
function useAcceptInvitePage({ token }) {
    const [, actions] = useSession();
    const { refreshSession } = actions;

    const navigate = useNavigate();

    const firstNameInput = useInput('First name');
    const lastNameInput = useInput('Last name');
    const passwordInput = useInput('Password');
    const usernameInput = useInput('Username');

    const [passwordIsValid, setPasswordIsValid] = useState(false);
    const [usernameIsValid, setUsernameIsValid] = useState(false);
    const [tokenIsValid, setTokenIsValid] = useState(false);

    const firstName = firstNameInput.value;
    const lastName = lastNameInput.value;
    const password = passwordInput.value;
    const username = usernameInput.value;

    const firstNameIsValid = !!firstName;
    const lastNameIsValid = !!lastName;

    const { go: invite, ...inviteReq } = useRequest(`/databases/invite/${token}`);

    const {
        email,
        dbName: databaseName,
        hasAccount: inviteeHasAccount,
    } = inviteReq.data || {};

    const { go: login, ...loginReq } = useRequest('/users/login', 'POST', {
        email,
        password,
    });

    const { go: signup, ...signupReq } = useRequest('/users', 'POST', {
        fname: firstName,
        lname: lastName,
        email,
        username,
        password,
    });

    const [acceptIsLoading, setAcceptIsLoading] = useState(false);

    const inviteIsLoading = inviteReq.loading;

    const loginIsDisabled = acceptIsLoading
        || !password
        || !email;

    const signupIsDisabled = acceptIsLoading
        || !firstNameIsValid
        || !lastNameIsValid
        || !passwordIsValid
        || !usernameIsValid
        || !email;

    const acceptDisabled = inviteeHasAccount
        ? loginIsDisabled
        : signupIsDisabled;

    const acceptError = signupReq.error || loginReq.error;

    const acceptLabel = inviteeHasAccount
        ? 'Use your existing Panoply account'
        : 'Create an account to join';

    const acceptCallToAction = inviteeHasAccount
        ? 'Join and Log In'
        : 'Sign Up and Join';

    const onFirstNameChanged = firstNameInput.bind.onChange;
    const onLastNameChanged = lastNameInput.bind.onChange;
    const onPasswordChanged = passwordInput.bind.onChange;
    const onUsernameChanged = usernameInput.bind.onChange;

    const onPasswordIsValidChanged = useCallback((valid) => {
        setPasswordIsValid(valid);
    }, []);

    const onUsernameIsValidChanged = useCallback((valid) => {
        setUsernameIsValid(valid);
    }, []);

    const onGoogleClicked = useCallback(() => {
        const domain = email ? email.split('@')[1] : '';
        gotoUrl('/users/auth/google', { domain });
    }, [email]);

    const onAcceptClicked = useCallback(() => {
        if (acceptIsLoading || !email || !password) {
            return;
        }

        setAcceptIsLoading(true);

        if (inviteeHasAccount) {
            login().then(async (res) => {
                if (!res.ok) {
                    setAcceptIsLoading(false);
                    return;
                }
                await refreshSession();
                navigate('/', { replace: true });
            });
            return;
        }

        if (!firstNameIsValid || !passwordIsValid) {
            setAcceptIsLoading(false);
            return;
        }

        signup().then((res) => {
            if (!res.ok) {
                setAcceptIsLoading(false);
                return res;
            }
            return login();
        }).then(async (res) => {
            if (!res.ok) {
                setAcceptIsLoading(false);
                return;
            }
            await refreshSession();
            navigate('/', { replace: true });
        });
    }, [
        acceptIsLoading,
        email,
        password,
        inviteeHasAccount,
        firstNameIsValid,
        passwordIsValid,
        navigate,
        signup,
        login,
        refreshSession,
    ]);

    const onEnterPressed = useCallback((e) => {
        if (acceptDisabled || e.key !== 'Enter') {
            return;
        }
        onAcceptClicked();
    }, [acceptDisabled, onAcceptClicked]);

    useEffect(() => {
        if (!token || tokenIsValid) {
            return;
        }
        invite().then((res) => {
            if (!res.ok) {
                return;
            }
            setTokenIsValid(true);
        });
    }, [token, tokenIsValid, invite]);

    return {
        firstName,
        lastName,
        password,
        username,
        email,
        databaseName,
        inviteeHasAccount,
        firstNameIsValid,
        lastNameIsValid,
        passwordIsValid,
        usernameIsValid,
        tokenIsValid,
        inviteIsLoading,
        acceptIsLoading,
        acceptDisabled,
        acceptError,
        acceptLabel,
        acceptCallToAction,
        onFirstNameChanged,
        onLastNameChanged,
        onPasswordChanged,
        onUsernameChanged,
        onPasswordIsValidChanged,
        onUsernameIsValidChanged,
        onGoogleClicked,
        onAcceptClicked,
        onEnterPressed,
    };
}

export default useAcceptInvitePage;
