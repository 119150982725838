import React from 'react';

import {
    Dialog,
    Typography,
    Button,
} from 'ui-components';

const UnsuspendDialog = ({
    unsuspendError,
    unsuspendLoading,
    warehouseToUnsuspend,
    onUnsuspendClose,
    onUnsuspendConfirm,
}) => {
    const unsuspendButtonProps = {
        onClick: onUnsuspendConfirm,
        disabled: !!unsuspendError,
        loading: unsuspendLoading,
    };

    const unsuspendDialogProps = {
        isOpen: !!warehouseToUnsuspend.id,
        onClose: onUnsuspendClose,
        actions: [
            <Button key="cancel" type="outline" onClick={onUnsuspendClose}>Cancel</Button>,
            <Button key="suspend" color="primary" {...unsuspendButtonProps}>Unsuspend</Button>,
        ],
    };

    return (
        <Dialog title="Unsuspend Warehouse" {...unsuspendDialogProps}>
            <Typography variant="subtitle1">
                This will unsuspend the selected warehouse.
            </Typography>
        </Dialog>
    );
};

export default UnsuspendDialog;
