import React from 'react';

import {
    Button,
    Dialog,
    Typography,
} from 'ui-components';

/** @typedef { import('./__types').SaveDialogProps } SaveDialogProps */

/** @type { React.FC<SaveDialogProps> } */
const SaveColumnDialog = ({
    columnToSave,
    savingColumn,
    onSaveCancelClicked,
    onSaveConfirmClicked,
}) => (
    <Dialog
        title="Save changes"
        isOpen={!!columnToSave.current_name}
        onClose={onSaveCancelClicked}
        actions={[
            <Button key="cancel" type="outline" onClick={onSaveCancelClicked}>
                Cancel
            </Button>,
            <Button
                key="open"
                color="primary"
                spacing="mr-0"
                onClick={onSaveConfirmClicked}
                disabled={!columnToSave.current_name}
                loading={savingColumn}
            >
                Confirm
            </Button>,
        ]}
    >
        <Typography>
            Are you sure you want to save changes done to
            column &quot;{columnToSave.current_name}&quot;?
        </Typography>
    </Dialog>
);

export default SaveColumnDialog;
