import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import copyToClipboard from 'copy-to-clipboard';

import {
    Typography,
    Layout,
    Card,
    Tooltip,
    Icon,
    Divider,
} from 'ui-components';

import { useSession, useToast } from './context-providers';
import { get } from '../lib/ajax';
import openSupportChat from './use-support-chat';

/** @typedef { import('../app/__types').Session } Session */

/**
 * @typedef { object } Detail
 *
 * @prop { string } name
 * @prop { string | number } value
 * @prop { boolean } [ellipsis]
 * @prop { boolean } [copyable]
 * @prop { () => void } [onClick]
 */

/** @typedef {React.HTMLProps<HTMLDialogElement>} ReactDivProps */
/** @typedef {ReactDivProps & {copyable?: boolean}} StyledDivProps */

/** @param {StyledDivProps} props  */
const getRelevantCursor = props => (props.copyable ? 'pointer' : 'default');

/** @type {React.FC<StyledDivProps>} */
const StyledDiv = styled.div`
    cursor: ${getRelevantCursor};
`;

/** @type { React.FC<Detail> } */
const ConnectionDetail = ({ name, value, ellipsis, copyable, onClick }) => {
    const [hovered, setHovered] = useState(false);
    const [copied, setCopied] = useState(false);

    const onMouseEnter = useCallback(() => setHovered(true), []);

    const onMouseLeave = useCallback(() => {
        setCopied(false);
        setHovered(false);
    }, []);

    const onCopyClicked = useCallback(() => {
        if (copyable) {
            copyToClipboard(value.toString());
            setCopied(true);
        }
    }, [copyable]);

    if (!value) {
        return null;
    }

    return (
        <StyledDiv
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onCopyClicked}
            copyable={copyable}
        >
            <Divider spacing="m-0" />
            <Layout flex spacing="px-5 my-3">
                <CustomWidthTypography
                    variant="subtitle1"
                    width="35"
                    color="secondaryText"
                    spacing="mb-0"
                >
                    {name}
                </CustomWidthTypography>
                <Layout width="65" flex>
                    {ellipsis ? (
                        <Tooltip content={value}>
                            <EllipsisText variant="subtitle1">{value}</EllipsisText>
                        </Tooltip>
                    ) : (
                        <Typography
                            spacing="mb-0"
                            variant="subtitle1"
                            link={!!onClick}
                            color={onClick ? 'primary' : 'text'}
                            onClick={onClick || (() => {})}
                        >
                            {value}
                        </Typography>
                    )}
                    {copyable && hovered && (
                        <Layout width="65" spacing="pb-1" flex alignItems="center">
                            <Icon
                                color={copied ? 'secondary' : 'secondaryText'}
                                icon={copied ? 'check' : 'copy'}
                                spacing="ml-4 mr-1"
                                size="sm"
                            />
                            <Typography spacing="mb-0" color="secondaryText">
                                {copied ? 'Copied!' : 'Copy'}
                            </Typography>
                        </Layout>
                    )}
                </Layout>
            </Layout>
        </StyledDiv>
    );
};

/**
 * @typedef { object } DetailProps
 *
 * @prop { boolean } ellipsis
 */

/**
 * Returns the connection details for the current database.
 *
 * @type { (props: DetailProps) => Detail[] }
 */
function useDetails({ ellipsis }) {
    const [session] = useSession();
    const { openToast } = useToast();
    const { user, database } = session;

    if (database.type === 'bigquery') {
        return [
            {
                name: 'Driver',
                value: 'Google BigQuery',
            },
            {
                name: 'Project Name',
                value: database.projectName || '',
                copyable: true,
            },
            {
                name: 'Project ID',
                value: database.projectId || '',
                ellipsis,
                copyable: true,
            },
            {
                name: 'User',
                value: user.cloudIdentityEmail || '',
                ellipsis,
                copyable: true,
            },
            {
                name: 'Password',
                ellipsis,
                value: 'Same as your Panoply password, unless it was changed in BigQuery',
            },
            {
                name: 'JSON File',
                value: 'Send your service account JSON file to your email',
                async onClick() {
                    try {
                        await get('/users/credentials');
                        openToast({ type: 'success', message: 'File sent to your email' });
                    } catch (err) {
                        openToast({ type: 'error', message: 'Something went wrong' });
                    }
                },
            },
        ];
    }

    return [
        {
            name: 'Driver',
            value: 'Amazon Redshift',
        },
        {
            name: 'Host',
            value: database.host || '',
            copyable: true,
        },
        {
            name: 'Database',
            value: database.name || '',
            ellipsis,
            copyable: true,
        },
        {
            name: 'Port',
            value: database.port || '',
            copyable: true,
        },
        {
            name: 'User',
            value: user.username || '',
            ellipsis,
            copyable: true,
        },
        {
            name: 'Password',
            value: '(same as your Panoply.io password)',
        },
    ];
}

/**
 * @typedef { object } Props
 *
 * @prop { number | string } [width]
 * @prop { boolean } [ellipsis]
 */

/** @type { React.FC<Props> } */
const DatabaseConnection = ({ width, ellipsis }) => {
    const details = useDetails({ ellipsis: ellipsis || false });

    return (
        <Card
            width={width}
            contentSpacing="p-0"
            title={(
                <Layout flex alignItems="center" spacing="mb-3">
                    <Typography variant="subtitle1" spacing="mb-0">
                        My Connection Details
                    </Typography>
                    <Tooltip content={(
                        <>
                            <Typography color="white" inline>
                                {/* eslint-disable max-len */}
                                This is the information you need to connect to most workbenches, BI tools and code environments.
                                If you need assistance please
                                {/* eslint-enable max-len */}
                            </Typography>
                            &nbsp;
                            <Typography
                                color="secondary"
                                inline
                                link
                                onClick={() => openSupportChat()}
                            >
                                contact support
                            </Typography>
                        </>
                    )}
                    >
                        <Icon size="md" spacing="ml-2" color="secondaryText" icon="info-circle" />
                    </Tooltip>
                </Layout>
            )}
        >
            {details.map((detailProps) => (
                <ConnectionDetail key={detailProps.name} {...detailProps} />
            ))}
        </Card>
    );
};

const CustomWidthTypography = styled(Typography)`
    max-width: 160px;
`;

const EllipsisText = styled(Typography)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 210px;
`;

export default DatabaseConnection;
