import { post, get } from '../../lib/ajax';
import { addBreadcrumb } from '../../lib/error-reporter';
import { fetchTables, deleteTable, fetchTableDependencies } from './tables';
import { fetchViews, deleteView, fetchViewDependencies } from './views';
import {
    fetchFolders,
    deleteFolder,
    populateFoldersMetadata,
    getFolderChildrenFromList,
} from './folders';

/** @typedef { import('./__types').DatabaseObject } DatabaseObject */
/** @typedef { import('./__types').Table } Table */
/** @typedef { import('./__types').Folder } Folder */
/** @typedef { import('./__types').View } View */
/** @typedef { import('./../users/__types').User } User */
/** @typedef { import('./__types').Query } Query */

/** @type { () => Promise<DatabaseObject[]> } */
export const fetchDatabaseObjects = async () => {
    /** @type { Query } */
    const query = {
        $with: 'metadata',
        $withOwner: true,
    };

    const [tables, views, folders] = await Promise.all([
        fetchTables({ query }),
        fetchViews({ query }),
        fetchFolders(),
    ]);

    // Populate folders with views & tables
    const populatedFolders = folders.map((f) => {
        const matchingTables = tables.filter(t => t.parent === f.id);
        const matchingViews = views.filter(v => v.parent === f.id);
        return {
            ...f, items: [...matchingTables, ...matchingViews],
        };
    });

    const nestedFolders = populateFoldersMetadata(populatedFolders.map((f) => {
        const matchingFolders = getFolderChildrenFromList(f, populatedFolders);
        return {
            ...f,
            items: [...f.items, ...matchingFolders],
        };
    }));

    const folderIds = folders.map(folder => folder.id);

    // Remove tables/views/folders that were added to folders from the top lvl
    const filteredTables = tables.filter(t => !folderIds.includes(`${t.parent}`) || !t.parent);
    const filteredViews = views.filter(v => !v.parent);
    const filteredFolders = nestedFolders.filter(v => !v.parent);

    return [...filteredFolders, ...filteredTables, ...filteredViews];
};

/** @type { (object: DatabaseObject) => Promise<View[]> } */
export const fetchObjectDependencies = async (object) => {

    if (object.type === 'view') {
        return fetchViewDependencies(object);
    }
    if (object.type === 'table') {
        return fetchTableDependencies(object);
    }

    throw new Error(`Fetching dependencies of type "${object.type}" is not supported`);

};

/** @type { (object: DatabaseObject) => Promise<void> } */
export const deleteObject = async (object) => {
    if (object.type === 'view') {
        return deleteView(object);
    }
    if (object.type === 'table') {
        return deleteTable(object);
    }
    if (object.type === 'folder') {
        return deleteFolder(object);
    }

    throw new Error('deleteObject called with invalid args');
};

/** @type { (object: Table | View) => Promise<User[]> } */
export const fetchObjectUsers = async (object) => {
    try {
        const { data } = await get(`/permissions/${object.schema}/${object.name}`);
        const { users } = data;
        return users;
    } catch (error) {
        addBreadcrumb('Failed to fetch object users', { object, error });
        throw error;
    }
};

/**
 * @type {(
 * object: Table | View,
 * users: User[],
 * action: 'GRANT' | 'REVOKE'
 * ) => Promise<number> }
 */
export const updateObjectUsers = async (object, users, action) => {
    const req = await post('/permissions', {
        body: {
            users: users.map(u => ({
                id: u.id,
                email: u.email,
                username: u.username,
            })),
            object: object.name,
            schema: object.schema,
            command: action,
            privilege: 'SELECT',
        },
    });
    return req.status;
};

/**
 * @type {(
 * objects: DatabaseObject[],
 * objectToAdd: DatabaseObject,
 * parentId: DatabaseObject['id'] | null
 * ) => DatabaseObject[]}
 */
export const addToTree = (objects, objectToAdd, parentId) => {
    if (!parentId) {
        return [objectToAdd, ...objects];
    }
    return objects.map((object) => {
        if (object.id === parentId && object.items) {
            return {
                ...object,
                items: [...object.items, objectToAdd],
            };
        }
        if (object.items) {
            return { ...object, items: addToTree(object.items, objectToAdd, parentId) };
        }
        return object;
    });
};

/** @type { (objects: DatabaseObject[], id: DatabaseObject['id']) => DatabaseObject[] } */
export const removeFromTree = (objects, objectIdToRemove) => objects
    .filter(object => object.id !== objectIdToRemove)
    .map(object => (object.items
        ? { ...object, items: removeFromTree(object.items, objectIdToRemove) }
        : object));

/** @type { (objects: DatabaseObject[], objectToUpdate: DatabaseObject) => DatabaseObject[] } */
export const updateInTree = (objects, objectToUpdate) => objects.map((object) => {
    if (object.id === objectToUpdate.id) {
        return objectToUpdate;
    }
    if (object.items) {
        return { ...object, items: updateInTree(object.items, objectToUpdate) };
    }
    return object;
});

/**
 * @type {(
 * objects: DatabaseObject[],
 * objectToMove: DatabaseObject,
 * parentId: DatabaseObject['id'] | undefined
 * ) => DatabaseObject[]}
 */
export const moveInTree = (objects, objectToMove, parentId) => {
    const listWithItemRemoved = removeFromTree(objects, objectToMove.id);
    const listWithItemMoved = addToTree(listWithItemRemoved, objectToMove, parentId || null);
    return listWithItemMoved;
};
