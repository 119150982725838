import React from 'react';

import {
    Dialog,
    Typography,
    Button,
} from 'ui-components';

const SuspendDialog = ({
    suspendError,
    suspendLoading,
    warehouseToSuspend,
    onSuspendClose,
    onSuspendConfirm,

}) => {
    const suspendButtonProps = {
        onClick: onSuspendConfirm,
        disabled: !!suspendError,
        loading: suspendLoading,
    };

    const suspendDialogProps = {
        isOpen: !!warehouseToSuspend.id,
        onClose: onSuspendClose,
        actions: [
            <Button key="cancel" type="outline" onClick={onSuspendClose}>Cancel</Button>,
            <Button key="suspend" color="primary" {...suspendButtonProps}>Suspend</Button>,
        ],
    };

    return (
        <Dialog title="Suspend Warehouse" {...suspendDialogProps}>
            <Typography variant="subtitle1">
                This will suspend the selected warehouse.
            </Typography>
        </Dialog>
    );
};

export default SuspendDialog;
