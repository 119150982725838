import { useCallback } from 'react';

import { useRequest } from '../../lib/ajax';

/**
 * @typedef { object } ReturnProps
 * @prop { boolean } loading
 * @prop { boolean } success
 * @prop { { message: string } } error
 * @prop { () => void } onRotateServiceAccountClicked
 */

/**
 * @returns { ReturnProps }
 */
function useRotateServicePage() {
    const {
        go: rotate,
        loading,
        error,
        data,
    } = useRequest('/users/credentials/rotate', 'PUT');

    const success = !error && !!data;

    const onRotateServiceAccountClicked = useCallback(() => {
        rotate();
    }, [rotate]);

    return {
        loading,
        success,
        error,
        onRotateServiceAccountClicked,
    };
}

export default useRotateServicePage;
