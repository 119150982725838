import React from 'react';

import {
    ResizableContainer,
    ResizableSplitter,
    ResizableElement,
    breakpoints,
} from 'ui-components';

import WorkbenchTree from './workbench-tree';
import WorkbenchMain from './workbench-main';
import useWorkbenchMode from './use-workbench-mode';
import useWorkbenchData from './use-workbench-data';
import useWorkbenchMenu from './use-workbench-menu';
import useWorkbenchQueryBuilder from './use-workbench-query-builder';

/** @type { React.FC } */
const Workbench = () => {
    const {
        addColumn,
        removeColumn,
        moveColumn,
        toggleColumn,
        clearColumns,
        toggleHidden,
        addJoinTable,
        changeJoinTable,
        removeJoinTable,
        changeAlias,
        changeFunction,
        changeFilter,
        changeOrder,
        changeLimit,
        undoChange,
        redoChange,
        hasColumns,
        allColumns,
        allTables,
        pendingColumn,
        hideHidden,
        someHidden,
        builtQuery,
        queryFilter,
        queryOrder,
        queryLimit,
        hasUndoChange,
        hasRedoChange,
        clearConfig,
    } = useWorkbenchQueryBuilder();

    const {
        mode,
        onModeChanged,
    } = useWorkbenchMode();

    const {
        dataIsReady,
        dataIsLoaded,
        views,
        tables,
        reports,
        schemas,
        loadingViews,
        loadingTables,
        loadingReports,
        loadingSchemas,
        fetchTables,
        fetchViews,
        fetchReports,
        fetchSchemas,
    } = useWorkbenchData();

    const {
        leftMenuIsOpen,
        dropDownIsOpen,
        menuIsExpanded,
        onLeftMenuToggled,
        onLeftMenuResized,
        onDropDownToggled,
        onMenuIsExpanding,
    } = useWorkbenchMenu();

    const treeProps = {
        dataIsReady,
        views,
        tables,
        reports,
        schemas,
        fetchTables,
        fetchViews,
        fetchReports,
        ...(mode === 'query-builder' ? {
            addColumn,
            hasColumns,
        } : {}),
    };

    const mainProps = {
        mode,
        onModeChanged,

        dataIsLoaded,
        views,
        tables,
        reports,
        schemas,
        loadingViews,
        loadingTables,
        loadingReports,
        loadingSchemas,
        fetchTables,
        fetchViews,
        fetchSchemas,
        fetchReports,
        clearConfig,

        leftMenuIsOpen,
        dropDownIsOpen,
        menuIsExpanded,
        onLeftMenuToggled,
        onDropDownToggled,

        allColumns,
        allTables,
        pendingColumn,
        hideHidden,
        someHidden,
        builtQuery: mode === 'query-builder'
            ? builtQuery
            : '',
        queryFilter,
        queryOrder,
        queryLimit,
        hasUndoChange,
        hasRedoChange,
        addColumn,
        removeColumn,
        moveColumn,
        toggleColumn,
        clearColumns,
        toggleHidden,
        addJoinTable,
        changeJoinTable,
        removeJoinTable,
        changeAlias,
        changeFunction,
        changeFilter,
        changeOrder,
        changeLimit,
        undoChange,
        redoChange,
    };

    const open = leftMenuIsOpen && dataIsLoaded;

    const leftElementProps = {
        ...getLeftElementProps(open),
        onStopResize: onLeftMenuResized,
    };

    const rightElementProps = {
        ...getRightElementProps(),
        onResize: onMenuIsExpanding,
    };

    return (
        <ResizableContainer orientation="vertical">
            <ResizableElement {...leftElementProps}>
                <WorkbenchTree {...treeProps} />
            </ResizableElement>

            <ResizableSplitter />

            <ResizableElement {...rightElementProps}>
                <WorkbenchMain {...mainProps} />
            </ResizableElement>
        </ResizableContainer>
    );
};

/**
 * @typedef { object } LeftElementProps
 * @prop { number } flex
 * @prop { number } size
 * @prop { number } maxSize
 * @prop { { boxShadow: string } } style
 */

/** @type { (open: boolean) => LeftElementProps } */
const getLeftElementProps = (open) => ({
    flex: 0,
    size: open ? Math.floor(parseInt(breakpoints.md) / 3) : 0,
    maxSize: parseInt(breakpoints.md),
    style: {
        boxShadow: open
            ? '-2px 0 4px rgba(0, 0, 0, 0.15)'
            : 'none',
    },
});

/**
 * @typedef { object } RightElementProps
 * @prop { number } minSize
 * @prop { { display: 'flex', flexDirection: 'column' } } style
 */

/** @type { () => RightElementProps } */
const getRightElementProps = () => ({
    minSize: Math.floor(parseInt(breakpoints.sm) / 2),
    style: {
        display: 'flex',
        flexDirection: 'column',
    },
});

export default Workbench;
