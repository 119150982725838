import React from 'react';

import {
    Loader,
    Layout,
    Typography,
} from 'ui-components';

import RecipesDialogFinished from './recipes-dialog-finished';

/** @typedef { import('models/sources').SourceDefaults } SourceDefaults */
/** @typedef { import('models/sources').Source } Source */
/** @typedef { import('models/recipes').Recipe } Recipe */
/** @typedef { import('models/recipes/types').Output } Output */
/** @typedef { import('./use-recipes-dialog').OutputReq } OutputReq */
/** @typedef { import('./use-recipes-dialog').default } useRecipesDialog */

/** @typedef { ReturnType<useRecipesDialog> } useRecipes */

/**
 * @typedef { object } Props
 * @prop { useRecipes['running'] } running
 * @prop { useRecipes['successfulQueries'] } successfulQueries
 * @prop { useRecipes['failedQueries'] } failedQueries
 * @prop { useRecipes['reset'] } reset
 * @prop { useRecipes['runningQueryName'] } runningQueryName
 * @prop { useRecipes['finished'] } finished
 * @prop { useRecipes['destinationPrefix'] } destinationPrefix
 * @prop { useRecipes['destinationSchema'] } destinationSchema
 * @prop { Recipe } selectedRecipe
 */

/** @type { React.FC<Props> } */
export const RecipesResultsStep = ({
    running,
    runningQueryName,
    finished,
    selectedRecipe,
    successfulQueries,
    failedQueries,
    destinationPrefix,
    destinationSchema,
    reset,
}) => {

    return (
        <Layout flex>
            {finished && (
                <RecipesDialogFinished
                    selectedParent={selectedRecipe}
                    successfulQueries={successfulQueries}
                    failedQueries={failedQueries}
                    destinationPrefix={destinationPrefix}
                    destinationSchema={destinationSchema}
                    reset={reset}
                />
            )}

            {running && (
                <Layout
                    flex
                    alignItems="center"
                    justifyContent="center"
                    width="100"
                    spacing="pt-7 pb-6"
                >
                    <Loader big active icon="flask-round-potion">
                        <Typography
                            variant="body1"
                            spacing="mt-5 mb-2"
                            align="center"
                            color="secondaryText"
                        >
                            Running Recipes
                        </Typography>
                        <Typography align="center" color="secondaryText">
                            {runningQueryName}
                        </Typography>
                    </Loader>
                </Layout>
            )}
        </Layout>
    );
};

export default RecipesResultsStep;
