import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    ActionCallout,
} from 'ui-components';
import cdnIcon from '../../../lib/cdn-icon';


/** @typedef { import('models/sources/__types').SourceType } SourceType */
/** @typedef { import('models/sources/__types').Source } Source */


/**
 * @typedef { object } Props
 * @prop { Source } source
 * @prop { boolean } canUseRecipes
 */

/** @type { React.FC<Props> } */
const RecipesBanner = React.memo(({
    canUseRecipes,
    source,
}) => {

    const lastCollectWasSuccess = source.job?.status === 'success';
    const navigate = useNavigate();

    const goToRecipes = () => {
        navigate(`/sources/${source.id}/recipes`);
    };

    const canShowBanner = lastCollectWasSuccess
        && canUseRecipes;

    return (
        <ActionCallout
            active={canShowBanner}
            label="Hey! New Data Recipes are available."
            linkText="Show me"
            iconUrl={cdnIcon('icon-recipe.svg')}
            bannerId="panoply-recipes-closed"
            onClick={goToRecipes}
            spacing="my-3"
        />
    );
});


export default RecipesBanner;
