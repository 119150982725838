import React from 'react';

import DeleteSourceDialog from '../../../shared/source/delete-source-dialog';
import RenameSourceDialog from '../../../shared/source/rename-source-dialog';
import ScheduleSourceDialog from '../../../shared/source/schedule-source-dialog';
import { JobCancelDialog } from '../../../shared/job/job-cancel-dialog';
import SyncSourceDialog from './sync-source-dialog';

import { useSourcePageContext } from '../use-source-page/source-page-context';

const SourceDialogsAndBanners = () => {

    const {
        source,
        sourceType,
        schedule,
        collectable,
        collectableAfterSave,
        scheduleModalOpen,
        saveSchedule,
        closeScheduleModal,
        deleteModalOpen,
        closeDeleteModal,
        deleteSource,
        deletingError,
        deleting,
        title,
        updateTitle,
        renameModalOpen,
        closeRenameModal,
        jobToCancel,
        onCancelJobConfirm,
        onCloseCancelDialog,
        cancelJobError,
        cancelJobLoading,
        syncModalOpen,
        closeSyncModal,
    } = useSourcePageContext();

    return (
        <>
            {!!(source && sourceType && schedule) && (
                <ScheduleSourceDialog
                    source={source}
                    sourceType={sourceType}
                    schedule={schedule}
                    isOpen={scheduleModalOpen && sourceType.schedulable}
                    saveSchedule={saveSchedule}
                    onClose={closeScheduleModal}
                    collectable={collectable}
                    collectableAfterSave={collectableAfterSave}
                />
            )}

            <DeleteSourceDialog
                title={title}
                isOpen={deleteModalOpen}
                onClose={closeDeleteModal}
                deleteSource={deleteSource}
                error={deletingError}
                loading={deleting}
            />

            <RenameSourceDialog
                currentTitle={title}
                setTitle={updateTitle}
                isOpen={renameModalOpen}
                onClose={closeRenameModal}
            />

            <JobCancelDialog
                jobToCancel={jobToCancel}
                onCancelJobConfirm={onCancelJobConfirm}
                onCloseCancelDialog={onCloseCancelDialog}
                loading={cancelJobLoading}
                error={cancelJobError}
            />

            {!!source && !!sourceType && (
                <SyncSourceDialog
                    key={`sync-source-dialog-${syncModalOpen}`}
                    open={syncModalOpen}
                    source={source}
                    sourceType={sourceType}
                    onClose={closeSyncModal}
                />
            )}
        </>
    );
};


export default SourceDialogsAndBanners;
