import React, { useCallback, useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { Icon, Typography, Dialog, Button, Input, Loader, Callout } from 'ui-components';
import { put, post } from '../../lib/ajax';
import { useSession } from '../../shared/context-providers';
import {
    Barcode,
    ErrorIconCircle,
    SuccessIconCircle,
    InputWrapper,
    HeaderWrapper,
} from './shared';


const AttachDialog = ({ onClose, open = false, refetch }) => {
    const [, actions] = useSession();
    const { refreshSession } = actions;
    const [loading, setLoading] = useState(false);
    const [finished, setFinished] = useState(false);
    const [error, setError] = useState(null);
    const [qrCode, setQrCode] = useState(null);
    const [currentStep, setCurrentStep] = useState('Scan');
    const [verificationCode, setVerificationCode] = useState('');
    const [actualSuccess, setActualSuccess] = useState(false);

    const createFactor = async () => {
        try {
            const result = await post('/mfa/totp', {});
            return result.data;
        } catch (err) {
            setError(err.data.message);
        }
        return null;
    };

    const verifyFactor = async (code) => {
        try {
            const result = await put('/mfa/totp/verify', {
                body: {
                    code,
                },
            });
            return result.data;
        } catch (err) {
            throw err.data;
        }
    };

    useEffect(() => {
        const getQRCode = async () => {
            try {
                if (qrCode) {
                    return;
                }
                setLoading(true);
                const { url } = await createFactor();
                setQrCode(url);
                setLoading(false);
            } catch (err) {
                setError(err);
                setActualSuccess(false);
            }
        };
        getQRCode();
    }, []);

    const reduceStep = useCallback(() => {
        if (currentStep === 'Scan') {
            onClose();
        } else {
            setCurrentStep('Scan');
        }
    }, [currentStep]);

    const increaseStep = useCallback(async () => {
        if (currentStep === 'Verify') {
            setLoading(true);
            try {
                await verifyFactor(verificationCode);
                setActualSuccess(true);
                setFinished(true);
                await refreshSession();
                refetch();
            } catch (err) {
                setError(err);
                setActualSuccess(false);
            } finally {
                setLoading(false);
            }

        } else {
            setCurrentStep('Verify');
        }
    }, [currentStep, verificationCode]); // otherwise code won't be sent

    return (
        <Dialog
            key={`attach-dialog-${open}`}
            isOpen={open}
            onClose={onClose}
            actions={
                actualSuccess ? (
                    <Button
                        onClick={onClose}
                        type="plain"
                        disabled={loading}
                    >
                        Close
                    </Button>
                ) : (
                    <>
                        <Button
                            onClick={reduceStep}
                            type="plain"
                            disabled={loading}
                        >
                            Back
                        </Button>
                        <Button
                            onClick={increaseStep}
                            color="primary"
                            spacing="mr-0"
                            loading={loading}
                            disabled={(currentStep === 'Verify' && verificationCode.length < 6)
                            || loading}
                        >
                            Next
                        </Button>
                    </>
                )
            }
        >
            {finished ? (
                actualSuccess ? (
                    <HeaderWrapper>
                        <SuccessIconCircle>
                            <Icon icon="check" size="lg" color="white" />
                        </SuccessIconCircle>
                        <Typography color="text" variant="h6" align="center" spacing="mb-4 mt-4">
                            Device has been successfully attached
                        </Typography>
                    </HeaderWrapper>
                ) : (
                    <HeaderWrapper>
                        <ErrorIconCircle>
                            <Icon icon="exclamation-circle" size="lg" color="white" />
                        </ErrorIconCircle>
                        <Typography color="text" variant="h6" align="center" spacing="mb-4 mt-4">
                            There was an error attaching your device
                        </Typography>
                        <Typography color="text" variant="body1" align="center">
                            {error?.message}
                        </Typography>
                    </HeaderWrapper>
                )
            ) : (currentStep === 'Scan'
                ? <ScanQR qrCode={qrCode} error={error} loading={loading} />
                : <SendVerificationCode setVerificationCode={setVerificationCode} error={error} />

            )}
        </Dialog>
    );
};

const ScanQR = ({ qrCode, loading, error }) => {
    return (
        <>
            <Typography color="text" variant="h6" align="center" spacing="mb-4 mt-2">
                Attach new device
            </Typography>
            <Typography color="text" variant="body1" align="center">
                Please scan the QR code with your favorite authenticator app
            </Typography>

            {loading ? <Loader big active /> : (
                qrCode && !error && (
                    <Barcode>
                        <QRCode value={qrCode} />
                    </Barcode>
                )
            )}

            <Typography variant="body1" align="center" color="secondaryText" italic>
                After scanning successfully, click Next.
            </Typography>
        </>
    );
};


const SendVerificationCode = ({ setVerificationCode, error }) => {
    const onChange = useCallback((e) => {
        setVerificationCode(e.target.value);
    }, []);

    return (
        <>
            <Typography color="text" variant="h6" align="center" spacing="mb-4 mt-2">
                Attach new device
            </Typography>
            <Typography color="text" variant="body1" align="center">
                Please enter the code from your authenticator app
            </Typography>
            <Typography color="black" variant="subtitle1" spacing="my-2" align="center">
                Multi Factor Authentication Code
            </Typography>
            <InputWrapper>
                {
                    error && (
                        <Callout color="error" spacing="mb-5">
                            {error.message}
                        </Callout>
                    )
                }
                <Input onChange={onChange} label={'Code'} />
            </InputWrapper>
        </>
    );
};

export default AttachDialog;
