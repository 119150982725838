import React, { useState, useCallback, useEffect } from 'react';

import {
    Dialog,
    Divider,
    Button,
    Typography,
    ValidatedInput,
    Input,
} from 'ui-components';

/** @typedef { import('lib/query-builder').Column } Column */
/** @typedef { import('lib/query-builder').Position } Position */

/**
 * @typedef { object } Props
 * @prop { Column } [column]
 * @prop { Position } [position]
 * @prop { Column[] } allColumns
 * @prop { (position: Position, alias: string) => void } changeAlias
 * @prop { () => void } onClose
 */

/** @type { React.FC<Props> } */
const AliasDialog = ({ column, position, allColumns, changeAlias, onClose }) => {
    const [alias, setAlias] = useState(column?.alias);
    const [valid, setValid] = useState(!!column?.alias);

    const disabled = !alias || !valid;

    const onSave = useCallback(() => {
        if (!alias || position === undefined) {
            return;
        }
        changeAlias(position, alias);
        onClose();
    }, [position, alias, changeAlias, onClose]);

    useEffect(() => {
        /** @type { (e: KeyboardEvent) => void } */
        const onKeyDown = e => {
            if (e.key === 'Escape') {
                e.preventDefault();
                onClose();
            } else if (e.key === 'Enter' && !disabled) {
                e.preventDefault();
                onSave();
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    }, [disabled, onClose, onSave]);

    return (
        <Dialog
            compact
            title="Alias"
            isOpen={!!column}
            onClose={onClose}
            actions={[
                <Button
                    key="cancel"
                    type="plain"
                    spacing="ml-0"
                    onClick={onClose}
                >
                    Cancel
                </Button>,
                <Button
                    key="save"
                    color="primary"
                    spacing="mr-0"
                    onClick={onSave}
                    disabled={disabled}
                >
                    Okay
                </Button>,
            ]}
        >
            <Divider spacing="-mx-4 mt-0 mb-4" />

            <Subtitle>
                Field
            </Subtitle>

            <Input
                label="Field"
                value={column?.name || ''}
                spacing="p-0"
                width="100"
                noFloatLabel
                disabled
            />

            <Help color="secondaryText" spacing="mt-1">
                {[
                    column?.schema,
                    column?.table,
                    column?.name,
                ].join('.')}
            </Help>

            <Subtitle>
                Alias
            </Subtitle>

            <ValidatedInput
                value={alias}
                valid={valid}
                label={column?.name || ''}
                validators={[
                    {
                        test: value => /^[a-zA-Z0-9_]+$/.test(value),
                        message: 'Only letters, numbers, or underscores',
                    },
                    {
                        test: value => /^[a-zA-Z_]/.test(value),
                        message: 'Must start with a letter or underscore',
                    },
                    {
                        test: value => !allColumns.some(c => {
                            if (c.id === column?.id) {
                                return false;
                            }

                            const alias = value || column?.name;

                            return c.alias
                                ? c.alias.toLowerCase() === alias?.toLowerCase()
                                : c.name.toLowerCase() === alias?.toLowerCase();
                        }),
                        message: 'Must be unique case-insensitive alias',
                    },
                ]}
                onChange={e => setAlias(e.target.value)}
                onChangeValid={setValid}
                spacing="-m-2"
                noFloatLabel
            />
        </Dialog>
    );
};

/** @type { React.FC<{ children: React.ReactNode, spacing?: string }> } */
const Subtitle = ({ children, ...props }) => (
    <Typography variant="subtitle1" color="text" spacing="mt-4" {...props}>
        {children}
    </Typography>
);

/** @type { React.FC<{ children: React.ReactNode, spacing?: string, color?: string }> } */
const Help = ({ children, ...props }) => (
    <Typography variant="body2" color="secondaryText" spacing="mb-0" {...props}>
        {children}
    </Typography>
);

export default AliasDialog;
