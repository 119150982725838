import React from 'react';

import {
    Dialog,
    Select,
    SelectItem,
    Button,
    Loader,
} from 'ui-components';

const ReassignDialog = ({
    reassignError,
    reassignLoading,
    warehouseToReassign,
    reassignData,
    updateReassignData,
    onReassignClose,
    onReassignConfirm,
    clustersLoading,
    clusters,
}) => {
    const reassignButtonProps = {
        onClick: onReassignConfirm,
        disabled: !!reassignError || !reassignData.host,
        loading: reassignLoading,
    };

    const reasssignDialogProps = {
        isOpen: !!warehouseToReassign.id,
        onClose: onReassignClose,
        actions: [
            <Button key="cancel" type="outline" onClick={onReassignClose}>Cancel</Button>,
            <Button key="suspend" color="primary" {...reassignButtonProps}>Reassign</Button>,
        ],
    };

    const clusterSelectProps = {
        label: 'Cluster',
        helperText: 'Select the cluster this warehouse will be reassigned to',
        value: reassignData.host || warehouseToReassign.host,
        onChange: e => updateReassignData({ host: e.target.value }),
    };

    const isClusterDisabled = cluster => warehouseToReassign.host === cluster.host
      || cluster.status !== 'available';

    const getSelectItemProps = cluster => ({
        value: cluster.host,
        disabled: isClusterDisabled(cluster),
    });

    return (
        <Dialog title="Reassign Warehouse" {...reasssignDialogProps}>
            {warehouseToReassign.host && (
                <Select {...clusterSelectProps}>
                    {clusters.map(cluster => (
                        <SelectItem width="100" key={cluster.id} {...getSelectItemProps(cluster)}>
                            {cluster.host} {cluster.dbs ? `(${cluster.dbs} dbs)` : ''}
                        </SelectItem>
                    ))}
                </Select>
            )}

            <Loader message="Loading clusters" active={clustersLoading} absolute overlay />
        </Dialog>
    );
};

export default ReassignDialog;
