import config from '../../config';

import logger from './logger';
import * as Sentry from './sentry';

/** @typedef { import('./sentry/__types').Tags } Tags */

/** @type { (err: Error, data?: object) => void } */
export const reportError = (err, data) => {
    if (config.isProd) {
        Sentry.captureError(err, data);
        return;
    }

    logger.error(err, data);
};

/** @type { (message: string, data?: object) => void } */
export const addBreadcrumb = (message, data) => {
    if (config.isProd) {
        Sentry.addBreadcrumb(message, data);
        return;
    }

    logger.info(message, data);
};

/** @type { (tags: Tags) => void } */
export const setTags = tags => Sentry.setTags(tags);
