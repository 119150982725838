import React, { useCallback, useState } from 'react';

import {
    Button,
    ExpansionPanel,
    Icon,
    Layout,
    Typography,
} from 'ui-components';

import useTableMetadataFields from './use-table-metadata-fields';

/**
 * @param { string } val1
 * @param { string= } val2
 * @returns { string }
 */
function normalizeValue(val1, val2) {
    if (val1 === 'INT' && val2 === 'FLOAT') {
        return val2;
    }
    return val1;
}

/** @typedef { import('models/columns/__types').ColumnData } ColumnData */
/** @typedef { import('models/columns/__types').ColumnItem } ColumnItem */
/** @typedef { import('./__types').MetadataItemProps } MetadataItemProps */

/** @type { React.FC<MetadataItemProps> } */
const TableMetadataItem = ({
    item, columns, initialColumns, onSaveClicked, onColumnChanged,
}) => {

    const fields = useTableMetadataFields();

    const idx = initialColumns.findIndex(x => x.original_name === item.original_name);

    const [columnIsChanged, setColumnIsChanged] = useState(false);

    const onCancelButtonClicked = useCallback(
        () => onColumnChanged(idx, initialColumns[idx]),
        [onColumnChanged, idx, initialColumns],
    );

    const onSaveButtonClicked = useCallback(
        () => onSaveClicked(idx),
        [onSaveClicked, idx],
    );

    return (
        <ExpansionPanel
            actions={[
                <Button key="1" onClick={onCancelButtonClicked}>Cancel</Button>,
                <Button
                    key="2"
                    color="primary"
                    spacing="mr-0"
                    onClick={onSaveButtonClicked}
                    disabled={!columnIsChanged}
                >
                    Save
                </Button>,
            ]}
            labelComponent={<ExpansionPanelLabel item={item} />}
            contentSpacing="p-5 pb-1"
        >
            <Layout width="100">
                {fields.map((f) => {
                    const onFieldChanged = (e) => {

                        const value = ['checkbox', 'radio'].includes(e.target.type)
                            ? e.target.checked
                            : e.target.value;

                        onColumnChanged(idx, { ...columns[idx], [f.name]: value });

                        if (value !== initialColumns[idx][f.name]) {
                            setColumnIsChanged(true);
                        }
                    };

                    if (f.hidden) {
                        return null;
                    }

                    return (
                        <Layout key={f.name} width="100" spacing="mb-4" flex>
                            <Layout width="19" flex>
                                <Typography variant="button" spacing="pt-2 pl-1">
                                    {f.label}
                                </Typography>
                            </Layout>

                            <f.Component
                                width="50"
                                value={columns[idx][f.name] || f.value}
                                disabled={f.disabled}
                                onChange={onFieldChanged}
                                spacing="p-0"
                            >
                                {f.children && f.children.map(C => (
                                    <C.Component
                                        key={C.name}
                                        value={normalizeValue(C.value, columns[idx][f.name])}
                                        disabled={C.value === columns[idx][f.name]}
                                        selected={C.value === columns[idx][f.name]}
                                    >
                                        {C.label}
                                    </C.Component>
                                ))}
                            </f.Component>

                            <Layout width="31">
                                {f.help && (
                                    <Typography color="secondaryText" spacing="pl-4">
                                        {f.help}
                                    </Typography>
                                )}
                            </Layout>
                        </Layout>
                    );
                })}
            </Layout>
        </ExpansionPanel>
    );
};

/**
 * @param { { item: ColumnItem } } props
 * @returns { React.ReactElement }
 */
function ExpansionPanelLabel({ item }) {
    return (
        <Layout width="100" flex alignItems="center">
            <Layout width="20" flex>
                <Typography color="secondaryText" spacing="mr-2">
                    <Icon icon={item.icon} />
                </Typography>

                <Typography color="secondaryText">
                    {item.label}
                </Typography>
            </Layout>

            <Layout width="80">
                <Typography>
                    {item.name}
                </Typography>
            </Layout>
        </Layout>
    );
}

export default TableMetadataItem;
