import { useEffect, useCallback, useState, useRef } from 'react';
import { useSession } from '../../../shared/context-providers';
import { get } from '../../../lib/ajax';
import moment from 'moment';

/** @typedef { import('../__types').Interval } Interval */
/** @typedef { import('../__types').UsageType } UsageType */
/** @typedef { import('../__types').UsagePoint } UsagePoint */
/** @typedef { import('../__types').UsageUnit } UsageUnit */
/** @typedef { import('../__types').UsageParams } UsageParams */
/** @typedef { import('../__types').QuotasState } QuotasState */
/** @typedef { import('../__types').Quotas } Quotas */
/** @typedef { import('app/__types').Database } Database */


/**
 * @param { Quotas } quotas
 * @param { Partial<QuotasState> } updates
 * @param { UsageParams } params
 * @returns { Quotas }
 */
const updateQuotas = (quotas, updates, params) => {
    const { since, until = 'now', interval = 'none' } = params;

    return {
        ...quotas,
        [since]: {
            ...quotas?.[since],
            [until]: {
                ...quotas?.[since]?.[until],
                [interval]: {
                    ...quotas?.[since]?.[until]?.[interval],
                    ...updates,
                },
            },
        },
    };
};

/**
 * @param { Quotas } quotas
 * @param { QuotasState } defaultState
 * @param { UsageParams } params
 * @returns { QuotasState }
 */
const getQuotasState = (quotas, defaultState, params) => {
    const { since, until = 'now', interval = 'none' } = params;
    return quotas?.[since]?.[until]?.[interval] || defaultState;
};

/** @type { (limit: number) => number } */
const timesOneMillion = (limit) => {
    return limit * 1000000;
};

/** @type { (dbType: Database['type'], params: UsageParams) => Promise<UsagePoint[]> } */
export const fetchStorageUsage = async (dbType, params) => {
    return fetchUsage('storage_used', params);
};

/** @type { (type: UsageType, params: UsageParams) => Promise<UsagePoint[]> } */
const fetchUsage = async (type, params) => {
    const { since, until, interval } = params;
    const res = await get('/insights/usage', { query: { type, since, until, interval } });
    return res.data;
};

/** @type { (params: UsageParams) => Promise<UsagePoint[]> } */
export const fetchRowsExtractedUsage = fetchUsage.bind(null, 'rows_extracted');

/** @type { (params: UsageParams) => Promise<UsagePoint[]> } */
export const fetchQueryBytesUsage = fetchUsage.bind(null, 'queries_scanned');


/** @type { (params: UsageParams) => QuotasState } */
const useUsageQuotas = (params) => {
    const [session] = useSession();
    const { database } = session;
    const limits = session.billing.limits;

    const [initialState] = useState(/** @type { QuotasState } */ ({
        loading: false,
        error: '',
        storage: {
            limit: limits?.storage_gb,
            used: [],
        },
        rowsExtracted: {
            limit: limits?.rows && (
                limits.rows < 1_000_000 ? timesOneMillion(limits.rows) : limits.rows
            ),
            used: [],
        },
        queryBytes: {
            limit: database.type === 'bigquery' ? limits?.query_bytes : null,
            used: database.type === 'bigquery' ? [] : null,
        },
    }));

    const [quotas, setQuotas] = useState({});

    const loadedRef = useRef(/** @type { { [key: string]: boolean } } */ ({}));

    const load = useCallback(async () => {
        const key = JSON.stringify(params);

        if (loadedRef.current[key]) {
            return;
        }

        loadedRef.current[key] = true;

        setQuotas(q => updateQuotas(q, { loading: true }, params));

        try {
            const requests = [
                fetchStorageUsage.bind(null, database.type),
                fetchRowsExtractedUsage,
                ...(database.type === 'bigquery'
                    ? [fetchQueryBytesUsage]
                    : []
                ),
            ];

            const results = await Promise.all(requests.map(fn => fn(params)));
            const [storageUsed, rowsExtractedUsed, queryBytesUsed] = results;

            const quotaState = {
                loading: false,
                error: '',
                storage: {
                    ...initialState.storage,
                    used: fillGapsInUsagePoints(storageUsed, 'bytes', params),
                },
                rowsExtracted: {
                    ...initialState.rowsExtracted,
                    used: fillGapsInUsagePoints(rowsExtractedUsed, 'rows', params),
                },
                queryBytes: {
                    ...initialState.queryBytes,
                    ...(queryBytesUsed ? {
                        used: fillGapsInUsagePoints(queryBytesUsed, 'bytes', params),
                    } : {}),
                },
            };

            setQuotas(q => updateQuotas(q, quotaState, params));

        } catch (e) {
            const error = e.message || 'There was an error loading usage data';
            setQuotas(q => updateQuotas(q, { loading: false, error }, params));
        }
    }, [params.since, params.until, params.interval, initialState]);

    useEffect(() => {
        load();
    }, [load]);

    const quotaState = getQuotasState(quotas, initialState, params);

    return quotaState;
};

/** @type { (usagePoints: UsagePoint[], unit: UsageUnit, params: UsageParams) => UsagePoint[] } */
const fillGapsInUsagePoints = (usagePoints, unit, params) => {
    if (!params.interval || !params.since || !usagePoints) {
        return usagePoints || [];
    }

    const since = moment.utc(params.since);
    const until = params.until ? moment.utc(params.until) : moment.utc();
    const interval = params.interval;

    const res = /** @type { UsagePoint[] } */ ([]);
    let cursor = since.startOf(interval);

    while (cursor.isSameOrBefore(until)) {
        const usagePoint = usagePoints.find(p => {
            const startOfInterval = moment.utc(p.endTime).startOf(interval);
            return cursor.isSame(startOfInterval);
        });

        res.unshift({
            startTime: cursor.toISOString(),
            endTime: cursor.endOf(interval).toISOString(),
            quantity: usagePoint ? usagePoint.quantity : '0',
            unit: usagePoint ? usagePoint.unit : unit,
        });

        cursor = cursor.add(1, interval).startOf(interval);
    }

    return res;
};

export default useUsageQuotas;
