import React, { useState, useCallback } from 'react';

import {
    Button,
    Input,
    Dialog,
    Layout,
    Typography,
} from 'ui-components';
import { reportError } from '../../lib/error-reporter';

/** @typedef { import('models/database-objects/__types').Folder } Folder */
/** @typedef { import('lib/ajax').AjaxResponse= } AjaxError */

/**
 * @typedef { object } Props
 * @prop { Boolean } open
 * @prop { Folder['id'] | null } parentFolderId
 * @prop { () => void } onClose
 * @prop { (name: string, parentId: Folder['id'] | null) => Promise<Folder> } createFolder
 */

/** @type { React.FC<Props> } */
const CreateFolderDialog = ({
    open, parentFolderId, onClose, createFolder,
}) => {

    const [loading, setLoading] = useState(false);

    /** @type {[AjaxError, React.Dispatch<AjaxError>]} */
    const [error, setError] = useState();

    const [newFolderName, setNewFolderName] = useState('');

    const title = parentFolderId ? 'Create Subfolder' : 'Create Folder';

    const onCreateClicked = useCallback(async () => {
        setLoading(true);
        try {
            await createFolder(newFolderName, parentFolderId);
            setLoading(false);
            setNewFolderName('');
            onClose();
        } catch (err) {
            reportError(err, { newFolderName, parentFolderId });
            setError(err);
            setLoading(false);
        }
    }, [newFolderName, parentFolderId, createFolder, onClose]);

    const onFolderInputChanged = useCallback((e) => {
        setNewFolderName(e.target.value);
    }, []);

    return (
        <Dialog
            title={title}
            isOpen={open}
            onClose={onClose}
        >
            <Input
                width="100"
                value={newFolderName}
                label="New folder name"
                spacing="m-0 p-0"
                disabled={loading}
                onChange={onFolderInputChanged}
            />

            {error && (
                <Layout spacing="mt-4">
                    <Typography variant="subtitle1" color="error">
                        Oops! an error was encountered when moving the item.
                    </Typography>
                    <Typography variant="body1" color="error">
                        {(error.data && error.data.message) || error.status}
                    </Typography>
                </Layout>
            )}

            <Layout flex justifyContent="flex-end" spacing="mt-6">
                <Button onClick={onClose} disabled={loading}>Cancel</Button>
                <Button
                    color="primary"
                    loading={loading}
                    onClick={onCreateClicked}
                    disabled={!newFolderName}
                    spacing="mr-0"
                >
                    Accept
                </Button>
            </Layout>
        </Dialog>
    );
};

export default CreateFolderDialog;
