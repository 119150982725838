// bytes
export const KILOBYTE = 1000;
export const MEGABYTE = KILOBYTE * 1000;
export const GIGABYTE = MEGABYTE * 1000;

// bibytes
export const BIBYTE = 1;
export const KIBIBYTE = 1024;
export const MEBIBYTE = KIBIBYTE * 1024;
export const GIBIBYTE = MEBIBYTE * 1024;
export const TEBIBYTE = GIBIBYTE * 1024;

export const BIBYTE_UNITS = [
    'B',
    'kiB',
    'MiB',
    'GiB',
    'TiB',
];
