import { useCallback, useState } from 'react';

function useWorkbenchVisualizationDrawer() {
    const [drawerOpen, setDrawerOpen] = useState(false);


    const toggleDrawerOpen = useCallback(() => {
        setDrawerOpen((drawerOpen => !drawerOpen));
    }, [drawerOpen]);

    return {
        drawerOpen,
        toggleDrawerOpen,
    };
}

export default useWorkbenchVisualizationDrawer;
