import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
    Avatar,
    Divider,
    Dropdown,
    Layout,
    List,
    ListItem,
    Typography,
    Tooltip,
    ToggleButton,
} from 'ui-components';

import gotoUrl from '../../lib/goto-url';
import getLink from '../../lib/get-link';
import useAvatar from '../../shared/use-avatar';

/** @typedef { import('../../app/__types').Session } Session */
/** @typedef { import('../../lib/get-link').Location } Location */

/**
 * @typedef { object } IsHiddenProps
 *
 * @prop { Session['nav'] } nav
 * @prop { Session['database'] } database
 */

/**
 * @typedef { object } Item
 * @prop { string } id
 * @prop { string } text
 * @prop { string } [url]
 * @prop { boolean } [divider]
 * @prop { boolean } [reload]
 * @prop { (user: Session['user']) => string | Location } [getUrl]
 * @prop { (props: IsHiddenProps) => boolean } [isHidden]
 */

/** @type { Item[] } */
const items = [
    {
        id: 'databases',
        text: 'Data Warehouses',
        url: '/databases',
        divider: true,
        isHidden: ({ nav }) => nav.exclude.includes('databases'),
    },
    {
        id: 'billing',
        text: 'Billing & Plan',
        url: '/billing',
        isHidden: ({ nav }) => nav.exclude.includes('billing'),
    },
    {
        id: 'notifications',
        text: 'Notifications',
        url: '/notifications/settings',
        isHidden: ({ nav }) => nav.exclude.includes('notifications'),
    },
    {
        id: 'change-password',
        text: 'Change password',
        isHidden: ({ nav }) => nav.exclude.includes('change-password'),
        getUrl: ({ email }) => getLink('/users/reset', { email }),
    },
    {
        id: 'rotate-service-account-json-file',
        text: 'Rotate service account JSON file',
        isHidden: ({ nav }) => nav.exclude.includes('rotate'),
        getUrl: () => getLink('/users/credentials/rotate'),
    },
    {
        id: 'two-factor-authentication',
        text: 'Multi Factor Authentication',
        isHidden: ({ nav }) => nav.exclude.includes('mfa'),
        getUrl: () => getLink('/mfa'),
    },
    {
        id: 'sign-out',
        text: 'Sign Out',
        url: '/users/logout',
        divider: true,
        reload: true,
    },
];

/**
 * @typedef { object } Props
 * @prop { Session['nav'] } nav
 * @prop { Session['user']  } user
 * @prop { Session['database']  } database
 * @prop { string } [spacing]
 */

/** @type { React.FC<Props> } */
const UserMenu = ({
    nav,
    user,
    database,
    ...props
}) => {
    const navigate = useNavigate();
    const { initials, avatar } = useAvatar(user);

    const [open, setOpen] = useState(false);

    const toggleOpen = useCallback(() => {
        setOpen(!open);
    }, [open]);

    const username = user.displayname || user.fname || user.username || '';

    return (
        <Dropdown
            id="user"
            opener={open ? (
                <UserToggle
                    active
                    initials={initials}
                    avatar={avatar}
                    {...props}
                />
            ) : (
                <Tooltip
                    content={[
                        <Typography key="title" component="span" color="white" weight="medium">
                            Panoply Account
                        </Typography>,
                        <Typography key="text" component="span" color="white">
                            {username}
                            {user.email !== username && (
                                <>
                                    <br/>
                                    {user.email}
                                </>
                            )}
                        </Typography>,
                    ]}
                    interactive={false}
                >
                    <UserToggle
                        initials={initials}
                        avatar={avatar}
                        onClick={toggleOpen}
                        {...props}
                    />
                </Tooltip>
            )}
            open={open}
            onClose={toggleOpen}
        >
            <StyledList>
                <ListItem>
                    <Layout flex alignItems="center">
                        <Avatar src={avatar} size="lg" spacing="mr-4">
                            {initials}
                        </Avatar>

                        <Layout>
                            <Typography weight="medium">
                                {username}
                            </Typography>

                            {user.email !== username && (
                                <Typography>
                                    {user.email}
                                </Typography>
                            )}
                        </Layout>
                    </Layout>
                </ListItem>

                {items.map((item) => {
                    if (item.isHidden && item.isHidden({ nav, database })) {
                        return null;
                    }

                    const onClicked = () => {
                        toggleOpen();

                        const url = item.getUrl
                            ? item.getUrl(user)
                            : item.url || '/';

                        if (item.reload && typeof url === 'string') {
                            gotoUrl(url);
                        } else {
                            navigate(url);
                        }
                    };

                    return (
                        <React.Fragment key={item.id}>
                            {item.divider && <Divider />}

                            <ListItem
                                button
                                label={item.text}
                                onClick={onClicked}
                            />
                        </React.Fragment>
                    );
                })}
            </StyledList>
        </Dropdown>
    );
};

/**
 * @typedef { object } ToggleProps
 * @prop { string } initials
 * @prop { string } avatar
 * @prop { boolean } [active]
 * @prop { () => void } [onClick]
 */

/** @type { (props: ToggleProps) => React.ReactElement } */
const UserToggle = styled(({ initials, avatar, ...props }) => (
    <ToggleButton
        round
        type="plain"
        color="secondaryText"
        {...props}
    >
        <Avatar src={avatar} size="sm">
            {initials}
        </Avatar>
    </ToggleButton>
))`
    &&& {
        padding: 4px;
    }
`;

const StyledList = styled(List)`
    min-width: 20rem;
`;

export default UserMenu;
