import React from 'react';

import {
    Button,
    Callout,
} from 'ui-components';

import PageLayout from '../../shared/page-layout';
import PageHeader from '../../shared/page-header';
import TablePreview from './table-preview';
import TableMetadata from './table-metadata';
import SaveColumnDialog from './save-column-dialog';
import useTablePage from './use-table-page';

import { extractTableName } from '../../models/columns';

/** @typedef { import('../../app/__types').PageProps } PageProps */

/** @type { React.FC<PageProps> } */
const TablePage = ({ id, openToast }) => {
    const {
        preview,
        loadingPreview,

        table,
        columns,
        columnToSave,
        initialColumns,
        loadingMetadata,
        loadingPrimaryKeys,
        savingColumn,
        hiddenCount,
        shownItems,
        showHidden,
        showWarning,
        onShowHiddenToggled,
        onSaveCancelClicked,
        onSaveClicked,
        onColumnChanged,
        onSaveConfirmClicked,
    } = useTablePage({ id, openToast });

    const previewProps = {
        data: preview,
        loading: loadingPreview,
    };

    const showItems = shownItems.length > 0
        && !loadingMetadata
        && !loadingPrimaryKeys;

    const metadataProps = {
        columns,
        initialColumns,
        loadingMetadata,
        loadingPrimaryKeys,
        hiddenCount,
        shownItems,
        showHidden,
        showItems,
        onShowHiddenToggled,
        onSaveClicked,
        onColumnChanged,
    };

    const dialogProps = {
        columnToSave,
        savingColumn,
        onSaveCancelClicked,
        onSaveConfirmClicked,
    };

    return (
        <PageLayout>
            {showItems && showWarning && (
                <Callout color="warning" spacing="mb-4">
                    A user removed the Panoply connector which collected this table.
                    The data may no longer be up-to-date.
                </Callout>
            )}

            <PageHeader title={extractTableName(id)}>
                <Button
                    link={(table.schema && (table.current_name || table.name)
                        ? `/workbench/tables/${table.schema}/${table.current_name || table.name}`
                        : `/workbench/tables/${id}`
                    )}
                    color="primary"
                    spacing="m-0 py-1 px-4"
                >
                    Query
                </Button>
            </PageHeader>

            <TablePreview {...previewProps} spacing="mb-6" />
            <TableMetadata {...metadataProps} />
            <SaveColumnDialog {...dialogProps} />
        </PageLayout>
    );
};


export default TablePage;
