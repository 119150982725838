
/**
 * @param {string} icon;
 */
function cdnIcon(icon, type = 'icons') {
    return `https://learn.panoply.io/hubfs/platform/images/${type}/${icon}`;
}

/**
 * @param {string} sourceTypeId;
 */
function cdnDataSourceIcon(sourceTypeId) {
    return `https://learn.panoply.io/hubfs/Data%20Source%20Icons/${sourceTypeId}.svg`;
}

export default cdnIcon;

export { cdnDataSourceIcon };
