import { useState, useCallback } from 'react';

import { reportError } from '../../lib/error-reporter';
import * as Report from '../../models/reports';
import { useToast } from '../context-providers';

/** @typedef { import('models/reports/types').Report } Report */
/** @typedef { import('models/reports/types').CreateReportParams } CreateReportParams */
/** @typedef { import('models/reports/types').EditReportParams } EditReportParams */
/** @typedef { import('./use-workbench-data').WorkbenchData } WorkbenchData */

/**
 * @typedef { object } Props
 * @prop { WorkbenchData['fetchReports'] } fetchReports
 */

/**
 * @param { Props } props
 */
function useWorkbenchReports({ fetchReports }) {
    const { openToast } = useToast();

    const [creatingReport, setSavingAsReport] = useState(false);
    const [editingReport, setSavingReport] = useState(false);
    const [removingReport, setDeletingReport] = useState(false);

    /** @type { (params: CreateReportParams) => Promise<Report> } */
    const createReport = useCallback(async (params) => {
        setSavingAsReport(true);

        try {
            const report = await Report.createReport(params);

            openToast({
                message: `The report "${report.title}" was saved.`,
                type: 'success',
                variant: 'outline',
            });

            fetchReports(); // refresh

            return report;

        } catch (/** @type { any } */ err) {
            reportError(err, params);

            openToast({
                message: err?.message || err?.data?.message || 'Unknown error occurred',
                type: 'error',
                variant: 'outline',
            });

            throw err;

        } finally {
            setSavingAsReport(false);
        }
    }, [fetchReports, openToast]);

    /** @type { (id: string, params: EditReportParams) => Promise<Report> } } */
    const editReport = useCallback(async (id, params) => {
        setSavingReport(true);

        try {
            const report = await Report.editReport(id, params);

            openToast({
                message: `The report "${report.title}" was saved.`,
                type: 'success',
                variant: 'outline',
            });

            fetchReports(); // refresh

            return report;

        } catch (/** @type { any } */ err) {
            reportError(err, { id, ...params });

            openToast({
                message: err?.message || err?.data?.message || 'Unknown error occurred',
                type: 'error',
                variant: 'outline',
            });

            throw err;

        } finally {
            setSavingReport(false);
        }
    }, [fetchReports, openToast]);

    /** @type { (id: string, title: string) => Promise<void> } */
    const removeReport = useCallback(async (id, title) => {
        setDeletingReport(true);

        try {
            await Report.removeReport(id);

            openToast({
                message: `The report "${title}" was deleted.`,
                type: 'success',
                variant: 'outline',
            });

            fetchReports(); // refresh

        } catch (/** @type { any } */ err) {
            reportError(err, { id, title });

            openToast({
                message: err?.message || err?.data?.message || 'Unknown error occurred',
                type: 'error',
                variant: 'outline',
            });

            throw err;

        } finally {
            setDeletingReport(false);
        }
    }, [fetchReports, openToast]);

    return {
        creatingReport,
        editingReport,
        removingReport,
        createReport,
        editReport,
        removeReport,
    };
}

export default useWorkbenchReports;
