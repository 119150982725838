import { useState, useCallback, useEffect, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

function useWorkbenchRecipes() {
    const location = useLocation();

    const [searchParams] = useSearchParams();

    const hasOpened = useRef(false);

    const isSourcesPage = location.pathname.startsWith('/sources');

    const [isRecipesOpen, setIsRecipesOpen] = useState(false);

    const onCloseRecipes = useCallback(() => {
        setIsRecipesOpen(false);
    }, []);

    const onOpenRecipes = useCallback(() => {
        setIsRecipesOpen(true);
    }, []);

    // Automatically open the Recipes Dialog (based on Figma specs)
    useEffect(() => {
        if (!isSourcesPage || hasOpened.current) {
            return;
        }

        hasOpened.current = true;

        onOpenRecipes();
    }, [onOpenRecipes, isSourcesPage]);

    useEffect(() => {
        if (!isSourcesPage) {
            return;
        }
        onOpenRecipes();
    }, [onOpenRecipes, isSourcesPage, searchParams]);

    return {
        isRecipesOpen,
        isSourcesPage,
        onCloseRecipes,
        onOpenRecipes,
    };
}

export default useWorkbenchRecipes;
