import React, {
    createContext,
    useContext,
    useCallback,
    useEffect,
    useState,
    useRef,
    useMemo,
} from 'react';

import moment from 'moment';

import useSession from '../use-session';
import { get, put, post } from '../../../lib/ajax';

/** @typedef { import('../../../app/__types').Session } Session */
/** @typedef { import('models/sources/__types').SourceType } SourceType */

/**
 * @typedef { object } OnboardingState
 * @prop { boolean } loading
 * @prop { boolean= } skipped
 * @prop { boolean= } completed
 */

/**
 * @typedef { object } OnboardingComputedState
 * @prop { boolean } active
 * @prop { boolean } enabled
 * @prop { boolean } didLoad
 */

/**
 * @typedef { object } OnboardingActions
 * @prop { () => Promise<void> } skipOnboarding
 * @prop { () => Promise<void> } endOnboarding
 */

/**
 * @typedef { OnboardingState & OnboardingComputedState } OnboardingValues
 */

const showOnboardingFromDate = '2020-11-15T00:00:01.000Z';

export const OnboardingContext = createContext(
    /** @type { [OnboardingValues, OnboardingActions] } */ ({})
);

/** @type { React.FC<{ children: React.ReactNode }> } */
export const OnboardingProvider = ({ children }) => {

    const [session] = useSession();

    /** @type { OnboardingState } */
    const initialState = {
        loading: false,
        skipped: undefined,
        completed: undefined,
    };

    /** @type { [OnboardingState, React.Dispatch<React.SetStateAction<OnboardingState>>] } */
    const [state, setState] = useState(initialState);

    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    const computedState = useMemo(() => {
        if (
            !session.user.id
            || !session.database.id
            || session.database.status !== 'active'
        ) {
            return {
                enabled: false,
                active: false,
                didLoad: false,
            };
        }
        const createdAt = moment(session.database.created_at);
        const showFrom = moment(showOnboardingFromDate);

        const enabled = createdAt.isAfter(showFrom)
            && session.user.id === session.database.owner_id;

        const active = enabled
            && !state.loading
            && !state.skipped
            && !state.completed;

        const didLoad = state.skipped !== undefined && state.completed !== undefined;

        return {
            enabled,
            active,
            didLoad,
        };
    }, [state, session]);

    const skipOnboarding = useCallback(async () => {
        try {
            await put(`/onboarding/${session.user.id}`, {
                body: {
                    skipped: true,
                    databaseId: session.database.id,
                    userId: session.user.id,
                },
            });
            setState(prevState => ({ ...prevState, skipped: true }));
        } catch (e) {
            setState(prevState => ({ ...prevState, skipped: false }));
        }
    }, [session]);

    /** @type { (typeId?: string) => Promise<void> } */
    const endOnboarding = useCallback(async () => {
        try {
            await put(`/onboarding/${session.user.id}`, {
                body: {
                    completed: true,
                    databaseId: session.database.id,
                    userId: session.user.id,
                },
            });
            setState(prevState => ({ ...prevState, completed: true }));
        } catch (e) {
            setState(prevState => ({ ...prevState, completed: false }));
        }
    }, [session]);

    /** @type { () => void } */
    const createInitialRecord = useCallback(async () => {
        try {
            const {
                data: { skipped, completed },
            } = await post('/onboarding', {
                body: {
                    databaseId: session.database.id,
                    userId: session.user.id,
                },
            });

            setState((prevState) => ({ ...prevState, skipped, completed, loading: false }));
        } catch (e) {
            setState((prevState) => (
                { ...prevState, skipped: false, completed: false, loading: false }
            ));
        }

    }, [session]);

    /** @type { (userId: Session['user']['id']) => void } */
    const loadOnboarding = useCallback(async (userId) => {
        setState((prevState) => ({ ...prevState, loading: true }));
        try {
            const {
                data: { skipped, completed },
            } = await get(`/onboarding/${userId}`);

            if (!mounted.current) {
                /* eslint-disable-next-line no-console */
                console.warn('loadOnboarding() resolved after the component was unmounted');
                return;
            }

            setState((prevState) => ({ ...prevState, skipped, completed, loading: false }));
        } catch (/** @type { any } */ e) {
            if (e.status === 404 && computedState.enabled) {
                createInitialRecord();
            } else {
                setState((prevState) => (
                    { ...prevState, skipped: false, completed: false, loading: false }));
            }
        }
    }, [createInitialRecord]);

    useEffect(() => {
        if (
            !session.user.id
            || !computedState.enabled
            || state.loading
            || computedState.didLoad
        ) {
            return;
        }
        loadOnboarding(session.user.id);
    }, [session.user.id, state, computedState, loadOnboarding]);

    const actions = useMemo(() => ({
        skipOnboarding,
        endOnboarding,
    }), [
        skipOnboarding,
        endOnboarding,
    ]);

    /** @type {[OnboardingValues, OnboardingActions]} */
    const value = useMemo(() => ([
        {
            ...state,
            ...computedState,
        },
        actions,
    ]), [
        state,
        actions,
        computedState,
    ]);

    return (
        <OnboardingContext.Provider value={value}>
            {children}
        </OnboardingContext.Provider>
    );
};

/** @type {() => [OnboardingValues, OnboardingActions]} */
function useOnboarding() {
    const [state, actions] = useContext(OnboardingContext);
    return [state, actions];
}

export default useOnboarding;
