import React from 'react';
import styled from 'styled-components';

import {
    Button,
    Icon,
    Tooltip,
    DebouncedInput,
    Typography,
    Layout,
    spacings,
} from 'ui-components';

/** @typedef { import('./__types').MenuItem } MenuItem */

/**
 * @typedef { object } Props
 * @prop { MenuItem[] } items
 * @prop { string } spacing
 */

/** @type { React.FC<Props> } */
const WorkbenchMenuExpanded = ({ items, ...props }) => (
    <>
        {items.map(item => <MenuItem key={item.label} {...item} {...props} />)}
    </>
);

/**
 * @param { MenuItem & { spacing: string } } props
 * @returns { React.ReactElement }
 */
function MenuItem({
    label,
    onClick,
    icon,
    prefix,
    tooltip,
    square,
    spacing,
    value,
    type,
    disabled,
    loading,
    active,
    onChange,
}) {
    if (onChange) {
        return (
            <InlineLayout
                flex
                alignItems="center"
                spacing={`m-0 ${spacing}`}
            >
                <Typography
                    color={disabled ? 'secondaryInterface' : 'secondaryText'}
                    spacing="mr-1"
                >
                    {label}
                </Typography>

                <Tooltip content={tooltip}>
                    <MiniDebouncedInput
                        noFloatLabel
                        type={type}
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        spacing="p-0"
                        suffix={value ? (
                            <Button
                                round
                                type="plain"
                                spacing="m-0 p-0"
                                onClick={() => onChange('')}
                                disabled={disabled}
                            >
                                <Icon
                                    prefix="fas"
                                    icon="times-circle"
                                    color="interface"
                                    size="sm"
                                />
                            </Button>
                        ) : null}
                        disabled={disabled}
                    />
                </Tooltip>
            </InlineLayout>
        );
    }

    return (
        <Tooltip content={tooltip} interactive={false}>
            <Button
                type="plain"
                onClick={onClick}
                square={square}
                spacing={`m-0 ${square ? 'p-2' : 'py-1 px-2'} ${spacing}`}
                disabled={disabled}
                loading={loading}
            >
                {icon && (
                    <Icon
                        color={active ? 'primary' : 'secondaryText'}
                        icon={icon}
                        prefix={prefix}
                        spacing={!square ? 'mr-1' : ''}
                    />
                )}
                {(!icon || !square) && label}
            </Button>
        </Tooltip>
    );
}

const InlineLayout = styled(Layout)`
    display: inline-flex;
`;

const MiniDebouncedInput = styled(DebouncedInput)`
    &&&& {
        max-width: 90px;

        & input {
            margin: 0;
            padding: ${spacings[2] / 2}em ${spacings[1]}em;
            min-width: unset;
            min-height: unset;
            font-size: 14px;
            line-height: 14px;
        }

        & .form-control {
            padding-right: ${({ suffix }) => (suffix ? spacings[1] : 0)}rem;
        }
    }
`;

export default WorkbenchMenuExpanded;
