import { useState, useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getFlattenedParams, fetchSourceList } from '../../../models/sources';
import useBatchActions from '../../../shared/use-batch-actions';

import {
    syncSources,
    copyParamValues,
    isSyncableParam,
    saveSourceCollectConfig,
    fetchSourceCollectConfig,
    isOAuthParam,
    areParamDepsMet,
    isSourceCollecting,
} from '../../../models/sources';

import {
    useCollectConfig,
    setCollectConfigParamValue,
} from '../../../shared/source/collect-config';

import { reportError } from '../../../lib/error-reporter';

/** @typedef { import('models/sources/__types').Source } Source */
/** @typedef { import('models/sources/__types').SourceType } SourceType */
/** @typedef { import('models/sources/__types').Param } Param */
/** @typedef { import('models/sources/__types').CollectConfig } CollectConfig */

/** @type { (value: any, param?: Param) => string } */
export const formatValue = (value, param) => {
    if (typeof value === 'boolean') {
        return resolveValue(value, 'True', 'False');
    }
    if (param && isOAuthParam(param)) {
        return resolveValue(value, 'Connected', 'Not connected');
    }
    if (param && param.type === 'password') {
        return resolveValue(value, 'Hidden', 'No value');
    }
    if (Array.isArray(value)) {
        const text = resolveValue(value.length === 1, 'item', 'items');
        return `${value.length} ${text} selected`;
    }
    if (typeof value === 'object' && value !== null) {
        return 'Multiple fields';
    }
    if (typeof value === 'number') {
        return value.toString();
    }
    if (!value) {
        return 'No value';
    }
    if (typeof value === 'string') {
        return value;
    }
    return '';
};

/** @type { (v: any, t1: string, t2: string) => string } */
const resolveValue = (v, t1, t2) => (v ? t1 : t2);

function useSyncSourceDialog(
    /** @type { boolean } */ open,
    /** @type { Source } */ source,
    /** @type { SourceType } */ sourceType,
) {

    const masterSource = source;
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);

    const collectConfigParams = useCollectConfig(source.collectConfig);

    const { data: syncableSources } = useQuery({
        enabled: open,
        initialData: [],
        queryKey: ['source-list', sourceType.id],
        queryFn: async () => {
            const sources = await fetchSourceList({
                type: sourceType.id,
                $limit: 500,
            });
            return sources.filter(s => s.id !== masterSource.id);
        },
    });

    const selectableParams = useMemo(() => {
        return sourceType.params.filter(isSyncableParam);
    }, [sourceType.params]);

    const {
        selectedObjects: selectedSources,
        toggleSelectedObject: toggleSelectedSources,
        isObjectSelected: isSourceSelected,
    } = useBatchActions(syncableSources);

    const {
        selectedObjects: selectedParams,
        toggleSelectedObject: toggleSelectedParam,
    } = useBatchActions(selectableParams);

    const {
        selectedObjects: selectedCcParams,
        toggleSelectedObject: toggleSelectedCcParam,
        isObjectSelected: isCcParamSelected,
    } = useBatchActions(collectConfigParams);

    /** @type { (stepNum?: number) => void } */
    const nextStep = useCallback(async (number) => {
        setCurrentStep(p => (number === undefined ? p + 1 : number));
    }, []);

    const constructedSource = useMemo(() => {
        const emptySource = /** @type { Source } */ ({});
        const flattenedParams = getFlattenedParams(selectedParams);

        return copyParamValues(
            masterSource,
            emptySource,
            flattenedParams,
        );
    }, [masterSource, selectedParams]);

    /** @type { (param: Param) => boolean } */
    const isParamDepsMet = (param) => {
        return areParamDepsMet(constructedSource, param);
    };

    /** @type { (source: Source) => boolean } */
    const isSourceDisabled = (source) => {
        return isSourceCollecting(source);
    };

    const _syncSources = async () => {
        setLoading(true);
        const ids = selectedSources.map(source => source.id);
        try {
            await Promise.all([
                syncSources(masterSource.id, ids, selectedParams),
                ...(!selectedCcParams.length ? [] : ids.map(async id => {
                    const initialCollectConfig = await fetchSourceCollectConfig(id);
                    const updatedCollectConfig = selectedCcParams.reduce((acc, param) => {
                        return setCollectConfigParamValue(param, acc);
                    }, initialCollectConfig);
                    return saveSourceCollectConfig(updatedCollectConfig, id);
                })),
            ]);
            setLoading(false);
            nextStep(3);
        } catch (/** @type { any } */ err) {
            setLoading(false);
            reportError(err);
            setError(err.data.message || 'An error was encountered while saving a Connector');
        }
    };

    return {
        masterSource,
        error,
        loading,
        currentStep,
        selectedSources,
        syncableSources,
        selectableParams,
        selectedParams,
        collectConfigParams,
        selectedCcParams,
        toggleSelectedCcParam,
        toggleSelectedSources,
        isCcParamSelected,
        isSourceSelected,
        toggleSelectedParam,
        nextStep,
        isParamDepsMet,
        isSourceDisabled,
        syncSources: _syncSources,
    };
}

export default useSyncSourceDialog;
