import { useEffect, useState, useCallback } from 'react';
import { useRequest } from '../../lib/ajax';

import {
    extractPrimaryKeys,
    normalizeColumns,
    createColumnItems,
} from '../../models/columns';

/** @typedef { import('models/columns/__types').ColumnData } ColumnData */
/** @typedef { import('models/columns/__types').ColumnItem } ColumnItem */
/** @typedef { import('models/columns/__types').ColumnToSave } ColumnToSave */
/** @typedef { import('./__types').MetadataHookProps } MetadataHookProps */
/** @typedef { import('./__types').MetadataReturnData } MetadataReturnData */

/**
 * @param { MetadataHookProps } props
 * @returns { MetadataReturnData }
 */
function useTableMetadata({ id, openToast }) {
    /** @type { ColumnData[] } */
    const initialColumns = [];

    /** @type { ColumnItem[] } */
    const initialItems = [];

    /** @type { [ColumnData[], (columns: ColumnData[]) => void] } */
    const [columns, setColumns] = useState(initialColumns);

    /** @type { [ColumnItem[], (columns: ColumnItem[]) => void] } */
    const [allItems, setAllItems] = useState(initialItems);

    /** @type { [ColumnToSave, (column: ColumnToSave) => void] } */
    const [columnToSave, setColumnToSave] = useState({});

    const [showHidden, setShowHidden] = useState(false);
    const [showWarning, setShowWarning] = useState(false);

    const tableReq = useRequest(`/tables/${id}?$with=columns`);
    const sourceReq = useRequest(`/sources/${tableReq.data?.sourceId}`);

    const alterReq = useRequest('/alter/' + [
        tableReq.data?.schema,
        tableReq.data?.current_name,
        columnToSave.original_name,
    ].join('/'), 'PUT', columnToSave);

    const onShowHiddenToggled = useCallback(() => {
        setShowHidden(!showHidden);
    }, [showHidden]);

    const onSaveClicked = useCallback((idx) => {
        setColumnToSave(columns[idx]);
    }, [columns]);

    const onSaveCancelClicked = useCallback(() => {
        setColumnToSave({});
    }, []);

    const onSaveConfirmClicked = useCallback(() => {
        if (!tableReq.data || !columnToSave.current_name) {
            return;
        }
        alterReq.go().then((res) => {
            if (!res.ok) {
                openToast('Failed to save the column. Please try again.');
                return;
            }
            setColumnToSave({});
            openToast('The changes have been successfully scheduled.');
        });
    }, [tableReq.data, columnToSave.current_name, alterReq, openToast]);

    const onColumnChanged = useCallback((idx, newColumn) => {
        /** @type { ColumnData[] } */
        const newColumns = [...columns];
        newColumns[idx] = newColumn;
        setColumns(newColumns);
    }, [columns]);

    useEffect(() => {
        if (id) {
            tableReq.go();
        }
    }, [id]);

    useEffect(() => {
        if (tableReq.error) {
            openToast('Failed to load table metadata.');
        }
    }, [tableReq.error]);

    useEffect(() => {
        if (!tableReq.data || tableReq.error) {
            return;
        }
        setColumns(normalizeColumns(tableReq.data.columns));
        setAllItems(createColumnItems(tableReq.data.columns));
        if (tableReq.data.sourceId) {
            sourceReq.go();
        }
    }, [tableReq.data]);

    useEffect(() => {
        if (!sourceReq.error) {
            return;
        }
        openToast('Failed to load primary keys.');
        setShowWarning(true);
    }, [sourceReq.error]);

    useEffect(() => {
        if (!(sourceReq.data && sourceReq.data.idpattern) || !columns.length) {
            return;
        }
        const primaryKeys = extractPrimaryKeys(sourceReq.data.idpattern);
        setColumns(normalizeColumns(columns, primaryKeys));
    }, [sourceReq.data]);

    return {
        table: tableReq.data || {},
        columns,
        columnToSave,
        initialColumns: tableReq.data ? tableReq.data.columns : [],
        loadingMetadata: tableReq.loading,
        loadingPrimaryKeys: sourceReq.loading,
        savingColumn: alterReq.loading,
        hiddenCount: allItems.filter(x => x.hidden).length,
        shownItems: showHidden ? allItems : allItems.filter(x => !x.hidden),
        showHidden,
        showWarning,
        onShowHiddenToggled,
        onSaveCancelClicked,
        onSaveClicked,
        onColumnChanged,
        onSaveConfirmClicked,
    };
}

export default useTableMetadata;
