import React from 'react';

import {
    Icon,
} from 'ui-components';

import QuerySelectorDropdown, { InlineLoader } from './query-selector-dropdown';

/** @typedef { import('models/queries/__types').Status } Status */
/** @typedef { import('models/queries/__types').QueryResult } QueryResult */

/**
 * @typedef { object } QuerySelectorBarProps
 * @prop { QueryResult[] } queryResults
 * @prop { QueryResult } [activeResult]
 * @prop { (activeResult: QueryResult) => void } changeActiveQueryResult
 * @prop { Status } [parentQueryStatus]
 * @prop { Date } [queryStartedAt]
 * @prop { Date } [queryEndedAt]
 */

/** @type { React.FC<QuerySelectorBarProps> } */

const WorkbenchQuerySelector = ({
    queryResults,
    activeResult,
    changeActiveQueryResult,
    parentQueryStatus,
    queryStartedAt,
    queryEndedAt,
}) => {
    if (queryResults.length <= 1 || !activeResult) {
        return null;
    }

    return (
        <>
            <StatusIcon
                status={parentQueryStatus}
                endedAt={queryEndedAt}
            />

            <QuerySelectorDropdown
                queryResults={queryResults}
                activeQueryResult={activeResult}
                changeActiveQueryResult={changeActiveQueryResult}
                startedAt={queryStartedAt}
                endedAt={queryEndedAt}
            />
        </>
    );
};

/** @type { React.FC<{ status?: Status, endedAt?: Date }> } */
const StatusIcon = ({ status, endedAt }) => {
    switch (status) {
        case 'Done':
            return <Icon icon="check" color="secondary" spacing="mr-3" />;

        case 'Error':
            return <Icon icon="exclamation-circle" color="error" spacing="mr-3" />;

        case 'Canceled':
            if (!endedAt) {
                return <InlineLoader color="interface" active />;
            }
            return <Icon icon="times-circle" color="interface" spacing="mr-3" />;

        case 'Pending': // break intentionally omitted
        case 'Running':
            return <InlineLoader color="interface" active />;

        default:
            return null;
    }
};

export default WorkbenchQuerySelector;
