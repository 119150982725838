import React, { useState } from 'react';

import {
    Dropdown,
    ToggleButton,
    Tooltip,
    List,
    ListItem,
    Icon,
} from 'ui-components';

import delay from '../../lib/delay';
import { reportError } from '../../lib/error-reporter';
import { downloadDashboardPDF } from './dashboard-pdf';

/** @typedef { import('./types').SharingItem } SharingItem */

/**
 * @typedef { object } ShareDropdownProps
 * @prop { string } title
 * @prop { string } [reportId]
 * @prop { (sharingItem?: SharingItem) => void } toggleShareDialog
 * @prop { string } [spacing]
 * @prop { boolean } [compact]
 */

/** @type { React.FC<ShareDropdownProps> } */
const ShareDropdown = ({
    title,
    reportId,
    spacing,
    compact,
    toggleShareDialog,
}) => {
    const [loading, setLoading] = useState(false);

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(open => !open);
    };

    const download = async () => {
        setDropdownOpen(false);
        setLoading(true);

        await delay(300); // wait for the dropdown to close

        try {
            await downloadDashboardPDF(title, reportId);
        } catch (/** @type { any } */ error) {
            reportError(error, { title, reportId });
        } finally {
            setLoading(false);
        }
    };

    const share = async () => {
        setDropdownOpen(false);
        toggleShareDialog({ title, reportId });
    };

    return (
        <Dropdown
            opener={
                <Tooltip
                    className="tooltip-wrap"
                    content={compact ? 'Share' : ''}
                    interactive={false}
                    placement="top"
                >
                    <ToggleButton
                        type="plain"
                        active={dropdownOpen}
                        loading={loading}
                        onClick={toggleDropdown}
                        spacing={spacing}
                        {...compact ? {
                            square: true,
                        } : {
                            leftIcon: 'share-nodes',
                        }}
                    >
                        {compact ? <Icon icon="share-nodes" size="sm" /> : 'Share'}
                    </ToggleButton>
                </Tooltip>
            }
            open={dropdownOpen}
            onClose={toggleDropdown}
            placement="bottom-right"
        >
            <List>
                <ListItem
                    label="Download"
                    onClick={download}
                    button
                />
                <ListItem
                    label="Share"
                    onClick={share}
                    button
                />
            </List>
        </Dropdown>
    );
};

export default ShareDropdown;
