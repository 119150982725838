import React from 'react';

import {
    useSession,
} from '../shared/context-providers';

/**
 * @typedef { object } Props
 * @prop { ('bigquery' | 'redshift')= } databaseType
 * @prop { boolean= } not
 * @prop { React.ReactNode= } children
 */

/** @type { React.FC<Props> } */
const Feature = ({ databaseType, not, children }) => {

    const [session] = useSession();

    const databaseTypeMatches = not
        ? databaseType !== session.database.type
        : databaseType === session.database.type;

    if (databaseType && !databaseTypeMatches) {
        return null;
    }

    return (
        <>{children}</>
    );
};

export default Feature;
