import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import {
    ToggleButton,
    Dropdown,
    Icon,
    List,
    ListItem,
    breakpoints,
} from 'ui-components';

/** @typedef { import('../../app/__types').Session } Session */
/** @typedef { import('../../app/__types').SessionActions } SessionActions */
/** @typedef { import('./__types').Database } Database */

/**
 * @typedef { object } Props
 * @prop { Session['database'] } database
 * @prop { Database[] } databases
 * @prop { string } [spacing]
 * @prop { SessionActions['changeDatabase'] } changeDatabase
 */

/** @type { React.FC<Props> } */
const WarehousesMenu = ({
    database,
    databases,
    changeDatabase,
    ...props
}) => {
    const [open, setOpen] = useState(false);

    const toggleOpen = useCallback(() => {
        setOpen(!open);
    }, [open]);

    if (!database.id) {
        return null;
    }

    if (!databases.length) {
        return <WarehousesToggle database={database} {...props} />;
    }

    return (
        <Dropdown
            id="warehouses"
            opener={(
                <WarehousesToggle
                    database={database}
                    active={open}
                    onClick={toggleOpen}
                    {...props}
                />
            )}
            open={open}
            onClose={toggleOpen}
        >
            <StyledList>
                {databases.map((db) => {
                    const suspended = db.suspended || db.suspending;
                    const { selected } = db;

                    const onClicked = () => {
                        if (suspended || selected) {
                            return;
                        }

                        changeDatabase(db.id);
                    };

                    return (
                        <ListItem
                            key={db.id}
                            button
                            disabled={suspended || selected}
                            caption={suspended ? 'Suspended (contact support)' : null}
                            iconName={selected ? 'check' : 'database'}
                            prefix={selected ? 'far' : 'fas'}
                            onClick={onClicked}
                            label={db.name}
                        />
                    );
                })}
            </StyledList>
        </Dropdown>
    );
};

/**
 * @typedef { object } ToggleProps
 * @prop { Database } database
 * @prop { boolean } [active]
 * @prop { () => void } [onClick]
 */

const WarehousesToggle = styled(({ database, ...props }) => (
    <ToggleButton type="outline" color="secondaryText" {...props}>
        <Icon icon="database" prefix="fas" />

        <VisibleOnLargeScreen>
            {database.name}
        </VisibleOnLargeScreen>
    </ToggleButton>
))`
    max-width: 10rem;

    @media (min-width: ${breakpoints.xl}) {
        max-width: 15rem;
    }
`;

const VisibleOnLargeScreen = styled('span')`
    display: none;
    font-weight: normal;

    @media (min-width: ${breakpoints.lg}) {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 0.375rem;
    }
`;

const StyledList = styled(List)`
    min-width: 15rem;
`;

export default WarehousesMenu;
