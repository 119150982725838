import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
    Button,
    Dialog,
    Layout,
    Typography,
    Switch,
    Loader,
    Callout,
} from 'ui-components';

import { reportError } from '../../lib/error-reporter';

/** @typedef { import('models/database-objects/__types').DatabaseObject } DatabaseObject */
/** @typedef { import('models/database-objects/__types').View } View */

/**
 * @typedef { object } Props
 * @prop { boolean } open
 * @prop { (refreshPage?: boolean) => void } onClose
 * @prop { DatabaseObject[] | undefined } objectsToDelete
 * @prop { (object: DatabaseObject) => Promise<View[]> } fetchObjectDependencies
 * @prop { (object: DatabaseObject) => Promise<void> } deleteObject
 */

/**
 * @typedef { object } DeleteReq
 * @prop { DatabaseObject } originalObject
 * @prop { DatabaseObject[] } dependencies
 * @prop { string } loadingDependenciesError
 * @prop { string } error
 * @prop {(
 *   'initial' |
 *   'deleting' |
 *   'deleted' |
 *   'loadedDependencies' |
 *   'loadingDependencies' |
 *   'loadingDependenciesError' |
 *   'error'
 * )} status
 */

/** @type { React.FC<Props> } */
const BatchDeleteObjectsDialog = ({
    open, onClose, objectsToDelete, deleteObject, fetchObjectDependencies,
}) => {

    const [deleteReqs, setDeleteReqs] = useState(
        /** @type { DeleteReq[] } */ ([])
    );

    const [deleteStarted, setDeleteStarted] = useState(false);

    const [didConfirm, setDidConfirm] = useState(false);

    const allDepsLoaded = deleteReqs.every((req) => (
        req.status === 'loadedDependencies'
    ));

    const deleting = deleteReqs.some((req) => req.status === 'deleting');

    const allFinished = deleteReqs.every((req) => (
        req.status === 'deleted' || req.status === 'error'
    ));

    const erroredReqs = deleteReqs.filter((req) => req.error);

    const numberOfObjects = objectsToDelete?.length || 0;

    useEffect(() => {

        if (!open || !objectsToDelete?.length) {
            return;
        }

        if (open && (!objectsToDelete || !objectsToDelete.length)) {
            reportError(
                new Error('Batch delete dialog opened with no objects to delete'),
            );
        }

        /** @type { DeleteReq[] } */
        const deleteReqs = objectsToDelete.map((object) => ({
            originalObject: object,
            dependencies: [],
            loadingDependenciesError: '',
            error: '',
            status: 'initial',
        }));

        setDeleteReqs(deleteReqs);
        setDidConfirm(false);
        setDeleteStarted(false);

        deleteReqs.forEach(async (req, i) => {
            try {
                setDeleteReqs((prevReqs) => {
                    prevReqs[i].status = 'loadingDependencies';
                    return [...prevReqs];
                });
                const deps = await fetchObjectDependencies(req.originalObject);
                setDeleteReqs((prevReqs) => {
                    prevReqs[i].dependencies = deps;
                    prevReqs[i].status = 'loadedDependencies';
                    return [...prevReqs];
                });
            } catch (err) {
                reportError(
                    err,
                    { objectId: req.originalObject.id },
                );
                setDeleteReqs((prevReqs) => {
                    prevReqs[i].loadingDependenciesError = err.data?.message || err.status;
                    prevReqs[i].status = 'loadingDependenciesError';
                    return [...prevReqs];
                });
            }
        });

    }, [open, objectsToDelete, setDeleteReqs]);

    const onDeleteClicked = async () => {
        if (!allDepsLoaded) {
            return;
        }
        setDeleteStarted(true);
        deleteReqs.forEach(async (req, i) => {
            try {
                setDeleteReqs((prevReqs) => {
                    prevReqs[i].status = 'deleting';
                    return [...prevReqs];
                });
                await deleteObject(req.originalObject);
                setDeleteReqs((prevReqs) => {
                    prevReqs[i].status = 'deleted';
                    return [...prevReqs];
                });
            } catch (err) {
                reportError(err, { objectId: req.originalObject.id });
                setDeleteReqs((prevReqs) => {
                    prevReqs[i].error = err.data?.message || err.status;
                    prevReqs[i].status = 'error';
                    return [...prevReqs];
                });
            }
        });
    };

    /** @type { (e: React.ChangeEvent<HTMLInputElement>) => void} */
    const onChangeConfirm = (e) => {
        setDidConfirm(e.target.checked);
    };

    const closeDialog = () => {
        onClose();
    };

    const closeDialogAndRefresh = () => {
        onClose(true);
    };

    const actions = (
        <>
            {!deleteStarted && (
                <Button onClick={closeDialog}>Cancel</Button>
            )}
            {!allFinished && (
                <Button
                    color="primary"
                    spacing="mr-0"
                    disabled={!didConfirm}
                    loading={deleting}
                    onClick={onDeleteClicked}
                >
                    Delete
                </Button>
            )}
            {allFinished && (
                <Button
                    color="primary"
                    spacing="mr-0"
                    onClick={closeDialogAndRefresh}
                >
                    Finish
                </Button>
            )}
        </>
    );

    const title = allFinished
        ? `Deleted ${numberOfObjects - erroredReqs.length} items`
        : `Delete ${numberOfObjects} Items`;

    const numberOfDeleted = numberOfObjects - erroredReqs.length;

    return (
        <Dialog
            title={title}
            isOpen={open}
            onClose={closeDialog}
            actions={actions}
        >

            {allFinished ? (
                <>
                    <Typography variant="subtitle1" weight="medium">
                        {numberOfDeleted} items deleted with{' '}
                        {erroredReqs.length} errors.
                    </Typography>

                    {erroredReqs?.map((req) => (
                        <DeleteCallout key={req.originalObject.id} req={req} />
                    ))}

                    {numberOfDeleted !== 0 && (
                        <Callout
                            color="default"
                            spacing="mt-4"
                        >
                            <Typography
                                component="span"
                                variant="subtitle1"
                                spacing="ml-2 mb-0"
                            >
                                Successfully deleted:
                                {deleteReqs.map((req) => {
                                    if (req.status !== 'deleted') {
                                        return null;
                                    }
                                    return (
                                        <Typography
                                            key={req.originalObject.id}
                                            color="text"
                                            weight="medium"
                                        >
                                            {req.originalObject.name}
                                        </Typography>
                                    );
                                })}
                            </Typography>
                        </Callout>
                    )}

                </>
            ) : (
                <>
                    <Typography variant="subtitle1" weight="medium">
                        Are you sure you want to delete {numberOfObjects} items?
                    </Typography>

                    {deleteReqs?.map((req) => (
                        <DeleteCallout key={req.originalObject.id} req={req} />
                    ))}

                    <Switch
                        type="checkbox"
                        checked={didConfirm}
                        disabled={!allDepsLoaded}
                        onChange={onChangeConfirm}
                        label={
                            `Yes, permanently remove ${numberOfObjects} tables and all related data`
                        }
                        spacing="mt-5"
                    />
                    {didConfirm && (
                        <Typography color="error" variant="body1" spacing="mt-2 ml-2">
                            Warning: This action cannot be undone.
                        </Typography>
                    )}
                </>
            )}

        </Dialog>
    );
};


/**
 * @typedef { object } DeleteCalloutProps
 * @prop { DeleteReq } req
 */

/** @type { React.FC<DeleteCalloutProps> } */
const DeleteCallout = ({ req }) => {

    const {
        loadingDependenciesError,
        error,
        originalObject,
        dependencies,
        status,
    } = req;

    const getCalloutColor = () => {
        if (error || loadingDependenciesError) {
            return 'error';
        }
        if (dependencies.length) {
            return 'warning';
        }
        return 'default';
    };

    return (
        <Callout
            color={getCalloutColor()}
            spacing="mt-4"
        >

            {status === 'deleted' && (
                <Typography
                    width="100"
                    component="span"
                    variant="subtitle1"
                    spacing="ml-2 mb-0"
                >
                    Deleted <b>{originalObject.name}</b>
                </Typography>
            )}

            {status === 'deleting' && (
                <Layout flex alignItems="center">
                    <InlineLoader
                        relative
                        active
                    />
                    <Typography
                        width="100"
                        component="span"
                        variant="subtitle1"
                        spacing="ml-2 mb-0"
                    >
                        Deleting <b>{originalObject.name}</b>
                    </Typography>
                </Layout>
            )}

            {status === 'loadingDependencies' && (
                <Layout flex alignItems="center">
                    <InlineLoader
                        relative
                        active
                    />
                    <Typography
                        width="100"
                        component="span"
                        variant="subtitle1"
                        spacing="ml-2 mb-0"
                    >
                        Loading dependencies for <b>{originalObject.name}{' '}</b>
                    </Typography>
                </Layout>
            )}

            {status === 'loadingDependenciesError' && (
                <>
                    <Typography
                        variant="subtitle1"
                    >
                        There was an error fetching dependencies for
                        {' '}<b>{originalObject.name}</b>
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        spacing="mb-0"
                        color="error"
                    >
                        {loadingDependenciesError}
                    </Typography>
                </>
            )}

            {status === 'error' && (
                <>
                    <Typography
                        variant="subtitle1"
                    >
                        There was an error deleting <b>{originalObject.name}{' '}</b>
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        spacing="mb-0"
                        color="error"
                    >
                        {error}
                    </Typography>
                </>
            )}

            {status === 'loadedDependencies' && (
                <>
                    {dependencies.length ? (
                        <>
                            <Typography component="span" color="text" variant="subtitle1">
                                <b>{req.originalObject.name}{' '}</b>
                                has dependent views that will also be deleted:
                            </Typography>
                            {req.dependencies.map(dep => (
                                <Typography
                                    key={dep.id}
                                    component="span"
                                    color="text"
                                    weight="medium"
                                >
                                    {dep.name}
                                </Typography>
                            ))}
                        </>
                    ) : (
                        <Typography component="span" variant="subtitle1" spacing="mb-0">
                            <b>{req.originalObject.name}{' '}</b>
                            will be deleted
                        </Typography>
                    )}
                </>
            )}
        </Callout>
    );
};


const InlineLoader = styled(Loader)`
    && {
        width: 40px;
    }
`;

export default BatchDeleteObjectsDialog;
