import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import {
    Loader,
    Typography,
    Layout,
    Button,
} from 'ui-components';

import cdnIcon from '../../lib/cdn-icon';
import openSupportChat from '../../shared/use-support-chat';

/** @typedef { import('models/sources/__types').Source } Source */
/** @typedef { import('models/sources/__types').CollectJob } CollectJob */
/** @typedef { import('./__types').SourceCardTab } SourceCardTab */
/** @typedef { import('models/recipes').Recipe } Recipe */

/**
 * @typedef { object } Props
 * @prop { boolean } collecting
 * @prop { CollectJob['status']= } status
 * @prop { boolean? } canUseRecipes
 * @prop { Source['id'] } sourceId
 * @prop { Recipe[] | undefined } recipes
 */

/** @type { React.FC<Props> } */
const CollectOverlay = ({ collecting, status, canUseRecipes, sourceId, recipes }) => {

    const [showOverlay, setShowOverlay] = useState(false);
    const prevCollecting = useRef(false);

    const showErrorOverlay = showOverlay
        && ['error', 'ext_error'].includes(status || '');

    const showSuccessOverlay = showOverlay
        && status === 'success';

    const removeOverlay = () => {
        setShowOverlay(false);
    };

    const contactSupport = () => {
        openSupportChat('I am having an issue with one of my connectors');
    };

    // Manually track if the source was collecting
    // So we only show the finishing overlay if the user actual saw the 'collecting' state
    useEffect(() => {
        // If we were collecting before but we arent now
        if (!collecting && prevCollecting.current) {
            setShowOverlay(true);
        }
        prevCollecting.current = collecting;
    }, [collecting, showOverlay]);

    const actions = (
        <>
            <Layout flex spacing="mt-7 mb-4">
                {recipes?.length !== 0 && (
                    <Button
                        link={`/sources/${sourceId}/recipes`}
                        disabled={collecting || !canUseRecipes}
                        type="outline"
                        color="primary"
                    >
                        Data Recipes for this source
                    </Button>
                )}
                <Button type="outline" color="primary" link="/sources/new/">
                    Add another connector
                </Button>
            </Layout>
            <Button disabled={collecting} onClick={removeOverlay} type="plain">
                Return to settings
            </Button>
        </>
    );

    return (
        <>
            {collecting && (
                <AnimatedOverlay>
                    <Loader
                        dark
                        big
                        active={true}
                        color="text"
                        icon="lock"
                    >
                        <Typography spacing="mt-5">
                            Your connector is collecting...
                        </Typography>
                        {actions}
                    </Loader>
                </AnimatedOverlay>
            )}
            {!collecting && showErrorOverlay && (
                <AnimatedOverlay type="error">
                    <OverlayIcon src={cdnIcon('icon-error.svg')} />
                    <Typography variant="body2" spacing="mt-5">
                        We had a problem collecting your data.
                    </Typography>
                    <Button
                        type="outline"
                        color="primary"
                        spacing="mt-7 mb-4"
                        onClick={contactSupport}
                    >
                        Talk to support
                    </Button>
                    <Button disabled={collecting} onClick={removeOverlay} type="plain">
                        Return to settings
                    </Button>
                </AnimatedOverlay>
            )}
            {!collecting && showSuccessOverlay && (
                <AnimatedOverlay type="success">
                    <OverlayIcon src={cdnIcon('icon-success.svg')} />
                    <Typography variant="body2" spacing="mt-5">
                        Collection successful.
                    </Typography>
                    {actions}
                </AnimatedOverlay>
            )}
        </>
    );
};

const AnimatedOverlay = styled('div')`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 1;
    ${({ type }) => {
        if (type === 'success') {
            return `
                background: linear-gradient(90deg, #EAFFF3 0%, #EAF2FF 51.56%, #FCF0FF 99.99%);
            `;
        }
        if (type === 'error') {
            return 'background: linear-gradient(90deg, #FCF0FF 0%, #FFEFF0 100%);';
        }
        return `
            background: #F2F3F8;
            opacity: 0.97;
        `;
    }}
    top: 0px;
    left: 0px;
    pointer-events: all;
    z-index: 100;
`;

const OverlayIcon = styled('img')`
    display: block;
    width: 64px;
    height: 64px;
`;

export default CollectOverlay;
