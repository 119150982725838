import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useSession } from '../../shared/context-providers';
import WorkbenchTable from './workbench-table';
import WorkbenchChart from './workbench-chart';
import { spreadsheetTheme } from 'ui-components';

/** @typedef { import('models/queries').QueryResultData } QueryResultData */
/** @typedef { import('./__types').VisualizationType } VisualizationType */
/** @typedef { import('./__types').VisualizationReport } VisualizationReport */
/** @typedef { import('./__types').VisualizationItem } VisualizationItem */
/** @typedef { import('./__types').WorkbenchParams } WorkbenchParams */
/** @typedef { import('./__types').ReportData } ReportData */

/**
 * @type { () => Record<string, { label: string }> } Returned a map of custom colors for the table,
 * keys are hex colors and values are strings.
 * For example:
 * ```
 * {
 *     '#000000': { label: 'custom-black' },
 *     '#ffffff': { label: 'custom-white' }
 * }
 * ```
 */
const getTableColors = () => {
    const customKeys = Object.keys(spreadsheetTheme).filter(key => key.includes('table'));
    return customKeys.reduce((acc, key) => {
        const {
            backgroundColor,
            secondaryBackgroundColor,
        } = spreadsheetTheme[key];
        acc[`${secondaryBackgroundColor}-${backgroundColor}`] = { label: key };
        return acc;
    }, {});
};

/** @type { Record<VisualizationType, VisualizationItem> } */
export const items = {
    'table': {
        icon: 'table-list',
        title: 'Table',
        help: '**Table**',
        config: {
            colorPalette: {
                disabled: () => false,
                component: 'color-bar',
                label: 'Color palette',
                default: 'table-text',
                items: getTableColors(),
            },
            showBorders: {
                disabled: () => false,
                component: 'switch',
                label: 'Show borders',
                default: false,
                items: {
                    true: {
                        label: 'true',
                    },
                    false: {
                        label: 'false',
                    },
                },
            },
        },
        isDisabled: () => false,
        Component: WorkbenchTable,
    },
    'single-value': {
        icon: 'square-1',
        title: 'Single Value',
        help: '**Single value**: Must be single numeric value.',
        config: {
            vAxisFormat: {
                disabled: () => false,
                component: 'dropdown',
                label: 'Value type',
                default: 'number',
                items: {
                    'number': {
                        icon: '2',
                        label: 'Number (100,000)',
                    },
                    'short-number': {
                        icon: '2',
                        label: 'Short Number (100K)',
                    },
                    'currency': {
                        icon: 'dollar-sign',
                        label: 'Currency ($100,000)',
                    },
                    'short-currency': {
                        icon: 'dollar-sign',
                        label: 'Short Currency ($100K)',
                    },
                    'percent': {
                        icon: 'percent',
                        label: 'Percent (10%)',
                    },
                },
            },
            threshold: {
                disabled: () => false,
                component: 'input',
                label: 'Threshold',
                default: '',
                info: 'Example: 15,000',
                items: {},
            },
            positiveThreshold: {
                disabled: ({ threshold }) => !threshold,
                component: 'checkbox',
                label: 'Positive threshold',
                default: false,
                info: 'Values above the threshold will be green',
                items: {
                    true: {
                        label: 'true',
                    },
                    false: {
                        label: 'false',
                    },
                },
            },
            negativeThreshold: {
                disabled: ({ threshold }) => !threshold,
                component: 'checkbox',
                label: 'Negative threshold',
                default: false,
                info: 'Values below the threshold will be red',
                items: {
                    true: {
                        label: 'true',
                    },
                    false: {
                        label: 'false',
                    },
                },
            },

        },
        isDisabled: ({ count, rows }) => {
            if (count !== 1 || rows?.length !== 1) {
                return true;
            }
            const values = Object.values(rows[0]);
            return values.length !== 1 || !isNumeric(values[0]);
        },
        Component: WorkbenchChart,
    },
    'pie-chart': {
        icon: 'chart-pie-alt',
        title: 'Pie Chart',
        help: '**Pie chart**: 2 columns. Second column must be numeric.',
        isDisabled: ({ count, rows }) => {
            if (!count || !rows?.length) {
                return true;
            }
            const values = Object.values(rows[0]);
            return values.length !== 2 || !isNumeric(values[1]);
        },
        Component: WorkbenchChart,
    },
    'column-chart': {
        icon: 'chart-column',
        title: 'Column Chart',
        help: '**Column chart**: 2-4 columns max. '
            + 'Second, third and fourth columns must be numeric.',
        isDisabled: ({ count, rows }) => {
            if (!count || !rows?.length) {
                return true;
            }
            const values = Object.values(rows[0]);
            return values.length < 2
                || values.length > 4
                || !values.slice(1).every(isNumeric);
        },
        Component: WorkbenchChart,
    },
    'line-chart': {
        icon: 'chart-line',
        title: 'Line Chart',
        help: '**Line chart**: 2-4 columns max. '
            + 'Second, third and fourth columns must be numeric.',
        isDisabled: ({ count, rows }) => {
            if (!count || !rows?.length) {
                return true;
            }
            const values = Object.values(rows[0]);
            return values.length < 2
                || values.length > 4
                || !values.slice(1).every(isNumeric);
        },
        Component: WorkbenchChart,
    },
};

/**
 * @typedef { object } Props
 * @prop { ReportData[] } reports
 * @prop { boolean } queryIsRunning
 * @prop { QueryResultData } [queryData]
 */

/**
 * @param { Props } props
 */
const useWorkbenchVisualization = ({
    reports,
    queryIsRunning,
    queryData,
}) => {
    /** @type { WorkbenchParams } */
    const { id, type } = useParams();
    const [session] = useSession();
    const { user, database } = session;

    const report = useMemo(() => (
        getInitialReport(id, type, reports)
    ), [id, type, reports]);

    const [visualizationReport, setVisualizationReport] = useState(
        () => getVisualizationReport({
            report,
            queryData,
            useCustomVisualization: database?.__customVisualizationBehavior,
        })
    );

    const reportIdRef = useRef(getReportId(visualizationReport));

    /** @type { (changes: Partial<VisualizationReport>) => void }  */
    const onVisualizationReportChanged = useCallback(changes => {
        setVisualizationReport(r => {
            const newReport = { ...r, ...changes };

            if (changes?.type) {
                newReport.userSelectedType = true;
            }

            reportIdRef.current = getReportId(newReport);

            return newReport;
        });
    }, [database, user]);

    useEffect(() => {
        if (queryIsRunning || !queryData) {
            return;
        }

        const newReport = getVisualizationReport({
            report,
            queryData,
            visualizationReport,
            useCustomVisualization: database?.__customVisualizationBehavior,
        });

        const reportId = getReportId(newReport);

        if (reportId === reportIdRef.current) {
            return;
        }

        reportIdRef.current = reportId;

        setVisualizationReport(newReport);
    }, [report, queryIsRunning, queryData]);

    return {
        visualizationReport,
        onVisualizationReportChanged,
    };
};

/**
 * @param { WorkbenchParams['id'] } [id]
 * @param { WorkbenchParams['type'] } [type]
 * @param { ReportData[] } [reports]
 * @returns { ReportData | undefined }
 */
const getInitialReport = (id, type, reports) => {
    if (id && type === 'reports') {
        const report = reports?.find(r => r.id === id);

        if (report) {
            return report;
        }
    }

    return undefined;
};

/**
 * @typedef { object } GetVisualizationReportParams
 * @prop { ReportData } [report]
 * @prop { QueryResultData } [queryData]
 * @prop { VisualizationReport } [visualizationReport]
 * @prop { boolean } [useCustomVisualization]
 */


/** @type { (args: GetVisualizationReportParams) => VisualizationReport } */
const getVisualizationReport = ({
    report,
    queryData,
    visualizationReport,
    useCustomVisualization = false,
}) => {
    return useCustomVisualization
        ? customVisualization(report, queryData, visualizationReport)
        : defaultVisualization(report, queryData, visualizationReport);
};

const geHighestChartType = (data) => {
    const chartTypes = Object.keys(items)
        .sort((a, b) => (a === 'table' ? 1 : b === 'table' ? -1 : 0)); // table last

    const enabledChartTypes = chartTypes.reduce((acc, type) => {
        acc[type] = !items[type].isDisabled(data);
        return acc;
    }, {});

    const type = chartTypes.find(type => enabledChartTypes[type]);

    if (type === 'column-chart'
        && data.rows.length > 10
        && enabledChartTypes['line-chart']) {
        return { type: 'line-chart' };
    }

    return { type };
};

/**
 * @param { ReportData } [report]
 * @param { QueryResultData } [queryData]
 * @param { VisualizationReport } [visualizationReport]
 * @returns { VisualizationReport }
 */
const defaultVisualization = (report, queryData, visualizationReport) => {
    if (report && queryData) {
        if (report.id === visualizationReport?.id) {
            const { type } = visualizationReport;

            if (!items[type].isDisabled(queryData)) {
                return { ...report, type };
            }
        }

        if (!items[report.type].isDisabled(queryData)) {
            return report;
        }
    }

    if (visualizationReport && queryData) {
        if (visualizationReport?.userSelectedType
            && !items[visualizationReport?.type]?.isDisabled(queryData)) {
            return { type: visualizationReport.type, userSelectedType: true };
        } else {
            const { type } = geHighestChartType(queryData);
            return { type, userSelectedType: false };
        }
    }


    return { type: 'line-chart', userSelectedType: false };
};

/**
 * @param { ReportData } [report]
 * @param { QueryResultData } [queryData]
 * @param { VisualizationReport } [visualizationReport]
 * @returns { VisualizationReport }
 */
const customVisualization = (report, queryData, visualizationReport) => {
    if (report && queryData) {
        if (report.id === visualizationReport?.id) {
            const { type } = visualizationReport;

            if (!items[type].isDisabled(queryData)) {
                return { ...report, type };
            }
        }

        if (!items[report.type].isDisabled(queryData)) {
            return report;
        }
    }

    if (visualizationReport && queryData) {
        const { type } = visualizationReport;

        if (!items[type].isDisabled(queryData)) {
            return { type };
        }
    }

    return { type: 'table' };
};
/** @type { (report: VisualizationReport) => string } */
const getReportId = (report) => {
    return `${report.id || 'new-' + uuidv4()}-${report.type}`;
};

/** @type { (n: any) => boolean } */
const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

export default useWorkbenchVisualization;
