import { useState, useEffect, useCallback } from 'react';
import { useNavBreadCrumbs } from '../../../shared/context-providers';

const useNavBreadCrumbsState = () => {
    const {
        navBreadCrumbItems,
        setNavBreadCrumbItems,
    } = useNavBreadCrumbs(false);

    const [initialLoad, setInitialLoad] = useState(true);
    const [showBreadCrumbNav, setShowBreadCrumbNav] = useState(true);
    const [navIsHovered, setNavIsHovered] = useState(false);
    const [showNavTimeout, setShowNavTimeout] = useState(0);

    const clearShowNavTimeout = useCallback(() => {
        if (showNavTimeout) {
            clearTimeout(showNavTimeout);
            setShowNavTimeout(0);
        }
    }, [showNavTimeout]);

    useEffect(() => {

        if (initialLoad) {
            setInitialLoad(false);
            return;
        }

        if (navIsHovered) {
            clearShowNavTimeout();
            return;
        }

        if (!showBreadCrumbNav && !showNavTimeout) {
            setShowNavTimeout(setTimeout(() => setShowBreadCrumbNav(true), 1200));
        }
    }, [showBreadCrumbNav, navIsHovered, showNavTimeout, initialLoad]);

    return {
        navBreadCrumbItems,
        setNavBreadCrumbItems,
        showBreadCrumbNav,
        setShowBreadCrumbNav,
        navIsHovered,
        setNavIsHovered,
    };
};

export default useNavBreadCrumbsState;
