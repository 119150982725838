import { useMemo } from 'react';

/** @typedef { import('lib/query-builder').Column } Column */
/** @typedef { import('lib/query-builder').JoinTable } JoinTable */
/** @typedef { import('lib/query-builder').Tables } Tables */

/** @type { (allColumns: Column[], joinTable: JoinTable) => boolean } */
function hasColumns(allColumns, joinTable) {
    return allColumns.some(column => {
        return column.table === joinTable.name
            && column.schema === joinTable.schema;
    });
}

/** @type { (allTables: Tables, joinTable: JoinTable) => boolean } */
function hasFilters(allTables, joinTable) {
    const [, ...joinTables] = allTables;
    const { name, schema, alias } = joinTable;

    return joinTables.some(table => {
        return table.alias !== alias
            && table.join.on.some(filter => {
                const { column, value } = filter;
                return (column.table === name && column.schema === schema)
                    || (value?.table === name && value.schema === schema);
            });
    });
}

/**
 * @typedef { object } Props
 * @prop { Column[] } allColumns
 * @prop { Tables } allTables
 * @prop { JoinTable } [joinTable]
 * @prop { number } [position]
 */

/** @param { Props } props */
function useJoinTable({ allColumns, allTables, joinTable, position }) {
    const noFields = useMemo(() => {
        if (!joinTable || position == null) {
            return false;
        }
        return !hasColumns(allColumns, joinTable)
            && !hasFilters(allTables, joinTable);
    }, [allColumns, allTables, joinTable, position]);

    return {
        noFields,
    };
}

export default useJoinTable;
