import React from 'react';
import styled from 'styled-components';
import {
    ExpansionPanel,
    Tooltip,
    Typography,
    Layout,
    Icon,
} from 'ui-components';

import MarkdownRenderer from '../../shared/markdown-renderer';

/**
 * @typedef { object } Props
 *
 * @prop { string } label
 * @prop { string= } tooltipContent
 * @prop { React.ReactNode } headerContent
 * @prop { React.ReactNode } children
 */

/** @type { React.FC<Props> } */
const FormPanel = ({
    label,
    tooltipContent,
    headerContent,
    children,
}) => (
    <Layout spacing="mb-4" width="">
        <ExpansionPanel
            flat
            spacing="mb-4"
            labelComponent={(
                <Layout flex width="100" alignItems="center">
                    <LabelWrapper flex alignItems="center">
                        <Typography spacing="m-0" variant="body1" noWrap>
                            {label}
                        </Typography>
                        {tooltipContent && (
                            <Tooltip content={<MarkdownRenderer source={tooltipContent} />}>
                                <Icon icon="info-circle" spacing="ml-2" color="secondaryText" />
                            </Tooltip>
                        )}
                    </LabelWrapper>
                    <Layout spacing="ml-3" flex alignItems="center">
                        {!!headerContent && headerContent}
                    </Layout>
                </Layout>
            )}
        >
            <Layout relative width="100">
                {children}
            </Layout>

        </ExpansionPanel>
    </Layout>
);

const LabelWrapper = styled(Layout)`
    min-width: 210px;
`;

export default FormPanel;
