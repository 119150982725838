import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
    Dialog,
    Divider,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    CodeEditor,
    Typography,
    Select,
    SelectItem,
    Loader,
    Icon,
    Layout,
    Autocomplete,
} from 'ui-components';

import { getDefaultSchema } from '../../models/schemas';
import { useSession } from '../context-providers';
import { items as visualizationItems } from './use-workbench-visualization';
import QuerySelectorDropdown from './query-selector-dropdown';
import useWorkbenchViews from './use-workbench-views';
import useWorkbenchReports from './use-workbench-reports';

/** @typedef { import('app/__types').Database } Database */
/** @typedef { import('models/queries/__types').QueryResult } QueryResult */
/** @typedef { import('models/schemas/__types').Schema } Schema */
/** @typedef { import('models/reports/types').Report } Report */
/** @typedef { import('./__types').WorkbenchParams } WorkbenchParams */
/** @typedef { import('./__types').VisualizationType } VisualizationType */
/** @typedef { import('./__types').VisualizationConfig } VisualizationConfig */
/** @typedef { import('./__types').VisualizationReport } VisualizationReport */
/** @typedef { import('./__types').ViewData } ViewData */
/** @typedef { import('./__types').TableData } TableData */
/** @typedef { import('./__types').SchemaData } SchemaData */
/** @typedef { import('./__types').ReportData } ReportData */
/** @typedef { 'report' | 'view' | '' } Mode */
/** @typedef { 'error' | 'success' | '' } Status */
/** @typedef { { title: string, subtitle?: string } } Option */
/** @typedef { import('./use-workbench-data').WorkbenchData } WorkbenchData */

/**
 * @typedef { import('app/__types').ChangeEvent<T> } ChangeEvent
 * @template [T=string]
 */

/** @type { (params: Params, schema: SchemaData, databaseType: Database['type']) => boolean } */
function isViewSchemaSelected(params, schema, databaseType) {
    return schema.name === (params.schema || getDefaultSchema(databaseType));
}

/** @type { (params: Params, views: ViewData[]) => boolean } */
function isViewNameTaken(params, views) {
    return views.some(v => v.name === params.name
        && v.schemaname === params.schema);
}

/** @type { (params: Params, tables: TableData[]) => boolean } */
function isTableNameTaken(params, tables) {
    return tables.some(t => t.name === params.name
        && t.schema === params.schema);
}

/** @type { (params: Params, reports: ReportData[]) => Report | undefined } */
function findReport(params, reports) {
    return reports.find(r => r.title === params.name);
}

/** @type { (status: Status) => React.ReactElement | null } */
const getStatusElement = (status) => {
    switch (status) {
        case 'error':
            return (
                <Status key="status">
                    <Icon
                        prefix="fas"
                        icon="exclamation-circle"
                        size="sm"
                        color="error"
                        spacing="mr-2"
                    />
                    <Help>
                        Error, can&apos;t save
                    </Help>
                </Status>
            );

        case 'success':
            return (
                <Status key="status">
                    <Icon
                        icon="check"
                        size="sm"
                        color="secondary"
                        spacing="mr-2"
                    />
                    <Help>
                        Saved
                    </Help>
                </Status>
            );

        default:
            return null;
    }
};

/**
 * @typedef { object } Params
 * @prop { string } [name]
 * @prop { Schema['name'] } [schema]
 * @prop { VisualizationType } [type]
 * @prop { VisualizationConfig } [config]
 * @prop { string } [sql]
 */

/**
 * @typedef { object } Props
 * @prop { boolean } saveAsDialogOpen
 * @prop { boolean } saveDialogOpen
 * @prop { () => void } toggleSaveAsDialog
 * @prop { () => void } toggleSaveDialog
 * @prop { VisualizationReport } visualizationReport
 * @prop { QueryResult[] } queryResults
 * @prop { QueryResult } [activeQueryResult]
 * @prop { (activeQueryResult: QueryResult) => void } changeActiveQueryResult
 * @prop { Date } [queryStartedAt]
 * @prop { Date} [queryEndedAt]
 * @prop { ViewData[] } views
 * @prop { TableData[] } tables
 * @prop { SchemaData[] } schemas
 * @prop { ReportData[] } reports
 * @prop { boolean } loadingViews
 * @prop { boolean } loadingTables
 * @prop { boolean } loadingSchemas
 * @prop { boolean } loadingReports
 * @prop { WorkbenchData['fetchViews'] } fetchViews
 * @prop { WorkbenchData['fetchReports'] } fetchReports
 */

/** @type { React.FC<Props> } */
const QueryResultSaveAsDialog = ({
    saveAsDialogOpen,
    saveDialogOpen,
    toggleSaveAsDialog,
    toggleSaveDialog,
    visualizationReport,
    queryResults,
    activeQueryResult,
    changeActiveQueryResult,
    queryStartedAt,
    queryEndedAt,
    views,
    tables,
    schemas,
    reports,
    loadingViews,
    loadingTables,
    loadingSchemas,
    loadingReports,
    fetchViews,
    fetchReports,
}) => {
    /** @type { WorkbenchParams } */
    const { id, type } = useParams();

    const [session] = useSession();
    const { database } = session;
    const databaseType = database.type;

    const [status, setStatus] = useState(
        /** @type { Status } */ ('')
    );

    const [mode, setMode] = useState(
        /** @type { Mode } */ ('')
    );

    const [params, setParams] = useState(
        /** @type { Params } */ ({})
    );

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const {
        creatingView,
        editingView,
        createView,
        editView,
    } = useWorkbenchViews({ fetchViews });

    const {
        creatingReport,
        editingReport,
        createReport,
        editReport,
    } = useWorkbenchReports({ fetchReports });

    const open = saveAsDialogOpen || saveDialogOpen;

    const loading = mode === 'view'
        ? creatingView || editingView
        : creatingReport || editingReport;

    const disabled = (mode === 'view' ? [
        !params.name,
        loadingViews,
        loadingSchemas,
        loadingTables,
        isTableNameTaken(params, tables),
    ] : [
        !params.name,
        loadingReports,
    ]).some(v => v);

    /** @type { (e: ChangeEvent<Mode>) => void } */
    const toggleMode = e => {
        setMode(e.target.value);
        setStatus('');
    };

    const closeConfirmDialog = () => {
        setConfirmDialogOpen(false);
    };

    /** @type { (updates: Params) => void } */
    const updateParams = useCallback((updates) => {
        setParams(p => ({ ...p, ...updates }));
    }, []);

    const close = useCallback(() => {
        if (saveDialogOpen) {
            toggleSaveDialog();
        } else {
            toggleSaveAsDialog();
        }
        setParams({});
        setStatus('');
        setMode('');
    }, [saveDialogOpen, toggleSaveDialog, toggleSaveAsDialog]);

    const onCreateReportClicked = useCallback(async () => {
        const { name: title, type, config, sql } = params;

        setStatus('');

        if (!title || !type || !sql) {
            setStatus('error');
            return;
        }

        if (findReport(params, reports)) {
            setConfirmDialogOpen(true);
            return;
        }

        try {
            await createReport({
                title,
                type,
                config: { ...config },
                query: { type: 'sql', sql },
                theme: { name: 'panoply' },
            });

            setStatus('success');

        } catch (err) {
            setStatus('error');
        }
    }, [params, reports, createReport]);

    const onEditReportClicked = useCallback(async () => {
        const { name: title, type, config, sql } = params;

        setStatus('');
        setConfirmDialogOpen(false);

        if (!title || !type || !sql) {
            setStatus('error');
            return;
        }

        const { id } = findReport(params, reports) || {};

        if (!id) {
            onCreateReportClicked();
            return;
        }

        try {
            await editReport(id, {
                title,
                type,
                config: { ...config },
                query: { type: 'sql', sql },
            });

            setStatus('success');

        } catch (err) {
            setStatus('error');
        }
    }, [params, reports, editReport, onCreateReportClicked]);

    const onCreateViewClicked = useCallback(async () => {
        const { name, schema, sql } = params;

        setStatus('');

        if (!name || !schema || !sql) {
            setStatus('error');
            return;
        }

        if (isViewNameTaken(params, views)) {
            setConfirmDialogOpen(true);
            return;
        }

        try {
            await createView({
                name,
                schema,
                sql,
            });

            setStatus('success');

        } catch (err) {
            setStatus('error');
        }
    }, [params, views, createView]);

    const onEditViewClicked = useCallback(async () => {
        const { name, schema, sql } = params;

        setStatus('');
        setConfirmDialogOpen(false);

        if (!name || !schema || !sql) {
            setStatus('error');
            return;
        }

        if (!isViewNameTaken(params, views)) {
            onCreateViewClicked();
            return;
        }

        try {
            await editView({
                name,
                schema,
                sql,
            });

            setStatus('success');

        } catch (err) {
            setStatus('error');
        }
    }, [params, views, editView]);

    const [onCreateNewClicked, onOverrideClicked] = mode === 'view'
        ? [onCreateViewClicked, onEditViewClicked]
        : [onCreateReportClicked, onEditReportClicked];

    useEffect(() => {
        if (!open || !activeQueryResult?.query) {
            return;
        }

        if (type === 'views') {
            setMode('view');
        } else if (type === 'reports') {
            setMode('report');
        }

        updateParams({
            type: visualizationReport.type,
            config: visualizationReport.config,
            sql: activeQueryResult.query,
            schema: getDefaultSchema(databaseType),
        });
    }, [
        type,
        open,
        visualizationReport,
        activeQueryResult,
        databaseType,
        updateParams,
    ]);

    useEffect(() => {
        if (params.name || !id || !type || !saveDialogOpen) {
            return;
        }

        switch (true) {
            case type === 'reports' && mode === 'report': {
                const report = reports.find(r => r.id === id);

                if (report) {
                    updateParams({
                        name: report.title,
                    });
                    setConfirmDialogOpen(true);
                }

                break;
            }

            case type === 'views' && mode === 'view': {
                const view = views.find(v => v.id === id);

                if (view) {
                    updateParams({
                        name: view.name,
                        schema: view.schemaname,
                    });
                    setConfirmDialogOpen(true);
                }

                break;
            }
        }
    }, [
        id,
        type,
        mode,
        params.name,
        saveDialogOpen,
        reports,
        views,
        updateParams,
    ]);

    useEffect(() => {
        /** @type { (e: KeyboardEvent) => void } */
        const onKeyDown = e => {
            const activeTag = document.activeElement?.tagName.toLowerCase();

            if (e.key === 'Escape' && !loading) {
                e.preventDefault();
                if (confirmDialogOpen) {
                    setConfirmDialogOpen(false);
                } else if (open) {
                    close();
                }
            } else if (e.key === 'Enter' && activeTag !== 'input' && !loading && !disabled) {
                e.preventDefault();
                if (confirmDialogOpen) {
                    onOverrideClicked();
                } else if (open) {
                    onCreateNewClicked();
                }
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    }, [
        loading,
        disabled,
        confirmDialogOpen,
        open,
        close,
        onOverrideClicked,
        onCreateNewClicked,
    ]);

    return (
        <Dialog
            compact
            title={saveDialogOpen ? 'Save' : 'Save as'}
            isOpen={open}
            onClose={close}
            actions={[
                getStatusElement(status),
                <Button
                    key="cancel"
                    type="plain"
                    spacing="ml-0"
                    onClick={close}
                    disabled={loading}
                >
                    Cancel
                </Button>,
                <Button
                    key="save"
                    color="primary"
                    spacing="mr-0"
                    onClick={onCreateNewClicked}
                    disabled={disabled}
                    loading={loading}
                >
                    Save
                </Button>,
            ]}
        >
            {activeQueryResult && (
                <Layout flex flexDirection="column">
                    <QuerySelectorDropdown
                        fullWidth
                        queryResults={queryResults}
                        activeQueryResult={activeQueryResult}
                        changeActiveQueryResult={changeActiveQueryResult}
                        startedAt={queryStartedAt}
                        endedAt={queryEndedAt}
                    />
                </Layout>
            )}

            <Divider spacing="-mx-4 my-4" />

            <CodeEditorWrapper>
                <CodeEditor
                    readOnly
                    contents={activeQueryResult?.query || ''}
                    dbType={databaseType}
                />
            </CodeEditorWrapper>

            <Subtitle>
                Save format
            </Subtitle>

            <ToggleMode
                mode={mode}
                saveDialogOpen={saveDialogOpen}
                type={type}
                toggleMode={toggleMode}
            />

            {mode === 'view' ? (
                <SaveAsView
                    databaseType={databaseType}
                    tables={tables}
                    schemas={schemas}
                    views={views}
                    loadingSchemas={loadingSchemas}
                    loading={loading}
                    saveDialogOpen={saveDialogOpen}
                    params={params}
                    updateParams={updateParams}
                />
            ) : (
                <SaveAsReport
                    visualizationReport={visualizationReport}
                    params={params}
                    loading={loading}
                    saveDialogOpen={saveDialogOpen}
                    reports={reports}
                    updateParams={updateParams}
                />
            )}

            <ConfirmOverrideDialog
                confirmDialogOpen={confirmDialogOpen}
                closeConfirmDialog={closeConfirmDialog}
                mode={mode}
                params={params}
                disabled={disabled}
                loading={loading}
                onOverrideClicked={onOverrideClicked}
            />
        </Dialog>
    );
};

/**
 * @typedef { object } ToggleModeProps
 * @prop { Mode } mode
 * @prop { boolean } saveDialogOpen
 * @prop { WorkbenchParams['type'] } type
 * @prop { (e: ChangeEvent<Mode>) => void } toggleMode
 */

/** @type { React.FC<ToggleModeProps> } */
const ToggleMode = ({ mode, type, saveDialogOpen, toggleMode }) => (
    <ToggleButtonGroup
        flex
        value={mode || 'report'}
        onChange={toggleMode}
        spacing="mb-1"
    >
        <ToggleButton
            type="solid"
            value="report"
            disabled={saveDialogOpen && type === 'views'}
        >
            Report
        </ToggleButton>

        <ToggleButton
            type="solid"
            value="view"
            disabled={saveDialogOpen && type === 'reports'}
        >
            View
        </ToggleButton>
    </ToggleButtonGroup>
);

/**
 * @typedef { object } ConfirmOverrideDialogProps
 * @prop { boolean } confirmDialogOpen
 * @prop { () => void } closeConfirmDialog
 * @prop { Mode } mode
 * @prop { Params } params
 * @prop { boolean } disabled
 * @prop { boolean } loading
 * @prop { () => void } onOverrideClicked
 */

/** @type { React.FC<ConfirmOverrideDialogProps> } */
const ConfirmOverrideDialog = ({
    confirmDialogOpen,
    closeConfirmDialog,
    mode,
    params,
    disabled,
    loading,
    onOverrideClicked,
}) => (
    <Dialog
        compact
        isOpen={confirmDialogOpen}
        onClose={closeConfirmDialog}
        actions={[
            <Button
                key="cancel"
                type="plain"
                onClick={closeConfirmDialog}
                disabled={loading}
            >
                Go Back
            </Button>,
            <Button
                key="save"
                color="primary"
                spacing="mr-0"
                onClick={onOverrideClicked}
                disabled={disabled}
                loading={loading}
            >
                Yes, Save and Override
            </Button>,
        ]}
    >
        <Typography variant="h4" color="warning" align="center">
            <Icon icon="exclamation-circle" prefix="fas" spacing="mt-4" />
        </Typography>

        <Typography variant="h5" color="text" align="center">
            Override existing {mode || 'report'}?
        </Typography>

        <Typography variant="h5" color="text" align="center">
            &quot;{params.name}&quot; already exists.
        </Typography>

        {mode !== 'view' && (
            <Typography variant="body1" color="text" align="center">
                Any dashboards also using this report will be updated.
            </Typography>
        )}
    </Dialog>
);

/**
 * @typedef { object } SaveAsReportProps
 * @prop { VisualizationReport } visualizationReport
 * @prop { Params } params
 * @prop { ReportData[] } reports
 * @prop { boolean } loading
 * @prop { boolean } saveDialogOpen
 * @prop { (updates: Params) => void } updateParams
 */

/** @type { React.FC<SaveAsReportProps> } */
const SaveAsReport = ({
    visualizationReport,
    params,
    loading,
    reports,
    saveDialogOpen,
    updateParams,
}) => {

    /** @type { (e: ChangeEvent | null, newValue: string | null) => void } */
    const onChangeName = (event, newValue) => {
        updateParams({
            name: newValue || '',
        });
    };

    const options = useMemo(() => reports.map(r => r.title), [reports]);

    const reportAlreadyExists = !!options.find(o => o === params.name);

    return (
        <>
            <Help>
                Reports can also be added to your dashboards in Panoply
            </Help>

            <Subtitle>
                Report Name
            </Subtitle>

            <Autocomplete
                label="Report name"
                spacing="p-0"
                options={options}
                onChange={onChangeName}
                onInputChange={onChangeName}
                value={params.name || ''}
                multiple={false}
                disabled={loading || saveDialogOpen && reportAlreadyExists}
                getOptionSearchableString={(/** @type { string | null } */ option) => {
                    return option || '';
                }}
                getOptionSelected={(/** @type { string | null } */ option) => {
                    return option === params.name;
                }}
                getOptionTitle={(/** @type { string | null } */ option) => {
                    return option || '';
                }}
                freeSolo
            />

            {reportAlreadyExists && (
                <Help color="secondaryText" spacing="mt-1">
                    This will overwrite the existing &quot;{params.name}&quot; report
                </Help>
            )}

            <Subtitle>
                Report Visualization
            </Subtitle>

            <Select
                name="report-visualization"
                width="100"
                spacing="p-0"
                label="Report visualization"
                value={visualizationReport.type}
                disabled
                noFloatLabel
            >
                {Object.entries(visualizationItems).map(([type, item]) => (
                    <SelectItem
                        width="100"
                        key={type}
                        value={type}
                        selected={type === visualizationReport.type}
                        disabled
                    >
                        <Layout flex alignItems="center">
                            <Icon icon={item.icon} color="secondaryText" spacing="mr-2" />
                            {item.title}
                        </Layout>
                    </SelectItem>
                ))}
            </Select>

            <Help spacing="mt-1 -mb-1">
                To change, go back and select a visualization, then save
            </Help>
        </>
    );
};

/**
 * @typedef { object } SaveAsViewProps
 * @prop { Database['type'] } databaseType
 * @prop { TableData[] } tables
 * @prop { SchemaData[] } schemas
 * @prop { ViewData[] } views
 * @prop { boolean } loadingSchemas
 * @prop { boolean } loading
 * @prop { boolean } saveDialogOpen
 * @prop { Params } params
 * @prop { (updates: Params) => void } updateParams
 */

/** @type { React.FC<SaveAsViewProps> } */
const SaveAsView = ({
    databaseType,
    tables,
    schemas,
    views,
    loadingSchemas,
    loading,
    saveDialogOpen,
    params,
    updateParams,
}) => {

    /** @type { (e: ChangeEvent | null, newValue: Option | string | null) => void } */
    const onChangeName = (event, newValue) => {
        if (newValue && typeof newValue === 'object' && 'title' in newValue) {
            updateParams({
                name: newValue.title || '',
                schema: newValue.subtitle,
            });
        } else {
            updateParams({
                name: newValue || '',
            });
        }
    };

    /** @type { (option: Option) => boolean } */
    const getOptionSelected = ({ title, subtitle }) => {
        return title === params.name && subtitle === params.schema;
    };

    /** @type { Option[] } */
    const options = useMemo(() => {
        return views.map(v => ({
            title: v.name || '',
            subtitle: v.schemaname,
        }));
    }, [views]);

    const value = options.find(getOptionSelected);

    const getValue = () => {
        return value || {
            title: params.name || '',
            subtitle: params.schema,
        };
    };

    const viewAlreadyExists = !!value;

    const hasSchemasLoaded = !loadingSchemas && schemas.length > 0;

    return (
        <>
            <Help>
                Views can also be added to your connected BI Tools
            </Help>

            <Subtitle>
                View Name
            </Subtitle>

            <Autocomplete
                label="View name"
                spacing="p-0"
                options={options}
                onChange={onChangeName}
                onInputChange={onChangeName}
                value={getValue()}
                multiple={false}
                disabled={loading || saveDialogOpen && viewAlreadyExists}
                getOptionSearchableString={(/** @type { Option | string | null } */ option) => {
                    if (typeof option === 'object' && option && 'title' in option) {
                        return option.title;
                    }
                    return option || '';
                }}
                getOptionSelected={getOptionSelected}
                error={isTableNameTaken(params, tables)}
                freeSolo
            />

            {viewAlreadyExists && (
                <Help color="secondaryText" spacing="mt-1">
                    This will overwrite the existing &quot;{params.name}&quot; view
                </Help>
            )}

            {isTableNameTaken(params, tables) && (
                <Help color="error" spacing="mt-1">
                    The view name conflicts with an existing table name in that schema
                </Help>
            )}

            <Subtitle>
                View Schema
            </Subtitle>

            <Select
                name="schema-name"
                width="100"
                spacing="p-0 -mb-1"
                label="View schema"
                value={(hasSchemasLoaded && (
                    params.schema || getDefaultSchema(databaseType)
                )) || ''}
                onChange={(/** @type { ChangeEvent } */ e) => updateParams({
                    schema: e.target.value,
                })}
                disabled={loading || loadingSchemas || saveDialogOpen}
                noFloatLabel
            >
                {hasSchemasLoaded && schemas.map(schema => (
                    <SelectItem
                        width="100"
                        key={schema.name}
                        value={schema.name}
                        selected={isViewSchemaSelected(params, schema, databaseType)}
                        disabled={isViewSchemaSelected(params, schema, databaseType)}
                    >
                        {schema.name}
                    </SelectItem>
                ))}
            </Select>

            <Loader
                message="Loading schemas"
                active={loadingSchemas}
                absolute overlay
            />
        </>
    );
};

/** @type { React.FC<{ children: React.ReactNode, spacing?: string }> } */
const Subtitle = ({ children, ...props }) => (
    <Typography variant="subtitle1" color="text" spacing="mt-4" {...props}>
        {children}
    </Typography>
);

/** @type { React.FC<{ children: React.ReactNode, spacing?: string, color?: string }> } */
const Help = ({ children, ...props }) => (
    <Typography variant="body2" color="secondaryText" spacing="mb-0" {...props}>
        {children}
    </Typography>
);

const Status = styled(({ className, children }) => (
    <Layout className={className} flex alignItems="center">
        {children}
    </Layout>
))`
    && {
        padding: 0.55rem 1rem 0.45rem;
    }
`;

const CodeEditorWrapper = styled.div`
    border-radius: 5px;
    overflow: auto;
    max-height: 5.25rem;
`;

export default QueryResultSaveAsDialog;
