import { useCallback } from 'react';
import { useInput } from 'ui-components';

import { useRequest } from '../../lib/ajax';
import isValidEmail from '../../lib/is-valid-email';

/**
 * @typedef { object } ReturnProps
 * @prop { string } email
 * @prop { boolean } emailIsValid
 * @prop { boolean } loading
 * @prop { boolean } disabled
 * @prop { boolean } success
 * @prop { { message: string } } error
 * @prop { (newEmail: string) => void } onEmailChanged
 * @prop { () => void } onResetPasswordClicked
 * @prop { () => void } onEnterPressed
 */

/**
 * @param { { initialEmail: string } } props
 * @returns { ReturnProps }
 */
function useResetPasswordPage({ initialEmail }) {
    const { value: email, ...emailInput } = useInput('Email', initialEmail);
    const {
        go: reset,
        loading,
        error,
        data,
    } = useRequest(`/users/reset/${email}`);

    const emailIsValid = isValidEmail(email);
    const disabled = loading || !emailIsValid;
    const success = !error && !!data;

    const onEmailChanged = emailInput.bind.onChange;

    const onResetPasswordClicked = useCallback(() => {
        if (!emailIsValid) {
            return;
        }
        reset();
    }, [emailIsValid, reset]);

    const onEnterPressed = useCallback((e) => {
        if (disabled || e.key !== 'Enter') {
            return;
        }
        onResetPasswordClicked();
    }, [disabled, onResetPasswordClicked]);

    return {
        email,
        emailIsValid,
        loading,
        disabled,
        success,
        error,
        onEmailChanged,
        onResetPasswordClicked,
        onEnterPressed,
    };
}

export default useResetPasswordPage;
