import { get } from '../../lib/ajax';
import { addBreadcrumb } from '../../lib/error-reporter';

/** @typedef { import('./__types').Team } Team */
/** @typedef { import('./__types').TeamName } TeamName */
/** @typedef { import('../users/__types').User } User */

/** @type {() => Promise<Team[]>} */
export const fetchTeams = async () => {
    try {
        const { data: teams } = await get('/teams');
        return teams;
    } catch (error) {
        addBreadcrumb('Failed to fetch teams', { error });
        throw error;
    }
};

/** @type {(teamName: TeamName) => Promise<User[]>} */
export const fetchTeamMembers = async (teamName) => {
    try {
        const teams = await fetchTeams();
        const desiredTeam = teams.find(team => team.name === teamName);

        if (!desiredTeam) {
            throw new Error(`No team with name ${teamName} found`);
        }

        return desiredTeam.users;
    } catch (error) {
        addBreadcrumb('Failed to fetch team members', { error, teamName });
        throw error;
    }
};
