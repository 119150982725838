import {
    useEffect,
    useState,
    useCallback,
} from 'react';


import { uniqWith, isEqual } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { get } from '../../lib/ajax';

import * as SM from '../../models/sources';
import { reportError } from '../../lib/error-reporter';
import filterIntegrations from './search';

import {
    useSession,
    useOnboarding,
    useNavBreadCrumbs,
} from '../../shared/context-providers';

import { LAST_CREATED_DS_TYPE } from '../../shared/source/constants';
import { trackSearchConnector } from '../source-list-page/tracking';


/** @typedef { import('models/sources/__types').Source } Source */
/** @typedef { import('models/sources/__types').SourceType } SourceType */
/** @typedef { import('models/sources/__types').Param } Param */
/** @typedef { import('app/__types').Feedback } Feedback */
/** @typedef { import('./__types').Integration } Integration */
/** @typedef { import('./__types').UICategory } UICategory */
/** @typedef { import('./__types').Type } Type */
/** @typedef { import('./__types').MarketingIntegration } MarketingIntegration */
/** @typedef { Integration[] } IntegrationsList */

//
// This component combines 'sourceTypes' for data sources
// with partner and ETL integrations that are exported from marketing
// we call this combined type 'integration' within these components.
//


// title is what is displayed to the user
// ID is used as the actual search term on integrations
/** @type { UICategory[] } */
const CATEGORIES = [
    { title: 'All (A-Z)', id: 'all' },
    { title: 'Files', id: 'files & services' },
    { title: 'Apps', id: 'apis' },
    { title: 'Databases', id: 'databases' },
];

/** @type { Type[] } */
const INTEGRATION_TYPES = [
    { title: 'Popular', id: 'popular' },
    { title: 'Snap Connectors', id: 'data-source' },
    { title: 'Other', id: 'other' },
];

const POPULAR_INTEGRATIONS = [
    'mysqlv2',
    'postgresv2',
    'flexconnector',
    'googlesheetsv2',
    'salesforcev2',
    'shopifyv2adminapi',
    's3v2',
    'googleanalyticsv4',
];

const NON_SNAP_DS_LIST = [
    'flexconnector',
];

/** @type { (st: SourceType[], i: MarketingIntegration[], showHidden: boolean) => Integration[] } */
const normalizeIntegrations = (sourceTypes, integrations, showHidden) => {
    return [
        ...sourceTypes.map((st) => {

            const defaultProperties = {
                ...st,
                id: st.id,
                title: st.title + (st.hidden ? ' (*)' : ''),
                type: 'data-source',
                icon: `https://learn.panoply.io/hubfs/Data%20Source%20Icons/${st.id}.svg`,
                docs: st.links?.docs,
                hidden: st.hidden || false,
                categories: st.categories,
            };

            // Special cases where a platform-defined data source is NOT a snap connector
            if (NON_SNAP_DS_LIST.includes(st.id)) {
                return {
                    ...defaultProperties,
                    type: 'other',
                };
            }

            return defaultProperties;
        }),
        ...integrations.map((i, index) => {

            const isETL = i.type === 'etl-tool'
                || i.integration_categories.find((c) => c.title === 'ETL');

            return {
                ...i,
                id: i.title + index,
                title: i.title,
                categories: [isETL ? 'etl' : 'other'],
                keywords: i.integration_categories.map((c) => c.title),
                icon: i.thumbnail_path_product,
                type: isETL ? 'etl' : 'other',
                hidden: false,
                docs: i.documentation_url,
                isAPI: i.is_api != null ? i.is_api : true, // default to true
            };
        }),
    ].filter(
        st => (showHidden || !st.hidden) && st.title && st.type !== 'etl',
    ).sort((a, b) => {
        return a.title?.toLowerCase().localeCompare(b.title?.toLowerCase() || '') || 0;
    });
};

/**
 * @param { (f: Feedback) => void } sendFeedback
 * @param { string } [search]
 */
const useAddSourcePage = (sendFeedback, search) => {

    const navigate = useNavigate();
    const [session] = useSession();
    const [onboarding, onboardingActions] = useOnboarding();
    const queryClient = useQueryClient();
    const { user } = session;
    const showHidden = !!(user.admin || user.developer);

    /** @type {[IntegrationsList, React.Dispatch<IntegrationsList>]} */
    const [integrations, setIntegrations] = useState(
        /** @type {IntegrationsList} */ ([])
    );

    const [loading, setLoading] = useState(true);

    const [creatingSource, setCreatingSource] = useState(false);

    const [searchTerm, setSearchTerm] = useState(search || '');

    /** @type {[UICategory | undefined, React.Dispatch<UICategory | undefined>]} */
    const [selectedCategory, setSelectedCategory] = useState();

    /** @type {[string | undefined, React.Dispatch<string>]} */
    const [error, setError] = useState();

    /** @type {[Integration | undefined, React.Dispatch<Integration | undefined>]} */
    const [focusedIntegration, setFocusedIntegration] = useState();

    /** @type {[IntegrationsList, React.Dispatch<IntegrationsList>]} */
    const [searchMatches, setSearchMatches] = useState(
        /** @type {IntegrationsList} */ ([])
    );

    const [detailsModalOpen, setDetailsModalOpen] = useState(false);

    const openDetailsModal = useCallback(() => {
        setDetailsModalOpen(true);
    }, []);

    const closeDetailsModal = useCallback(() => {
        setDetailsModalOpen(false);
    }, []);

    /** @type {() => void } */
    const requestDataSource = useCallback(() => {
        const header = 'Can\'t find the data source you need?';
        const description = [
            'Please let us know which API or database you\'d',
            'like to see added to our list of supported',
            'connectors.',
        ].join(' ');

        sendFeedback({
            header,
            description,
            input: searchTerm || '',
        });
    }, [sendFeedback, searchTerm]);

    /** @type { (typeId: SourceType['id'], title: SourceType['title']) => Promise<void> } */
    const createSource = useCallback(async (typeId, title) => {
        setCreatingSource(true);
        try {
            if (onboarding.active) {
                await onboardingActions.endOnboarding();
            }

            const newSource = await SM.createSource(typeId, title);

            queryClient.removeQueries({ queryKey: ['sources'], exact: false });

            navigate(`/sources/${newSource.id}`);

        } catch (/** @type { any } */ e) {
            reportError(e);
            setError(e);
        }
        setCreatingSource(false);
    }, [navigate, onboardingActions, onboarding.active, queryClient.removeQueries]);

    const onGoBackClicked = useCallback(() => {
        setFocusedIntegration(undefined);
    }, []);

    /** @type { () => void } */
    const addFlexConnector = useCallback(() => {
        createSource('flexconnector', 'Flex Connector');
        sessionStorage.setItem(LAST_CREATED_DS_TYPE, 'flexconnector');
        return;
    }, [createSource]);

    /** @type { (integration: Integration) => void } */
    const onIntegrationClicked = useCallback((integration) => {
        if (integration.type === 'data-source' || NON_SNAP_DS_LIST.includes(integration.id)) {
            createSource(integration.id, integration.title);
            sessionStorage.setItem(LAST_CREATED_DS_TYPE, integration.id);
            return;
        }
        setSearchTerm('');
        setFocusedIntegration(integration);
    }, [createSource, onboarding.active]);

    /** @type { React.ChangeEventHandler<HTMLInputElement> } */
    const onSearchChanged = useCallback((e) => {
        setSearchTerm(e.target.value);
        setSelectedCategory(undefined);
        setFocusedIntegration(undefined);
        trackSearchConnector(e.target.value);
    }, []);

    /** @type { (category: UICategory) => Integration[] } */
    const getIntegrationsByCategory = useCallback((category) => {

        if (!integrations.length) {

            return [];
        }

        if (category.id === 'all') {
            return integrations;
        }

        return integrations.filter((st) => {
            return st.categories?.map(cat => cat.toLowerCase())
                .includes(category.id);
        }).sort((a, b) => {
            return a.title?.toLowerCase()
                .localeCompare(b.title?.toLowerCase() || '') || 0;
        });
    }, [integrations]);

    /** @type { (type: Type) => Integration[] } */
    const getIntegrationsByType = useCallback((type) => {

        if (!integrations.length) {
            return [];
        }

        if (type.id === 'popular') {
            return integrations.filter((st) => {
                return POPULAR_INTEGRATIONS.includes(st.id || '');
            });
        }

        return integrations.filter((st) => {
            return st.type === type.id;
        }).sort((a, b) => {
            return a.title?.toLowerCase()
                .localeCompare(b.title?.toLowerCase() || '') || 0;
        });
    }, [integrations]);

    /** @type {(c: {title: string, id: string}) => void } */
    const onCategoryClicked = useCallback((category) => {
        if (selectedCategory === category) {
            setSelectedCategory(undefined);
            return;
        }
        setSelectedCategory(category);
        setSearchTerm('');
        setFocusedIntegration(undefined);
    }, [selectedCategory]);

    useEffect(() => {

        const load = async () => {
            setLoading(true);

            try {
                const sourceTypes = await SM.fetchSourceTypes();

                /** @type { { data: { integrations: MarketingIntegration[] } } } */
                const {
                    data: { integrations: partners },
                } = await get('/shared-assets/partner-integrations.json');

                const partnerIntegrations = uniqWith(partners.filter((i) => {
                    return (
                        i.type === 'etl-tool'
                        || i.type === '3rd-party-data-source'
                        || i.integration_categories.find((c) => c.title === 'ETL')
                    ) && i.title;
                }), isEqual);

                const fullDSList = normalizeIntegrations(
                    sourceTypes,
                    partnerIntegrations,
                    showHidden,
                );
                setIntegrations(fullDSList);
            } catch (/** @type { any } */ e) {
                reportError(e);
                setError(e);
            }

            setLoading(false);

        };

        load();
    }, [showHidden]);

    useEffect(() => {
        if (!searchTerm || !integrations.length) {
            setSearchMatches([]);
            return;
        }
        const matches = filterIntegrations(searchTerm, integrations, showHidden).sort(
            (a, b) => {
                if (a.type === 'data-source' && b.type === 'data-source') {
                    return 0;
                }
                if (b.type === 'data-source') {
                    return 1;
                }
                if (a.type === 'data-source') {
                    return -1;
                }
                return 0;
            },
        );
        setSearchMatches(matches);
    }, [searchTerm, integrations]);

    const { setNavBreadCrumbItems } = useNavBreadCrumbs();

    useEffect(() => {
        setNavBreadCrumbItems([
            { text: 'Connectors', link: '/sources' },
            { text: 'Add Connector' },
        ]);
    }, []);

    return {
        session,
        categories: CATEGORIES,
        integrationTypes: INTEGRATION_TYPES,
        integrations,
        loading,
        error,
        selectedCategory,
        searchTerm,
        searchMatches,
        focusedIntegration,
        detailsModalOpen,
        creatingSource,
        addFlexConnector,
        onCategoryClicked,
        getIntegrationsByCategory,
        getIntegrationsByType,
        onSearchChanged,
        onIntegrationClicked,
        onGoBackClicked,
        requestDataSource,
        openDetailsModal,
        closeDetailsModal,
        createSource,
        onboardingIsActive: onboarding.active,
    };
};

export default useAddSourcePage;
