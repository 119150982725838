import React from 'react';

import {
    Button,
    Callout,
    Input,
    ValidatedInput,
    Typography,
    Link as ClickLink,
} from 'ui-components';

import getLink from '../../lib/get-link';
import { allowedEmailValidators } from '../../models/users';

/**
 * @typedef { object } Props
 * @prop { string } firstName
 * @prop { string } lastName
 * @prop { string } email
 * @prop { boolean } firstNameIsValid
 * @prop { boolean } lastNameIsValid
 * @prop { boolean } emailIsValid
 * @prop { boolean } emailIsAllowed
 * @prop { boolean } loading
 * @prop { boolean } disabled
 * @prop { { message: string, type: string } } error
 * @prop { boolean } showLoginWarning
 * @prop { (newFirstName: string) => void } onFirstNameChanged
 * @prop { (newLastName: string) => void } onLastNameChanged
 * @prop { (newEmail: string) => void } onEmailChanged
 * @prop { () => void } onSignupClicked
 * @prop { () => void } onEnterPressed
 */

/** @type { React.FC<Props> } */
const SignupForm = ({
    firstName,
    lastName,
    email,
    firstNameIsValid,
    lastNameIsValid,
    emailIsValid,
    emailIsAllowed,
    loading,
    disabled,
    error,
    showLoginWarning,
    onFirstNameChanged,
    onLastNameChanged,
    onEmailChanged,
    onSignupClicked,
    onEnterPressed,
}) => {
    const firstNameProps = {
        label: 'First name',
        name: 'first_name',
        value: firstName,
        error: !firstNameIsValid,
        disabled: loading,
        onKeyDown: onEnterPressed,
        onChange: onFirstNameChanged,
    };

    const lastNameProps = {
        label: 'Last name',
        name: 'last_name',
        value: lastName,
        error: !lastNameIsValid,
        disabled: loading,
        onKeyDown: onEnterPressed,
        onChange: onLastNameChanged,
    };

    const emailProps = {
        label: 'Work email',
        validators: allowedEmailValidators,
        value: email,
        valid: emailIsAllowed && emailIsValid,
        disabled: loading,
        onKeyDown: onEnterPressed,
        onChange: onEmailChanged,
    };

    const buttonProps = {
        color: 'primary',
        onClick: onSignupClicked,
        loading,
        disabled,
    };

    return (
        <>
            <FormHeader title="Try Panoply" />

            {showLoginWarning && (
                <Callout color="warning" spacing="mb-5">
                    Looks like you already have an account. Please

                    <ClickLink
                        inline
                        component="span"
                        color="inherit"
                        align="center"
                        spacing="ml-1"
                        link={getLink('/login', { email })}
                    >
                        Log In
                    </ClickLink>
                </Callout>
            )}

            {error && !showLoginWarning && (
                <Callout color="error" spacing="mb-5">
                    {error.message}
                </Callout>
            )}

            <Input width="100" spacing="p-0 mb-5" noFloatLabel {...firstNameProps} />
            <Input width="100" spacing="p-0 mb-5" noFloatLabel {...lastNameProps} />

            <ValidatedInput width="103" spacing="-mx-2 mb-5" noFloatLabel {...emailProps} />

            <Button width="100" spacing="m-0 mb-5" {...buttonProps}>
                Sign Up
            </Button>
        </>
    );
};

/**
 * @param { { title: string }} props
 * @returns { React.ReactElement }
 */
function FormHeader({ title }) {
    return (
        <Typography variant="h4" align="center" spacing="mb-6">
            {title}
        </Typography>
    );
}

export default SignupForm;
