import React, { useState, useMemo } from 'react';

import {
    Anchor,
    DebouncedInput,
    Layout,
    Select,
    SelectItem,
    ExpansionPanel,
    Typography,
} from 'ui-components';

import FormRow from '../../../pages/source-page/form-row';

/** @typedef { import('models/sources/__types').CollectConfig } CollectConfig */
/** @typedef { import('models/sources/__types').CollectConfigField } CollectConfigField */
/** @typedef { import('./types').Fields } Fields */
/** @typedef { import('./types').Structure } Structure */
/** @typedef { import('./types').OptionGroup } OptionGroup */
/** @typedef { import('./types').ConfigName } ConfigName */
/** @typedef { import('./types').ConfigValue } ConfigValue */

/**
 * @typedef { object } Field
 * @prop { number } key
 * @prop { string } path
 * @prop { CollectConfigField } config
 */

/**
 * @typedef { object } Props
 * @prop { string } title
 * @prop { Fields } [value]
 * @prop { (value: Fields) => void } setValue
 * @prop { string } description
 * @prop { string } link
 * @prop { ConfigName } configName
 * @prop { ConfigValue } configValue
 * @prop { OptionGroup } globalGroup
 * @prop { OptionGroup } fieldGroup
 */

/** @type { React.FC<Props> } */
const Fields = ({
    title,
    value: collectConfigFields,
    setValue: setCollectConfigFields,
    description,
    link,
    configName,
    configValue,
    globalGroup,
    fieldGroup,
}) => {
    const [defaultConfigValue, setDefaultConfigValue] = useState(configValue);

    const fields = useMemo(() => {
        return getFields(configName, collectConfigFields);
    }, [configName, collectConfigFields]);

    /** @type { Field } */
    const defaultNewField = {
        key: Object.keys(collectConfigFields?.['*'] || {}).length,
        path: '',
        config: {
            [configName]: defaultConfigValue,
        },
    };

    /** @type { (newDefaultConfigValue: ConfigValue) => void } */
    const changeDefaultConfigValue = (newDefaultConfigValue) => {
        setDefaultConfigValue(newDefaultConfigValue);
        updateCollectConfig(newDefaultConfigValue, fields);
    };

    /** @type { (field: Field, position: number) => void } */
    const changeField = (field, position) => {
        const keys = Object.keys(field.config);

        if (keys.length <= 1) {
            if (field.path) {
                fields.splice(position, 1, field);
            } else {
                fields.splice(position, 1);
            }
        } else {
            /** @type { Field } */
            const newField = {
                ...field,
                config: {
                    ...collectConfigFields?.['*'][field.path],
                    [configName]: field.config[configName],
                },
            };

            delete fields[position]?.config[configName];

            fields.splice(position, 0, newField);
        }

        updateCollectConfig(defaultConfigValue, fields);
    };

    /** @type { (defaultConfigValue: ConfigValue, fields: Field[] ) => void } */
    const updateCollectConfig = (defaultConfigValue, fields) => {
        /** @type { CollectConfig['fields'] } */
        const newFields = {
            '*': {
                ...Object.entries(collectConfigFields?.['*'] || {}).reduce(
                    (acc, [key, value]) => {
                        if (!(configName in value)) {
                            acc[key] = value;
                        }

                        return acc;
                    },
                    /** @type { Record<string, CollectConfigField> } */ ({})
                ),
                '*': {
                    ...collectConfigFields?.['*']['*'],
                    [configName]: defaultConfigValue,
                },
            },
        };

        fields.forEach(({ path, config }) => {
            newFields['*'][path] = {
                ...newFields['*'][path],
                ...config,
            };
        });

        setCollectConfigFields(newFields);
    };

    /** @type { (path: string, position: number) => void } */
    const changePath = (path, position) => {
        const field = fields[position] || defaultNewField;

        /** @type { Field } */
        const newField = {
            ...field,
            path: path.trim(),
            config: {
                [configName]: defaultConfigValue,
                ...field.config,
            },
        };

        changeField(newField, position);
    };

    /** @type { (configValue: ConfigValue, position: number) => void } */
    const changeConfigValue = (configValue, position) => {
        const field = fields[position] || defaultNewField;

        /** @type { Field } */
        const newField = {
            ...field,
            config: {
                ...field.config,
                [configName]: configValue,
            },
        };

        changeField(newField, position);
    };

    const lastElement = fields[fields.length - 1];

    // If our last field has a path (or theres no elements) then we add a new empty one
    // But we dont actually set it on 'value' until the user types a path
    const elements = lastElement?.path || !lastElement
        ? [...fields, defaultNewField]
        : fields;

    return (
        <Layout width="100" spacing="mt-3">
            <ExpansionPanel
                flat
                labelComponent={
                    <Layout flex width="100" alignItems="center">
                        <Typography variant="subtitle1" spacing="mb-0">
                            {title}
                        </Typography>
                    </Layout>
                }
            >
                <Layout width="100">
                    <Typography variant="body1" color="secondaryText" spacing="mt-3 mb-4">
                        {description}. Learn more&nbsp;

                        <Anchor
                            target="_blank"
                            href={link}
                            color="secondaryText"
                            underline
                        >
                            here
                        </Anchor>.
                    </Typography>

                    <FormRow
                        label={globalGroup.label}
                        tooltipContent={globalGroup.help}
                        required={false}
                    >
                        <Select
                            width="50"
                            label={globalGroup.name}
                            value={defaultConfigValue}
                            onChange={e => changeDefaultConfigValue(e.target.value)}
                            noFloatLabel
                        >
                            <SelectItem value="" disabled>
                                {globalGroup.name}
                            </SelectItem>

                            {globalGroup.options.map(option => (
                                <SelectItem value={option.value} key={option.value}>
                                    {option.label}
                                </SelectItem>
                            ))}
                        </Select>
                    </FormRow>

                    <FormRow
                        label={fieldGroup.label}
                        tooltipContent={fieldGroup.help}
                        required={false}
                    >
                        <Layout width="100">
                            {elements.map((elem, i) => (
                                <Layout width="100" flex alignItems="center" key={elem.key}>
                                    <DebouncedInput
                                        width="50"
                                        label="Field Path"
                                        value={elem.path}
                                        onChange={e => changePath(e.target.value, i)}
                                        helperText="Field Path"
                                        noFloatLabel
                                    />

                                    <Select
                                        width="50"
                                        label={fieldGroup.name}
                                        value={elem.config[configName] || ''}
                                        disabled={!elem.path}
                                        helperText={fieldGroup.name}
                                        onChange={e => changeConfigValue(e.target.value, i)}
                                        noFloatLabel
                                    >
                                        <SelectItem value="" disabled>
                                            {fieldGroup.name}
                                        </SelectItem>

                                        {fieldGroup.options.map(option => (
                                            <SelectItem value={option.value} key={option.value}>
                                                {option.label}
                                            </SelectItem>
                                        ))}
                                    </Select>
                                </Layout>
                            ))}
                        </Layout>
                    </FormRow>
                </Layout>
            </ExpansionPanel>
        </Layout>
    );
};

/** @type { (configName: ConfigName, collectConfigFields?: Fields) => Field[] } */
function getFields(configName, collectConfigFields) {
    const rootTable = collectConfigFields?.['*'] || {};

    const fields = Object.keys(rootTable)
        .map((key, i) => ({
            key: String(i),
            path: key,
            config: rootTable[key],
        }))
        .filter(f => f.path !== '*' && configName in rootTable[f.path]);

    return fields;
}

export default Fields;
