/* eslint-disable complexity */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { transparentize } from 'polished';

import {
    Layout,
    Typography,
    Divider,
    Button,
    Icon,
    Dropdown,
    List,
    ListItem,
    colors,
    Tooltip,
    Switch,
} from 'ui-components';

import {
    formatSize,
    formatRows,
} from './formatters';

import Feature from '../../shared/feature';

/** @typedef { import('models/database-objects/__types').DatabaseObject } DatabaseObject */
/** @typedef { import('models/database-objects/__types').Folder } Folder */
/** @typedef { import('models/database-objects/__types').View } View */
/** @typedef { import('models/database-objects/__types').Table } Table */

/** @type { (type: DatabaseObject['type'], open: boolean) => string } */
const getIcon = (type, open) => {
    switch (type) {
        case 'table': return 'table';
        case 'view': return 'search';
        case 'folder': return (open ? 'folder-open' : 'folder');
        default: return 'folder';
    }
};

/**
 * @typedef { object } Props
 * @prop { DatabaseObject } object
 * @prop { number= } indent
 * @prop { boolean } userCanManageTables
 * @prop { boolean } userCanMaterialize
 * @prop { boolean } actionsDisabled
 * @prop { (item: DatabaseObject) => boolean } isSelected
 * @prop { (item: DatabaseObject) => void } onSelected
 * @prop { (item: DatabaseObject) => void } openMoveDialog
 * @prop { (item: DatabaseObject) => void } openDeleteDialog
 * @prop { (item: Table | View) => void } openViewersDialog
 * @prop { (parentFolderId: Folder['id']) => void } openCreateDialog
 * @prop { (view: View) => void } openMaterializeDialog
 */

/** @type { React.FC<Props> } */
const TablesListItem = React.memo(({
    object,
    indent = 0,
    userCanManageTables,
    userCanMaterialize,
    isSelected,
    actionsDisabled,
    onSelected,
    openMoveDialog,
    openDeleteDialog,
    openViewersDialog,
    openCreateDialog,
    openMaterializeDialog,
}) => {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showTooltip, setShowTooltip] = useState(true);
    const selected = isSelected(object);

    const hasNestedObjects = !!(object.items && object.items.length);

    const hasSelectedNestedObjects = useMemo(() => {
        return hasNestedObjects && object.items?.some(o => isSelected(o));
    }, [hasNestedObjects, isSelected, object.items]);

    useEffect(() => {
        if (hasSelectedNestedObjects && !open) {
            setOpen(true);
        }
    }, [hasSelectedNestedObjects]);

    const size = object.metadata.size
        ? formatSize(object.metadata.size)
        : '';

    const rows = object.metadata.rows && formatRows(object.metadata.rows);

    const openDropdown = useCallback((e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        setDropdownOpen(true);
    }, []);

    const closeDropdown = useCallback((e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        setDropdownOpen(false);
    }, []);

    const toggleOpen = useCallback(() => {
        setOpen(!open);
    }, [open]);

    const onCreateSubfolderClicked = useCallback((e) => {
        e.stopPropagation();
        openCreateDialog(object.id);
        closeDropdown();
    }, [object, closeDropdown, openCreateDialog]);

    const onDeleteClicked = useCallback((e) => {
        e.stopPropagation();
        openDeleteDialog(object);
        closeDropdown();
    }, [object, closeDropdown, openDeleteDialog]);

    const onMoveClicked = useCallback((e) => {
        e.stopPropagation();
        openMoveDialog(object);
        closeDropdown();
    }, [object, closeDropdown, openMoveDialog]);

    const onMaterializeClicked = useCallback((e) => {
        if (object.type !== 'view') {
            return;
        }
        e.stopPropagation();
        openMaterializeDialog(object);
        closeDropdown();
    }, [object, closeDropdown, openMaterializeDialog]);

    const onManageViewersClicked = useCallback((e) => {

        if (object.type !== 'table' && object.type !== 'view') {
            return;
        }

        e.stopPropagation();
        openViewersDialog(object);
        closeDropdown();

    }, [object, closeDropdown, openViewersDialog]);

    const handleClick = useCallback((e) => {
        e.preventDefault();
        if (object.type === 'folder') {
            toggleOpen();
            return;
        }
        setShowTooltip(false);
        if (object.type === 'view') {
            const name = object.current_name || object.name;
            const schema = object.schemaname || object.schema;

            navigate((schema
                ? '/workbench/views/' + [schema, name].map(encodeURIComponent).join('/')
                : `/workbench/views/${encodeURIComponent(object.id)}`
            ));
            return;
        }
        navigate(`/explore/${encodeURIComponent(object.id)}`);
    }, [object, toggleOpen, navigate]);

    const iconProps = {
        icon: getIcon(object.type, open),
        prefix: object.type === 'folder' ? 'fas' : 'far',
        color: object.type === 'folder' ? 'secondaryText' : 'text',
        spacing: 'mr-3',
    };

    const actionsDropdownProps = {
        object,
        open: dropdownOpen,
        userCanMaterialize,
        disabled: actionsDisabled,
        openDropdown,
        closeDropdown,
        onCreateSubfolderClicked,
        onMoveClicked,
        onDeleteClicked,
        onManageViewersClicked,
        onMaterializeClicked,
    };

    const nestedItemsListProps = {
        objects: object.items || [],
        indent,
        userCanManageTables,
        userCanMaterialize,
        actionsDisabled,
        isSelected,
        onSelected,
        openMoveDialog,
        openDeleteDialog,
        openViewersDialog,
        openMaterializeDialog,
        openCreateDialog,
    };

    return (
        <>
            <ListItemContainer spacing="py-4 px-5" onClick={handleClick}>
                <Layout flex alignItems="center" width="100">

                    <Layout width="45" spacing="pr-2">
                        <IndentLevel flex alignItems="center" indent={indent}>

                            {userCanManageTables && (
                                <div onClick={e => e.stopPropagation()}>
                                    <Switch
                                        type="checkbox"
                                        checked={selected}
                                        label={''}
                                        disabled={object.type === 'folder'}
                                        onChange={() => onSelected(object)}
                                        spacing="mr-2 -ml-1 p-1"
                                    />
                                </div>
                            )}
                            <Icon {...iconProps} />
                            <Layout relative width="70" flex alignItems="center">
                                {!showTooltip ? <Typography title={object.name} /> : (
                                    <ObjectTitleTooltip
                                        interactive={false}
                                        content={object.name}
                                    >
                                        <Typography spacing="m-0" variant="subtitle1" noWrap>
                                            {object.name}
                                        </Typography>
                                    </ObjectTitleTooltip>
                                )}
                            </Layout>
                        </IndentLevel>
                    </Layout>
                    <Layout width="15">
                        <Typography spacing="m-0 mr-1" noWrap>{rows} rows</Typography>
                    </Layout>
                    <Layout width="15">
                        <Typography spacing="m-0 mr-1" noWrap>{object.schema || ''}</Typography>
                    </Layout>
                    <Layout width="15">
                        <Typography spacing="m-0 mr-1" noWrap>
                            {size}
                        </Typography>
                    </Layout>

                    <Layout flex justifyContent="flex-end" width="10">
                        {userCanManageTables && (
                            <ActionsDropdown {...actionsDropdownProps} />
                        )}
                    </Layout>
                </Layout>
            </ListItemContainer>

            <Divider spacing="my-0" />

            {open && hasNestedObjects && (
                <NestedItemsList {...nestedItemsListProps} />
            )}

        </>
    );
});

/**
 * @typedef { object } NestedItemsListProps
 * @prop { DatabaseObject[] } objects
 * @prop { number } indent
 * @prop { boolean } userCanManageTables
 * @prop { boolean } userCanMaterialize
 * @prop { boolean } actionsDisabled
 * @prop { (item: DatabaseObject) => void } onSelected
 * @prop { (item: DatabaseObject) => boolean } isSelected
 * @prop { (item: DatabaseObject) => void } openMoveDialog
 * @prop { (item: DatabaseObject) => void } openDeleteDialog
 * @prop { (item: Table | View) => void } openViewersDialog
 * @prop { (parentFolderId: Folder['id']) => void } openCreateDialog
 * @prop { (view: View) => void } openMaterializeDialog
 */

/** @type { React.FC<NestedItemsListProps> } */
const NestedItemsList = ({
    objects,
    indent,
    userCanManageTables,
    userCanMaterialize,
    actionsDisabled,
    isSelected,
    onSelected,
    openMoveDialog,
    openDeleteDialog,
    openViewersDialog,
    openCreateDialog,
    openMaterializeDialog,
}) => (
    <Layout>
        {objects && objects.map(nestedObject => (
            <TablesListItem
                key={nestedObject.id}
                object={nestedObject}
                indent={indent + 1}
                userCanManageTables={userCanManageTables}
                userCanMaterialize={userCanMaterialize}
                actionsDisabled={actionsDisabled}
                isSelected={isSelected}
                onSelected={onSelected}
                openMoveDialog={openMoveDialog}
                openDeleteDialog={openDeleteDialog}
                openViewersDialog={openViewersDialog}
                openMaterializeDialog={openMaterializeDialog}
                openCreateDialog={openCreateDialog}
            />
        ))}
    </Layout>
);


/**
 * @typedef { object } ActionsDropdownProps
 * @prop { DatabaseObject } object
 * @prop { boolean } open
 * @prop { boolean } userCanMaterialize
 * @prop { boolean } disabled
 * @prop { () => void } openDropdown
 * @prop { () => void } closeDropdown
 * @prop { () => void } onCreateSubfolderClicked
 * @prop { () => void } onMoveClicked
 * @prop { () => void } onDeleteClicked
 * @prop { () => void } onManageViewersClicked,
 * @prop { () => void } onMaterializeClicked,
 */

/** @type { React.FC<ActionsDropdownProps> } */
const ActionsDropdown = ({
    object,
    open,
    userCanMaterialize,
    disabled,
    openDropdown,
    closeDropdown,
    onCreateSubfolderClicked,
    onMoveClicked,
    onDeleteClicked,
    onManageViewersClicked,
    onMaterializeClicked,
}) => (
    <Dropdown
        onClose={closeDropdown}
        open={open}
        opener={(
            <Button disabled={disabled} onClick={openDropdown} type="plain" spacing="m-0 px-2">
                <Icon icon="ellipsis-v" />
            </Button>
        )}
    >
        <DropdownList>

            <Feature databaseType="redshift">
                {object.type === 'folder' && (
                    <ListItem
                        button
                        label="Create Subfolder"
                        onClick={onCreateSubfolderClicked}
                    />
                )}

                <ListItem button label="Move" onClick={onMoveClicked} />
            </Feature>

            {object.type === 'view' && userCanMaterialize && (
                <ListItem
                    button
                    label={object.materialized ? 'Dematerialize' : 'Materialize'}
                    onClick={onMaterializeClicked}
                />
            )}

            {(object.type === 'view' || object.type === 'table') && (
                <ListItem
                    button
                    label="Manage Viewers"
                    onClick={onManageViewersClicked}
                />
            )}

            <ListItem button label="Delete" onClick={onDeleteClicked} />
        </DropdownList>
    </Dropdown>
);

const IndentLevel = styled(Layout)`
    ${({ indent }) => indent && `
        margin-left: ${(indent * 40) + 'px'}
    `}
`;

const ObjectTitleTooltip = styled(Tooltip)`
    max-width: 100%;
`;

const DropdownList = styled(List)`
    min-width: 160px;
`;

const ListItemContainer = styled(Layout)`
    &:hover {
        background-color: ${transparentize(0.95, colors.primary.main)};
    }
`;

export default TablesListItem;
