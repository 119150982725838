import {
    useState,
    useCallback,
} from 'react';

import { isEqual } from 'lodash';

/** @typedef { import('lib/ajax').AjaxResponse= } AjaxError */
/** @typedef { import('../app/__types').PageProps } PageProps */

/**
 * @typedef { T } SelectableAnyObject
 * @template [T=any]
 */

/**
 * @typedef { {
 *     selectedObjects: SelectableAnyObject<T>[]
 *     allSelected: boolean
 *     toggleSelectAll: () => void
 *     toggleSelectedObject: (object: SelectableAnyObject<T>) => void
 *     clearSelectedObjects: () => void
 *     isObjectSelected: (object: SelectableAnyObject<T>) => boolean
 * } } ReturnProps
 * @template { object } T
 */

/** @type { <T extends object>(selectableObjects: T[]) => ReturnProps<T> } */
const useBatchActions = (selectableObjects) => {

    const [selectedObjects, setSelectedObjects] = useState(
        /** @type { SelectableAnyObject[] } */ ([])
    );

    /** @type { (object: SelectableAnyObject) => void } */
    const toggleSelectedObject = useCallback((object) => {
        const newSelectedObjects = [...selectedObjects];
        const index = newSelectedObjects.findIndex((selected) => isEqual(selected, object));
        if (index === -1) {
            newSelectedObjects.push(object);
        } else {
            newSelectedObjects.splice(index, 1);
        }
        setSelectedObjects(newSelectedObjects);
    }, [selectedObjects]);

    /** @type { () => void } */
    const clearSelectedObjects = useCallback(() => {
        setSelectedObjects([]);
    }, []);

    const allSelected = selectableObjects.length !== 0
        && selectedObjects.length === selectableObjects.length;

    /** @type { () => void } */
    const toggleSelectAll = useCallback(() => {
        if (allSelected) {
            setSelectedObjects([]);
            return;
        }
        setSelectedObjects(selectableObjects);

    }, [allSelected, selectableObjects]);

    /** @type { (object: SelectableAnyObject) => boolean } */
    const isObjectSelected = useCallback((object) => {
        return selectedObjects.find(
            selectedObject => isEqual(selectedObject, object),
        ) !== undefined;
    }, [selectedObjects]);

    return {
        selectedObjects,
        allSelected,
        toggleSelectedObject,
        clearSelectedObjects,
        toggleSelectAll,
        isObjectSelected,
    };
};

export default useBatchActions;
