import React from 'react';
import styled from 'styled-components';
import { Layout, Loader } from 'ui-components';

import cdnIcon from '../../../../lib/cdn-icon';

const vendors = [
    'google',
    'facebook',
    'twitter',
    'linkedin',
];

/** @type { (type: string) => string } */
function normalizeType(type) {
    if (type === 'bigquery') {
        return 'google-bigquery';
    }
    if (type === 'ga') {
        return 'google-analytics';
    }
    if (type === 'adwords') {
        return 'google-adwords';
    }
    return type;
}

/** @type { (type: string) => string } */
export function getVendor(type) {
    return vendors.find(t => normalizeType(type).includes(t)) || '';
}

/** @type { React.FC<{ vendor: string, onClick: () => void, loading?: boolean }> } */
const BrandedButton = ({ vendor, loading, onClick }) => (
    <Layout relative onClick={!loading ? onClick : null}>
        <Loader color="default" active={loading} absolute overlay />
        <Image src={cdnIcon(`oauth-${vendor}.svg`, 'vendors')} />
    </Layout>
);

const Image = styled('img')`
    vertical-align: top;
`;

export default BrandedButton;
