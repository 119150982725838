import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Layout } from 'ui-components';

import PageHelmet from './page-helmet';

/**
 * @typedef { object } Props
 * @prop { React.ReactNode | React.ReactNode[] } children
 * @prop { string } [title]
 * @prop { string } [bgColor]
 * @prop { boolean } [fullbleed]
 * @prop { boolean } [fullwidth]
 * @prop { boolean } [stretched]
 * @prop { boolean } [flex]
 * @prop { string } [flexDirection]
 * @prop { string } [alignItems]
 * @prop { string } [justifyContent]
 * @prop { string } [spacing]
 * @prop { boolean } [wide]
 */

/** @type { React.FC<Props> } */
const PageLayout = ({
    children,
    title,
    fullbleed = false,
    ...props
}) => {
    useEffect(() => {
        document.dispatchEvent(new CustomEvent('fullbleed', {
            detail: { fullbleed },
        }));
    }, [fullbleed]);

    return (
        <Container>
            <PageHelmet title={title} />
            <Content id="page-content" fullbleed={fullbleed} {...props}>
                {children}
            </Content>
        </Container>
    );
};

const Container = styled(Layout)`
    min-width: 100%;
    min-height: 100%;
`;

const Content = styled(Layout)`
    box-sizing: border-box;
    min-width: 800px; /* 815px - 15px (for the scrolling bar) */
    max-width: ${({
        wide,
    }) => (wide ? 1470 : 1170)}px; /* 1122 || 1422px + 48px (left & right padding) */
    position: relative;
    margin: 0 auto;

    ${({ bgColor }) => bgColor && `
        background-color: ${bgColor};
    `}

    ${({ fullwidth }) => fullwidth && `
        max-width: 100%;
    `}

    ${({ stretched }) => stretched && `
        height: 100%;
        min-width: 100%;
        max-width: 100%;
    `}

    ${({ fullbleed }) => fullbleed && `
        height: 100vh;
        max-height: 100vh;
        min-width: 100vw;
        max-width: 100vw;
        overflow: auto;
    `}

    ${({ stretched, fullbleed }) => !stretched && !fullbleed && `
        padding: 24px 24px 74px;
        min-height: 100%;
    `}
`;

export default PageLayout;
