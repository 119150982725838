import React from 'react';

import { SearchInput } from 'ui-components';

import PageLayout from '../../shared/page-layout';
import PageHeader from '../../shared/page-header';
import WarehousesList from './warehouses-list';
import SuspendDialog from './suspend-dialog';
import UnsuspendDialog from './unsuspend-dialog';
import ReassignDialog from './reassign-dialog';
import useWarehouses from './use-warehouses';

/** @typedef { import('../../app/__types').PageProps } PageProps */

/** @type { React.FC<PageProps> } */
const Warehouses = ({ openToast }) => {
    const {
        user,
        keyword,
        page,
        pageSize,
        onKeywordUpdated,
        updatePage,
        onSwitch,

        warehousesData,
        warehousesError,
        warehousesLoading,

        suspendError,
        suspendLoading,
        warehouseToSuspend,
        onSuspendClose,
        onSuspendConfirm,
        onSuspend,

        unsuspendError,
        unsuspendLoading,
        warehouseToUnsuspend,
        onUnsuspend,
        onUnsuspendClose,
        onUnsuspendConfirm,

        reassignError,
        reassignLoading,
        warehouseToReassign,
        reassignData,
        onReassignClose,
        onReassignConfirm,
        onReassign,
        updateReassignData,

        clustersError,
        clustersLoading,
        clusters,
    } = useWarehouses({ openToast });

    const searchInputProps = {
        value: keyword,
        onChange: onKeywordUpdated,
    };

    const warehouseListProps = {
        user,
        warehouses: warehousesData,
        loading: warehousesLoading,
        onSwitch,
        onSuspend,
        onUnsuspend,
        onReassign,
        page,
        pageSize,
        updatePage,
    };

    const suspendDialogProps = {
        suspendError,
        suspendLoading,
        warehouseToSuspend,
        onSuspendClose,
        onSuspendConfirm,
    };

    const unsuspendDialogProps = {
        unsuspendError,
        unsuspendLoading,
        warehouseToUnsuspend,
        onUnsuspendClose,
        onUnsuspendConfirm,
    };

    const reassignDialogProps = {
        reassignError,
        reassignLoading,
        warehouseToReassign,
        reassignData,
        updateReassignData,
        onReassignClose,
        onReassignConfirm,
        clustersError,
        clustersLoading,
        clusters,
    };

    return (
        <PageLayout>
            <PageHeader title="Warehouses">
                <SearchInput
                    label="Search"
                    spacing="p-0"
                    noFloatLabel
                    debounceDuration={500}
                    {...searchInputProps}
                />
            </PageHeader>

            {!warehousesError && (
                <WarehousesList {...warehouseListProps} />
            )}

            {user.admin && (
                <>
                    <SuspendDialog {...suspendDialogProps} />
                    <UnsuspendDialog {...unsuspendDialogProps} />
                    <ReassignDialog {...reassignDialogProps} />
                </>
            )}
        </PageLayout>
    );
};

export default Warehouses;
