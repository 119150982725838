/** @typedef { import('./__types').DataType } DataType */

const NUMERIC_TYPES = [
    'INT',
    'INTEGER',
    'NUMERIC',
    'DECIMAL',
    'FLOAT',
    'REAL',
    'DOUBLE',
];

const STRING_TYPES = [
    'STRING',
    'CHAR',
    'VARCHAR',
    'TEXT',
    'VARYING',
];

const DATE_TYPES = [
    'DATE',
];

const TIME_TYPES = [
    'TIME',
];

const DATETIME_TYPES = [
    'TIMESTAMP',
    'DATETIME',
];

const BOOLEAN_TYPES = [
    'BOOLEAN',
    'BOOL',
];

/** @type { (type: DataType) => boolean } */
const isNumericColumn = type => {
    const dataType = type.toUpperCase();

    return dataType !== 'INTERVAL'
        && NUMERIC_TYPES.some(type => dataType.includes(type));
};

/** @type { (type: DataType) => boolean } */
const isStringColumn = type => {
    const dataType = type.toUpperCase();

    return STRING_TYPES.some(type => dataType.includes(type));
};

/** @type { (type: DataType) => boolean } */
const isDateColumn = type => {
    const dataType = type.toUpperCase();

    return dataType !== 'DATETIME'
        && DATE_TYPES.some(type => dataType.includes(type));
};

/** @type { (type: DataType) => boolean } */
const isTimeColumn = type => {
    const dataType = type.toUpperCase();

    return !DATETIME_TYPES.some(type => dataType.includes(type))
        && TIME_TYPES.some(type => dataType.includes(type));
};

/** @type { (type: DataType) => boolean } */
const isDatetimeColumn = type => {
    const dataType = type.toUpperCase();

    return DATETIME_TYPES.some(type => dataType.includes(type));
};

/** @type { (type: DataType) => boolean } */
const isBooleanColumn = type => {
    const dataType = type.toUpperCase();

    return BOOLEAN_TYPES.some(type => dataType.includes(type));
};

export {
    isNumericColumn,
    isStringColumn,
    isDateColumn,
    isTimeColumn,
    isDatetimeColumn,
    isBooleanColumn,
};
