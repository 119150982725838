import React from 'react';
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

import { SessionProvider } from './use-session';
import { OnboardingProvider } from './use-onboarding';
import { NavBreadCrumbsProvider } from './use-nav-bread-crumbs';
import { FeedbackProvider } from './use-feedback';
import { ToastProvider } from './use-toast';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            gcTime: 1000 * 60 * 60 * 1, // 1 hour
        },
    },
});

const localStoragePersister = createSyncStoragePersister({ storage: window.localStorage });

persistQueryClient({
    queryClient,
    persister: localStoragePersister,
});

/** @type { React.FC<{ children: React.ReactNode }> } */
const ContextProviders = ({ children }) => (
    <QueryClientProvider client={queryClient}>
        <SessionProvider>
            <OnboardingProvider>
                <NavBreadCrumbsProvider>
                    <FeedbackProvider>
                        <ToastProvider>
                            {children}
                        </ToastProvider>
                    </FeedbackProvider>
                </NavBreadCrumbsProvider>
            </OnboardingProvider>
        </SessionProvider>
    </QueryClientProvider>
);

export default ContextProviders;
