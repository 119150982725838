import { post } from '../../lib/ajax';
import { reportError } from '../../lib/error-reporter';

/** @typedef { import('lib/tracking').PageData } PageData */
/** @typedef { import('lib/tracking').UserData } UserData */
/** @typedef { import('lib/tracking').Data } Data */

/**
 * @typedef { object } TrackEvent
 * @prop { string } name - the name of the event
 * @prop { string } [type] - old name/type of the event
 * @prop { Data } [data] - the payload of the event
 * @prop { PageData } [page] - the page that the event occurred in
 * @prop { UserData } [user] - the user that the event occurred for
 */

/**
 * Sends a tracking event to our server.
 *  @type { (event: TrackEvent) => Promise<void> }
 */
export async function trackEvent(event) {
    const name = event.type || event.name;
    const data = event.data || {};

    if (event.page) {
        data.page = event.page;
    }

    if (event.user) {
        data.user = event.user;
    }

    try {
        await post(`/track?table=${name}`, {
            body: data,
        });

    } catch (/** @type { any} */ err) {
        if (err.status === 0) {
            // We don't care about 0 error codes to tracking. It has no effect on
            // the user experience & is likely due to ad blocking. This already gets
            // logged as a breadcrumb in ajax.js so there is no need to log it here.
            return;
        }

        reportError(err, { event });
    }
}
