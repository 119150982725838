import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

import {
    Dropdown,
    Layout,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Loader,
    Button,
    ToggleButton,
    Tooltip,
    Icon,
    SearchInput,
    Divider,
    spacings,
    colors,
} from 'ui-components';

import { fetchReports } from '../../models/reports';
import { items as visualizationItems } from '../../shared/workbench/use-workbench-visualization';

/** @typedef { import('models/reports').Report } Report */
/** @typedef { import('models/dashboards').DashboardItem } DashboardItem */

/**
 * @typedef { import('styled-components').ThemedStyledFunction<C, any, O> } Styled
 * @template { 'span' } C
 * @template { object } [O={}]
 */

const defaultOrder = {
    title: 'asc',
    updatedAt: 'desc',
};

/**
 * @typedef { object } ReportsDropdownProps
 * @prop { string } [spacing]
 * @prop { DashboardItem[] } items
 * @prop { boolean } reportsDropdownOpen
 * @prop { (reportId?: Report['id']) => void } changeDroppingReportId
 * @prop { () => void } toggleReportsDropdown
 * @prop { () => void } closeReportsDropdown
 * @prop { (id: Report['id']) => void } addDashboardItem
 */

/** @type { React.FC<ReportsDropdownProps> } */
const ReportsDropdown = ({
    spacing,
    items,
    reportsDropdownOpen,
    changeDroppingReportId,
    toggleReportsDropdown,
    closeReportsDropdown,
    addDashboardItem,
}) => {
    const [search, setSearch] = useState('');

    const [order, setOrder] = useState(defaultOrder);

    const [orderBy, setOrderBy] = useState(
        /** @type { keyof order } */ ('title')
    );

    /** @type { (field: keyof order) => void } */
    const toggleOrder = (field) => {
        setOrder(o => ({
            ...defaultOrder,
            [field]: o[field] === defaultOrder[field] && orderBy === field
                ? o[field] === 'asc' ? 'desc' : 'asc'
                : defaultOrder[field],
        }));
        setOrderBy(field);
    };

    const {
        data: reports,
        isLoading,
        isError,
    } = useQuery({ queryKey: ['reports'], queryFn: () => fetchReports() });

    const filteredReports = useMemo(() => {
        if (!reports || !search) {
            return reports || [];
        }

        return reports.filter(report => (
            report.title.toLowerCase().includes(search.toLowerCase())
        ));
    }, [reports, search]);

    const sortedReports = useMemo(() => {
        return [...filteredReports].sort((...props) => {
            const [a, b] = order[orderBy] === 'asc'
                ? props
                : props.reverse();

            if (orderBy === 'title') {
                return a.title.localeCompare(b.title);
            }

            if (orderBy === 'updatedAt') {
                return moment.utc(a.updatedAt)
                    .diff(moment.utc(b.updatedAt));
            }

            return 0;
        });
    }, [filteredReports, orderBy, order]);

    useEffect(() => {
        if (!reportsDropdownOpen) {
            return () => {};
        }

        /** @type { (e: KeyboardEvent) => void } */
        const onKeyDown = e => {
            if (e.key === 'Escape') {
                e.preventDefault();
                closeReportsDropdown();
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    }, [reportsDropdownOpen, closeReportsDropdown]);

    const cellProps = {
        spacing: 'px-4 py-0',
        noBorder: true,
    };

    return (
        <Dropdown
            opener={
                <Tooltip
                    content="Add to dashboard"
                    interactive={false}
                    placement="top"
                >
                    <ToggleButton
                        square
                        type="plain"
                        active={reportsDropdownOpen}
                        onClick={toggleReportsDropdown}
                        spacing={spacing}
                    >
                        <Icon icon="plus" />
                    </ToggleButton>
                </Tooltip>
            }
            open={reportsDropdownOpen}
            onClose={closeReportsDropdown}
            placement="bottom-right"
            noOverlay
        >
            <WideSearchInput
                label="Search"
                variant="plain"
                spacing="p-0"
                width="100"
                value={search}
                disabled={!reports?.length || isLoading || isError}
                onChange={e => setSearch(e.target.value)}
                debounceDuration={300}
                noFloatLabel
            />

            <Divider width="100" spacing="m-0" />

            <ScrollableLayout width="100" spacing="pb-1">
                <Table>
                    <StickyTableHead>
                        <TableRow>
                            <SmallTableCell {...cellProps}>
                                <TableSortLabel
                                    active={orderBy === 'title'}
                                    direction={order.title}
                                    onClick={() => toggleOrder('title')}
                                >
                                    Report name
                                </TableSortLabel>
                            </SmallTableCell>

                            <SmallTableCell {...cellProps}>
                                <TableSortLabel
                                    active={orderBy === 'updatedAt'}
                                    direction={order.updatedAt}
                                    onClick={() => toggleOrder('updatedAt')}
                                >
                                    Last modified
                                </TableSortLabel>
                            </SmallTableCell>

                            <SmallTableCell align="right" {...cellProps}>
                                &nbsp;
                            </SmallTableCell>
                        </TableRow>
                    </StickyTableHead>

                    <TableBody>
                        {sortedReports.map(report => {
                            const exists = items.some(
                                item => item.widget.type === 'report-ref'
                                    && item.widget.id === report.id
                                    && item.position.x !== -1
                                    && item.position.y !== -1
                            );

                            return (
                                <ClickableTableRow
                                    key={report.id}
                                    {...!exists && {
                                        onClick: () => {
                                            addDashboardItem(report.id);
                                        },
                                        onDragStart: () => {
                                            changeDroppingReportId(report.id);
                                        },
                                        onDragEnd: () => {
                                            changeDroppingReportId(undefined);
                                        },
                                    }}
                                >
                                    <SmallTableCell
                                        component="th"
                                        scope="row" {...cellProps}
                                    >
                                        <Layout flex alignItems="center">
                                            <Icon
                                                color={!exists ? 'text' : 'interface'}
                                                icon={visualizationItems[report.type].icon}
                                            />

                                            <TitleTooltip
                                                content={report.title}
                                                interactive={false}
                                                placement="top"
                                                enterDelay={700}
                                            >
                                                <Typography
                                                    color={!exists ? 'text' : 'interface'}
                                                    spacing="m-0 ml-4"
                                                    noWrap
                                                >
                                                    {(search
                                                        ? getHighlightedText(report.title, search)
                                                        : report.title
                                                    )}
                                                </Typography>
                                            </TitleTooltip>
                                        </Layout>
                                    </SmallTableCell>

                                    <SmallTableCell {...cellProps}>
                                        <Typography
                                            color={(!exists
                                                ? 'secondaryText'
                                                : 'interface'
                                            )}
                                            spacing="m-0"
                                            noWrap
                                        >
                                            {formatDate(report.updatedAt)}
                                        </Typography>
                                    </SmallTableCell>

                                    <SmallTableCell align="right" {...cellProps}>
                                        <Tooltip
                                            {...!exists && {
                                                content: 'Add to dashboard',
                                            }}
                                            interactive={false}
                                            placement="top"
                                        >
                                            <Button
                                                square
                                                type="plain"
                                                spacing="m-0 p-1"
                                                onClick={() => (
                                                    addDashboardItem(report.id)
                                                )}
                                                disabled={exists}
                                            >
                                                {!exists ? (
                                                    <Icon icon="plus" />
                                                ) : (
                                                    <Icon icon="check" color="interface" />
                                                )}
                                            </Button>
                                        </Tooltip>
                                    </SmallTableCell>
                                </ClickableTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </ScrollableLayout>

            {!!reports?.length && !sortedReports.length && search && (
                <Info>
                    No reports matching &quot;{search}&quot; found
                </Info>
            )}

            {!reports?.length && !isLoading && (isError ? (
                <Info>
                    Oops! There was an error when loading reports
                </Info>
            ) : (
                <>
                    <Info>
                        {'\u00A0'}
                    </Info>

                    <Overlay>
                        <Typography variant="h6" align="center" spacing="pt-8">
                            No reports yet
                        </Typography>

                        <Button color="secondary" link="/workbench">
                            Click here to create your first report
                        </Button>
                    </Overlay>
                </>
            ))}

            <Loader
                absolute
                overlay
                active={isLoading || !reports}
                color="interface"
            />
        </Dropdown>
    );
};

/** @type { React.FC<{ children: React.ReactNode }> } */
const Info = ({ children }) => (
    <Layout
        flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100"
        spacing="px-4 pt-5 pb-8"
    >
        <Typography color="secondaryText">
            {children}
        </Typography>
    </Layout>
);

const Overlay = styled.div`
    position: absolute;
    inset: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.6);
`;

const TitleTooltip = styled(Tooltip)`
    overflow: hidden;
`;

const StickyTableHead = styled(TableHead)`
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: ${colors.white};
`;

const ClickableTableRow = styled(TableRow)`
    &&& {
        :hover {
            background-color: transparent;

            .button:not(:hover):not(:disabled) {
                background-color: ${transparentize(0.9, colors.secondaryText)};
            }
        }

        :active {
            .button:not(:hover):not(:disabled) {
                background-color: ${transparentize(0.7, colors.secondaryText)};
            }
        }
    }
`;

const SmallTableCell = styled(TableCell)`
    height: 41px;
    width: 200px;
    max-width: 200px;

    :first-child {
        width: 270px;
        max-width: 270px;
    }

    :last-child {
        width: 70px;
        max-width: 70px;
    }
`;

const ScrollableLayout = styled(Layout)`
    max-height: 50vh;
    overflow: auto;
`;

const WideSearchInput = styled(SearchInput)`
    &&& {
        .input {
            padding-left: ${spacings[4]}rem;
        }
        .form-control {
            padding-right: ${spacings[4] + spacings[1]}rem;
        }
    }
`;

/** @typedef { { highlighted?: boolean } } TextProps */

const Text = /** @type { Styled<'span', TextProps> } */ (styled.span)`
    ${props => props.highlighted && `
        background-color: yellow;
    `}
`;

/** @type { (text: string) => string } */
const escapeRegExp = (text) => {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};


/** @type { (text: string, highlight: string) => React.ReactNode } */
const getHighlightedText = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case sensitivity
    const parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, 'gi'));

    return (
        <>
            {parts.map((part, i) => (
                <Text key={i} highlighted={part.toLowerCase() === highlight.toLowerCase()}>
                    {part}
                </Text>
            ))}
        </>
    );
};

/** @type { (date: string) => string } */
const formatDate = (date) => {
    const d = moment.utc(date);

    if (!d.isValid()) {
        return 'Invalid Date';
    }

    return d.format('MMM DD, YYYY [@] HH:mm');
};

export default ReportsDropdown;
