import React, { useCallback } from 'react';
import {
    Layout,
    Typography,
    Switch,
    Select,
    SelectItem,
    Card,
    colors,
} from 'ui-components';
import styled from 'styled-components';
import { useSession } from '../../shared/context-providers';
import {
    page as connectorsPage,
    trackScheduleModalEnable as trackConnectorsScheduleEnable,
    trackScheduleModalSelectingDay as trackConnectorsScheduleDay,
    trackScheduleModalSelectingTime as trackConnectorsScheduleTime,
} from '../source-list-page/tracking';
import { getCurrentRoute } from '../../lib/get-current-route';

/** @typedef { import('models/sources/__types').Source } Source */
/** @typedef { import('models/sources/__types').Schedule } Schedule */
/** @typedef { import('shared/context-providers/use-session').Session } Session */

const DAY_OPTIONS = [
    { value: '*', label: 'Every Day' },
    { value: '0', label: 'Sunday' },
    { value: '1', label: 'Monday' },
    { value: '2', label: 'Tuesday' },
    { value: '3', label: 'Wednesday' },
    { value: '4', label: 'Thursday' },
    { value: '5', label: 'Friday' },
    { value: '6', label: 'Saturday' },
];

const TIME_OPTIONS = [
    { value: { hour: '*', minute: '0' }, label: 'Every Hour' },
    { value: { hour: '*/2', minute: '0' }, label: 'Every 2 Hours' },
    { value: { hour: '*/4', minute: '0' }, label: 'Every 4 Hours' },
    { value: { hour: '*/6', minute: '0' }, label: 'Every 6 Hours' },
    { value: { hour: '*/12', minute: '0' }, label: 'Every 12 Hours' },
    { value: { hour: '0', minute: '0' }, label: '12am UTC' },
    { value: { hour: '1', minute: '0' }, label: '1am UTC' },
    { value: { hour: '2', minute: '0' }, label: '2am UTC' },
    { value: { hour: '3', minute: '0' }, label: '3am UTC' },
    { value: { hour: '4', minute: '0' }, label: '4am UTC' },
    { value: { hour: '5', minute: '0' }, label: '5am UTC' },
    { value: { hour: '6', minute: '0' }, label: '6am UTC' },
    { value: { hour: '7', minute: '0' }, label: '7am UTC' },
    { value: { hour: '8', minute: '0' }, label: '8am UTC' },
    { value: { hour: '9', minute: '0' }, label: '9am UTC' },
    { value: { hour: '10', minute: '0' }, label: '10am UTC' },
    { value: { hour: '11', minute: '0' }, label: '11am UTC' },
    { value: { hour: '12', minute: '0' }, label: '12pm UTC' },
    { value: { hour: '13', minute: '0' }, label: '1pm UTC' },
    { value: { hour: '14', minute: '0' }, label: '2pm UTC' },
    { value: { hour: '15', minute: '0' }, label: '3pm UTC' },
    { value: { hour: '16', minute: '0' }, label: '4pm UTC' },
    { value: { hour: '17', minute: '0' }, label: '5pm UTC' },
    { value: { hour: '18', minute: '0' }, label: '6pm UTC' },
    { value: { hour: '19', minute: '0' }, label: '7pm UTC' },
    { value: { hour: '20', minute: '0' }, label: '8pm UTC' },
    { value: { hour: '21', minute: '0' }, label: '9pm UTC' },
    { value: { hour: '22', minute: '0' }, label: '10pm UTC' },
    { value: { hour: '23', minute: '0' }, label: '11pm UTC' },
];

const MINUTE_TIME_OPTIONS = [
    { value: { hour: '*', minute: '*/10' }, label: 'Every 10 Minutes' },
    { value: { hour: '*', minute: '*/20' }, label: 'Every 20 Minutes' },
];

/**
 * @param { Session['billing'] } billing
 * @returns { boolean }
 */
const isTopPayingCustomer = ({ plan }, minAmount = 999) => {

    if (!plan?.amount) {
        return false;
    }

    if (plan.interval === 'year') {
        return (plan.amount / 12) >= minAmount;
    }

    return plan.amount >= minAmount;

};

/**
 * @typedef { object } Props
 * @prop { Schedule } currentSchedule
 * @prop { (schedule: Schedule) => void } setCurrentSchedule
 */


/** @type { React.FC<Props> } */
const SourceScheduling = ({
    currentSchedule,
    setCurrentSchedule,
}) => {
    const [session] = useSession();
    const isTopPayer = isTopPayingCustomer(session.billing);

    const page = getCurrentRoute()?.name;

    const timeOptions = isTopPayer
        ? MINUTE_TIME_OPTIONS.concat(TIME_OPTIONS)
        : TIME_OPTIONS;

    const scheduleMinute = currentSchedule.minute || '0';
    const scheduleDay = currentSchedule.dayOfWeek || '0';
    const scheduleHour = currentSchedule.hour || '1';
    const scheduleDisabled = currentSchedule.disabled === true
        || !currentSchedule.dayOfWeek && !currentSchedule.hour
        || false;

    /** @type { (e: React.ChangeEvent<HTMLInputElement>) => void } */
    const toggleSchedule = useCallback(() => {
        setCurrentSchedule({
            ...currentSchedule,
            dayOfWeek: scheduleDay,
            hour: scheduleHour,
            minute: scheduleMinute,
            disabled: !scheduleDisabled,
        });
    }, [currentSchedule, setCurrentSchedule]);

    /** @type { (e: React.ChangeEvent<HTMLInputElement>) => void } */
    const updateDay = useCallback((e) => {
        /** @type {Schedule['dayOfWeek']} */
        // @ts-ignore
        const value = e.target.value;
        setCurrentSchedule({
            ...currentSchedule,
            dayOfWeek: value,
        });
    }, [currentSchedule, setCurrentSchedule]);

    /** @type { (e: React.ChangeEvent<HTMLInputElement>) => void } */
    const updateHour = useCallback((e) => {
        /** @type {Schedule['hour']} */
        // @ts-ignore
        const value = e.target.value;

        if (value) {
            const [minute, hour] = value.split(' ');

            setCurrentSchedule({
                ...currentSchedule,
                hour,
                minute,
            });
        }

    }, [currentSchedule, setCurrentSchedule]);

    const onToggleSchedule = (e) => {
        switch (page) {
            case connectorsPage:
                trackConnectorsScheduleEnable(String(scheduleDisabled));
                break;

            default:
                break;
        }

        toggleSchedule(e);
    };

    const onChangeDay = (e) => {
        const dayAsCronExpression = e.target.value;
        const day = DAY_OPTIONS.find(option => option.value === dayAsCronExpression)?.label
            || dayAsCronExpression;

        switch (page) {
            case connectorsPage:
                trackConnectorsScheduleDay(day);
                break;

            default:
                break;
        }

        updateDay(e);
    };

    const onChangeHour = (e) => {
        const hourAsCronExpression = e.target.value;
        const [minute, hour] = hourAsCronExpression.split(' ');

        const time = TIME_OPTIONS
            .find(option => option.value.minute === minute && option.value.hour === hour)?.label
            || MINUTE_TIME_OPTIONS
                .find(option => option.value.minute === minute && option.value.hour === hour)?.label
            || hourAsCronExpression;

        switch (page) {
            case connectorsPage:
                trackConnectorsScheduleTime(time);
                break;

            default:
                break;
        }

        updateHour(e);
    };

    return (
        <>

            <Layout>

                <StyledSwitch
                    checked={!scheduleDisabled}
                    onChange={onToggleSchedule}
                    label="Enable auto-collection"
                    spacing="-ml-2 mb-5"
                    color="primary"
                />

                <Card flat color="interface">
                    <DisableArea disabled={scheduleDisabled} flex alignItems="center">
                        <Layout width="40" lgWidth="40" flex alignItems="center">
                            <Typography spacing="my-0">Collection Day</Typography>
                        </Layout>
                        <Select
                            value={scheduleDay}
                            onChange={onChangeDay}
                            width="35"
                            lgWidth="35"
                        >
                            {DAY_OPTIONS.map(option => (
                                <SelectItem key={option.value} value={option.value}>
                                    {option.label}
                                </SelectItem>
                            ))}
                        </Select>
                    </DisableArea>

                    <DisableArea disabled={scheduleDisabled} flex alignItems="center">
                        <Layout width="40" lgWidth="40" flex alignItems="center">
                            <Typography spacing="my-0">Collection Time</Typography>
                        </Layout>
                        <Select
                            value={scheduleMinute + ' ' + scheduleHour}
                            onChange={onChangeHour}
                            width="35"
                            lgWidth="35"
                        >
                            {timeOptions.map(option => (
                                <SelectItem
                                    key={option.value.minute + ' ' + option.value.hour}
                                    value={option.value.minute + ' ' + option.value.hour}>
                                    {option.label}
                                </SelectItem>
                            ))}
                        </Select>
                    </DisableArea>
                </Card>
            </Layout>
        </>
    );
};

const StyledSwitch = styled(Switch)`
    .label {
        color: ${colors.text};
    }
`;

const DisableArea = styled(Layout)`
    ${({ disabled }) => disabled && `
        && {
            pointer-events: none;
            opacity: 0.5;
        }
    `}
`;

export default SourceScheduling;
