import React from 'react';
import { Image, Typography, Card } from 'ui-components';

import mailImagePath from '../../images/icon-mail-sent.svg';

/** @type { React.FC } */
const RotateServiceSuccess = () => (
    <>
        <Card spacing="mt-5">
            <Typography align="center" variant="h5" spacing="mb-6">
                Your service account JSON file has been rotated and sent!
            </Typography>

            <Image
                src={mailImagePath}
                width="100"
                height="70px"
                size="contain"
                repeat="no-repeat"
                position="center"
                align="center"
            />

            <Typography align="center" color="secondaryText" spacing="mt-5">
                You’ll receive this email within a few minutes.
                Please check your spam folder if you don’t see
                it in your inbox.
            </Typography>
        </Card>
    </>
);

export default RotateServiceSuccess;
