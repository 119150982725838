import React from 'react';
import { Tree } from 'ui-components';

import { DeleteObjectDialog, ManageViewersDialog } from '../database-objects';
import DeleteReportDialog from './delete-report-dialog';
import useWorkbenchTree from './use-workbench-tree';

/** @typedef { import('./use-workbench-tree').Props } Props */
/** @typedef { import('./use-workbench-tree').Params } Params */

/** @type { React.FC<Props & Params> } */
const WorkbenchTree = React.memo(({
    dataIsReady,
    tables,
    views,
    schemas,
    reports,
    fetchTables,
    fetchViews,
    fetchReports,
    addColumn,
    hasColumns,
}) => {
    const {
        data,
        onToggle,
        objectToDelete,
        objectToManage,
        reportToDelete,
        deleteObjectDialogOpen,
        manageViewersDialogOpen,
        deleteReportDialogOpen,
        closeDeleteObjectDialog,
        closeManageViewersDialog,
        closeDeleteReportDialog,
        fetchObjectDependencies,
        deleteObject,
        deleteReport,
    } = useWorkbenchTree({
        dataIsReady,
        tables,
        views,
        schemas,
        reports,
        fetchTables,
        fetchViews,
        fetchReports,
        addColumn,
        hasColumns,
    });

    const deleteObjectDialogProps = {
        open: deleteObjectDialogOpen,
        onClose: closeDeleteObjectDialog,
        objectToDelete,
        fetchObjectDependencies,
        deleteObject,
    };

    const deleteReportDialogProps = {
        open: deleteReportDialogOpen,
        onClose: closeDeleteReportDialog,
        reportToDelete,
        deleteReport,
    };

    const manageViewersDialogProps = {
        open: manageViewersDialogOpen,
        onClose: closeManageViewersDialog,
        objectsToManage: objectToManage ? [objectToManage] : [],
    };

    return (
        <>
            <Tree
                data={data}
                loading={!dataIsReady}
                onToggle={onToggle}
            />

            <DeleteObjectDialog {...deleteObjectDialogProps} />
            <DeleteReportDialog {...deleteReportDialogProps} />
            <ManageViewersDialog {...manageViewersDialogProps} />
        </>
    );
});

export default WorkbenchTree;
