/* eslint-disable complexity */
import React, { useEffect, useCallback, useRef } from 'react';
import { isEmpty, toNumber } from 'lodash';
import { reportError } from '../../../../lib/error-reporter';
import SourceListParam from './list-param';
import useValidatedValues from '../../../../shared/source/use-validated-values';


/** @typedef { import('models/sources/__types').DynamicListParam } Param */
/** @typedef { import('models/sources/__types').ListParamValue } Value */
/** @typedef { import('models/sources/__types').ListParamItem } Item */
/** @typedef { import('models/sources/__types').ListParamTotalItems } TotalItems */
/** @typedef { import('models/sources/__types').DynamicParamArgs } DynamicParamArgs */
/** @typedef { import('models/sources/__types').DynamicParamResponse } DynamicParamResponse */

/**
 * @typedef { object } Args
 * @prop { string= } search
 * @prop { number= } offset // Used with server-offset pagination
 * @prop { string | null | undefined } nextPageToken // Used with server-cursor pagination
 * @prop { number= } pageSize
 */

/**
 * @typedef { object } State
 * @prop { boolean= } loading
 * @prop { string= } error
 * @prop { boolean= } invalid
 * @prop { boolean= } showOnlySelected
 * @prop { Value= } items
 * @prop { Args= } args
 * @prop { TotalItems= } totalItems
 * @prop { number= } page
 */

/**
 * @typedef { object } Props
 * @prop { Param } param
 * @prop { Value } value
 * @prop { State } state
 * @prop { (value: Value) => void } setValue
 * @prop { (state: State) => void } setState
 * @prop { (args: DynamicParamArgs) => Promise<DynamicParamResponse['data']>} loadItems
 */

const ITEMS_PER_PAGE = 10;
const MIN_ITEMS_FOR_SEARCH = 10;
const SERVER_PAGE_SIZE = 100;

/** @type { React.FC<Props> } */
const SourceDynamicListParam = (props) => {
    // Used to keep track of most recent req to prevent UI jitter
    const requestIdRef = useRef(0);

    const {
        param,
        value,
        state,
        setState,
        setValue,
        loadItems,
    } = props;

    const {
        title,
        description,
        pagination = { mode: 'client' },
        hideSelectAll,
        maxSelectedItems,
    } = param;

    const { mode, maxPageSize = SERVER_PAGE_SIZE } = pagination;

    const {
        loading = false,
        error,
        invalid,
        items = [],
        args = {
            offset: 0,
            nextPageToken: null,
            pageSize: Math.min(SERVER_PAGE_SIZE, maxPageSize),
        },
        showOnlySelected = false,
        totalItems,
        page = 0,
    } = state;

    const { search = '' } = args;

    const serverPaginated = mode === 'server-offset' || mode === 'server-cursor';

    const searchAllowed = items.length > MIN_ITEMS_FOR_SEARCH
        || serverPaginated;

    const totalPages = Math.ceil(items.length / ITEMS_PER_PAGE);

    const shouldLoadItems = !loading && !error
        && (page + 1) >= totalPages
        && totalItems !== 0
        && (!items.length
            || totalItems === null
            || items.length < toNumber(totalItems)
        );

    const canUseValidatedValues = !serverPaginated;
    const {
        validatedOptions,
        validatedValues,
        invalidValues,
    } = useValidatedValues(value, items);

    const updatePage = useCallback((pageNum) => {
        setState({
            ...state,
            page: pageNum,
        });
    }, [state, setState]);

    const updateSelectAll = useCallback((checked) => {
        if (items == null || isEmpty(items)) {
            const e = new Error('"Select all" toggled with no items');
            reportError(e);
        }

        const newValue = checked ? items : [];
        setValue(newValue);
    }, [items, setValue]);

    const updateShowOnlySelected = useCallback((newValue) => {

        if (newValue && (value == null || isEmpty(value))) {
            const e = new Error('"Show only selected" toggled when no items selected');
            reportError(e);
        }

        setState({
            ...state,
            showOnlySelected: newValue,
        });
    }, [value, setState, state]);

    const updateSearch = useCallback((newValue) => {
        if (newValue === search) {
            return;
        }

        if (serverPaginated) {
            setState({
                ...state,
                args: {
                    ...state.args,
                    search: newValue,
                    offset: 0,
                    nextPageToken: null,
                },
                items: [],
                totalItems: null,
                page: 0,
            });
            return;
        }

        setState({
            ...state,
            args: {
                ...state.args,
                search: newValue,
            },
        });
    }, [state, setState, search, serverPaginated]);

    useEffect(() => {
        if (invalidValues.length && !invalid) {
            setState({ ...state, invalid: true });
            return;
        }
        if (invalidValues.length === 0 && invalid) {
            setState({ ...state, invalid: false });
            return;
        }
    }, [invalidValues, setState, state, invalid]);

    useEffect(() => {
        /** @type { (args?: Args) => Promise<void> } */
        const load = async (reqArgs = {}) => {
            setState({ ...state, loading: true, error: '' });

            // We use this later to tell if a newer request has already been sent
            // If so we do nothing with the response in order to prevent UI jitter.
            const requestId = Date.now();
            requestIdRef.current = requestId;

            try {
                const { metadata = {}, values } = await loadItems(reqArgs);

                if (requestId !== requestIdRef.current) {
                    return;
                }

                if (mode === 'server-offset') {
                    const lastPageIsFull = items.length
                        && ((items.length % ITEMS_PER_PAGE) === 0);

                    const newItems = [...items, ...values];
                    const pageSize = toNumber(metadata.pageSize) || reqArgs.pageSize;

                    // First, check if the server tells us what's the total
                    const newTotalItems = toNumber(metadata.totalItems)
                        // If the server doesn't tell us what's the total
                        || (
                            // If this request gave us less items than expected,
                            // and it's the last page, just count the items array,
                            // otherwise keep the value as null until the next time.
                            values.length < pageSize
                                ? newItems.length
                                : null
                        );

                    // If the page (starts from 0) exceeds the total pages,
                    // and the current request didn't give us any results,
                    // or the last page (currently seen to user) isn't full,
                    // than take the user 1 page back to avoid an empty page.
                    const newPage = (page + 1) > totalPages
                        && (!values.length || !lastPageIsFull)
                        ? Math.max(0, page - 1)
                        : page;

                    setState({
                        ...state,
                        items: newItems,
                        args: {
                            ...state.args,
                            offset: newItems.length,
                            pageSize,
                        },
                        loading: false,
                        totalItems: newTotalItems,
                        page: newPage,
                    });
                    return;
                }

                if (mode === 'server-cursor') {

                    const hasNextPage = metadata.nextPageToken;
                    const newItems = [...items, ...values];
                    const calculatedTotalItems = hasNextPage ? null : newItems.length;
                    const totalItems = metadata.totalItems !== undefined
                        ? metadata.totalItems : calculatedTotalItems;

                    const nextPageToken = hasNextPage
                        ? String(metadata.nextPageToken)
                        : null;

                    const pageSize = toNumber(metadata.pageSize) || reqArgs.pageSize;

                    setState({
                        ...state,
                        items: newItems,
                        args: {
                            ...state.args,
                            nextPageToken,
                            pageSize,
                        },
                        loading: false,
                        totalItems: totalItems,
                    });

                    return;
                }

                setState({
                    ...state,
                    items: values,
                    loading: false,
                });
            } catch (e) {
                if (requestId !== requestIdRef.current) {
                    return;
                }
                setState({
                    ...state,
                    loading: false,
                    error: e.message,
                });
            }
        };

        if (!shouldLoadItems) {
            return;
        }

        load(serverPaginated ? args : {});
    }, [state, page, search]);

    const listParamBaseProps = {
        title,
        items: canUseValidatedValues ? validatedOptions : items,
        value: canUseValidatedValues ? validatedValues : value,
        loading,
        error,
        selectAllAllowed: !hideSelectAll,
        maxSelectedItems,
        showOnlySelected,
        updateShowOnlySelected,
        showOnlySelectedAllowed: true,
        updateSelectAll,
        searchAllowed,
        search,
        description,
        updateSearch,
        page,
        pageSize: ITEMS_PER_PAGE,
        totalItems,
        searchDebounceDuration: serverPaginated ? 500 : 0,
        updatePage,
        setValue,
    };

    return (
        <SourceListParam {...listParamBaseProps} />
    );
};

export default SourceDynamicListParam;
