import React from 'react';
import styled from 'styled-components';

import {
    Card,
    Layout,
    Typography,
    Switch,
} from 'ui-components';

import PageLayout from '../../shared/page-layout';
import PageHeader from '../../shared/page-header';
import useNotifications from './use-notifications';

/** @typedef { import('../../app/__types').PageProps } PageProps */
/** @typedef { import('./__types').Notification } Notification */

const switchProps = {
    sources_collect_first: {
        title: 'New connector collected',
        text: 'Immediately receive an email when a new connector finishes for the first time.',
    },
    sources_collect_manual: {
        title: 'Manual collection',
        text: 'Immediately receive an email when a manual collection finishes.',
    },
    sources_collect_schedule: {
        title: 'Failed scheduled collection',
        text: 'Immediately receive an email when a scheduled collection fails.',
    },
    sources_collect_summary: {
        title: 'Currently failing',
        text: 'Daily email summarizing the currently failing connectors.',
    },
};

/**
 * @typedef { object } NotificationItemProps
 *
 * @prop { string } title
 * @prop { string } text
 * @prop { string } type
 * @prop { string } color
 * @prop { string } label
 * @prop { boolean } checked
 * @prop { string } value
 * @prop { (e: Event) => void } onChange
 */

/** @type { React.FC<NotificationItemProps> } */
const NotificationItem = ({ title, text, ...props }) => (
    <Layout width="100" spacing="mb-4" flex alignItems="flex-start">
        <SettingColumn>
            <Typography variant="subtitle1" weight="medium">
                {title}
            </Typography>
            <Typography variant="body1" color="secondaryText" italic>
                {text}
            </Typography>
        </SettingColumn>
        <Switch spacing="m-0 -mt-1" {...props} />
    </Layout>
);

/** @type { React.FC<PageProps> } */
const NotificationsPage = ({ openToast }) => {
    const {
        notifications,
        onChange,
    } = useNotifications({ openToast });

    /** @type { (value: Notification) => NotificationItemProps } */
    const getSwitchProps = value => ({
        ...switchProps[value],
        type: 'switch',
        color: 'primary',
        label: notifications[value] ? 'On' : 'Off',
        checked: notifications[value],
        value,
        onChange,
    });

    return (
        <PageLayout>
            <PageHeader title="Notifications" />
            <Card title="Manage Email Notifications" titleDivider>
                <NotificationItem {...getSwitchProps('sources_collect_first')} />
                <NotificationItem {...getSwitchProps('sources_collect_manual')} />
                <NotificationItem {...getSwitchProps('sources_collect_schedule')} />
                <NotificationItem {...getSwitchProps('sources_collect_summary')} />
            </Card>
        </PageLayout>
    );
};

const SettingColumn = styled(Layout)`
    width: 36rem;
`;

export default NotificationsPage;
