import React from 'react';

import {
    Icon,
    Typography,
    Tooltip,
    Layout,
} from 'ui-components';

import { extractStatusData, statuses } from '../../models/jobs/status';

/** @typedef { import('../../models/jobs/__types').Job } Job */

/** @typedef { import('../../models/jobs/__types').UserFacingStatus } UserFacingStatus */

/** @param { UserFacingStatus } [userFacingStatus] */
const getStatusColor = (userFacingStatus) => {
    switch (userFacingStatus) {
        case statuses.Success:
            return 'secondary';
        case statuses.Pending:
            return 'secondaryText';
        case statuses.Running:
            return 'secondary';
        case statuses.Canceled:
            return 'secondaryText';
        case statuses.Failed:
            return 'error';
        default:
            return 'secondaryText';
    }
};

/** @param { UserFacingStatus } [userFacingStatus] */
const getStatusIcon = (userFacingStatus) => {
    switch (userFacingStatus) {
        case statuses.Success:
            return 'check-circle';
        case statuses.Failed:
            return 'exclamation-circle';
        case statuses.Canceled:
            return 'times-circle';
        default:
        // Circle should get the 'fas' variant
            return 'circle';
    }
};

/** @type {React.FC<{job: Job}>} */
const StatusContent = ({ job }) => {
    const { status, finished } = extractStatusData(job);

    const statusIcon = status && getStatusIcon(status);
    const statusColor = status && getStatusColor(status);

    return (
        <Layout width="15" flex alignItems="center">

            <Typography inline color={statusColor} spacing="mr-1">
                {finished ? (
                    <Icon icon={statusIcon} />
                ) : (
                    <Icon icon="circle" prefix="fas" />
                )}
            </Typography>

            <Typography inline color={statusColor}>
                {status}
            </Typography>

        </Layout>
    );
};

/** @type {React.FC<{job: Job, error: string}>} */
const StatusContentWithTooltip = ({ job, error }) => (
    <Layout width="15" flex alignItems="center">
        <Tooltip
            content={error}
            interactive={false}
            enterDelay={700}
        >
            <StatusContent job={job} />
        </Tooltip>
    </Layout>
);

/**
 * @typedef { object } Props
 * @prop { Job? } job
 */

/** @type { React.FC<Props> } */
const JobStatus = React.memo(({
    job,
}) => {
    if (!job || !job.status) {
        return null;
    }

    const error = job.error || job.output?.error?.message;

    return error
        ? <StatusContentWithTooltip job={job} error={error} />
        : <StatusContent job={job} />;
});

export default JobStatus;
