import React from 'react';

import {
    Card,
    Typography,
    Layout,
} from 'ui-components';

import PageLayout from '../../shared/page-layout';
import PageHeader from '../../shared/page-header';
import DatabaseConnection from '../../shared/database-connection';
import openSupportChat from '../../shared/use-support-chat';
import FeaturedTool from './featured-tool';
import { useSession } from '../../shared/context-providers';

import tools from '../../shared/featured-tools.json';

/** @typedef { import('../../app/__types').PageProps } PageProps */

/** @type {React.FC<{category?: 'featured' | 'tools'}>} */
const LinkCardList = ({ category = 'featured' }) => {
    const commonProps = {
        hoverText: 'View connection docs',
        subtitle: 'BI Tools',
        target: '_blank',
    };

    const [session] = useSession();

    const toolsToDisplay = tools[category].filter((tool) => {
        if (tool.title === 'Mode Analytics' && session.database.type === 'bigquery') {
            return false;
        }
        return true;
    });

    return (
        <Layout flex wrap spacing="mt-5 -mx-2">
            {toolsToDisplay.map((tool) => {
                const toolProps = { ...commonProps, ...tool };
                return (
                    <FeaturedTool {...toolProps} key={tool.title} />
                );
            })}
        </Layout>
    );
};

const Footer = () => (
    <Card flat color="interface" spacing="mt-6 p-2" contentSpacing="p-3">
        <Layout flex alignItems="center" flexDirection="column">
            <Typography variant="body2" spacing="m-0">
                Don&apos;t see your BI tool?&nbsp;
                <Typography
                    inline
                    link
                    component="span"
                    variant="body2"
                    color="primary"
                    spacing="m-0"
                    onClick={() => openSupportChat()}
                >
                    Get in touch
                </Typography>
                &nbsp;with us and we can help you connect.
            </Typography>
        </Layout>
    </Card>
);

/** @type { React.FC<PageProps> } */
const ConnectPage = () => {
    return (
        <PageLayout>
            <PageHeader title="Connect Tools" />
            <DatabaseConnection />
            <Typography variant="h6" spacing="mt-6">Featured Tools</Typography>
            <LinkCardList category="featured" />
            <Typography variant="h6" spacing="mt-6">Tools</Typography>
            <LinkCardList category="tools" />
            <Footer />
        </PageLayout>
    );
};

export default ConnectPage;
