import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import copyToClipboard from 'copy-to-clipboard';

import {
    ExpansionPanel,
    Layout,
    Typography,
    Icon,
    Button,
    Divider,
    Tooltip,
    CodeEditor,
} from 'ui-components';

/** @typedef { import('../../models/queries/__types').QueryInfo } QueryInfo */

/** @type { (status: QueryInfo['status']) => string } */
const getStatusIconColor = (status) => {
    const s = status.toLowerCase();
    if (s === 'running') {
        return 'secondary';
    }
    if (s === 'done') {
        return 'secondary';
    }
    if (s === 'error') {
        return 'error';
    }
    return 'secondaryText';
};

/** @type { (status: QueryInfo['status']) => string } */
const getStatusIcon = (status) => {
    const s = status.toLowerCase();
    if (s === 'done') {
        return 'check-circle';
    }
    if (s === 'error') {
        return 'exclamation-circle';
    }
    return 'circle';
};

/** @type { (date: Date) => string | null } */
const formatDate = (date) => {
    const d = moment.utc(date);
    if (!d.isValid()) {
        return null;
    }
    return d.format('MMM DD, YYYY [@] HH:mm');
};

/** @type { (query: QueryInfo) => string } */
const getQueryDuration = (query) => {
    if (!query.startTime || !query.endTime) {
        return '';
    }
    const startMoment = moment(query.startTime);
    const endMoment = moment(query.endTime);
    const diff = endMoment.diff(startMoment);
    const duration = moment.duration(diff);

    const milliseconds = Math.round(duration.milliseconds() / 100);
    const seconds = duration.seconds();
    const minutes = duration.minutes();
    if (minutes > 0) {
        return `${minutes}m ${seconds}s`;
    }
    return `${seconds}.${milliseconds}s`;
};

/**
 * @typedef { object } Props
 * @prop { QueryInfo } query
 * @prop { (query: QueryInfo) => void } openCancelQueryDialog
 */

/** @type { React.FC<Props> } */
const QueryListItem = React.memo(({
    query,
    openCancelQueryDialog,
}) => {

    const canCancel = query.status === 'Pending' || query.status === 'Running';

    /** @type { (e: MouseEvent) => void } */
    const copyQuery = (e) => {
        e.stopPropagation();
        copyToClipboard(query.sql);
    };

    /** @type { (e: MouseEvent) => void } */
    const cancelQuery = (e) => {
        e.stopPropagation();
        openCancelQueryDialog(query);
    };
    return (
        <>
            <ExpansionPanel
                key={query.id}
                labelComponent={
                    <Layout flex width="100" alignItems="center" justifyContent="space-between">
                        <Column width="20" spacing="pr-2 pr-lg-3">
                            <Typography variant="body1" spacing="my-0" color="text" noWrap>
                                {formatDate(query.creationTime)}
                            </Typography>
                        </Column>

                        <Column width="30" spacing="pr-2 pr-lg-3" flex alignItems="center">
                            <Tooltip content="Copy to clipboard">
                                <Button
                                    type="plain"
                                    spacing="p-2 m-0 -ml-1"
                                    onClick={copyQuery}
                                    round
                                >
                                    <Icon
                                        size="sm"
                                        icon="copy"
                                        color="secondaryText"
                                        spacing="m-0 p-0"
                                    />
                                </Button>
                            </Tooltip>
                            <Typography variant="body1" spacing="my-0" color="secondaryText" noWrap>
                                {query.sql}
                            </Typography>
                        </Column>

                        <Column width="15" spacing="pr-2 pr-lg-3">
                            <Typography variant="body1" spacing="my-0" color="secondaryText" noWrap>
                                {query.userName}
                            </Typography>
                        </Column>

                        <Column width="15" spacing="pr-2 pr-lg-3" flex alignItems="center">
                            <Icon
                                icon={getStatusIcon(query.status)}
                                color={getStatusIconColor(query.status)}
                                prefix={getStatusIcon(query.status) === 'circle' ? 'fas' : 'far'}
                                size="sm"
                                spacing="mr-1"
                            />
                            <Typography variant="body1" spacing="my-0" color="secondaryText" noWrap>
                                {query.status}
                            </Typography>
                        </Column>

                        <Column width="10" spacing="pr-2 pr-lg-3">
                            <Typography variant="body1" spacing="my-0" color="secondaryText" noWrap>
                                {getQueryDuration(query)}
                            </Typography>
                        </Column>

                        <Column width="10" flex justifyContent="center">
                            {canCancel && (
                                <Button
                                    type="plain"
                                    spacing="px-2 py-0 m-0 -my-1 -ml-1"
                                    onClick={cancelQuery}
                                >
                                    <Icon
                                        size="md"
                                        icon="circle-stop"
                                        color="secondaryText"
                                        spacing="m-0 p-0"
                                    />
                                </Button>
                            )}
                        </Column>
                    </Layout>
                }
                flat={true}
                contentSpacing="px-5 py-0"
            >
                <Layout width="100">

                    <Layout flex width="100" spacing="my-4">
                        <Layout width="20" spacing="pr-2 pr-lg-3">
                            <Typography variant="body1" color="text" noWrap spacing="my-0">
                                Query ID
                            </Typography>
                        </Layout>
                        <Layout spacing="pr-2 pr-lg-3">
                            <Typography variant="body1" color="secondaryText" spacing="my-0">
                                {query.id}
                            </Typography>
                        </Layout>
                    </Layout>
                    <Divider spacing="my-0" />

                    <Layout flex width="100" spacing="my-4">
                        <Layout width="20" spacing="pr-2 pr-lg-3">
                            <Typography variant="body1" color="text" noWrap spacing="my-0">
                                Status
                            </Typography>
                        </Layout>
                        <Layout spacing="pr-2 pr-lg-3">
                            <Typography variant="body1" color="secondaryText" spacing="my-0">
                                {query.status}
                            </Typography>
                        </Layout>
                    </Layout>
                    <Divider spacing="my-0" />

                    {query.error && (
                        <>
                            <Layout flex width="100" spacing="my-4">
                                <Layout width="20" spacing="pr-2 pr-lg-3">
                                    <Typography variant="body1" color="text" noWrap spacing="my-0">
                                        Error
                                    </Typography>
                                </Layout>
                                <Layout spacing="pr-2 pr-lg-3">
                                    <Typography
                                        variant="body1"
                                        color="secondaryText"
                                        spacing="my-0"
                                    >
                                        {query.error.reason}
                                        {query.error.message && ` - ${query.error.message}`}
                                    </Typography>
                                </Layout>
                            </Layout>
                            <Divider spacing="my-0" />
                        </>
                    )}

                    <Layout flex width="100" spacing="mt-4 mb-3">
                        <Layout width="20" spacing="pr-2 pr-lg-3">
                            <Typography variant="body1" color="text" noWrap spacing="my-0">
                                Created
                            </Typography>
                        </Layout>
                        <Layout spacing="pr-2 pr-lg-3">
                            <Typography variant="body1" color="secondaryText" spacing="my-0">
                                {formatDate(query.creationTime) || '-'}
                            </Typography>
                        </Layout>
                    </Layout>
                    <Layout flex width="100" spacing="my-3">
                        <Layout width="20" spacing="pr-2 pr-lg-3">
                            <Typography variant="body1" color="text" noWrap spacing="my-0">
                                Started
                            </Typography>
                        </Layout>
                        <Layout spacing="pr-2 pr-lg-3">
                            <Typography variant="body1" color="secondaryText" spacing="my-0">
                                {query.startTime ? formatDate(query.startTime) || '-' : '-'}
                            </Typography>
                        </Layout>
                    </Layout>
                    <Layout flex width="100" spacing="mt-3 mb-4">
                        <Layout width="20" spacing="pr-2 pr-lg-3">
                            <Typography variant="body1" color="text" noWrap spacing="my-0">
                                Duration
                            </Typography>
                        </Layout>
                        <Layout spacing="pr-2 pr-lg-3">
                            <Typography variant="body1" color="secondaryText" spacing="my-0">
                                {getQueryDuration(query) || '-'}
                            </Typography>
                        </Layout>
                    </Layout>
                    <Divider spacing="my-0" />

                    <Layout flex width="100" spacing="my-4">
                        <Layout width="20" spacing="pr-2 pr-lg-3">
                            <Typography variant="body1" color="text" noWrap spacing="my-0">
                                User
                            </Typography>
                        </Layout>
                        <Layout spacing="pr-2 pr-lg-3">
                            <Typography variant="body1" color="secondaryText" spacing="my-0">
                                {query.userName}
                            </Typography>
                        </Layout>
                    </Layout>
                    <Divider spacing="my-0" />

                    <Layout flex width="100" spacing="my-4">
                        <Layout width="20" spacing="pr-2 pr-lg-3">
                            <Typography variant="body1" color="text" noWrap spacing="my-0">
                                SQL
                            </Typography>
                        </Layout>
                        <Layout width="80">
                            <CodeEditorWrapper>
                                <CodeEditor
                                    readOnly
                                    contents={query.sql}
                                    onChange={() => {}}
                                    dbType={'bigquery'}
                                />
                            </CodeEditorWrapper>
                        </Layout>
                    </Layout>

                </Layout>

            </ExpansionPanel>
        </>
    );
});

const Column = styled(Layout)`
    min-width: 90px;
`;

const CodeEditorWrapper = styled.div`
    border-radius: 5px;
    overflow: auto;
    max-height: 5.25rem;
`;

export default QueryListItem;
