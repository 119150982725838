import { useState, useCallback } from 'react';

import { useRequest } from '../../lib/ajax';

/** @typedef { import('./__types').Warehouse } Warehouse */
/** @typedef { import('./__types').Cluster } Cluster */
/** @typedef { import('./__types').Reassign } Reassign */
/** @typedef { import('../../app/__types').PageProps } PageProps */

/**
 * @typedef { object } Props
 *
 * @prop { PageProps['openToast'] } openToast
 * @prop { () => void } fetchWarehouses
 */

/**
 * @typedef { object } ReturnProps
 *
 * @prop { Reassign } reassignData
 * @prop { { message: string } } reassignError
 * @prop { boolean } reassignLoading
 * @prop { Warehouse } warehouseToReassign
 * @prop { (r: Reassign) => void } updateReassignData
 * @prop { () => void } onReassignClose
 * @prop { () => void } onReassignConfirm
 * @prop { (w: Warehouse) => Promise<void> } onReassign
 *
 * @prop { { message: string } } clustersError
 * @prop { boolean } clustersLoading
 * @prop { Cluster[] } clusters
 */

/** @type { (props: Props) => ReturnProps } */
function useReassign({ openToast, fetchWarehouses }) {
    /** @type { [Warehouse, (w: Warehouse) => void] } */
    const [warehouseToReassign, setWarehouseToReassign] = useState(({}));

    /** @type { [Reassign, (r: Reassign) => void] } */
    const [reassignData, setReassignData] = useState({});

    const [clusters, setClusters] = useState(/** @type { Cluster[] } */ ([]));

    const {
        go: fetchClusters,
        error: clustersError,
        loading: clustersLoading,
    } = useRequest('/engine/clusters');

    const reassignReq = useRequest(
        `/databases/${warehouseToReassign.id}`,
        'PUT',
        reassignData,
    );

    const {
        error: reassignError,
        loading: reassignLoading,
    } = reassignReq;

    /**
     * Adds a cluster to the clusters list if not exists.
     *
     * @type { (c: Cluster, l: Cluster[] ) => Cluster[] }
     */
    const addClusterTo = useCallback((cluster, clustersList) => {
        if (!clustersList.find(c => c.host === cluster.host)) {
            clustersList.push(cluster);
        }
        return clustersList;
    }, []);

    const onReassign = useCallback(async (warehouse) => {
        setWarehouseToReassign(warehouse);

        const id = warehouse.host.substr(0, warehouse.host.indexOf('.'));
        const cluster = { id, host: warehouse.host };

        let clustersList = clusters;
        if (!clustersList.length) {
            const { ok, data } = await fetchClusters();
            if (ok) {
                clustersList = data;
            } else {
                openToast('Failed to fetch clusters.');
            }
        }

        setClusters(addClusterTo(cluster, clustersList));
    }, [addClusterTo, clusters, fetchClusters, openToast]);

    const onReassignClose = useCallback(() => {
        setWarehouseToReassign({});
        setReassignData({});
    }, []);

    const updateReassignData = useCallback(({ host }) => {
        setReassignData({ host });
    }, []);

    const onReassignConfirm = useCallback(() => {
        reassignReq.go().then(({ ok, data }) => {
            onReassignClose();
            reassignReq.clear();

            if (!ok) {
                openToast('An error occurred during reassignment.');
                return;
            }

            openToast(`Reassigning of ${data.name} has been scheduled.`);
            fetchWarehouses();
        });
    }, [reassignReq, onReassignClose, openToast, fetchWarehouses]);

    return {
        reassignData,
        reassignError,
        reassignLoading,
        warehouseToReassign,
        updateReassignData,
        onReassignClose,
        onReassignConfirm,
        onReassign,

        clustersError,
        clustersLoading,
        clusters,
    };
}

export default useReassign;
