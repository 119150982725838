import { items as visualizationItems } from './use-workbench-visualization';

/** @typedef { import('lib/query-builder').Column } Column */
/** @typedef { import('lib/query-builder').Position } Position */
/** @typedef { import('./__types').Mode } Mode */
/** @typedef { import('./__types').TreeNode } TreeNode */
/** @typedef { import('./__types').Table } Table */
/** @typedef { import('./__types').View } View */
/** @typedef { import('./__types').TableData } TableData */
/** @typedef { import('./__types').ViewData } ViewData */
/** @typedef { import('./__types').SchemaData } SchemaData */
/** @typedef { import('./__types').ViewItem } ViewItem */
/** @typedef { import('./__types').TableItem } TableItem */
/** @typedef { import('./__types').SchemaItem } SchemaItem */
/** @typedef { import('./__types').ReportItem } ReportItem */
/** @typedef { import('./__types').ColumnItem } ColumnItem */
/** @typedef { import('./__types').ReportData } ReportData */
/** @typedef { import('./__types').ReportGroup } ReportGroup */
/** @typedef { import('./__types').EditorSchema } EditorSchema */
/** @typedef { import('models/database-objects/__types').DatabaseObject } DatabaseObject */
/** @typedef { import('models/database-objects/__types').Table } TableObject */
/** @typedef { import('models/database-objects/__types').View } ViewObject */
/** @typedef { import('models/schemas/__types').Schema } Schema */

const TABLES_BRANCH_POSITION = 0;
const VIEWS_BRANCH_POSITION = 1;

/**
 * @param { TableData | ViewData } item
 * @returns { boolean }
 */
function isItemHidden(item) {
    return !!item.name?.startsWith('__');
}

/** @type { (schemaItems: SchemaItem[], schemas: SchemaData[]) => SchemaItem[] } */
function createSchemaItems(schemaItems, schemas) {
    const items = /** @type { SchemaItem[] } */ ([]);

    schemas.forEach(schema => {
        if (!schema.name) {
            return;
        }

        const existingSchema = schemaItems.find(item => item.name === schema.name);

        items.push(existingSchema || createSchemaItem({ name: schema.name }));
    });

    return items;
}

/** @type { (item: Table | View | Schema) => SchemaItem } */
function createSchemaItem(item) {
    const name = 'schema' in item ? item.schema : item.name;

    return {
        id: name,
        name,
        type: 'schema',
        label: 'schema',
        children: [
            {
                id: `${name}-tables`,
                name: 'Tables',
                type: 'tables',
                children: [],
                loading: false,
            },
            {
                id: `${name}-views`,
                name: 'Views',
                type: 'views',
                children: [],
                loading: false,
            },
        ],
        searchable: true,
    };
}

/**
 * @param { TableData | ViewData } item
 * @param { (item: TableItem | ViewItem) => Promise<ColumnItem[]> } [loadColumns]
 * @returns { TableItem | ViewItem }
 */
function createSchemaChildItem(item, loadColumns) {
    switch (item.type) {
        case 'table': {
            const table = normalizeItem(item, 'table');

            return {
                id: table.id,
                type: 'table',
                name: table.name,
                schema: table.schema,
                icon: 'table',
                loading: /** @type { NonNullable<TreeNode['loading']> } */ (
                    loadColumns || false
                ),
                children: [],
                metadata: {
                    rows: table.metadata.rows,
                    size: table.metadata.size,
                },
                materialized: table.materialized || false,
                searchable: true,
            };
        }
        case 'view': {
            const view = normalizeItem(item, 'view');

            return {
                id: view.id,
                type: 'view',
                name: view.name,
                schema: view.schema,
                icon: 'search',
                loading: /** @type { NonNullable<TreeNode['loading']> } */ (
                    loadColumns || false
                ),
                children: [],
                metadata: {
                    rows: view.metadata.rows,
                    size: view.metadata.size,
                },
                materialized: item.materialized || false,
                searchable: true,
            };
        }
    }

    throw new Error(`Unknown item type: ${item.type}`);
}

/**
 * @param { TableData | ViewData } item
 * @param { string } [type]
 * @returns { Table | View }
 */
function normalizeItem(item, type) {
    return {
        ...item,
        id: item.id || `${item.database}-${item.schema}-${item.name}`,
        database: item.database || '',
        type: item.type || type || '',
        name: item.current_name || item.name || '',
        schema: item.schemaname || item.schema || '',
        metadata: {
            rows: item.metadata?.rows || 0,
            size: item.metadata?.size || 0,
        },
        materialized: item.materialized || false,
    };
}

/**
 * @param { SchemaItem[] } schemaItems
 * @param { TableItem | ViewItem } item
 * @returns { SchemaItem[] }
 */
function populateSchemaItem(schemaItems, item) {
    let index = schemaItems.findIndex(schemaItem => schemaItem.name === item.schema);

    if (index === -1) {
        index = schemaItems.length;
        schemaItems[index] = createSchemaItem(item);
    }

    switch (item.type) {
        case 'table':
            schemaItems[index]
                .children[TABLES_BRANCH_POSITION]
                .children
                .push(item);
            break;

        case 'view':
            schemaItems[index]
                .children[VIEWS_BRANCH_POSITION]
                .children
                .push(item);
            break;

        default:
            break;
    }

    return schemaItems;
}

/**
 * @typedef { object } ObjectParams
 * @prop { (node: TableItem | ViewItem) => void } onOpen
 * @prop { (object: DatabaseObject) => void } openDeleteObjectDialog
 * @prop { (object: TableObject | ViewObject) => void } openManageViewersDialog
 * @prop { boolean } canManageTables
 * @prop { (item: TableItem | ViewItem) => Promise<ColumnItem[]> } [loadColumns]
 */

/** @type { <T extends ViewItem | TableItem>(childItem: T, params: ObjectParams) => T } */
function populateSchemaChildItem(childItem, params) {
    childItem.actions = undefined;

    if (params.canManageTables) {
        childItem.actions = [
            {
                id: 'open',
                label: 'Open',
                iconName: 'folder-open',
                onClick: params.onOpen,
            },
            {
                id: 'manage-viewers',
                label: 'Manage Viewers',
                iconName: 'user-unlock',
                onClick: params.openManageViewersDialog,
                disabled: !params.openManageViewersDialog,
            },
            {
                id: 'delete',
                label: 'Delete',
                iconName: 'trash',
                onClick: params.openDeleteObjectDialog,
            },
        ];
    }

    return childItem;
}

/**
 * @param { SchemaItem[] } schemaItems
 * @param { TableData[] } tables
 * @param { ViewData[] } views
 * @param { ObjectParams } params
 * @return { SchemaItem[] }
 */
function populateSchemaChildItems(schemaItems, tables, views, params) {
    schemaItems.forEach(schemaItem => {
        schemaItem.children[TABLES_BRANCH_POSITION].children = [];
        schemaItem.children[VIEWS_BRANCH_POSITION].children = [];
    });

    tables.concat(views).forEach(item => {
        if (isItemHidden(item)) {
            return;
        }

        const childItem = createSchemaChildItem(item, params.loadColumns);

        populateSchemaChildItem(childItem, params);

        populateSchemaItem(schemaItems, childItem);
    });

    return schemaItems;
}

/** @type { () => ReportGroup } */
function createReportGroup() {
    return {
        id: 'reports',
        name: 'Reports',
        type: 'reports',
        children: [],
        loading: false,
    };
}

/**
 * @typedef { object } ReportParams
 * @prop { (item: ReportItem) => void } onOpen
 * @prop { (item: ReportItem) => void } openDeleteReportDialog
 * @prop { boolean } canManageReports
 */

/**
 * @param { ReportGroup } reportGroup
 * @param { ReportData[] } reports
 * @param { ReportParams } params
 * @return { ReportGroup }
 */
function populateReportItems(reportGroup, reports, params) {
    reportGroup.children = [];

    reports.forEach(report => {
        const reportItem = createReportItem(report);

        populateReportItem(reportItem, params);

        reportGroup.children.push(reportItem);
    });

    return reportGroup;
}

/** @type { (reportItem: ReportItem, params: ReportParams) => ReportItem } */
function populateReportItem(reportItem, params) {
    reportItem.actions = undefined;

    if (params.canManageReports) {
        reportItem.actions = [
            {
                id: 'open',
                label: 'Open',
                iconName: 'folder-open',
                onClick: params.onOpen,
            },
            {
                id: 'manage-viewers',
                label: 'Manage Viewers',
                iconName: 'user-unlock',
                onClick: () => {}, // noop
                disabled: true,
            },
            {
                id: 'delete',
                label: 'Delete',
                iconName: 'trash',
                onClick: params.openDeleteReportDialog,
            },
        ];
    }

    return reportItem;
}

/**
 * @param { ReportData } item
 * @return { ReportItem }
 */
function createReportItem(item) {
    /** @type { ReportItem } */
    const reportItem = {
        id: item.id,
        name: item.title,
        type: 'report',
        icon: visualizationItems[item.type].icon,
        loading: false,
        children: null,
        searchable: true,
    };

    return reportItem;
}

/**
 * @param { SchemaData[] } schemas
 * @param { TableData[] } tables
 * @param { ViewData[] } views
 * @returns { EditorSchema[] }
 */
function createEditorSchemas(schemas, tables, views) {
    const editorSchemas = /** @type { EditorSchema[] } */ ([]);

    schemas.forEach(schema => {
        if (!schema.name) {
            return;
        }

        editorSchemas.push({
            schemaName: schema.name,
            tables: [],
        });
    });

    tables.concat(views).forEach(item => {
        if (!item.name || !item.schema || item.name.startsWith('__')) {
            return;
        }

        const schemaIndex = editorSchemas.findIndex(s => (
            s.schemaName === item.schema
        ));

        if (schemaIndex === -1) {
            editorSchemas.push({
                schemaName: item.schema,
                tables: [item.name],
            });
        } else {
            editorSchemas[schemaIndex].tables.push(item.name);
        }
    });

    return editorSchemas;
}

/**
 * @typedef { object } ColumnParams
 * @prop { (column: Column, position?: Position) => void } [addColumn]
 * @prop { boolean } [hasColumns]
 */

/**
 * @template { TableItem | ViewItem } T
 * @param { T } parentItem
 * @param { ColumnItem[] } childItems
 * @param { ColumnParams } params
 * @return { T }
 */
function populateParentChildItems(parentItem, childItems, params) {
    parentItem.children = [];

    childItems.forEach(item => {
        populateParentChildItem(item, params);

        parentItem.children?.push(item);
    });

    return parentItem;
}

/** @type { (childItem: ColumnItem, params: ColumnParams) => ColumnItem } */
function populateParentChildItem(childItem, params) {
    childItem.actions = undefined;
    childItem.onDoubleClick = undefined;
    childItem.draggable = false;

    if (childItem.type === 'column' && params.addColumn) {
        childItem.actions = params.hasColumns ? [
            {
                id: 'add-to-beginning',
                label: 'Add to beginning of query',
                onClick: column => params.addColumn?.(column, 'first'),
            },
            {
                id: 'add-to-end',
                label: 'Add to end of query',
                onClick: column => params.addColumn?.(column, 'last'),
            },
        ] : [
            {
                id: 'add',
                label: 'Add to query',
                onClick: column => params.addColumn?.(column, 'last'),
            },
        ];
        childItem.onDoubleClick = column => params.addColumn?.(column, 'last');
        childItem.draggable = true;
    }

    return childItem;
}

export {
    createSchemaItems,
    createReportGroup,
    populateReportItems,
    populateReportItem,
    populateSchemaChildItems,
    populateSchemaChildItem,
    populateParentChildItems,
    populateParentChildItem,
    createEditorSchemas,
};
