/* eslint-disable complexity */
import React, { useMemo } from 'react';

import {
    Layout,
    BreadCrumbs,
    BreadCrumb,
    Typography,
    Loader,
    ToggleButton,
    LogoThumbnail,
    Icon,
    Divider,
} from 'ui-components';

import useAddSourcePage from './use-add-source-page';
import PageLayout from '../../shared/page-layout';
import PageError from '../../shared/page-error';
import IntegrationInfo from './integration-info';
import Sidebar from './sidebar';
import SearchResults from './search-results';

/** @typedef { import('app/__types').Feedback } Feedback */
/** @typedef { import('./__types').Integration } Integration */
/** @typedef { import('./__types').UICategory } Category */
/** @typedef { import('./__types').Type } Type */

/** @typedef { import('../../app/__types').PageProps } PageProps */

/** @type { (i: Integration) => React.ReactNode } */
const getLabel = (integration) => {
    if (integration.type === 'etl') {
        return (
            <Layout flex alignItems="center">
                <Icon size="sm" icon="cog" spacing="mr-1" /> ETL
            </Layout>
        );
    }
    if (integration.type === 'data-source') {
        return (
            <Layout flex alignItems="center">
                <Icon
                    size="md"
                    icon="bolt"
                    spacing={integration.beta ? 'mr-1' : 'm-0'}
                />
                {integration.beta && 'New'}
            </Layout>
        );
    }
    if (integration.beta) {
        return 'new';
    }
    return null;
};

/** @type { React.FC<PageProps & { search?: string }> } */
const AddSourcePage = ({ sendFeedback, search }) => {

    const {
        categories,
        integrations,
        loading,
        error,
        selectedCategory,
        integrationTypes,
        searchTerm,
        searchMatches,
        focusedIntegration,
        detailsModalOpen,
        onboardingIsActive,
        addFlexConnector,
        onCategoryClicked,
        onIntegrationClicked,
        getIntegrationsByType,
        getIntegrationsByCategory,
        onSearchChanged,
        onGoBackClicked,
        requestDataSource,
        openDetailsModal,
        closeDetailsModal,
    } = useAddSourcePage(sendFeedback, search);

    return (
        <PageLayout title="Add Connector">

            {!onboardingIsActive && (
                <Layout flex>
                    <BreadCrumbs spacing="mr-1">
                        <BreadCrumb label="Connectors" link="/sources" />
                        <BreadCrumb label="Add Connector" />
                    </BreadCrumbs>
                </Layout>
            )}

            {loading && (
                <Layout spacing="p-8">
                    <Loader active big message="Loading available Connectors" />
                </Layout>
            )}

            {!loading && error && (
                <PageError
                    title="Oops! There was an error when loading available connectors."
                    error={error}
                />
            )}


            {!loading && !error && (
                <>
                    <Layout flex alignItems="center" flexDirection="column">
                        <Typography variant="h5" weight="medium" spacing="mb-5 mt-5">
                            {onboardingIsActive ? (
                                'Select Your First Connector'
                            ) : (
                                'Connect to Databases, APIs, and More'
                            )}
                        </Typography>

                        <Layout flex spacing="mb-3">
                            {categories.map((category) => (
                                <ToggleButton
                                    type="plain"
                                    key={category.id}
                                    spacing=""
                                    active={category === selectedCategory}
                                    onClick={() => onCategoryClicked(category)}
                                >
                                    {category.title}
                                </ToggleButton>
                            ))}
                        </Layout>
                    </Layout>


                    <Layout flex>

                        <Sidebar
                            requestDataSource={requestDataSource}
                            searchTerm={searchTerm}
                            onSearchChanged={onSearchChanged}
                        />

                        {!searchTerm && focusedIntegration && (
                            <Layout spacing="pt-4" width="65">
                                <IntegrationInfo
                                    integration={focusedIntegration}
                                    addFlexConnector={addFlexConnector}
                                    onGoBackClicked={onGoBackClicked}
                                    detailsModalOpen={detailsModalOpen}
                                    closeDetailsModal={closeDetailsModal}
                                    openDetailsModal={openDetailsModal}
                                    requestDataSource={requestDataSource}
                                />
                            </Layout>
                        )}

                        {!searchTerm && !focusedIntegration && (
                            <Layout width="65">

                                <Divider width="100" spacing="mt-4 mb-0" />

                                {selectedCategory ? (
                                    <CategoryContent
                                        integrations={integrations}
                                        category={selectedCategory}
                                        title={selectedCategory.title}
                                        getIntegrationsByCategory={getIntegrationsByCategory}
                                        getIntegrationsByType={getIntegrationsByType}
                                        getLabel={getLabel}
                                        onIntegrationClicked={onIntegrationClicked}
                                    />
                                ) : (
                                    integrationTypes.map((integrationType) => (
                                        <CategoryContent
                                            key={integrationType.id}
                                            integrations={integrations}
                                            type={integrationType}
                                            title={integrationType.title}
                                            getIntegrationsByCategory={getIntegrationsByCategory}
                                            getIntegrationsByType={getIntegrationsByType}
                                            getLabel={getLabel}
                                            onIntegrationClicked={onIntegrationClicked}
                                        />
                                    ))
                                )}
                            </Layout>
                        )}

                        {searchTerm && !focusedIntegration && (
                            <SearchResults
                                searchTerm={searchTerm}
                                searchMatches={searchMatches}
                                getLabel={getLabel}
                                onIntegrationClicked={onIntegrationClicked}
                                addFlexConnector={addFlexConnector}
                            />
                        )}

                    </Layout>
                </>
            )}

        </PageLayout>
    );
};


/**
 * @typedef { object } CategoryContentProps
 * @prop { Category= } category
 * @prop { Type= } type
 * @prop { string } title
 * @prop { Integration[]= } integrations
 * @prop { number= } limit
 * @prop { (i: Integration) => void } onIntegrationClicked
 * @prop { (c: Category) => Integration[] } getIntegrationsByCategory
 * @prop { (t: Type) => Integration[] } getIntegrationsByType
 * @prop { (i: Integration) => React.ReactNode } getLabel
 */

/** @type { React.FC<CategoryContentProps> } */
const CategoryContent = ({
    category,
    type,
    title,
    integrations,
    limit,
    onIntegrationClicked,
    getIntegrationsByCategory,
    getIntegrationsByType,
    getLabel,
}) => {

    const integrationsToDisplay = useMemo(() => {
        if (category) {
            return getIntegrationsByCategory(category).slice(0, limit);
        }
        if (type) {
            return getIntegrationsByType(type).slice(0, limit);
        }
        return null;
    }, [category, type]);

    if (!integrations || !integrations.length) {
        return null;
    }

    if (!integrationsToDisplay) {
        return null;
    }

    return (
        <Layout>
            <Typography
                variant="body2"
                color="secondaryText"
                spacing="mt-5"
            >
                {title}
            </Typography>
            <Layout flex wrap>
                {integrationsToDisplay && integrationsToDisplay.map((integration) => (
                    <LogoThumbnail
                        key={integration.id}
                        title={integration.title}
                        imageUrl={integration.icon}
                        chipLabel={getLabel(integration)}
                        width="25"
                        spacing="py-2 mb-0"
                        onClick={() => onIntegrationClicked(integration)}
                        chipColor="default"
                    />
                ))}
            </Layout>
            <Divider spacing="mt-5" />
        </Layout>
    );
};

export default AddSourcePage;
