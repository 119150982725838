import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import {
    Layout,
    Typography,
} from 'ui-components';

import cdnIcon from '../../lib/cdn-icon';

/**
 * @typedef { object } Props
 * @prop { string } icon
 * @prop { string } label
 * @prop { string= } link
 * @prop { string= } spacing
 * @prop { (() => void)= } onClick
 */

/** @type { React.FC<Props> } */
const IconLink = ({ icon, link, label, spacing, onClick }) => {

    const navigate = useNavigate();

    const onIconLinkClicked = useCallback(() => {

        if (onClick) {
            onClick();
            return;
        }

        if (link) {
            if (link.startsWith('https://') || link.startsWith('http://')) {
                window.open(link);
                return;
            }
            navigate(link);
        }

    }, [navigate, link, onClick]);

    return (
        <Layout width="33.3" spacing={spacing}>
            <ClickArea onClick={onIconLinkClicked}>
                <Layout flex alignItems="center">
                    <img src={cdnIcon(icon)} />
                    <Typography spacing="ml-3">
                        {label}
                    </Typography>
                </Layout>
            </ClickArea>
        </Layout>
    );
};

const ClickArea = styled('div')`
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

export default IconLink;
