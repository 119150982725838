import React from 'react';

import {
    Card,
    Layout,
    Typography,
} from 'ui-components';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import config from '../../../config';
import PlanInfo from './plan-info';
import PaymentForm from './payment-form';

/** @typedef { import('app/__types').Plan } Plan */
/** @typedef { import('./use-billing').Subscription } Subscription */
/** @typedef { import('./use-billing').Address } Address */

const stripe = loadStripe(config.stripe.apiKey);

const options = {
    fonts: [
        {
            cssSrc: 'https://fonts.googleapis.com/css?family=Roboto:400',
        },
    ],
};

/**
 * @typedef { object } Props
 * @prop { string } [email]
 * @prop { string } [name]
 * @prop { Address } [address]
 * @prop { () => void } onCancel
 * @prop { (subscription: Subscription) => void } onUpdate
 * @prop { Plan } plan
 */

/** @type { React.FC<Props> } */
const Payment = ({ plan, ...props }) => {
    if (!plan.id) {
        return (
            <Card>
                <OopsMessage spacing="m-0 p-2" />
            </Card>
        );
    }
    return (
        <Card contentSpacing="p-0">
            <PaymentInfo plan={plan} {...props} />
        </Card>
    );
};

/** @type { React.FC<{ spacing?: string }> } */
const OopsMessage = (props) => {
    return (
        <Typography variant="subtitle1" color="secondaryText" align="center" italic {...props}>
            Opps, no plan has been selected. Please go back
            to&nbsp;<a href="/#/billing">Billing & Plan</a>.
        </Typography>
    );
};

/** @type { React.FC<Props> } */
const PaymentInfo = ({ plan, ...props }) => {
    return (
        <Layout width="100" flex alignItems="center">
            <PlanInfo width="40" spacing="p-6 pb-5" plan={plan} />

            <Layout width="60" spacing="p-6 pl-0">
                <Typography spacing="mb-4" variant="h6">
                    Enter your payment details
                </Typography>

                <GoogleReCaptchaProvider reCaptchaKey={config.recaptcha.siteKey}>
                    <Elements stripe={stripe} options={options}>
                        <PaymentForm {...props} />
                    </Elements>
                </GoogleReCaptchaProvider>
            </Layout>
        </Layout>
    );
};

export default Payment;
