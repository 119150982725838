const { track } = require('../../lib/tracking');

const page = 'source-list'; // should be the same as the page name in the router

/** @typedef { (value?: string) => void } TrackingFunction */

/** @type { TrackingFunction } */
const trackAddConnector = () => {
    track({
        event: 'Click Add Connector',
        type: 'click',
        action: 'add connector',
    });
};

/** @type { TrackingFunction } */
const trackSearchConnector = value => {
    track({
        event: 'Search entry',
        type: 'input',
        action: 'search connector',
        value, // entered search name
    });
};

/** @type { TrackingFunction } */
const trackFilterDropdown = () => {
    track({
        event: 'Filter dropdown',
        type: 'click',
        action: 'open status filter',
    });
};

/** @type { TrackingFunction } */
const trackFilterDropdownSelection = value => {
    track({
        event: 'Filter dropdown - selection',
        type: 'selection',
        action: 'filter status',
        value, // the selected status
    });
};

/** @type { TrackingFunction } */
const trackOpeningConnector = (value) => {
    track({
        event: 'Opening a connector',
        type: 'click',
        action: 'open connector page',
        value, // connector id
    });
};

/** @type { TrackingFunction } */
const trackConnectorSelection = value => {
    track({
        event: 'Connector selection',
        type: 'selection',
        action: 'connector selection',
        value, // connector id
    });
};

/** @type { TrackingFunction } */
const trackConnectorDeSelection = value => {
    track({
        event: 'Connector deselection',
        type: 'selection',
        action: 'connector deselection',
        value, // connector id
    });
};

/** @type { TrackingFunction } */
const trackAllConnectorsSelection = value => {
    track({
        event: 'All connectors de/selection',
        type: 'toggle',
        action: 'all connectors selection',
        value, // true for selecting all, false for deselecting all
    });
};

/** @type { TrackingFunction } */
const trackOpenBulkOperationMenu = () => {
    track({
        event: 'Bulk operation menu',
        type: 'click',
        action: 'open bulk operation menu',
    });
};

/** @type { TrackingFunction } */
const trackBulkOperationMenuSelection = value => {
    track({
        event: 'Bulk operation menu - selection',
        type: 'selection',
        action: 'bulk operation',
        value, // the selected operation
    });
};

/** @type { TrackingFunction } */
const trackClearSelection = () => {
    track({
        event: 'Clear selection',
        type: 'click',
        action: 'clear selection',
    });
};

/** @type { TrackingFunction } */
const trackStopCollectionModalSubmit = value => {
    track({
        event: 'Stop collection modal - submit',
        type: 'click',
        action: 'submit or cancel',
        value, // true for submit, false for cancel
    });
};

/** @type { TrackingFunction } */
const deleteConnectorsModalSubmit = value => {
    track({
        event: 'Delete connectors modal - submit',
        type: 'click',
        action: 'submit or cancel',
        value, // true for submit, false for cancel
    });
};

/** @type { TrackingFunction } */
const trackScheduleModalEnable = value => {
    track({
        event: 'Schedule modal - enable',
        type: 'toggle',
        action: 'enable schedule',
        value, // true for enable false for disable
    });
};

/** @type { TrackingFunction } */
const trackScheduleModalSelectingDay = value => {
    track({
        event: 'Schedule modal - selecting day',
        type: 'selection',
        action: 'collection day',
        value, // day as a string
    });
};

/** @type { TrackingFunction } */
const trackScheduleModalSelectingTime = value => {
    track({
        event: 'Schedule modal - selecting time',
        type: 'selection',
        action: 'collection time',
        value, // time as a string example: 9am UTC
    });
};

/** @type { TrackingFunction } */
const trackScheduleModalSubmit = value => {
    track({
        event: 'Schedule modal - submit',
        type: 'click',
        action: 'submit or cancel',
        value, // true for submit, false for cancel
    });
};

/** @type { TrackingFunction } */
const trackOpenThreeDotMenu = value => {
    track({
        event: '3 dot menu',
        type: 'click',
        action: 'opens 3 dot menu',
        value, // connector id
    });
};

/** @type { TrackingFunction } */
const trackThreeDotMenuSelection = value => {
    track({
        event: '3 dot menu - selection',
        type: 'click',
        action: 'open modal',
        value, // the selected modal, i.e. Rename
    });
};

/** @type { TrackingFunction } */
const trackRenameModalInput = value => {
    track({
        event: 'Rename modal - input',
        type: 'input',
        action: 'connector name',
        value, // the new name
    });
};

/** @type { TrackingFunction } */
const trackRenameModalInputSubmit = value => {
    track({
        event: 'Rename modal - submit',
        type: 'click',
        action: 'submit or cancel',
        value, // true for submit, false for cancel
    });
};

/** @type { TrackingFunction } */
const trackCloneModalInput = value => {
    track({
        event: 'Clone modal - input',
        type: 'input',
        action: 'cloned connector name',
        value, // the new name
    });
};

/** @type { TrackingFunction } */
const trackCloneModalSubmit = value => {
    track({
        event: 'Clone modal - submit',
        type: 'click',
        action: 'clone or cancel',
        value, // true for submit, false for cancel
    });
};

/** @type { TrackingFunction } */
const trackPrevAndNextBtns = value => {
    track({
        event: 'Next/Prev buttons',
        type: 'click',
        action: 'page switch',
        value, // prev or next
    });
};

export {
    page,
    trackAddConnector,
    trackSearchConnector,
    trackFilterDropdown,
    trackFilterDropdownSelection,
    trackOpeningConnector,
    trackConnectorSelection,
    trackConnectorDeSelection,
    trackAllConnectorsSelection,
    trackOpenBulkOperationMenu,
    trackBulkOperationMenuSelection,
    trackClearSelection,
    trackStopCollectionModalSubmit,
    deleteConnectorsModalSubmit,
    trackScheduleModalEnable,
    trackScheduleModalSelectingDay,
    trackScheduleModalSelectingTime,
    trackScheduleModalSubmit,
    trackOpenThreeDotMenu,
    trackThreeDotMenuSelection,
    trackRenameModalInput,
    trackRenameModalInputSubmit,
    trackPrevAndNextBtns,
    trackCloneModalInput,
    trackCloneModalSubmit,
};
