import React, { useState } from 'react';
import { isString } from 'lodash';
import { LockedInput, DebouncedInput, Input } from 'ui-components';

/** @typedef { 'text' | 'number' | 'password' | 'email' } Type */
/** @typedef { Event & { target: EventTarget & { value: string} } } ChangeEvent */

const SENSITIVE_VALUE_TAG = '__S=';

/** @type { Type[] } */
const INPUT_TYPES = [
    'text',
    'password',
    'email',
    'number',
];

/**
 * @typedef { object } Props
 * @prop { string } name
 * @prop { string } value
 * @prop { Type } [type]
 * @prop { (e: ChangeEvent) => void } onChange
 * @prop { string } [id]
 * @prop { number } [duration]
 * @prop { boolean } [error]
 * @prop { boolean } [multiline]
 * @prop { boolean } [noFloatLabel]
 * @prop { boolean } [alwaysShowError]
 * @prop { string } [placeholder]
 * @prop { string } [label]
 * @prop { string } [spacing]
 * @prop { string } [width]
 * @prop { React.ReactNode } [helperText]
 */

/** @type { React.FC<Props> } */
const SecretInput = ({ value, duration, type, ...props }) => {
    // I'd like to set the sensitive mode based on the initial value
    // because this value can be changed later on and loose the prefix
    const [initialValueIsSensitive] = useState(isSensitiveValue(value));

    const inputType = isValidType(type) ? type : 'text';

    const inputValue = type === 'number' && isNumeric(value)
        ? toNumber(value)
        : toString(value);

    if (initialValueIsSensitive) {
        const sensitiveValue = getSensitiveValue(inputValue);

        return (
            <LockedInput
                type={inputType}
                value={sensitiveValue}
                debounceDuration={duration}
                {...props}
            />
        );
    }

    if (duration) {
        return (
            <DebouncedInput
                type={inputType}
                value={inputValue}
                duration={duration}
                {...props}
            />
        );
    }

    return <Input type={inputType} value={inputValue} {...props} />;
};

/** @type { (value: any) => any } */
const getSensitiveValue = value => (
    isString(value) ? value.replace(SENSITIVE_VALUE_TAG, '') : value
);

/** @type { (value: any) => boolean } */
const isSensitiveValue = value => isString(value)
    && value.startsWith(SENSITIVE_VALUE_TAG);

/** @type { (type: any) => type is Type } */
const isValidType = type => INPUT_TYPES.includes(type);

/** @type { (value: any) => boolean } */
const isNumeric = value => /^\d+$/.test(value);

/** @type { (value: any) => string | '' } */
const toString = value => String(value || '');

/** @type { (value: any) => number | '' } */
const toNumber = value => {
    const number = parseInt(value, 10);
    return !isNaN(number) ? number : '';
};

export default SecretInput;
