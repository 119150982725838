import React, { useCallback } from 'react';

import {
    Layout,
    Card,
    Typography,
    Image,
    Button,
} from 'ui-components';

import openSupportChat from '../use-support-chat';

import enterpriseImagePath from '../../images/enterprise.svg';

const EnterprisePlan = (props) => {
    const subject = 'I would like to know more about Enterprise features';
    const onClick = useCallback(() => {
        openSupportChat(subject);
    }, []);
    return (
        <Layout width="100">
            <Card contentSpacing="p-0" {...props}>
                <Layout width="100" flex alignItems="center">
                    <Layout width="25" spacing="pt-4 pb-5">
                        <EnterpriseImage spacing="m-0" />
                    </Layout>

                    <Layout width="40" spacing="pt-5 pb-4">
                        <Typography variant="h4">Custom</Typography>
                        <Typography variant="subtitle1">
                            Work with an account executive to create a tailored plan
                            that fits your business needs
                        </Typography>
                    </Layout>

                    <Layout width="35" spacing="p-5">
                        <ContactButton spacing="m-0 mt-2" onClick={onClick} />
                    </Layout>
                </Layout>
            </Card>
        </Layout>
    );
};

function EnterpriseImage(props) {
    return (
        <Image
            src={enterpriseImagePath}
            width="100"
            height="143px"
            size="contain"
            repeat="no-repeat"
            position="center"
            {...props}
        />
    );
}

function ContactButton(props) {
    return <Button width="100" color="error" {...props}>Contact Us</Button>;
}

export default EnterprisePlan;
