import React from 'react';
import styled from 'styled-components';

import {
    Layout,
} from 'ui-components';

import WorkbenchVisualizationToggle from './workbench-visualization-toggle';
import WorkbenchQuerySelector from './workbench-query-selector';
import WorkbenchSaveDropdown from './workbench-save-dropdown';
import WorkbenchExport from './workbench-export';
import WorkbenchCopyDropdown from './workbench-copy-dropdown';

/** @typedef { import('models/queries/__types').QueryResult } QueryResult */
/** @typedef { import('./__types').VisualizationType } VisualizationType */
/** @typedef { import('./__types').VisualizationReport } VisualizationReport */

/**
 * @typedef { object } PaginationProps
 *
 * @prop { number } page
 * @prop { (newPage: number) => void } updatePage
 * @prop { number } totalItems
 * @prop { boolean } loading
 * @prop { number } pageSize
 */

/**
 * @typedef { object } OtherStatusProps
 *
 * @prop { Date } [queryStartedAt]
 * @prop { Date } [queryEndedAt]
 * @prop { QueryResult } [queryResultPage]
 * @prop { boolean } pageLoading
 * @prop { boolean } showPagination
 * @prop { boolean } isSelectQuery
 * @prop { string } [queryError]
 * @prop { boolean } running
 * @prop { VisualizationReport } visualizationReport
 * @prop { (changes: Partial<VisualizationReport>) => void } onVisualizationReportChanged
 * @prop { (id: QueryResult['id'], updates: Partial<QueryResult>) => void } updateQueryResult
 * @prop { () => void } toggleSaveAsDialog
 * @prop { () => void } toggleSaveDialog
 */

/**
 * @typedef { object } Props
 *
 * @prop { string } [queryError]
 * @prop { Date } [queryStartedAt]
 * @prop { Date } [queryEndedAt]
 * @prop { boolean } isSelectQuery
 * @prop { QueryResult } [queryResultPage]
 * @prop { VisualizationReport } visualizationReport
 * @prop { (changes: Partial<VisualizationReport>) => void } onVisualizationReportChanged
 * @prop { (id: QueryResult['id'], updates: Partial<QueryResult>) => void } updateQueryResult
 * @prop { () => void } toggleSaveAsDialog
 * @prop { () => void } toggleSaveDialog
 * @prop { QueryResult[] } queryResults
 * @prop { QueryResult } [activeResult]
 * @prop { Status } [parentQueryStatus]
 * @prop { (result: QueryResult) => void } changeActiveQueryResult
 */

/** @type { React.FC<Props> } */
const WorkbenchStatusBar = React.memo(({
    queryError,
    queryStartedAt,
    queryEndedAt,
    isSelectQuery,
    queryResults,
    activeResult,
    parentQueryStatus,
    changeActiveQueryResult,
    queryResultPage,
    visualizationReport,
    onVisualizationReportChanged,
    updateQueryResult,
    toggleSaveAsDialog,
    toggleSaveDialog,
}) => {
    const hasData = !!queryResultPage?.data?.rows?.length
        && isSelectQuery
        && !queryError;

    return (
        <StatusBarWrapper width="100" flex alignItems="center" justifyContent="space-between">
            <Layout width={hasData ? '70' : '100'} flex alignItems="center" spacing="pl-4">
                <WorkbenchQuerySelector
                    queryResults={queryResults}
                    activeResult={activeResult}
                    changeActiveQueryResult={changeActiveQueryResult}
                    queryStartedAt={queryStartedAt}
                    queryEndedAt={queryEndedAt}
                    parentQueryStatus={parentQueryStatus}
                />

                <WorkbenchSaveDropdown
                    queryResultPage={queryResultPage}
                    isSelectQuery={isSelectQuery}
                    queryError={queryError}
                    toggleSaveAsDialog={toggleSaveAsDialog}
                    toggleSaveDialog={toggleSaveDialog}
                />

                <WorkbenchExport
                    queryResultPage={queryResultPage}
                    isSelectQuery={isSelectQuery}
                    queryError={queryError}
                    updateQueryResult={updateQueryResult}
                />

                <WorkbenchCopyDropdown
                    queryResultPage={queryResultPage}
                    isSelectQuery={isSelectQuery}
                    queryError={queryError}
                />
            </Layout>

            {hasData && (
                <Layout
                    width="30"
                    flex
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing="pr-4"
                >
                    <WorkbenchVisualizationToggle
                        queryData={queryResultPage.data}
                        visualizationReport={visualizationReport}
                        onVisualizationReportChanged={onVisualizationReportChanged}
                    />
                </Layout>
            )}
        </StatusBarWrapper>
    );
});

const StatusBarWrapper = styled(Layout)`
    && {
        min-height: 54px;
    }
`;

export default WorkbenchStatusBar;
