/* eslint-disable complexity */
import React, { useCallback, useReducer, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEqual, noop, omit, pick, isEmpty } from 'lodash';
import { Typography } from 'ui-components';


import { reportError } from '../../../lib/error-reporter';
import * as Sources from '../../../models/sources';
import * as Schemas from '../../../models/schemas';
import * as Recipes from '../../../models/recipes';

import useSelectors from './use-selectors';
import reducer, { initialState } from './reducer';

import { useNavBreadCrumbs } from '../../../shared/context-providers';
import { useJobCancelDialog } from '../../../shared/job/job-cancel-dialog';
import gotoUrl from '../../../lib/goto-url';

/** @typedef { import('models/sources/__types').DynamicParamArgs } DynamicParamArgs */
/** @typedef { import('models/sources/__types').DynamicParamResponse } DynamicParamResponse */
/** @typedef { import('models/sources/__types').ValidationParamResponse } ValidationParamResponse */
/** @typedef { import('models/sources/__types').Param } Param */
/** @typedef { import('models/sources/__types').Schedule } Schedule */
/** @typedef { import('models/sources/__types').Source } Source */
/** @typedef { import('models/sources/__types').SourceType } SourceType */
/** @typedef { import('models/schemas/__types').Schema } Schema */
/** @typedef { import('models/recipes').Recipe } Recipe */
/** @typedef { import('./__types').State } State */
/** @typedef { ReturnType<useSourcePage> } SourcePageData */

const contactDSFeedback = () => {
    gotoUrl('mailto: dsfeedback@panoply.io');
};

const chipTooltipContent = (
    <>
        <Typography color="white" inline>
            Send feedback (bugs, feature requests, etc.) to
        </Typography>
        &nbsp;
        <Typography
            color="white"
            inline
            link
            onClick={contactDSFeedback}
        >
            dsfeedback@panopy.io
        </Typography>
    </>
);

/** How often do we fetch the source while it is collecting */
const POLLING_INTERVAL_MS = 6000;

/** @type { (code: string) => string } */
const getErrorMsgFromCode = code => (
    code === 'NOT_FOUND'
        ? 'Source not found'
        : 'Uh oh. Something went wrong. Try loading the page again'
);

const useSourcePage = (/** @type { Source['id'] } */ sourceId) => {
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const derivedState = useSelectors(state);
    const {
        loading,
        loadingError,
        deleting,
        deletingError,
        source,
        sourceDefaults,
        pendingChanges,
        editingSource,
        flattenedParams,
        sourceType,
        saving,
        savingError,
        collectingError,
        refreshingError,
        schedule,
        lastSavedSchedule,
        scheduleSaving,
        schedulingError,
        actionsDropdownOpen,
        deleteModalOpen,
        renameModalOpen,
        scheduleModalOpen,
        syncModalOpen,
        fieldValidationErrors,

        historyLoading,
        historyError,
        historyJobs,

        recipes,
    } = state;

    const refreshSource = useCallback(async () => {
        if (source == null) {
            return;
        }

        try {
            const freshSource = await Sources.refreshSource(source.id, flattenedParams);

            freshSource.collectConfig = source?.collectConfig;

            dispatch({
                type: 'SET_SOURCE',
                source: freshSource,
            });

        } catch (/** @type { any } */ error) {
            reportError(error, { sourceId });
            dispatch({
                type: 'SOURCE_REFRESHING_ERROR',
                error: getErrorMsgFromCode(error.code),
            });
        }
    }, [sourceId, source, flattenedParams]);

    const {
        jobToCancel,
        onCancelJob,
        onCancelJobConfirm,
        onCloseCancelDialog,
        error: cancelJobError,
        loading: cancelJobLoading,
    } = useJobCancelDialog(refreshSource);

    const [activeTab, setActiveTab] = useState('general');
    const savedRef = useRef(false);

    const [showSavedMessage, setShowSavedMessage] = useState(false);
    const collectAfterSaveRef = useRef(false);

    // Get data on load
    useEffect(() => {
        const loadSource = async () => {
            dispatch({ type: 'SOURCE_LOADING', loading: true });

            try {
                const [sourceAndType, sourceDefaults] = await Promise.all([
                    Sources.fetchSourceAndType(sourceId),
                    Sources.fetchSourceDefaults(sourceId),
                ]);

                const [source, sourceType, flattenedParams] = sourceAndType;

                const collectConfig = await Sources.fetchSourceCollectConfig(sourceId);

                if (collectConfig) {
                    source.collectConfig = collectConfig;
                }

                const schedule = Sources.getSourceSchedule(source);

                const recipes = await Recipes.fetchRecipes([sourceType.id]);

                dispatch({
                    type: 'SOURCE_LOADED',
                    source,
                    sourceDefaults,
                    sourceType,
                    flattenedParams,
                    schedule,
                    recipes,
                });

            } catch (/** @type { any } */ error) {
                reportError(error, { sourceId });
                dispatch({
                    type: 'SOURCE_LOADING_ERROR',
                    error: getErrorMsgFromCode(error.code),
                });
            }
        };

        const needsSource = (
            (sourceId && !source)
            || (source && sourceId !== source.id)
        );

        if (needsSource && !loading && !loadingError) {
            loadSource();
        }

    }, [sourceId, source, loading, loadingError]);

    /** @type { () => Promise<Schema[]>} */
    const loadSchemas = useCallback(async () => {
        try {
            const schemas = await Schemas.fetchSchemas();
            return schemas;
        } catch (/** @type { any } */ err) {
            reportError(err, { sourceId });
            throw new Error('Error when fetching schemas');
        }
    }, [sourceId]);

    // Poll for updates if source is collecting
    useEffect(() => {
        if (source == null || sourceType == null || !Sources.isSourceCollecting(source)) {
            return noop;
        }

        const timeout = setTimeout(
            refreshSource,
            POLLING_INTERVAL_MS,
        );

        return () => clearTimeout(timeout);
    }, [sourceType, source, refreshSource]);

    useEffect(() => {
        if (!editingSource || !sourceType) {
            return;
        }
        const errors = Sources.getFieldValidationErrors(editingSource, flattenedParams);
        if (!isEqual(errors, fieldValidationErrors)) {
            dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', errors });
        }
    }, [editingSource, sourceType, fieldValidationErrors, flattenedParams]);


    const loadHistory = useCallback(async () => {
        if (!source) {
            return;
        }
        try {
            dispatch({ type: 'SOURCE_HISTORY_LOADING' });
            const data = await Sources.fetchSourceHistory(source); // TODO: Memoize
            dispatch({ type: 'SOURCE_HISTORY_LOADED', data });
        } catch (/** @type { any } */ err) {
            reportError(err, { sourceId: source.id });
            dispatch({
                type: 'SOURCE_HISTORY_ERROR',
                error: 'Uh oh. Something went wrong.'
                    + ' If possible, save, then try loading the page again',
            });
        }
    }, [source]);

    const openActionsDropdown = useCallback(() => {
        dispatch({ type: 'OPEN_ACTIONS_DROPDOWN' });
    }, []);

    const closeActionsDropdown = useCallback(() => {
        dispatch({ type: 'CLOSE_ACTIONS_DROPDOWN' });
    }, []);

    const openDeleteModal = useCallback(() => {
        dispatch({ type: 'OPEN_DELETE_MODAL' });
    }, []);

    const closeDeleteModal = useCallback(() => {
        dispatch({ type: 'CLOSE_DELETE_MODAL' });
    }, []);

    const openRenameModal = useCallback(() => {
        dispatch({ type: 'OPEN_RENAME_MODAL' });
    }, []);

    const closeRenameModal = useCallback(() => {
        dispatch({ type: 'CLOSE_RENAME_MODAL' });
    }, []);

    const openScheduleModal = useCallback(() => {
        dispatch({ type: 'OPEN_SCHEDULE_MODAL' });
    }, []);

    const closeScheduleModal = useCallback(() => {
        dispatch({ type: 'CLOSE_SCHEDULE_MODAL' });
    }, []);

    const openSyncModal = useCallback(() => {
        dispatch({ type: 'OPEN_SYNC_MODAL' });
    }, []);

    const closeSyncModal = useCallback(() => {
        dispatch({ type: 'CLOSE_SYNC_MODAL' });
    }, []);

    // Updates pending changes with a list of source props
    /** @type { (editingSource: Partial<Source>, replace?: boolean) => void } */
    const updateSource = useCallback((sourceProps, replace = false) => {
        if (!editingSource) {
            return;
        }

        if (sourceType && sourceType.params) {
            const propNames = Object.keys(sourceProps);
            const paramsHaveChanged = sourceType.params.some(
                ({ name }) => propNames.includes(name),
            );
            if (paramsHaveChanged) {
                const e = new Error('updateSource is not supposed to be used to set param values');
                reportError(e, { sourceId: editingSource.id });
                throw e;
            }
        }

        // List of source properties that are immutable
        const immutable = [
            'id',
            'type',
            'job',
        ];
        const validProps = omit(sourceProps, immutable);
        dispatch({
            type: 'ADD_PENDING_CHANGES',
            changes: { ...validProps },
            replace,
        });
    }, [editingSource, sourceType]);

    /** @type { (partialSource: Partial<Source>) => void } */
    const updateAdvanced = useCallback((partialSource, replace = false) => {
        updateSource(partialSource, replace);
    }, [updateSource, source, sourceType]);

    /** @type { (name: Param['name'], value: any, replace?: boolean) => void } */
    const setParamValue = useCallback((name, value, replace) => {
        if (editingSource == null || sourceType == null) {
            return;
        }

        const flattenedParam = Sources.findFlattenedParam(flattenedParams, name);

        const [updatedSource, affectedParams] = Sources.setParamValue(
            flattenedParams,
            editingSource,
            flattenedParam,
            value,
        );

        // TODO: Remove this hack once we stop spreading oauth param values over the source
        const paramsToChange = updatedSource.__clientBackCompat
            ? [...affectedParams, ...updatedSource.__clientBackCompat.oauthValueKeys]
            : [...affectedParams];

        dispatch({
            type: 'ADD_PENDING_CHANGES',
            changes: pick(updatedSource, paramsToChange),
            paramStatesToReset: affectedParams.filter(n => n !== name),
            replace,
        });
    }, [editingSource, sourceType, flattenedParams]);


    /** @type { (name: Param['name']) => any } */
    const getParamValue = useCallback((name) => {
        if (editingSource == null || sourceType == null) {
            return null;
        }
        const flattenedParam = Sources.findFlattenedParam(flattenedParams, name);
        return Sources.getParamValue(editingSource, flattenedParam);
    }, [editingSource, sourceType, flattenedParams]);

    /** @type { (name: Param['name'], newState: any) => void } */
    const setParamState = useCallback((name, newState) => {
        dispatch({ type: 'SET_PARAM_STATE', name, newState });
    }, []);

    /** @type { (name: Param['name']) => any } */
    const getParamState = useCallback(
        name => state.paramState[name] || {},
        [state.paramState],
    );

    /** @type { (name: Param['name']) => Promise<ValidationParamResponse> } */
    const loadValidationParam = useCallback(async (name) => {
        if (source == null || sourceType == null || editingSource == null) {
            throw new Error('Cannot load validation param, source not loaded');
        }

        try {
            const flattenedParam = Sources.findFlattenedParam(flattenedParams, name);

            const response = await Sources.loadValidationParam(
                editingSource,
                flattenedParams,
                flattenedParam
            );

            return response;

        } catch (/** @type { any } */ err) {
            reportError(err, { name, sourceId });

            throw new Error(
                'So sorry, something blocked the validation. Please check '
                + `your ${sourceType.title} credentials and try again.`,
            );
        }
    }, [source, sourceType, editingSource, sourceId, flattenedParams]);

    /**
     * @type { (
     *     name: Param['name'],
     *     args: DynamicParamArgs
     * ) => Promise<DynamicParamResponse['data']> }
     */
    const loadDynamicParam = useCallback(async (name, args) => {
        let res;
        try {
            if (source == null || editingSource == null || sourceType == null) {
                throw new Error('Cannot load dynamic param, source not loaded');
            }

            const flattenedParam = Sources.findFlattenedParam(flattenedParams, name);

            res = await Sources.loadDynamicParam(
                editingSource,
                flattenedParams,
                flattenedParam,
                args,
            );
        } catch (/** @type { any } */ err) {
            // This line is commented out because we don't have proper actions
            // for these errors. The overwhelming majority of the errors we
            // get are user based errors e.g. wrong credentials.
            // Once the data-sources return errors that allow us to distinguish
            // between error types, we will uncomment this line.
            // reportError(err, { name, sourceId });

            // Reset token and dependent params if the error is oAuth specific
            if (err.message && err.message.includes('access token')) {
                setParamState('access_token', {});
                setParamValue(name, null);
            }

            // Error state for dynamic-param is handled in its component
            throw new Error(
                'Oh no, seems there was a problem. Check the connection details, then try again',
            );
        }

        // The response may contain a refreshed access token that was acquired
        // by the source while it was fulfilling this request. We want to
        // update the value of the access_token oauth param and save the source
        // immediately without touching pending changes or causing dependent
        // params to reset. This is also why we're not using setParamValue to
        // set the value of the oauth param.
        if (res.refreshedToken) {
            const tokenChanged = res.refreshedToken !== editingSource.access_token;

            if (!tokenChanged) {
                return res.data;
            }

            const updatedSource = {
                ...source,
                access_token: res.refreshedToken,
                oauth_token: res.refreshedToken,
            };

            try {
                await Sources.saveSource(source, flattenedParams);
            } catch (/** @type { any } */ err) {
                reportError(err, { sourceId });
                // Error state for dynamic-param is handled in its component
                throw new Error('Uh oh. Something went wrong. Try loading the page again.');
            }

            dispatch({ type: 'SET_SOURCE', source: updatedSource });
        }

        return res.data;

    }, [
        editingSource,
        source,
        sourceType,
        flattenedParams,
        sourceId,
        setParamValue,
        setParamState,
    ]);


    /** @type { (param: Param) => boolean } */
    const isParamRequired = useCallback((param) => {
        if (!editingSource) {
            return false;
        }
        return Sources.isParamRequired(editingSource, param);
    }, [editingSource]);

    /** @type { (name: Param['name']) => boolean } */
    const areParamDepsMet = useCallback((name) => {
        if (editingSource == null || sourceType == null) {
            return false;
        }
        const [param] = Sources.findFlattenedParam(flattenedParams, name);
        return Sources.areParamDepsMet(editingSource, param);
    }, [sourceType, editingSource, flattenedParams]);

    /** @type { (partialSchedule: Partial<Schedule>) => void } */
    const updateSchedule = useCallback((partialSchedule) => {
        if (!schedule) {
            return;
        }

        const newSchedule = { ...schedule, ...partialSchedule };

        updateSource({
            schedule: newSchedule.dayOfWeek,
            schedule_hour: newSchedule.hour,
            schedule_minute: newSchedule.minute,
        });

        dispatch({ type: 'SOURCE_SCHEDULE_UPDATED', schedule: newSchedule });
    }, [schedule, source, sourceType, updateSource]);


    /**
     * @type { (
     *  schedule: Schedule, source: Source, sourceType: SourceType
     * ) => Promise<void> }
     */
    const saveSchedule = useCallback(async (schedule, source, sourceType) => {

        dispatch({ type: 'SOURCE_SCHEDULE_SAVING' });

        try {
            await Sources.syncSourceSchedule(schedule, source, sourceType);
            dispatch({ type: 'SOURCE_SCHEDULE_SAVED', schedule });
        } catch (/** @type { any } */ err) {
            reportError(err, { sourceId: source.id, schedule });
            dispatch({
                type: 'SOURCE_SCHEDULE_SAVING_ERROR',
                error: 'Uh oh. Something went wrong.'
                    + ' If possible, save, then try changing the schedule again',
            });

        }

    }, [lastSavedSchedule]);


    const saveSource = useCallback(async () => {

        if (editingSource == null || sourceType == null) {
            return;
        }

        try {
            dispatch({ type: 'SOURCE_SAVING' });
            const { collectConfig, ...sourceToSave } = editingSource;

            if (!isEmpty(collectConfig)) {
                await Promise.all([
                    Sources.saveSource(sourceToSave, flattenedParams),
                    Sources.saveSourceCollectConfig(collectConfig, sourceToSave.id),
                ]);
            } else {
                await Sources.saveSource(sourceToSave, flattenedParams);
            }

            savedRef.current = true;

            setShowSavedMessage(true);

            setTimeout(() => {
                setShowSavedMessage(false);
            }, 2000);

            dispatch({ type: 'SOURCE_SAVED' });
        } catch (/** @type { any } */ err) {
            reportError(err, { sourceId: editingSource.id, sourceTypeId: sourceType.id });
            dispatch({
                type: 'SOURCE_SAVING_ERROR',
                error: 'Uh oh. Something went wrong. Try saving again in a few seconds',
            });
        }
    }, [editingSource, sourceType, activeTab, flattenedParams]);


    const collectSource = useCallback(async () => {
        if (source == null || sourceType == null) {
            return;
        }

        try {
            if (sourceType.offline) {
                throw new Error(`Source Type is offline: ${sourceType.id}`);
            }

            if (!Sources.isSourceCollectable(source, flattenedParams)) {
                throw new Error('Tried to collect a non-collectable source');
            }

            await Sources.collectSource(source);
            const loadedSource = await Sources.refreshSource(source.id, flattenedParams);
            loadedSource.collectConfig = source?.collectConfig;
            dispatch({ type: 'SET_SOURCE', source: loadedSource });
        } catch (/** @type { any } */ err) {
            reportError(err, { sourceId: source.id, sourceTypeId: sourceType.id });
            dispatch({
                type: 'SOURCE_COLLECTING_ERROR',
                error: 'Uh oh. Something went wrong. Try collecting again in a few seconds',
            });

        }
    }, [source, sourceType, activeTab, flattenedParams]);

    const enableCollectAfterSave = useCallback(() => {
        collectAfterSaveRef.current = true;
    }, []);

    const deleteSource = useCallback(async () => {
        if (source == null) {
            return;
        }

        dispatch({ type: 'SOURCE_DELETING' });

        try {
            await Sources.deleteSource(source.id);

            if (source.collectConfig?.id) {
                await Sources.deleteSourceCollectConfig(source.id);
            }

            dispatch({ type: 'SOURCE_DELETED' });
            navigate('/sources');
        } catch (/** @type { any } */ err) {
            reportError(err, { sourceId: source.id });
            dispatch({
                type: 'SOURCE_DELETING_ERROR',
                error: 'Uh oh. Something went wrong. Try deleting again in a few seconds',
            });
        }
    }, [source, navigate]);

    const clearPendingChanges = useCallback(() => (
        dispatch({ type: 'SET_PENDING_CHANGES', changes: {} })
    ), []);

    /** @type { (newTitle: string) => void } */
    const updateTitle = useCallback(async (newTitle) => {
        if (source == null || sourceType == null) {
            return;
        }

        try {
            const updatedSource = { ...source, title: newTitle };
            dispatch({ type: 'SOURCE_SAVING' });
            await Sources.saveSource(updatedSource, flattenedParams);
            dispatch({
                type: 'SET_SOURCE',
                source: updatedSource,
            });
            // Remove title from pending changes since it got saved
            if (pendingChanges && pendingChanges.title) {
                const pendingChangesWithoutTitle = omit(pendingChanges, 'title');
                dispatch({
                    type: 'SET_PENDING_CHANGES',
                    changes: pendingChangesWithoutTitle,
                });
            }
        } catch (/** @type { any } */ error) {
            reportError(error, { sourceId: source.id, sourceTypeId: sourceType.id });
            dispatch({
                type: 'SOURCE_SAVING_ERROR',
                error: 'Uh oh. Something went wrong. Try saving again in a few seconds',
            });
        }
    }, [source, sourceType, pendingChanges, flattenedParams]);


    // Track collectable
    useEffect(() => {
        if (source == null || sourceType == null) {
            return;
        }

        if (!savedRef.current || !derivedState.collectable) {
            return;
        }

        savedRef.current = false;

        if (collectAfterSaveRef.current) {
            collectSource();
        }

        collectAfterSaveRef.current = false;

    }, [activeTab, derivedState.collectable, source, sourceType, collectSource]);

    const { setNavBreadCrumbItems } = useNavBreadCrumbs();

    useEffect(() => {
        if (sourceType) {
            setNavBreadCrumbItems([
                {
                    text: 'Connectors',
                    link: '/sources',
                },
                {
                    text: source?.title || '',
                    titleTooltip: source?.title,
                    icon: derivedState.icon,
                    iconTooltip: sourceType.title,
                    chip: sourceType?.beta ? 'New' : '',
                    chipTooltip: chipTooltipContent,
                },
            ]);
        }
    }, [derivedState.icon, sourceType, source]);

    return {
        ...derivedState,

        source: editingSource,
        sourceDefaults,
        sourceType,
        flattenedParams,
        collectingError,
        refreshingError,
        loading,
        loadingError,
        deletingError,
        deleting,
        actionsDropdownOpen,
        deleteModalOpen,
        renameModalOpen,
        scheduleModalOpen,
        syncModalOpen,
        schedule,
        scheduleSaving,
        schedulingError,
        fieldValidationErrors,

        areParamDepsMet,
        isParamRequired,
        closeActionsDropdown,
        closeDeleteModal,
        closeRenameModal,
        closeScheduleModal,
        closeSyncModal,
        collectSource,
        deleteSource,
        getParamValue,
        getParamState,
        loadDynamicParam,
        loadValidationParam,
        openActionsDropdown,
        openDeleteModal,
        openRenameModal,
        openScheduleModal,
        openSyncModal,
        saveSource,
        savingError,
        setParamState,
        setParamValue,
        updateSchedule,
        updateAdvanced,
        updateTitle,

        loadHistory,
        historyLoading,
        historyError,
        historyJobs,

        loadSchemas,

        activeTab,
        setActiveTab,

        enableCollectAfterSave,
        saving,
        showSavedMessage,

        refreshSource,

        saveSchedule,

        jobToCancel,
        onCancelJob,
        onCancelJobConfirm,
        onCloseCancelDialog,
        cancelJobError,
        cancelJobLoading,

        clearPendingChanges,
        recipes,
    };

};

export default useSourcePage;
