import React, { useState, useEffect } from 'react';
import moment from 'moment';

/**
 * @typedef { object } Props
 * @prop { number } timestamp
 * @prop { boolean } [withoutSuffix]
 * @prop { boolean } [withoutPrefix]
 */

/** @type { React.FC<Props> } */
const LastUpdated = ({
    timestamp,
    withoutSuffix,
    withoutPrefix,
}) => {
    const [, forceRender] = useState({});

    useEffect(() => {
        // Force a render every 5 seconds
        // So that the "time ago" is current
        const interval = setInterval(() => {
            forceRender({});
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    if (!timestamp) {
        return null;
    }

    const updatedAt = moment(timestamp).fromNow(withoutSuffix);

    return (
        <>
            {!withoutPrefix ? `Refreshed ${updatedAt}` : updatedAt}
        </>
    );
};


export default LastUpdated;
