import { useState, useCallback } from 'react';

/**
 * @typedef { object } ReturnProps
 * @prop { number } report
 * @prop { () => void } onReportChanged
 * @prop { () => void } onReportCleared
 */

/** @type { () => ReturnProps } */
const useReportsDrawer = () => {
    const [report, setReport] = useState(0);

    const onReportChanged = useCallback(e => {
        setReport(Number(e.target.value));
    }, []);

    const onReportCleared = useCallback(() => {
        setReport(0);
    }, []);

    return {
        report,
        onReportChanged,
        onReportCleared,
    };
};

export default useReportsDrawer;
