import { useState, useCallback } from 'react';
import { reportError } from '../../lib/error-reporter';
import { useToast } from '../context-providers';
import * as ViewsModel from '../../models/views';

/** @typedef { import('models/views/types').View } View */
/** @typedef { import('models/views/types').CreateViewParams } CreateViewParams */
/** @typedef { import('models/views/types').EditViewParams } EditViewParams */
/** @typedef { import('models/views/types').RemoveViewParams } RemoveViewParams */
/** @typedef { import('./use-workbench-data').WorkbenchData } WorkbenchData */

/**
 * @typedef { object } Props
 * @prop { WorkbenchData['fetchViews'] } fetchViews
 */

/**
 * @param { Props } props
 */
function useWorkbenchViews({ fetchViews }) {
    const { openToast } = useToast();

    const [creatingView, setSavingAsView] = useState(false);
    const [editingView, setSavingView] = useState(false);
    const [removingView, setDeletingView] = useState(false);

    /** @type { (params: CreateViewParams) => Promise<View> } */
    const createView = useCallback(async (params) => {
        setSavingAsView(true);

        try {
            const view = await ViewsModel.createView(params);

            openToast({
                message: `The view "${view.name}" was saved.`,
                type: 'success',
                variant: 'outline',
            });

            fetchViews(); // refresh

            return view;

        } catch (/** @type { any } */ err) {
            reportError(err, params);

            openToast({
                message: err?.message || err?.data?.message || 'Unknown error occurred',
                type: 'error',
                variant: 'outline',
            });

            throw err;

        } finally {
            setSavingAsView(false);
        }
    }, [fetchViews, openToast]);

    /** @type { (params: EditViewParams) => Promise<View> } } */
    const editView = useCallback(async (params) => {
        setSavingView(true);

        try {
            const view = await ViewsModel.editView(params);

            openToast({
                message: `The view "${view.name}" was saved.`,
                type: 'success',
                variant: 'outline',
            });

            fetchViews(); // refresh

            return view;

        } catch (/** @type { any } */ err) {
            reportError(err, params);

            openToast({
                message: err?.message || err?.data?.message || 'Unknown error occurred',
                type: 'error',
                variant: 'outline',
            });

            throw err;

        } finally {
            setSavingView(false);
        }
    }, [fetchViews, openToast]);

    /** @type { (params: RemoveViewParams) => Promise<void> } */
    const removeView = useCallback(async (params) => {
        setDeletingView(true);

        try {
            await ViewsModel.removeView(params);

            openToast({
                message: `The view "${params.name}" was deleted.`,
                type: 'success',
                variant: 'outline',
            });

            fetchViews(); // refresh

        } catch (/** @type { any } */ err) {
            reportError(err, params);

            openToast({
                message: err?.message || err?.data?.message || 'Unknown error occurred',
                type: 'error',
                variant: 'outline',
            });

            throw err;

        } finally {
            setDeletingView(false);
        }
    }, [fetchViews, openToast]);

    return {
        creatingView,
        editingView,
        removingView,
        createView,
        editView,
        removeView,
    };
}

export default useWorkbenchViews;
