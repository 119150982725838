
/** @typedef { import('models/sources/__types').Source } Source */
/** @typedef { import('./__types').Integration } Integration */

/** @type { (word: string, terms: string[]) => boolean } */
const filterByString = (word, terms) => {
    if (typeof word !== 'string') {
        return false;
    }

    const lowerCaseWord = word.toLowerCase();
    return terms.some(term => lowerCaseWord.includes(term));
};

/** @type { (words: string[], terms: string[]) => boolean } */
const filterByArray = (words, terms) => Array.isArray(words)
    && words.some(word => filterByString(word, terms));

/**
 * @type { <T extends Source | Integration>(
 *     keyword: string,
 *     sources: T[]
 * ) => T[] }
 */
const filterByKeyword = (keyword, sources) => {
    const delimiter = ' ';
    const terms = keyword
        .trim()
        .replace(/\s+/g, delimiter)
        .split(delimiter)
        .map(term => term.toLowerCase());

    return sources && sources.filter(({
        title, keywords, categories,
    }) => filterByString(title, terms)
        || filterByArray(keywords, terms)
        || filterByArray(categories, terms));
};

/**
 * @type { <T extends Source | Integration>(
 *     keyword: string,
 *     allSources: T[],
 *     showHidden: boolean
 * ) => T[] }
 */
const filterIntegrations = (keyword, allSources, showHidden) => {
    if (!allSources.length) {
        return [];
    }

    const unfilteredSources = showHidden
        ? allSources
        : allSources.filter(source => !source.hidden);

    if (keyword) {
        return filterByKeyword(keyword, unfilteredSources);
    }

    return unfilteredSources;
};

export default filterIntegrations;
