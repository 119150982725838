import React, { useState } from 'react';
import { Button, Callout, Dialog, Layout, Typography } from 'ui-components';
import { removeDashboard } from '../../models/dashboards';


/** @typedef { import('models/dashboards/types').Dashboard} Dashboard */

/**
 * @typedef { object } DeleteDashboardDialogProps
 * @property { Dashboard | null } dashboard
 * @property { boolean } isOpen
 * @property { (dashboard: Dashboard) => void } onClose
 * @property { (id: string) => void } removeDashboardListItem
 */

/** @type { React.FC<DeleteDashboardDialogProps> } */
const DeleteDashboardDialog = ({
    dashboard,
    isOpen,
    onClose,
    removeDashboardListItem,
}) => {
    const id = dashboard?.id;
    const currentTitle = dashboard?.title;

    const [error, setError] = useState(null);

    const deleteDashboardListItem = async (id) => {
        try {
            await removeDashboard(id);
            removeDashboardListItem(id);
            onClose();
        } catch (err) {
            setError(err.data);
        }
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            actions={[
                <Button
                    key={'deleteDialog-cancel-btn'}
                    onClick={onClose}
                    type="plain"
                >
                    Cancel
                </Button>,
                <Button
                    key={'deleteDialog-rename-btn'}
                    color="primary"
                    onClick={() => deleteDashboardListItem(id)}
                >
                    Delete
                </Button>,
            ]}
        >
            <Layout>
                <Typography
                    spacing="mt-4"
                    color="text"
                    variant="h6"
                    align="center"
                >
                    Delete &quot;{currentTitle}&quot;?
                </Typography>

                <Typography spacing="mt-4" color="text" variant="body1" align="center">
                    Remove this Dashboard.
                </Typography>

                <Typography spacing="mt-4" color="secondaryText" variant="body2" align="center">
                    However, any connected reports will not be deleted.
                </Typography>

                {error && <Callout color="error">{error.message}</Callout>}

            </Layout>
        </Dialog>
    );

};

export default DeleteDashboardDialog;
