import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import * as ReportsModel from '../../models/reports';
import * as DatabaseObjectsModel from '../../models/database-objects';
import * as SchemasModel from '../../models/schemas';
import { useSession } from '../context-providers';

/** @typedef { ReturnType<typeof useWorkbenchData> } WorkbenchData */

function useWorkbenchData() {
    const [session] = useSession();

    // Currently, reports are visible to those who can manage reports
    const canViewReports = session.scopes.includes('reports:manage');

    const [dataIsLoaded, setDataIsLoaded] = useState(false);

    const { refetch: fetchReports, ...reportsReq } = useQuery({
        queryKey: ['reports'],
        queryFn: () => {
            if (!canViewReports) {
                return [];
            }
            return ReportsModel.fetchReports();
        },
    });

    const { refetch: fetchTables, ...tablesReq } = useQuery({
        queryKey: ['tables'],
        queryFn: () => {
            return DatabaseObjectsModel.fetchTables();
        },
    });

    const { refetch: fetchViews, ...viewsReq } = useQuery({
        queryKey: ['views'],
        queryFn: () => {
            return DatabaseObjectsModel.fetchViews();
        },
    });

    const { refetch: fetchSchemas, ...schemasReq } = useQuery({
        queryKey: ['schemas'],
        queryFn: () => {
            return SchemasModel.fetchSchemas();
        },
    });

    const requests = [
        ...(canViewReports ? [reportsReq] : []),
        tablesReq,
        viewsReq,
        schemasReq,
    ];

    const dataIsReady = requests.every(req => req.data)
        && !requests.some(req => req.isLoading || req.isFetching || req.isError);

    useEffect(() => {
        if (canViewReports) {
            fetchReports();
        }
        fetchTables();
        fetchViews();
        fetchSchemas();
    }, [
        canViewReports,
        fetchReports,
        fetchTables,
        fetchViews,
        fetchSchemas,
    ]);

    useEffect(() => {
        if (!dataIsReady) {
            return;
        }
        setDataIsLoaded(true);
    }, [dataIsReady]);

    return {
        dataIsReady,
        dataIsLoaded,
        reports: reportsReq.data || [],
        tables: tablesReq.data || [],
        views: viewsReq.data || [],
        schemas: schemasReq.data || [],
        loadingReports: reportsReq.isLoading,
        loadingTables: tablesReq.isLoading,
        loadingViews: viewsReq.isLoading,
        loadingSchemas: schemasReq.isLoading,
        fetchReports,
        fetchTables,
        fetchViews,
        fetchSchemas,
    };
}

export default useWorkbenchData;
