import React from 'react';
import openSupportChat from '../../shared/use-support-chat';
import styled from 'styled-components';

import {
    Layout,
    Typography,
    colors,
    LogoThumbnail,
    Anchor,
} from 'ui-components';

import { transparentize } from 'polished';

/** @typedef { import('./__types').Integration } Integration */

/**
 * @typedef { object } Props
 * @prop { string } searchTerm
 * @prop { Integration[] } searchMatches
 * @prop { (i: Integration) => React.ReactNode } getLabel
 * @prop { (i: Integration) => void } onIntegrationClicked
 * @prop { () => void } addFlexConnector
 */

/** @type { React.FC<Props> } */
const SearchResults = ({
    searchTerm,
    searchMatches,
    getLabel,
    onIntegrationClicked,
    addFlexConnector,
}) => {
    const chat = () => openSupportChat(`How to get ${searchTerm} data into Panoply?`);

    return (
        <Layout width="70">
            <Layout>
                <Typography
                    variant="body2"
                    color="secondaryText"
                    spacing="mt-6"
                    weight="medium"
                >
                    Results
                </Typography>
                <Layout flex wrap>
                    {searchMatches.length > 0 && searchMatches.map((integration) => (
                        <LogoThumbnail
                            key={integration.id}
                            title={integration.title}
                            imageUrl={integration.icon}
                            chipLabel={getLabel(integration)}
                            width="25"
                            spacing="py-2 mb-0"
                            onClick={() => onIntegrationClicked(integration)}
                            chipColor="default"
                        />
                    ))}
                    {searchMatches && searchMatches.length === 0 && (
                        <MinHeightLayout
                            flex
                            width="100"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Layout>
                                <Typography
                                    variant="body1"
                                    spacing="mt-7 mb-5"
                                    weight="medium"
                                >
                                    Connect Panoply to {searchTerm} with our
                                    {' '}
                                    <Anchor onClick={addFlexConnector}>
                                        Flex Connector
                                    </Anchor>
                                </Typography>
                                <Typography spacing="mb-5">
                                    <Anchor color="primary" onClick={chat}>
                                        Chat with us
                                    </Anchor>
                                    {' '}
                                    and we will be happy to help you connect.
                                </Typography>
                            </Layout>
                        </MinHeightLayout>
                    )}
                </Layout>
            </Layout>
        </Layout>
    );
};

const MinHeightLayout = styled(Layout)`
    min-height: 30vh;
    background-color: ${transparentize(0.95, colors.interface)};
    border: 1px solid ${colors.secondaryInterface};
    border-radius: 5px;
`;

export default SearchResults;
