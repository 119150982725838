import numeral from 'numeral';
import { formatBytes } from '../../shared/formatters';
import { MEBIBYTE } from '../../shared/constants';

/** @typedef { import('models/database-objects/__types').DatabaseObject } DatabaseObject */

/** @type { (rows: DatabaseObject['metadata']['rows']) => string } */
export const formatRows = (rows) => {
    const formatter = String(rows).length <= 3 ? '0a' : '0.0a';
    return numeral(rows).format(formatter);
};

/** @type { (size: number) => string } */
export const formatSize = size => formatBytes(size * MEBIBYTE);
