import { useState, useCallback, useEffect } from 'react';
import { breakpoints } from 'ui-components';

/** @typedef { ReturnType<typeof useWorkbenchMenu> } WorkbenchMenu */

function useWorkbenchMenu() {
    const [leftMenuIsOpen, setLeftMenuIsOpen] = useState(true);
    const [dropDownIsOpen, setDropDownIsOpen] = useState(false);
    const [menuIsExpanded, setMenuIsExpanded] = useState(true);

    const [windowResizeAware, setWindowResizeAware] = useState({});

    const onLeftMenuToggled = useCallback(() => {
        setLeftMenuIsOpen(open => !open);
    }, []);

    /** @type { (args: { domElement: Element | Text }) => void } */
    const onLeftMenuResized = useCallback(({ domElement }) => {
        setTimeout(() => {
            if (!(domElement instanceof HTMLElement)) {
                return;
            }
            const minSize = Math.floor(parseInt(breakpoints.sm) / 3);
            if (domElement.offsetWidth <= minSize) {
                setLeftMenuIsOpen(open => !open);
            }
        }, 50);
    }, []);

    const onDropDownToggled = useCallback(() => {
        setDropDownIsOpen(open => !open);
    }, []);

    /** @type { (args: { domElement: Element | Text }) => void } */
    const onMenuIsExpanding = useCallback(({ domElement }) => {
        const onResize = () => {
            if (!(domElement instanceof HTMLElement)) {
                return;
            }
            const minSize = parseInt(breakpoints.lg) - 1;
            setMenuIsExpanded(domElement.offsetWidth >= minSize);
        };

        const intervalId = setInterval(onResize, 50);
        setTimeout(() => clearInterval(intervalId), 300);

        setWindowResizeAware(callbacks => ({
            ...callbacks,
            onMenuIsExpanding: onResize,
        }));
    }, []);

    useEffect(() => {
        const onResize = () => {
            Object.values(windowResizeAware).forEach(fn => fn());
        };
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, [windowResizeAware]);

    return {
        leftMenuIsOpen,
        dropDownIsOpen,
        menuIsExpanded,
        onLeftMenuToggled,
        onLeftMenuResized,
        onDropDownToggled,
        onMenuIsExpanding,
    };
}

export default useWorkbenchMenu;
