import React from 'react';
import styled from 'styled-components';

import {
    useMutation,
} from '@tanstack/react-query';

import { cancelQuery } from '../models/queries';

import {
    Typography,
    Icon,
    Button,
    colors,
    Dialog,
} from 'ui-components';

/** @typedef { import('../models/queries/__types').QueryInfo } QueryInfo */
/** @typedef { import('../models/queries/__types').QueryResult } QueryResult */
/** @typedef { import('../models/queries/__types').CancelQueryResponse } CancelQueryResponse */

/**
 * @typedef { object } Props
 * @prop { QueryInfo | QueryResult } [queryToCancel]
 * @prop { () => void } closeDialog
 * @prop { (id: QueryInfo['id']) => void } [onQueryCancelled]
 */

/** @type { React.FC<Props> } */
const CancelQueryDialog = React.memo(({
    queryToCancel,
    onQueryCancelled,
    closeDialog,
}) => {

    const open = !!queryToCancel?.id;

    const cancelQueryMutation = useMutation({
        /** @type { (query: QueryInfo | QueryResult) => Promise<CancelQueryResponse> } */
        mutationFn: query => cancelQuery(query.id),
        retry: false,
        onSuccess: data => {
            if (onQueryCancelled) {
                onQueryCancelled(data.id);
            }
        },
    });

    const {
        data: cancelResponse,
        isPending: loading,
        isSuccess: success,
        error,
    } = cancelQueryMutation;

    const onCancelQueryClicked = () => {
        if (!queryToCancel) {
            return;
        }
        cancelQueryMutation.mutate(queryToCancel);
    };

    const close = () => {
        cancelQueryMutation.reset();
        closeDialog();
    };

    const finished = success || error;

    const actualSuccess = success && cancelResponse.status === 'Accepted';

    const finalActions = (
        <Button
            onClick={close}
            color="primary"
            spacing="mr-0"
        >
            Done
        </Button>
    );

    return (
        <Dialog
            isOpen={open}
            onClose={close}
            actions={
                finished ? (
                    finalActions
                ) : (
                    <>
                        <Button
                            onClick={close}
                            type="plain"
                            disabled={loading}
                        >
                            Don&apos;t Cancel
                        </Button>
                        <Button
                            onClick={onCancelQueryClicked}
                            color="primary"
                            spacing="mr-0"
                            loading={loading}
                        >
                            Cancel Query
                        </Button>
                    </>
                )
            }
        >
            {finished ? (
                actualSuccess ? (
                    <>
                        <SuccessIconCircle>
                            <Icon icon="check" size="lg" color="white" />
                        </SuccessIconCircle>
                        <Typography color="text" variant="h6" align="center" spacing="mb-4 mt-4">
                            Query canceled successfully.
                        </Typography>
                        <Typography color="text" variant="body1" align="center">
                            Some operations may have already finished running.
                        </Typography>
                    </>
                ) : (
                    <>
                        <ErrorIconCircle>
                            <Icon icon="exclamation-circle" size="lg" color="white" />
                        </ErrorIconCircle>
                        <Typography color="text" variant="h6" align="center" spacing="mb-4 mt-4">
                            Query could not be canceled
                        </Typography>
                        <Typography color="text" variant="body1" align="center">
                            {cancelResponse?.error?.message}
                        </Typography>
                    </>
                )
            ) : (
                <>
                    <Typography color="text" variant="h6" align="center" spacing="mb-4 mt-2">
                        {loading ? 'Canceling Query...' : 'Cancel Query?'}
                    </Typography>
                    <Typography color="text" variant="body1" align="center">
                        Canceling queries might cause unexpected results.
                    </Typography>
                </>
            )}
        </Dialog>
    );
});

const SuccessIconCircle = styled.div`
    background-color: ${colors.secondary.main};
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ErrorIconCircle = styled.div`
    background-color: ${colors.error.main};
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default CancelQueryDialog;
