import React from 'react';
import numeral from 'numeral';
import { formatBytes } from '../formatters';

import {
    Layout,
    Typography,
    Image,
} from 'ui-components';

import { pricingTypes } from './use-plan-filters';
import dbIconPath from '../../images/icon-panoply-database.svg';
import { GIBIBYTE } from '../constants';

const typographyProps = {
    align: 'center',
    weight: 'normal',
};

const PlanImage = props => (
    <Image
        src={dbIconPath}
        width="100"
        height="132px"
        size="contain"
        repeat="no-repeat"
        position="center"
        {...props}
    />
);

const PlanInfo = ({ plan, ...props }) => (
    <Layout {...props}>
        <PlanImage spacing="my-6" />

        <Typography
            spacing="mt-4 mb-0"
            variant="h6"
            color="primary"
            {...typographyProps}
        >
            {plan.name}
        </Typography>

        <Typography
            spacing="mb-0"
            variant="h6"
            {...typographyProps}
        >
            ${plan.amount} / {plan.period}
        </Typography>

        {plan.pricingType === pricingTypes.annualMonth && (
            <Typography spacing="mt-2" color="secondaryText" {...typographyProps}>
                For your annual agreement
            </Typography>
        )}

        <PlanFeatures plan={plan} typographyProps={typographyProps} spacing="mt-4" />

    </Layout>
);

export const PlanFeatures = ({ plan, typographyProps = {}, ...props }) => {

    const period = (
        <Typography inline color="secondaryText" component="span">
            {' '}/ month
        </Typography>
    );

    return (
        <Layout {...props}>

            {plan.details.rowsExtracted && (
                <Typography {...typographyProps}>
                    {
                        formatNumeral(
                            numeral(plan.details.rowsExtracted).format('0 a'),
                        )
                    } Rows extracted
                    {period}
                </Typography>
            )}

            {plan.details.queryBytes && plan.details.dbType === 'bigquery' && (
                <Typography spacing="mt-2" {...typographyProps}>
                    {formatBytes(plan.details.queryBytes, GIBIBYTE)} Query bytes
                    {period}
                </Typography>
            )}

            <Typography spacing="mt-5" color="secondaryText" italic {...typographyProps}>
                Includes {formatBytes(plan.details.gbStored, GIBIBYTE)} of free storage
            </Typography>
        </Layout>
    );
};

/** @param { string } str */
const formatNumeral = str => {
    if (str.includes('m')) {
        return str.replace('m', 'million');
    } else if (str.includes('b')) {
        return str.replace('b', 'billion');
    } else {
        return str;
    }
};

export default PlanInfo;
