import { get } from '../../lib/ajax';
import { addBreadcrumb } from '../../lib/error-reporter';
import { isNumericColumn, isStringColumn } from './data-types';

/** @typedef { import('../../shared/workbench/__types').TableItem } TableItem */
/** @typedef { import('../../shared/workbench/__types').ViewItem } ViewItem */
/** @typedef { import('./__types').ColumnData } ColumnData */
/** @typedef { import('./__types').Column } Column */
/** @typedef { import('./__types').ColumnItem } ColumnItem */
/** @typedef { import('./__types').TableType } TableType */
/** @typedef { TableItem | ViewItem } Table */
/** @typedef { Pick<Table, 'type' | 'schema' | 'name'> } Item */

/** @type { (item: Item) => Promise<ColumnItem[]> } */
async function loadColumns({ type, schema, name }) {
    try {
        const id = [schema, name].map(encodeURIComponent).join('/');

        const { data: columns } = await get(`/${type}s/${id}/columns`);

        return createColumnItems(columns, type);

    } catch (error) {
        addBreadcrumb('Failed to fetch columns', { error });

        throw error;
    }
}

/**
 * @param { Column } item
 * @returns { boolean }
 */
function isItemHidden(item) {
    return item.name.startsWith('__');
}

/**
 * @param { Column } a
 * @param { Column } b
 * @returns { number }
 */
function sortByName(a, b) {
    if (a.name > b.name) {
        return 1;
    }
    if (a.name < b.name) {
        return -1;
    }
    return 0;
}

/**
 * @param { string } str
 * @returns { string }
 */
function capitalizeFirstLetter(str) {
    if (!str) {
        return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

/**
 * @param { ColumnData } column
 * @returns { Column['type'] }
 */
function getColumnType(column) {
    const name = column.current_name || column.name;

    if (name === '__panoply_id') {
        return 'KEY';
    }

    if (column.code) {
        return 'CODE';
    }

    if (!column.type) {
        return 'VARCHAR';
    }

    return column.type.toUpperCase();
}

/**
 * @param { Column } column
 * @returns { ColumnItem['icon'] }
 */
function getColumnIcon(column) {
    if (isNumericColumn(column.type)) {
        return 'subscript';
    }

    if (isStringColumn(column.type)) {
        return 'quote-right';
    }

    return resolveColumnIcon(column);
}

/**
 * @param { Column } column
 * @returns { ColumnItem['icon'] }
 */
function resolveColumnIcon(column) {
    switch (column.type.toUpperCase()) {
        case 'JSON': return 'brackets-curly';
        case 'GEOGRAPHY': return 'earth-americas';
        case 'DATE': // break omitted
        case 'DATETIME': // break omitted
        case 'TIMESTAMP': // break omitted
        case 'TIME': return 'calendar';
        case 'CODE': return 'code';
        case 'KEY': return 'key';
        case 'BOOL': // break omitted
        case 'BOOLEAN': return 'flag';
        default: return 'quote-right';
    }
}

/**
 * @param { Column } column
 * @returns { ColumnItem['label'] }
 */
function getColumnLabel(column) {
    return capitalizeFirstLetter(column.type);
}

/**
 * @param { Column } a
 * @param { Column } b
 * @returns { number }
 */
function sortByType(a, b) {
    if (a.type === 'KEY') {
        return -1;
    }
    if (b.type === 'KEY') {
        return 1;
    }
    if (a.name.startsWith('__')) {
        return 1;
    }
    if (b.name.startsWith('__')) {
        return -1;
    }
    return 0;
}

/**
 * @param { Column } item
 * @returns { ColumnItem }
 */
function createColumnItem(item) {
    const tableId = [item.schema, item.table].join('-');
    return {
        id: `${item.tableId || tableId}-${item.name}`,
        name: item.name,
        type: 'column',
        table: item.table,
        schema: item.schema,
        dataType: item.dataType,
        tableType: item.tableType,
        icon: getColumnIcon(item),
        label: getColumnLabel(item),
        hidden: isItemHidden(item),
        original_name: item.original_name || item.name,
    };
}

/**
 * @param { ColumnData[] } data
 * @param { TableType } tableType
 * @returns { Column[] }
 */
function normalizeColumnData(data, tableType) {
    return data.map(item => ({
        ...item,
        name: item.current_name || item.name,
        type: getColumnType(item),
        dataType: item.type,
        tableType,
    }));
}

/**
 * @param { ColumnData[] } data
 * @param { TableType } tableType
 * @returns { ColumnItem[] }
 */
function createColumnItems(data, tableType) {
    return normalizeColumnData(data, tableType)
        .sort(sortByName)
        .sort(sortByType)
        .map(createColumnItem);
}

/**
 * @param { string } id
 * @returns { string }
 */
function extractTableName(id) {
    if (!id) {
        return 'Table';
    }
    return id.split('-').slice(2).join('-');
}

/**
 * @param { string } idpattern
 * @returns { string[] }
 */
function extractPrimaryKeys(idpattern) {
    const parts = idpattern.match(/{([^{}]+)}/g) || [];
    return parts.map(x => x.replace(/[{}]/g, ''));
}

/**
 * @param { string } value
 * @returns { string }
 */
function normalizeMetadata(value) {
    if (!value) {
        return 'false';
    }
    if (value.toLowerCase() === 'no') {
        return 'false';
    }
    if (value.toLowerCase() === 'yes') {
        return 'true';
    }
    return value;
}

/**
 * @param { ColumnData[] } columns
 * @param { string[] } primaryKeys
 * @returns { ColumnData[] }
 */
function normalizeColumns(columns, primaryKeys = []) {
    return columns.map(column => ({
        ...column,
        isPrimary: primaryKeys.includes(column.current_name || column.name)
            ? 'true'
            : normalizeMetadata(column.isPrimary || ''),
        keyValue: normalizeMetadata(column.keyValue || ''),
    }));
}

export {
    loadColumns,
    extractTableName,
    extractPrimaryKeys,
    normalizeColumns,
    createColumnItems,
};
