import React from 'react';

import {
    Card,
    Typography,
    Input,
    useInput,
    Button,
    Layout,
} from 'ui-components';

import isValidEmail from '../../lib/is-valid-email';
import UsersTable from './users-table';

/** @typedef { import('./__types').User } User */
/** @typedef { import('./__types').Invite } Invite */
/** @typedef { import('./__types').Team } Team */
/** @typedef { import('./__types').State } State */

const descriptions = {
    ADMINS: [{
        key: 'crud-access',
        text: 'Admins are almighty, they can add, edit and remove any table or connector.',
    }, {
        key: 'full-access',
        text: 'Admins have full access to the platform and all data stored in your '
            + 'Panoply account.',
    }],
    EDITORS: [{
        key: 'crud-access',
        text: 'Editors can add, edit and remove any table and have full control over '
            + 'connectors.',
    }],
    VIEWERS: [{
        key: 'read-access',
        text: 'Viewers can see or query the data in tables shared with them, but can\'t '
            + 'change or share the table with others.',
    }],
};

/** @type { (name: string) => string } */
function capitalize(name) {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
}

/** @type { (u: User, t: Team) => boolean } */
function isMemberOrInviteeOfTeam(user, team) {
    const users = [
        ...team.users || [],
        ...team.invites || [],
    ];
    return users.some(u => u.email === user.email);
}


/**
 * @typedef { object } Props
 *
 * @prop { State } state
 * @prop { Team } team
 * @prop { boolean } updateLoading
 * @prop { boolean } resendLoading
 * @prop { (u: User, t: Team) => void } onUserDelete
 * @prop { (i: Invite, t: Team) => void } onUserInvite
 * @prop { (i: Invite, t: Team) => void } onResendInvite
 */

/** @type { React.FC<Props> } */
const TeamsListItem = ({
    team,
    updateLoading,
    onUserInvite,
    ...props
}) => {
    const emailInput = useInput('Enter an email to invite a team member', '');
    const userToInvite = { email: emailInput.value.trim() };
    const emailIsValid = isValidEmail(emailInput.value);

    const disabled = isMemberOrInviteeOfTeam(userToInvite, team)
        || !emailIsValid;

    const inputProps = {
        ...emailInput.bind,
        error: !emailIsValid,
        disabled: updateLoading,
        onKeyDown: e => e.key === 'Enter'
            && !disabled
            && onUserInvite(userToInvite, team),
    };

    const buttonProps = {
        onClick: () => onUserInvite(userToInvite, team),
        disabled,
        loading: !disabled && updateLoading,
    };

    const usersTableProps = {
        users: [
            ...team.users || [],
            ...team.invites || [],
        ],
        team,
        ...props,
    };

    const { name = '' } = team;

    return (
        <Card spacing="mt-5" title={capitalize(name)} titleDivider>
            {descriptions[name].map(d => (
                <Typography key={d.key} variant="body1" spacing="-mt-1">
                    {d.text}
                </Typography>
            ))}

            <Layout spacing="mt-4 mb-5" flex>
                <Input width="50" spacing="m-0 p-0" noFloatLabel {...inputProps} />
                <Button spacing="ml-3 px-8" {...buttonProps}>Add</Button>
            </Layout>

            <UsersTable {...usersTableProps} />
        </Card>
    );
};

export default TeamsListItem;
