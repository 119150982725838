import React, { useState, useCallback } from 'react';

import {
    Button,
    Dialog,
    Layout,
    Typography,
    Callout,
} from 'ui-components';
import { reportError } from '../../lib/error-reporter';

/** @typedef { import('models/database-objects/__types').View } View */
/** @typedef { import('lib/ajax').AjaxResponse= } AjaxError */

/**
 * @typedef { object } Props
 * @prop { View= } viewToMaterialize
 * @prop { Boolean } open
 * @prop { Boolean } userCanMaterialize
 * @prop { () => void } onClose
 * @prop { (view: View) => Promise<void> } materializeView,
 */

/** @type { React.FC<Props> } */
const MaterializeViewDialog = ({
    viewToMaterialize, open, userCanMaterialize, onClose, materializeView,
}) => {

    const [loading, setLoading] = useState(false);

    /** @type {[AjaxError, React.Dispatch<AjaxError>]} */
    const [error, setError] = useState();

    const onConfirmClicked = useCallback(async () => {
        if (!viewToMaterialize) {
            return;
        }
        setLoading(true);
        try {
            await materializeView(viewToMaterialize);
            setLoading(false);
            onClose();
        } catch (err) {
            reportError(err, { viewId: viewToMaterialize.id });
            setError(err);
            setLoading(false);
        }
    }, [viewToMaterialize, materializeView, onClose]);

    if (!viewToMaterialize || !userCanMaterialize) {
        return null;
    }

    const action = viewToMaterialize.materialized
        ? 'dematerialize'
        : 'materialize';

    const title = viewToMaterialize.materialized
        ? 'Dematerialize View'
        : 'Materialize View';

    return (
        <Dialog
            title={title}
            isOpen={open}
            onClose={onClose}
        >

            <Typography variant="body1">
                This action will {action} the following view: {viewToMaterialize.name}
            </Typography>

            {error && (
                <Callout color="error" spacing="mt-4 p-2">
                    Oops! An error was encountered while trying to {action} the view.
                </Callout>
            )}

            <Layout flex justifyContent="flex-end" spacing="mt-6">
                <Button onClick={onClose} disabled={loading}>Cancel</Button>
                <Button
                    color="primary"
                    loading={loading}
                    onClick={onConfirmClicked}
                    spacing="mr-0"
                >
                    Confirm
                </Button>
            </Layout>
        </Dialog>
    );
};

export default MaterializeViewDialog;
