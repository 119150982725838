import React from 'react';
import styled from 'styled-components';

import {
    Layout,
    Loader,
    Typography,
    Button,
    Icon,
    Tooltip,
    Anchor,
} from 'ui-components';

import PageLayout from '../../shared/page-layout';
import PageHeader from '../../shared/page-header';
import LastUpdated from '../../shared/last-updated';
import Time from '../../shared/time';

import useDashboardPage from './use-dashboard-page';
import DashboardGrid from './dashboard-grid';
import DashboardDropdown from './dashboard-dropdown';
import ShareDropdown from './share-dropdown';
import SaveDialog from './save-dialog';
import RenameDialog from './rename-dialog';
import DeleteDialog from './delete-dialog';
import ShareDialog from './share-dialog';
import ReportsDropdown from './reports-dropdown';

const DashboardPage = () => {
    const {
        layout,
        editable,
        dashboard,
        droppingItem,
        sharingItem,
        isLoading,
        isError,
        refreshedAt,
        saveDialogOpen,
        renameDialogOpen,
        deleteDialogOpen,
        reportsDropdownOpen,
        shareDialogOpen,
        refresh,
        changeMode,
        changeLayout,
        changeDroppingReportId,
        toggleSaveDialog,
        toggleRenameDialog,
        toggleDeleteDialog,
        toggleReportsDropdown,
        closeReportsDropdown,
        toggleShareDialog,
        saveDashboard,
        renameDashboard,
        deleteDashboard,
        deleteDashboardItem,
        addDroppedItem,
        addDashboardItem,
    } = useDashboardPage();

    if (!isLoading && isError) {
        return (
            <PageLayout title="Oops!">
                <Layout spacing="p-8">
                    <Typography variant="h6" align="center">
                        Oops! There was an error when loading this dashboard
                    </Typography>
                </Layout>
            </PageLayout>
        );
    }

    if (isLoading || !dashboard) {
        return (
            <PageLayout title="Loading...">
                <Layout spacing="p-8">
                    <Loader active big message="Loading Dashboard" />
                </Layout>
            </PageLayout>
        );
    }

    const {
        title,
        items = [],
    } = dashboard;

    return (
        <PageLayout
            fullwidth
            flex
            flexDirection="column"
            alignItems="center"
        >
            <PageHeader
                sticky
                title={title}
                {...!editable ? {
                    titleLayoutProps: {
                        alignItems: 'stretch',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    },
                    titleAdornment: (
                        <NoWrapTypography
                            inline
                            color="secondaryText"
                        >
                            <Time timestamp={refreshedAt}>
                                <LastUpdated timestamp={refreshedAt} />
                            </Time>
                            {' '}
                            <Anchor
                                color="primary"
                                onClick={() => refresh()}
                            >
                                Refresh Now
                            </Anchor>
                        </NoWrapTypography>
                    ),
                } : {
                    titleAdornment: (
                        <Tooltip content="Rename" interactive={false} placement="top">
                            <Button
                                square
                                type="plain"
                                spacing="m-0 mx-2 p-2"
                                onClick={toggleRenameDialog}
                            >
                                <Icon icon="pen" />
                            </Button>
                        </Tooltip>
                    ),
                }}
            >
                {!editable ? (
                    <>
                        <ShareDropdown
                            title={title}
                            toggleShareDialog={toggleShareDialog}
                            spacing="m-0 p-2 mx-1"
                        />

                        <Button
                            square
                            type="plain"
                            spacing="m-0 p-2"
                            onClick={() => changeMode('edit')}
                            leftIcon="pen"
                        >
                            Edit Dashboard
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            color="secondary"
                            onClick={toggleSaveDialog}
                            spacing="m-0 mr-4"
                        >
                            Save Changes
                        </Button>

                        <ReportsDropdown
                            spacing="m-0 my-1 ml-1 p-2"
                            items={items}
                            changeDroppingReportId={changeDroppingReportId}
                            reportsDropdownOpen={reportsDropdownOpen}
                            toggleReportsDropdown={toggleReportsDropdown}
                            closeReportsDropdown={closeReportsDropdown}
                            addDashboardItem={addDashboardItem}
                        />

                        <DashboardDropdown
                            spacing="m-0 my-1 ml-1 p-2"
                            toggleRenameDialog={toggleRenameDialog}
                            toggleDeleteDialog={toggleDeleteDialog}
                        />
                    </>
                )}
            </PageHeader>

            <DashboardGrid
                layout={layout}
                editable={editable}
                items={items}
                droppingItem={droppingItem}
                refreshedAt={refreshedAt}
                changeLayout={changeLayout}
                deleteDashboardItem={deleteDashboardItem}
                addDroppedItem={addDroppedItem}
                toggleShareDialog={toggleShareDialog}
            />

            {!items.length && (
                <Overlay>
                    <Typography variant="h6" spacing="px-5 pt-9+9">
                        Welcome to Panoply Dashboards
                    </Typography>

                    <Typography spacing="px-5">
                        Leverage your data by adding saved reports to your dashboard.{' '}

                        <Anchor href="https://panoply.io/docs/dashboards" target="_blank" underline>
                            Learn more here
                        </Anchor>
                    </Typography>
                </Overlay>
            )}

            <SaveDialog
                key={`save-dialog-${saveDialogOpen}`}
                saveDialogOpen={saveDialogOpen}
                toggleSaveDialog={toggleSaveDialog}
                saveDashboard={saveDashboard}
            />

            <RenameDialog
                key={`rename-dialog-${renameDialogOpen}`}
                renameDialogOpen={renameDialogOpen}
                toggleRenameDialog={toggleRenameDialog}
                renameDashboard={renameDashboard}
                dashboard={dashboard}
            />

            <DeleteDialog
                key={`delete-dialog-${deleteDialogOpen}`}
                deleteDialogOpen={deleteDialogOpen}
                toggleDeleteDialog={toggleDeleteDialog}
                deleteDashboard={deleteDashboard}
                dashboard={dashboard}
            />

            <ShareDialog
                key={`share-dialog-${shareDialogOpen}`}
                shareDialogOpen={shareDialogOpen}
                toggleShareDialog={toggleShareDialog}
                sharingItem={sharingItem}
            />
        </PageLayout>
    );
};

const NoWrapTypography = styled(Typography)`
    white-space: nowrap;
`;

const Overlay = styled.div`
    position: absolute;
    inset: 0;
    z-index: 0;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;
    pointer-events: none;

    .anchor {
        pointer-events: auto;
    }
`;

export default DashboardPage;
