import React, { useMemo } from 'react';
import { CodeEditor } from 'ui-components';

import { createEditorSchemas } from './tree-transformers';

/** @typedef { import('app/__types').Database } Database */
/** @typedef { import('./__types').EditorDiagnostic } EditorDiagnostic */
/** @typedef { import('./__types').SchemaData } SchemaData */
/** @typedef { import('./__types').ViewData } ViewData */
/** @typedef { import('./__types').TableData } TableData */

/**
 * @typedef { object } Props
 * @prop { string= } contents
 * @prop { (newQuery: string) => void } onChange
 * @prop { (newQuery: string) => void } onSelectionChange
 * @prop { SchemaData[] } schemas
 * @prop { ViewData[] } views
 * @prop { TableData[] } tables
 * @prop { EditorDiagnostic[] } diagnostics
 * @prop { Database['type'] } dbType
 * @prop { string } highlight
 */

/** @type { React.FC<Props> } */
const WorkbenchEditor = React.memo(({
    contents,
    onChange,
    onSelectionChange,
    schemas,
    tables,
    views,
    diagnostics,
    dbType,
    highlight,
}) => {
    const editorSchemas = useMemo(() => (
        createEditorSchemas(schemas, tables, views)
    ), [schemas, tables, views]);

    return (
        <CodeEditor
            placeholder="-- Type your SQL query (Shift + Enter to run)"
            contents={contents}
            onChange={onChange}
            onSelectionChange={onSelectionChange}
            schema={editorSchemas}
            diagnostics={diagnostics}
            dbType={dbType}
            highlight={highlight}
        />
    );
});

export default WorkbenchEditor;
