import React, { useState, useCallback } from 'react';

import {
    FileInput,
    Typography,
    Icon,
    Layout,
    Callout,
} from 'ui-components';

import { post } from '../../../lib/ajax';
import { reportError } from '../../../lib/error-reporter';
import MarkdownRenderer from '../../../shared/markdown-renderer';

/** @typedef { import('models/sources/__types').UploadParam } Param */
/** @typedef { import('models/sources/__types').UploadParamValue } Value */

/** @typedef { 'check-circle' | 'exclamation-circle' | 'exclamation-triangle' } Icon */
/** @typedef { 'secondary' | 'error' | 'secondaryText' } Color */

/**
 * @typedef { object } Status
 * @prop { Icon } [icon]
 * @prop { Color } [color]
 * @prop { string } [message]
 */

const MAX_FILE_SIZE = 1024 * 1024 * 100; // 100 MiB

/** @type { (path: string) => string } */
function getBasename(path) {
    return path.replace(/^.*[\\/]/, '');
}

/** @type { (value: Value) => string } */
function getFileName(value) {
    if (!value || !value.files || !value.files.length) {
        return '';
    }
    return getBasename(value.files[0]);
}

/** @type { Status } */
const initialStatus = {};

/**
 * @typedef { object } Props
 * @prop { Param } param
 * @prop { Value } value
 * @prop { (newValue: Value) => void } setValue
 */

/** @type { React.FC<Props> } */
const UploadParam = ({
    param,
    value,
    setValue,
}) => {
    const {
        name,
        accept,
        multiple,
        label,
        description,
    } = param;

    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(initialStatus);
    const [placeholder, setPlaceholder] = useState(getFileName(value));
    const [oldValue, setOldValue] = useState(value);

    const clearValue = useCallback(() => {
        setOldValue(value);
        setPlaceholder('');
        setValue(null);
    }, [value, setValue]);

    const restoreValue = useCallback(() => {
        setPlaceholder(getFileName(oldValue));
        setValue(oldValue);
    }, [oldValue, setValue]);

    const showWarning = useCallback((message) => {
        setStatus({
            icon: 'exclamation-triangle',
            color: 'error',
            message,
        });
    }, []);

    const showError = useCallback((message) => {
        setStatus({
            icon: 'exclamation-circle',
            color: 'error',
            message,
        });
    }, []);

    const showSuccess = useCallback((message) => {
        setStatus({
            icon: 'check-circle',
            color: 'secondary',
            message,
        });
    }, []);

    const upload = useCallback(async (file) => {
        setLoading(true);

        const body = new FormData();
        body.append('file', file);

        try {
            const { data: newValue } = await post('/sources/upload/upload', {
                headers: { 'Content-Type': 'multipart/form-data' },
                body,
            });

            showSuccess('File ready. Select a Destination, then click Save Changes below.');
            setValue(newValue);
        } catch (err) {
            reportError(err, { file });
            showError('So sorry! We noticed an issue. To retry choose the file again.');
        } finally {
            setLoading(false);
        }
    }, [setValue, showSuccess, showError]);

    const onChange = useCallback((e) => {
        // Save the old value and clear the current
        // for the case of canceling the new file.
        clearValue();

        // Clear the current status (warning/error)
        // before starting uploading the new file.
        setStatus({});

        const files = e.target.files || [];
        if (!files.length) {
            // Restore the old value when clearing files,
            // which can happen when clicking on the "x".
            restoreValue();
            return;
        }

        const file = files[0];

        if (!file.size) {
            showWarning('The file cannot be empty. Choose a different file to try again.');
            return;
        }

        if (file.size > MAX_FILE_SIZE) {
            showWarning('File size cannot exceed 100MB. Choose a different file to try again.');
            return;
        }

        upload(file);
    }, [clearValue, restoreValue, upload, showWarning]);

    const uploadProps = {
        width: '100',
        spacing: 'p-0',
        name,
        accept,
        multiple,
        label,
        description,
        placeholder,
        loading,
        onChange,
    };

    return (
        <Layout width="100">
            <FileInput {...uploadProps} />

            {description && (
                <Description
                    text={description}
                    spacing="mt-2 ml-3"
                />
            )}

            {status.message && (
                <Callout
                    spacing="mt-2"
                    contentSpacing="py-2 px-3"
                    color={status.color || 'default'}
                >
                    {status.icon && (
                        <Icon icon={status.icon} spacing="mr-2" />
                    )}

                    {status.message}
                </Callout>
            )}
        </Layout>
    );
};

/** @type { React.FC<{ text: string, spacing: string }> } */
const Description = ({ text, spacing }) => (
    <Typography
        component="div"
        variant="body2"
        color="secondaryText"
        spacing={spacing}
    >
        <MarkdownRenderer source={text} />
    </Typography>
);

export default UploadParam;
