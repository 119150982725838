
import React, { useCallback } from 'react';
import { Select, SelectItem } from 'ui-components';
import MarkdownRenderer from '../../../../shared/markdown-renderer';

/** @typedef { import('models/sources/__types').StaticSelectParam } Param */

/**
 * @typedef { object } Props
 * @prop { Param } param
 * @prop { string } value
 * @prop { (value: any) => void } setValue
 */

/** @type { React.FC<Props> } */
const SourceStaticSelectParam = ({
    param,
    value,
    setValue,
}) => {

    const options = param.options || [];

    const onSelectChanged = useCallback((e) => {
        setValue(e.target.value);
    }, [setValue]);

    return (
        <>
            <Select
                onChange={onSelectChanged}
                value={value || ''}
                noFloatLabel
                width="100"
                mdWidth="50"
                spacing="mx-0 px-0"
                helperText={param.description && (
                    <MarkdownRenderer source={param.description} />
                )}
            >
                <SelectItem disabled value="">
                    {param.placeholder || 'Select'}
                </SelectItem>
                {options.map(option => (
                    <SelectItem
                        key={option.name}
                        value={option.value}
                        disabled={option.disabled || false}
                    >
                        {option.name}
                    </SelectItem>
                ))}
            </Select>
        </>
    );
};

export default SourceStaticSelectParam;
