import { stringify } from 'qs';
import { omitBy, isEmpty } from 'lodash';

/**
 * @param { string } url
 * @param { object } [query]
 */
function gotoUrl(url, query) {
    const queryString = stringify(
        omitBy(query, isEmpty),
        { addQueryPrefix: true },
    );

    window.location.assign(`${url}${queryString}`);

    if (url.startsWith('/#')) {
        window.location.reload(true);
    }
}

export default gotoUrl;
