export default {
    env: 'development',
    hostname: [
        /^http:\/\/localhost:8000\//,
        /^http:\/\/127\.0\.0\.1:8000\//,
    ],
    ajax: {
        csrfCookieName: 'panoply.csrf',
        apiBaseUrl: '',
    },
    sentry: {
        dsn: 'https://4d1a31cd5d2d4c42a321e199f3db7a22@o205711.ingest.sentry.io/1814855',
        ignoreErrorsWithMessage: [
            'InvalidStateError',
            'Converting circular structure',
            'Cannot read property \'readyState\' of undefined',
            'Maximum call stack size exceeded',
        ],
    },
    stripe: {
        apiKey: 'pk_test_Z7aSaAqSWUjfJycvBT1L0LVc',
    },
    recaptcha: {
        siteKey: '6Ld_fdsUAAAAABE-FA95NbsU8g7xrCgLO21E4hEm',
    },
};
