import React, { useCallback } from 'react';
import { Switch } from 'ui-components';
import MarkdownRenderer from '../../../shared/markdown-renderer';
import FormRow from '../../../pages/source-page/form-row';


/** @typedef { import('models/sources/__types').CollectConfig } CollectConfig */

/**
 * @typedef { object } Props
 * @prop { boolean } [value]
 * @prop { boolean } indeterminate
 * @prop { boolean } disabled
 * @prop { (value: boolean) => void } setValue
*/


/** @type { React.FC<Props> } */
const SchemaLock = ({
    value,
    indeterminate,
    disabled,
    setValue,
}) => {

    const onChange = useCallback((e) => {
        setValue(e.target.checked);
    }, [setValue]);

    /* eslint-disable max-len */
    const help = 'When selected, any new column, change to a data type or new table will not be addressed by the ingestion process. Any incompatible data will be stored as `null` and the connector will not fail when encountering any incompatibility. ***This feature is only available for connectors with default and user defined primary keys or datasets with `id` field in them.***';
    const description = 'When selected, any new column, change to a data type or new table will not be addressed';
    /* eslint-enable max-len */

    return (
        <FormRow
            label="Lock Schema"
            tooltipContent={help}
            showOptionalLabel={false}
        >
            <Switch
                spacing="m-0 -ml-2"
                checked={value}
                type="checkbox"
                onChange={onChange}
                indeterminate={indeterminate}
                disabled={disabled}
                name="lock schema"
                label={<MarkdownRenderer source={description} />}
            />
        </FormRow>
    );
};

export default SchemaLock;
