import { useEffect, useCallback, useState } from 'react';

import { useRequest } from '../../lib/ajax';
import { useSession } from '../../shared/context-providers';
import useSearch from '../../shared/use-search';
import usePagination from '../../shared/use-pagination';
import useSwitch from './use-switch';
import useSuspend from './use-suspend';
import useUnsuspend from './use-unsuspend';
import useReassign from './use-reassign';

/** @typedef { import('./__types').Warehouse } Warehouse */
/** @typedef { import('./__types').Cluster } Cluster */
/** @typedef { import('./__types').Reassign } Reassign */
/** @typedef { import('../../app/__types').ChangeEvent } ChangeEvent */
/** @typedef { import('../../app/__types').PageProps } PageProps */
/** @typedef { import('../../app/__types').Session } Session */

/**
 * @typedef { object } Props
 *
 * @prop { PageProps['openToast'] } openToast
 */

/**
 * @typedef { object } ReturnProps
 *
 * @prop { Session['user'] } user
 * @prop { string } keyword
 * @prop { number } page
 * @prop { number } pageSize
 * @prop { (e: ChangeEvent) => void } onKeywordUpdated
 * @prop { (newPage: number) => void } updatePage
 * @prop { (w: Warehouse) => void } onSwitch
 *
 * @prop { Warehouse[] } warehousesData
 * @prop { { message: string } } warehousesError
 * @prop { boolean } warehousesLoading
 *
 * @prop { { message: string } } suspendError
 * @prop { boolean } suspendLoading
 * @prop { Warehouse } warehouseToSuspend
 * @prop { () => void } onSuspendClose
 * @prop { () => void } onSuspendConfirm
 * @prop { (w: Warehouse) => void } onSuspend
 *
 * @prop { { message: string } } unsuspendError
 * @prop { boolean } unsuspendLoading
 * @prop { Warehouse } warehouseToUnsuspend
 * @prop { () => void } onUnsuspendClose
 * @prop { () => void } onUnsuspendConfirm
 * @prop { (w: Warehouse) => void } onUnsuspend
 *
 * @prop { Reassign } reassignData
 * @prop { { message: string } } reassignError
 * @prop { boolean } reassignLoading
 * @prop { Warehouse } warehouseToReassign
 * @prop { (r: Reassign) => void } updateReassignData
 * @prop { () => void } onReassignClose
 * @prop { () => void } onReassignConfirm
 * @prop { (w: Warehouse) => Promise<void> } onReassign
 *
 * @prop { { message: string } } clustersError
 * @prop { boolean } clustersLoading
 * @prop { Cluster[] } clusters
 *
 */

/** @type { (props: Props) => ReturnProps } */
function useWarehouses({ openToast }) {
    const [session] = useSession();
    const { user } = session;
    const { keyword, updateKeyword, debouncedKeyword } = useSearch();
    const { page, pageSize, updatePage } = usePagination();

    const [body, setBody] = useState({
        all: true,
        name: debouncedKeyword,
        $sort: 'name',
        $offset: pageSize * page,
        $limit: pageSize + 1,
    });

    const {
        go: fetchWarehouses,
        data: warehousesData = [],
        error: warehousesError,
        loading: warehousesLoading,
    } = useRequest('/databases', 'GET', null, body);

    /** @type { (e: ChangeEvent) => void } */
    const onKeywordUpdated = useCallback((e) => {
        updateKeyword(e.target.value);
    }, [updateKeyword]);

    useEffect(() => {
        updatePage(0);
    }, [debouncedKeyword, updatePage]);

    useEffect(() => {
        setBody(b => ({
            ...b,
            name: debouncedKeyword,
            $offset: pageSize * page,
            $limit: pageSize + 1,
        }));
    }, [debouncedKeyword, pageSize, page]);

    useEffect(() => {
        fetchWarehouses();
    }, [fetchWarehouses]);

    const { onSwitch } = useSwitch({ openToast });

    const {
        suspendError,
        suspendLoading,
        warehouseToSuspend,
        onSuspendClose,
        onSuspendConfirm,
        onSuspend,
    } = useSuspend({ openToast, fetchWarehouses });

    const {
        unsuspendError,
        unsuspendLoading,
        warehouseToUnsuspend,
        onUnsuspendClose,
        onUnsuspendConfirm,
        onUnsuspend,
    } = useUnsuspend({ openToast, fetchWarehouses });

    const {
        reassignData,
        reassignError,
        reassignLoading,
        warehouseToReassign,
        updateReassignData,
        onReassignClose,
        onReassignConfirm,
        onReassign,

        clustersError,
        clustersLoading,
        clusters,
    } = useReassign({ openToast, fetchWarehouses });

    return {
        user,
        keyword,
        page,
        pageSize,
        onKeywordUpdated,
        updatePage,
        onSwitch,

        warehousesData,
        warehousesError,
        warehousesLoading,

        suspendError,
        suspendLoading,
        warehouseToSuspend,
        onSuspendClose,
        onSuspendConfirm,
        onSuspend,

        unsuspendError,
        unsuspendLoading,
        warehouseToUnsuspend,
        onUnsuspend,
        onUnsuspendClose,
        onUnsuspendConfirm,

        reassignError,
        reassignLoading,
        warehouseToReassign,
        reassignData,
        onReassignClose,
        onReassignConfirm,
        onReassign,
        updateReassignData,

        clustersError,
        clustersLoading,
        clusters,
    };
}

export default useWarehouses;
