import { useEffect, useState, useCallback } from 'react';
import {
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';

import { fetchQueriesList } from '../../models/queries';

/** @typedef { import('../../models/queries/__types').QueryInfo } QueryInfo */
/** @typedef { import('../../models/queries/__types').PageToken } PageToken */

const PAGE_SIZE = 20;

function useQueriesPage() {

    const queryClient = useQueryClient();
    const [page, setPage] = useState(0);
    const [lastUpdate, setLastUpdate] = useState(0);
    const [allQueries, setAllQueries] = useState(
        /** @type { QueryInfo[] } */ ([])
    );
    const [shouldRefetch, setShouldRefetch] = useState(false);
    const [pageToken, setPageToken] = useState(
        /** @type { PageToken= } */ (undefined)
    );
    const [queryToCancel, setQueryToCancel] = useState(
        /** @type { QueryInfo= } */ (undefined)
    );

    const {
        data: queriesList,
        error: error,
        isLoading: loading,
        isFetching: fetching,
        dataUpdatedAt,
        isFetched: loaded,
        refetch,
    } = useQuery({
        queryKey: ['queries'],
        queryFn: () => fetchQueriesList(pageToken),
        staleTime: Infinity,
        gcTime: Infinity,
    });

    useEffect(() => {
        if (shouldRefetch) {
            refetch();
            setShouldRefetch(false);
        }
    }, [shouldRefetch]);

    useEffect(() => {
        if (!queriesList?.rows) {
            setAllQueries([]);
            return;
        }

        setAllQueries(allQueries => ([...allQueries, ...queriesList.rows]));
        setPageToken(queriesList.nextPageToken);

    }, [queriesList?.rows]);


    useEffect(() => {
        const totalPages = Math.ceil(allQueries.length / PAGE_SIZE) - 1;
        const pageBeforeLast = totalPages - 1;
        const isNextPageLast = pageBeforeLast === page;

        if (page > 0
            && isNextPageLast
            && pageToken?.token
        ) {
            setShouldRefetch(true);
        }

    }, [page]);


    const start = page * (PAGE_SIZE);
    const end = allQueries.length < start + PAGE_SIZE && !pageToken
        ? allQueries.length
        : page * (PAGE_SIZE) + PAGE_SIZE;

    const queries = allQueries?.slice(start, end);

    const nextPageDisabled = !!(!queries
        || queries.length < PAGE_SIZE
        || fetching
    );

    useEffect(() => {
        if (page === 0 && dataUpdatedAt) {
            setLastUpdate(dataUpdatedAt);
        }
    }, [page, dataUpdatedAt]);

    /** @type { (newPage: number) => void } */
    const updatePage = useCallback((newPage) => {
        setPage(newPage);
    }, []);

    const enableLoading = useCallback(() => {
        setPageToken(undefined);
        setPage(0);
        setShouldRefetch(true);
    }, []);

    /** @type { (id: QueryInfo['id']) => void } */
    const onQueryCancelled = useCallback((id) => {
        const updatedQueriesList = allQueries?.map((q) => {
            if (q.id === id) {
                return /** @type { QueryInfo } */ ({
                    ...q,
                    status: 'Canceled',
                    endTime: new Date(),
                });
            }
            return q;
        });

        setAllQueries([...updatedQueriesList]);

        queryClient.setQueryData(['queries'], {
            rows: updatedQueriesList,
            nextPageToken: pageToken,
        });

    }, [queriesList, queries, pageToken, queryClient.setQueryData]);

    /** @type { (query: QueryInfo) => void } */
    const openCancelQueryDialog = useCallback((query) => {
        setQueryToCancel(query);
    }, []);

    const closeCancelQueryDialog = useCallback(() => {
        setQueryToCancel(undefined);
    }, []);


    return {
        queriesUpdatedAt: lastUpdate,
        loaded,
        queryToCancel,
        nextPageDisabled,
        queries,
        error,
        loading,
        fetching,
        page,
        pageSize: PAGE_SIZE,
        enableLoading,
        updatePage,
        onQueryCancelled,
        openCancelQueryDialog,
        closeCancelQueryDialog,
    };
}

export default useQueriesPage;
