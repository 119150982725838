import { trackEvent } from '../../models/tracking';

/** @type { (databaseNameIsValid: boolean, passwordIsValid: boolean) => void } */
function trackSignupFormInvalid(databaseInvalid, passwordInvalid) {
    const props = {
        type: 'SignupFormInvalid',
        databaseInvalid,
        passwordInvalid,
    };

    trackEvent({
        name: 'Signup: Data Warehouse Step Error',
        type: 'log',
        data: props,
    });
}

/** @type { () => void } */
function trackSignupVerification() {
    const props = {
        type: 'SignupVerificationComplete',
    };

    trackEvent({
        name: 'Signup: Data Warehouse Step',
        type: 'log',
        data: props,
    });
}

export {
    trackSignupFormInvalid,
    trackSignupVerification,
};
