import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import {
    Typography,
    colors,
} from 'ui-components';

/**
 * @typedef { object } Props
 * @prop { boolean } open
 * @prop { string } title
 */

/** @type { React.FC<Props> } */
const FullPageAlert = ({ open, title, children }) => {
    if (!open) {
        return null;
    }
    return (
        <Background>
            <Container>
                {title && (
                    <Typography
                        variant="h5"
                        weight="medium"
                        spacing="mb-6"
                    >
                        {title}
                    </Typography>
                )}
                {children}
            </Container>
        </Background>
    );
};


/** @type { () => ({ open: boolean, toggle: () => void }) } */
const useFullPageAlert = () => {
    const [open, setOpen] = useState(false);
    const toggle = useCallback(() => {
        setOpen((oldVal) => !oldVal);
    }, []);
    return {
        open,
        toggle,
    };
};

const Background = styled('div')`
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Container = styled('div')`
    max-width: 1040px;
    text-align: center;
`;

export { useFullPageAlert };

export default FullPageAlert;
