
import React, { useCallback } from 'react';
import {
    DebouncedInput,
    Typography,
    Layout,
    Icon,
    Switch,
} from 'ui-components';
import FormRow from '../form-row';
import FormPanel from '../form-panel';
import SecretInput from './secret-input';

/** @typedef { import('models/sources/__types').SshParam } Param */
/** @typedef { import('models/sources/__types').SshParamValue } Value */
/** @typedef { import('models/sources/__types').FieldValidationErrors } FieldValidationErrors */


/**
 * @typedef { object } Props
 * @prop { Param } param
 * @prop { Partial<Value> } value
 * @prop { (val: Partial<Value>) => void } setValue
 * @prop { FieldValidationErrors } errors
 */


/** @type { React.FC<Props> } */
const SshParam = ({
    param,
    value,
    setValue,
    errors,
}) => {

    const {
        active = false,
        host = '',
        port = 22,
        username = '',
        password = '',
        privateKey = '',
    } = value || {};

    const paramErrors = errors.sshTunnel || {};

    const onChangeValue = useCallback((e) => {
        const { name, value: elemValue } = e.target;
        const key = /** @type {keyof Value} */ (name);
        const newValue = { ...value };
        newValue[key] = elemValue.trim();
        setValue(newValue);
    }, [setValue, value]);

    const onChangeActive = useCallback((e) => {
        setValue({
            ...value,
            active: e.target.checked,
        });
    }, [setValue, value]);

    return (
        <FormPanel
            label={param.title || 'Connect with SSH'}
            tooltipContent={param.help}
            headerContent={
                active ? (
                    <>
                        <Icon size="small" color="secondaryText" icon="check" />
                        <Typography color="secondaryText" spacing="ml-2">
                            Enabled
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography color="interface">
                            Disabled
                        </Typography>
                    </>
                )
            }
        >
            <FormRow
                label="Host and Port"
            >
                <Layout width="50">
                    <DebouncedInput
                        value={host}
                        name="host"
                        onChange={onChangeValue}
                        error={paramErrors.host}
                        noFloatLabel
                        width="100"
                        placeholder={'IP or host (e.g. 123.45.67.89 or your.server.com)'}
                        helperText={paramErrors.host || 'SSH Host'}
                        alwaysShowError
                        spacing="pl-0"
                    />
                </Layout>

                <Layout width="50">
                    <DebouncedInput
                        value={port}
                        name="port"
                        onChange={onChangeValue}
                        error={paramErrors.port}
                        type="number"
                        noFloatLabel
                        width="100"
                        placeholder={'e.g. 22'}
                        alwaysShowError
                        helperText={paramErrors.port || 'SSH Port'}
                        spacing="pl-0"
                    />
                </Layout>

            </FormRow>
            <FormRow
                label="SSH User"
            >

                <Layout width="50">
                    <DebouncedInput
                        value={username}
                        name="username"
                        onChange={onChangeValue}
                        error={paramErrors.username}
                        noFloatLabel
                        width="100"
                        placeholder={'SSH User'}
                        helperText={paramErrors.username || 'SSH User'}
                        alwaysShowError
                        spacing="pl-0"
                    />
                </Layout>

                <Layout width="50">
                    <SecretInput
                        duration={300}
                        value={password}
                        name="password"
                        onChange={onChangeValue}
                        type="password"
                        noFloatLabel
                        width="100"
                        placeholder="SSH Password"
                        helperText="SSH Password"
                        spacing="pl-0"
                        alwaysShowError
                    />
                </Layout>

            </FormRow>
            <FormRow
                label="SSH Private Key"
            >
                <Layout width="100">
                    <SecretInput
                        duration={300}
                        value={privateKey}
                        name="privateKey"
                        onChange={onChangeValue}
                        error={paramErrors.privateKey}
                        noFloatLabel
                        width="100"
                        multiline
                        placeholder="Your SSH private key"
                        alwaysShowError
                        helperText={paramErrors.privateKey || 'SSH Private Key'}
                        spacing="pl-0"
                    />
                </Layout>
            </FormRow>
            <FormRow
                label="Enable"
            >
                <Layout width="100">
                    <Switch
                        name="active"
                        checked={active}
                        label="Turn on SSH"
                        onChange={onChangeActive}
                    />
                </Layout>
            </FormRow>
        </FormPanel>
    );
};

export default SshParam;
