import React from 'react';
import {
    Card,
    Layout,
    Typography,
} from 'ui-components';

const JobsStatusCards = ({ summary, loading }) => {
    const sum = {
        cur_pending: 0,
        cur_running: 0,
        cur_booting: 0,
        day_success: 0,
        day_failure: 0,
        ...summary || {},
    };
    const pendingJobs = sum.cur_pending;
    const runningJobs = sum.cur_running + sum.cur_booting;
    const successfulJobs = sum.day_success;
    const failedJobs = sum.day_failure;

    return (
        <Layout flex alignItems="stretch">
            <Card spacing="mr-4 mb-4" width="25">
                <Typography color="secondaryText">Pending</Typography>
                <Typography variant="h5" spacing="mb-0 mt-3">
                    {loading ? '-' : pendingJobs}
                </Typography>
            </Card>
            <Card spacing="mr-4 mb-4" width="25">
                <Typography color="secondaryText">Running</Typography>
                <Typography color="secondary" variant="h5" spacing="mb-0 mt-3">
                    {loading ? '-' : runningJobs}
                </Typography>
            </Card>
            <Card spacing="mr-4 mb-4" width="25">
                <Typography color="secondaryText" noWrap>
                    Successful (last&nbsp;24&nbsp;hours)
                </Typography>
                <Typography color="secondary" variant="h5" spacing="mb-0 mt-3">
                    {loading ? '-' : successfulJobs}
                </Typography>
            </Card>
            <Card spacing="mb-4" width="25">
                <Typography color="secondaryText" noWrap>
                    Failed (last&nbsp;24&nbsp;hours)
                </Typography>
                <Typography color="error" variant="h5" spacing="mb-0 mt-3">
                    {loading ? '-' : failedJobs}
                </Typography>
            </Card>
        </Layout>
    );
};

export default JobsStatusCards;
