import React from 'react';
import styled from 'styled-components';

import { routes, routesMap } from './routes';

import AppRoutes from '../../../app/app-routes';

import { useSourcePageContext } from '../use-source-page/source-page-context';

export const PageWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
`;

const SourceNestedRouter = () => {
    const { sourceType } = useSourcePageContext();

    const hasKnowledge = !(
        !sourceType?.links || !Object.keys(sourceType.links).length
    );

    const filteredRoutes = routes.filter(route => (
        route.name === routesMap.knowledge.name ? hasKnowledge : true
    ));

    return (
        <PageWrapper id="source-nested-router">
            {filteredRoutes.length > 0 && <AppRoutes routes={filteredRoutes} nested />}
        </PageWrapper>
    );
};

export default SourceNestedRouter;
