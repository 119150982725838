import { post, put, del } from '../../lib/ajax';
import { addBreadcrumb } from '../../lib/error-reporter';

/** @typedef { import('./types').CreateView } CreateView */
/** @typedef { import('./types').EditView } EditView */
/** @typedef { import('./types').RemoveView } RemoveView */

/** @type { CreateView } */
export const createView = async (params) => {
    const { name, schema, sql } = params;

    try {
        const { data: view } = await post(
            '/views/' + [schema, name].map(encodeURIComponent).join('/'),
            {
                body: {
                    sql,
                },
            }
        );

        return view;

    } catch (error) {
        addBreadcrumb('Failed to create a view', { error });

        throw error;
    }
};

/** @type { EditView } */
export const editView = async (params) => {
    const { name, schema, sql } = params;

    try {
        const { data: view } = await put(
            '/views/' + [schema, name].map(encodeURIComponent).join('/'),
            {
                body: {
                    sql,
                },
            }
        );

        return view;

    } catch (error) {
        addBreadcrumb('Failed to edit a view', { error });

        throw error;
    }
};

/** @type { RemoveView } */
export const removeView = async (params) => {
    const { name, schema } = params;

    try {
        await del('/views/' + [schema, name].map(encodeURIComponent).join('/'));

    } catch (error) {
        addBreadcrumb('Failed to remove a view', { error });

        throw error;
    }
};
