import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import {
    Layout,
    Typography,
    Button,
    Icon,
    Dialog,
} from 'ui-components';

import { reportError } from '../../lib/error-reporter';

/** @typedef { import('./types').Layout } Layout */
/** @typedef { 'error' | 'success' | '' } Status */

/**
 * @typedef { Object } SaveDialogProps
 * @prop { boolean } saveDialogOpen
 * @prop { () => void } toggleSaveDialog
 * @prop { () => Promise<void> } saveDashboard
 */

/** @type { React.FC<SaveDialogProps> } */
const SaveDialog = ({
    saveDialogOpen,
    toggleSaveDialog,
    saveDashboard,
}) => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');

    const save = useCallback(async () => {
        setLoading(true);
        setStatus('');

        try {
            await saveDashboard();
            setStatus('success');
            toggleSaveDialog();

        } catch (/** @type { any } */ err) {
            reportError(err);
            setStatus('error');
        } finally {
            setLoading(false);
        }
    }, [saveDashboard, toggleSaveDialog]);

    useEffect(() => {
        if (!saveDialogOpen) {
            return () => {};
        }

        /** @type { (e: KeyboardEvent) => void } */
        const onKeyDown = e => {
            if (e.key === 'Escape') {
                e.preventDefault();
                toggleSaveDialog();
            } else if (e.key === 'Enter') {
                e.preventDefault();
                save();
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    }, [saveDialogOpen, save, toggleSaveDialog]);

    const actions = [
        <Button
            key="cancel"
            type="plain"
            onClick={toggleSaveDialog}
            disabled={loading}
        >
            Go Back
        </Button>,
        <Button
            key="save"
            color="primary"
            spacing="mr-0"
            onClick={save}
            loading={loading}
        >
            Yes, Save Dashboard
        </Button>,
    ];

    if (status === 'error') {
        actions.unshift(<Error key="error" />);
    } else if (status === 'success') {
        actions.unshift(<Success key="success" />);
    }

    return (
        <Dialog
            compact
            isOpen={saveDialogOpen}
            onClose={toggleSaveDialog}
            title="Save changes to dashboard?"
            justifyContent="center"
            actions={actions}
        >
            <Typography
                color="text"
                variant="body1"
                spacing="m-0"
            >
                This cannot be undone.
            </Typography>
        </Dialog>
    );
};

/** @type { React.FC<{ children: React.ReactNode, spacing?: string, color?: string }> } */
const Help = ({ children, ...props }) => (
    <Typography variant="body2" color="secondaryText" spacing="mb-0" {...props}>
        {children}
    </Typography>
);

/** @type { React.FC } */
const Error = () => (
    <Status>
        <Icon
            prefix="fas"
            icon="exclamation-circle"
            size="sm"
            color="error"
            spacing="mr-2"
        />
        <Help>
            Error, can&apos;t save
        </Help>
    </Status>
);

/** @type { React.FC } */
const Success = () => (
    <Status>
        <Icon
            icon="check"
            size="sm"
            color="secondary"
            spacing="mr-2"
        />
        <Help>
            Saved
        </Help>
    </Status>
);

/** @type { React.FC<{ children: React.ReactNode }> } */
const Status = ({ children }) => (
    <StyledLayout flex alignItems="center">
        {children}
    </StyledLayout>
);

const StyledLayout = styled(Layout)`
    && {
        padding: 0.55rem 1rem 0.45rem;
    }
`;

export default SaveDialog;
