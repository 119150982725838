import React, { useState, useEffect } from 'react';
import {
    Dialog,
    Typography,
    Button,
    Callout,
    Input,
} from 'ui-components';

import { useNavigate } from 'react-router-dom';

import { reportError } from '../../../lib/error-reporter';
import { cloneSource } from '../../../models/sources';
import { trackCloneModalInput, trackCloneModalSubmit } from '../tracking';


/** @typedef { import('models/sources/__types').Source } Source */
/** @typedef { import('models/sources/__types').SourceType } SourceType */

/**
 * @typedef { object } Props
 * @prop { boolean } open
 * @prop { Source } source
 * @prop { () => void } onClose
 */

/** @type { React.FC<Props> } */
const CloneSourceDialog = React.memo(({
    open,
    source,
    onClose,
}) => {

    const navigate = useNavigate();
    const [cloning, setCloning] = useState(false);
    const [finished, setFinished] = useState(false);
    const [error, setError] = useState('');
    const [newTitle, setNewTitle] = useState(source.title + ' - Clone');

    const onCloneSource = async () => {
        setCloning(true);
        try {
            const newSource = await cloneSource(source.id, newTitle);
            trackCloneModalInput(newTitle);
            trackCloneModalSubmit('true');
            navigate('/sources/' + newSource.id);
        } catch (err) {
            const defaultError = 'There was an error when cloning the connector';
            setError(err.message || err.data.message || defaultError);
            reportError(err);
            setCloning(false);
            setFinished(true);
        }
    };

    const onChangeTitle = (e) => setNewTitle(e.target.value);

    useEffect(() => {
        if (!open) {
            return;
        }
        setCloning(false);
        setFinished(false);
        setError('');
    }, [open]);

    const actions = (
        <>
            {!finished && (
                <>
                    <Button
                        onClick={onClose}
                        type="plain"
                        disabled={cloning}
                    >
                        Cancel
                    </Button>
                    <Button
                        loading={cloning}
                        disabled={!newTitle}
                        onClick={onCloneSource}
                        color="primary"
                    >
                        Clone
                    </Button>
                </>
            )}
            {finished && (
                <Button
                    onClick={onClose}
                >
                    Finish
                </Button>
            )}
        </>
    );

    return (
        <Dialog
            isOpen={open}
            onClose={onClose}
            actions={actions}
        >
            {error && (
                <>
                    <Typography
                        spacing="mt-1"
                        color="text"
                        variant="h6"
                        align="center"
                    >
                        Could not clone connector
                    </Typography>
                    <Callout
                        color="error"
                        spacing="mt-4"
                    >
                        {error}
                    </Callout>
                </>
            )}
            {!error && (
                <>
                    <Typography
                        spacing="mt-1"
                        color="text"
                        variant="h6"
                        align="center"
                    >
                        Clone &quot;{source.title}&quot;?
                    </Typography>
                    <Typography
                        color="text"
                        variant="subtitle1"
                        spacing="my-4"
                        align="center"
                    >
                        Enter a name for your new connector.
                    </Typography>
                    <Input
                        onChange={onChangeTitle}
                        value={newTitle}
                        disabled={cloning}
                        label="New connector name"
                        noFloatLabel
                        width="100"
                    />
                </>
            )}
        </Dialog>
    );
});

export default CloneSourceDialog;
