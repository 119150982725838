import useCompleteSignupForm from './use-complete-signup-form';

/** @typedef { import('./use-complete-signup-form').ReturnProps } CompleteSignupReturn */

/**
 * @typedef { object } Props
 * @prop { string } tokenToValidate
 */

/** @type { (props: Props) => CompleteSignupReturn } */
function useCompleteSignupPage({ tokenToValidate }) {
    return useCompleteSignupForm({ tokenToValidate });
}

export default useCompleteSignupPage;
