import React from 'react';
import styled from 'styled-components';

import {
    Layout,
    Typography,
    SearchInput,
    Link,
    Icon,
} from 'ui-components';

/**
 * @typedef { object } Props
 * @prop { () => void } requestDataSource
 * @prop { string } searchTerm
 * @prop { React.ChangeEventHandler<HTMLInputElement> } onSearchChanged
 */

/** @type { React.FC<Props> } */
const Sidebar = ({
    requestDataSource,
    searchTerm,
    onSearchChanged,
}) => {

    return (
        <SidebarContainer spacing="pr-6">
            <Sticky spacing="pt-4">
                <SearchInput
                    width="100"
                    spacing="mb-0 p-0"
                    value={searchTerm}
                    label="Search for Connectors"
                    onChange={onSearchChanged}
                    debounceDuration={300}
                    noFloatLabel
                    autoFocus
                />

                <Layout flex alignItems="center" spacing="mb-4 mt-6">
                    <Icon icon="bolt" spacing="mr-1" size="sm" />
                    <Typography variant="body2" weight="medium" spacing="m-0">
                        Snap Connectors
                    </Typography>
                </Layout>

                <Typography variant="body2" color="secondaryText">
                    Easy setup, unlimited connections included. Can’t find a connector?{' '}
                    <SidebarLink
                        inline
                        component="span"
                        color="secondaryText"
                        onClick={requestDataSource}
                        weight="normal"
                    >
                        Request it
                    </SidebarLink>
                </Typography>

                <Layout flex alignItems="center" spacing="mb-2 mt-6">
                    <Icon
                        icon="lock"
                        size="sm"
                        spacing="mr-1"
                        color="interface"
                    />
                    <Typography
                        variant="caption"
                        color="interface"
                        spacing="m-0"
                    >
                        Your data is secure and encrypted
                    </Typography>
                </Layout>

            </Sticky>
        </SidebarContainer>
    );
};

const SidebarLink = styled(Link)`
    text-decoration: underline;
    cursor: pointer;
    font-weight: normal;
`;

const Sticky = styled(Layout)`
   position: sticky;
   top: 0px;
`;

const SidebarContainer = styled(Layout)`
   width: 35%;
   max-width: 300px;
`;

export default Sidebar;
