import React from 'react';
import { Image, Typography } from 'ui-components';

import mailImagePath from '../../images/icon-mail-sent.svg';

/** @type { React.FC<{ email: string }> } */
const ResetPasswordSuccess = ({ email }) => (
    <>
        <Typography align="center" variant="h4" spacing="mb-6">
            Instructions sent!
        </Typography>

        <Image
            src={mailImagePath}
            width="100"
            height="70px"
            size="contain"
            repeat="no-repeat"
            position="center"
            align="center"
        />

        <Typography align="center" spacing="mt-6 mb-5">
            Instructions for resetting your
            password have been sent to {email}
        </Typography>

        <Typography align="center" color="secondaryText">
            You’ll receive this email within a few minutes.
            Please check your spam folder if you don’t see
            it in your inbox.
        </Typography>
    </>
);

export default ResetPasswordSuccess;
