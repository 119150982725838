import React from 'react';
import { withProfiler } from '../lib/sentry';

import AppLayout from './app-layout';
import AppRoutes from './app-routes';
import useApp from './use-app';

/** @type { React.FC } */
const App = () => {
    const {
        routes,
        fullbleed,
        feedback,
        toast,
    } = useApp();

    const layoutProps = {
        fullbleed,
        feedback,
        toast,
    };

    return (
        <AppLayout {...layoutProps}>
            {routes.length > 0 && <AppRoutes routes={routes} />}
        </AppLayout>
    );
};

export default withProfiler(App);
