import React, { useRef, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { isObject } from 'lodash';
import numeral from 'numeral';
import moment from 'moment';

import {
    PieChart,
    ColumnChart,
    LineChart,
    Typography,
    Layout,
    colors,
} from 'ui-components';

import useWindowSize from '../../shared/use-window-size';

/** @typedef { import('./__types').Row } Row */
/** @typedef { import('./../../models/queries/__types').QueryResultData } QueryData */
/** @typedef { import('./__types').VisualizationType } VisualizationType */
/** @typedef { import('./__types').VisualizationProps } VisualizationProps */
/** @typedef { import('./__types').LegendPosition } LegendPosition */
/** @typedef { import('./__types').LegendAlignment } LegendAlignment */
/** @typedef { Exclude<VisualizationType, 'table'> } VisualizationChartType */
/** @typedef { Array<string | number | Date>[] } Data */
/** @typedef { 'none' | 'function' } CurveType */

/**
 * @typedef { import('styled-components').ThemedStyledFunction<C, any, O> } Styled
 * @template { 'div' } C
 * @template { object } [O={}]
 */

/**
 * @typedef { object } ChartProps
 * @prop { Data } data
 * @prop { string } [vAxisFormat]
 * @prop { number } [threshold]
 * @prop { boolean } [negativeThreshold]
 * @prop { boolean } [positiveThreshold]
 * @prop { LegendPosition } [legendPosition]
 * @prop { LegendAlignment } [legendAlignment]
 * @prop { string | number } [width]
 * @prop { string | number } [height]
 * @prop { CurveType | null } curveType
 */

/** @type { React.FC<ChartProps> } */
const SingleValue = ({
    data,
    width,
    height,
    vAxisFormat,
    threshold,
    negativeThreshold,
    positiveThreshold,
}) => {
    const value = data[1][0];
    let color = 'text';

    const formattedValue = useMemo(() => {
        if (value instanceof Date) {
            return formatDate(value.toISOString());
        }

        switch (vAxisFormat) {
            case 'short-number':
                return numeral(value).format('0[.]0a').toUpperCase();

            case 'currency':
                return numeral(value).format('$0,0[.]00');

            case 'short-currency':
                return numeral(value).format('$0[.]0a').toUpperCase();

            case 'percent':
                return numeral(value).format('0[.]00%');

            case 'number': // break intentionally omitted
            default:
                return numeral(value).format('0,0[.]00');
        }
    }, [vAxisFormat, value]);

    if (threshold) {
        const valueAsNumber = Number(value);

        if (negativeThreshold && valueAsNumber < threshold) {
            color = 'error';
        }
        if (positiveThreshold && valueAsNumber > threshold) {
            color = 'success';
        }
    }

    return (
        <InlineSize
            flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={String(width || '100%').replace(/\D/g, '')}
            height={String(height || '100%').replace(/\D/g, '')}
        >
            <Typography component="h1" variant="h1" color={color} spacing="m-0">
                {formattedValue}
            </Typography>
        </InlineSize>
    );
};

const InlineSize = styled(Layout)`
    && {
        container-type: inline-size;

        & > h1 {
            font-size: min(7.5rem, max(2.125rem, 1.25rem + 10cqi));
        }
    }
`;

/**
 * @typedef { object } Chart
 * @prop { string } [vAxisFormat]
 * @prop { LegendPosition } [legendPosition]
 * @prop { LegendAlignment } [legendAlignment]
 * @prop { (queryData: QueryData) => Row[] } getRows
 * @prop { React.FC<ChartProps> } Component
 */

/**
 * These charts to be used depending on the selected `visualizationChartType`,
 * like this: `const chart = charts[visualizationChartType]`, where the component
 * itself is in the `chart.Component` and the rest of the props are helpers.
 * Please see the implementation of the `WorkbenchChart` component for the example.
 *
 * @type { Record<VisualizationChartType, Chart> }
 */
const charts = {
    'single-value': {
        getRows: ({ rows = [] }) => rows,
        Component: SingleValue,
    },
    'pie-chart': {
        vAxisFormat: 'short',
        legendPosition: 'right',
        legendAlignment: 'center',
        getRows: ({ rows = [] }) => {
            if (rows.length <= 6) {
                return rows;
            }

            const [keyX, ...otherKeys] = Object.keys(rows[0]);

            const otherRow = rows.slice(5).reduce((acc, row) => {
                otherKeys.forEach(key => {
                    acc[key] = toNumber(acc[key]) + toNumber(row[key]);
                });
                return acc;
            }, { [keyX]: 'Other' });

            return [...rows.slice(0, 5), otherRow];
        },
        Component: PieChart,
    },
    'column-chart': {
        vAxisFormat: 'short',
        legendPosition: 'bottom',
        legendAlignment: 'start',
        getRows: ({ rows = [] }) => rows,
        Component: ColumnChart,
    },
    'line-chart': {
        vAxisFormat: 'short',
        legendPosition: 'bottom',
        legendAlignment: 'start',
        getRows: ({ rows = [] }) => rows,
        Component: LineChart,
    },
};

/** @type { (type: VisualizationType) => type is VisualizationChartType } */
const isVisualizationChart = type => Object.keys(charts).includes(type);

/** @type { React.FC<VisualizationProps> } */
const WorkbenchChart = React.memo(({
    queryData,
    visualizationReport,
    noBorder,
    ...props
}) => {
    const containerRef = useRef(
        /** @type { HTMLDivElement | null } */ (null)
    );
    const windowSize = useWindowSize();

    if (!queryData?.rows?.length || !isVisualizationChart(visualizationReport.type)) {
        return null;
    }

    const { Component, getRows, ...others } = charts[visualizationReport.type];

    const {
        vAxisFormat,
        legendPosition,
        legendAlignment,
        threshold,
        positiveThreshold,
        negativeThreshold,
    } = {
        ...others,
        ...props,
        ...visualizationReport.config,
    };

    const data = /** @type { Data } */ ([]);

    const rows = getRows(queryData);
    const [firstRow = {}] = rows;
    data.push(Object.keys(firstRow));

    rows.forEach(row => {
        const [x, ...others] = Object.values(row);
        data.push([formatAxisX(x), ...others.map(toNumber)]);
    });

    const width = visualizationReport.type === 'column-chart'
        ? getColumnChartWidth(data, containerRef.current, windowSize.width)
        : '100%';

    return (
        <Container
            ref={containerRef}
            backgroundColor={colors.white}
            noBorder={noBorder}
        >
            <Component
                /**
                 * Using width as key to force the chart to update
                 * as by default the google chart doesnt rerender
                 * when the props change
                 */
                key={width}
                data={data}
                vAxisFormat={vAxisFormat}
                legendPosition={legendPosition}
                legendAlignment={legendAlignment}
                width={width}
                height="100%"
                curveType="none"
                threshold={threshold}
                negativeThreshold={negativeThreshold}
                positiveThreshold={positiveThreshold}
            />
        </Container>
    );
});

/**
 * @typedef { object } ContainerProps
 * @prop { string } [backgroundColor]
 * @prop { boolean } [noBorder]
 */

const Container = /** @type { Styled<'div', ContainerProps> } */ (styled.div)`
    width: 100%;
    height: 100%;
    overflow: auto hidden;

    ${({ backgroundColor }) => backgroundColor && css`
        background-color: ${backgroundColor};
    `}

    ${({ noBorder }) => !noBorder && css`
        border-left: 1px solid ${colors.background};
        width: calc(100% - 1px);
    `}
`;

/** @type { (data: Row[], elem: HTMLDivElement | null, width?: number) => string } */
const getColumnChartWidth = (data, containerElem, width) => {
    const containerWidth = containerElem?.clientWidth
        || width || window.innerWidth;
    const axisWidth = 240;
    const columnsWidth = data.length * 4;
    const minWidth = axisWidth + columnsWidth;
    if (containerWidth > minWidth) {
        return '100%';
    }
    return minWidth + 'px';
};

/** @type { (value: any) => string } */
const formatAxisX = value => {
    if (typeof value === 'boolean') {
        return value ? 'True' : 'False';
    }

    if (isObject(value)) {
        if ('value' in value) {
            return toString(value['value']);
        }

        return JSON.stringify(value);
    }

    return toString(value);
};

/** @type { (date: string) => string } */
const formatDate = (date) => {
    const d = moment.utc(date);

    if (!d.isValid()) {
        return 'Invalid Date';
    }

    return d.format('MMM DD, YYYY [@] HH:mm');
};

/** @type { (value: any) => string } */
const toString = value => String(value || '');

/** @type { (value: any) => number } */
const toNumber = value => {
    const number = parseFloat(value);
    return !isNaN(number) ? number : 0;
};

export default WorkbenchChart;
