import generatePDF from 'react-to-pdf';
import filenamify from 'filenamify/browser';

/** @typedef { import('react-to-pdf').Options } Options */
/** @typedef { Awaited<ReturnType<typeof generatePDF>> } PDF */
/** @typedef { { x: number, y: number } } Coordinates */
/** @typedef { { w: number, h: number } } Dimensions */

const MM_TO_PX = 3.77952755906;

/** @type { (px: number) => number } */
function convertToMM(px) {
    return px / MM_TO_PX;
}

/** @type { (x: number, y: number) => Coordinates } */
export function getCoordinates(x, y) {
    return {
        x: convertToMM(x),
        y: convertToMM(y),
    };
}

/** @type { (w: number, h: number) => Dimensions } */
export function getDimensions(w, h) {
    return {
        w: convertToMM(w),
        h: convertToMM(h),
    };
}

/** @type { (width: number, height: number) => [number, number] } */
export function getFormat(width, height) {
    return [
        Math.ceil(convertToMM(width)),
        Math.ceil(convertToMM(height)),
    ];
}

/** @type { (title: string) => string } */
export function getFilename(title) {
    return filenamify(title, { replacement: '-' }) + '.pdf';
}

/** @type { (format: [number, number]) => 'landscape' | 'portrait' } */
export function getOrientation([width, height]) {
    return width > height ? 'landscape' : 'portrait';
}

/** @type { (options?: Options) => Options } */
function getOptions(options) {
    return {
        ...options,
        overrides: {
            // https://artskydj.github.io/jsPDF/docs/jsPDF.html
            pdf: {
                compress: true,
                ...options?.overrides?.pdf,
            },
            // https://html2canvas.hertzen.com/configuration
            canvas: {
                foreignObjectRendering: true,
                ...options?.overrides?.canvas,
            },
        },
    };
}

/** @type { (options?: Options) => Promise<PDF> } */
export async function createPDF(options) {
    return generatePDF(() => document.body, getOptions(options));
}
