import { useCallback, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useInput } from 'ui-components';
import { parse } from 'qs';

import { useRequest } from '../../lib/ajax';
import gotoUrl from '../../lib/goto-url';
import { useSession } from '../../shared/context-providers';

import isValidEmail from '../../lib/is-valid-email';

/** @typedef { import('../../app/__types').PageProps } PageProps */

/** @type { (url: string) => URL | null } */
function getAbsoluteUrl(url) {
    try {
        return new URL(url);
    } catch {
        return null;
    }
}

/**
 * @typedef { object } Props
 *
 * @prop { string } initialEmail
 */

/**
 * @typedef { object } ReturnProps
 *
 * @prop { string } email
 * @prop { string } password
 * @prop { boolean } emailIsValid
 * @prop { boolean } passwordIsValid
 * @prop { boolean } loading
 * @prop { boolean } disabled
 * @prop { { message: string } } error
 * @prop { string } googleError
 * @prop { (newEmail: string) => void } onEmailChanged
 * @prop { (newPassword: string) => void } onPasswordChanged
 * @prop { () => void } onGoogleClicked
 * @prop { () => void } onLoginClicked
 * @prop { () => void } onEnterPressed
 */

/** @type { (props: Props) => ReturnProps } */
function useLoginPage({ initialEmail }) {
    const [session, sessionActions] = useSession();
    const { afterLoginRedirect, mfaRequired = false } = session;
    const { refreshSession } = sessionActions;

    const navigate = useNavigate();
    const location = useLocation();

    const [googleError, setGoogleError] = useState('');

    const emailInput = useInput('Email', initialEmail);
    const passwordInput = useInput('Password');

    const email = emailInput.value;
    const password = passwordInput.value;

    const emailIsValid = isValidEmail(email);
    const passwordIsValid = !!password;

    const onEmailChanged = emailInput.bind.onChange;
    const onPasswordChanged = passwordInput.bind.onChange;


    const { go: login, error } = useRequest('/users/login', 'POST', {
        email,
        password,
    });

    const [loading, setLoading] = useState(false);
    const disabled = loading || !emailIsValid || !passwordIsValid;

    const onGoogleClicked = useCallback(() => {
        gotoUrl('/users/auth/google', { afterLoginRedirect });
    }, [afterLoginRedirect]);

    const onLoginClicked = useCallback(() => {
        if (loading || !emailIsValid || !passwordIsValid) {
            return;
        }

        setLoading(true);

        login().then(async (res) => {
            if (!res.ok) {
                setLoading(false);
                return;
            }

            await refreshSession();

            const path = res.data.afterLoginRedirect || afterLoginRedirect || '/';

            const absoluteUrl = getAbsoluteUrl(path);

            if (absoluteUrl) {
                if (absoluteUrl.hash) {
                    const pathToRedirectTo = absoluteUrl.hash.slice(1);
                    setTimeout(() => navigate(pathToRedirectTo, { replace: true }), 0);
                } else {
                    setTimeout(() => window.location.replace(absoluteUrl), 0);
                }
                return;
            }

            setTimeout(() => navigate(path, { replace: true }), 0);
        });
    }, [
        loading,
        emailIsValid,
        passwordIsValid,
        afterLoginRedirect,
        navigate,
        refreshSession,
        login,
    ]);

    const onEnterPressed = useCallback((e) => {
        if (disabled || e.key !== 'Enter') {
            return;
        }
        onLoginClicked();
    }, [disabled, onLoginClicked]);

    useEffect(() => {
        const { error } = parse(location.search, { ignoreQueryPrefix: true });

        if (error) {
            // Remember the error message, and remove it from the url
            setGoogleError(decodeURIComponent(String(error || '')));
            navigate('/login', { replace: true });
        }
    }, [location.search]);

    return {
        email,
        password,
        emailIsValid,
        passwordIsValid,
        loading,
        disabled,
        error,
        googleError,
        mfaRequired,
        onEmailChanged,
        onPasswordChanged,
        onGoogleClicked,
        onLoginClicked,
        onEnterPressed,
    };
}

export default useLoginPage;
