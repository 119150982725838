import React from 'react';
import styled from 'styled-components';

import {
    Loader,
    Layout,
    Typography,
    ExpansionPanelHeader,
} from 'ui-components';

import WarehousesListItem from './warehouses-list-item';
import Pagination from '../../shared/pagination';
import Feature from '../../shared/feature';

const WarehousesListHeader = () => (
    <ExpansionPanelHeader>
        <Layout width="100" flex alignItems="center" justifyContent="space-between">
            {/* Spacer for switch button even though it doesn't get a label */}
            <SwitchColumn spacing="-m-1" />

            <Feature databaseType="redshift">
                <Layout width="30" spacing="pr-3">
                    <Typography color="secondaryText" noWrap>
                        Name
                    </Typography>
                </Layout>

                <Layout width="30" spacing="pr-3">
                    <Typography color="secondaryText" noWrap>
                        Host
                    </Typography>
                </Layout>

                <Layout width="30" spacing="">
                    <Typography color="secondaryText" noWrap>
                        Owner
                    </Typography>
                </Layout>
            </Feature>

            <Feature not databaseType="redshift">
                <Layout width="30" spacing="pr-3">
                    <Typography color="secondaryText" noWrap>
                        Name
                    </Typography>
                </Layout>

                <Layout width="60" spacing="">
                    <Typography color="secondaryText" noWrap>
                        Owner
                    </Typography>
                </Layout>
            </Feature>

        </Layout>
    </ExpansionPanelHeader>
);

const WarehousesNotFound = () => (
    <ExpansionPanelHeader>
        <Typography align="center">
            No warehouses matching the search keyword.
        </Typography>
    </ExpansionPanelHeader>
);

const WarehousesLoading = () => (
    <ExpansionPanelHeader spacing="pt-4">
        <Loader big active message="Loading Warehouses" />
    </ExpansionPanelHeader>
);

const WarehousesList = ({
    warehouses,
    loading,
    onSwitch,
    onSuspend,
    onUnsuspend,
    onReassign,
    page,
    updatePage,
    pageSize,
    user,
}) => {
    if (loading) {
        return (
            <Layout spacing="mt-6">
                <WarehousesLoading />
            </Layout>
        );
    }

    if (!warehouses?.length) {
        return (
            <Layout spacing="mt-6">
                <WarehousesNotFound />
            </Layout>
        );
    }

    // All warehouses are already filtered and sorted in the server.
    // The warehouses may have an extra warehouse used for pagination.
    const pageData = warehouses && warehouses.slice(0, pageSize);

    const getListItemProps = warehouse => ({
        user,
        warehouse,
        onSwitch,
        onSuspend,
        onUnsuspend,
        onReassign,
    });

    const paginationProps = {
        page,
        updatePage,
        pageSize,
        totalItems: warehouses.length,
    };

    return (
        <Layout spacing="mt-6">
            <WarehousesListHeader />

            {pageData.map(warehouse => (
                <WarehousesListItem key={warehouse.id} {...getListItemProps(warehouse)} />
            ))}

            <Pagination {...paginationProps} />
        </Layout>
    );
};

const SwitchColumn = styled(Layout)`
    min-width: 56px;
`;

export default WarehousesList;
