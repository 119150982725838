import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';

import './lib/sentry'; // first initialization of sentry
import ContextProviders from './shared/context-providers';

import App from './app';

const rootElem = document.getElementById('root');

if (rootElem) {
    const root = ReactDOM.createRoot(rootElem);
    root.render((
        <ContextProviders>
            <Router future={{ v7_startTransition: true }}>
                <App />
            </Router>
        </ContextProviders>
    ));
} else {
    throw new Error('Root DOM element to mount react not found (#root)');
}
