import React, { useEffect, useCallback } from 'react';

import {
    Callout,
    Layout,
    Loader,
    Select,
    SelectItem,
} from 'ui-components';
import MarkdownRenderer from '../../../shared/markdown-renderer';

/** @typedef { import('models/sources/__types').Source } Source */
/** @typedef { import('models/sources/__types').SchemaParam } Param */
/** @typedef { import('models/sources/__types').SchemaParamValue } Value */
/** @typedef { import('models/sources/__types').SchemaParamOption } ParamOption */

/**
 * @typedef { object } State
 * @prop { boolean= } loading
 * @prop { boolean= } loaded
 * @prop { string= } error
 * @prop { ParamOption[] } options
 */

/**
 * @typedef { object } Props
 * @prop { Source } source
 * @prop { Param } param
 * @prop { string } value
 * @prop { State } state
 * @prop { (value: State) => void } setState
 * @prop { (value: State) => void } setValue
 * @prop { () => Promise<ParamOption[]>} loadSchemas
 */

/** @type { React.FC<Props> } */
const SchemaParam = ({
    source,
    param,
    value,
    setValue,
    state,
    setState,
    loadSchemas,
}) => {
    const {
        options = [],
        loading = false,
        loaded = false,
        error,
    } = state;

    const shouldLoadOptions = !loaded && !loading && !error;

    const disabled = source.lastRuntime != null;

    const onSelectChanged = useCallback((e) => {
        setValue(e.target.value);
    }, [setValue]);

    const load = useCallback(async () => {
        setState({
            ...state,
            loaded: false,
            loading: true,
            error: undefined,
        });

        try {
            const schemas = await loadSchemas();
            setState({
                ...state,
                options: schemas,
                loaded: true,
                loading: false,
            });
        } catch (e) {
            setState({
                ...state,
                loaded: true,
                loading: false,
                error: e.message,
            });
        }
    }, [setState, loadSchemas]);

    useEffect(() => {
        if (!disabled && shouldLoadOptions) {
            load();
            return;
        }
        if (disabled && shouldLoadOptions) {
            // If its disabled we don't need to show all the schemas
            // Only the current value
            setState({
                options: [{ name: value }],
            });
        }
    }, [shouldLoadOptions, load]);

    if (error) {
        return <Callout color="error">{error}</Callout>;
    }

    if (loading) {
        return (
            <Layout spacing="ml-4">
                <Loader active />
            </Layout>
        );
    }

    return (
        <Layout relative width="100" mdWidth="50">
            <Select
                onChange={onSelectChanged}
                value={options.length ? value || '' : ''}
                noFloatLabel
                width="100"
                spacing="mx-0 px-0"
                disabled={disabled}
                helperText={(disabled
                    ? 'This cannot be changed after the first collection'
                    : param.description && (
                        <MarkdownRenderer source={param.description} />
                    )
                )}
            >
                <SelectItem disabled value="">
                    Select
                </SelectItem>

                {!!options.length && options.map(option => (
                    <SelectItem value={option.name} key={option.name}>
                        {option.name}
                    </SelectItem>
                ))}
            </Select>
        </Layout>
    );
};

export default SchemaParam;
