export const redshiftDatabaseNameValidators = [
    { test: v => /^.{4,22}$/.test(v), message: '4-22 characters long' },
    { test: v => /^[A-Za-z].*$/.test(v), message: 'Begin with a letter' },
    { test: v => /^[^A-Z]+$/.test(v), message: 'Only lowercase letters' },
    { test: v => /^[a-z0-9_]+$/.test(v), message: 'Characters allowed: a-z, 0-9, _' },
];

export const bigqueryDatabaseNameValidators = [
    { test: v => /^.{4,22}$/.test(v), message: '4-22 characters long' },
    { test: v => /^[A-Za-z].*$/.test(v), message: 'Begin with a letter' },
    { test: v => /^[^A-Z]+$/.test(v), message: 'Only lowercase letters' },
    { test: v => /^[a-z0-9-]+$/.test(v), message: 'Characters allowed: a-z, 0-9, -' },
];
