import React from 'react';

import {
    Button,
    Dialog,
    Divider,
    Icon,
    Layout,
    Loader,
} from 'ui-components';

import useRecipesDialog from './use-recipes-dialog';
import RecipesPickStep from './recipes-pick-step';
import RecipesConfigStep from './recipes-config-step';
import RecipesResultsStep from './recipes-results-step';
import { DialogWrapper } from './shared';

/** @typedef { import('models/recipes').Recipe } Recipe */

/**
 * @typedef { object } Props
 * @prop { boolean } isOpen
 * @prop { () => void } onClose
 * @prop { (newQuery: string) => void } onQueryChanged
 */

/* eslint-disable complexity */
/** @type { React.FC<Props> } */
export const RecipesDialog = ({
    isOpen,
    onClose,
    onQueryChanged,
}) => {

    const {
        source,
        selectedRecipe,
        selectedOutputs,
        running,
        dryRunning,
        finished,
        runningQueryName,
        successfulQueries,
        failedQueries,
        selectableSources,
        selectableRecipes,
        destinationPrefix,
        destinationSchema,
        runDisabled,
        sourceSearchTerm,
        recipeSearchTerm,
        schemas,
        updateSearch,
        resetConfig,
        setDestinationPrefix,
        setDestinationSchema,
        reset,
        run,
        open,
        close,
        goToRecipeDashboard,
        isOutputSelected,
        isRecipeSelected,
        toggleSelectedOutput,
        toggleSelectedRecipe,
        isPartiallySelected,
        toggleSource,
        isSourceSelected,
        getSourceSchemaAndDestination,
        deselectErroredOutputs,
        selectedSources,
        loading,
        currentStep,
        nextStep,
    } = useRecipesDialog({
        onClose,
        onQueryChanged,
    });

    const title = currentStep === 'pick'
        ? 'Available Recipes'
        : 'Run Recipe';

    const actions = (
        <>
            {currentStep !== 'results' && (
                <>
                    <Button
                        type="plain"
                        disabled={running}
                        onClick={close}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        type="outline"
                        loading={running}
                        disabled={selectedOutputs.length !== 1}
                        onClick={open}
                    >
                        Open in workbench
                    </Button>
                </>
            )}
            {currentStep === 'pick' && (
                <Button
                    color="primary"
                    disabled={!selectedOutputs.length}
                    onClick={nextStep}
                    loading={running}
                    spacing="mr-0"
                >
                    Next
                </Button>
            )}
            {currentStep === 'config' && (
                <Button
                    color="primary"
                    disabled={runDisabled}
                    onClick={run}
                    loading={dryRunning}
                    spacing="mr-0"
                >
                    <Icon icon="play-circle" spacing="mr-1" />
                    Run
                </Button>
            )}
            {currentStep === 'results' && (
                <Button
                    color="primary"
                    onClick={close}
                    spacing="mr-0"
                >
                    Close
                </Button>
            )}
        </>
    );

    return (
        <Dialog
            title={title}
            isOpen={isOpen}
            onClose={close}
            actions={actions}
        >

            <DialogWrapper>
                <Divider spacing="m-0 -mx-4" />

                {loading && (
                    <Layout spacing="p-5 mt-4">
                        <Loader relative active big message="Loading Recipes" />
                    </Layout>
                )}

                {currentStep === 'pick' && !loading && (
                    <RecipesPickStep
                        source={source}
                        selectableSources={selectableSources}
                        selectableRecipes={selectableRecipes}
                        getSourceSchemaAndDestination={getSourceSchemaAndDestination}
                        isSourceSelected={isSourceSelected}
                        toggleSource={toggleSource}
                        isRecipeSelected={isRecipeSelected}
                        toggleSelectedRecipe={toggleSelectedRecipe}
                        isPartiallySelected={isPartiallySelected}
                        isOutputSelected={isOutputSelected}
                        toggleSelectedOutput={toggleSelectedOutput}
                        goToRecipeDashboard={goToRecipeDashboard}
                        sourceSearchTerm={sourceSearchTerm}
                        recipeSearchTerm={recipeSearchTerm}
                        updateSearch={updateSearch}
                        selectedSources={selectedSources}
                    />
                )}

                {currentStep === 'config' && !loading && (
                    <RecipesConfigStep
                        dryRunning={dryRunning}
                        failedQueries={failedQueries}
                        getSourceSchemaAndDestination={getSourceSchemaAndDestination}
                        toggleSelectedRecipe={toggleSelectedRecipe}
                        isPartiallySelected={isPartiallySelected}
                        isOutputSelected={isOutputSelected}
                        toggleSelectedOutput={toggleSelectedOutput}
                        isRecipeSelected={isRecipeSelected}
                        selectedSources={selectedSources}
                        selectedRecipe={/** @type {Recipe} */(selectedRecipe)} // No longer optional
                        destinationPrefix={destinationPrefix}
                        destinationSchema={destinationSchema}
                        setDestinationPrefix={setDestinationPrefix}
                        setDestinationSchema={setDestinationSchema}
                        schemas={schemas}
                        resetConfig={resetConfig}
                        deselectErroredOutputs={deselectErroredOutputs}
                    />
                )}

                {currentStep === 'results' && !loading && (
                    <RecipesResultsStep
                        running={running}
                        runningQueryName={runningQueryName}
                        finished={finished}
                        selectedRecipe={/** @type {Recipe} */(selectedRecipe)} // No longer optional
                        successfulQueries={successfulQueries}
                        failedQueries={failedQueries}
                        destinationPrefix={destinationPrefix}
                        destinationSchema={destinationSchema}
                        reset={reset}
                    />
                )}

            </DialogWrapper>

        </Dialog>
    );
};

export default RecipesDialog;
