import React from 'react';
import styled from 'styled-components';

import {
    Layout,
    Typography,
    Link as ClickLink,
    Icon,
    colors,
} from 'ui-components';

/** @typedef { import('./../../models/queries/__types').QueryResultData } QueryResultData */
/** @typedef { import('react').ReactNode } ReactNode */

/**
 * @typedef { object } Props
 * @prop { QueryResultData } queryData
 * @prop { ReactNode } [content]
 */

/** @type { React.FC<Props> } */
const WorkbenchCallToAction = ({ queryData, content }) => {
    if (content) {
        return <CallToAction>{content}</CallToAction>;
    }

    return <DefaultCallToAction queryData={queryData} />;
};

/** @type { React.FC<{ queryData: QueryResultData }> } */
const DefaultCallToAction = ({ queryData }) => {
    const viewName = getViewName(queryData);

    if (!viewName) {
        return null;
    }

    return (
        <CallToAction>
            <DefaultContent viewName={viewName} />
        </CallToAction>
    );
};

/** @type { React.FC<{ viewName: string }> } */
export const DefaultContent = ({ viewName }) => (
    <>
        <Typography color="secondaryText" align="center" spacing="mb-2">
            You’ve just saved the <Code>{viewName}</Code> view!
        </Typography>

        <Typography color="secondaryText" align="center" spacing="mb-2">
            You can now visualize it directly in your{' '}

            <ClickLink inline component="span" color="primary" link="/connect">
                BI tool
            </ClickLink>
        </Typography>

        <Icon icon="check-circle" size="lg" color="secondary" />
    </>
);

/** @type { React.FC<{ children: React.ReactNode }> } */
const CallToAction = ({ children }) => (
    <Container>
        <Layout
            flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            spacing="px-7 py-5"
        >
            {children}
        </Layout>
    </Container>
);

const Container = styled(Layout)`
    max-height: 100%;
    display: block;
    overflow: auto;
`;

export const Code = styled('code')`
    border: 1px solid ${colors.secondaryInterface};
    background: ${colors.secondaryBackground};
    border-radius: 0.25rem;
    padding: 0.125rem 0.25rem;
`;

/** @type { (queryData: QueryResultData) => string } */
export function getViewName(queryData) {
    const { command, objectType, objectName = '' } = queryData;
    if (command !== 'CREATE') {
        return '';
    }

    if (objectType !== 'View') {
        return '';
    }

    return objectName;
}

export default WorkbenchCallToAction;
