import React from 'react';
import styled from 'styled-components';
import openSupportChat from '../../../shared/use-support-chat';
import MarkdownRenderer from '../../../shared/markdown-renderer';

import {
    Icon,
    Banner,
    Layout,
} from 'ui-components';

import { useToast } from '../../../shared/context-providers';

/** @typedef { import('../../../app/__types').PageProps } PageProps */

/** @typedef { PageProps['openToast'] } OpenToast */

/** @typedef { import('../../../models/sources/__types').CollectJob } CollectJob */

/**
 * @typedef { object } Props
 * @prop { CollectJob } job
 * @prop { string } title
 */

const SupportButtonContainter = styled(Layout)`
&:hover {
    cursor: pointer;
    filter: brightness(80%);
}
&:active {
    filter: brightness(40%);
}`;

/** @type {React.FC<{jobId: any, openToast: OpenToast, sourceTitle: string}>} */
const BannerFooter = ({ jobId, openToast, sourceTitle }) => {
    const msg = `I would like help with ${sourceTitle} (Last collection failed, Job ID: ${jobId})`;
    const openChatOrToast = () => openSupportChat(msg, () => (
        openToast({
            // eslint-disable-next-line max-len
            message: 'Support Chat could not open. Your browser or ad blocker may be stopping our popups.',
            type: 'warning',
        })
    ));

    return (
        <Layout flex>
            <SupportButtonContainter
                spacing="mr-6"
                flex
                alignItems="center"
                onClick={openChatOrToast}
            >
                <Icon icon="comments-alt" size="sm" spacing="mr-1" />
                <span>Support</span>
            </SupportButtonContainter>
            <span>Job ID: {jobId}</span>
        </Layout>
    );
};

/** @type {React.FC<Props>} */
const SourceErrorBanner = ({ job, title }) => {
    const { openToast } = useToast();

    return (
        <Layout spacing="my-5">
            <Banner
                footer={<BannerFooter jobId={job.id} openToast={openToast} sourceTitle={title} />}
                headerText="Last collection failed"
                type="error"
            >
                <MarkdownRenderer source={job.error} />
            </Banner>
        </Layout>
    );
};

export default SourceErrorBanner;
