import React from 'react';
import { Loader } from 'ui-components';

import AuthLayout from '../../shared/auth/auth-layout';
import AuthLinks from '../../shared/auth/auth-links';

import AcceptInviteForm from './accept-invite-form';
import AcceptInviteFailure from './accept-invite-failure';
import useAcceptInvitePage from './use-accept-invite-page';

/** @typedef { import('../../app/__types').PageProps } PageProps */

/** @type { React.FC<PageProps> } */
const AcceptInvitePage = ({ token }) => {
    const {
        firstName,
        lastName,
        password,
        username,
        email,
        databaseName,
        inviteeHasAccount,
        firstNameIsValid,
        lastNameIsValid,
        passwordIsValid,
        usernameIsValid,
        tokenIsValid,
        inviteIsLoading,
        acceptIsLoading,
        acceptDisabled,
        acceptError,
        acceptLabel,
        acceptCallToAction,
        onFirstNameChanged,
        onLastNameChanged,
        onPasswordChanged,
        onUsernameChanged,
        onPasswordIsValidChanged,
        onUsernameIsValidChanged,
        onGoogleClicked,
        onAcceptClicked,
        onEnterPressed,
    } = useAcceptInvitePage({ token });

    if (inviteIsLoading) {
        return (
            <AuthLayout>
                <Loader big active />
            </AuthLayout>
        );
    }

    if (!tokenIsValid) {
        return (
            <AuthLayout>
                <AcceptInviteFailure />
            </AuthLayout>
        );
    }

    const formProps = {
        firstName,
        lastName,
        password,
        username,
        email,
        databaseName,
        inviteeHasAccount,
        firstNameIsValid,
        lastNameIsValid,
        passwordIsValid,
        usernameIsValid,
        acceptIsLoading,
        acceptDisabled,
        acceptError,
        acceptLabel,
        acceptCallToAction,
        onFirstNameChanged,
        onLastNameChanged,
        onPasswordChanged,
        onUsernameChanged,
        onPasswordIsValidChanged,
        onUsernameIsValidChanged,
        onGoogleClicked,
        onAcceptClicked,
        onEnterPressed,
    };

    const linksProps = inviteeHasAccount ? {
        email,
        showForgotLink: true,
    } : {
        showTermsLink: true,
        showPrivacyLink: true,
    };

    return (
        <AuthLayout>
            <AcceptInviteForm {...formProps} />
            <AuthLinks {...linksProps} />
        </AuthLayout>
    );
};

export default AcceptInvitePage;
