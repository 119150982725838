import React, { useCallback } from 'react';
import {
    Layout, DebouncedInput,
} from 'ui-components';

/** @typedef { import('models/sources/__types').Source } Source */

/**
 * @typedef { object } Props
 * @prop { Array<string> } values
 * @prop { string= } spacing
 * @prop { (values: Array<string>) => void } setValues
 */

/** @type { React.FC<Props> } */
const InputList = ({
    values,
    spacing,
    setValues,
}) => {
    const inputValues = values.filter(v => v !== '').concat('');

    const setValueAtIndex = useCallback(
        /** @type { (value: string, index: number) => void } */
        (value, index) => {
            const updatedValues = [
                ...inputValues.slice(0, index),
                value,
                ...inputValues.slice(index + 1),
            ];
            setValues(updatedValues);
        },
        [inputValues, setValues],
    );

    return (
        <Layout spacing={spacing} width="50">
            {inputValues.map((value, index) => (
                // In this case we WANT the behavior that comes from index keys
                <InputListItem
                    key={index} // eslint-disable-line react/no-array-index-key
                    index={index}
                    value={value}
                    setValueAtIndex={setValueAtIndex}
                    spacing={index ? 'mt-2' : ''}
                />
            ))}
        </Layout>
    );
};

/**
 * @typedef { object } InputItemProps
 * @prop { number } index
 * @prop { string } value
 * @prop { (value: string, index: number) => void } setValueAtIndex
 * @prop { string } [spacing]
 */

/** @type { React.FC<InputItemProps> } */
const InputListItem = ({ index, value, setValueAtIndex, spacing }) => {
    const onValueChanged = useCallback((e) => {
        setValueAtIndex(e.target.value, index);
    }, [index, setValueAtIndex]);

    return (
        <Layout>
            <DebouncedInput
                value={value}
                onChange={onValueChanged}
                width="100"
                spacing={`p-0 ${spacing || ''}`}
            />
        </Layout>
    );
};

export default InputList;
