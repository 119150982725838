import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import {
    Layout,
    Dropdown,
    Typography,
    Button,
    List,
    ListItem,
    Icon,
    Loader,
} from 'ui-components';

import { getQueryStatusMessage, isQueryFinished } from '../../models/queries';
import Timer from './timer';

/** @typedef { import('models/queries/__types').QueryResult } QueryResult */

/** @type { (result: QueryResult) => React.ReactNode } */
const getChildQueryIcon = (result) => {
    if (!result.exportingCsv) {
        if (result.status === 'Done') {
            return <Icon icon="check-circle" color="secondary" spacing="mr-3" />;
        }
        if (result.error || result.status === 'Error') {
            return <Icon icon="times-circle" color="error" spacing="mr-3" />;
        }
        if (result.status === 'Canceled' && result.endTime) {
            return <Icon icon="times-circle" color="interface" spacing="mr-3" />;
        }
    }
    return <InlineLoader color="interface" active />;
};

/** @type { (query: string, limit: number) => string } */
const truncateQuery = (query, limit) => {
    if (query.length > limit) {
        return query.substring(0, limit) + '...';
    }
    return query;
};

/**
 * @typedef { object } QuerySelectorDropdownProps
 * @prop { QueryResult[] } queryResults
 * @prop { QueryResult } activeQueryResult
 * @prop { (activeResult: QueryResult) => void } changeActiveQueryResult
 * @prop { Date } [startedAt]
 * @prop { Date} [endedAt]
 * @prop { boolean } [fullWidth]
 */

/** @type { React.FC<QuerySelectorDropdownProps> } */
const QuerySelectorDropdown = ({
    queryResults,
    activeQueryResult,
    changeActiveQueryResult,
    startedAt,
    endedAt,
    fullWidth,
}) => {
    const activeQueryIndex = queryResults.findIndex(r => r.id === activeQueryResult.id);

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(open => !open);
    };

    /** @type { (newQueryResult: QueryResult) => void } */
    const updateActiveQueryResult = (newQueryResult) => {
        changeActiveQueryResult(newQueryResult);
        setDropdownOpen(false);
    };

    return (
        <Dropdown
            opener={
                <QuerySelectorDropdownOpener
                    width={fullWidth ? '100' : ''}
                    spacing="m-0 mr-4"
                    onClick={toggleDropdown}
                >
                    <Layout
                        flex
                        alignItems="center"
                        justifyContent="space-between"
                        width="100"
                    >
                        {fullWidth ? (
                            <Typography color="text" noWrap>
                                <Typography
                                    inline
                                    component="span"
                                    color="secondaryText"
                                    spacing="mr-2"
                                    noWrap
                                >
                                    Result {activeQueryIndex + 1}
                                </Typography>

                                {activeQueryResult.query}
                            </Typography>
                        ) : (
                            <Typography noWrap>
                                {activeQueryResult.query}
                            </Typography>
                        )}

                        <Icon prefix="fas" icon="caret-down" spacing="ml-2" />
                    </Layout>
                </QuerySelectorDropdownOpener>
            }
            open={dropdownOpen}
            onClose={toggleDropdown}
        >
            <Typography
                variant="body2"
                color="secondaryText"
                spacing="mx-4 mt-3 mb-0"
            >
                {queryResults.length} Result{queryResults.length !== 1 ? 's' : ''}
            </Typography>

            <List>
                {queryResults.map(result => (
                    <ListItem
                        key={result.id}
                        button
                        selected={result.id === activeQueryResult.id}
                        icon={getChildQueryIcon(result)}
                        label={truncateQuery(result.query, fullWidth ? 80 : 50)}
                        caption={
                            <>
                                {getQueryStatusMessage(result, false)}

                                <Timer
                                    startedAt={startedAt}
                                    endedAt={endedAt}
                                    queryResult={result}
                                    running={
                                        !isQueryFinished(result)
                                            || !!result.exportingCsv
                                            || (result.status === 'Canceled'
                                                && !result.endTime
                                            )
                                    }
                                />
                            </>
                        }
                        onClick={() => updateActiveQueryResult(result)}
                    />
                ))}
            </List>
        </Dropdown>
    );
};

const QuerySelectorDropdownOpener = styled(Button)`
    &&& {
        ${({ width }) => !width && css`
            max-width: 350px;
        `}
    }
`;

const InlineLoader = styled(Loader)`
    && {
        align-items: flex-start;
        width: 20px;
        margin-right: 0.75rem;
    }
`;

export { InlineLoader };

export default QuerySelectorDropdown;
