import { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'sql-formatter';
import md5 from 'md5';

import { reportError } from '../../lib/error-reporter';
import { useSession, useToast } from '../context-providers';
import { fetchReport } from '../../models/reports';
import { fetchView, fetchTable } from '../../models/database-objects';

/** @typedef { import('app/__types').Database } Database */
/** @typedef { import('models/queries/__types').QueryResult } QueryResult */
/** @typedef { import('./__types').ViewData } ViewData */
/** @typedef { import('./__types').TableData } TableData */
/** @typedef { import('./__types').SchemaData } SchemaData */
/** @typedef { import('./__types').ReportData } ReportData */
/** @typedef { import('./__types').EditorDiagnostic } EditorDiagnostic */
/** @typedef { import('./__types').WorkbenchParams } WorkbenchParams */

/** @typedef { ReturnType<useWorkbenchEditor> } WorkbenchEditorData */

/**
 * @typedef { object } Props
 * @prop { ViewData[] } views
 * @prop { TableData[] } tables
 * @prop { ReportData[] } reports
 * @prop { () => void } fetchViews
 * @prop { () => void } fetchTables
 * @prop { () => void } fetchSchemas
 * @prop { string } builtQuery
 * @prop { string } query
 * @prop { QueryResult[] } queryResults
 * @prop { QueryResult } [activeResult]
 * @prop { (query: string) => Promise<void> } runQuery
 * @prop { (newQuery: string) => void } changeQuery
 * @prop { (newQueryError: string) => void } changeQueryError
 * @prop { (newQueryResults: QueryResult[]) => void } changeQueryResults
 */

/**
 * @param { Props & WorkbenchParams } props
 */
function useWorkbenchEditor({
    id,
    schema,
    type,
    views,
    tables,
    reports,
    fetchViews,
    fetchTables,
    fetchSchemas,
    builtQuery,
    query,
    queryResults,
    activeResult,
    runQuery,
    changeQuery,
    changeQueryError,
    changeQueryResults,
}) {
    const navigate = useNavigate();
    const { openToast } = useToast();

    const [session] = useSession();
    const { database } = session;
    const dbType = database.type;

    const [querySelection, setQuerySelection] = useState('');

    const [diagnostics, setDiagnostics] = useState(
        /** @type { EditorDiagnostic[] } */ ([])
    );

    const [highlight, setHighlight] = useState('');

    const itemIdRef = useRef(/** @type { string= } */ (undefined));

    /** @type { (newQuery: string) => void } */
    const onQueryChanged = useCallback((newQuery) => {
        changeQuery(newQuery);
        setDiagnostics([]);
    }, [changeQuery]);

    /** @type { (newQuery: string) => void } */
    const onQuerySelectionChanged = useCallback((newQuery) => {
        setQuerySelection(newQuery);
    }, []);

    /** @type { (newQuery?: string) => void } */
    const onRunClicked = useCallback((newQuery) => {
        runQuery(newQuery || (querySelection.trim() ? querySelection : query));
    }, [
        querySelection,
        query,
        runQuery,
    ]);

    const onNewClicked = useCallback(() => {
        onQueryChanged('');
        changeQueryError('');
        changeQueryResults([]);
        navigate('/workbench');
    }, [onQueryChanged, changeQueryError, changeQueryResults, navigate]);

    const onFormatClicked = useCallback(() => {
        if (!query) {
            return;
        }

        changeQueryError('');

        try {
            if (querySelection) {
                const formattedQuerySelection = formatQuery(querySelection, dbType);
                onQueryChanged(query.replace(querySelection, formattedQuerySelection));
            } else {
                onQueryChanged(formatQuery(query, dbType));
            }

        } catch (/** @type { any } */ err) {
            openToast({
                message: err?.message || 'Unknown error occurred',
                type: 'error',
                variant: 'outline',
            });
        }
    }, [query, querySelection, dbType, onQueryChanged, openToast, changeQueryError]);

    /** @type { (e: KeyboardEvent) => void } */
    const onKeyPressed = useCallback((e) => {
        if (e.shiftKey && e.key === 'Enter') {
            e.preventDefault();
            if (query.trim().length || querySelection.trim().length) {
                onRunClicked();
            }
        }
    }, [query, querySelection, onRunClicked]);

    useEffect(() => {
        window.addEventListener('keydown', onKeyPressed);
        return () => window.removeEventListener('keydown', onKeyPressed);
    }, [onKeyPressed]);

    useEffect(() => {
        setDiagnostics([]);

        const queryResult = queryResults.find(qr => !!qr.error);

        /**
         * Since both Error and Response objects of runQuery returns 200 ok, the indication of
         * an error could not be !res.ok, However an Error response object will generically
         * have a Code prop which used for an error indication.
         */
        if (!queryResult?.error) {
            return;
        }

        const errorDetails = queryResult.error.details;
        const errorMessage = queryResult.error.message;

        if (errorMessage.startsWith('The query did not complete before')) {
            const timeoutMs = `${Number(errorMessage.match(/\d+/)?.[0]) / 1000} seconds`;
            const message = 'Panoply\'s workbench can only run queries that'
                + ` do not exceed ${timeoutMs} in runtime.`;
            changeQueryError(message);
        } else {
            changeQueryError(errorMessage);
        }

        if (!errorDetails || !errorMessage) {
            return;
        }

        setDiagnostics(getDiagnostics(errorMessage, errorDetails, query));
    }, [queryResults]);

    useEffect(() => {
        const filteredQueryResults = queryResults.filter(qr => !qr.error && (
            ['CREATE', 'DROP'].includes(qr.data.command)
        ));

        const objectTypes = filteredQueryResults.map(qr => qr.data.objectType);

        if (!objectTypes.length) {
            return;
        }

        const uniqueObjectTypes = Array.from(new Set(objectTypes)).sort();

        uniqueObjectTypes.forEach(objectType => {
            switch (objectType) {
                case 'View':
                    fetchViews();
                    break;

                case 'Table':
                    fetchTables();
                    break;

                case 'Schema':
                    fetchSchemas();
                    break;
            }
        });
    }, [queryResults]);

    const runInitialQuery = useCallback(async () => {
        if (!id || !type) {
            return;
        }

        const initialQuery = await getInitialQuery({
            id,
            schema,
            type,
            views,
            tables,
            reports,
            dbType,
        });

        if (!initialQuery.trim()) {
            navigate('/workbench', { replace: true });
            return;
        }

        runNewQuery(initialQuery);
    }, [
        id,
        schema,
        type,
        views,
        tables,
        reports,
        dbType,
        query,
        queryResults,
        onQueryChanged,
        onRunClicked,
    ]);

    /** @type { (newQuery: string) => void } */
    const runNewQuery = useCallback((newQuery) => {
        if (query.trim() === newQuery.trim() && queryResults.length) {
            return;
        }

        onQueryChanged(newQuery);
        onRunClicked(newQuery);
    }, [query, queryResults, onQueryChanged, onRunClicked]);

    useEffect(() => {
        const itemId = id
            ? (schema ? [type, schema, id] : [type, id]).join('-')
            : md5(builtQuery.trim().toLowerCase().replace(/\s/g, '-') || '');

        if (itemIdRef.current === itemId) {
            return;
        }

        itemIdRef.current = itemId;

        if (id) {
            runInitialQuery();
        } else if (builtQuery.trim()) {
            runNewQuery(formatQuery(builtQuery, dbType));
        }
    }, [id, schema, type, builtQuery, dbType, runInitialQuery, runNewQuery]);

    useEffect(() => {
        const newHighlight = queryResults.length > 1
            ? activeResult?.query || ''
            : '';

        setHighlight(newHighlight);
    }, [activeResult, queryResults]);

    return {
        dbType,
        query,
        queryResults,
        onQueryChanged,
        onQuerySelectionChanged,
        onRunClicked,
        diagnostics,
        highlight,
        onNewClicked,
        onFormatClicked,
    };
}

//
// Get the initial query etc.
//

/**
 * @typedef { object } GetInitialQueryParams
 * @prop { NonNullable<WorkbenchParams['id']> } id
 * @prop { WorkbenchParams['schema'] } [schema]
 * @prop { WorkbenchParams['type'] } type
 * @prop { Database['type'] } dbType
 * @prop { ViewData[] } views
 * @prop { TableData[] } tables
 * @prop { ReportData[] } reports
 */

/** @type { (params: GetInitialQueryParams) => Promise<string> } */
const getInitialQuery = async (params) => {
    const {
        id,
        schema,
        type,
        views,
        tables,
        reports,
        dbType,
    } = params;

    switch (type) {
        case 'views': {
            const query = await getInitialViewQuery({
                id,
                schema,
                dbType,
                views,
            });

            return query;
        }
        case 'tables': {
            const query = await getInitialTableQuery({
                id,
                schema,
                dbType,
                tables,
            });

            return query;
        }
        case 'reports': {
            const query = await getInitialReportQuery({
                id,
                reports,
            });

            return query;
        }
    }

    return '';
};

/**
 * @typedef { object } GetInitialViewQueryParams
 * @prop { NonNullable<WorkbenchParams['id']> } id
 * @prop { WorkbenchParams['schema'] } [schema]
 * @prop { Database['type'] } dbType
 * @prop { ViewData[] } views
 */

/** @type { (params: GetInitialViewQueryParams) => Promise<string> } */
const getInitialViewQuery = async (params) => {
    const { id, schema, dbType, views } = params;

    if (schema) {
        return buildQuery(id, schema, dbType);
    }

    const view = await findView(id, views, dbType);

    return view?.definition || '';
};

/**
 * @typedef { object } GetInitialTableQueryParams
 * @prop { NonNullable<WorkbenchParams['id']> } id
 * @prop { WorkbenchParams['schema'] } [schema]
 * @prop { Database['type'] } dbType
 * @prop { TableData[] } tables
 */

/** @type { (params: GetInitialTableQueryParams) => Promise<string> } */
const getInitialTableQuery = async (params) => {
    const { id, schema, dbType, tables } = params;

    if (schema) {
        return buildQuery(id, schema, dbType);
    }

    const table = await findTable(id, tables);

    if (table) {
        const name = table.current_name || table.name;
        const schema = table.schemaname || table.schema;

        if (name && schema) {
            return buildQuery(name, schema, dbType);
        }
    }

    return '';
};

/**
 * @typedef { object } GetInitialReportQueryParams
 * @prop { NonNullable<WorkbenchParams['id']> } id
 * @prop { ReportData[] } reports
 */

/** @type { (params: GetInitialReportQueryParams) => Promise<string> } */
const getInitialReportQuery = async (params) => {
    const { id, reports } = params;

    const report = await findReport(id, reports);

    return report?.query.sql || '';
};

/** @type { (name: string, schema: string, dbType: Database['type']) => string } */
const buildQuery = (name, schema, dbType) => {
    const query = dbType === 'bigquery'
        ? `SELECT * FROM \`${schema}.${name}\`;`
        : `SELECT * FROM "${schema}"."${name}";`;

    return query;
};

/** @type { (query: string, dbType: Database['type']) => string } */
const formatQuery = (query, dbType) => {
    const formattedQuery = format(query, {
        language: dbType,
        tabWidth: 4,
        keywordCase: 'upper',
    });

    return formattedQuery;
};

/** @type { (id: string, views: ViewData[], dbType: Database['type']) => Promise<ViewData> } */
const findView = async (id, views, dbType) => {
    const view = views.find(t => t.id === id);

    if (view) {
        return view;
    }

    try {
        const view = await fetchView(id);

        return view;

    } catch (/** @type { any } */ err) {
        reportError(err);

        const [database, schema, ...otherParts] = id.split('-');
        const name = otherParts.join('-');

        const definition = buildQuery(schema, name, dbType);

        const table = {
            id,
            database,
            schema,
            name,
            definition,
        };

        return table;
    }
};

/** @type { (id: string, tables: TableData[]) => Promise<TableData> } */
const findTable = async (id, tables) => {
    const table = tables.find(t => t.id === id);

    if (table) {
        return table;
    }

    try {
        const table = await fetchTable(id);

        return table;

    } catch (/** @type { any } */ err) {
        reportError(err);

        const [database, schema, ...otherParts] = id.split('-');
        const name = otherParts.join('-');

        const table = {
            id,
            database,
            schema,
            name,
        };

        return table;
    }
};

/** @type { (id: string, reports: ReportData[]) => Promise<ReportData | undefined> } */
const findReport = async (id, reports) => {
    const report = reports.find(t => t.id === id);

    if (report) {
        return report;
    }

    try {
        const report = await fetchReport(id);

        // While all the reports are still loading, and the reports array
        // is replaced, we need this report to be added until the, so it
        // can be used by the visualization toggle to auto-select the type.
        reports.push(report);

        return report;

    } catch (/** @type { any } */ err) {
        reportError(err);

        return undefined;
    }
};

//
//  BigQuery Error Highlighting
//

/** @type { (errorLine: string, position: number) => string } */
export const getErrorWord = (errorLine, position) => {
    return errorLine.substring(position - 1, errorLine.length).split(' ')[0];
};

/** @type { (errorLine: string, errorWord: string) => number } */
export const getPreErrorTextLength = (errorLine, errorWord) => {
    const preErrorWord = errorLine.split(errorWord)[0] || '';
    return preErrorWord.length + 1;
};

/**
 * @param { number } line
 * @param { string[] } queryLines
 * @param { number } preErrorTextLength
 * @param { number } position
 * @returns { number }
 */
export const getErrorStartIndex = (line, queryLines, preErrorTextLength, position) => {
    return line - 1 > 0
        ? queryLines.slice(0, line - 1).join(',').length + preErrorTextLength
        : position;
};

/**
 * @typedef { object } QueryErrorDetails
 * @prop { number } line
 * @prop { number } position
 */

/**
 * @typedef { object } Diagnostics
 * @prop { number } from
 * @prop { number } to
 * @prop { string } severity
 * @prop { string } message
 */

/**
 * @param { string } queryError
 * @param { QueryErrorDetails } queryErrorDetails
 * @param { string } query
 * @returns {  EditorDiagnostic[] }
 */
export const getDiagnostics = (queryError, queryErrorDetails, query) => {
    const { line, position } = queryErrorDetails;

    try {
        const queryLines = query.split('\n');
        const errorLine = queryLines[line - 1];

        // TODO: Find out why the errorLine is undefined
        if (!errorLine) {
            throw new Error('Error line is not found');
        }

        const errorWord = getErrorWord(errorLine, position);
        const preErrorTextLength = getPreErrorTextLength(errorLine, errorWord);
        const errorWordStart = getErrorStartIndex(line, queryLines, preErrorTextLength, position);
        const from = errorWordStart === position ? errorWordStart - 1 : errorWordStart;
        const to = from + errorWord.length;

        return [{
            from,
            to,
            severity: 'error',
            message: queryError,
        }];

    } catch (/** @type { any } */ err) {
        reportError(err, { line, position });
        return [];
    }
};

export default useWorkbenchEditor;
