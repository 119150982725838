import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSession } from '../context-providers';

import {
    Layout,
    Typography,
    ExpansionPanelHeader,
} from 'ui-components';

import { useRequest } from '../../lib/ajax';
import JobCard from './job-card';
import { JobCancelDialog, useJobCancelDialog } from './job-cancel-dialog';

/** @typedef { import('../../models/jobs/__types').Job } Job */
/** @typedef { import('../../models/sources/__types').Source } Source */
/** @typedef { import('../../models/sources/__types').SourceType } SourceType */

/**
 * @typedef { object } Props
 *
 * @prop { Job[] } jobs
 * @prop { Source[] } [sources]
 * @prop { SourceType[] } [sourceTypes]
 * @prop { boolean } [flat]
 */

/** @type { React.FC<Props> } */
const JobCardList = ({
    jobs = [],
    sources,
    sourceTypes,
    flat = false,
}) => {
    const [currentJobs, setCurrentJobs] = useState(jobs);
    useEffect(() => setCurrentJobs(jobs), [jobs]);

    const {
        go: getJobs,
    } = useRequest('/jobs', 'GET', null, {
        id: currentJobs.map(j => j.id),
        $sort: '-id',
    });

    const fetchJobs = useCallback(() => {
        getJobs().then((res) => {
            if (!res.ok) {
                return;
            }
            setCurrentJobs(res.data);
        });
    }, [getJobs]);

    const [jobToExport, setJobToExport] = useState(/** @type { Job } */ ({}));

    const {
        go: exportJob,
        clear: clearExportJob,
    } = useRequest(`/workbench/export/${jobToExport.id}`);

    const onExportJob = useCallback((job) => {
        setJobToExport(job);
    }, []);

    useEffect(() => {
        if (!jobToExport.id) {
            return;
        }
        exportJob().then((res) => {
            if (!res.ok) {
                return;
            }
            setJobToExport(/** @type { Job } */ ({}));
            clearExportJob();
            window.location.assign(res.data);
        });
    }, [jobToExport, exportJob, clearExportJob]);


    /** @param {Job} job */
    const getJobSourceType = (job) => {
        if (!sourceTypes) {
            return null;
        }
        const matchingType = sourceTypes.find(type => job?.data?.type === type.id);
        return (matchingType && matchingType.title) || job?.data?.type;
    };

    /** @param {Job} job */
    const getJobSourceTitle = (job) => {
        if (!sources) {
            return null;
        }
        const matchingSource = sources?.find(source => source.id === job.source);
        return (matchingSource && matchingSource.title) || job.title;
    };

    const {
        jobToCancel,
        onCancelJob,
        onCloseCancelDialog,
        onCancelJobConfirm,
        loading,
        error,
    } = useJobCancelDialog(fetchJobs);

    const [session] = useSession();
    const { database } = session;

    const isBQ = database.type === 'bigquery';

    return (
        <>
            <JobCancelDialog
                jobToCancel={jobToCancel}
                onCloseCancelDialog={onCloseCancelDialog}
                onCancelJobConfirm={onCancelJobConfirm}
                loading={loading}
                error={error}
            />

            <ExpansionPanelHeader flat={flat}>
                <Layout width="100" flex alignItems="center" justifyContent="space-between">
                    <Layout width="30" spacing="pr-3">
                        <Typography color="secondaryText" noWrap>
                            Name
                        </Typography>
                    </Layout>

                    <Layout width="20" spacing="pr-3">
                        <Typography color="secondaryText" noWrap>
                            Created
                        </Typography>
                    </Layout>

                    <Layout width="15" spacing="">
                        <Typography color="secondaryText" noWrap>
                            Status
                        </Typography>
                    </Layout>

                    <Layout width="15" spacing="">
                        <Typography color="secondaryText" noWrap>
                            Rows Extracted
                        </Typography>
                    </Layout>

                    {isBQ && (
                        <Layout width="15" spacing="">
                            <Typography color="secondaryText" noWrap>
                                Query Bytes Used
                            </Typography>
                        </Layout>
                    )}

                    {/* Spacer for cancel button even though it doesn't get a label */}
                    <CancelColumn />
                </Layout>
            </ExpansionPanelHeader>

            {currentJobs.map(job => (
                <JobCard
                    key={job.id}
                    job={job}
                    onCancelJob={onCancelJob}
                    onExportJob={onExportJob}
                    getJobSourceType={getJobSourceType}
                    getJobSourceTitle={getJobSourceTitle}
                    flat={flat}
                />
            ))}
        </>
    );
};

const CancelColumn = styled(Layout)`
  min-width: 64px;
`;

export default JobCardList;
