import React from 'react';

import {
    Anchor,
    Layout,
    Typography,
} from 'ui-components';

import SourceForm from '../form';
import CollectConfig from '../collect-config';

/** @typedef { import('../__types').SourceSettingsProps } SourceSettingsProps */
/** @typedef { import('models/sources/__types').CollectConfig } CollectConfig */

/** @type { React.FC<SourceSettingsProps> } */
const SourceAdvanced = ({
    source,
    sourceType,
    flattenedParams,
    getParamValue,
    setParamValue,
    getParamState,
    setParamState,
    isParamRequired,
    areParamDepsMet,
    loadDynamicParam,
    loadValidationParam,
    fieldValidationErrors,
    loadSchemas,
    updateAdvanced,
}) => {

    const sourceFormProps = {
        source,
        sourceType,
        flattenedParams,
        getParamState,
        setParamState,
        getParamValue,
        setParamValue,
        isParamRequired,
        areParamDepsMet,
        loadDynamicParam,
        loadValidationParam,
        fieldValidationErrors,
        loadSchemas,
    };

    /** @type { (cc: Partial<CollectConfig>, replace?: boolean) => void } */
    const setCollectConfig = (collectConfig, replace = false) => {
        updateAdvanced({ collectConfig }, replace);
    };

    return (
        <Layout width={100}>
            <Typography variant="body1" color="secondaryText" spacing="mt-4">
                Advanced Settings are optional. Learn more&nbsp;
                <Anchor
                    target="_blank"
                    href="https://panoply.io/docs/advanced-settings"
                    color="secondaryText"
                    underline
                >
                    here
                </Anchor>.
            </Typography>

            <Layout spacing="py-5">
                <SourceForm
                    {...sourceFormProps}
                    category="advanced"
                />

                {source.collectConfig && (
                    <CollectConfig
                        source={source}
                        collectConfig={source.collectConfig}
                        setCollectConfig={setCollectConfig}
                    />
                )}
            </Layout>
        </Layout>
    );
};

export default SourceAdvanced;
