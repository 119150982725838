import React, { useState, useCallback, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { isPlainObject } from 'lodash';
import moment from 'moment';

import {
    Dialog,
    Divider,
    Button,
    Typography,
    Layout,
    Icon,
    Tooltip,
    Autocomplete,
    ToggleButton,
    ToggleButtonGroup,
    List,
    ListItem,
    Dropdown,
    DatePicker,
    spacings,
} from 'ui-components';

import {
    isNumericColumn,
    isStringColumn,
    isDateColumn,
    isTimeColumn,
    isDatetimeColumn,
    isBooleanColumn,
} from '../../models/columns';

import useDraggable, { onDragStart, onDragOver } from './use-draggable';

/** @typedef { import('moment').Moment } Moment */
/** @typedef { import('lib/query-builder').Column } Column */
/** @typedef { import('lib/query-builder').ColumnValue } ColumnValue */
/** @typedef { import('lib/query-builder').FilterColumn } FilterColumn */
/** @typedef { import('lib/query-builder').Filter } Filter */
/** @typedef { import('lib/query-builder').Logic } Logic */
/** @typedef { import('lib/query-builder').Value } Value */
/** @typedef { import('lib/query-builder').Operator } Operator */
/** @typedef { 'date' | 'time' | 'datetime' } DateType */
/** @typedef { 'text' | 'number' | 'boolean' } TextType */
/** @typedef { TextType | DateType } ValueType */
/** @typedef { { column?: Column, data?: Partial<Filter> } } Item */
/** @typedef { Option | string } OptionType */
/** @typedef { OptionType | OptionType[] | null } NewValue */
/** @typedef { 'error' | 'success' | '' } Status */
/** @typedef { 'where' | 'having' } Mode */
/** @typedef { number } Position */

/**
 * @typedef { object } Option
 * @prop { string } title
 * @prop { string } [subtitle]
 * @prop { string } [category]
 * @prop { Column } [column]
 * @prop { boolean } [disabled]
 * @prop { boolean } [error]
 */

/**
 * @typedef { import('lib/query-builder/types').Value<string, Option> } OptionValue
 */

/**
 * @typedef { React.Dispatch<React.SetStateAction<T>> } SetItems
 * @template [T=Item[]]
 */

/**
 * @typedef { import('app/__types').ChangeEvent<T> } ChangeEvent
 * @template [T=string]
 */

/**
 * @typedef { object } ModeProps
 * @prop { string } label
 * @prop { string } help
 * @prop { (allColumn: Column[]) => boolean } isDisabled
 */

/** @type { Record<Mode, ModeProps> } */
const modes = {
    where: {
        label: 'Before functions are applied',
        help: 'Filter rows based on column values',
        isDisabled: () => false,
    },
    having: {
        label: 'After functions are applied',
        help: 'Only fields with functions are valid',
        isDisabled: allColumns => !allColumns.some(c => c.function),
    },
};

/** @type { Operator } */
const defaultOperator = '=';

/**
 * @typedef { object } OperatorConfig
 * @prop { string } label
 * @prop { string } [icon]
 * @prop { ValueType[] } types
 * @prop { boolean } [disabled]
 */

/** @typedef { Record<Operator, OperatorConfig> } Operators */

/** @type { Operators } */
const allOperators = {
    '=': {
        label: 'Equals',
        icon: 'equals',
        types: ['text', 'number', 'date', 'time', 'datetime', 'boolean'],
    },
    '!=': {
        label: 'Not equals',
        icon: 'not-equal',
        types: ['text', 'number', 'date', 'time', 'datetime', 'boolean'],
    },
    '>': {
        label: 'Greater than',
        icon: 'greater-than',
        types: ['text', 'number', 'date', 'time', 'datetime'],
    },
    '>=': {
        label: 'Greater than or equal',
        icon: 'greater-than-equal',
        types: ['text', 'number', 'date', 'time', 'datetime'],
    },
    '<': {
        label: 'Less than',
        icon: 'less-than',
        types: ['text', 'number', 'date', 'time', 'datetime'],
    },
    '<=': {
        label: 'Less than or equal',
        icon: 'less-than-equal',
        types: ['text', 'number', 'date', 'time', 'datetime'],
    },
    'in': {
        label: 'In',
        types: ['text', 'number'],
    },
    'not in': {
        label: 'Not in',
        types: ['text', 'number'],
    },
    'between': {
        label: 'Between',
        types: ['text', 'number', 'date', 'time', 'datetime'],
        disabled: true,
    },
    'not between': {
        label: 'Not between',
        types: ['text', 'number', 'date', 'time', 'datetime'],
        disabled: true,
    },
    'is true': {
        label: 'Is true',
        types: ['boolean'],
    },
    'is not true': {
        label: 'Is not true',
        types: ['boolean'],
    },
    'is false': {
        label: 'Is false',
        types: ['boolean'],
    },
    'is not false': {
        label: 'Is not false',
        types: ['boolean'],
    },
    'like': {
        label: 'Contains',
        types: ['text'],
    },
    'not like': {
        label: 'Not contains',
        types: ['text'],
    },
    'is null': {
        label: 'Is null',
        types: ['text', 'number', 'date', 'time', 'datetime', 'boolean'],
    },
    'is not null': {
        label: 'Is not null',
        types: ['text', 'number', 'date', 'time', 'datetime', 'boolean'],
    },
};

/**
 * @param { Column[] } allColumns
 * @param { FilterColumn } column
 * @param { { checkFunction?: boolean } } [options]
 * @returns { Column | undefined }
 */
const findColumn = (allColumns, column, options) => {
    return allColumns.find(c => isSameColumn(c, column, options));
};

/**
 * @param { FilterColumn } column1
 * @param { FilterColumn } [column2]
 * @param { { checkFunction?: boolean } } [options]
 * @returns { boolean }
 */
const isSameColumn = (column1, column2, options) => {
    if (!column2 || options?.checkFunction && !column2.function) {
        return false;
    }

    return column1.name === column2.name
        && column1.table === column2.table
        && column1.schema === column2.schema
        && (!options?.checkFunction
            || column1.function === column2.function
        );
};

/** @type { (column: Column, items: Item[]) => boolean } */
const hasColumn = (column, items) => (
    items.some(item => isSameColumn(column, item.column))
);

/**
 * @param { Filter[] } filters
 * @param { Column[] } allColumns
 * @param { Column } [column]
 * @returns { Item[] }
 */
const normalizeFilters = (filters, allColumns, column) => {
    const items = filters.map(data => {
        const column = findColumn(allColumns, data.column);
        return /** @type { Item } */ ({ column, data });
    }).filter(item => item.column);

    if (column?.id && !hasColumn(column, items)) {
        const { name, table, schema, function: f } = column;

        items.push({
            column,
            data: {
                column: {
                    name,
                    table,
                    schema,
                    function: f,
                },
                operator: defaultOperator,
            },
        });
    }

    return items.length ? items : [{}];
};

/**
 * @param { NonNullable<Column['function']> } f
 * @param { Column['name'] } name
 * @returns { string }
 */
const resolveFunction = (f, name) => {
    if (f.endsWith(')')) {
        return f.toUpperCase().slice(0, -1) + ` ${name})`;
    }
    return `${f.toUpperCase()}(${name})`;
};

/** @type { (item: Item) => ValueType } */
const resolveValueType = (item) => {
    const { column, data = {} } = item;

    if (column?.id) {
        if (data.column?.function?.startsWith('count')) {
            return 'number';
        }

        switch (true) {
            case isStringColumn(column.dataType):
                return 'text';

            case isNumericColumn(column.dataType):
                return 'number';

            case isDateColumn(column.dataType):
                return 'date';

            case isTimeColumn(column.dataType):
                return 'time';

            case isDatetimeColumn(column.dataType):
                return 'datetime';

            case isBooleanColumn(column.dataType):
                return 'boolean';
        }
    }

    return 'text';
};

/**
 * @param { OptionValue | null } value
 * @param { Operator } operator
 * @param { ValueType } type
 * @returns { Value | undefined }
 */
const resolveFilterValue = (value, operator, type) => {
    if (value == null || operator.startsWith('is')) {
        return undefined;
    }

    if (Array.isArray(value)) {
        if (!value.length) {
            return undefined;
        }

        const v = value.map(v => resolveValue(v, type));

        return operator.endsWith('in')
            ? Array.from(new Set(v))
            : v[0];
    }

    if (typeof value === 'object' && 'min' in value) {
        return {
            min: resolveValue(value.min, type),
            max: resolveValue(value.max, type),
        };
    }

    const v = resolveValue(value, type);

    return operator.endsWith('in')
        ? v ? [v] : []
        : v;
};

/** @type { (value: Option | string, type: ValueType) => ColumnValue | string | number } */
const resolveValue = (value, type) => {
    if (typeof value === 'object') {
        if (value.column?.id) {
            const { name, table, schema } = value.column;

            return {
                name,
                schema,
                table,
            };
        }

        return resolveValue(value.title, type);
    }

    if (type === 'number' && isNumeric(value)) {
        return Number(value || 0);
    }

    return String(value || '');
};

/**
 * @param { Item } item
 * @param { Operator } operator
 * @param { ValueType } type
 * @param { Status } status
 * @param { Option[] } options
 * @returns { OptionValue }
 */
const resolveOptionValue = (item, operator, type, status, options) => {
    const value = item.data?.value;

    if (value == null || operator.startsWith('is')) {
        return operator.endsWith('in') ? [] : '';
    }

    if (Array.isArray(value)) {
        const v = value.map(v => resolveOption(v, type, status, options));

        return operator.endsWith('in')
            ? v
            : v[0] || '';
    }

    if (typeof value === 'object' && 'min' in value) {
        return {
            min: resolveOption(value.min, type, status, options),
            max: resolveOption(value.max, type, status, options),
        };
    }

    const v = resolveOption(value, type, status, options);

    return operator.endsWith('in')
        ? v ? [v] : []
        : v;
};

/**
 *
 * @param { Value } value
 * @param { ValueType } type
 * @param { Status } status
 * @param { Option[] } options
 * @returns { Option | string }
 */
const resolveOption = (value, type, status, options) => {
    if (typeof value === 'object' && 'name' in value) {
        const columns = /** @type { Column[] } */ (
            options.filter(o => o.column?.id).map(o => o.column)
        );

        const column = findColumn(columns, value);

        if (column?.id) {
            return {
                title: column.name,
                subtitle: [
                    column.schema,
                    column.table,
                ].join('.'),
                category: 'Fields',
                column,
            };
        }
    }

    return createOption(value, type, status);
};

/**
 *
 * @param { Value } value
 * @param { ValueType } type
 * @param { Status } status
 * @returns { Option | string }
 */
const createOption = (value, type, status) => {
    if (['date', 'time', 'datetime'].includes(type)) {
        return String(value);
    }

    if (type === 'number') {
        return {
            title: String(value),
            subtitle: 'number',
            error: status === 'error' && !isNumeric(value),
        };
    }

    return {
        title: String(value || ''),
        subtitle: type,
    };
};

/**
 * Determine the index of the first open parenthesis
 *
 * @type { (items: Item[], index: number) => number }
 */
const findOpen = (items, index) => {
    let j = 0;

    for (let i = items.length - 1; i > index; i--) {
        const { open = 0, close = 0 } = items[i].data || {};
        j -= close;
        j += open;
    }

    for (let i = index; i >= 0; i--) {
        if (!items.length || (i === 0 && index === items.length - 1)) {
            break;
        }

        const { open = 0, close = 0 } = items[i].data || {};
        j -= close;
        j += open;

        if (j > 0) {
            return i;
        }
    }

    return index !== items.length - 1 ? 0 : 1;
};

/**
 * Determine the index of the first close parenthesis
 *
 * @type { (items: Item[], index: number) => number }
 */
const findClose = (items, index) => {
    let j = 0;

    for (let i = 0; i < index; i++) {
        const { open = 0, close = 0 } = items[i].data || {};
        j -= open;
        j += close;
    }

    for (let i = index; i < items.length; i++) {
        if (i === items.length - 1 && index === 0) {
            break;
        }

        const { open = 0, close = 0 } = items[i].data || {};
        j -= open;
        j += close;

        if (j > 0) {
            return i;
        }
    }

    return index !== 0 ? items.length - 1 : items.length - 2;
};

/**
 * Count the number of open parenthesis
 *
 * @type { (items: Item[]) => number }
 */
const countOpen = items => items.reduce((acc, item) => {
    const count = item.data?.open || 0;
    return acc + count;
}, 0);

/**
 * Count the number of close parenthesis
 *
 * @type { (items: Item[]) => number }
 */
const countClose = items => items.reduce((acc, item) => {
    const count = item.data?.close || 0;
    return acc + count;
}, 0);

/**
 * Calculate the nesting level of the current item
 *
 * @type { (items: Item[], index: number) => number }
 */
const calculateNesting = (items, index) => {
    let nesting = 0;

    for (let i = 0; i < index; i++) {
        const { open = 0, close = 0 } = items[i].data || {};
        nesting += open;
        nesting -= close;
    }

    return nesting > -1 ? nesting : 0;
};

/** @type { (items: Item[]) => Item[] } */
const filterItems = (items) => (
    items.filter(item => Object.keys(item).length)
);

/** @type { (items: Item[]) => boolean } */
const validateItems = items => (
    items.every(validateValue) && validateParens(items)
);

/** @type { (item: Item) => boolean } */
const validateValue = item => {
    const { column, data } = item;

    if (!column?.id || !data?.column || !data?.operator) {
        return false;
    }

    if (data.operator.startsWith('is')) {
        return true;
    }

    if (data.value == null) {
        return false;
    }

    const type = resolveValueType(item);

    if (type === 'number') {
        /** @type { (v?: Value) => boolean } */
        const isValid = v => ['object', 'number'].includes(typeof v);

        if (Array.isArray(data.value)) {
            return data.value.every(isValid);
        }

        return isValid(data.value);
    }

    return true;
};

/** @type { (items: Item[]) => boolean } */
const validateParens = items => {
    const openTotal = countOpen(items);
    const closeTotal = countClose(items);

    if (openTotal !== closeTotal) {
        return false;
    }

    for (let i = 0; i < items.length; i++) {
        let j = 0;

        for (let k = i; k < items.length; k++) {
            const { open = 0, close = 0 } = items[k].data || {};

            j -= close;
            j += open;
        }

        if (j > 0) {
            return false;
        }
    }

    return true;
};

/** @type { (n: any) => boolean } */
const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

/** @type { (type: ValueType) => type is DateType } */
const isDateType = type => ['date', 'time', 'datetime'].includes(type);

/** @type { (type: ValueType) => type is TextType } */
const isTextType = type => ['text', 'number', 'boolean'].includes(type);

/** @type { (v: NewValue) => v is Option } */
const isOptionObject = v => isPlainObject(v);

/**
 * @typedef { object } Props
 * @prop { boolean } [open]
 * @prop { Column } [column]
 * @prop { Column[] } allColumns
 * @prop { { where: Filter[], having: Filter[] } } queryFilter
 * @prop { (where: Filter[], having: Filter[]) => void } changeFilter
 * @prop { () => void } onClose
 */

/** @type { React.FC<Props> } */
const FilterDialog = ({
    open,
    column,
    allColumns,
    queryFilter,
    changeFilter,
    onClose,
}) => {
    const [data, setData] = useState(() => {
        const having = normalizeFilters(queryFilter.having, allColumns);

        const where = normalizeFilters(
            queryFilter.where,
            allColumns,
            column?.id && !hasColumn(column, having)
                ? column
                : undefined
        );

        return {
            where,
            having,
        };
    });

    const [mode, setMode] = useState(/** @type { () => Mode } */ (() => {
        if ((column?.id
            && !hasColumn(column, data.where)
            && hasColumn(column, data.having)
        ) || (!filterItems(data.where).length
            && filterItems(data.having).length
            && !modes.having.isDisabled(allColumns)
        )) {
            return 'having';
        }

        return 'where';
    }));

    const [status, setStatus] = useState(
        /** @type { Status } */ ('')
    );

    /** @type { (e: ChangeEvent<Mode>) => void } */
    const toggleMode = e => {
        setMode(e.target.value);
    };

    /** @type { SetItems } */
    const setItems = newItems => {
        setData(data => ({
            ...data,
            [mode]: typeof newItems === 'function'
                ? newItems(data[mode])
                : newItems,
        }));
    };

    const onSave = useCallback(() => {
        setStatus('');

        const filteredData = Object.entries(data).reduce((acc, [key, items]) => {
            acc[/** @type { Mode } */ (key)] = key !== mode
                ? filterItems(items)
                : items;

            return acc;
        }, /** @type { typeof data } */ ({}));

        const keys = /** @type { Mode[] } */ (
            ['where', 'having'].sort(a => (a === mode ? -1 : 1))
        );

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];

            if (!validateItems(filteredData[key])) {
                if (mode !== key) {
                    setMode(key);
                }
                setStatus('error');
                return;
            }
        }

        setStatus('success');

        const { where, having } = filteredData;

        const newWhere = /** @type { Filter[] } */ (where.map(item => item.data));
        const newHaving = /** @type { Filter[] } */ (having.map(item => item.data));

        changeFilter(newWhere, newHaving);

        onClose();
    }, [mode, data, changeFilter, onClose]);

    useEffect(() => {
        if (!open && !column?.id) {
            return () => {};
        }

        /** @type { (e: KeyboardEvent) => void } */
        const onKeyDown = e => {
            const activeTag = document.activeElement?.tagName.toLowerCase();

            if (e.key === 'Escape') {
                e.preventDefault();
                onClose();
            } else if (e.key === 'Enter' && activeTag !== 'input') {
                e.preventDefault();
                onSave();
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    }, [open, column, onClose, onSave]);

    const actions = [
        <Button
            key="cancel"
            type="plain"
            spacing="ml-0"
            onClick={onClose}
        >
            Cancel
        </Button>,
        <Button
            key="save"
            color="primary"
            spacing="mr-0"
            onClick={onSave}
        >
            Filter
        </Button>,
    ];

    switch (status) {
        case 'error':
            actions.unshift(<Error key="error" />);
            break;

        case 'success':
            actions.unshift(<Success key="success" />);
            break;
    }

    return (
        <Dialog
            title="Filter"
            isOpen={open || !!column?.id}
            onClose={onClose}
            actions={actions}
        >
            <Wrapper>
                <Divider spacing="-mx-4 mt-0 mb-4" />

                <ToggleMode
                    mode={mode}
                    allColumns={allColumns}
                    toggleMode={toggleMode}
                />

                <Divider spacing="-mx-4 mt-4 mb-5" />

                <Filter
                    key={mode}
                    mode={mode}
                    allColumns={allColumns}
                    status={status}
                    items={data[mode]}
                    setStatus={setStatus}
                    setItems={setItems}
                />
            </Wrapper>
        </Dialog>
    );
};

/**
 * @typedef { object } ToggleModeProps
 * @prop { Mode } mode
 * @prop { Column[] } allColumns
 * @prop { (e: ChangeEvent<Mode>) => void } toggleMode
 */

/** @type { React.FC<ToggleModeProps> } */
const ToggleMode = ({ mode, allColumns, toggleMode }) => (
    <FlexLayout justifyContent="center" flexDirection="column">
        <ToggleButtonGroup flex value={mode} onChange={toggleMode}>
            {Object.entries(modes).map(([key, { label, isDisabled }]) => (
                <ToggleButton
                    key={key}
                    type="solid"
                    value={key}
                    disabled={isDisabled(allColumns)}
                >
                    {label}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>

        <Help spacing="m-0 mt-1">
            {modes[mode].help}
        </Help>
    </FlexLayout>
);

/**
 * @typedef { object } FilterProps
 * @prop { Mode } mode
 * @prop { Column[] } allColumns
 * @prop { Status } status
 * @prop { Item[] } items
 * @prop { (status: Status) => void } setStatus
 * @prop { SetItems } setItems
 */

/** @type { React.FC<FilterProps> } */
const Filter = ({ mode, allColumns, status, items, setStatus, setItems }) => {
    const {
        getClassName,
        onDragEnter,
        onDragLeave,
        onDrop,
    } = useDraggable({ setItems });

    const options = useMemo(() => {
        const options = allColumns.reduce((acc, c) => {
            if (mode === 'having' && !c.function) {
                return acc;
            }

            const title = mode === 'having' && c.function
                ? resolveFunction(c.function, c.name)
                : c.name;

            const key = [c.schema, c.table, title].join('-');

            return {
                ...acc,
                [key]: {
                    title,
                    subtitle: [c.schema, c.table].join('.'),
                    category: 'Fields',
                    column: c,
                },
            };
        }, /** @type { Record<string, Option> } */ ({}));

        return Object.values(options);
    }, [mode, allColumns]);

    const allOptions = useMemo(() => {
        const options = allColumns.reduce((acc, c) => {
            const key = [c.schema, c.table, c.name].join('-');

            return {
                ...acc,
                [key]: {
                    title: c.name,
                    subtitle: [c.schema, c.table].join('.'),
                    category: 'Fields',
                    column: c,
                },
            };
        }, /** @type { Record<string, Option> } */ ({}));

        return Object.values(options);
    }, [allColumns]);

    /** @type { (index: number, item: Item) => void } */
    const updateField = (index, item) => {
        const { column, data } = item;

        setItems(items => {
            const newItems = [...items];
            newItems[index] = { column, data };
            return newItems;
        });

        setStatus('');
    };

    /** @type { (index: number) => void } */
    const addField = (index) => {
        setItems(items => {
            const newItems = [...items];
            newItems.splice(index, 0, {});
            return newItems;
        });
        setStatus('');
    };

    /** @type { (index: number) => void } */
    const removeField = (index) => {
        setItems(items => {
            const newItems = [...items];
            newItems.splice(index, 1);
            return newItems;
        });
        setStatus('');
    };

    const bodyRowProps = {
        options,
        allOptions,
        items,
        status,
        mode,
        draggable: items.length > 1,
        updateField,
        addField,
        removeField,
        onDragEnter,
        onDragLeave,
        onDrop,
    };

    return (
        <>
            <HeaderRow />

            {(items.length ? items : [{}]).map((item, index) => (
                <BodyRow
                    key={`item-${index}_${item.column?.id || 'empty'}`}
                    className={getClassName(index)}
                    item={item}
                    index={index}
                    {...bodyRowProps}
                />
            ))}
        </>
    );
};

/** @type { React.FC } */
const HeaderRow = () => (
    <FlexLayout>
        <FlexLayout spacing="mr-4">
            <Dragger color="white" />
        </FlexLayout>

        <FlexLayout width="47">
            <Label>
                Field
            </Label>
        </FlexLayout>

        <FlexLayout grow="1">
            <Label>
                Value
            </Label>
        </FlexLayout>
    </FlexLayout>
);

/**
 * @typedef { object } BodyRowProps
 * @prop { string } className
 * @prop { Mode } mode
 * @prop { Item } item
 * @prop { number } index
 * @prop { Item[] } items
 * @prop { Option[] } options
 * @prop { Option[] } allOptions
 * @prop { Status } status
 * @prop { boolean } draggable
 * @prop { (index: number, item: Item) => void } updateField
 * @prop { (index: number) => void } addField
 * @prop { (index: number) => void } removeField
 * @prop { (e: React.DragEvent<HTMLDivElement>, index: number) => void } onDragEnter
 * @prop { (e: React.DragEvent<HTMLDivElement>, index: number) => void } onDragLeave
 * @prop { (e: React.DragEvent<HTMLDivElement>, to: Position) => void } onDrop
 */

/** @type { React.FC<BodyRowProps> } */
const BodyRow = ({
    className,
    mode,
    item,
    index,
    options,
    allOptions,
    items,
    status,
    draggable,
    updateField,
    addField,
    removeField,
    onDragEnter,
    onDragLeave,
    onDrop,
}) => {
    const nesting = useMemo(() => {
        return calculateNesting(items, index);
    }, [items, index]);

    const operators = useMemo(() => {
        const valueType = resolveValueType(item);

        return Object.entries(allOperators).reduce((acc, [key, value]) => {
            if (value.types.includes(valueType)) {
                return { ...acc, [key]: value };
            }

            return acc;
        }, /** @type { Operators } */ ({}));
    }, [item]);

    /** @type { (operator: Operator) => void } */
    const updateOperator = (operator) => {
        updateField(index, { ...item, data: { ...item.data, operator } });
    };

    useEffect(() => {
        if (!Object.keys(item).length) {
            return;
        }

        const operator = item.data?.operator;

        if (!operator || !operators[operator] || operators[operator]?.disabled) {
            const filteredOperators = (/** @type { Operator[] } */ (Object.keys(operators)))
                .filter(key => !operators[key]?.disabled);

            const newOperator = filteredOperators[0] || defaultOperator;

            updateOperator(newOperator);
        }
    }, [operators]);

    const operator = item.data?.operator || defaultOperator;

    return (
        <Draggable
            className={className}
            {...draggable && {
                draggable,
                onDrop: e => onDrop(e, index),
                onDragStart: e => onDragStart(e, index),
                onDragEnter: e => onDragEnter(e, index),
                onDragLeave: e => onDragLeave(e, index),
                onDragOver,
            }}
        >
            <FlexLayout>
                <FlexLayout spacing={'mr-4' + '+4'.repeat(nesting)}>
                    <Dragger color={draggable ? 'interface' : 'secondaryInterface'} />
                </FlexLayout>

                <FlexLayout width="47">
                    <Logic
                        item={item}
                        index={index}
                        updateField={updateField}
                    />

                    <Open
                        item={item}
                        index={index}
                        items={items}
                        status={status}
                        updateField={updateField}
                    />

                    <Field
                        mode={mode}
                        item={item}
                        index={index}
                        operator={operator}
                        options={options}
                        status={status}
                        updateField={updateField}
                    />

                    <Operator
                        operators={operators}
                        operator={operator}
                        updateOperator={updateOperator}
                    />
                </FlexLayout>

                <FlexLayout grow="1">
                    <Value
                        mode={mode}
                        item={item}
                        index={index}
                        operator={operator}
                        options={allOptions}
                        status={status}
                        updateField={updateField}
                    />

                    <Add
                        item={item}
                        index={index}
                        options={options}
                        addField={addField}
                    />

                    <Remove
                        item={item}
                        index={index}
                        items={items}
                        removeField={removeField}
                    />

                    <Close
                        item={item}
                        index={index}
                        items={items}
                        status={status}
                        updateField={updateField}
                    />
                </FlexLayout>
            </FlexLayout>
        </Draggable>
    );
};

/** @type { React.FC<{ color?: string }> } */
const Dragger = ({ color }) => (
    <Icon
        icon="equals"
        size="sm"
        color={color}
    />
);

/**
 * @typedef { object } LogicProps
 * @prop { Item } item
 * @prop { number } index
 * @prop { (index: number, item: Item) => void } updateField
 */

/** @type { React.FC<LogicProps> } */
const Logic = ({ item, index, updateField }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(open => !open);
    };

    /** @type { (logic: Logic) => void } */
    const updateLogic = (logic) => {
        updateField(index, { ...item, data: { ...item.data, logic } });
        setDropdownOpen(false);
    };

    if (index === 0) {
        return null;
    }

    const logic = item.data?.logic || 'and';

    return (
        <FlexLayout>

            <Dropdown
                opener={
                    <Button
                        square
                        type="plain"
                        onClick={toggleDropdown}
                        spacing="m-0 px-2 py-2 mr-2"
                    >
                        {logic.toUpperCase()}
                    </Button>
                }
                open={dropdownOpen}
                onClose={toggleDropdown}
            >
                <List>
                    {/** @type { Logic[] } */ (['and', 'or']).map(value => (
                        <ListItem
                            key={value}
                            selected={value === logic}
                            disabled={value === logic}
                            onClick={() => updateLogic(value)}
                            button
                        >
                            <Text>
                                {value.toUpperCase()}
                            </Text>
                        </ListItem>
                    ))}
                </List>
            </Dropdown>
        </FlexLayout>
    );
};

/**
 * @typedef { object } OperatorProps
 * @prop { Operators } operators
 * @prop { Operator } operator
 * @prop { (operator: Operator) => void } updateOperator
 */

/** @type { React.FC<OperatorProps> } */
const Operator = ({ operators, operator, updateOperator }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(open => !open);
    };

    /** @type { (operator: Operator) => void } */
    const changeOperator = (operator) => {
        updateOperator(operator);
        setDropdownOpen(false);
    };

    const { label, icon } = allOperators[operator];

    return (
        <FlexLayout>
            <Dropdown
                opener={
                    <Button
                        square
                        type="plain"
                        onClick={toggleDropdown}
                        spacing="m-0 px-2 py-2 mr-2"
                    >
                        {icon ? <Icon icon={icon} /> : label?.toUpperCase()}
                    </Button>
                }
                open={dropdownOpen}
                onClose={toggleDropdown}
            >
                <List>
                    {Object.entries(operators).map(([key, { label, icon, disabled }]) => (
                        <ListItem
                            key={key}
                            selected={key === operator}
                            disabled={key === operator || disabled}
                            onClick={() => changeOperator(/** @type { Operator } */ (key))}
                            button
                        >
                            <Text>
                                {icon ? <Icon icon={icon} /> : label?.toUpperCase()}
                            </Text>
                        </ListItem>
                    ))}
                </List>
            </Dropdown>
        </FlexLayout>
    );
};

/**
 * @typedef { object } OpenProps
 * @prop { Item } item
 * @prop { number } index
 * @prop { Status } status
 * @prop { Item[] } items
 * @prop { (index: number, item: Item) => void } updateField
 */

/** @type { React.FC<OpenProps> } */
const Open = ({ item, index, status, items, updateField }) => {
    const { open = 0 } = item.data || {};

    /** @type { (i: number) => void } */
    const updateOpen = i => {
        const newOpen = i === open ? open + 1 : open - 1;
        updateField(index, { ...item, data: { ...item.data, open: newOpen } });
    };

    const canOpen = useMemo(() => {
        const endIndex = findClose(items, index);
        const slicedItems = items.slice(index, endIndex + 1);

        const openCount = countOpen(slicedItems);
        const closeCount = countClose(slicedItems);

        const openTotal = countOpen(items);
        const closeTotal = countClose(items);

        const currentItem = items[index];

        const canOpen = slicedItems.length > openCount + 1
            && openCount === closeCount
            && openTotal === closeTotal
            && !currentItem.data?.close;

        return canOpen;
    }, [index, items]);

    const error = useMemo(() => {
        return status === 'error' && !validateParens(items);
    }, [status, items]);

    const num = canOpen ? open + 1 : open;

    return (
        <FlexLayout spacing={num ? 'mr-2' : ''}>
            {new Array(num).fill(0).map((_, i) => (
                <ToggleButton
                    key={`open-${i}`}
                    square
                    type="plain"
                    active={error}
                    color={error ? 'error' : 'default'}
                    onClick={() => updateOpen(i)}
                    spacing="m-0 px-0 py-3"
                >
                    <Icon
                        icon="bracket-round"
                        color={(i < open || !canOpen
                            ? undefined
                            : 'secondaryInterface'
                        )}
                    />
                </ToggleButton>
            )).reverse()}
        </FlexLayout>
    );
};

/** @typedef { OpenProps } CloseProps */

/** @type { React.FC<CloseProps> } */
const Close = ({ item, index, status, items, updateField }) => {
    const { close = 0 } = item.data || {};

    /** @type { (i: number) => void } */
    const updateClose = (i) => {
        const newClose = i === close ? close + 1 : close - 1;
        updateField(index, { ...item, data: { ...item.data, close: newClose } });
    };

    const canClose = useMemo(() => {
        const startIndex = findOpen(items, index);
        const slicedItems = items.slice(startIndex, index + 1);

        const openCount = countOpen(slicedItems);
        const closeCount = countClose(slicedItems);

        const openTotal = countOpen(items);
        const closeTotal = countClose(items);

        const firstCount = countOpen(slicedItems.slice(0, -1));
        const lastCount = countClose(slicedItems.slice(-1));

        const currentItem = items[index];

        const canClose = slicedItems.length > closeCount + 1
            && openCount > closeCount
            && openTotal > closeTotal
            && (firstCount > lastCount || !lastCount)
            && !currentItem.data?.open;

        return canClose;
    }, [index, items]);

    const error = useMemo(() => {
        return status === 'error' && !validateParens(items);
    }, [status, items]);

    const num = canClose ? close + 1 : close;

    return (
        <FlexLayout>
            {new Array(num).fill(0).map((_, i) => (
                <ToggleButton
                    key={`close-${i}`}
                    square
                    type="plain"
                    active={error}
                    color={error ? 'error' : 'default'}
                    onClick={() => updateClose(i)}
                    spacing="m-0 px-0 py-3"
                >
                    <Icon
                        icon="bracket-round-right"
                        color={(i < close
                            ? undefined
                            : 'secondaryInterface'
                        )}
                    />
                </ToggleButton>
            ))}
        </FlexLayout>
    );
};

/**
 * @typedef { object } FieldProps
 * @prop { Mode } mode
 * @prop { Item } item
 * @prop { number } index
 * @prop { Operator } operator
 * @prop { Option[] } options
 * @prop { Status } status
 * @prop { (index: number, item: Item) => void } updateField
 */

/** @type { React.FC<FieldProps> } */
const Field = ({ mode, item, index, operator, options, status, updateField }) => {
    const [open, setOpen] = useState(false);

    /**
     * @type { (
     *     e: React.ChangeEvent<HTMLInputElement | HTMLDivElement>,
     *     newValue: NewValue
     * ) => void }
     */
    const onChangeField = (e, newValue) => {
        if (isOptionObject(newValue) && newValue.column?.id) {
            const { name, table, schema, function: f } = newValue.column;

            const data = {
                ...item.data,
                column: {
                    name,
                    table,
                    schema,
                    ...(mode === 'having' ? { function: f } : {}),
                },
                operator,
            };

            if (operator.startsWith('is')) {
                data.value = undefined;
            }

            updateField(index, { ...item, column: newValue.column, data });
        } else if (newValue === null) {
            const data = { ...item.data, column: undefined };

            updateField(index, { ...item, column: undefined, data });
        }
    };

    /** @type { (option: OptionType, value: OptionType) => boolean } */
    const getOptionSelected = (option, value) => {
        return JSON.stringify(option) === JSON.stringify(value);
    };

    /** @type { (option: OptionType) => boolean } */
    const getOptionDisabled = (option) => {
        if (!isOptionObject(option)) {
            return false;
        }

        const value = item.data?.value;

        if (typeof value === 'object' && 'name' in value) {
            if (isSameColumn(value, option.column)) {
                return true;
            }
        }

        return false;
    };

    /** @type { (option: Option | string | null) => string } */
    const getOptionLabel = (option) => {
        if (option && typeof option === 'object') {
            return option.column?.id
                ? mode === 'having' && option.column.function
                    ? resolveFunction(option.column.function, option.column.name)
                    : option.column.name
                : option.title || '';
        }

        return option || '';
    };

    /** @type { Option | null } */
    const value = useMemo(() => {
        const { column } = item;

        if (column?.id) {
            return {
                title: mode === 'having' && column.function
                    ? resolveFunction(column.function, column.name)
                    : column.name,
                subtitle: [
                    column.schema,
                    column.table,
                ].join('.'),
                category: 'Fields',
                column,
            };
        }

        return null;
    }, [mode, item]);

    return (
        <InputTooltip content={!open && value ? value?.subtitle : ''}>
            <Autocomplete
                label="Field"
                width="100"
                spacing="p-0 pr-2"
                options={options}
                onChange={onChangeField}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={value}
                multiple={false}
                getOptionSearchableString={getOptionLabel}
                getOptionSelected={getOptionSelected}
                getOptionDisabled={getOptionDisabled}
                error={status === 'error' && !item.column?.id}
            />
        </InputTooltip>
    );
};

/**
 * @typedef { object } ValueProps
 * @prop { Mode } mode
 * @prop { Item } item
 * @prop { number } index
 * @prop { Operator } operator
 * @prop { Option[] } options
 * @prop { Status } status
 * @prop { (index: number, item: Item) => void } updateField
 */

/** @type { React.FC<ValueProps> } */
const Value = ({ mode, item, index, operator, options, status, updateField }) => {
    const type = useMemo(() => {
        return resolveValueType(item);
    }, [item]);

    /** @type { OptionValue | null } */
    const value = useMemo(() => {
        return resolveOptionValue(item, operator, type, status, options);
    }, [item, operator, type, status, options]);

    /**
     * @type { (
     *     e: React.ChangeEvent<HTMLInputElement | HTMLDivElement>,
     *     newValue: NewValue
     * ) => void }
     */
    const onChangeField = (e, newValue) => {
        const value = resolveFilterValue(newValue, operator, type);

        updateField(index, { ...item, data: { ...item.data, value } });
    };

    /**
     * @type { (
     *     e: React.ChangeEvent<HTMLInputElement>,
     *     newValue: string
     * ) => void }
     */
    const onInputChangeField = (e, newValue) => {
        const value = resolveFilterValue(newValue, operator, type);

        const isTextValue = () => {
            return typeof newValue === 'string'
                && !operator.endsWith('in')
                && type !== 'boolean';
        };

        const isSameValue = () => {
            const v = item.data?.value;

            if (typeof v === 'object' && 'name' in v) {
                return value === v.name;
            }

            return value === v;
        };

        if (isTextValue() && !isSameValue()) {
            updateField(index, { ...item, data: { ...item.data, value } });
        }
    };

    /** @type { (moment: Moment | null) => void } */
    const onDateChangeField = (moment) => {
        if (moment && moment.isValid()) {
            switch (type) {
                case 'date': {
                    const value = moment.format('YYYY-MM-DD');

                    updateField(index, { ...item, data: { ...item.data, value } });
                    break;
                }

                case 'time': {
                    const value = moment.format('HH:mm:ss');

                    updateField(index, { ...item, data: { ...item.data, value } });
                    break;
                }

                case 'datetime': {
                    const value = moment.format('YYYY-MM-DD HH:mm:ss');

                    updateField(index, { ...item, data: { ...item.data, value } });
                    break;
                }
            }
        } else {
            const data = { ...item.data, value: undefined };

            updateField(index, { ...item, data });
        }
    };

    /** @type { (option: OptionType, value: NewValue) => boolean } */
    const getOptionSelected = (option, value) => {
        return JSON.stringify(option) === JSON.stringify(value);
    };

    /** @type { (option: OptionType) => boolean } */
    const getOptionDisabled = (option) => {
        if (!isOptionObject(option)) {
            return false;
        }

        const { column } = item;

        if (column?.id && mode !== 'having') {
            if (isSameColumn(column, option.column)) {
                return true;
            }
        }

        if (option.column?.id) {
            const valueType = resolveValueType(option);

            if (type !== valueType) {
                return true;
            }
        }

        return false;
    };

    /** @type { (option: OptionType) => string } */
    const getOptionLabel = (option) => {
        if (isOptionObject(option)) {
            return option.column?.id
                ? option.column.name
                : option.title || '';
        }

        return option || '';
    };

    if (operator.startsWith('is')) {
        return null;
    }

    switch (true) {
        case isDateType(type): {
            const getValue = () => {
                if (value && typeof value === 'string') {
                    switch (type) {
                        case 'date': {
                            return moment(value, 'YYYY-MM-DD', true);
                        }

                        case 'time': {
                            return moment(value, 'HH:mm:ss', true);
                        }

                        case 'datetime': {
                            return moment(value, 'YYYY-MM-DD HH:mm:ss', true);
                        }
                    }
                }

                return null;
            };

            return (
                <DatePicker
                    label="Value"
                    width="100"
                    spacing="p-0 pr-2"
                    onChange={onDateChangeField}
                    value={getValue()}
                    type={type}
                    minutesStep={1}
                    error={status === 'error' && !item.data?.value}
                />
            );
        }

        case isTextType(type): {
            return (
                <TextValue
                    item={item}
                    status={status}
                    value={value}
                    type={type}
                    operator={operator}
                    options={options}
                    onChangeField={onChangeField}
                    onInputChangeField={onInputChangeField}
                    getOptionSelected={getOptionSelected}
                    getOptionDisabled={getOptionDisabled}
                    getOptionLabel={getOptionLabel}
                />
            );
        }

        default:
            return null;
    }
};

/**
 * @typedef { object } TextValueProps
 * @prop { Item } item
 * @prop { Status } status
 * @prop { NewValue } value
 * @prop { ValueType } type
 * @prop { Operator } operator
 * @prop { Option[] } options
 * @prop { (
 *     e: React.ChangeEvent<HTMLInputElement | HTMLDivElement>,
 *     newValue: NewValue
 * ) => void } onChangeField
 * @prop { (
 *     e: React.ChangeEvent<HTMLInputElement>,
 *     newValue: string
 * ) => void } onInputChangeField
 * @prop { (option: OptionType, value: NewValue) => boolean } getOptionSelected
 * @prop { (option: OptionType) => boolean } getOptionDisabled
 * @prop { (option: OptionType) => string } getOptionLabel
 */

/** @type { React.FC<TextValueProps> } */
const TextValue = ({
    item,
    status,
    value,
    type,
    operator,
    options,
    onChangeField,
    onInputChangeField,
    getOptionSelected,
    getOptionDisabled,
    getOptionLabel,
}) => {
    const [open, setOpen] = useState(false);

    const tooltipProps = useMemo(() => (operator.startsWith('in')
        ? {
            content: open && (!Array.isArray(value) || !value.length)
                ? 'Press Enter to add a value'
                : '',
            placement: 'top-start',
            enterDelay: 100,
        }
        : {
            content: !open && value
                    && typeof value === 'object'
                    && !Array.isArray(value)
                    && 'subtitle' in value
                ? value.subtitle || ''
                : '',
        }
    ), [operator, value]);

    const allOptions = useMemo(() => {
        if (operator.startsWith('in') && Array.isArray(value)) {
            const freeSoloOptions = value
                .filter(v => typeof v !== 'object' || !v.column?.id)
                .map(v => (typeof v !== 'object'
                    ? createOption(v, type, status)
                    : v
                ));
            return freeSoloOptions.concat(options);
        }

        return !operator.endsWith('like') ? options : [];
    }, [operator, status, value, options]);

    const error = useMemo(() => {
        if (status !== 'error') {
            return false;
        }
        return !validateValue(item);
    }, [status, item]);

    const multiple = Array.isArray(value);

    return (
        <InputTooltip {...tooltipProps}>
            <Autocomplete
                key={[type, operator].join('-')}
                label="Value"
                width="100"
                spacing="p-0 pr-2"
                options={allOptions}
                onChange={onChangeField}
                onInputChange={onInputChangeField}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={value}
                multiple={multiple}
                autoSelect={false}
                getOptionSearchableString={getOptionLabel}
                getOptionSelected={getOptionSelected}
                getOptionDisabled={getOptionDisabled}
                error={error}
                freeSolo={type !== 'boolean'}
            />
        </InputTooltip>
    );
};

/**
 * @typedef { object } AddProps
 * @prop { Item } item
 * @prop { number } index
 * @prop { Option[] } options
 * @prop { (index: number) => void } addField
 */

/** @type { React.FC<AddProps> } */
const Add = ({ item, index, options, addField }) => {
    const disabled = !options.some(o => !o.disabled)
        || !item.column?.id;

    return (
        <Button
            round
            type="plain"
            color="secondary"
            onClick={() => addField(index + 1)}
            disabled={disabled}
            spacing="m-0 p-1"
        >
            <Icon
                icon="plus-circle"
                color={disabled ? 'interface' : 'secondary'}
            />
        </Button>
    );
};

/**
 * @typedef { object } ActionsProps
 * @prop { Item } item
 * @prop { number } index
 * @prop { Item[] } items
 * @prop { (index: number) => void } removeField
 */

/** @type { React.FC<ActionsProps> } */
const Remove = ({ item, index, items, removeField }) => {
    const disabled = items.length <= 1 && !item.column?.id;

    return (
        <Button
            round
            type="plain"
            color="error"
            onClick={() => removeField(index)}
            disabled={disabled}
            spacing="m-0 p-1"
        >
            <Icon
                icon="minus-circle"
                color={disabled ? 'interface' : 'error'}
            />
        </Button>
    );
};

/**
 * @typedef { object } FlexLayoutProps
 * @prop { React.ReactNode } children
 * @prop { string } [spacing]
 * @prop { string } [width]
 * @prop { string } [grow]
 * @prop { 'center' } [justifyContent]
 * @prop { 'column' } [flexDirection]
 */

/** @type { React.FC<FlexLayoutProps> } */
const FlexLayout = ({ children, ...props }) => (
    <NoWrap flex alignItems="center" {...props}>
        {children}
    </NoWrap>
);

/**
 * @typedef { object } TextProps
 * @prop { React.ReactNode } children
 * @prop { string } [spacing]
 */

/** @type { React.FC<TextProps> } */
const Text = ({ children, ...props }) => (
    <Typography
        component="div"
        variant="body1"
        color="secondaryText"
        weight="medium"
        align="center"
        spacing="mb-0"
        {...props}
    >
        {children}
    </Typography>
);

/**
 * @typedef { object } LabelProps
 * @prop { React.ReactNode } children
 * @prop { string } [spacing]
 */

/** @type { React.FC<LabelProps> } */
const Label = ({ children, ...props }) => (
    <Typography variant="subtitle1" color="text" spacing="mb-0" {...props}>
        {children}
    </Typography>
);

/**
 * @typedef { object } HelpProps
 * @prop { React.ReactNode } children
 * @prop { string } [spacing]
 * @prop { string } [color]
 */

/** @type { React.FC<HelpProps> } */
const Help = ({ children, ...props }) => (
    <Typography variant="body2" color="secondaryText" spacing="mb-0" {...props}>
        {children}
    </Typography>
);

/** @type { React.FC } */
const Error = () => (
    <Status>
        <Icon
            prefix="fas"
            icon="exclamation-circle"
            size="sm"
            color="error"
            spacing="mr-2"
        />
        <Help>
            There is at least one incomplete condition
        </Help>
    </Status>
);

/** @type { React.FC } */
const Success = () => (
    <Status>
        <Icon
            icon="check"
            size="sm"
            color="secondary"
            spacing="mr-2"
        />
        <Help>
            Saved
        </Help>
    </Status>
);

const Status = styled(({ className, children }) => (
    <Layout className={className} flex alignItems="center">
        {children}
    </Layout>
))`
    && {
        padding: 0.55rem 1rem 0.45rem;
    }
`;

/**
 * @typedef { object } InputTooltipProps
 * @prop { React.ReactNode } children
 * @prop { string } [className]
 * @prop { string } [width]
 * @prop { string } [placement]
 * @prop { string } [content]
 * @prop { number } [enterDelay]
 */

const InputTooltip = styled((/** @type { InputTooltipProps } */ {
    className,
    content,
    placement,
    enterDelay,
    children,
}) => ((
    <Tooltip
        block
        className={className}
        content={content}
        enterDelay={enterDelay || 1000}
        interactive={false}
        placement={placement || 'bottom-start'}
    >
        {children}
    </Tooltip>
)))`
    width: ${({ width }) => width || 100}%;
`;

const Draggable = styled.div`
    margin: 0 -${spacings[2]}rem;
    padding: ${spacings[1]}rem ${spacings[2]}rem;

    ${({ draggable }) => draggable && css`
        cursor: grab;
        :active {
            cursor: grabbing;
        }
    `}
`;

const NoWrap = styled(Layout)`
    && {
        white-space: nowrap;
    }
`;

const Wrapper = styled(Layout)`
    width: 90vw;
    max-width: 100%;
`;

export { isSameColumn };

export default FilterDialog;
