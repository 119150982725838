import React from 'react';
import {
    Loader,
    Layout,
    Typography,
    ExpansionPanelHeader,
    Button,
} from 'ui-components';

import JobCardList from '../../shared/job/job-card-list';

/** @typedef { import('./__types').Job } Job */
/** @typedef { import('../../models/sources/__types').Source } Source */
/** @typedef { import('../../models/sources/__types').SourceType } SourceType */

/**
 * @typedef { object } Props
 *
 * @prop { Job[] } jobs
 * @prop { Source[] } sources
 * @prop { boolean } loading
 * @prop { SourceType[] } sourceTypes
 * @prop { number } jobsPerPage
 * @prop { number } page
 * @prop { (newPage: number ) => void } updatePage
 */

/** @type { React.FC<Props> } */
const JobsList = ({
    jobs,
    sources,
    loading,
    sourceTypes,
    jobsPerPage,
    updatePage,
    page,
}) => {
    // Jobs are already filtered and sorted in the server.
    // The jobs may have an extra job used in pagination.
    const pageJobs = jobs && jobs.slice(0, jobsPerPage);

    if (loading) {
        return (
            <Layout spacing="mt-6">
                <ExpansionPanelHeader spacing="pt-4">
                    <Loader big active message="Loading Jobs" />
                </ExpansionPanelHeader>
            </Layout>
        );
    }

    return (
        <Layout spacing="mt-6">
            {pageJobs.length ? (
                <JobCardList
                    jobs={pageJobs}
                    sources={sources}
                    sourceTypes={sourceTypes}
                />
            ) : (
                <ExpansionPanelHeader>
                    <Typography align="center">
                        No jobs matching selected filters.
                    </Typography>
                </ExpansionPanelHeader>
            )}

            <Layout flex justifyContent="flex-end" alignItems="center" spacing="mt-3">
                <Typography color="secondaryText" variant="body2" spacing="mb-0">
                    Page {page + 1}
                </Typography>

                <Button
                    onClick={() => updatePage(page - 1)}
                    disabled={page <= 0}
                    spacing="mt-0 mr-2 ml-4"
                >
                    Prev
                </Button>

                <Button
                    onClick={() => updatePage(page + 1)}
                    disabled={jobs.length <= jobsPerPage}
                    spacing="m-0"
                >
                    Next
                </Button>
            </Layout>
        </Layout>
    );
};

export default JobsList;
