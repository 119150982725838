import { useState, useCallback, useEffect } from 'react';

import { reportError } from '../../lib/error-reporter';
import { useSession } from '../context-providers';
import openSupportChat from '../use-support-chat';
import { fetchTeamMembers } from '../../models/teams';
import { fetchObjectUsers, updateObjectUsers } from '../../models/database-objects';

/** @typedef { import('models/database-objects/__types').DatabaseObject } DatabaseObject */
/** @typedef { import('models/database-objects/__types').Table } Table */
/** @typedef { import('models/database-objects/__types').View } View */
/** @typedef { import('models/users/__types').User } User */
/** @typedef { import('lib/ajax.js').AjaxResponse= } AjaxError */
/** @typedef { import('app/__types').Session } Session */

/** @typedef { User & {canView: boolean | 'partial', modified: boolean } } ObjectViewer */
/** @typedef { ObjectViewer[]= } ObjectViewersState */

/**
 * @typedef { object } Props
 * @prop { (Table | View)[] | undefined } objectsToManage
 * @prop { boolean } open
 */

/**
 * @typedef { object } ReturnProps
 *
 * @prop { ObjectViewersState } viewers
 * @prop { boolean } loading
 * @prop { boolean } updating
 * @prop { boolean } finished
 * @prop { boolean } canInviteUsers
 * @prop { boolean } disabled
 * @prop { AjaxError } loadingError
 * @prop { AjaxError } updatingError
 * @prop { (v: ObjectViewer) => void } toggleCanView
 * @prop { () => Promise<void> } saveChanges
 * @prop { () => void } contactSupport
 */

/** @type { (props: Props) => ReturnProps } */
const useManageViewersDialog = ({ objectsToManage, open }) => {

    const [session] = useSession();
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [finished, setFinished] = useState(false);

    /** @type {[AjaxError, React.Dispatch<AjaxError>]} */
    const [loadingError, setLoadingError] = useState();

    /** @type {[AjaxError, React.Dispatch<AjaxError>]} */
    const [updatingError, setUpdatingError] = useState();

    /** @type {[ObjectViewersState, React.Dispatch<ObjectViewersState>]} */
    const [viewers, setViewers] = useState();

    /** @type {[User[] | undefined, React.Dispatch<User[] | undefined>]} */
    const [allUsers, setAllUsers] = useState();

    // Brittle seeming condition inherited from polymer
    const disabled = allUsers && allUsers.length
        ? (allUsers[0].public_select || false)
        : false;

    const canInviteUsers = !session.nav.exclude.includes('teams');

    /** @type { (viewerToToggle: ObjectViewer) => void } */
    const toggleCanView = useCallback((viewerToToggle) => {
        if (!viewers) {
            return;
        }
        const updatedViewers = viewers.map((viewer) => {
            if (viewer === viewerToToggle) {
                return {
                    ...viewer,
                    modified: true,
                    canView: !viewer.canView,
                };
            }
            return viewer;
        });
        setViewers(updatedViewers);
    }, [viewers]);

    const changeAccess = useCallback((objectToChange, users, action) => {
        // The backend throws on BQ if we update more then 1 users at the same time
        // so update 1-by-1 here instead of just calling updateObjectUsers with the full array
        return Promise.all(users.map((user) => {
            return updateObjectUsers(objectToChange, [user], action);
        }));
    }, []);

    const saveChanges = useCallback(async () => {
        if (!viewers || !objectsToManage?.length) {
            return;
        }
        setUpdating(true);
        const usersToGrant = viewers.filter(u => u.canView === true && u.modified);
        const usersToRevoke = viewers.filter(u => u.canView === false && u.modified);

        try {
            await Promise.all(objectsToManage.map(async (objectToManage) => {
                await changeAccess(objectToManage, usersToGrant, 'GRANT');
                await changeAccess(objectToManage, usersToRevoke, 'REVOKE');
            }));
        } catch (err) {
            reportError(err);
            setUpdatingError(err.data?.message || 'There was an error updating permissions');
        }

        setUpdating(false);
        setFinished(true);
    }, [viewers, objectsToManage]);

    const contactSupport = useCallback(() => {
        openSupportChat('I would like to learn how to change Public Group permissions.');
    }, []);

    useEffect(() => {
        if (!objectsToManage) {
            return;
        }

        /** @type { (objectsToManage: DatabaseObject[]) => Promise<void> } */
        const loadViewers = async (objectsToManage) => {
            setLoading(true);

            try {
                const viewersInDb = await fetchTeamMembers('VIEWERS');

                // Fetch all users with access for each object
                const accessLists = await Promise.all(objectsToManage.map((objectToManage) => {
                    if (objectToManage.type === 'folder') {
                        return [];
                    }
                    return fetchObjectUsers(objectToManage);
                }));

                // Creates single merged accessList
                // with 'partial' for viewers with access to some but not all objects
                /** @type { ((ObjectViewer)[]) } */
                const viewersWithAccess = viewersInDb.map((viewer) => {

                    const viewerToReturn = {
                        ...viewer,
                        modified: false,
                        canView: false,
                    };

                    const numOfObjectsViewerHasAccess = accessLists
                        .reduce((acc, usersWithObjectAccess) => {
                            const canView = usersWithObjectAccess
                                .some(user => user.username === viewer.username);
                            if (canView) {
                                return acc + 1;
                            }
                            return acc;
                        }, 0);

                    if (numOfObjectsViewerHasAccess === accessLists.length) {
                        return { ...viewerToReturn, canView: true };
                    }
                    if (numOfObjectsViewerHasAccess > 0) {
                        return { ...viewerToReturn, canView: 'partial' };
                    }
                    return viewerToReturn;

                });

                setViewers(viewersWithAccess);
                setAllUsers(accessLists[0]);
                setLoading(false);
            } catch (err) {
                reportError(err);
                setLoadingError(err.data?.message || 'There was an error loading viewers');
            }

        };

        const shouldLoadDeps = open && objectsToManage.length;

        if (shouldLoadDeps) {
            loadViewers(objectsToManage);
        }

    }, [open, objectsToManage]);

    useEffect(() => {
        if (open) {
            return;
        }
        setLoadingError(undefined);
        setUpdatingError(undefined);
        setLoading(false);
        setFinished(false);
        setViewers(undefined);
    }, [open]);

    return {
        viewers,
        loading,
        updating,
        loadingError,
        updatingError,
        canInviteUsers,
        disabled,
        finished,
        toggleCanView,
        saveChanges,
        contactSupport,
    };
};


export default useManageViewersDialog;
