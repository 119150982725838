import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Layout,
    Typography,
    Icon,
    Card,
    Dropdown,
    ListItem,
    Loader,
    TableCell,
    TableRow,
    TableHead,
    Table,
    TableBody,
} from 'ui-components';

import PageLayout from '../../shared/page-layout';
import PageHeader from '../../shared/page-header';
import useDashboardsPage from './use-dashboards-page';
import CreateDashboardDialog from './create-dashboard-dialog';
import RenameDashboardDialog from './rename-dashboard-dialog';
import DeleteDashboardDialog from './delete-dashboard-dialog';

/** @typedef { import('models/dashboards').Dashboard } Dashboard */

/** @type { React.FC } */
const DashboardsPage = () => {
    const {
        dashboards,
        isError,
        closeNewDashboardDialog,
        isLoading,
        newDashboardDialogOpen,
        openNewDashboardDialog,
        addDashboardListItem,
        removeDashboardListItem,
        updateDashboardListItem,
        deleteDialogOpen,
        renameDialogOpen,
        toggleDeleteDialog,
        toggleRenameDialog,
        dashboardToDelete,
        dashboardToRename,
    } = useDashboardsPage();

    return (
        <PageLayout title="Dashboards">
            <PageHeader
                title="Dashboards"
            >
                <Button
                    color={'primary'}
                    type="solid"
                    spacing="mx-0"
                    onClick={() => openNewDashboardDialog()}
                >
                    New Dashboard
                </Button>
            </PageHeader>

            <CreateDashboardDialog
                key={`createNewDashboardDialog-${newDashboardDialogOpen}`}
                isOpen={newDashboardDialogOpen}
                onClose={closeNewDashboardDialog}
                addDashboardListItem={addDashboardListItem}
            />
            <>
                <RenameDashboardDialog
                    key={`renameDialog-${renameDialogOpen}`}
                    isOpen={renameDialogOpen}
                    onClose={(dashboard) => toggleRenameDialog(dashboard)}
                    updateDashboardListItem={updateDashboardListItem}
                    dashboard={dashboardToRename}
                />
                <DeleteDashboardDialog
                    key={`deleteDialog-${deleteDialogOpen}`}
                    isOpen={deleteDialogOpen}
                    onClose={(dashboard) => toggleDeleteDialog(dashboard)}
                    removeDashboardListItem={removeDashboardListItem}
                    dashboard={dashboardToDelete}
                />
                {
                    isLoading ? (
                        <Card flat contentSpacing="py-4 px-6">
                            <Typography
                                align="center"
                                spacing="my-4"
                                color="secondaryText"
                                variant="body1">
                                <Loader big active message="Loading Dashboards" />.
                            </Typography>
                        </Card>
                    ) : dashboards?.length > 0
                        ? (<Card contentSpacing="p-0">
                            <Table>
                                <DashboardsListHeader />

                                <TableBody>
                                    {dashboards?.map(dashboard => (
                                        <DashboardsListItem
                                            key={dashboard.id}
                                            dashboard={dashboard}
                                            toggleDeleteDialog={toggleDeleteDialog}
                                            toggleRenameDialog={toggleRenameDialog}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </Card>
                        ) : (<Card flat contentSpacing="py-4 px-6">
                            <Typography
                                align="center"
                                spacing="my-4"
                                color={isError ? 'error' : 'secondaryText'}
                                variant="body1">
                                {isError
                                    ? 'Oops ! an error was encountered while loading dashboards'
                                    : 'No dashboards found'}
                            </Typography>
                        </Card>)
                }
            </>
        </PageLayout>
    );
};


/** @type { React.FC } */
const DashboardsListHeader = () => (
    <TableHead height="80px">
        <TableRow>
            <TableCell width="5%" align="left">{/* Placeholder for checkbox */}</TableCell>
            <TableCell width="45%" align="left">Name</TableCell>
            <TableCell width="40%" align="left">Modified</TableCell>
            <TableCell width="10%" align="left">{/* Placeholder for dropdown */} </TableCell>
        </TableRow>
    </TableHead>
);

/**
 * @typedef { object } DashboardsListItemProps
 * @property { Dashboard } dashboard
 * @property { () => void } toggleDeleteDialog
 * @property { () => void } toggleRenameDialog
 */

/** @type { React.FC<DashboardsListItemProps> } */
const DashboardsListItem = React.memo(({
    dashboard,
    toggleDeleteDialog,
    toggleRenameDialog,
}) => {
    const {
        updatedAt,
        title: name,
    } = dashboard;

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const closeDropdown = useCallback(() => {
        setDropdownOpen(false);
    }, []);

    const openDropdown = useCallback(() => {
        setDropdownOpen(true);
    }, []);


    const modified = formatDate(updatedAt);

    const navigate = useNavigate();
    const goToDashboard = () => {
        navigate(`/dashboards/${dashboard.id}`);
    };

    const renameDashboard = () => {
        closeDropdown();
        toggleRenameDialog(dashboard);
    };

    const deleteDashboard = () => {
        closeDropdown();
        toggleDeleteDialog(dashboard);
    };

    return (
        <>
            <TableRow
                key={dashboard.id}
                onClick={goToDashboard}
            >
                <TableCell width="5%" align="left">
                    {/* Placeholder for checkbox */}
                </TableCell>

                <TableCell width="45%" align="left">
                    <Icon icon="rectangles-mixed" spacing="mr-2" />
                    {name}
                </TableCell>

                <TableCell width="40%" align="left">{modified}</TableCell>

                <TableCell width="10%" align="right">
                    <Layout
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Dropdown
                            onClose={closeDropdown}
                            open={dropdownOpen}
                            opener={(
                                <Button
                                    onClick={openDropdown}
                                    type="plain"
                                    spacing="px-2">

                                    <Icon icon="ellipsis-v" />
                                </Button>
                            )}
                        >
                            <ListItem
                                button
                                label="Rename"
                                onClick={renameDashboard}
                            />

                            <ListItem
                                button
                                label="Delete"
                                onClick={deleteDashboard}
                            />
                        </Dropdown>
                    </Layout>
                </TableCell>
            </TableRow>
        </>
    );
});


/** @type { (date: Date | string) => string | null } */
const formatDate = (date) => {
    const d = moment.utc(date);
    if (!d.isValid()) {
        return null;
    }
    return d.format('MMM DD, YYYY [@] HH:mm');
};


export default DashboardsPage;
