import { useEffect, useState, useCallback } from 'react';

import { useRequest } from '../../lib/ajax';

/** @typedef { import('./__types').Warehouse } Warehouse */
/** @typedef { import('../../app/__types').PageProps } PageProps */

/**
 * @typedef { object } Props
 *
 * @prop { PageProps['openToast'] } openToast
 */

/**
 * @typedef { object } ReturnProps
 *
 * @prop { (w: Warehouse) => void } onSwitch
 */

/** @type { (props: Props) => ReturnProps } */
function useSwitch({ openToast }) {
    /** @type { [Warehouse, (w: Warehouse) => void ] } */
    const [warehouseToSwitch, setWarehouseToSwitch] = useState({});

    const switchReq = useRequest(`/databases/switch/${warehouseToSwitch.id}`);

    const onSwitch = useCallback((warehouse) => {
        setWarehouseToSwitch(warehouse);
    }, []);

    useEffect(() => {
        if (!warehouseToSwitch.id) {
            return;
        }

        switchReq.go().then(({ ok, data }) => {
            setWarehouseToSwitch({});
            switchReq.clear();

            if (!ok) {
                openToast(data);
                return;
            }

            openToast(`Your active warehouse is now ${data.database.name}.`);
            window.location.reload();
        });
    }, [warehouseToSwitch, switchReq, openToast]);

    return { onSwitch };
}

export default useSwitch;
