/* eslint-disable camelcase */
import React, { useEffect, useCallback } from 'react';
import { parse as parseQueryString } from 'qs';
import { Button, Layout, Typography } from 'ui-components';
import { post } from '../../../../lib/ajax';
import { reportError } from '../../../../lib/error-reporter';
import MarkdownRenderer from '../../../../shared/markdown-renderer';

import BrandedButton, { getVendor } from './branded-button';

/** @typedef { import('models/sources/__types').Source } Source */
/** @typedef { import('models/sources/__types').OAuth1Param } Param */
/** @typedef { import('models/sources/__types').OAuth1ParamValue } Value */

/**
 * @typedef { object } State
 * @prop { boolean= } loading
 * @prop { string= } error
 * @prop { string= } oauth_token
 * @prop { string= } oauth_token_secret
 */

/**
 * @typedef { object } Props
 * @prop { Source } source
 * @prop { Param } param
 * @prop { Value } value
 * @prop { State } state
 * @prop { (value: Value) => void } setValue
 * @prop { (state: State) => void } setState
 */

/** @type { React.FC<Props> } */
const SourceOAuth1Param = ({
    source,
    param,
    value,
    state,
    setValue,
    setState,
}) => {

    const { loading, error } = state;
    const connected = !!value;

    /** @type { (evt: MessageEvent) => void } */
    const onMessage = useCallback(async (evt) => {
        // TODO: Add a security verification https://panoply.atlassian.net/browse/APPS-219
        if (evt.data && !evt.data.search) {
            return;
        }

        if (typeof evt.data.search !== 'string') {
            return;
        }

        const params = parseQueryString(evt.data.search, { ignoreQueryPrefix: true });

        if (params.error || params.error_description) {
            const msg = String(params.error_description
                || `Something went wrong (code: ${params.error})`);
            const e = new Error('Oauth error');
            reportError(e, {
                error: msg,
                data: evt.data.search,
                sourceId: source.id,
            });
            setState({ loading: false, error: msg });
            return;
        }

        setState({ ...state, loading: true, error: undefined });

        try {
            const payload = {
                ...source,
                oauth_token: params.oauth_token,
                oauth_verifier: params.oauth_verifier,
                oauth_token_secret: state.oauth_token_secret,
            };
            const { data } = await post('/sources/oauth/v1/access_token', { body: payload });
            setValue(data);
            setState({ ...state, loading: false, error: undefined });
        } catch (err) {
            reportError(err, {
                qs: params,
                sourceId: source.id,
            });
            const errMsg = err.message || err.data.message;
            setState({
                loading: false,
                error: `Could not complete OAuth with the provider due to an error: ${errMsg}`,
            });
        }
    }, [source, state, setState, setValue]);

    const connect = useCallback(async () => {
        if (loading) {
            return;
        }

        try {
            setState({ loading: true });

            const { data } = await post('/sources/oauth/v1/oauth_token', { body: source });
            const { oauth_token, oauth_token_secret } = data;

            setState({
                loading: false,
                oauth_token,
                oauth_token_secret,
            });

            const { oauthAuthorizeURL } = param;
            const url = `${oauthAuthorizeURL}?oauth_token=${oauth_token}`;
            window.open(url, '_blank');
        } catch (err) {
            reportError(err, {
                sourceId: source.id,
            });
            setState({
                loading: false,
                error: 'Something went wrong. The connector blocked access.'
                    + ' Verify your permissions and login again',
            });
        }
    }, [loading, setState, param, source]);

    const disconnect = useCallback(() => {
        setValue(null);
    }, [setValue, source.id]);

    useEffect(() => {
        window.addEventListener('message', onMessage, false);
        return () => window.removeEventListener('message', onMessage);
    }, [onMessage]);

    const vendor = getVendor(source.type);

    return (
        <Layout width="100">
            <Layout flex alignItems="center">
                {connected ? (
                    <Button onClick={disconnect} loading={loading} spacing="ml-0">
                        Disconnect
                    </Button>
                ) : (!vendor ? (
                    <Button color="primary" onClick={connect} loading={loading} spacing="ml-0">
                        {param.cta_title || param.title}
                    </Button>
                ) : (
                    <BrandedButton vendor={vendor} onClick={connect} loading={loading} />
                ))}

                {error && (
                    <Typography spacing="ml-3" color="error">
                        {error}
                    </Typography>
                )}
            </Layout>
            {param.description && (
                <Typography spacing="mt-2 ml-3" color="secondaryText" variant="body2">
                    <MarkdownRenderer source={param.description} />
                </Typography>
            )}
        </Layout>
    );
};

export default SourceOAuth1Param;
