import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Typography, Layout, Loader, Switch, Link, colors } from 'ui-components';

import PageLayout from '../../shared/page-layout';
import PageHeader from '../../shared/page-header';
import useBilling from './use-billing';
import Payment from './payment';
import PlansList from './plans-list';
import EnterprisePlan from './enterprise-plan';
import { pricingTypes } from './use-plan-filters';

import InfoIcon from '../../images/icon-info.svg';

const { monthToMonth, annualMonth } = pricingTypes;

/** @typedef { import('../../app/__types').PageProps } PageProps */
/** @typedef { import('../../app/__types').Plan } Plan */

/** @type { React.FC<PageProps> } */
const Billing = () => {
    const {
        loading,
        billing,
        selectedPlan,
        subscription,
        visiblePlans,
        onSelect,
        onCancel,
        onUpdate,
        pricingType,
        setPricingType,
        showAnnualSwitch,
    } = useBilling();

    if (loading) {
        return (
            <PageLayout>
                <BillingLoader />
            </PageLayout>
        );
    }

    if (selectedPlan.id) {
        return (
            <PageLayout>
                <BillingHeader hideSwitch />
                <Payment
                    plan={selectedPlan}
                    email={subscription.email || ''}
                    name={subscription.name || ''}
                    address={subscription.address}
                    onCancel={onCancel}
                    onUpdate={onUpdate}
                />
            </PageLayout>
        );
    }

    return (
        <>
            <BillingHeader
                currentPlan={billing.plan}
                pricingType={pricingType}
                setPricingType={setPricingType}
                hideSwitch={!showAnnualSwitch}
            />
            <BillingInfoBanner plan={billing.plan} onSelect={onSelect} />
            <PlansList plans={visiblePlans} onSelect={onSelect} />
            <EnterprisePlan spacing="mt-8" />
        </>
    );
};

/**
 * @typedef {object} HeaderProps
 * @prop {Plan?} [currentPlan]
 * @prop {string} [pricingType]
 * @prop {(planType: string) => void} [setPricingType]
 * @prop {boolean} [hideSwitch]
 */

/** @type {React.FC<HeaderProps>} */
const BillingHeader = ({ currentPlan, pricingType, setPricingType, hideSwitch = false }) => {
    const handleSwitch = useCallback((e) => (
        setPricingType?.(e.target.checked ? annualMonth : monthToMonth)
    ), [setPricingType]);

    const isCurrentPlanAnnual = useCallback(() => {
        return currentPlan?.pricingType === pricingTypes.annualMonth;
    }, [currentPlan]);

    return (
        <PageHeader title="Billing Info">
            {pricingType && !hideSwitch && (
                <Layout flex alignItems="center">
                    <Typography color={isCurrentPlanAnnual() ? 'interface' : 'text'}>
                        {(pricingType === monthToMonth
                            ? 'Save 30% with annual billing'
                            : 'Annual billing'
                        )}
                    </Typography>
                    <Switch
                        spacing="-mr-1 pl-2"
                        type="switch"
                        label=""
                        checked={pricingType === annualMonth}
                        onChange={handleSwitch}
                        disabled={isCurrentPlanAnnual()}
                    />
                </Layout>
            )}
        </PageHeader>
    );
};

/** @type {React.FC<{plan?: Plan | null, onSelect: (plan: Plan) => void}>} */
const BillingInfoBanner = ({ plan, onSelect }) => {

    if (!plan) {
        return null;
    }

    return (
        <Banner
            spacing="mb-5 px-3 px-pd-4"
            flex
            alignItems="center"
        >
            <Layout spacing="mr-3" flex alignItems="center">
                <img src={InfoIcon} />
            </Layout>
            <Typography variant="body2" component="span" spacing="my-0">
                Need to update your billing info?&nbsp;
                <Link
                    inline
                    component="span"
                    color="primary"
                    onClick={() => onSelect(plan)}
                >
                    Click here
                </Link>
            </Typography>
        </Banner>
    );
};

const Banner = styled(Layout)`
    border: 1px solid ${colors.interface};
    border-radius: 5px;
    height: 44px;
    background: linear-gradient(180deg, #F2F3F8 0%, #F4F4F4 0.01%, #F2F3F8 100%);
`;

function BillingLoader() {
    return (
        <>
            <BillingHeader hideSwitch />
            <Loader active big />
        </>
    );
}

export default Billing;
