import {
    get,
    put,
    del,
} from '../../lib/ajax';
import { addBreadcrumb } from '../../lib/error-reporter';

import { normalizeViews } from './views';

/** @typedef { import('./__types').DatabaseObject } DatabaseObject */
/** @typedef { import('./__types').Table } Table */
/** @typedef { import('./__types').View } View */
/** @typedef { import('./__types').Query } Query */

/** @type { (table: any) => Table } */
const normalizeTable = table => ({
    ...table,
    type: 'table',
    name: table.current_name,
    metadata: {
        ...table.metadata,
        rows: Number((table.metadata && table.metadata.tbl_rows) || 0),
        size: Number((table.metadata && table.metadata.size) || 0),
    },
    // folders use parent & items use folder so make everything use parent
    parent: table.folder,
});

/** @type { (tables: any[]) => Table[] } */
const normalizeTables = tables => tables.map(normalizeTable);

/** @type { (table: Table) => Promise<Table> } */
export const updateTable = async (table) => {
    try {
        const { data: updatedTable } = await put(`/tables/${encodeURIComponent(table.id)}`, {
            body: table,
            query: {
                $with: 'metadata',
                $withOwner: true,
            },
        });

        return normalizeTable(updatedTable);
    } catch (error) {
        addBreadcrumb('Failed to update a table', { error });

        throw error;
    }
};

/** @type { (table: Table) => Promise<void> } */
export const deleteTable = async (table) => {
    try {
        await del(`/tables/${encodeURIComponent(table.id)}`);

    } catch (error) {
        addBreadcrumb('Failed to delete a table', { error });

        throw error;
    }
};

/** @type { (params?: { query?: Query }) => Promise<Table[]> } */
export const fetchTables = async (params) => {
    const { query } = params || {};

    try {
        const { data: tables } = await get('/tables', { query });

        return normalizeTables(tables);
    } catch (error) {
        addBreadcrumb('Failed to fetch tables', { error });

        throw error;
    }
};

/** @type { (id: string) => Promise<Table> } */
export const fetchTable = async (id) => {
    try {
        const { data: table } = await get(`/tables/${encodeURIComponent(id)}`);

        return normalizeTable(table);

    } catch (error) {
        addBreadcrumb('Failed to fetch a table', { error });

        throw error;
    }
};

/** @type { (table: Table) => Promise<View[]> } */
export const fetchTableDependencies = async (table) => {
    try {
        const { data: fetchedTable } = await get(`/tables/${encodeURIComponent(table.id)}`, {
            query: {
                $withDepends: true,
            },
        });

        if (!fetchedTable.views) {
            return [];
        }

        const dependencies = normalizeViews(fetchedTable.views);

        return dependencies;

    } catch (error) {
        addBreadcrumb('Failed to fetch table dependencies', { error });

        throw error;
    }
};
