/** @type { (message: string, data?: object) => void } */
const info = (message, data) => {
    console.log(message, data); // eslint-disable-line no-console
};

/** @type { (err: Error, data?: object) => void } */
const error = (err, data) => {
    console.error(err, data); // eslint-disable-line no-console
};

export default { info, error };
