import React from 'react';

import InputList from './input-list';

/** @typedef { import('models/sources/__types').ExcludeParam } Param */
/** @typedef { import('models/sources/__types').ExcludeParamValue } Value */

/**
 * @typedef { object } Props
 * @prop { Param } param
 * @prop { Value } value
 * @prop { (value: Value) => void } setValue
*/

/** @type { React.FC<Props> } */
const ExcludeParam = ({
    value,
    setValue,
}) => (
    <InputList
        values={value || []}
        setValues={setValue}
    />
);

export default ExcludeParam;
