import { useCallback, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { fetchDashboards } from '../../models/dashboards';

/** @typedef { import('models/dashboards/types').Dashboard} Dashboard */

/**
 * @typedef { Object } UseDashboardsPage
 * @property { Dashboard[] } dashboards
 * @property { boolean } isLoading
 * @property { boolean } isError
 * @property { () => void } refetch
 * @property { unknown } error
 * @property { (dashboard: Dashboard) => void } addDashboardListItem
 * @property { (dashboard: Dashboard) => void } updateDashboardListItem
 * @property { (id: string) => void } removeDashboardListItem
 * @property { boolean } newDashboardDialogOpen
 * @property { () => void } openNewDashboardDialog
 * @property { () => void } closeNewDashboardDialog
 * @property { boolean } renameDialogOpen
 * @property { () => void } toggleRenameDialog
 * @property { Dashboard } selectedDashboard
 * @property { boolean } deleteDialogOpen
 * @property { () => void } toggleDeleteDialog
 * @property { (dashboard: Dashboard) => void } setSelectedDashboard
 */


function useDashboardsPage() {
    const queryClient = useQueryClient();
    const {
        isLoading,
        isError,
        refetch,
        error,
        data: dashboards = [],
    } = useQuery({ queryKey: ['dashboards'], queryFn: () => fetchDashboards() });

    const [newDashboardDialogOpen, setNewDashboardDialogOpen] = useState(false);
    // const [selectedDashboard, setSelectedDashboard] = useState(null);
    const [renameDialogOpen, setRenameDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [dashboardToDelete, setDashboardToDelete] = useState(null);
    const [dashboardToRename, setDashboardToRename] = useState(null);

    const openNewDashboardDialog = useCallback(() => setNewDashboardDialogOpen(true), []);
    const closeNewDashboardDialog = useCallback(() => setNewDashboardDialogOpen(false), []);

    /** @param { Dashboard } dashboard */
    const toggleRenameDialog = (dashboard) => {
        setDashboardToRename(dashboard);
        setRenameDialogOpen(!renameDialogOpen);
    };

    /** @param { Dashboard } dashboard */
    const toggleDeleteDialog = (dashboard) => {
        setDashboardToDelete(dashboard);
        setDeleteDialogOpen(!deleteDialogOpen);
    };

    /** @param { Dashboard[]} dashboards */
    const setDashboards = (dashboards) => {
        queryClient.setQueryData(['dashboards'], dashboards);
    };

    /** @param { Dashboard} dashboard */
    const addDashboardListItem = useCallback((dashboard) => {
        const newDashboardsState = [...dashboards]?.push(dashboard);
        setDashboards(newDashboardsState);
    }, [dashboards]);

    /** @param { Dashboard} dashboard */
    const updateDashboardListItem = useCallback((dashboard) => {
        const newDashboardsState = dashboards?.map((d) => {
            if (d.id === dashboard.id) {
                return dashboard;
            }
            return d;
        });
        setDashboards(newDashboardsState || []);
    }, [dashboards]);

    /** @param { string} id */
    const removeDashboardListItem = useCallback((id) => {
        const newList = dashboards?.filter((d) => d.id !== id);
        setDashboards(newList || []);
    }, [dashboards]);

    return {
        dashboards,
        error,
        isLoading,
        isError,
        refetch,
        addDashboardListItem,
        updateDashboardListItem,
        removeDashboardListItem,
        newDashboardDialogOpen,
        openNewDashboardDialog,
        closeNewDashboardDialog,
        renameDialogOpen,
        deleteDialogOpen,
        toggleRenameDialog,
        toggleDeleteDialog,
        dashboardToDelete,
        dashboardToRename,
    };
}

export default useDashboardsPage;
