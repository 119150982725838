import React, { useEffect, Fragment } from 'react';
import styled from 'styled-components';

import { Icon, Layout } from 'ui-components';

import ShowNavButton from './show-nav-button';
import NavBreadCrumbItem from './nav-bread-crumb-item';
import { useLocation } from 'react-router-dom';

/** @typedef {import('../__types').NavBreadCrumbItem} NavBreadCrumbItem */

/**
 * @typedef {{
 *  navBreadCrumbItems: NavBreadCrumbItem[];
 *  setShowBreadCrumbNav: (value: boolean) => void;
 * }} Props
 */

const Container = styled(Layout)`
    animation: fadein 0.15s;

    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
`;

/** @type {React.FC<Props>} */
const NavBreadCrumbs = ({ navBreadCrumbItems = [], setShowBreadCrumbNav }) => {
    const location = useLocation();

    useEffect(() => {
        if (!navBreadCrumbItems.length) {
            setShowBreadCrumbNav(false);
        }
    }, [navBreadCrumbItems]);

    return (
        <Container flex alignItems="center">
            <ShowNavButton setShowNav={setShowBreadCrumbNav} />
            <Layout spacing="ml-4" flex alignItems="center">
                {navBreadCrumbItems?.map((el, i) => (
                    <Fragment key={i + el.text}>
                        <NavBreadCrumbItem
                            currentRoute={location.pathname}
                            {...el}
                        />
                        {i < navBreadCrumbItems.length - 1 && (
                            <Icon
                                spacing="mx-1"
                                color="secondaryText"
                                icon="angle-right"
                                size="sm"
                            />
                        )}
                    </Fragment>
                ))}
            </Layout>
        </Container>
    );
};

export default NavBreadCrumbs;
