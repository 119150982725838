/* eslint-disable no-console */
import {
    useState,
    useEffect,
    useRef,
    useCallback,
} from 'react';
import axios from 'axios';
import qs from 'qs';
import { isString } from 'lodash';
import { reportError, addBreadcrumb } from './error-reporter';
import config from '../../config';
import { getCookie } from './cookie';

/** @typedef { { message?: string, [key: string]: any } } ErrorData */
/** @typedef { { ok: false, status: number, data: ErrorData } } AjaxError */
/** @typedef { 'GET' | 'PUT' | 'POST' | 'DELETE' | 'HEAD' } HttpMethod */

/**
 * @typedef { import('axios').AxiosRequestConfig<D> } AxiosRequestConfig
 * @template [D=any]
 */

/**
 * @typedef { { ok: true, status: number, data: T } } AjaxResponse
 * @template [T=any]
 */

/**
 * @typedef { () => Promise<AjaxResponse<T> | AjaxError> } Go
 * @template [T=any]
 */

/**
 * @typedef { {
 *     method?: M,
 *     body?: AxiosRequestConfig<D>['data'],
 *     query?: Record<string, any>,
 *     headers?: AxiosRequestConfig<D>['headers']
 * } } AjaxOptions
 * @template [M=HttpMethod]
 * @template [D=any]
 */

/**
 * @typedef { (path: string, options?: AjaxOptions<M, D>) => Promise<AjaxResponse<T>> } Ajax
 * @template [T=any]
 * @template [M=HttpMethod]
 * @template [D=any]
 */

/** @type { Ajax } */
const ajax = async function ajax(path, options = {}) {

    // Responses from the server can be inconsistent
    // some responses are in json and some in plain text
    // This attempts to normalize the responses as much as possible.
    // Responses should always return {ok, data, status}

    try {
        const response = await axios({
            method: options.method || 'GET',
            url: config.ajax.apiBaseUrl + path,
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': getCookie(config.ajax.csrfCookieName),
                ...options.headers,
            },
            data: options.body,
            params: options.query,
            paramsSerializer: {
                serialize: params => qs.stringify(params),
            },
        });

        return {
            ok: true,
            data: response.data,
            status: response.status,
        };

    } catch (/** @type { any } */ error) {
        addBreadcrumb('Ajax request failed', {
            data: error?.response?.data,
            status: error?.response?.status,
        });

        const errorToSet = formatError(error);

        throw errorToSet;
    }

};


/**
 * @param { any } error
 * @returns { AjaxError }
 */
function formatError(error) {

    // Responses from the server can be inconsistent
    // some responses are in json and some in plain text
    // This attempts to normalize the responses as much as possible.
    // Responses should always return {ok, data, status}
    let status = 0;
    let message = 'Unknown error. We\'re on it.';
    let type;
    let data;

    if (error?.response) {
        status = error.response.status || status;
        type = error.response.data?.type || type;
        data = typeof error.response.data === 'object' ? error.response.data : {};

        switch (status) {
            case 504:
                message = 'Request timeout, please try again.';
                break;
            case 503:
                message = 'There was an error connecting to the server. '
                + 'It\'s just a temporary problem, please try again soon.';
                break;
            case 502:
                message = 'There was an error connecting to the server. '
                + 'It\'s just a temporary problem, please try again.';
                break;
            default:
                message = isString(error.response.data)
                    ? error.response.data || message
                    : error.response.data?.message || message;
                break;
        }
    } else {
        message = 'There was a network error. '
            + 'Please ensure you are connected to the internet.';
    }

    return {
        ok: false,
        status,
        data: {
            ...data,
            message,
            type,
        },
    };
}

/**
 * @typedef { {
 *     data?: T,
 *     loading: boolean,
 *     error?: ErrorData,
 *     clear: () => void,
 *     go: Go<T>
 * } } ReturnData
 * @template [T=any]
 */

/**
 * @typedef { (
 *      endpoint: string,
 *      method?: HttpMethod,
 *      body?: AxiosRequestConfig<D>['data'],
 *      query?: Record<string, any>,
 *      headers?: AxiosRequestConfig<D>['headers']
 * ) => ReturnData<T> } UseRequest
 * @template [T=any]
 * @template [D=any]
 */

/**
 * Hook that wraps the ajax method & adds data to state.
 * Should be refactored to use options object like Ajax
 *
 * @type { UseRequest }
 */
function useRequest(endpoint, method = 'GET', body, query, headers) {
    const [data, setData] = useState(
        /** @type { ReturnData['data'] } */ (undefined)
    );

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(
        /** @type { ReturnData['error'] } */ (undefined)
    );

    // Changing mounted.current doesn't cause a re-render
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true; // Set after first render+mount to DOM
        return () => {
            mounted.current = false;
        }; // Set after unmounting from DOM
    }, []);

    // Doesn't throw | catches errors thrown from ajax | resolves on response
    // Sets any errors caught to state so it can be displayed in the UI
    const go = useCallback(/** @type { Go } */ async () => {
        if (!mounted.current) {
            console.warn('go() called after the component was unmounted');
            return {
                ok: false,
                status: 0,
                data: {
                    message: 'Request cancelled',
                },
            };
        }
        setLoading(true);

        try {
            const response = await ajax(endpoint, {
                method,
                body,
                query: query || {},
                headers: headers || {},
            });
            if (!mounted.current) {
                console.warn('Request resolved after the component was unmounted');
                return response;
            }

            setData(response.data);
            setError(undefined);
            setLoading(false);
            return response;
        } catch (/** @type { any } */ e) {
            if (!mounted.current) {
                console.warn('Request Rejected after the component was unmounted');
                return e;
            }
            reportError(e);
            setError(e.data);
            setLoading(false);
            return e;
        }
    }, [endpoint, method, body, query, headers]);

    const clear = useCallback(() => {
        if (!mounted.current) {
            console.warn('clear() called after the component was unmounted');
            return;
        }

        setData(undefined);
        setError(undefined);
        setLoading(false);
    }, []);

    return {
        data,
        loading,
        error,
        clear,
        go,
    };
}

/**
 * @typedef { Ajax<T, 'GET', D> } Get
 * @template [T=any]
 * @template [D=any]
 */

/** @type { Get } */
const get = (path, options) => ajax(path, { method: 'GET', ...options });

/**
 * @typedef { Ajax<T, 'POST', D> } Post
 * @template [T=any]
 * @template [D=any]
 */

/** @type { Post } */
const post = (path, options) => ajax(path, { method: 'POST', ...options });

/**
 * @typedef { Ajax<T, 'PUT', D> } Put
 * @template [T=any]
 * @template [D=any]
 */

/** @type { Put } */
const put = (path, options) => ajax(path, { method: 'PUT', ...options });

/**
 * @typedef { Ajax<T, 'DELETE', D> } Del
 * @template [T=any]
 * @template [D=any]
 */

/** @type { Del } */
const del = (path, options) => ajax(path, { method: 'DELETE', ...options });

/**
 * @typedef { Ajax<T, 'HEAD', D> } Head
 * @template [T=any]
 * @template [D=any]
 */

/** @type { Head } */
const head = (path, options) => ajax(path, { method: 'HEAD', ...options });

export {
    get,
    post,
    put,
    del,
    head,
    useRequest,
};

export default ajax;
