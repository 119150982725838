import React from 'react';
import { Loader } from 'ui-components';

import AuthLayout from '../../shared/auth/auth-layout';

import CompleteSignupForm from './complete-signup-form';
import CompleteSignupFailure from './complete-signup-failure';
import useCompleteSignupPage from './use-complete-signup-page';

/** @typedef { import('../../app/__types').PageProps } PageProps */

/** @type { React.FC<PageProps> } */
const CompleteSignupPage = ({ token: tokenToValidate }) => {
    const {
        databaseName,
        password,
        username,
        fname,
        databaseType,
        databaseRegion,
        databaseRegionOptions,
        showDatabaseType,
        showDatabaseRegion,
        databaseNameIsValid,
        passwordIsValid,
        usernameIsValid,
        tokenIsValid,
        passwordLabel,
        validateIsLoading,
        completeIsLoading,
        validateError,
        completeError,
        completeIsDisabled,
        signOut,
        onDatabaseNameChanged,
        onPasswordChanged,
        onUsernameChanged,
        toggleShowDatabaseType,
        onDatabaseTypeChanged,
        onDatabaseRegionChanged,
        onDatabaseNameIsValidChanged,
        onPasswordIsValidChanged,
        onUsernameIsValidChanged,
        onCompleteSignupClicked,
        onFormEnterPressed,
    } = useCompleteSignupPage({ tokenToValidate });

    if (validateError) {
        return (
            <AuthLayout title="Oops!">
                <CompleteSignupFailure />
            </AuthLayout>
        );
    }

    if (validateIsLoading) {
        return (
            <AuthLayout title="Loading...">
                <Loader big active />
            </AuthLayout>
        );
    }

    if (!tokenIsValid) {
        return (
            <AuthLayout title="Oops!">
                <CompleteSignupFailure />
            </AuthLayout>
        );
    }

    const formProps = {
        databaseName,
        password,
        username,
        fname,
        databaseType,
        databaseRegion,
        databaseRegionOptions,
        databaseNameIsValid,
        passwordIsValid,
        usernameIsValid,
        passwordLabel,
        completeIsLoading,
        completeError,
        completeIsDisabled,
        showDatabaseType,
        showDatabaseRegion,
        signOut,
        toggleShowDatabaseType,
        onDatabaseNameChanged,
        onPasswordChanged,
        onUsernameChanged,
        onDatabaseTypeChanged,
        onDatabaseRegionChanged,
        onDatabaseNameIsValidChanged,
        onPasswordIsValidChanged,
        onUsernameIsValidChanged,
        onCompleteSignupClicked,
        onFormEnterPressed,
    };

    return (
        <AuthLayout title="Create a Data Warehouse">
            <CompleteSignupForm {...formProps} />
        </AuthLayout>
    );

};

export default CompleteSignupPage;
