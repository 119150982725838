import { stringify } from 'qs';
import { omitBy, isEmpty } from 'lodash';

/**
 * @typedef { object } Location
 * @prop { string } pathname
 * @prop { string } [search]
 * @prop { object } [state]
 */

/**
 * @param { string } pathname
 * @param { object } [query]
 * @param { object } [state]
 * @returns { Location }
 */
function getLink(pathname, query, state = {}) {
    const search = query ? stringify(
        omitBy(query, isEmpty),
        { addQueryPrefix: true },
    ) : '';

    return {
        pathname,
        search,
        state,
    };
}

export default getLink;
