import React, { useState, useEffect, createContext, useContext } from 'react';

/** @typedef {import('../../app/app-header/__types').NavBreadCrumbItem} NavBreadCrumbItem */

/**
 * @type {{
 *  navBreadCrumbItems: NavBreadCrumbItem[],
 *  setNavBreadCrumbItems: (items: NavBreadCrumbItem[]) => void
 * }}
 */
const context = {
    navBreadCrumbItems: [],
    setNavBreadCrumbItems: () => {},
};

const NavBreadCrumbsContext = createContext(context);

/** @type {React.FC<{ children: React.ReactNode }>} */
export const NavBreadCrumbsProvider = ({ children }) => {
    /** @type {NavBreadCrumbItem[]} */
    const initialItems = [];
    const [navBreadCrumbItems, setNavBreadCrumbItems] = useState(initialItems);

    return (
        <NavBreadCrumbsContext.Provider value={{ navBreadCrumbItems, setNavBreadCrumbItems }}>
            {children}
        </NavBreadCrumbsContext.Provider>
    );
};

export const useNavBreadCrumbs = (cleanup = true) => {
    const { navBreadCrumbItems, setNavBreadCrumbItems } = useContext(NavBreadCrumbsContext);

    useEffect(() => (
        cleanup ? () => setNavBreadCrumbItems([]) : () => {}
    ), []);

    return {
        navBreadCrumbItems,
        setNavBreadCrumbItems,
    };
};

export default useNavBreadCrumbs;
