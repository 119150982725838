import React, { useState, useCallback, createContext, useContext } from 'react';

/** @typedef { import('../../app/__types').Feedback } Feedback */

/**
 * @typedef { object } Context
 * @prop { Feedback } feedback
 * @prop { (newFeedback: Feedback ) => void } sendFeedback
 */

/** @type { Feedback } */
const initialFeedback = {};

/** @type { Context } */
const context = {
    feedback: initialFeedback,
    sendFeedback: () => { },
};

const FeedbackContext = createContext(context);

/** @type {React.FC<{ children: React.ReactNode }>} */
export const FeedbackProvider = ({ children }) => {

    const [feedback, setFeedback] = useState(initialFeedback);

    const onClose = useCallback(() => {
        setFeedback(initialFeedback);
    }, []);

    const sendFeedback = useCallback((newFeedback) => {
        if (feedback.open) {
            return;
        }
        setFeedback({ ...newFeedback, open: true });
    }, [feedback]);

    return (
        <FeedbackContext.Provider value={{
            feedback: {
                open: false,
                onClose,
                ...feedback,
            },
            sendFeedback,
        }}>
            {children}
        </FeedbackContext.Provider>
    );
};

const useFeedback = () => useContext(FeedbackContext);

export default useFeedback;
