import { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';

const ResizeObserverFallback = () => {
    return {
        disconnect: () => {},
        observe: () => {},
        unobserve: () => {},
    };
};

/**
 * @typedef { object } Props
 * @prop { number } [borderHeight]
 */

/**
 * @param { Props } [props]
 */
function useResizeObserver(props) {
    const { borderHeight = 1, borderWidth = 1 } = props || {};

    const Observer = window.ResizeObserver || ResizeObserverFallback;

    const [visualizationHeight, setVisualizationHeight] = useState(0);
    const [visualizationWidth, setVisualizationWidth] = useState(0);

    const resizeListenerElemRef = useRef(
        /** @type { HTMLDivElement | null } */ (null)
    );

    const resizeObserverRef = useRef(new Observer(debounce((entries) => {
        window.requestAnimationFrame(() => {
            if (!Array.isArray(entries) || !entries.length) {
                return;
            }

            const elemHeight = entries[0]?.contentRect?.height || 0;
            const elemWidth = entries[0]?.contentRect?.width || 0;

            if (elemHeight + borderHeight !== visualizationHeight) {
                setVisualizationHeight(elemHeight + borderHeight);
            }

            if (elemWidth + borderWidth !== visualizationWidth) {
                setVisualizationWidth(elemWidth + borderWidth);
            }
        });
    }, 200)));

    useEffect(() => {
        const elemRef = resizeListenerElemRef.current;

        if (!elemRef) {
            return () => {
                resizeObserverRef.current.disconnect();
            };
        }

        resizeObserverRef.current.observe(elemRef);

        return () => {
            resizeObserverRef.current.unobserve(elemRef);
        };
    }, [resizeListenerElemRef.current, resizeObserverRef.current]);

    return {
        visualizationHeight,
        visualizationWidth,
        resizeListenerElemRef,
    };
}

export default useResizeObserver;
