import React, { useCallback } from 'react';
import styled from 'styled-components';

import NavBreadCrumbs from './nav-bread-crumbs';

import {
    Layout,
    Typography,
    colors,
    breakpoints,
} from 'ui-components';

/** @typedef { import('./__types').MenuItem } MenuItem */
/** @typedef { import('./__types').NavBreadCrumbItem } NavBreadCrumbItem */

/** @type { (path: string, item: MenuItem) => boolean } */
function isSelected(path, { selected, link }) {
    return path === link || path.includes(selected || link);
}

/**
 * @typedef { object } Props
 * @prop { string } [path='/']
 * @prop { MenuItem[] } [items=[]]
 * @prop { () => void } toggleOpen
 * @prop { boolean } open
 * @prop { NavBreadCrumbItem[] } navBreadCrumbItems
 * @prop { boolean } [showBreadCrumbNav]
 * @prop { (value: boolean) => void } [setShowBreadCrumbNav]
 * @prop { string } [spacing]
 * @prop { boolean } [desktop]
 */

/** @type { React.FC<Props>} */
const HeaderNav = ({
    path = '/',
    items = [],
    toggleOpen,
    open,
    navBreadCrumbItems = [],
    showBreadCrumbNav = true,
    setShowBreadCrumbNav = () => {},
    desktop = true,
    ...props
}) => {

    const onClicked = useCallback(() => {
        if (open) {
            toggleOpen();
        }
    }, [toggleOpen, open]);

    if (showBreadCrumbNav && desktop && navBreadCrumbItems.length) {
        return (
            <NavBreadCrumbs
                navBreadCrumbItems={navBreadCrumbItems}
                setShowBreadCrumbNav={setShowBreadCrumbNav}
            />
        );
    }

    return (
        <ResponsiveMenu open={open} {...props}>
            {items.map((item) => {
                const selected = isSelected(path, item);
                return (
                    <MenuItem
                        key={item.id}
                        selected={selected}
                        link={item.link}
                    >
                        <Typography
                            noWrap
                            component="span"
                            color={selected ? 'primary' : 'text'}
                            hoverColor="primary"
                            spacing="px-2 py-4"
                            onClick={onClicked}
                        >
                            {item.title}
                        </Typography>
                    </MenuItem>
                );
            })}
        </ResponsiveMenu>
    );

};

const ResponsiveMenu = styled(Layout)`
    animation: fadein 0.15s;
    display: ${({ open }) => (open ? 'flex' : 'none')};
    flex: 1 0 auto;
    flex-direction: column;
    box-sizing: border-box;
    background: ${colors.white};
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    width: 100%;
    height: calc(100vh - 54px);
    z-index: 99;
    overflow: auto;

    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    @media (min-width: ${breakpoints.md}) {
        display: flex;
        flex-direction: row;
        position: initial;
        top: initial;
        left: initial;
        width: initial;
        height: initial;
        z-index: initial;
        overflow: hidden;
    }
`;

const MenuItem = styled(({ children, ...props }) => (
    <Typography component="span" {...props}>
        {children}
    </Typography>
))`
    && {
        &:hover, a:hover {
            text-decoration: none;
        }
    }

    box-sizing: border-box;
    max-height: 59px;
    max-width: calc(${breakpoints.sm} / 2);
    border-top: 1px solid transparent;
    border-bottom: 1px solid ${({ selected }) => (selected
        ? colors.primary.main
        : colors.secondaryInterface
    )};

    @media (min-width: ${breakpoints.md}) {
        border-width: 3px;
        border-top-width: 4px;
        border-bottom-color: ${({ selected }) => (selected ? colors.primary.main : 'transparent')};
    }
`;

export default HeaderNav;
