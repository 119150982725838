/* eslint-disable complexity */
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import {
    Button,
    Dialog,
    Layout,
    Divider,
    Typography,
    Switch,
    Loader,
    Avatar,
    Card,
    Callout,
    Icon,
    Tooltip,
} from 'ui-components';

import useManageViewersDialog from './use-manage-viewers-dialog';
import useAvatar from '../../shared/use-avatar';

/** @typedef { import('../../models/database-objects/__types').Table } Table */
/** @typedef { import('../../models/database-objects/__types').View } View */
/** @typedef { import('../../models/users/__types').User } User */
/** @typedef { import('lib/ajax.js').AjaxResponse= } AjaxError */
/** @typedef { import('../../app/__types').Session } Session */

/** @typedef { User & { canView: boolean | 'partial', modified: boolean } } ObjectViewer */
/** @typedef { ObjectViewer[]= } ObjectViewersState */

/**
 * @typedef { object } Props
 * @prop { (Table | View)[] | undefined } objectsToManage
 * @prop { boolean } open
 * @prop { () => void } onClose
 */


/** @type { React.FC<Props> } */
const ViewersDialog = ({ open, onClose, objectsToManage }) => {

    const {
        viewers,
        loading,
        updating,
        loadingError,
        updatingError,
        canInviteUsers,
        disabled,
        finished,
        toggleCanView,
        saveChanges,
        contactSupport,
    } = useManageViewersDialog({ objectsToManage, open });

    useEffect(() => {
        /** @type { (e: KeyboardEvent) => void } */
        const onKeyDown = e => {
            if (e.key === 'Escape' && !loading && !updating) {
                e.preventDefault();
                if (open) {
                    onClose();
                }
            } else if (e.key === 'Enter' && !loading && !updating && !disabled) {
                e.preventDefault();
                if (open) {
                    if (!finished) {
                        saveChanges();
                    } else {
                        onClose();
                    }
                }
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    }, [loading, updating, disabled, finished, open, onClose, saveChanges]);

    if (!objectsToManage) {
        return null;
    }

    const actions = (
        <>
            <Button onClick={onClose} disabled={loading || updating}>
                Close
            </Button>
            {!finished && !!viewers?.length && (
                <Button
                    color="primary"
                    loading={loading || updating}
                    disabled={disabled}
                    onClick={saveChanges}
                    spacing="mr-0"
                >
                    Save Changes
                </Button>
            )}
            {finished && (
                <Button
                    color="primary"
                    onClick={onClose}
                    spacing="mr-0"
                >
                    Finish
                </Button>
            )}
        </>
    );

    const showMainContent = !finished && !loading && !disabled && viewers;

    return (
        <Dialog
            title="Manage Viewers"
            isOpen={open}
            onClose={onClose}
            actions={actions}
        >

            {loading && (
                <Layout spacing="mt-5">
                    <Loader
                        active
                        message="Loading viewers"
                    />
                </Layout>
            )}

            {finished && (
                <Layout>
                    {updatingError ? (
                        <Callout color="error" spacing="mt-3">
                            <Typography component="span" variant="subtitle1" color="text">
                                Oops! We encountered an error when updating permissions.
                                Some permissions may have still been updated
                            </Typography>

                            {updatingError}

                        </Callout>
                    ) : (
                        <>
                            <Typography variant="subtitle1" color="text">
                                Permissions successfully updated on {objectsToManage.length} items.
                            </Typography>
                            <Callout color="default" spacing="mt-3 mb-2">
                                {objectsToManage.map((obj) => (
                                    <Typography key={obj.id}>
                                        {obj.name}
                                    </Typography>
                                ))}
                            </Callout>
                        </>
                    )}
                </Layout>
            )}

            {showMainContent && (
                <>
                    {!!viewers.length && (
                        <>
                            {objectsToManage.length === 1 ? (
                                <Typography variant="body1" spacing="mb-5">
                                    Add or remove viewers of &quot;{objectsToManage[0].name}&quot;
                                </Typography>
                            ) : (
                                <Callout color="warning" spacing="mb-5">
                                    <Typography color="text" weight="semibold" spacing="mb-2">
                                        This will add or remove viewers
                                        for {objectsToManage.length} items:
                                    </Typography>
                                    {objectsToManage.map((obj) => (
                                        <Typography key={obj.id}>
                                            {obj.name}
                                        </Typography>
                                    ))}
                                </Callout>
                            )}
                            <ScrollCard flat contentSpacing="p-4">
                                {viewers && viewers.map((viewer, i) => (
                                    <React.Fragment key={viewer.id}>
                                        <ViewerListItem
                                            viewer={viewer}
                                            toggleCanView={toggleCanView}
                                            disabled={updating}
                                        />
                                        {i !== viewers.length - 1 && <Divider />}
                                    </React.Fragment>
                                ))}
                            </ScrollCard>
                        </>
                    )}

                    {!viewers.length && (
                        <Layout spacing="p-5">
                            <Typography variant="body1" align="center">
                                There are no viewers configured for this database.<br/>
                                {canInviteUsers && (
                                    <Button link="/teams" spacing="mt-4">
                                        Manage Teams
                                    </Button>
                                )}
                            </Typography>
                        </Layout>
                    )}
                </>
            )}

            {!loading && disabled && (
                <Layout spacing="p-5">
                    <Typography variant="subtitle1" align="center">
                        Permissions for this table are managed at the PUBLIC group level.
                    </Typography>
                    <Typography variant="body1" align="center">
                        This may have been done by a warehouse Admin or by an external ETL tool.
                    </Typography>
                    <Typography variant="body1" component="div" align="center">
                        For further assistance&nbsp;
                        <Typography link variant="body1" inline onClick={contactSupport}>
                            contact support
                        </Typography>
                    </Typography>
                </Layout>
            )}

            {loadingError && (
                <Callout color="error" spacing="mt-4 p-2">
                    Oops! An error was encountered while loading object viewers.
                </Callout>
            )}
        </Dialog>
    );
};

/**
 * @typedef { object } ViewerListItemProps
 * @prop { ObjectViewer } viewer
 * @prop { boolean } disabled
 * @prop { (viewerToToggle: ObjectViewer) => void } toggleCanView
 */

/** @type { React.FC<ViewerListItemProps> } */
const ViewerListItem = ({
    viewer, disabled, toggleCanView,
}) => {
    const { initials, avatar } = useAvatar(viewer);

    const onSwitchChanged = useCallback(() => {
        toggleCanView(viewer);
    }, [viewer, toggleCanView]);

    const getLabel = () => {
        if (viewer.canView === true) {
            return 'Can View';
        }
        if (viewer.canView === false) {
            return 'Cannot View';
        }
        return (
            <Tooltip content={
                <>
                    This viewer has access to some of the selected items.
                    Toggling this will sync the viewers access for all selected items
                </>
            }>
                <Layout flex alignItems="center">
                    Partial Access
                    <Icon icon="info-circle" color="secondaryText" spacing="ml-2" />
                </Layout>
            </Tooltip>
        );
    };

    return (
        <Layout key={viewer.id} flex justifyContent="space-between" alignItems="center">
            <Layout flex alignItems="center">
                <Avatar src={avatar}>
                    {initials}
                </Avatar>
                <Layout spacing="ml-3">
                    <Typography weight="medium" variant="body1" spacing="mt-0">
                        {viewer.fname}
                    </Typography>
                    <Typography variant="caption" spacing="mb-0">
                        {viewer.email}
                    </Typography>
                </Layout>
            </Layout>
            <ReverseSwitch
                checked={!!viewer.canView}
                label={getLabel()}
                onChange={onSwitchChanged}
                color="primary"
                spacing="m-0"
                disabled={disabled}
                indeterminate={viewer.canView === 'partial'}
                type="checkbox"
            />
        </Layout>
    );
};

const ScrollCard = styled(Card)`
    &&& {
        max-height: 50vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }
`;

const ReverseSwitch = styled(Switch)`
    flex-direction: row-reverse;
`;

export default ViewersDialog;
