import React from 'react';

import {
    Card,
    Layout,
    Typography,
    Button,
} from 'ui-components';

import {
    formatPeriod,
    formatInterval,
} from './formatters';

import { PlanFeatures } from './plan-info';

const PlansListItem = ({ plan, onClick, ...props }) => (
    <Layout {...props}>
        <Card contentSpacing="pt-0" title={plan.name}>
            <PlanPrice spacing="my-5" plan={plan} />
            <PlanFeatures spacing="mb-2" plan={plan} />
            <SelectButton spacing="m-0 mt-6" plan={plan} onClick={onClick} />
        </Card>
    </Layout>
);

function PlanPrice({ plan, ...props }) {
    if (!plan.details.public || plan.amount === null) {
        return null;
    }
    return (
        <Layout {...props}>
            <Typography variant="h3" inline>
                ${plan.amount || 0}
            </Typography>

            {plan.interval && (
                <Typography variant="h6" inline>
                    /{formatInterval(plan.interval)}
                </Typography>
            )}
            <BillingPeriod plan={plan} />
        </Layout>
    );
}

function SelectButton({ plan, ...props }) {
    return (
        <Button
            width="100"
            color={plan.current ? 'default' : 'primary'}
            leftIcon={plan.current ? 'check' : null}
            disabled={plan.disabled}
            {...props}
        >
            {plan.action || (plan.current ? 'Selected' : 'Select Plan')}
        </Button>
    );
}

function BillingPeriod({ plan, ...props }) {
    if (!plan.details.public || plan.amount === null || !plan.period) {
        return null;
    }

    return (
        <Typography spacing="mt-3" color="secondaryText" {...props}>
            {`Billed ${formatPeriod(plan.period)}`}
        </Typography>
    );
}

export default PlansListItem;
