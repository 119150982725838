import { matchRoutes } from 'react-router-dom';
import { allRoutes } from '../app/use-router';

/** @typedef { import('../app/__types').PageRoute } PageRoute */

/** @type { () => PageRoute | undefined } */
function getCurrentRoute() {
    const path = window.location.hash.substring(1);
    const match = matchRoutes(allRoutes, path)?.[0];

    if (!match) {
        return undefined;
    }

    const { params, route } = match;

    if (route.path?.endsWith('*')) {
        return {
            ...route,
            path: route.path.replace('*', params['*'] || '*'),
        };
    }

    return route;
}


export { getCurrentRoute };
