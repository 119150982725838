import React, { useCallback } from 'react';
import {
    DebouncedInput,
    Layout,
} from 'ui-components';
import MarkdownRenderer from '../../../shared/markdown-renderer';

/** @typedef { import('models/sources/__types').PastDaysParam } Param */
/** @typedef { import('models/sources/__types').PastDaysParamValue } Value */

/**
 * @typedef { object } Props
 * @prop { Param } param
 * @prop { Value } value
 * @prop { (value: Value) => void } setValue
*/

/** @type { React.FC<Props> } */
const PastDaysParam = ({
    param,
    value,
    setValue,
}) => {
    const onPastDaysChanged = useCallback((e) => {
        setValue(e.target.value);
    }, [setValue]);

    return (
        <Layout relative width="50">
            <DebouncedInput
                spacing="p-0"
                name="pastDays"
                value={value || ''}
                label="Example= 60"
                type="number"
                width="100"
                noFloatLabel
                onChange={onPastDaysChanged}
                helperText={param.description && (
                    <MarkdownRenderer source={param.description} />
                )}
            />
        </Layout>
    );
};

export default PastDaysParam;
