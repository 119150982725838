import { trackEvent } from '../../models/tracking';

/** @type { (email: string, fname: string, lname: string) => void } */
function trackSignupStarted(email, fname, lname) {
    const props = {
        type: 'signupStarted',
        email,
        fname,
        lname,
        platform_type: 'aws',
        signup_type: 'regular',
    };

    trackEvent({
        name: 'Signup: Email Confirmation Step',
        type: 'log',
        data: props,
    });
}


export {
    trackSignupStarted,
};
