import React from 'react';

import AuthLayout from '../../shared/auth/auth-layout';
import AuthLinks from '../../shared/auth/auth-links';
import Experiment, { Variant } from '../../shared/experiment';

import SignupForm from './signup-form';
import SignupBadges from './signup-badges';
import SignupSuccess from './signup-success';
import useSignupPage from './use-signup-page';

/** @typedef { import('../../app/__types').PageProps } PageProps */

/** @type { React.FC<PageProps> } */
const SignupPage = ({ email: initialEmail }) => {
    const {
        firstName,
        lastName,
        email,
        firstNameIsValid,
        lastNameIsValid,
        emailIsValid,
        emailIsAllowed,
        loading,
        disabled,
        error,
        signupIsSuccess,
        resendIsSuccess,
        showLoginWarning,
        onFirstNameChanged,
        onLastNameChanged,
        onEmailChanged,
        onSignupClicked,
        onResendClicked,
        onEnterPressed,
    } = useSignupPage({ initialEmail });

    if (signupIsSuccess || resendIsSuccess) {
        const successProps = {
            email, resendIsSuccess, loading, onResendClicked,
        };
        return (
            <AuthLayout title="Confirmation Email Sent!">
                <SignupSuccess {...successProps} />
            </AuthLayout>
        );
    }

    const formProps = {
        firstName,
        lastName,
        email,
        firstNameIsValid,
        lastNameIsValid,
        emailIsValid,
        emailIsAllowed,
        loading,
        disabled,
        error,
        showLoginWarning,
        onFirstNameChanged,
        onLastNameChanged,
        onEmailChanged,
        onSignupClicked,
        onEnterPressed,
    };

    const linksProps = {
        email,
        showTermsLink: true,
        showPrivacyLink: true,
        showLoginLink: true,
    };

    return (
        <AuthLayout title="Sign Up">
            <SignupForm {...formProps} />
            <Experiment
                name="Signup Badges Experiment"
                id="o90f3S26TGaaow5OKS41QA"
            >
                <Variant version={0} name="Original">
                    {/* Show no badges for default variant */}
                </Variant>
                <Variant version={1} name="With Badges">
                    <SignupBadges />
                </Variant>
            </Experiment>
            <AuthLinks {...linksProps} />
        </AuthLayout>
    );
};

export default SignupPage;
