import { get, post, put, del } from '../../lib/ajax';
import { addBreadcrumb } from '../../lib/error-reporter';

/** @typedef { import('./types').CreateDashboardItem } CreateDashboardItem */
/** @typedef { import('./types').FetchDashboardItem } FetchDashboardItem */
/** @typedef { import('./types').FetchDashboardItems } FetchDashboardItems */
/** @typedef { import('./types').EditDashboardItem } EditDashboardItem */
/** @typedef { import('./types').RemoveDashboardItem } RemoveDashboardItem */

/** @type { CreateDashboardItem } */
export const createDashboardItem = async (dashboardId, params) => {
    const { widget, position } = params;

    const dashId = encodeURIComponent(dashboardId);

    try {
        const { data: item } = await post(`/dashboards/${dashId}/items`, {
            body: {
                widget,
                position,
            },
        });

        return item;

    } catch (err) {
        addBreadcrumb('Failed to create a dashboard item', { err });

        throw err;
    }
};

/** @type { FetchDashboardItem } */
export const fetchDashboardItem = async (id, dashboardId) => {
    const itemId = encodeURIComponent(id);
    const dashId = encodeURIComponent(dashboardId);

    try {
        const { data: item } = await get(`/dashboards/${dashId}/items/${itemId}`);

        return item;

    } catch (err) {
        addBreadcrumb('Failed to fetch a dashboard item', { err });

        throw err;
    }
};

/** @type { FetchDashboardItems } */
export const fetchDashboardItems = async (dashboardId) => {
    const dashId = encodeURIComponent(dashboardId);

    try {
        const { data: items } = await get(`/dashboards/${dashId}/items`);

        return items;

    } catch (err) {
        addBreadcrumb('Failed to fetch dashboard items', { err });

        throw err;
    }
};

/** @type { EditDashboardItem } */
export const editDashboardItem = async (id, dashboardId, params) => {
    const { widget, position } = params;

    const itemId = encodeURIComponent(id);
    const dashId = encodeURIComponent(dashboardId);

    try {
        const { data: item } = await put(`/dashboards/${dashId}/items/${itemId}`, {
            body: {
                widget,
                position,
            },
        });

        return item;

    } catch (err) {
        addBreadcrumb('Failed to edit a dashboard item', { err });

        throw err;
    }
};

/** @type { RemoveDashboardItem } */
export const removeDashboardItem = async (id, dashboardId) => {
    const itemId = encodeURIComponent(id);
    const dashId = encodeURIComponent(dashboardId);

    try {
        await del(`/dashboards/${dashId}/items/${itemId}`);

    } catch (err) {
        addBreadcrumb('Failed to remove a dashboard item', { err });

        throw err;
    }
};
