import React, { useMemo, useState, useCallback } from 'react';

import {
    Layout,
    Typography,
    SpreadsheetCard,
    Loader,
} from 'ui-components';

import { isNumericColumn } from '../../models/columns';
import WorkbenchTable from './workbench-table';
import useResizeObserver from './use-resize-observer';
import AliasDialog from './alias-dialog';
import FilterDialog, { isSameColumn } from './filter-dialog';
import OrderDialog from './order-dialog';

/** @typedef { import('lib/query-builder').Column } Column */
/** @typedef { import('lib/query-builder').Position } Position */
/** @typedef { import('lib/query-builder').Filter } Filter */
/** @typedef { import('lib/query-builder').Order } Order */
/** @typedef { import('models/queries/__types').QueryResultData } QueryResultData */
/** @typedef { import('./workbench-table').Props['onDrop'] } DropHandler */
/** @typedef { import('./workbench-table').Node } Node */
/** @typedef { import('./__types').Action<TableColumn> } Action */
/** @typedef { import('./__types').Feature<TableColumn> } Feature */
/** @typedef { import('./__types').Column } TableColumn */

/**
 * @typedef { object } Props
 * @prop { boolean } queryIsRunning
 * @prop { string } [queryError]
 * @prop { QueryResultData } [queryData]
 * @prop { (column: Omit<Column, 'id'>, position?: Position) => void } addColumn
 * @prop { (position: Position) => void } removeColumn
 * @prop { (from: Position, to: Position) => void } moveColumn
 * @prop { (position: Position) => void } toggleColumn
 * @prop { (position: Position, alias: NonNullable<Column['alias']>) => void } changeAlias
 * @prop { (position: Position, newFunction: Column['function']) => void } changeFunction
 * @prop { (where: Filter[], having: Filter[]) => void } changeFilter
 * @prop { (orderBy: Order[]) => void } changeOrder
 * @prop { boolean } hideHidden
 * @prop { boolean } someHidden
 * @prop { Column[] } allColumns
 * @prop { { where: Filter[], having: Filter[] } } queryFilter
 * @prop { Order[] } queryOrder
 * @prop { () => void } clearColumns
 * @prop { () => void } toggleHidden
 */

/** @type { React.FC<Props> } */
const WorkbenchQueryBuilder = ({
    queryIsRunning,
    queryError,
    queryData,
    hideHidden,
    allColumns,
    queryFilter,
    queryOrder,
    addColumn,
    removeColumn,
    moveColumn,
    toggleColumn,
    clearColumns,
    changeAlias,
    changeFunction,
    changeFilter,
    changeOrder,
}) => {
    const [columnToAlias, setColumnToAlias] = useState(
        /** @type { { column?: Column, position?: Position} } */ ({}),
    );

    const [columnToFilter, setColumnToFilter] = useState(
        /** @type { Column= } */ (undefined),
    );

    const [columnToOrder, setColumnToOrder] = useState(
        /** @type { Column= } */ (undefined),
    );

    const {
        visualizationHeight,
        resizeListenerElemRef,
    } = useResizeObserver();

    /** @type { DropHandler } */
    const onDrop = (to, from, node) => {
        if (node && isColumnNode(node)) {
            const position = to !== undefined ? to + 1 : 'last';

            addColumn(node, position);
        }

        if (from !== undefined && to !== undefined) {
            const fromIndex = Math.max(from, 0);
            const toIndex = Math.max(to, 0);

            if (fromIndex !== toIndex) {
                moveColumn(fromIndex, toIndex);
            }
        }
    };

    /** @type { (column: Column, position: Position) => void } */
    const openAliasDialog = useCallback((column, position) => {
        setColumnToAlias({ column, position });
    }, []);

    const closeAliasDialog = useCallback(() => {
        setColumnToAlias({});
    }, []);

    /** @type { (column: Column) => void } */
    const openFilterDialog = useCallback((column) => {
        setColumnToFilter(column);
    }, []);

    const closeFilterDialog = useCallback(() => {
        setColumnToFilter(undefined);
    }, []);

    /** @type { (column: Column) => void } */
    const openOrderDialog = useCallback((column) => {
        setColumnToOrder(column);
    }, []);

    const closeOrderDialog = useCallback(() => {
        setColumnToOrder(undefined);
    }, []);

    const columns = useMemo(() => {
        const filteredColumns = allColumns
            .map((column, position) => ({ column, position }))
            .filter(({ column }) => !hideHidden || !column.hidden);

        /** @type { (position: number) => number } */
        const resolveIndex = position => (
            filteredColumns.findIndex(c => c.position === position)
        );

        /** @type { (position: number, step: number) => number } */
        const getMovePosition = (position, step) => {
            const index = resolveIndex(position);
            return filteredColumns[index + step]?.position ?? position;
        };

        /** @type { (column: Column, position: number, title?: string) => Action[] } */
        const getFunctions = (column, position, title) => {
            const FUNCTIONS = /** @type { Array<NonNullable<Column['function']>> } */ ([
                ...(isNumericColumn(column.dataType) ? ['sum'] : []),
                'max',
                'min',
                ...(isNumericColumn(column.dataType) ? ['avg'] : []),
                'count',
                'count(distinct)',
            ]);

            /** @type { Action[] } */
            const functions = FUNCTIONS.map(f => ({
                label: f.toUpperCase(),
                checked: column.function === f,
                onClick: () => changeFunction(
                    position,
                    column.function !== f ? f : undefined
                ),
            }));

            functions[0].title = title;

            return functions;
        };

        /** @type { (column: Column) => boolean } */
        const hasFilter = column => Object.values(queryFilter).some(filters => (
            filters.some(f => isSameColumn(f.column, column))
        ));

        /** @type { (column: Column, position: number, index: number) => Action[] } */
        const getActions = (column, position, index) => ([
            ...getFunctions(column, position, 'Functions'),
            {
                label: hasFilter(column) ? 'Edit Filter' : 'Filter',
                iconName: 'filter',
                onClick: () => openFilterDialog(column),
                divider: true,
            },
            {
                label: queryOrder.some(o => o.id === column.id) ? 'Edit Order' : 'Order',
                iconName: 'arrow-down-arrow-up',
                onClick: () => openOrderDialog(column),
            },
            {
                label: column.hidden ? 'Unhide column' : 'Hide column',
                iconName: column.hidden ? 'eye' : 'eye-slash',
                onClick: () => toggleColumn(position),
            },
            {
                label: 'Alias',
                iconName: 'square-quote',
                onClick: () => openAliasDialog(column, position),
            },
            ...(filteredColumns.length > 1 && index !== 0 ? [{
                label: 'Move column left',
                iconName: 'arrow-left-from-line',
                onClick: () => moveColumn(position, getMovePosition(position, -1)),
            }] : []),
            ...(filteredColumns.length > 1 && index !== (filteredColumns.length - 1) ? [{
                label: 'Move column right',
                iconName: 'arrow-right-from-line',
                onClick: () => moveColumn(position, getMovePosition(position, 1)),
            }] : []),
            {
                label: 'Duplicate column',
                iconName: 'clone',
                onClick: () => addColumn(column, position + 1),
            },
            {
                label: 'Remove column',
                iconName: 'trash',
                onClick: () => {
                    if (allColumns.length === 1) {
                        clearColumns();
                    } else {
                        removeColumn(position);
                    }
                },
            },
        ]);

        /** @type { (column: Column, position: number, index: number) => Feature[] } */
        const getFeatures = (column, position, index) => {
            /** @type { Feature[] } */
            const features = [];

            if (column.function) {
                features.push({
                    label: 'Functions',
                    iconName: 'sigma',
                    onClick: () => {}, // noop
                    actions: getFunctions(column, position),
                });
            }

            if (hasFilter(column)) {
                features.push({
                    label: 'Edit Filter',
                    iconName: 'filter',
                    onClick: () => openFilterDialog(column),
                });
            }

            const order = queryOrder.find(o => o.id === column.id);

            if (order) {
                features.push({
                    label: 'Edit Order',
                    iconName: order.direction === 'asc' ? 'arrow-up' : 'arrow-down',
                    onClick: () => openOrderDialog(column),
                });
            }

            if (column.hidden) {
                features.push({
                    label: 'Unhide column',
                    iconName: 'eye-slash',
                    onClick: () => toggleColumn(index),
                });
            }

            return features;
        };

        /** @type { TableColumn[] } */
        return filteredColumns.map(({ column, position }, index) => ({
            id: column.id,
            Header: column.alias || column.name,
            actions: getActions(column, position, index),
            features: getFeatures(column, position, index),
            faded: column.hidden,
            placeholder: column.hidden ? '** Hidden **' : '',
            tooltip: [
                column.schema,
                column.table,
                column.name,
            ].join('.'),
            position,
        }));
    }, [
        allColumns,
        hideHidden,
        queryFilter,
        queryOrder,
        addColumn,
        moveColumn,
        removeColumn,
        toggleColumn,
        clearColumns,
        changeFunction,
        openAliasDialog,
        openFilterDialog,
        openOrderDialog,
    ]);

    const tableProps = !!queryData?.rows?.length && columns.length > 0 && {
        queryData,
        visualizationHeight,
        columns,
        onDrop,
        noSort: true,
        virtualized: true,
    };

    const isLoadingData = () => {
        return !tableProps
            && (queryIsRunning || (allColumns.length > 0 && !queryData))
            && !queryError;
    };

    return (
        <Container spacing="p-4">
            <SpreadsheetCard forwardedRef={resizeListenerElemRef} onDrop={onDrop}>
                {tableProps && (
                    <WorkbenchTable {...tableProps} />
                )}

                {!tableProps && !queryIsRunning && (
                    <CallToAction
                        allColumns={allColumns}
                        queryError={queryError}
                        queryData={queryData}
                    />
                )}

                <LoaderOverlay
                    message="Loading data"
                    active={isLoadingData()}
                />
            </SpreadsheetCard>

            <AliasDialog
                key={columnToAlias?.column?.id}
                column={columnToAlias?.column}
                position={columnToAlias?.position}
                allColumns={allColumns}
                changeAlias={changeAlias}
                onClose={closeAliasDialog}
            />

            <FilterDialog
                key={columnToFilter?.id}
                column={columnToFilter}
                allColumns={allColumns}
                queryFilter={queryFilter}
                changeFilter={changeFilter}
                onClose={closeFilterDialog}
            />

            <OrderDialog
                key={columnToOrder?.id}
                column={columnToOrder}
                allColumns={allColumns}
                queryOrder={queryOrder}
                changeOrder={changeOrder}
                onClose={closeOrderDialog}
            />
        </Container>
    );
};

/** @type { React.FC<{ children: React.ReactNode, bgColor?: string, spacing?: string }> } */
const Container = ({ children, ...props }) => (
    <Layout
        flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100"
        height="100"
        {...props}
    >
        {children}
    </Layout>
);

/** @type { React.FC<{ message: string, active: boolean }> } */
const LoaderOverlay = ({ active, message }) => (
    <Loader
        big
        absolute
        message={message}
        active={active}
    />
);

/**
 * @typedef { object } CallToActionProps
 * @prop { Column[] } allColumns
 * @prop { string } [queryError]
 * @prop { QueryResultData } [queryData]
 */

/** @type { React.FC<CallToActionProps> } */
const CallToAction = ({ allColumns, queryError, queryData }) => {
    const text = useMemo(() => {
        if (allColumns.length > 0) {
            if (queryError) {
                return 'Failed to run a query';
            }

            if (queryData) {
                return 'No data to display';
            }

            return '';
        }

        return 'To get started, drag and drop or right-click columns from the workbench sidebar';
    }, [allColumns, queryData, queryError]);

    if (!text) {
        return null;
    }

    return (
        <Container>
            <Typography color="secondaryText" align="center" spacing="p-4">
                {text}
            </Typography>
        </Container>
    );
};

/** @type { (node: Node) => node is Column } */
const isColumnNode = (node) => {
    return node?.type === 'column';
};

export default WorkbenchQueryBuilder;
