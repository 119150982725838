import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { pickBy } from 'lodash';

import {
    fetchDashboard,
    editDashboard,
    removeDashboard,
    createDashboardItem,
    editDashboardItem,
    removeDashboardItem,
} from '../../models/dashboards';

import { useNavBreadCrumbs, useSession } from '../../shared/context-providers';
import DashboardIcon from './dashboard-icon';

/** @typedef { import('models/reports').Report } Report */
/** @typedef { import('models/dashboards').DashboardItem } DashboardItem */
/** @typedef { import('./types').Mode } Mode */
/** @typedef { import('./types').Layout } Layout */
/** @typedef { import('./types').DroppingItem } DroppingItem */
/** @typedef { import('./types').SharingItem } SharingItem */

/**
 * @typedef { import('lodash').Dictionary<T> } Dictionary
 * @template T
 */

/** @type { <T>(obj: Dictionary<T>) => Dictionary<T> } */
function removeEmpty(obj) {
    return pickBy(obj, v => v !== undefined);
}

/** @type { () => string } */
function createItemId() {
    return `new-${uuidv4()}`;
}

function useDashboardPage() {
    const { id } = useParams();
    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const { setNavBreadCrumbItems } = useNavBreadCrumbs();

    const [session] = useSession();
    const { user, database } = session;

    const [mode, setMode] = useState(/** @type { Mode } */ ('view'));
    const [layout, setLayout] = useState(/** @type { Layout[] } */ ([]));

    const [droppingItem, setDroppingItem] = useState(
        /** @type { DroppingItem= } */ (undefined)
    );

    const [droppingReportId, setDroppingReportId] = useState(
        /** @type { Report['id']= } */ (undefined)
    );

    const [sharingItem, setSharingItem] = useState(
        /** @type { SharingItem= } */ (undefined)
    );

    const [saveDialogOpen, setSaveDialogOpen] = useState(false);
    const [renameDialogOpen, setRenameDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [reportsDropdownOpen, setReportsDropdownOpen] = useState(false);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);

    const editable = mode === 'edit';

    const position = useMemo(() => {
        // Calculate the position and dimensions of the new item
        const { y, w, h } = layout.reduce((acc, cur) => {
            const pos = { ...acc };

            const newY = cur.y + cur.h;

            // Y-axis
            if (pos.y < newY) {
                pos.y = newY;
            }

            // Width
            if (pos.w > cur.w) {
                pos.w = cur.w;
            }

            // Height
            if (pos.h > cur.h) {
                pos.h = cur.h;
            }

            return pos;
        }, { x: 0, y: 0, w: 12, h: 10 });

        return {
            x: 0,
            y,
            w,
            h,
        };
    }, [layout]);

    const {
        data: refreshedAt = 0,
        refetch: refresh,
    } = useQuery({
        queryKey: ['dashboard-refresh', id],
        queryFn: () => {
            if (!id) {
                return undefined;
            }

            return Date.now();
        },
        staleTime: 1000 * 60 * 59, // 59 minutes
    });

    const {
        data: dashboard,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ['dashboard', id],
        queryFn: async () => {
            if (!id) {
                return undefined;
            }

            const dashboard = await fetchDashboard(id, { withItems: true });

            const { items = [] } = dashboard;

            if (items.length === 0) {
                setMode('edit');
                setReportsDropdownOpen(true);
            }

            return dashboard;
        },
    });

    /** @type { (newMode: Mode) => void } */
    const changeMode = (newMode) => {
        setMode(newMode);
    };

    /** @type { (newLayout: Layout[]) => void } */
    const changeLayout = (newLayout) => {
        setLayout(newLayout);
    };

    /** @type { (reportId?: Report['id']) => void } */
    const changeDroppingReportId = (reportId) => {
        setDroppingReportId(reportId);
        setDroppingItem(reportId ? {
            i: createItemId(),
            w: position.w,
            h: position.h,
        } : undefined);
    };

    const toggleSaveDialog = useCallback(() => {
        setSaveDialogOpen(open => !open);
    }, []);

    const toggleRenameDialog = useCallback(() => {
        setRenameDialogOpen(open => !open);
    }, []);

    const toggleDeleteDialog = useCallback(() => {
        setDeleteDialogOpen(open => !open);
    }, []);

    const toggleReportsDropdown = useCallback(() => {
        setReportsDropdownOpen(open => !open);
    }, []);

    const closeReportsDropdown = useCallback(() => {
        setReportsDropdownOpen(false);
    }, []);

    /** @type { (sharingItem?: SharingItem) => void } */
    const toggleShareDialog = useCallback((sharingItem) => {
        setShareDialogOpen(open => {
            setSharingItem(open ? undefined : sharingItem);
            return !open;
        });
    }, []);

    const saveDashboard = async () => {
        if (!dashboard?.id || !dashboard.items) {
            return;
        }

        const items = dashboard.items.map(item => {
            const { x, y, w, h } = layout.find(l => l.i === item.id) || {};

            return {
                ...item,
                position: {
                    ...item.position,
                    ...removeEmpty({
                        x,
                        y,
                        w,
                        h,
                    }),
                },
            };
        });

        const newItems = await Promise.all(
            items.map(async ({ id, widget, position }) => {
                if (id.startsWith('new')) {
                    return createDashboardItem(dashboard.id, {
                        widget,
                        position,
                    });
                }

                if (position.x === -1 && position.y === -1) {
                    return removeDashboardItem(id, dashboard.id);
                }

                return editDashboardItem(id, dashboard.id, {
                    position,
                });
            })
        );

        await editDashboard(dashboard.id, { title: dashboard.title });

        const filteredItems = newItems.filter(item => item !== undefined);

        queryClient.setQueryData(['dashboard', dashboard.id], {
            ...dashboard,
            items: filteredItems,
        });

        if (filteredItems.length === 0) {
            setReportsDropdownOpen(true);
        } else {
            setMode('view');
        }
    };

    /** @type { (title: string) => Promise<void> } */
    const renameDashboard = async (title) => {
        if (!dashboard?.id) {
            return;
        }

        queryClient.setQueryData(['dashboard', dashboard.id], {
            ...dashboard,
            title,
        });
    };

    const deleteDashboard = async () => {
        if (!dashboard?.id) {
            return;
        }

        await removeDashboard(dashboard.id);

        navigate('/dashboards');
    };

    /** @type { (id: DashboardItem['id']) => Promise<void> } */
    const deleteDashboardItem = async (id) => {
        if (!dashboard?.id || !dashboard.items) {
            return;
        }

        const newItems = id.startsWith('new')
            ? dashboard.items.filter(item => item.id !== id)
            : dashboard.items.map(item => {
                if (item.id === id) {
                    return {
                        ...item,
                        position: {
                            x: -1,
                            y: -1,
                        },
                    };
                }

                return item;
            });

        queryClient.setQueryData(['dashboard', dashboard.id], {
            ...dashboard,
            items: newItems,
        });

        const filteredItems = newItems.filter(({ position: { x, y } }) => (
            x !== -1 && y !== -1
        ));

        if (filteredItems.length === 0) {
            setReportsDropdownOpen(true);
        }
    };

    /** @type { (item: Layout) => void } */
    const addDroppedItem = (item) => {
        if (!droppingReportId || droppingItem?.i !== item.i) {
            return;
        }

        addDashboardItem(droppingReportId, item);
        changeDroppingReportId(undefined);
    };

    /** @type { (id: Report['id'], droppedItem?: Layout) => void } */
    const addDashboardItem = (id, droppedItem) => {
        if (!dashboard?.id) {
            return;
        }

        const createdBy = user.id || dashboard.createdBy;
        const createdAt = new Date().toISOString();

        const { i, x, y, w, h } = { ...position, ...droppedItem };

        /** @type { DashboardItem } */
        const item = {
            id: i || createItemId(),
            dashboardId: dashboard.id,
            databaseId: database.id || dashboard.databaseId,
            widget: {
                type: 'report-ref',
                id,
            },
            position: {
                x,
                y,
                w,
                h,
            },
            createdBy,
            createdAt,
            updatedBy: createdBy,
            updatedAt: createdAt,
        };

        const { items = [] } = dashboard;

        queryClient.setQueryData(['dashboard', dashboard.id], {
            ...dashboard,
            items: [...items, item],
        });
    };

    useEffect(() => {
        if (dashboard) {
            setNavBreadCrumbItems([
                {
                    text: 'Dashboards',
                    link: '/dashboards',
                },
                {
                    text: dashboard.title,
                    titleTooltip: dashboard.title,
                    icon: <DashboardIcon />,
                    iconTooltip: dashboard.title,
                },
            ]);
        }
    }, [dashboard]);

    return {
        layout,
        editable,
        dashboard,
        droppingItem,
        sharingItem,
        isLoading,
        isError,
        refreshedAt,
        saveDialogOpen,
        renameDialogOpen,
        deleteDialogOpen,
        shareDialogOpen,
        reportsDropdownOpen,
        refresh,
        changeMode,
        changeLayout,
        changeDroppingReportId,
        toggleSaveDialog,
        toggleRenameDialog,
        toggleDeleteDialog,
        toggleReportsDropdown,
        closeReportsDropdown,
        toggleShareDialog,
        saveDashboard,
        renameDashboard,
        deleteDashboard,
        deleteDashboardItem,
        addDashboardItem,
        addDroppedItem,
    };
}

export {
    removeEmpty,
};

export default useDashboardPage;
