import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * @typedef { object } Props
 * @prop { string } [title]
 */

/** @type { React.FC<Props> } */
const PageHelmet = ({ title }) => (
    <Helmet>
        <title>{title ? `${title} | Panoply` : 'Panoply'}</title>
    </Helmet>
);

export default PageHelmet;
