import { get, post } from '../../lib/ajax';
import { addBreadcrumb } from '../../lib/error-reporter';
import { parseTemplate } from '../../lib/parse-template';
import { getDefaultSchema } from '../schemas';
import { isEqual } from 'lodash';

/** @typedef { import('app/__types').Database } Database */
/** @typedef { import('../sources').Source } Source */
/** @typedef { import('../sources').SourceType } SourceType */
/** @typedef { import('../sources').SourceDefaults } SourceDefaults */
/** @typedef { import('./types').Recipe } Recipe */
/** @typedef { import('./types').RunErrorData } RunErrorData */

/** @type { (sourceTypeIds?: string[]) => Promise<Recipe[]> } */
export const fetchRecipes = async (sourceTypeIds) => {
    try {
        const { data: recipes } = await get('/recipes', {
            query: {
                sourceTypeIds,
            },
        });
        return recipes;
    } catch (error) {
        addBreadcrumb('Failed to fetch recipes', { error });
        throw error;
    }
};

/** @type { (sql: string, dry?: boolean) => Promise<any> } */
export const runRecipeQuery = async (sql, dry = false) => {
    try {
        const { data: recipes } = await post('/recipes/run', {
            body: {
                sql,
                dry,
            },
        });
        return recipes;
    } catch (/** @type { any } */ error) {
        addBreadcrumb('Failed to run recipe query', { error });
        throw error;
    }
};

/**
 * @typedef { object } Overrides
 * @prop { string= } schema
 * @prop { string= } prefix
 */
/** @type {(d: string, r: Recipe, defaults: SourceDefaults[], overrides: Overrides) => string } */
export const parseDefinition = (definition, recipe, sourceDefaults, userOverrides) => {

    if (!Array.isArray(sourceDefaults) || sourceDefaults.length === 0) {
        throw new Error('sourceDefaults must be of type array with at least 1 element');
    }

    /**
     * These are the default fields, it is used when running an older single-source
     * recipe. since older single-source recipes still uses 'src_schema' and 'src_prefix' fields
     * in their sql statments,this is the reason we have to provide those fields, to allow backwards
     * compatability.
     */
    const srcSchema = sourceDefaults[0].schema || getDefaultSchema(recipe.warehouse);
    const srcPrefix = sourceDefaults[0].prefix || '';

    const destinationDefaults = recipe.defaults?.destination || {};

    const dstSchema = userOverrides?.schema || destinationDefaults?.schema || srcSchema;
    const dstPrefix = userOverrides?.prefix || destinationDefaults?.prefix || '';

    /** @type { Record<string, string> } */
    const fieldsMap = {
        src_schema: srcSchema,
        src_prefix: srcPrefix,
        dst_schema: dstSchema,
        dst_prefix: dstPrefix,
    };

    /**
     * This loop adds the relevant fields in case we use a newer version of
     * recipe, where we do not use 'src_schema' and 'src_prefix' since we change
     * it to to specific field names to support usage of multi-sources in the
     * SQL and the need to identify each field in order to compose correct SQL
     * statment.
     */
    sourceDefaults.forEach(s => {
        fieldsMap[`${s.type}_schema`] = s.schema || getDefaultSchema(recipe.warehouse);
        fieldsMap[`${s.type}_prefix`] = s.prefix || '';
    });

    return parseTemplate(definition, fieldsMap);
};

/**
 * Checks if a list of sources meets a recipes requirements
 * @type { (recipe: Recipe, sources: Source[]) => boolean }
 */
export const recipeMatchesSources = (recipe, sources) => {

    /** @type { SourceType['id'][] } */
    const requiredSourceTypes = recipe.requirements.reduce((acc, requirement) => {
        if (requirement.type === 'source') {
            return [...acc, requirement.id];
        }
        return acc;
    }, /** @type {SourceType['id'][]} */([])).sort();
    const selectedSourceTypes = sources.map(source => source.type).sort();

    return isEqual(requiredSourceTypes, selectedSourceTypes);
};
