import React from 'react';
import {
    Dialog,
    Typography,
    Button,
} from 'ui-components';

/**
 * @typedef { object } Props
 * @prop { string } title
 * @prop { boolean } isOpen
 * @prop { () => Promise<void> } deleteSource
 * @prop { string= } error
 * @prop { boolean } loading
 * @prop { () => void } onClose
 */

/** @type { React.FC<Props> } */
const DeleteSourceDialog = React.memo(({
    title,
    isOpen,
    deleteSource,
    error,
    loading,
    onClose,
}) => (
    <Dialog
        isOpen={isOpen}
        onClose={onClose}
        actions={[
            <Button
                key={1}
                onClick={onClose}
                type="plain"
                disabled={loading}
            >
                Cancel
            </Button>,
            <Button
                key={2}
                loading={loading}
                onClick={deleteSource}
                color="error"
            >
                Delete Connector
            </Button>,
        ]}
    >
        <Typography
            spacing="mt-1"
            color="text"
            variant="h6"
            align="center"
        >
            Delete {`"${title}"`}?
        </Typography>
        <Typography variant="body1" align="center" color="text" spacing="my-4">
            Remove this connector and disable any scheduled collections.
        </Typography>
        <Typography variant="body2" align="center" italic>
            However, any collected tables will not be removed.
        </Typography>

        {error && (
            <Typography color="error" spacing="mt-5">
                {error}
            </Typography>
        )}
    </Dialog>
));

export default DeleteSourceDialog;
