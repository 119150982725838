import React from 'react';

import {
    TableBody,
} from 'ui-components';

import UsersTableBodyRow from './users-table-body-row';

/** @typedef { import('./__types').User } User */
/** @typedef { import('./__types').Invite } Invite */
/** @typedef { import('./__types').Team } Team */
/** @typedef { import('./__types').State } State */
/** @typedef { import('./__types').Sorting } Sorting */
/** @typedef { User | Invite } Item */

/** @type { (a: Item, b: Item, o: Sorting['orderBy']) => number } */
function desc(a, b, orderBy) {
    const orderByA = a[orderBy] || '';
    const orderByB = b[orderBy] || '';
    if (orderByB < orderByA) {
        return -1;
    }
    if (orderByB > orderByA) {
        return 1;
    }
    return 0;
}

/** @type { (a: Item[], fn: (a: Item, b: Item) => number) => Item[] } */
function stableSort(array, cmp) {
    const stabilizedArray = array.map(
        /** @type { (el: Item, i: number) => [Item, number] } */
        (el, index) => [el, index],
    );
    stabilizedArray.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedArray.map(el => el[0]);
}

/** @type { (props: Sorting) => (a: Item, b: Item) => number } */
function getSorting({ order, orderBy }) {
    return order === 'desc'
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

/**
 * @typedef { object } Props
 *
 * @prop { State } state
 * @prop { Team } team
 * @prop { Item[] } users
 * @prop { Sorting } sorting
 * @prop { boolean } resendLoading
 * @prop { (u: User, t: Team) => void } onUserDelete
 * @prop { (i: Invite, t: Team) => void } onResendInvite
 */

/** @type { React.FC<Props> } */
const UsersTableBody = ({ users, sorting, ...props }) => {
    const sortedUsers = stableSort(users, getSorting(sorting));
    return (
        <TableBody>
            {sortedUsers.map((/** @type { Item } */ item) => (
                <UsersTableBodyRow key={item.email} item={item} {...props} />
            ))}
        </TableBody>
    );
};

export default UsersTableBody;
