import React, { useEffect, useCallback } from 'react';
import {
    DebouncedInput,
    Select,
    SelectItem,
    Layout,
} from 'ui-components';

/** @typedef { import('models/sources/__types').DelimiterParam } Param */
/** @typedef { import('models/sources/__types').DelimiterParamValue } Value */
/** @typedef { import('models/sources/__types').FieldValidationErrors } FieldValidationErrors */

/**
 * @typedef { object } Props
 * @prop { Param } param
 * @prop { Value } value
 * @prop { (value: Value) => void } setValue
 * @prop { FieldValidationErrors } errors
*/

/** @type { React.FC<Props> } */
const DelimiterParam = ({
    value,
    setValue,
    errors,
}) => {
    useEffect(() => {
        if (value?.delimiter !== 'other' && value?.otherDelimiter) {
            setValue({
                ...value,
                otherDelimiter: undefined,
            });
        }
    }, [setValue, value]);

    const onDelimiterChanged = useCallback((e) => {
        setValue({
            ...value,
            delimiter: e.target.value || undefined,
        });
    }, [setValue, value]);

    const onOtherDelimiterChanged = useCallback((e) => {
        setValue({
            ...value,
            otherDelimiter: e.target.value,
        });
    }, [setValue, value]);

    return (
        <Layout flex width="100">
            <Layout width="50">
                <Select
                    noFloatLabel
                    name="delimiter"
                    spacing="p-0"
                    width="100"
                    value={value?.delimiter || ''}
                    onChange={onDelimiterChanged}
                >
                    <SelectItem value="">
                        Default
                    </SelectItem>
                    <SelectItem value="space">
                        Space
                    </SelectItem>
                    <SelectItem value="semicolon">
                        Semicolon
                    </SelectItem>
                    <SelectItem value="other">
                        Other
                    </SelectItem>
                </Select>
            </Layout>

            {value?.delimiter === 'other' && (
                <Layout width="50">
                    <DebouncedInput
                        spacing="p-0 ml-2"
                        width="100"
                        name="otherDelimiter"
                        error={!!errors.delimiter}
                        helperText="Choose a delimiter"
                        alwaysShowError={true}
                        disabled={value?.delimiter !== 'other'}
                        value={value?.otherDelimiter || ''}
                        label="e.g. $ or !"
                        noFloatLabel
                        onChange={onOtherDelimiterChanged}
                    />
                </Layout>
            )}
        </Layout>
    );
};

export default DelimiterParam;
