import React from 'react';

import {
    Button,
    Callout,
    Input,
    Typography,
    ValidatedInput,
} from 'ui-components';

import { passwordValidators } from '../../models/users';

/** @typedef { import('lib/ajax').ErrorData } ErrorData */

/**
 * @typedef { object } Props
 * @prop { string } password
 * @prop { string } repeatPassword
 * @prop { boolean } passwordIsValid
 * @prop { boolean } repeatPasswordIsValid
 * @prop { boolean } passwordIsValid
 * @prop { boolean } loading
 * @prop { boolean } disabled
 * @prop { ErrorData } [error]
 * @prop { (e: Event) => void } onPasswordChanged
 * @prop { (e: Event) => void } onRepeatPasswordChanged
 * @prop { (valid: boolean) => void } onPasswordIsValidChanged
 * @prop { () => void } onChangeClicked
 * @prop { (e: KeyboardEvent) => void } onEnterPressed
 */

/** @type { React.FC<Props> } */
const ChangePasswordForm = ({
    password,
    repeatPassword,
    passwordIsValid,
    repeatPasswordIsValid,
    loading,
    disabled,
    error,
    onPasswordChanged,
    onRepeatPasswordChanged,
    onPasswordIsValidChanged,
    onChangeClicked,
    onEnterPressed,
}) => {
    const passwordProps = {
        label: 'Password',
        name: 'password',
        type: 'password',
        value: password,
        error: !password,
        valid: passwordIsValid,
        validators: passwordValidators,
        disabled: loading,
        onKeyDown: onEnterPressed,
        onChange: onPasswordChanged,
        onChangeValid: onPasswordIsValidChanged,
    };

    const repeatPasswordProps = {
        label: 'Repeat password',
        name: 'repeat_password',
        type: 'password',
        value: repeatPassword,
        error: !repeatPasswordIsValid,
        disabled: loading,
        onKeyDown: onEnterPressed,
        onChange: onRepeatPasswordChanged,
    };

    const buttonProps = {
        color: 'primary',
        onClick: onChangeClicked,
        loading,
        disabled,
    };

    return (
        <>
            <Typography variant="h4" align="center" spacing="mb-6">
                Choose a new password
            </Typography>

            {error && (
                <Callout color="error" spacing="mb-5">
                    {error.message}
                </Callout>
            )}

            <ValidatedInput spacing="-m-2 mb-4" noFloatLabel {...passwordProps} />
            <Input width="100" spacing="p-0 mb-5" noFloatLabel {...repeatPasswordProps} />

            <Button width="100" spacing="m-0" {...buttonProps}>
                Change Password
            </Button>
        </>
    );
};

export default ChangePasswordForm;
