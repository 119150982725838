import { Link } from 'react-router-dom';
import styled from 'styled-components';

/** @typedef { Parameters<Link>['0'] & { disabled?: boolean } } NavLinkParams */

/** @type { React.FC<NavLinkParams> } */
const NavLink = styled(Link)`
    &, &:visited, &:hover, &:active {
    font-style: inherit;
    color: inherit;
    background-color: transparent;
    font-size: inherit;
    text-decoration: none;
    font-variant: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
  }

  &[disabled] {
    pointer-events: none;
  }
`;

export default NavLink;
