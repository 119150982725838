import React, { useState, useCallback, useEffect } from 'react';
import {
    Dialog,
    Typography,
    Button,
    Callout,
} from 'ui-components';

import { reportError } from '../../lib/error-reporter';
import { isSourceCollecting, cancelSourceJob } from '../../models/sources';
import { trackStopCollectionModalSubmit } from './tracking';


/** @typedef { import('models/sources/__types').Source } Source */

/**
 * @typedef { object } Props
 * @prop { boolean } open
 * @prop { Source[] } sourcesToStop
 * @prop { () => void } [onStopped]
 * @prop { () => void } onClose
 */

/** @type { React.FC<Props> } */
const BatchStopDialog = React.memo(({
    open,
    sourcesToStop,
    onStopped,
    onClose,
}) => {

    const [stopping, setStopping] = useState(false);
    const [finished, setFinished] = useState(false);
    const [error, setError] = useState('');

    const unstopableSources = sourcesToStop.filter((source) => {
        return !isSourceCollecting(source);
    });

    const stoppableSources = sourcesToStop.filter((source) => {
        return isSourceCollecting(source);
    });

    const stopSources = useCallback(async () => {
        setStopping(true);

        try {
            await Promise.all(stoppableSources.map((source) => {
                return cancelSourceJob(source);
            }));
            if (onStopped) {
                onStopped();
            }
        } catch (err) {
            setError(
                'An error was encountered. Some connectors may have not been deleted',
            );
            reportError(err);
        }

        setStopping(false);
        setFinished(true);
    }, [sourcesToStop]);

    useEffect(() => {
        if (!open) {
            return;
        }
        setFinished(false);
        setError('');
    }, [open]);

    const onCancel = () => {
        trackStopCollectionModalSubmit('false');
        onClose();
    };

    const onStopCollection = () => {
        trackStopCollectionModalSubmit('true');
        stopSources();
    };

    const actions = (
        <>
            {!finished && (
                <>
                    <Button
                        onClick={onCancel}
                        type="plain"
                        disabled={stopping}
                    >
                        Cancel
                    </Button>
                    <Button
                        loading={stopping}
                        onClick={onStopCollection}
                        disabled={stoppableSources.length === 0}
                        color="error"
                    >
                        Stop collecting
                    </Button>
                </>
            )}
            {finished && (
                <Button
                    onClick={onClose}
                >
                    Finish
                </Button>
            )}
        </>
    );

    return (
        <Dialog
            isOpen={open}
            onClose={onClose}
            actions={actions}
        >
            {finished && (
                <>
                    {error && <Error error={error} />}
                    {finished && <Finished stoppedSources={stoppableSources} />}
                </>
            )}
            {!finished && stoppableSources.length === 0 && (
                <NoStoppableSourcesNotification />
            )}
            {!finished && stoppableSources.length !== 0 && (
                <>
                    <Typography
                        spacing="mt-1"
                        color="text"
                        variant="h6"
                        align="center"
                    >
                        Stop collecting {stoppableSources.length}{' '}
                        {stoppableSources.length === 1 ? 'connector' : 'connectors'}?
                    </Typography>
                    <Typography variant="subtitle1" align="center">
                        Data that has already been processed will be preserved.
                    </Typography>

                    {unstopableSources.length !== 0 && (
                        <Callout
                            color="warning"
                            spacing="mt-5"
                        >
                            <Typography
                                color="text"
                                weight="medium"
                                spacing="mb-2"
                            >
                                The following connectors aren&apos;t collecting
                                so will not be stopped
                            </Typography>
                            {unstopableSources.map((source) => (
                                <Typography
                                    key={source.id}
                                    color="text"
                                    weight="medium"
                                >
                                    {source.title}
                                </Typography>
                            ))}
                        </Callout>
                    )}

                    <Callout
                        color="default"
                        spacing="mt-5"
                    >
                        <Typography
                            color="text"
                            weight="medium"
                            spacing="mb-2"
                        >
                            The following connector jobs will be canceled
                        </Typography>
                        {stoppableSources.map((source) => (
                            <Typography
                                key={source.id}
                                color="text"
                                weight="medium"
                            >
                                {source.title}
                            </Typography>
                        ))}
                    </Callout>
                </>
            )}
        </Dialog>
    );
});

/**
 * @typedef { object } ErrorProps
 * @prop { string } error
 */

/** @type { React.FC<ErrorProps> } */
const Error = ({ error }) => {
    return (
        <>
            <Typography
                spacing="mt-1"
                color="text"
                variant="h6"
                align="center"
            >
                Something went wrong.
            </Typography>

            <Callout
                color="error"
                spacing="mt-4"
            >
                {error}
            </Callout>
        </>
    );
};

/**
 * @typedef { object } FinishedProps
 * @prop { Source[] } stoppedSources
 */

/** @type { React.FC<FinishedProps> } */
const Finished = ({ stoppedSources }) => {
    return (
        <>
            <Typography
                spacing="mt-1"
                color="text"
                variant="h6"
                align="center"
            >
                {stoppedSources.length} connector collect{' '}
                {stoppedSources.length === 1 ? 'job' : 'jobs'}{' '}
                stopped successfully.{' '}
            </Typography>

            <Callout
                color="default"
                spacing="mt-4"
            >
                <Typography
                    component="span"
                    variant="subtitle1"
                    spacing="ml-2 mb-0"
                >
                    Canceled connector jobs:
                    {stoppedSources.map((source) => (
                        <Typography
                            key={source.id}
                            color="text"
                            weight="medium"
                        >
                            {source.title}
                        </Typography>
                    ))}
                </Typography>
            </Callout>
        </>
    );
};


/** @type { React.FC } */
const NoStoppableSourcesNotification = () => {
    return (
        <>
            <Typography
                spacing="mt-1"
                color="text"
                variant="h6"
                align="center"
            >
                None of the selected connectors have running jobs
            </Typography>
            <Typography variant="subtitle1" align="center">
                You must select a connector that is currently collecting
            </Typography>
        </>
    );
};

export default BatchStopDialog;
