import React, { useState } from 'react';
import { Icon, Typography, Dialog, Button } from 'ui-components';
import { del } from '../../lib/ajax';
import { useSession } from '../../shared/context-providers';
import {
    ErrorIconCircle,
    SuccessIconCircle,
    WarningIconCircle,
    HeaderWrapper,
} from './shared';


const DetachDialog = ({ onClose, open = false, refetch }) => {
    const [, actions] = useSession();
    const { refreshSession } = actions;

    const [loading, setLoading] = useState(false);
    const [finished, setFinished] = useState(false);
    const [error, setError] = useState(null);
    const [hideDetach, setHideDetach] = useState(false);
    const [actualSuccess, setActualSuccess] = useState(false);

    const deleteFactor = async () => {
        await del('/mfa/totp', {});
        await refreshSession();
    };

    const detachDevice = async () => {
        try {
            setLoading(true);
            await deleteFactor();
            setActualSuccess(true);
            refetch();
        } catch (error) {
            setError(error);
            setActualSuccess(false);
        } finally {
            setHideDetach(true);
            setLoading(false);
            setFinished(true);
        }

    };

    return (
        <Dialog
            key={`detach-dialog-${open}`}
            isOpen={open}
            onClose={onClose}
            actions={
                (
                    <>
                        <Button
                            onClick={onClose}
                            type="plain"
                            disabled={loading}
                        >
                            Close
                        </Button>
                        {!hideDetach && <Button
                            onClick={detachDevice}
                            color="primary"
                            spacing="mr-0"
                            loading={loading}

                        >
                            Detach
                        </Button>}
                    </>
                )
            }
        >
            {finished ? (
                actualSuccess ? (
                    <HeaderWrapper>
                        <SuccessIconCircle>
                            <Icon icon="check" size="lg" color="white" />
                        </SuccessIconCircle>
                        <Typography color="text" variant="h6" align="center" spacing="mb-4 mt-4">
                            Device has successfully detached
                        </Typography>
                        <Typography color="text" variant="body1" align="center">
                            Your MFA will be disabled until you re-attach a device
                        </Typography>
                    </HeaderWrapper>
                ) : (
                    <HeaderWrapper>
                        <ErrorIconCircle>
                            <Icon icon="exclamation-circle" size="lg" color="white" />
                        </ErrorIconCircle>
                        <Typography color="text" variant="h6" align="center" spacing="mb-4 mt-4">
                            There was an error detaching your device
                        </Typography>
                        <Typography color="text" variant="body1" align="center">
                            {error?.message}
                        </Typography>
                    </HeaderWrapper>
                )
            ) : (
                <HeaderWrapper>
                    <WarningIconCircle>
                        <Icon icon="exclamation-circle" size="lg" color="white" />
                    </WarningIconCircle>
                    <Typography color="text" variant="h6" align="center" spacing="mb-4 mt-2">
                        {loading ? 'Detaching...' : 'Detach authenticator app?'}
                    </Typography>
                    <Typography color="text" variant="body1" align="center">
                        In order to attach the device again,
                        you will have to attach it from scratch
                    </Typography>
                </HeaderWrapper>
            )}
        </Dialog>
    );
};

export default DetachDialog;
