import {
    trackBulkOperationMenuSelection,
    trackClearSelection,
    trackOpenBulkOperationMenu,
} from '../pages/source-list-page/tracking';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import {
    Button,
    Typography,
    Layout,
    Select,
    SelectItem,
    Icon,
} from 'ui-components';

/**
 * @typedef { object } Action
 * @prop { string } name
 * @prop { () => void } action
 */

/**
 * @typedef { object } Props
 * @prop { any[] } selectedObjects
 * @prop { () => void } clearSelectedObjects
 * @prop { Action[] } actions
 */

/** @type { React.FC<Props> } */
const BatchActions = ({
    selectedObjects,
    actions,
    clearSelectedObjects,
}) => {

    const onClear = useCallback(() => {
        trackClearSelection();
        clearSelectedObjects();
    }, [clearSelectedObjects]);

    const numberOfSelected = selectedObjects.length;

    /** @type { (e: React.ChangeEvent<HTMLSelectElement> ) => void } */
    const onBatchAction = useCallback((e) => {
        const batchAction = actions.find(a => a.name === e.target.value);

        if (batchAction) {
            trackBulkOperationMenuSelection(batchAction.name);
            if (batchAction.action) {
                batchAction.action();
            }
        }
    }, [actions]);

    const onOpen = useCallback(() => {
        trackOpenBulkOperationMenu();
    }, []);

    return (
        <BulkActionsWrapper
            open={!!numberOfSelected}
            width="100"
        >
            <Layout flex alignItems="center" spacing="mb-3" width="100">
                <SmallSelect
                    noFloatLabel
                    width="20"
                    value=""
                    spacing="pl-0 pt-0"
                    disabled={!numberOfSelected}
                    onChange={onBatchAction}
                    onOpen={onOpen}
                >
                    <SelectItem value="" disabled>
                        Actions
                    </SelectItem>
                    {actions && actions.map((action) => (
                        <SelectItem key={action.name} value={action.name}>
                            {action.name}{' '}
                            ({numberOfSelected} {numberOfSelected > 1 ? 'items' : 'item'})
                        </SelectItem>
                    ))}
                </SmallSelect>

                <Typography color="secondaryText" variant="body2" spacing="my-0 ml-3">
                    {numberOfSelected} {numberOfSelected > 1 ? 'items' : 'item'} selected
                </Typography>

                <Button type="plain" spacing="p-2" onClick={onClear}>
                    <Icon icon="times-circle" size="md" spacing="m-0" />
                </Button>
            </Layout>
        </BulkActionsWrapper>
    );
};

const SmallSelect = styled(Select)`
    &&& {
        width: 120px;
        .input {
            padding: 8px 8px 8px 12px;
            font-size: 14px;
        }
        .MuiInputBase-root {
            background-color: transparent;
        }
    }
`;

const BulkActionsWrapper = styled(Layout)`
    &&& {
        height: 0px;
        opacity: 0;
        transition: all 0.3s;
        pointer-events: none;
        ${({ open }) => open && `
            height: 68px;
            opacity: 1;
            pointer-events: all;
        `};
    }
`;


export default BatchActions;
