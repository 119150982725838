import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import {
    Button,
    Dialog,
    Layout,
    Select,
    SelectItem,
    Typography,
} from 'ui-components';
import { reportError } from '../../lib/error-reporter';

/** @typedef { import('models/database-objects/__types').DatabaseObject } DatabaseObject */
/** @typedef { import('models/database-objects/__types').Folder } Folder */
/** @typedef { import('lib/ajax').AjaxResponse= } AjaxError */

/**
 * @typedef { Folder & {indent: number, disabled: boolean} } FlatFolder
 */

/**
 * @param { DatabaseObject } objectToMove
 * @param { DatabaseObject[] } objectsToFilter
 * @param { object } [options]
 * @param { number= } options.indent
 * @param { boolean= } options.parentDisabled
 * @returns { FlatFolder[] }
 */
const getFolders = (objectToMove, objectsToFilter, { indent = 0, parentDisabled = false } = {}) => (
    objectsToFilter.filter(i => i.type === 'folder')
        .sort((a, b) => (a.name > b.name ? -1 : 1))
        .reduce((acc, object) => {
            const disabled = parentDisabled || (objectToMove && object.id === objectToMove.id);
            const subItemOptions = { indent: indent + 1, parentDisabled: disabled };
            const subItems = (
                (
                    object.items
                    && getFolders(objectToMove, object.items, subItemOptions)
                ) || []
            );
            return [
                ...acc,
                { ...object, disabled, indent },
                ...subItems,
            ];
        }, /** @type { any[] } */ ([]))
);


/**
 * @typedef { object } Props
 * @prop { boolean } open
 * @prop { () => void } onClose
 * @prop { DatabaseObject[] } objects
 * @prop { DatabaseObject= } objectToMove
 * @prop { (object: DatabaseObject, folderId: Folder['id']) => Promise<void> } moveObjectToFolder
 */

/** @type { React.FC<Props> } */
const MoveObjectDialog = ({
    open, onClose, objects, objectToMove, moveObjectToFolder,
}) => {

    const [selectedDestination, setSelectedDestination] = useState('');
    const [loading, setLoading] = useState(false);

    /** @type {[AjaxError, React.Dispatch<AjaxError>]} */
    const [error, setError] = useState();

    const onMoveClicked = useCallback(async () => {
        if (!objectToMove) {
            return;
        }
        setLoading(true);
        try {
            await moveObjectToFolder(objectToMove, selectedDestination);
            onClose();
        } catch (err) {
            reportError(err, { objectId: objectToMove.id, selectedDestination });
            setError(err);
        }
        setLoading(false);
    }, [objectToMove, moveObjectToFolder, selectedDestination, onClose]);

    /** @type { (e: React.ChangeEvent<HTMLSelectElement>) => void } */
    const onDestinationSelectChange = useCallback((e) => {
        setSelectedDestination(e.target.value);
    }, []);

    if (!objectToMove) {
        return null;
    }

    const folders = getFolders(objectToMove, objects, { indent: 1 });

    return (
        <Dialog
            title="Move to Folder"
            isOpen={open}
            onClose={onClose}
        >

            <Select
                value={selectedDestination}
                onChange={onDestinationSelectChange}
                label="Select Destination"
                width="100"
                spacing="m-0 p-0"
                disabled={loading}
                noFloatLabel
            >
                <SelectTreeItem
                    indent={0}
                    value=""
                >
                    All Tables
                </SelectTreeItem>
                {folders.map(folder => (
                    <SelectTreeItem
                        key={folder.id}
                        indent={folder.indent}
                        value={folder.id}
                        disabled={folder.disabled}
                    >
                        {folder.name}
                    </SelectTreeItem>
                ))}
            </Select>

            {error && (
                <Layout spacing="mt-4">
                    <Typography variant="subtitle1" color="error">
                        Oops! an error was encountered when moving the item.
                    </Typography>
                    <Typography variant="body1" color="error">
                        {(error.data && error.data.message) || error.status}
                    </Typography>
                </Layout>
            )}

            <Layout flex justifyContent="flex-end" spacing="mt-6">
                <Button onClick={onClose} disabled={loading}>Cancel</Button>
                <Button
                    color="primary"
                    loading={loading}
                    onClick={onMoveClicked}
                    spacing="mr-0"
                >
                    Move
                </Button>
            </Layout>
        </Dialog>
    );
};


const SelectTreeItem = styled(SelectItem)`
    &&& {
        ${({ indent }) => indent && `
            margin-left: ${indent * 12}px;
        `}
    }
`;

export default MoveObjectDialog;
