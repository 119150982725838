import { useState, useEffect, useCallback } from 'react';

import { useRequest } from '../../lib/ajax';

/** @typedef { import('./__types').User } User */
/** @typedef { import('./__types').Invite } Invite */
/** @typedef { import('./__types').Team } Team */
/** @typedef { import('./__types').State } State */
/** @typedef { import('../../app/__types').PageProps } PageProps */

/** @type { State } */
const initialState = {
    userToInvite: {},
    userToDelete: {},
    teamToUpdate: {},
    resendInvite: {},
};

/**
 * @typedef { object } Props
 *
 * @prop { PageProps['openToast'] } openToast
 */

/**
 * @typedef { object } ReturnProps
 *
 * @prop { State } state
 * @prop { Team[] } teams
 * @prop { boolean } teamsLoading
 * @prop { boolean } updateLoading
 * @prop { boolean } resendLoading
 * @prop { { message: string } } teamsError
 * @prop { { message: string } } updateError
 * @prop { () => void } onCloseDeleteDialog
 * @prop { () => void } onDeleteUserConfirm
 * @prop { (u: User, t: Team) => void } onUserDelete
 * @prop { (i: Invite, t: Team) => void } onUserInvite
 * @prop { (i: Invite, t: Team) => void } onResendInvite
 */

/** @type { (props: Props) => ReturnProps } */
function useUsersPage({ openToast }) {
    const [state, setState] = useState({ ...initialState });

    const { go: fetchTeams, ...teamsReq } = useRequest('/teams');

    const {
        go: updateTeam,
        clear: clearUpdateTeam,
        ...updateTeamReq
    } = useRequest(`/teams/${state.teamToUpdate.id}`, 'PUT', state.teamToUpdate);

    const {
        go: resendInvite,
        ...resendInviteReq
    } = useRequest('/teams/invites', 'POST', state.resendInvite);

    const [refreshingTeams, setRefreshingTeams] = useState(false);

    // In some situations we make multiple requests in a row
    // Use refreshingTeams to manually manage loading state in those cases
    // To prevent UI jitter
    // Else teamsReq.loading should suffice for most situations
    const teamsLoading = teamsReq.loading || refreshingTeams;

    const updateLoading = updateTeamReq.loading;
    const resendLoading = resendInviteReq.loading;

    const updateError = updateTeamReq.error;
    const teamsError = teamsReq.error;

    const teams = teamsReq.data || [];

    const updateState = useCallback((newState) => {
        setState(s => ({ ...s, ...newState }));
    }, []);

    const onUserDelete = useCallback((userToDelete, team) => {
        const teamToUpdate = {
            id: team.id,
            name: team.name,
            invites: team.invites.filter(
                (/** @type { Invite } */ i) => i.email !== userToDelete.email,
            ),
            users: team.users.filter(
                (/** @type { User } */ u) => u.email !== userToDelete.email,
            ),
        };
        updateState({ userToDelete, teamToUpdate });
    }, [updateState]);

    const onCloseDeleteDialog = useCallback(() => {
        updateState({ ...initialState });
    }, [updateState]);

    const onDeleteUserConfirm = useCallback(() => {
        if (!state.teamToUpdate.id) {
            return;
        }
        updateTeam().then(({ ok }) => {
            if (!ok) {
                return;
            }
            onCloseDeleteDialog();
            clearUpdateTeam();
            fetchTeams();
        });
    }, [
        state.teamToUpdate,
        state.userToDelete,
        onCloseDeleteDialog,
        fetchTeams,
        updateTeam,
        clearUpdateTeam,
    ]);

    const onUserInvite = useCallback((userToInvite, team) => {
        setRefreshingTeams(true);
        fetchTeams().then(({ data: teams, ok }) => {
            if (!ok) {
                setRefreshingTeams(false);
                return;
            }
            const teamToUpdate = teams.find((/** @type {Team} */ t) => t.id === team.id);
            updateState({
                userToInvite, teamToUpdate: {
                    id: teamToUpdate.id,
                    name: teamToUpdate.name,
                    users: teamToUpdate.users,
                    invites: [...teamToUpdate.invites, { email: userToInvite.email }],
                },
            });
        });
    }, [updateState, fetchTeams]);

    const onResendInvite = useCallback((u, team) => {
        updateState({ resendInvite: { invitee: u.email, teamId: team.id } });
    }, [updateState]);

    useEffect(() => {
        fetchTeams();
    }, [fetchTeams]);

    useEffect(() => {
        if (!state.userToInvite.email) {
            return;
        }
        updateTeam().then(({ ok }) => {
            if (!ok) {
                setRefreshingTeams(false);
                return;
            }

            updateState({ ...initialState });
            clearUpdateTeam();
            fetchTeams();
            setRefreshingTeams(false);
        });
    }, [
        state.userToInvite,
        updateTeam,
        clearUpdateTeam,
        fetchTeams,
        updateState,
    ]);

    useEffect(() => {
        if (!state.resendInvite.invitee) {
            return;
        }
        resendInvite().then((res) => {
            if (!res.ok) {
                openToast(res.data);
                return;
            }
            updateState({ ...initialState });
        });
    }, [state.resendInvite, resendInvite, updateState, openToast]);

    return {
        state,
        teams,
        teamsLoading,
        updateLoading,
        resendLoading,
        teamsError,
        updateError,
        onUserDelete,
        onUserInvite,
        onCloseDeleteDialog,
        onDeleteUserConfirm,
        onResendInvite,
    };
}

export default useUsersPage;
