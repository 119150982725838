import React, { useCallback } from 'react';
import styled from 'styled-components';

import {
    Button,
    Callout,
    Dialog,
    Input,
    Typography,
    useInput,
} from 'ui-components';

import { useRequest } from '../../lib/ajax';

/** @typedef { import('../__types').Feedback } Props */

/** @type { React.FC<Props> } */
const FeedbackDialog = ({
    open,
    header,
    description,
    buttonLabel,
    input,
    emailTitle,
    onClose,
}) => {
    const feedbackInput = useInput('Feedback', input);

    const feedbackReq = useRequest('/feedback', 'POST', {
        title: emailTitle,
        feedback: feedbackInput.value,
    });

    const send = useCallback(() => {
        feedbackReq.go().then((res) => {
            if (!res.ok) {
                return;
            }
            if (onClose) {
                onClose();
            }
            feedbackReq.clear();
        });
    }, [feedbackReq, onClose]);

    const cancel = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    return (
        <Dialog
            isOpen={open}
            onClose={cancel}
            title={header || 'Tell us what you think...'}
            actions={[
                <Button
                    key="cancel"
                    type="plain"
                    onClick={cancel}
                    disabled={feedbackReq.loading}
                >
                    Cancel
                </Button>,
                <Button
                    key="send"
                    color="primary"
                    spacing="mr-0"
                    onClick={send}
                    disabled={!feedbackInput.value}
                    loading={feedbackReq.loading}
                >
                    {buttonLabel || 'Send Feedback'}
                </Button>,
            ]}
        >
            {description && (
                <Description spacing="mb-4">
                    {description}
                </Description>
            )}

            <Input
                multiline
                width="100"
                spacing="m-0 p-0"
                disabled={feedbackReq.loading}
                {...feedbackInput.bind}
            />

            {feedbackReq.error && (
                <Callout color="error" spacing="mt-2">
                    {feedbackReq.error.message}
                </Callout>
            )}
        </Dialog>
    );
};

const Description = styled(Typography)`
    max-width: 28rem;
`;

export default FeedbackDialog;
