import Settings from '../nested-pages/settings';
import History from '../nested-pages/history';
import Knowledge from '../nested-pages/knowledge';
import Recipes from '../nested-pages/recipes';

/** @typedef {import('../../../app/__types').PageRoute} PageRoute */

export const routesMap = {
    settings: {
        id: 'settings',
        name: 'settings',
        path: 'settings',
        label: 'Connector Settings',
        Component: Settings,
    },
    history: {
        id: 'history',
        name: 'history',
        path: 'history',
        label: 'Collection History',
        Component: History,
    },
    knowledge: {
        id: 'knowledge',
        name: 'knowledge',
        path: 'knowledge',
        label: 'Knowledge Center',
        Component: Knowledge,
    },
    recipes: {
        id: 'recipes',
        name: 'recipes',
        path: 'recipes',
        label: 'Data Recipes',
        Component: Recipes,
    },
    fallback: {
        id: 'fallback',
        name: 'fallback',
        redirect: {
            to: 'settings',
        },
    },
};

/** @type {PageRoute[]} */
export const routes = [
    routesMap.settings,
    routesMap.history,
    routesMap.knowledge,
    routesMap.recipes,
    routesMap.fallback,
];

export default routes;
