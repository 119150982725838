import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { reportError } from '../../lib/error-reporter';
import useModeSelector from './use-mode-selector';

/** @typedef { import('./__types').Mode } Mode */

function useWorkbenchMode() {
    const navigate = useNavigate();

    const { isQueryBuilderAllowed } = useModeSelector();

    const [mode, setMode] = useState(() => {
        try {
            const item = localStorage.getItem('workbench-mode');

            if (item) {
                return /** @type { Mode } */ (item);
            }
        } catch (/** @type { any } */ err) {
            reportError(err);
        }

        return /** @type { Mode } */ ('sql');
    });

    /** @type { (newMode: Mode) => void } */
    const onModeChanged = useCallback((newMode) => {
        if (newMode === 'query-builder') {
            navigate('/workbench');
        }

        try {
            localStorage.setItem('workbench-mode', newMode);
        } catch (/** @type { any } */ err) {
            reportError(err);
        }

        setMode(newMode);
    }, []);

    return {
        mode: isQueryBuilderAllowed ? mode : 'sql',
        onModeChanged,
    };
}

export default useWorkbenchMode;
