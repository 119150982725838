import React from 'react';

import {
    Layout,
    ExpansionPanelHeader,
    Loader,
    Typography,
} from 'ui-components';

import TeamsListItem from './teams-list-item';

/** @typedef { import('./__types').User } User */
/** @typedef { import('./__types').Invite } Invite */
/** @typedef { import('./__types').Team } Team */
/** @typedef { import('./__types').State } State */

/** @type { (ts: Team[]) => Team[] } */
function sortByName(teams) {
    return teams.sort((a, b) => {
        const { name: nameA = '' } = a;
        const { name: nameB = '' } = b;
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
}

const TeamsLoading = () => (
    <ExpansionPanelHeader spacing="pt-4">
        <Loader big active message="Loading Teams" />
    </ExpansionPanelHeader>
);

const TeamsNotFound = () => (
    <ExpansionPanelHeader>
        <Typography align="center">
            No teams have been found.
        </Typography>
    </ExpansionPanelHeader>
);

/**
 * @typedef { object } Props
 *
 * @prop { State } state
 * @prop { Team[] } teams
 * @prop { boolean } teamsLoading
 * @prop { boolean } updateLoading
 * @prop { boolean } resendLoading
 * @prop { (u: User, t: Team) => void } onUserDelete
 * @prop { (i: Invite, t: Team) => void } onUserInvite
 * @prop { (i: Invite, t: Team) => void } onResendInvite
 */

/** @type { React.FC<Props> } */
const TeamsList = ({ teamsLoading, teams, ...props }) => {
    if (teamsLoading) {
        return (
            <Layout spacing="mt-5">
                <TeamsLoading />
            </Layout>
        );
    }

    if (!teams.length) {
        return (
            <Layout spacing="mt-6">
                <TeamsNotFound />
            </Layout>
        );
    }

    return (
        <>
            {sortByName(teams).map(team => (
                <TeamsListItem key={team.id} team={team} {...props} />
            ))}
        </>
    );
};

export default TeamsList;
