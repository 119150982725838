import React, { useCallback } from 'react';

import MarkdownRenderer from '../../../shared/markdown-renderer';
import SecretInput from './secret-input';

/** @typedef { import('models/sources/__types').InputParam } Param */

/**
 * @typedef { object } Props
 * @prop { Param } param
 * @prop { string } value
 * @prop { (val: string) => void } setValue
 * @prop { string } sourceTypeId
 */

/** @type { React.FC<Props> } */
const InputParam = ({
    param,
    value,
    sourceTypeId,
    setValue,
}) => {
    const onInputChanged = useCallback(e => setValue(e.target.value), [setValue]);

    const inputProps = {
        width: '50',
        spacing: 'px-0',
        name: sourceTypeId + param.name,
        id: sourceTypeId + param.name,
        label: param.placeholder || param.title,
        helperText: param.description && (
            <MarkdownRenderer source={param.description} />
        ),
        type: param.type === 'textarea' ? 'text' : param.type,
        value,
        multiline: param.type === 'textarea',
        noFloatLabel: true,
        onChange: onInputChanged,
        duration: 500,
    };

    return (<SecretInput {...inputProps} />);
};

export default InputParam;
