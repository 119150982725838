import React, {
    createContext,
    useContext,
    useCallback,
    useEffect,
    useState,
    useRef,
    useMemo,
} from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { configureScope } from '../../lib/sentry';

import { get } from '../../lib/ajax';
import { reportError } from '../../lib/error-reporter';
import gotoUrl from '../../lib/goto-url';

/** @typedef { import('../../app/__types').Session } Session */
/** @typedef { import('../../app/__types').SessionContext } Context */
/** @typedef { import('../../app/__types').UpdateSession } Update */
/** @typedef { import('../../app/__types').RefreshSession } Refresh */
/** @typedef { import('../../app/__types').ChangeDatabase } Change */
/** @typedef { import('../../app/__types').SessionActions } Actions */

export const SessionContext = createContext(/** @type { Context } */ ([{}, {}]));

/** @type { React.FC<{ children: React.ReactNode }> } */
export const SessionProvider = ({ children }) => {
    /** @type { Session } */
    const initialSession = {
        database: {},
        user: {},
        billing: {},
        csrfToken: '',
        afterLoginRedirect: '',
        scopes: [],
        nav: {
            exclude: [],
        },
        outdated: false,
        databaseSwitched: false,
    };

    const [session, setSession] = useState(initialSession);

    const queryClient = useQueryClient();

    const broadcastChannelRef = useRef(
        /** @type { BroadcastChannel | null } */ (null)
    );

    // @ts-ignore
    const { Panoply } = window;

    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    const clearAjaxCache = useCallback(() => {
        queryClient.clear();

        // Even though the query cache is cleared there is a potential delay
        // before actually clearing local storage. This does it immediately
        // see https://github.com/TanStack/query/issues/1951
        localStorage.removeItem('REACT_QUERY_OFFLINE_CACHE');
    }, [queryClient.clear]);

    /** @type { Change } */
    const changeDatabase = useCallback(async (id) => {
        clearAjaxCache();
        gotoUrl(`/databases/switch/${id}`);
    }, [clearAjaxCache]);

    /** @type { Update } */
    const updateSession = useCallback((updates) => {
        // @ts-ignore
        window.Panoply = { ...Panoply, ...updates };
        setSession(s => ({ ...s, ...updates }));
    }, []);

    /** @type { Refresh } */
    const refreshSession = useCallback(async () => {
        const { data: newSession } = await get('/session');
        const { user, database, billing } = newSession;

        configureScope({ user, database });

        const initialValues = {
            csrfToken: '',
            afterLoginRedirect: '',
        };

        // @ts-ignore
        window.Panoply = {
            ...Panoply,
            ...initialValues,
            ...newSession,
            user: user.id ? user : null,
            database: database.id ? database : null,
            billing: 'onTrial' in billing ? billing : null,
        };

        if (!mounted.current) {
            /* eslint-disable-next-line no-console */
            console.warn('refreshSession() resolved after the component was unmounted');
            return newSession;
        }

        if (!newSession.user.id) {
            clearAjaxCache();
        }

        setSession(s => ({
            ...s,
            ...initialValues,
            ...newSession,
        }));

        return newSession;
    }, []);

    /** @type { (u: Session['user'], db: Session['database']) => void } */
    const validateSession = useCallback((newUser, newDatabase) => {
        const userChanged = newUser.id !== session.user.id;
        const databaseChanged = !!session.database?.id && newDatabase.id !== session.database.id;
        if (userChanged) {
            updateSession({ outdated: true });
            return;
        }
        if (databaseChanged) {
            updateSession({ databaseSwitched: true, database: newDatabase });
        }
    }, [session.user, session.database, updateSession]);

    const setupBroadcastChannel = useCallback(() => {
        try {
            broadcastChannelRef.current = new BroadcastChannel('panoply_user');
            broadcastChannelRef.current.onmessage = e => {
                const message = e.data;
                if (message.type === 'session-changed') {
                    validateSession(message.user, message.database);
                }
            };
            broadcastChannelRef.current.onmessageerror = e => {
                reportError(new Error('BroadcastChannel message cant be deserialized'), e);
            };
            return () => broadcastChannelRef.current?.close();
        } catch (e) {
            // Browser doesn't support BroadcastChannel. Do nothing.
            return () => {};
        }
    }, [validateSession]);

    const broadcastUserChanges = useCallback(() => {
        if (!session.user?.id || !session.database?.id) {
            return;
        }

        broadcastChannelRef.current?.postMessage({
            type: 'session-changed',
            user: session.user,
            database: session.database,
        });
    }, [session.user, session.database]);

    useEffect(setupBroadcastChannel, [setupBroadcastChannel]);

    useEffect(broadcastUserChanges, [broadcastUserChanges]);

    useEffect(() => {
        refreshSession();
    }, [refreshSession]);

    /** @type { Actions } */
    const actions = useMemo(() => ({
        updateSession,
        refreshSession,
        changeDatabase,
    }), [updateSession, refreshSession, changeDatabase]);

    return (
        <SessionContext.Provider value={[session, actions]}>
            {children}
        </SessionContext.Provider>
    );
};

/** @type { () => Context } */
function useSession() {
    const context = useContext(SessionContext);
    return context;
}

export default useSession;
