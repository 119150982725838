import React, { useCallback } from 'react';

import {
    TableRow,
    TableCell,
    TableHead,
    TableSortLabel,
} from 'ui-components';

const rows = [
    { id: 'email', label: 'Email' },
    { id: 'username', label: 'Username' },
    { id: 'lastLogin', label: 'Last Login' },
    { id: 'token', label: '' },
    { id: 'id', label: '' },
];

const UsersTableHead = ({ sorting, setSorting }) => {
    const onSort = useCallback((orderBy) => {
        let order = 'desc';
        if (sorting.orderBy === orderBy && sorting.order === 'desc') {
            order = 'asc';
        }
        setSorting({ order, orderBy });
    }, [sorting, setSorting]);

    const getTableCellProps = row => ({
        align: 'left',
        padding: 'normal',
        sortDirection: sorting.orderBy === row.id ? sorting.order : false,
    });

    const getTableSortProps = row => ({
        active: sorting.orderBy === row.id,
        direction: sorting.order,
        onClick: () => onSort(row.id),
    });

    return (
        <TableHead>
            <TableRow>
                {rows.map(row => (
                    <TableCell key={row.id} {...getTableCellProps(row)}>
                        {row.label && (
                            <TableSortLabel {...getTableSortProps(row)}>
                                {row.label}
                            </TableSortLabel>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default UsersTableHead;
