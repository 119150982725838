import React, { useState } from 'react';
import { Button, Callout, Dialog, Input, Layout, Typography } from 'ui-components';
import { editDashboard } from '../../models/dashboards';

/** @typedef { import('models/dashboards/types').Dashboard} Dashboard */

/**
 * @typedef { object } RenameDashboardDialogProps
 * @property { Dashboard | null } dashboard
 * @property { boolean } isOpen
 * @property { (dashboard: Dashboard) => void } onClose
 * @property { (dashboard: Dashboard) => void } updateDashboardListItem
 */

/** @type { React.FC<RenameDashboardDialogProps> } */
const RenameDashboardDialog = ({
    dashboard = {},
    isOpen,
    onClose,
    updateDashboardListItem,
}) => {
    const id = dashboard?.id;
    const currentTitle = dashboard?.title;

    const [renameInput, setRenameInput] = useState(currentTitle);
    const [error, setError] = useState(null);

    const rename = async (/** @type { string } */ newTitle) => {
        try {
            const updatedDashboard = await editDashboard(id, { title: newTitle });
            updateDashboardListItem(updatedDashboard);
            onClose();
        } catch (err) {
            setError(err.data);
        }
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            actions={[
                <Button
                    key={'renameDialog-cancel-btn'}
                    onClick={onClose}
                    type="plain"
                >
                    Cancel
                </Button>,
                <Button
                    key={'renameDialog-rename-btn'}
                    color="primary"
                    onClick={() => rename(renameInput)}
                    disabled={!renameInput}
                >
                    Rename
                </Button>,
            ]}
        >
            <Layout>
                <Typography
                    spacing="mt-1"
                    color="text"
                    variant="h6"
                    align="center"
                >
                    Rename {`"${currentTitle}"`}?
                </Typography>

                <Typography spacing="mt-1" color="text" variant="body1" align="center">
                    Rename the Dashboard
                </Typography>
                {error && <Callout color="error">{error.message}</Callout>}

                <Input
                    label={'Rename Dashboard'}
                    onChange={(e) => setRenameInput(e.target.value)}
                    value={renameInput}
                    noFloatLabel
                    width="100"
                    autoFocus
                />
            </Layout>
        </Dialog>
    );

};

export default RenameDashboardDialog;
