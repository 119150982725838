import React from 'react';

import {
    Typography,
    TableRow,
    TableCell,
    Button,
    Icon,
} from 'ui-components';

import { useSession } from '../../shared/context-providers';

/** @typedef { import('./__types').User } User */
/** @typedef { import('./__types').Invite } Invite */
/** @typedef { import('./__types').Team } Team */
/** @typedef { import('./__types').State } State */
/** @typedef { User | Invite } Item */

/** @type { React.FC<{ email: string, isOwner: boolean }> } */
const TableCellEmail = ({ email, isOwner }) => (
    <TableCell component="th">
        <Typography color="text" inline>{email}</Typography>
        {isOwner && (
            <Typography color="secondaryText" spacing="ml-2" inline>Owner</Typography>
        )}
    </TableCell>
);

/** @type { React.FC<{ text: string }> } */
const TableCellUsername = ({ text }) => (
    <TableCell>
        {text ? (
            <Typography inline>{text}</Typography>
        ) : (
            <Typography inline color="secondaryText">Not created</Typography>
        )}
    </TableCell>
);

/** @type { React.FC<{ text: string }> } */
const TableCellLogin = ({ text }) => (
    <TableCell>
        <Typography inline>{text}</Typography>
    </TableCell>
);

/** @type { React.FC<{ visible: boolean, loading: boolean, onClick: () => void }> } */
const TableCellInvite = ({ visible, ...props }) => (
    <TableCell>
        {visible && (
            <Button type="plain" spacing="m-0 py-1" {...props}>
                Resend invite
            </Button>
        )}
    </TableCell>
);

/** @type { React.FC<{ disabled: boolean, onClick: () => void }> } */
const TableCellDelete = props => (
    <TableCell align="right">
        <Button type="plain" spacing="m-0 py-2 -mr-2" {...props}>
            <Icon prefix="fas" icon="trash" />
        </Button>
    </TableCell>
);

/** @type { (i: Item) => string } */
function getLastLogin(item) {
    if ('token' in item && item.token) {
        return 'Invitation sent';
    }
    if (!('lastLogin' in item) || !item.lastLogin) {
        return 'Never';
    }
    return new Date(item.lastLogin).toUTCString();
}

/**
 * @typedef { object } Props
 *
 * @prop { State } state
 * @prop { Team } team
 * @prop { Item } item
 * @prop { boolean } resendLoading
 * @prop { (u: User, t: Team) => void } onUserDelete
 * @prop { (i: Invite, t: Team) => void } onResendInvite
 */

/** @type { React.FC<Props> } */
const UsersTableBodyRow = (({
    state,
    item,
    team,
    resendLoading,
    onResendInvite,
    onUserDelete,
}) => {
    const [session] = useSession();
    const { user, database } = session;

    const itemId = 'id' in item ? item.id : '';

    const isOwner = itemId === database.owner_id;
    const isMe = itemId === user.id;

    const cellEmailProps = {
        email: item.email || '',
        isOwner,
    };

    const cellLoginProps = {
        text: getLastLogin(item),
    };

    const cellUsernameProps = {
        text: item.username,
    };

    const cellInviteProps = {
        onClick: () => onResendInvite(item, team),
        visible: !!('token' in item && item.token),
        loading: resendLoading
            && state.resendInvite.invitee === item.email,
    };

    const cellDeleteProps = {
        onClick: () => onUserDelete(item, team),
        disabled: isOwner || isMe,
    };

    return (
        <TableRow>
            <TableCellEmail {...cellEmailProps} />
            <TableCellUsername {...cellUsernameProps} />
            <TableCellLogin {...cellLoginProps} />
            <TableCellInvite {...cellInviteProps} />
            <TableCellDelete {...cellDeleteProps} />
        </TableRow>
    );
});

export default UsersTableBodyRow;
