import React, { useState, useCallback, useEffect } from 'react';

import {
    Dialog,
    Divider,
    Button,
    Typography,
    Input,
    Icon,
} from 'ui-components';

/** @typedef { import('lib/query-builder').Column } Column */
/** @typedef { import('lib/query-builder').Position } Position */

/**
 * @typedef { object } Props
 * @prop { boolean } open
 * @prop { number | undefined } queryLimit
 * @prop { (limit?: number) => void } changeLimit
 * @prop { () => void } onClose
 */

/** @type { React.FC<Props> } */
const LimitDialog = ({ open, queryLimit, changeLimit, onClose }) => {
    const [limit, setLimit] = useState(queryLimit);

    const onSave = useCallback(() => {
        changeLimit(limit);
        onClose();
    }, [limit, changeLimit, onClose]);

    useEffect(() => {
        if (!open) {
            return () => {};
        }

        /** @type { (e: KeyboardEvent) => void } */
        const onKeyDown = e => {
            if (e.key === 'Escape') {
                e.preventDefault();
                onClose();
            } else if (e.key === 'Enter') {
                e.preventDefault();
                onSave();
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    }, [open, onSave, onClose]);

    return (
        <Dialog
            compact
            title="Limit"
            isOpen={open}
            onClose={onClose}
            actions={[
                <Button
                    key="cancel"
                    type="plain"
                    spacing="ml-0"
                    onClick={onClose}
                >
                    Cancel
                </Button>,
                <Button
                    key="save"
                    color="primary"
                    spacing="mr-0"
                    onClick={onSave}
                >
                    Okay
                </Button>,
            ]}
        >
            <Divider spacing="-mx-4 mt-0 mb-4" />

            <Subtitle>
                Limit
            </Subtitle>

            <Input
                type="number"
                label="Limit"
                value={limit || ''}
                onChange={e => setLimit(
                    e.target.value
                        ? Math.abs(Number(e.target.value))
                        : undefined
                )}
                suffix={limit ? (
                    <Button
                        round
                        type="plain"
                        spacing="m-0 p-0"
                        onClick={() => setLimit(undefined)}
                    >
                        <Icon prefix="fas" icon="times-circle" size="sm" />
                    </Button>
                ) : null}
                spacing="p-0"
                width="100"
                noFloatLabel
            />
        </Dialog>
    );
};

/** @type { React.FC<{ children: React.ReactNode, spacing?: string }> } */
const Subtitle = ({ children, ...props }) => (
    <Typography variant="subtitle1" color="text" spacing="mt-4" {...props}>
        {children}
    </Typography>
);

export default LimitDialog;
