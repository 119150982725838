import { useState, useCallback } from 'react';

import { useRequest } from '../../lib/ajax';

/** @typedef { import('./__types').Warehouse } Warehouse */
/** @typedef { import('../../app/__types').PageProps } PageProps */

/**
 * @typedef { object } Props
 *
 * @prop { PageProps['openToast'] } openToast
 * @prop { () => void } fetchWarehouses
 */

/**
 * @typedef { object } ReturnProps
 *
 * @prop { { message: string } } suspendError
 * @prop { boolean } suspendLoading
 * @prop { Warehouse } warehouseToSuspend
 * @prop { () => void } onSuspendClose
 * @prop { () => void } onSuspendConfirm
 * @prop { (w: Warehouse) => void } onSuspend
 */

/** @type { (props: Props) => ReturnProps } */
function useSuspend({ openToast, fetchWarehouses }) {
    /** @type { [Warehouse, (w: Warehouse) => void] } */
    const [warehouseToSuspend, setWarehouseToSuspend] = useState({});

    const suspendReq = useRequest(`/databases/${warehouseToSuspend.id}`, 'DELETE');

    const {
        error: suspendError,
        loading: suspendLoading,
    } = suspendReq;

    const onSuspend = useCallback((warehouse) => {
        setWarehouseToSuspend(warehouse);
    }, []);

    const onSuspendClose = useCallback(() => {
        setWarehouseToSuspend({});
    }, []);

    const onSuspendConfirm = useCallback(() => {
        suspendReq.go().then(({ ok, data }) => {
            onSuspendClose();
            suspendReq.clear();

            if (!ok) {
                openToast(data);
                return;
            }

            openToast(`Suspension of ${data.name} has been scheduled.`);
            fetchWarehouses();
        });
    }, [suspendReq, onSuspendClose, openToast, fetchWarehouses]);

    return {
        suspendError,
        suspendLoading,
        warehouseToSuspend,
        onSuspendClose,
        onSuspendConfirm,
        onSuspend,
    };
}

export default useSuspend;
