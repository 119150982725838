import { split, shuffle, random } from 'lodash';

const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

/** @type { (length: number) => string } */
export const getRandomString = (length) => {
    const randomChars = shuffle(split(CHARS, ''));
    let randomString = '';

    for (let i = 0; i < length; i++) {
        randomString += randomChars[random(randomChars.length)];
    }

    return randomString;
};

export default getRandomString;
