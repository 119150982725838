import React from 'react';
import styled from 'styled-components';
const { Layout, Icon } = require('ui-components');


/**
 * @typedef { object } Props
 * @prop { () => void } toggleOpen
 */

/** @type { React.FC<Props> } */
function WorkbenchVisualizationConfigOpener({ toggleOpen }) {
    return (
        <Container
            flex
            alignItems="center"
            justifyContent="flex-end"
            spacing="p-3 pl-0"
        >
            <div onClick={toggleOpen}>
                <Icon icon="cog" size="md" />
            </div>
        </Container>
    );
}

const Container = styled(Layout)`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
`;

export default WorkbenchVisualizationConfigOpener;
