import { reportError } from '../../lib/error-reporter';

/** @typedef { import('./__types').Job } Job */
/** @typedef { import('./__types').UserFacingStatuses } UserFacingStatuses */
/** @typedef { import('./__types').UserFacingStatus } UserFacingStatus */

/** @type {UserFacingStatuses} */
export const statuses = {
    Success: 'Success',
    Failed: 'Failed',
    Pending: 'Pending',
    Running: 'Running',
    Terminating: 'Terminating',
    Canceled: 'Canceled',
};

/** @param {Job} job */
export const getStatus = (job) => {
    const canceled = job.error === statuses.Canceled
        || job.output?.error?.message === statuses.Canceled;

    switch (job.status) {

        case 'success':
            return statuses.Success;
        case 'error':
            return canceled ? statuses.Canceled : statuses.Failed;
        case 'ext_error':
            return canceled ? statuses.Terminating : statuses.Failed;
        case 'pending':
            return statuses.Pending;
        case 'running':
            return statuses.Running;
        case 'booting':
            return statuses.Running;
        default:
            reportError(new Error(`Invalid Job status (${job.status}) for jobId (${job.id})`));
            return undefined;
    }
};

/** @param {UserFacingStatus} [status] */
const isFinished = (status) => {

    /** @type {Array<any>} */
    const isRunningStatuses = [
        statuses.Pending,
        statuses.Running,
        statuses.Terminating,
    ];

    const isRunning = isRunningStatuses.includes(status);

    return !isRunning;
};

/**
 * Extracts user facing status and whether job is still running or not
 * @param {Job} job
 */
export const extractStatusData = (job) => {
    const status = getStatus(job);
    const finished = isFinished(status);

    return {
        status,
        finished,
    };
};
