import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import {
    Layout,
    Button,
    Icon,
    Tooltip,
    breakpoints,
} from 'ui-components';

import RecipesDialog from '../../pages/source-page/nested-pages/recipes-dialog';
import { useSourcePageContext } from '../../pages/source-page/use-source-page/source-page-context';
import { isQueryFinished } from '../../models/queries';
import CancelQueryDialog from '../cancel-query-dialog';
import WorkbenchMenuDropDown from './workbench-menu-dropdown';
import WorkbenchMenuExpanded from './workbench-menu-expanded';
import ModeSelectorDropdown from './mode-selector-dropdown';
import useWorkbenchRecipes from './use-workbench-recipes';
import useModeSelector from './use-mode-selector';
import EditJoinDialog from './edit-join-dialog';
import JoinDialog from './join-dialog';
import FilterDialog from './filter-dialog';
import OrderDialog from './order-dialog';
import LimitDialog from './limit-dialog';

/** @typedef { import('lib/query-builder').Column } Column */
/** @typedef { import('lib/query-builder').Filter } Filter */
/** @typedef { import('lib/query-builder').FilterType } FilterType */
/** @typedef { import('lib/query-builder').Order } Order */
/** @typedef { import('lib/query-builder').MainTable } MainTable */
/** @typedef { import('lib/query-builder').JoinTable } JoinTable */
/** @typedef { import('lib/query-builder').Tables } Tables */
/** @typedef { import('lib/query-builder').Join } Join */
/** @typedef { import('models/queries/__types').Status } Status */
/** @typedef { import('models/queries/__types').QueryResult } QueryResult */
/** @typedef { import('./__types').Mode } Mode */
/** @typedef { import('./__types').MenuItem } MenuItem */
/** @typedef { import('./__types').TableData } TableData */
/** @typedef { import('./__types').ViewData } ViewData */
/** @typedef { { expanded?: boolean } } MenuProps */

/**
 * @typedef { import('styled-components').ThemedStyledFunction<C, any, O> } Styled
 * @template { 'span' } C
 * @template { object } [O={}]
 */

/**
 * @typedef { object } JoinToEdit
 * @prop { JoinTable } [joinTable]
 * @prop { MainTable } [mainTable]
 * @prop { number } [position]
 */

/**
 * @typedef { object } Props
 *
 * @prop { Mode } mode
 * @prop { (newMode: Mode) => void } onModeChanged
 *
 * @prop { TableData[] } tables
 * @prop { ViewData[] } views
 *
 * @prop { boolean } opening
 * @prop { boolean } queryIsEmpty
 * @prop { boolean } queryIsRunning
 * @prop { boolean } hasQueryError
 * @prop { boolean } hasQueryResults
 * @prop { boolean } exportingCsv
 * @prop { QueryResult } [activeResult]
 * @prop { () => void } onRunClicked
 * @prop { () => void } onNewClicked
 * @prop { () => void } onFormatClicked
 * @prop { (newQuery: string) => void } onQueryChanged
 * @prop { (id: QueryResult['id'], updates: Partial<QueryResult>) => void } updateQueryResult
 *
 * @prop { boolean } leftMenuIsOpen
 * @prop { boolean } dropDownIsOpen
 * @prop { boolean } menuIsExpanded
 * @prop { () => void } onLeftMenuToggled
 * @prop { () => void } onDropDownToggled
 *
 * @prop { string } builtQuery
 * @prop { Column[] } allColumns
 * @prop { Tables } allTables
 * @prop { Omit<Column, 'id'> } [pendingColumn]
 * @prop { { where: Filter[], having: Filter[]} } queryFilter
 * @prop { Order[] } queryOrder
 * @prop { number | undefined } queryLimit
 * @prop { boolean } hasUndoChange
 * @prop { boolean } hasRedoChange
 * @prop { (params: Omit<JoinTable, 'alias'>) => void } addJoinTable
 * @prop { (position: number, join: Join) => void } changeJoinTable
 * @prop { (position: number) => void } removeJoinTable
 * @prop { (where: Filter[], having: Filter[]) => void } changeFilter
 * @prop { (orderBy: Order[]) => void } changeOrder
 * @prop { (limit?: number) => void } changeLimit
 * @prop { () => void } undoChange
 * @prop { () => void } redoChange
 * @prop { () => void } clearConfig
 * @prop { boolean } hideHidden
 * @prop { () => void } toggleHidden
 * @prop { boolean } someHidden
 */

/** @type { React.FC<Props> } */
const WorkbenchMenu = React.memo(({
    mode,
    onModeChanged,

    tables,
    views,

    opening,
    queryIsEmpty,
    queryIsRunning,
    hasQueryError,
    hasQueryResults,
    exportingCsv,
    activeResult,
    onRunClicked,
    onNewClicked,
    onFormatClicked,
    onQueryChanged,
    updateQueryResult,

    leftMenuIsOpen,
    dropDownIsOpen,
    menuIsExpanded,
    onLeftMenuToggled,
    onDropDownToggled,

    builtQuery,
    allColumns,
    allTables,
    pendingColumn,
    queryFilter,
    queryOrder,
    queryLimit,
    hasUndoChange,
    hasRedoChange,
    addJoinTable,
    changeJoinTable,
    removeJoinTable,
    changeFilter,
    changeOrder,
    changeLimit,
    undoChange,
    redoChange,
    clearConfig,
    hideHidden,
    someHidden,
    toggleHidden,
}) => {
    const { recipes } = useSourcePageContext();

    const clearQueryAndResults = useCallback(() => {
        clearConfig();
        onNewClicked();
    }, [clearConfig, onNewClicked]);

    const {
        isRecipesOpen,
        isSourcesPage,
        onCloseRecipes,
        onOpenRecipes,
    } = useWorkbenchRecipes();

    const { isModeSelectorAllowed } = useModeSelector();

    const [editJoinDialogOpen, setEditJoinDialogOpen] = useState(false);
    const [joinDialogOpen, setJoinDialogOpen] = useState(false);
    const [filterDialogOpen, setFilterDialogOpen] = useState(false);
    const [orderDialogOpen, setOrderDialogOpen] = useState(false);
    const [limitDialogOpen, setLimitDialogOpen] = useState(false);

    const [queryToCancel, setQueryToCancel] = useState(
        /** @type { QueryResult= } */ (undefined)
    );

    const [joinToEdit, setJoinToEdit] = useState(
        /** @type { JoinToEdit= } */ (undefined)
    );

    /** @type { (joinToEdit: JoinToEdit) => void } */
    const openEditJoinDialog = useCallback((joinToEdit) => {
        setJoinToEdit(joinToEdit);
        setJoinDialogOpen(false);
        setEditJoinDialogOpen(true);
    }, []);

    const openEditJoinDialogOnPendingColumn = useCallback(() => {
        if (!pendingColumn) {
            return;
        }

        const { table: name, schema } = pendingColumn;
        const [mainTable, ...joinTables] = allTables;

        openEditJoinDialog({
            joinTable: {
                name,
                schema,
                alias: '',
                join: {
                    type: 'inner',
                    on: [],
                },
            },
            ...(joinTables.length === 0 ? { mainTable } : {}),
        });
    }, [pendingColumn, openEditJoinDialog]);

    const closeEditJoinDialog = useCallback(() => {
        setEditJoinDialogOpen(false);
        if (!pendingColumn) {
            setJoinDialogOpen(true);
        }
        setJoinToEdit(undefined);
    }, [pendingColumn]);

    const openJoinDialog = useCallback(() => {
        if (pendingColumn) {
            openEditJoinDialogOnPendingColumn();
            return;
        }
        setJoinDialogOpen(true);
    }, [pendingColumn, openEditJoinDialogOnPendingColumn]);

    const closeJoinDialog = useCallback(() => setJoinDialogOpen(false), []);

    const openFilterDialog = useCallback(() => setFilterDialogOpen(true), []);
    const closeFilterDialog = useCallback(() => setFilterDialogOpen(false), []);

    const openOrderDialog = useCallback(() => setOrderDialogOpen(true), []);
    const closeOrderDialog = useCallback(() => setOrderDialogOpen(false), []);

    const openLimitDialog = useCallback(() => setLimitDialogOpen(true), []);
    const closeLimitDialog = useCallback(() => setLimitDialogOpen(false), []);

    const openCancelDialog = () => setQueryToCancel(activeResult);
    const closeCancelDialog = () => setQueryToCancel(undefined);

    /** @type { (id: QueryResult['id']) => void } */
    const onQueryCancelled = useCallback(id => {
        updateQueryResult(id, {
            status: /** @type { Status } */ ('Canceled'),
        });
    }, [updateQueryResult]);

    const items = useMemo(/** @type { () => MenuItem[] } */ () => {
        if (mode === 'query-builder') {
            return [
                {
                    label: 'Clear',
                    tooltip: 'Clear Query',
                    icon: 'undo',
                    onClick: clearQueryAndResults,
                    disabled: !builtQuery?.trim(),
                    square: true,
                },
                {
                    label: 'Undo',
                    tooltip: 'Undo Change',
                    icon: 'arrow-left-long-to-line',
                    onClick: undoChange,
                    disabled: !hasUndoChange,
                    square: true,
                },
                {
                    label: 'Redo',
                    tooltip: 'Redo Change',
                    icon: 'arrow-right-long-to-line',
                    onClick: redoChange,
                    disabled: !hasRedoChange,
                    square: true,
                },
                {
                    label: 'Join',
                    tooltip: 'Join Query',
                    icon: 'diagram-venn',
                    onClick: openJoinDialog,
                    disabled: !builtQuery?.trim(),
                    square: true,
                    active: allTables.length > 1,
                },
                {
                    label: 'Filter',
                    tooltip: 'Filter Query',
                    icon: 'filter',
                    onClick: openFilterDialog,
                    disabled: !builtQuery?.trim(),
                    square: true,
                    active: Object.keys(queryFilter).some(k => (
                        queryFilter[/** @type { FilterType } */ (k)].length > 0
                    )),
                },
                {
                    label: 'Order',
                    tooltip: 'Order Query',
                    icon: 'arrow-down-arrow-up',
                    onClick: openOrderDialog,
                    disabled: !builtQuery?.trim(),
                    square: true,
                    active: queryOrder.length > 0,
                },
                {
                    label: hideHidden ? 'Show Hidden' : 'Hide Hidden',
                    tooltip: hideHidden ? 'Show Hidden' : 'Hide Hidden',
                    icon: 'eye-slash',
                    onClick: toggleHidden,
                    disabled: !someHidden,
                    square: true,
                    active: someHidden && hideHidden,
                },
                {
                    label: 'Limit',
                    tooltip: 'Limit Query',
                    icon: 'brackets',
                    onClick: openLimitDialog,
                    value: queryLimit || '',
                    type: 'number',
                    onChange: limit => changeLimit(
                        limit
                            ? Math.abs(Number(limit))
                            : undefined
                    ),
                    disabled: !builtQuery?.trim(),
                },
                {
                    label: 'SQL',
                    tooltip: '(Coming Soon)', // TODO: SQL Query
                    icon: 'square-code',
                    onClick: () => {},
                    disabled: true,
                    square: true,
                },
            ];
        }

        return [
            {
                label: 'Format',
                tooltip: 'Format Query',
                icon: 'code',
                onClick: onFormatClicked,
                disabled: queryIsEmpty
                    || queryIsRunning,
            },
            {
                label: 'New',
                tooltip: 'Clear the Editor',
                icon: 'folder-plus',
                prefix: 'fas',
                onClick: onNewClicked,
                disabled: (queryIsEmpty && !hasQueryError && !hasQueryResults)
                    || queryIsRunning,
            },
            {
                label: 'Recipes',
                tooltip: 'Open Recipes',
                icon: 'flask-round-potion',
                onClick: onOpenRecipes,
                disabled: (isSourcesPage ? !recipes?.length : false)
                    || queryIsRunning,
            },
        ];
    }, [
        mode,
        recipes,
        isSourcesPage,
        queryIsEmpty,
        queryIsRunning,
        hasQueryError,
        hasQueryResults,
        onFormatClicked,
        onNewClicked,
        onOpenRecipes,
        allTables,
        queryFilter,
        queryOrder,
        queryLimit,
        builtQuery,
        hasUndoChange,
        hasRedoChange,
        changeLimit,
        undoChange,
        redoChange,
        openJoinDialog,
        openFilterDialog,
        openOrderDialog,
        openLimitDialog,
        clearQueryAndResults,
        hideHidden,
        someHidden,
        toggleHidden,
    ]);

    useEffect(openEditJoinDialogOnPendingColumn, [pendingColumn]);

    const recipesDialogProps = {
        isOpen: isRecipesOpen,
        onClose: onCloseRecipes,
        onQueryChanged,
    };

    const editJoinDialogProps = {
        key: `edit-join-dialog-${editJoinDialogOpen}`,
        open: editJoinDialogOpen,
        onClose: closeEditJoinDialog,
        tables,
        views,
        allColumns,
        allTables,
        addJoinTable,
        changeJoinTable,
        removeJoinTable,
        ...joinToEdit,
    };

    const joinDialogProps = {
        key: `join-dialog-${joinDialogOpen}`,
        open: joinDialogOpen,
        onClose: closeJoinDialog,
        allColumns,
        allTables,
        openEditJoinDialog,
        removeJoinTable,
    };

    const filterDialogProps = {
        key: `filter-dialog-${filterDialogOpen}`,
        open: filterDialogOpen,
        onClose: closeFilterDialog,
        queryFilter,
        changeFilter,
        allColumns,
    };

    const orderDialogProps = {
        key: `order-dialog-${orderDialogOpen}`,
        open: orderDialogOpen,
        onClose: closeOrderDialog,
        queryOrder,
        changeOrder,
        allColumns,
    };

    const limitDialogProps = {
        key: `limit-dialog-${limitDialogOpen}`,
        open: limitDialogOpen,
        onClose: closeLimitDialog,
        queryLimit,
        changeLimit,
    };

    const cancelDialogProps = {
        key: `cancel-dialog-${!!queryToCancel?.id}`,
        queryToCancel,
        closeDialog: closeCancelDialog,
        onQueryCancelled,
    };

    return (
        <Layout flex alignItems="center">
            <LeftMenuToggle
                open={leftMenuIsOpen}
                onClick={onLeftMenuToggled}
                loading={opening}
                spacing="ml-4 mr-1 my-3 p-2"
            />

            <VisibleOnSmallScreen expanded={menuIsExpanded}>
                <WorkbenchMenuDropDown
                    open={dropDownIsOpen}
                    onClick={onDropDownToggled}
                    items={items}
                    spacing="m-0 mr-1 p-2"
                />
            </VisibleOnSmallScreen>

            <VisibleOnLargeScreen expanded={menuIsExpanded}>
                <WorkbenchMenuExpanded
                    items={items}
                    spacing="mx-1"
                />
            </VisibleOnLargeScreen>

            <ActionsContainer>
                {mode !== 'query-builder' && (
                    <RunButton
                        spacing="m-0 mr-4 py-1 pl-3 pr-4"
                        queryIsRunning={queryIsRunning}
                        queryIsEmpty={queryIsEmpty}
                        hasQueryResults={hasQueryResults}
                        exportingCsv={exportingCsv}
                        activeResult={activeResult}
                        onRunClicked={onRunClicked}
                        openCancelDialog={openCancelDialog}
                    />
                )}

                {isModeSelectorAllowed && (
                    <ModeSelectorDropdown
                        key={mode}
                        mode={mode}
                        onModeChanged={onModeChanged}
                        spacing="m-0 mr-4 py-1 pl-4 pr-3"
                    />
                )}
            </ActionsContainer>

            <RecipesDialog {...recipesDialogProps} />
            <EditJoinDialog {...editJoinDialogProps} />
            <JoinDialog {...joinDialogProps} />
            <FilterDialog {...filterDialogProps} />
            <OrderDialog {...orderDialogProps} />
            <LimitDialog {...limitDialogProps} />
            <CancelQueryDialog {...cancelDialogProps} />
        </Layout>
    );
});

/**
 * @typedef { object } LeftMenuToggleProps
 * @prop { boolean } open
 * @prop { () => void } onClick
 * @prop { boolean } loading
 * @prop { string } spacing
 */

/**
 * @param { LeftMenuToggleProps } props
 * @returns { React.ReactElement }
 */
function LeftMenuToggle({ open, ...props }) {
    return (
        <Tooltip content={open ? 'Close' : 'Open'} placement="bottom-start" interactive={false}>
            <Button square type="plain" {...props}>
                <Icon icon={open ? 'outdent' : 'indent'} />
            </Button>
        </Tooltip>
    );
}

/**
 * @typedef { object } RunButtonProps
 * @prop { boolean } queryIsRunning
 * @prop { boolean } queryIsEmpty
 * @prop { boolean } hasQueryResults
 * @prop { boolean } exportingCsv
 * @prop { QueryResult } [activeResult]
 * @prop { () => void } onRunClicked
 * @prop { () => void } openCancelDialog
 * @prop { string } spacing
 */

/**
 * @param { RunButtonProps } props
 * @returns { React.ReactElement }
 */
function RunButton({
    queryIsRunning,
    queryIsEmpty,
    hasQueryResults,
    exportingCsv,
    activeResult,
    onRunClicked,
    openCancelDialog,
    ...props
}) {
    if (activeResult?.id && queryIsRunning) {
        return (
            <Button
                type="outline"
                color="primary"
                onClick={openCancelDialog}
                disabled={isQueryFinished(activeResult)}
                {...props}
            >
                <Icon icon="stop-circle" spacing="mr-1" />Stop
            </Button>
        );
    }

    return (
        <Tooltip placement="left" content="Run (Shift + Enter)" interactive={false}>
            <Button
                type="outline"
                color="primary"
                loading={queryIsRunning}
                disabled={queryIsEmpty || (hasQueryResults && exportingCsv)}
                onClick={() => onRunClicked()}
                {...props}
            >
                <Icon icon="play-circle" spacing="mr-1" />Run
            </Button>
        </Tooltip>
    );
}

const ActionsContainer = styled.div`
    && {
        display: flex;
        margin-left: auto;
    }
`;

const VisibleOnSmallScreen = /** @type { Styled<'span', MenuProps> } */ (styled.span)`
    && {
        display: inline-flex;

        @media (min-width: ${breakpoints.lg}) {
            display: ${props => (props.expanded ? 'none' : 'inline-flex')};
        }
    }
`;

const VisibleOnLargeScreen = /** @type { Styled<'span', MenuProps> } */ (styled.span)`
    && {
        display: none;

        @media (min-width: ${breakpoints.lg}) {
            display: ${props => (props.expanded ? 'inline-flex' : 'none')};
        }
    }
`;

export default WorkbenchMenu;
