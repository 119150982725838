import { useState, useCallback } from 'react';

function usePagination(initialPage = 0, pageSize = 20) {
    const [page, setPage] = useState(initialPage);

    /** @type { (newPage: number) => void } */
    const updatePage = useCallback((newPage) => {
        setPage(newPage);
    }, []);

    return {
        page,
        pageSize,
        updatePage,
    };
}

export default usePagination;
