import React, { useEffect, useCallback, useState, useRef } from 'react';
import styled from 'styled-components';
import {
    Button,
    Dropdown,
    List,
    ListItem,
    Loader,
    Layout,
    Typography,
    Tooltip,
    Icon,
    Card,
    ExpansionPanel,
    colors,
    spacings,
} from 'ui-components';

import { useSourcePageContext } from '../use-source-page/source-page-context';
import SourceForm from '../form';
import SourceAdvanced from '../advanced/source-advanced';
import Banners from '../banners';
import SourceCollectOverlay from '../source-collect-overlay';
import NestedPageWrapper from './nested-page-wrapper';

/** @typedef {import('../__types').SourceSettingsProps} SourceAdvancedProps */

const DropDownButton = styled(Button)`
    &&& {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 1px solid rgba(255, 255, 255, 0.5);
        width: 1rem;
        height: 100%;
    }
`;

/** @type {React.FC<{clearPendingChanges: () => void}>} */
export const DiscardChangesDropDown = ({ clearPendingChanges }) => {
    const [open, setOpen] = useState(false);

    const onOpen = useCallback(() => setOpen(true), [setOpen]);
    const onClose = useCallback(() => setOpen(false), [setOpen]);

    return (
        <Dropdown
            open={open}
            spacing="m-0"
            opener={
                <DropDownButton
                    color="secondary"
                    spacing="m-0"
                    onClick={onOpen}
                >
                    <Icon icon="angle-down" size="sm" />
                </DropDownButton>
            }
            onClose={onClose}
        >
            <List>
                <ListItem
                    button
                    onClick={clearPendingChanges}
                    label="Discard all changes"
                    iconName="undo"
                />
            </List>
        </Dropdown>
    );
};

const Settings = () => {
    const {
        source,
        sourceType,
        flattenedParams,
        saving,
        collecting,
        collectable,
        collectSource,
        saveSource,
        shouldSave,
        collectableAfterSave,
        fieldValidationErrors,
        getParamState,
        getParamValue,
        isParamRequired,
        areParamDepsMet,
        loadDynamicParam,
        loadSchemas,
        loadValidationParam,
        setParamState,
        setParamValue,
        onCancelJob,
        canUseRecipes,
        recipes,
        updateAdvanced,
    } = useSourcePageContext();

    /** @type {React.MutableRefObject<HTMLDivElement | null>} */
    const header = useRef(null);

    const cancelJob = useCallback(() => {
        onCancelJob(source?.job);
    }, [source]);

    useEffect(() => {
        /** @type {HTMLDivElement} */
        // @ts-ignore
        const main = document.querySelector('#source-nested-router');

        const handler = () => {
            if (main.scrollTop > 80) {
                header.current?.classList.add('sticky');
            } else {
                header.current?.classList.remove('sticky');
            }
        };

        main.addEventListener('scroll', handler);

        return () => {
            main.removeEventListener('scroll', handler);
        };

    }, []);

    if (!source || !sourceType) {
        return null;
    }

    /** @type {SourceAdvancedProps} */
    const props = {
        source,
        sourceType,
        fieldValidationErrors,
        flattenedParams,
        getParamState,
        getParamValue,
        isParamRequired,
        areParamDepsMet,
        loadDynamicParam,
        loadSchemas,
        loadValidationParam,
        setParamState,
        setParamValue,
        updateAdvanced,
    };

    const collectingMessage = (
        <Layout flex alignItems="center">
            <Layout spacing="mr-2">
                <Loader active color="secondaryText" />
            </Layout>

            <Typography
                color="secondaryText"
                variant="body2"
                spacing="m-0"
            >
                Collecting data...
            </Typography>

            <Tooltip
                content="Stop collecting"
                placement="top"
                interactive={false}
            >
                <Button
                    type="plain"
                    spacing="py-1"
                    onClick={cancelJob}
                >
                    <Icon icon="stop-circle" color="primary" />
                </Button>
            </Tooltip>
        </Layout>
    );

    const saveChangesButton = (
        <Layout flex>
            <Button
                color="secondary"
                disabled={!collectableAfterSave}
                loading={saving}
                onClick={saveSource}
                spacing="m-0"
            >
                Save Changes
            </Button>
        </Layout>
    );

    const collectButton = (
        <Button
            color="primary"
            spacing="m-0"
            disabled={!collectable}
            onClick={collectSource}
        >
            Collect Data
        </Button>
    );

    return (
        <NestedPageWrapper maxWidth>
            <HeaderContainer>
                <HeaderContent ref={header}>
                    <Typography variant="h6" spacing="m-0">
                        Connector Settings
                    </Typography>
                    <Layout flex>
                        {(shouldSave && collectableAfterSave)
                            ? saveChangesButton : (
                                collecting
                                    ? collectingMessage
                                    : collectButton
                            )}
                    </Layout>
                </HeaderContent>
            </HeaderContainer>

            <Banners />

            <Card spacing="my-5">
                <SourceCollectOverlay
                    collecting={collecting}
                    status={source.job?.status}
                    canUseRecipes={canUseRecipes}
                    sourceId={source.id}
                    recipes={recipes}
                />

                <Layout spacing="pb-5">
                    <SourceForm category="general" {...props} />
                </Layout>

                <Layout>
                    <ExpansionPanel flat label="Advanced Settings (optional)">
                        <SourceAdvanced {...props} />
                    </ExpansionPanel>
                </Layout>
            </Card>
        </NestedPageWrapper>
    );
};

const HeaderContainer = styled.div`
    position: sticky;
    top: -1px;
    z-index: 101;
    background: ${colors.background};
    width: calc(100% + 12px);
    margin-left: -6px;
    margin-right: -6px;
    padding-left: 6px;
    padding-right: 6px;
`;

const HeaderContent = styled.div`
    padding: ${spacings[2]}rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &.sticky {
        border-bottom: 1px solid ${colors.secondaryInterface};
    }
`;

export default Settings;
