import {
    get,
} from '../../lib/ajax';

import { addBreadcrumb } from '../../lib/error-reporter';

/** @typedef { import('./__types').Job } Job */
/** @typedef { import('./__types').JobsSummary } JobsSummary */

/** @type { (query: any) => Promise<Job[]> } */
export const fetchJobs = async (query) => {
    try {
        const { data: jobs } = await get('/jobs/', { query });
        return jobs;
    } catch (error) {
        addBreadcrumb('Could not fetch jobs', { query });
        throw error;
    }
};

/** @type { () => Promise<JobsSummary> } */
export const fetchJobsSummary = async () => {
    try {
        const { data: summary } = await get('/jobs/summary');
        return summary;
    } catch (error) {
        addBreadcrumb('Could not fetch jobs summary');
        throw error;
    }
};
