import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { SentryRoutes } from '../lib/sentry';

import AppPage from './app-page';

/** @typedef { import('./__types').PageRoute } PageRoute */

/**
 * @typedef { object } AppRoutesProps
 * @prop { PageRoute[] } routes
 * @prop { boolean } [nested]
 */

/** @type {React.FC<AppRoutesProps>} */
const AppRoutes = ({ routes, nested }) => {
    const RoutesComponent = nested ? Routes : SentryRoutes;

    return (
        <RoutesComponent>
            {routes.map(({ id, name, path, Component, redirect, ...props }) => (
                <Route
                    key={id}
                    element={(name && path && Component
                        ? <AppPage name={name} Component={Component} />
                        : <Navigate to="/" {...redirect} replace />
                    )}
                    path={path || '*'}
                    {...props}
                />
            ))}
        </RoutesComponent>
    );
};

export default AppRoutes;
