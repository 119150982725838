import React from 'react';

import PageLayout from '../../shared/page-layout';
import Workbench from '../../shared/workbench';

/** @typedef { import('app/__types').PageProps } PageProps */

/** @type { React.FC<PageProps> } */
const WorkbenchPage = () => {
    return (
        <PageLayout title="Workbench" flex stretched>
            <Workbench />
        </PageLayout>
    );
};

export default WorkbenchPage;
