/** @typedef { string } ISODateString */

/** @type { (name: string) => string } */
export function getCookie(name) {
    const value = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)');
    return value ? (value.pop() || '') : '';
}

/** @type { (name: string, value: string, expires: ISODateString) => void } */
export function setCookie(name, value, expires) {
    document.cookie = `${name}=${value}; expires=${expires}; path=/`;
}

/** @type { (name: string) => void } */
export function deleteCookie(name) {
    const expires = (new Date('1970-01-01')).toUTCString();
    setCookie(name, '', expires);
}
