import { useState, useCallback } from 'react';

import useDebounce from './use-debounce';

function useSearch(initialKeyword = '', debounceDelay = 250) {
    const [keyword, setKeyword] = useState(initialKeyword);

    const updateKeyword = useCallback((newKeyword) => {
        setKeyword(newKeyword);
    }, []);

    const debouncedKeyword = useDebounce(keyword, debounceDelay);

    return {
        keyword,
        updateKeyword,
        debouncedKeyword,
    };
}

export default useSearch;
