/** @typedef { import('./types').DbType } DbType */
/** @typedef { import('./types').Position } Position */
/** @typedef { import('./types').Order } Order */
/** @typedef { import('./types').Direction } Direction */
/** @typedef { import('./types').Operator } Operator */
/** @typedef { import('./types').ComparisonOperator } ComparisonOperator */
/** @typedef { import('./types').Filter } Filter */
/** @typedef { import('./types').FilterColumn } FilterColumn */
/** @typedef { import('./types').FilterType } FilterType */
/** @typedef { import('./types').Logic } Logic */
/** @typedef { import('./types').Value } Value */
/** @typedef { import('./types').Column } Column */
/** @typedef { import('./types').ColumnValue } ColumnValue */
/** @typedef { import('./types').Join } Join */
/** @typedef { import('./types').JoinType } JoinType */
/** @typedef { import('./types').TableType } TableType */
/** @typedef { import('./types').MainTable } MainTable */
/** @typedef { import('./types').JoinTable } JoinTable */
/** @typedef { import('./types').JoinColumn } JoinColumn */
/** @typedef { import('./types').Table } Table */
/** @typedef { import('./types').Tables } Tables */
/** @typedef { import('./types').Pending } Pending */
/** @typedef { import('./types').Config } Config */

/** @typedef { { [columnName: string]: Column } } AvailableColumns } */
/** @typedef { { [tableName: string]: Table } } AvailableTables } */

const MAIN_TABLE_INDEX = 0;
const JOIN_TABLE_INDEX = 1;

/** @type { (dbType: DbType) => Config } */
function createConfig(dbType) {
    return {
        dbType,
        columns: [],
        tables: [],
        hideHidden: true,
    };
}

/** @type { (config: any) => config is Config } */
function isValidConfig(config) {
    if (config && typeof config === 'object') {
        return !!config.dbType
            && Array.isArray(config.columns)
            && Array.isArray(config.tables);
    }
    return false;
}

/** @type { (config: Config, dbType: DbType) => Config } */
function changeDbType(config, dbType) {
    config.dbType = dbType;
    return config;
}

/** @type { (config: Config, params: Omit<Column, 'id'>, position?: Position) => Config } */
function addColumn(config, params, position = 'last') {
    const { columns } = config;
    const { schema, table, name, dataType } = params;

    const id = `${schema}.${table}.${name}`;
    const column = { id, schema, table, name, dataType };

    // If the column already exists, then make it unique to allow duplicates
    const exists = columns.some(c => c.id === id || c.id.startsWith(id + '-'));

    if (exists) {
        const state = { i: 0, exists: false };

        do {
            column.id = `${id}-${++state.i}`;
            state.exists = columns.some(c => c.id === column.id);
        } while (state.exists);
    }

    insertColumn(config, position, column);

    if (params.alias) {
        changeAlias(config, position, params.alias);
    } else if (columns.some(c => isDuplicate(c, { id, name }))) {
        changeAlias(config, position, name);
    }

    if (params.function) {
        changeFunction(config, position, params.function);
    }

    return config;
}

/** @type { (config: Config, position: Position, column: Column) => Config } */
function insertColumn(config, position, column) {
    const { columns } = config;

    if (isNumber(position) && position > -1) {
        columns.splice(position, 0, column);
    } else if (position === 'first') {
        columns.unshift(column);
    } else if (position === 'last') {
        columns.push(column);
    }

    return config;
}

/** @type { (config: Config, position: Position) => Config } */
function removeColumn(config, position) {
    const { columns } = config;

    if (isNumber(position) && position > -1) {
        columns.splice(position, 1);
    } else if (position === 'first') {
        columns.shift();
    } else if (position === 'last') {
        columns.pop();
    }

    return config;
}

/** @type { (config: Config, from: Position, to: Position) => Config } */
function moveColumn(config, from, to) {
    const column = findColumn(config, from);

    if (column) {
        removeColumn(config, from);
        insertColumn(config, to, column);
    }

    return config;
}

/** @type { (config: Config, position: Position) => Config } */
function toggleColumn(config, position) {
    const column = findColumn(config, position);

    if (column) {
        column.hidden = !column.hidden;
    }

    return config;
}

/** @type { (config: Config, position: Position) => Column | undefined } */
function findColumn(config, position) {
    const { columns } = config;

    if (isNumber(position) && position > -1) {
        return columns[position];
    } else if (position === 'first') {
        return columns[0];
    } else if (position === 'last') {
        return columns[columns.length - 1];
    }

    return undefined;
}

/** @type { (config: Config, name: string) => string } */
function getTableAlias(config, name) {
    const { tables } = config;

    const alias = resolveTableAlias(name);

    if (tables.some(t => t?.alias === alias)) {
        let i = 1;

        while (tables.some(t => t?.alias === `${alias}_${i}`)) {
            i++;
        }

        return `${alias}_${i}`;
    }

    return alias;
}

/** @type { (config: Config, params: Omit<MainTable, 'alias'>) => Config } */
function changeMainTable(config, params) {
    const { tables } = config;
    const { name, schema } = params;

    const mainTable = {
        name,
        schema,
        alias: getTableAlias(config, name),
    };

    tables[MAIN_TABLE_INDEX] = mainTable;

    return config;
}

/** @type { (config: Config, params: Omit<JoinTable, 'alias'>) => Config } */
function addJoinTable(config, params) {
    const { tables } = config;
    const { name, schema, join } = params;

    const joinTable = {
        name,
        schema,
        alias: getTableAlias(config, name),
        join,
    };

    tables[Math.max(JOIN_TABLE_INDEX, tables.length)] = joinTable;

    return config;
}

/** @type { (config: Config, position: number, join: Join) => Config } */
function changeJoinTable(config, position, join) {
    const table = findJoinTable(config, position);

    if (table) {
        table.join = join;
    }

    return config;
}

/** @type { (config: Config, position: number) => Config } */
function removeJoinTable(config, position) {
    const { tables } = config;

    if (isNumber(position) && position > -1) {
        tables.splice(JOIN_TABLE_INDEX + position, 1);
    }

    return config;
}

/** @type { (config: Config, position: number) => JoinTable | undefined } */
function findJoinTable(config, position) {
    const { tables } = config;

    const [, ...joinTables] = tables;

    if (isNumber(position) && position > -1) {
        return joinTables[position];
    }

    return undefined;
}

/** @type { (config: Config, column: Omit<Column, 'id'>, position?: Position) => Config } */
function changePending(config, column, position = 'last') {
    config.pending = { column, position };
    return config;
}

/** @type { (config: Config) => Pending | undefined } */
function getPending(config) {
    return config.pending;
}

/** @type { (config: Config) => Config } */
function clearPending(config) {
    config.pending = undefined;
    return config;
}

/** @type { (config: Config, position: Position, alias: NonNullable<Column['alias']>) => Config } */
function changeAlias(config, position, alias) {
    const { columns } = config;

    const column = findColumn(config, position);

    if (column) {
        const { id, name } = column;

        // If the alias is already used, then make it unique, otherwise use it
        const exists = columns.some(c => isDuplicate(c, { id, name, alias }));

        if (exists) {
            /** @type { { i: number, exists: boolean, params: IsDuplicateParams } } */
            const state = { i: 0, exists: false, params: { id, name, alias } };

            const newAlias = alias.replace(/_\d+$/, '');

            do {
                state.params.alias = `${newAlias}_${++state.i}`;
                state.exists = columns.some(c => isDuplicate(c, state.params));
            } while (state.exists);

            column.alias = state.params.alias;
        } else {
            column.alias = alias;
        }
    }

    return config;
}

/** @type { (config: Config, position: Position, newFunction: Column['function']) => Config } */
function changeFunction(config, position, newFunction) {
    const column = findColumn(config, position);

    if (column) {
        const alias = column.function && resolveAlias(column.function, column.name);

        column.function = newFunction;

        const shouldChangeAlias = !column.alias
            || (column.alias === alias || column.alias.startsWith(alias + '_'))
            || (column.alias === column.name || column.alias.startsWith(column.name + '_'));

        if (shouldChangeAlias) {
            const newAlias = newFunction
                ? resolveAlias(newFunction, column.name)
                : column.name;

            changeAlias(config, position, newAlias);
        }
    }

    return config;
}

/** @type { (config: Config, where: Filter[], having: Filter[]) => Config } */
function changeFilter(config, where, having) {
    const { columns } = config;

    config.where = where.filter(f => hasColumn(columns, f.column));

    config.having = having.filter(f => hasColumn(columns, f.column, {
        checkFunction: true,
    }));

    return config;
}

/** @type { (config: Config) => { where: Filter[], having: Filter[] } } */
function getFilter(config) {
    const { where = [], having = [] } = config;

    return {
        where,
        having,
    };
}

/** @type { (config: Config, orderBy: Order[]) => Config } */
function changeOrder(config, orderBy) {
    const { columns } = config;

    config.orderBy = orderBy.filter(o => columns.some(c => c.id === o.id));

    return config;
}

/** @type { (config: Config) => Order[] } */
function getOrder(config) {
    return config.orderBy || [];
}

/** @type { (config: Config, limit?: number) => Config } */
function changeLimit(config, limit) {
    config.limit = limit;
    return config;
}

/** @type { (config: Config) => number | undefined } */
function getLimit(config) {
    return config.limit;
}

/** @type { (config: Config) => boolean } */
function hasColumns(config) {
    return config.columns.length > 0;
}

/** @type { (config: Config) => Column[] } */
function getColumns(config) {
    return config.columns;
}

/** @type { (config: Config) => Config } */
function clearColumns(config) {
    const { dbType } = config;

    const { hideHidden } = createConfig(dbType);

    const keys = /** @type { Array<keyof Config> } */ (Object.keys(config));

    for (const key of keys) {
        switch (key) {
            case 'columns':
            case 'tables':
                config[key] = [];
                break;

            case 'pending':
            case 'where':
            case 'having':
            case 'orderBy':
            case 'limit':
                delete config[key];
                break;

            case 'hideHidden':
                config[key] = hideHidden;
                break;

            default:
                // do nothing
                break;
        }
    }

    return config;
}

/** @type { (config: Config) => boolean } */
function hasTables(config) {
    return config.tables.filter(t => t).length > 0;
}

/** @type { (config: Config) => Tables } */
function getTables(config) {
    return config.tables;
}

/** @type { (config: Config) => Config } */
function toggleHidden(config) {
    config.hideHidden = !config.hideHidden;
    return config;
}

/** @type { (config: Config) => boolean } */
function getHideHidden(config) {
    return !!config.hideHidden;
}

/** @type { (config: Config) => boolean } */
function getSomeHidden(config) {
    return config.columns.some(column => column.hidden);
}

/** @type { (tables: Tables) => tables is [MainTable, ...JoinTable[]] } */
function hasMainTable(tables) {
    return tables[MAIN_TABLE_INDEX] != null;
}

/** @type { (config: Config) => string } */
function buildQuery(config) {
    const { dbType, columns, tables, limit } = config;

    const visibleColumns = columns.filter(filterHidden);

    if (!visibleColumns.length || !hasMainTable(tables)) {
        return '';
    }

    const availableColumns = visibleColumns
        .reduce((columns, column) => {
            const { alias, function: f } = column;

            const fieldName = resolveFieldName(dbType, tables, column);

            const columnName = (f ? resolveFunction(f, fieldName) : fieldName)
                + (alias ? ` AS ${escapeName(dbType, alias)}` : '');

            columns[columnName] = column;

            return columns;
        }, /** @type { AvailableColumns } */ ({}));

    const availableTables = tables
        .reduce((tables, table) => {
            const { name, schema, alias } = table;

            const tableName = escapeName(dbType, schema, name)
                + (alias ? ` AS ${escapeName(dbType, alias)}` : '');

            tables[tableName] = table;

            return tables;
        }, /** @type { AvailableTables } */ ({}));

    const columnNames = Object.keys(availableColumns);

    const tableNames = Object.entries(availableTables)
        .map(([tableName, table], i) => {
            if ('join' in table) {
                const { join } = table;

                const type = join.type.toUpperCase();

                const availableTables = tables.slice(0, i + 1).reverse();
                const on = buildFilters(dbType, availableTables, join.on);

                return `${type} JOIN ${tableName} ON ${on.join(' ')}`;
            }

            return tableName;
        });

    const hasFunctions = Object.values(availableColumns).some(c => !!c.function);

    const whereFilters = (config.where || [])
        .filter(f => hasColumn(columns, f.column));

    const where = buildFilters(dbType, tables, whereFilters);

    const groupBy = hasFunctions
        ? columnNames
            .filter(columnName => !availableColumns[columnName].function)
            .map(columnName => {
                const [field, alias] = columnName.split(' AS ');
                return alias || field;
            })
        : [];

    const options = { checkFunction: true };

    const havingFilters = (config.having || [])
        .filter(f => hasColumn(columns, f.column, options));

    const having = buildFilters(dbType, tables, havingFilters, options);

    const orderBy = columnNames
        .filter(columnName => {
            const { orderBy = [] } = config;
            const column = availableColumns[columnName];
            return orderBy.some(o => o.id === column.id);
        })
        .map(columnName => {
            const { orderBy = [] } = config;

            const column = availableColumns[columnName];

            const [field, alias] = columnName.split(' AS ');

            const index = orderBy.findIndex(o => o.id === column.id);

            const direction = index !== -1
                ? orderBy[index].direction.toUpperCase()
                : 'ASC';

            return {
                index,
                order: `${alias || field} ${direction}`,
            };
        })
        .sort(sortByIndex)
        .map(({ order }) => order);

    return `
        SELECT ${columnNames.join(', ')}
        FROM ${tableNames.join('\n')}
        ${where.length ? `WHERE ${where.join(' ')}` : ''}
        ${groupBy.length ? `GROUP BY ${groupBy.join(', ')}` : ''}
        ${having.length ? `HAVING ${having.join(' ')}` : ''}
        ${orderBy.length ? `ORDER BY ${orderBy.join(', ')}` : ''}
        ${isNumber(limit) ? `LIMIT ${limit}` : ''};
    `;
}

/**
 * @param { DbType } dbType
 * @param { Table[] } tables
 * @param { Filter[] } filters
 * @param { { checkFunction?: boolean, checkColumn?: boolean } } [options]
 * @returns { string[] }
 */
function buildFilters(dbType, tables, filters, options) {
    const { checkFunction } = options || {};

    return filters.map((filter, i) => {
        const { logic, operator, open, close, column } = filter;

        const fieldName = resolveFieldName(dbType, tables, column);

        const columnName = checkFunction && column.function
            ? resolveFunction(column.function, fieldName)
            : fieldName;

        const value = resolveValue(dbType, tables, filter);

        // [AND | OR] [(] columnName [operator] [value] [)]
        return [
            i > 0 ? logic?.toUpperCase() || 'AND' : '',
            '('.repeat(open || 0), // Add opening parenthesis
            columnName,
            operator.toUpperCase(),
            value,
            ')'.repeat(close || 0), // Add closing parenthesis
        ].join(' ');
    });
}

/**
 * @param { Column[] } columns
 * @param { FilterColumn } column
 * @param { { checkFunction?: boolean } } [options]
 * @returns { boolean }
 */
function hasColumn(columns, column, options) {
    if (options?.checkFunction && !column.function) {
        return false;
    }

    return columns.some(c => (c.name === column.name
        && c.table === column.table
        && c.schema === column.schema
        && (!options?.checkFunction || c.function === column.function)
    ));
}

/** @type { (config: Config, table: Omit<MainTable, 'alias'>) => boolean } */
function hasTable(config, table) {
    return config.tables.some(t => (t
        && t.schema === table.schema
        && t.name === table.name
    ));
}

/** @type { (a: { index: number}, b: { index: number }) => number } */
function sortByIndex(a, b) {
    if (a.index < b.index) {
        return -1;
    }

    if (a.index > b.index) {
        return 1;
    }

    return 0;
}

/** @type { (dbType: DbType, ...names: string[]) => string } */
function escapeName(dbType, ...names) {
    return dbType === 'bigquery'
        ? `\`${names.join('.')}\``
        : names.map(name => `"${name}"`).join('.');
}

/** @type { (value: string) => string } */
function escapeText(value) {
    return `'${value.replace(/'/g, "\\'")}'`;
}

/** @type { (value: string) => string } */
function escapeLike(value) {
    return `%${value.replace(/%/g, '%%%')}%`;
}

/** @type { (dbType: DbType, tables: Table[], filter: Filter) => string } */
function resolveValue(dbType, tables, filter) {
    const { value, operator } = filter;

    if (value == null || operator.startsWith('is')) {
        return '';
    }

    if (Array.isArray(value)) {
        return `(${value.map(v => (
            resolveValue(dbType, tables, { ...filter, value: v })
        )).join(', ')})`;
    }

    if (typeof value === 'object') {
        if ('min' in value) {
            return [
                resolveValue(dbType, tables, { ...filter, value: value.min }),
                'AND',
                resolveValue(dbType, tables, { ...filter, value: value.max }),
            ].join(' ');
        }

        if ('name' in value) {
            return resolveFieldName(dbType, tables, value);
        }

        return escapeText(JSON.stringify(value));
    }

    if (typeof value === 'number') {
        return String(isFinite(value) ? value : 0);
    }

    if (typeof value === 'string') {
        return escapeText(
            operator.endsWith('like')
                ? escapeLike(value)
                : value
        );
    }

    return "''";
}

/** @type { (dbType: DbType, tables: Table[], column: ColumnValue) => string } */
function resolveFieldName(dbType, tables, column) {
    const { schema, table, name } = column;

    const t = tables.find(t => t.schema === schema && t.name === table);

    return (t?.alias ? escapeName(dbType, t.alias) + '.' : '')
        + escapeName(dbType, name);
}

/** @type { (f: NonNullable<Column['function']>, name: Column['name']) => string } */
function resolveFunction(f, name) {
    if (f.endsWith(')')) {
        return f.toUpperCase().slice(0, -1) + ` ${name})`;
    }
    return `${f.toUpperCase()}(${name})`;
}

/** @type { (f: NonNullable<Column['function']>, name: Column['name']) => string } */
function resolveAlias(f, name) {
    return `${f.toLowerCase().replace('(', '_').replace(')', '')}_${name}`;
}

/** @type { (name: string) => string } */
function resolveTableAlias(name) {
    // Remove special characters and leave alphanumeric characters
    const parts = name.toLowerCase().split(/[\W_]/i).filter(x => x);

    // Full example: "Gross Sales (30 Days)" => "gs30d"
    return parts.reduce((alias, part) => {
        // Check if the part starts with a number
        if (/^\d/.test(part)) {
            // Find the first number and add it to the alias
            const number = part.split(/\D+/).filter(x => x).shift();

            return alias + number;
        }

        // Add the first character of the part to the alias
        return alias + part[0];
    }, '');
}

/** @type { (column: Column) => boolean } */
function filterHidden(column) {
    return !column.hidden;
}

/** @typedef { Pick<Column, 'id' | 'name' | 'alias'> } IsDuplicateParams */

/** @type { (column: Column, params: IsDuplicateParams) => boolean } */
function isDuplicate(column, params) {
    if (column.id === params.id) {
        return false;
    }

    const alias = params.alias || params.name;

    return column.alias
        ? column.alias.toLowerCase() === alias.toLowerCase()
        : column.name.toLowerCase() === alias.toLowerCase();
}

/** @type { (n: any) => n is number } */
function isNumber(n) {
    return !isNaN(n) && isFinite(n);
}

/** @param { Config } config */
function getParams(config) {
    return {
        hasColumns: hasColumns(config),
        allColumns: getColumns(config),
        allTables: getTables(config),
        pending: getPending(config),
        hideHidden: getHideHidden(config),
        someHidden: getSomeHidden(config),
        queryFilter: getFilter(config),
        queryOrder: getOrder(config),
        queryLimit: getLimit(config),
    };
}

export {
    createConfig,
    isValidConfig,
    changeDbType,
    toggleHidden,
    addColumn,
    removeColumn,
    moveColumn,
    toggleColumn,
    changeMainTable,
    addJoinTable,
    changeJoinTable,
    removeJoinTable,
    changePending,
    getPending,
    clearPending,
    changeAlias,
    changeFunction,
    changeFilter,
    changeOrder,
    changeLimit,
    getFilter,
    getOrder,
    getLimit,
    clearColumns,
    hasTable,
    hasTables,
    buildQuery,
    getParams,
};
