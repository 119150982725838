import React from 'react';

import {
    Button,
    Callout,
    Divider,
    Input,
    Typography,
    ValidatedInput,
} from 'ui-components';

import GoogleButton from '../../shared/auth/google-button';
import { passwordValidators, usernameValidators } from '../../models/users';

/**
 * @typedef { object } Props
 * @prop { string } firstName
 * @prop { string } lastName
 * @prop { string } password
 * @prop { string } username
 * @prop { string } email
 * @prop { string } databaseName
 * @prop { boolean } inviteeHasAccount
 * @prop { boolean } firstNameIsValid
 * @prop { boolean } lastNameIsValid
 * @prop { boolean } passwordIsValid
 * @prop { boolean } usernameIsValid
 * @prop { boolean } acceptIsLoading
 * @prop { boolean } acceptDisabled
 * @prop { { message: string } } acceptError
 * @prop { string } acceptLabel
 * @prop { string } acceptCallToAction
 * @prop { (value: string) => void } onFirstNameChanged
 * @prop { (value: string) => void } onLastNameChanged
 * @prop { (value: string) => void } onPasswordChanged
 * @prop { (value: string) => void } onUsernameChanged
 * @prop { (valid: boolean) => void } onPasswordIsValidChanged
 * @prop { (valid: boolean) => void } onUsernameIsValidChanged
 * @prop { () => void } onGoogleClicked
 * @prop { () => void } onAcceptClicked
 * @prop { () => void } onEnterPressed
 */

/** @type { React.FC<Props> } */
const AcceptInviteForm = ({
    firstName,
    lastName,
    password,
    username,
    email,
    databaseName,
    inviteeHasAccount,
    firstNameIsValid,
    lastNameIsValid,
    passwordIsValid,
    usernameIsValid,
    acceptIsLoading,
    acceptDisabled,
    acceptError,
    acceptLabel,
    acceptCallToAction,
    onFirstNameChanged,
    onLastNameChanged,
    onPasswordChanged,
    onUsernameChanged,
    onPasswordIsValidChanged,
    onUsernameIsValidChanged,
    onGoogleClicked,
    onAcceptClicked,
    onEnterPressed,
}) => {
    const emailProps = {
        label: 'Work email',
        name: 'email',
        value: email,
        disabled: true,
    };

    const firstNameProps = {
        label: 'First name',
        name: 'first_name',
        value: firstName,
        error: !firstNameIsValid,
        disabled: acceptIsLoading,
        onKeyDown: onEnterPressed,
        onChange: onFirstNameChanged,
    };

    const lastNameProps = {
        label: 'Last name',
        name: 'last_name',
        value: lastName,
        error: !lastNameIsValid,
        disabled: acceptIsLoading,
        onKeyDown: onEnterPressed,
        onChange: onLastNameChanged,
    };

    const passwordProps = {
        label: 'Password',
        name: 'password',
        type: 'password',
        value: password,
        error: !password,
        valid: passwordIsValid,
        validators: passwordValidators,
        disabled: acceptIsLoading,
        onKeyDown: onEnterPressed,
        onChange: onPasswordChanged,
        onChangeValid: onPasswordIsValidChanged,
    };

    const usernameProps = {
        label: 'Username',
        name: 'username',
        validators: usernameValidators,
        value: username,
        valid: usernameIsValid,
        disabled: acceptIsLoading,
        onChange: onUsernameChanged,
        onKeyDown: onEnterPressed,
        onChangeValid: onUsernameIsValidChanged,
    };

    const buttonProps = {
        color: 'primary',
        onClick: onAcceptClicked,
        loading: acceptIsLoading,
        disabled: acceptDisabled,
    };

    return (
        <>
            <Typography variant="h4" align="center" spacing="-mx-4 mb-6">
                You&apos;ve been invited to join the
                &quot;{databaseName}&quot; warehouse
            </Typography>

            {inviteeHasAccount && (
                <>
                    <Typography
                        variant="subtitle1"
                        align="center"
                        color="secondaryText"
                        weight="normal"
                        spacing="-mt-4 mb-4"
                    >
                        You already have a Panoply account
                    </Typography>

                    <GoogleButton onClick={onGoogleClicked}>
                        Join With Google
                    </GoogleButton>

                    <Typography
                        variant="caption"
                        color="secondaryText"
                        align="center"
                        spacing="mt-2"
                    >
                        Use {email} to accept the invitation
                    </Typography>

                    <Divider label="or" spacing="my-6" />
                </>
            )}

            {acceptError && (
                <Callout color="error" spacing="mb-5">
                    {acceptError.message}
                </Callout>
            )}

            <Typography variant="subtitle2" spacing="mb-2">
                {acceptLabel}
            </Typography>

            <Input width="100" spacing="p-0 mb-5" noFloatLabel {...emailProps} />

            {inviteeHasAccount ? (
                <Input width="100" spacing="p-0 mb-5" noFloatLabel {...passwordProps} />
            ) : (
                <>
                    <Typography variant="subtitle2" spacing="mt-0 mb-1">
                        First name
                    </Typography>
                    <Input width="100" spacing="p-0 mb-5" noFloatLabel {...firstNameProps} />
                    <Typography variant="subtitle2" spacing="mt-0 mb-1">
                        Last name
                    </Typography>
                    <Input width="100" spacing="p-0 mb-5" noFloatLabel {...lastNameProps} />
                    <Typography variant="subtitle2" spacing="mt-0 mb-1">
                        Set your Panoply password
                    </Typography>
                    <ValidatedInput
                        spacing="-mx-2 mb-5 -mt-1"
                        noFloatLabel
                        {...passwordProps}
                    />
                    <Typography variant="subtitle2" spacing="mt-0 mb-0">
                        Set your Panoply username
                    </Typography>
                    <Typography variant="body2" color="secondaryText" spacing="mt-1 mb-1">
                        Your Panoply username must be unique, and cannot be changed later.
                    </Typography>
                    <ValidatedInput
                        spacing="-mx-2 mb-5 -mt-1"
                        noFloatLabel
                        {...usernameProps}
                    />
                </>
            )}

            <Button width="100" spacing="m-0 mb-5" {...buttonProps}>
                {acceptCallToAction}
            </Button>
        </>
    );
};

export default AcceptInviteForm;
