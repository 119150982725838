import React, { useState, useCallback, useEffect } from 'react';

import {
    Dialog,
    Button,
    Typography,
    Input,
} from 'ui-components';

import { reportError } from '../../lib/error-reporter';

/** @typedef { import('models/dashboards').Dashboard } Dashboard */

/**
 * @typedef { object } Props
 * @prop { Dashboard } dashboard
 * @prop { boolean } renameDialogOpen
 * @prop { () => void } toggleRenameDialog
 * @prop { (title: string) => Promise<void> } renameDashboard
 */

/** @type { React.FC<Props> } */
const RenameDialog = ({
    dashboard,
    renameDialogOpen,
    toggleRenameDialog,
    renameDashboard,
}) => {
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState(dashboard.title);
    const [error, setError] = useState('');

    const disabled = !title;

    const save = useCallback(async () => {
        if (!title) {
            return;
        }

        setLoading(true);
        setError('');

        try {
            await renameDashboard(title);
            toggleRenameDialog();

        } catch (/** @type { any } */ err) {
            reportError(err);
            setError(err.data.message);
        } finally {
            setLoading(false);
        }
    }, [title, renameDashboard, toggleRenameDialog]);

    useEffect(() => {
        if (!renameDialogOpen) {
            return () => {};
        }

        /** @type { (e: KeyboardEvent) => void } */
        const onKeyDown = e => {
            if (e.key === 'Escape') {
                e.preventDefault();
                toggleRenameDialog();
            } else if (e.key === 'Enter' && !disabled) {
                e.preventDefault();
                save();
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    }, [renameDialogOpen, disabled, toggleRenameDialog, save]);

    return (
        <Dialog
            compact
            title={`Rename "${dashboard.title}"?`}
            isOpen={renameDialogOpen}
            onClose={toggleRenameDialog}
            actions={[
                <Button
                    key="cancel"
                    type="plain"
                    spacing="ml-0"
                    onClick={toggleRenameDialog}
                    disabled={loading}
                >
                    Cancel
                </Button>,
                <Button
                    key="save"
                    color="primary"
                    spacing="mr-0"
                    onClick={save}
                    disabled={disabled}
                    loading={loading}
                >
                    Rename
                </Button>,
            ]}
            justifyContent="center"
            flexDirection="column"
        >
            <Typography
                color="text"
                variant="body1"
                spacing="mb-4"
                align="center"
            >
                Rename the dashboard.
            </Typography>

            <Input
                label="Rename Dashboard"
                value={title}
                spacing="p-0"
                width="100"
                onChange={e => setTitle(e.target.value)}
                error={!!error}
                disabled={loading}
                noFloatLabel
            />

            {error && (
                <Help color="error" spacing="mt-1">
                    {error}
                </Help>
            )}
        </Dialog>
    );
};

/** @type { React.FC<{ children: React.ReactNode, spacing?: string, color?: string }> } */
const Help = ({ children, ...props }) => (
    <Typography variant="body2" color="secondaryText" spacing="mb-0" {...props}>
        {children}
    </Typography>
);

export default RenameDialog;
