import React from 'react';
import { Radio, RadioGroup } from 'ui-components';
import FormRow from '../../../pages/source-page/form-row';


/** @typedef { import('models/sources/__types').CollectConfig } CollectConfig */
/** @typedef { import('./types').LoadStrategy } LoadStrategy */

/**
 * @typedef { object } Props
 * @prop { LoadStrategy } [value]
 * @prop { (value: LoadStrategy) => void } setValue
*/


/** @type { React.FC<Props> } */
const LoadStrategy = ({
    value = 'upsert',
    setValue,
}) => {

    /* eslint-disable max-len */
    const help = `
Changing the load strategy will affect the way Panoply ingests the data into the tables:

**Upsert** - Every record that already exists in the table will be updated while new records will be inserted. The identification of an existing record is done based on its default or configured primary key.

**Append** - All the records will always be inserted into the table. Any default or configured primary keys will be saved in the table but will not be used during the ingestion process.
    `;
    /* eslint-enable max-len */

    return (
        <FormRow
            label="Load Strategy"
            tooltipContent={help}
            showOptionalLabel={false}
        >
            <RadioGroup
                value={value}
                onChange={(e) => setValue(e.target.value)}
                row
            >
                <Radio label="Upsert" value="upsert" />
                <Radio label="Append" value="append" />
            </RadioGroup>
        </FormRow>
    );
};

export default LoadStrategy;
