import React, { useState, useCallback, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { isPlainObject } from 'lodash';

import {
    Dialog,
    Divider,
    Button,
    Typography,
    Layout,
    Icon,
    Tooltip,
    Select,
    SelectItem,
    Autocomplete,
    spacings,
} from 'ui-components';

import useDraggable, { onDragStart, onDragOver } from './use-draggable';

/** @typedef { import('lib/query-builder').Column } Column */
/** @typedef { import('lib/query-builder').Order } Order */
/** @typedef { import('lib/query-builder').Direction } Direction */
/** @typedef { { column?: Column, direction?: Direction } } Item */
/** @typedef { Option | string } OptionType */
/** @typedef { OptionType | OptionType[] | null } Value */
/** @typedef { 'error' | 'success' | '' } Status */
/** @typedef { number } Position */

/**
 * @typedef { React.Dispatch<React.SetStateAction<T[]>> } SetItems
 * @template [T=Item]
 */

/**
 * @typedef { object } Option
 * @prop { string } title
 * @prop { string } [subtitle]
 * @prop { string } [category]
 * @prop { Column } [column]
 * @prop { boolean } [disabled]
 */

const directions = {
    desc: {
        label: 'Descending',
        icon: 'arrow-down',
    },
    asc: {
        label: 'Ascending',
        icon: 'arrow-up',
    },
};

/** @type { (v: Value) => v is Option } */
const isOptionObject = v => isPlainObject(v);

/** @type { (column: Column) => boolean } */
const filterHidden = column => !column.hidden;

/**
 * @typedef { object } Props
 * @prop { boolean } [open]
 * @prop { Column } [column]
 * @prop { Column[] } allColumns
 * @prop { Order[] } queryOrder
 * @prop { (orderBy: Order[]) => void } changeOrder
 * @prop { () => void } onClose
 */

/** @type { React.FC<Props> } */
const OrderDialog = ({
    open,
    column,
    allColumns,
    queryOrder,
    changeOrder,
    onClose,
}) => {
    const visibleColumns = useMemo(() => {
        return allColumns.filter(filterHidden);
    }, [allColumns]);

    const [items, setItems] = useState(() => {
        const items = queryOrder.map(({ id, direction }) => {
            const column = visibleColumns.find(c => c.id === id);
            return /** @type { Item } */ ({ column, direction });
        }).filter(item => item.column);

        if (column?.id && !items.some(item => item.column?.id === column.id)) {
            items.push({ column });
        }

        if (!items.length) {
            items.push({});
        }

        return items;
    });

    const [status, setStatus] = useState(
        /** @type { Status } */ ('')
    );

    const onSave = useCallback(() => {
        setStatus('');

        const hasEmpty = items.some(item => !item.column?.id || !item.direction);

        if (hasEmpty) {
            setStatus('error');
            return;
        }

        setStatus('success');

        const newOrderBy = /** @type { Order[] } */ (
            items.map(({ column, direction }) => ({ id: column?.id, direction }))
        );

        changeOrder(newOrderBy);

        onClose();
    }, [items, changeOrder, onClose]);

    useEffect(() => {
        if (!open && !column?.id) {
            return () => {};
        }

        /** @type { (e: KeyboardEvent) => void } */
        const onKeyDown = e => {
            if (e.key === 'Escape') {
                e.preventDefault();
                onClose();
            } else if (e.key === 'Enter') {
                e.preventDefault();
                onSave();
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    }, [open, column, onClose, onSave]);

    const actions = [
        <Button key="cancel" type="plain" spacing="ml-0" onClick={onClose}>
            Cancel
        </Button>,
        <Button key="save" color="primary" spacing="mr-0" onClick={onSave}>
            Order
        </Button>,
    ];

    if (status === 'error') {
        actions.unshift(<Error key="error" />);
    }

    if (status === 'success') {
        actions.unshift(<Success key="success" />);
    }

    return (
        <Dialog
            title="Order"
            isOpen={open || !!column?.id}
            onClose={onClose}
            actions={actions}
        >
            <Wrapper>
                <Divider spacing="-mx-4 mt-0 mb-5" />

                <Order
                    visibleColumns={visibleColumns}
                    status={status}
                    items={items}
                    setStatus={setStatus}
                    setItems={setItems}
                />
            </Wrapper>
        </Dialog>
    );
};

/**
 * @typedef { object } OrderProps
 * @prop { Column[] } visibleColumns
 * @prop { Status } status
 * @prop { Item[] } items
 * @prop { (status: Status) => void } setStatus
 * @prop { SetItems } setItems
 */

/** @type { React.FC<OrderProps> } */
const Order = ({ visibleColumns, status, items, setStatus, setItems }) => {
    const {
        getClassName,
        onDragEnter,
        onDragLeave,
        onDrop,
    } = useDraggable({ setItems });

    const options = useMemo(() => (
        visibleColumns.map(c => /** @type { Option } */ ({
            title: c.alias || c.name,
            subtitle: [c.schema, c.table, c.name].join('.'),
            category: 'Fields',
            disabled: items.some(item => item.column?.id === c.id),
            column: c,
        }))
    ), [items, visibleColumns]);

    /** @type { (index: number, item: Item) => void } */
    const updateField = (index, { column, direction }) => {
        setItems(items => {
            const newItems = [...items];
            newItems[index] = { column, direction };
            return newItems;
        });
        setStatus('');
    };

    /** @type { (index: number) => void } */
    const addField = (index) => {
        setItems(items => {
            const newItems = [...items];
            newItems.splice(index, 0, {});
            return newItems;
        });
        setStatus('');
    };

    /** @type { (index: number) => void } */
    const removeField = (index) => {
        setItems(items => {
            const newItems = [...items];
            newItems.splice(index, 1);
            return newItems;
        });
        setStatus('');
    };

    const bodyRowProps = {
        options,
        items,
        status,
        draggable: items.length > 1,
        updateField,
        addField,
        removeField,
        onDragEnter,
        onDragLeave,
        onDrop,
    };

    return (
        <>
            <HeaderRow />

            {(items.length ? items : [{}]).map((item, index) => (
                <BodyRow
                    key={`item-${index}_${item.column?.id || 'empty'}`}
                    className={getClassName(index)}
                    item={item}
                    index={index}
                    {...bodyRowProps}
                />
            ))}
        </>
    );
};

/** @type { React.FC } */
const HeaderRow = () => (
    <FlexLayout>
        <FlexLayout spacing="mr-4">
            <Dragger color="white" />
        </FlexLayout>

        <FlexLayout width="40">
            <Label>
                Field
            </Label>
        </FlexLayout>

        <FlexLayout>
            <Label>
                Sort
            </Label>
        </FlexLayout>
    </FlexLayout>
);

/**
 * @typedef { object } BodyRowProps
 * @prop { string } className
 * @prop { Item } item
 * @prop { number } index
 * @prop { Item[] } items
 * @prop { Option[] } options
 * @prop { Status } status
 * @prop { boolean } draggable
 * @prop { (index: number, item: Item) => void } updateField
 * @prop { (index: number) => void } addField
 * @prop { (index: number) => void } removeField
 * @prop { (e: React.DragEvent<HTMLDivElement>, index: number) => void } onDragEnter
 * @prop { (e: React.DragEvent<HTMLDivElement>, index: number) => void } onDragLeave
 * @prop { (e: React.DragEvent<HTMLDivElement>, to: Position) => void } onDrop
 */

/** @type { React.FC<BodyRowProps> } */
const BodyRow = ({
    className,
    item,
    index,
    options,
    items,
    status,
    draggable,
    updateField,
    addField,
    removeField,
    onDragEnter,
    onDragLeave,
    onDrop,
}) => (
    <Draggable
        className={className}
        {...draggable && {
            draggable,
            onDrop: e => onDrop(e, index),
            onDragStart: e => onDragStart(e, index),
            onDragEnter: e => onDragEnter(e, index),
            onDragLeave: e => onDragLeave(e, index),
            onDragOver,
        }}
    >
        <FlexLayout>
            <FlexLayout spacing="mr-4">
                <Dragger color={draggable ? 'interface' : 'secondaryInterface'} />
            </FlexLayout>

            <FlexLayout width="40">
                <Field
                    item={item}
                    index={index}
                    options={options}
                    items={items}
                    status={status}
                    updateField={updateField}
                />
            </FlexLayout>

            <FlexLayout>
                <Sort
                    item={item}
                    index={index}
                    status={status}
                    updateField={updateField}
                />

                <Add
                    item={item}
                    index={index}
                    items={items}
                    options={options}
                    addField={addField}
                />

                <Remove
                    item={item}
                    index={index}
                    items={items}
                    removeField={removeField}
                />
            </FlexLayout>
        </FlexLayout>
    </Draggable>
);

/** @type { React.FC<{ color?: string }> } */
const Dragger = ({ color }) => (
    <Icon
        icon="equals"
        size="sm"
        color={color}
    />
);

/**
 * @typedef { object } FieldProps
 * @prop { Item } item
 * @prop { number } index
 * @prop { Option[] } options
 * @prop { Item[] } items
 * @prop { Status } status
 * @prop { (index: number, item: Item) => void } updateField
 */

/** @type { React.FC<FieldProps> } */
const Field = ({ item, index, options, items, status, updateField }) => {
    const [open, setOpen] = useState(false);

    /**
     * @type { (
     *     e: React.ChangeEvent<HTMLInputElement | HTMLDivElement>,
     *     newValue: Value
     * ) => void }
     */
    const onChangeField = (event, newValue) => {
        if (isOptionObject(newValue) && newValue.column?.id) {
            updateField(index, { ...item, column: newValue.column });
        } else if (newValue === null) {
            updateField(index, { ...item, column: undefined });
        }
    };

    /** @type { (option: OptionType) => boolean } */
    const getOptionSelected = (option) => {
        if (!isOptionObject(option)) {
            return false;
        }
        return items.some(item => item.column?.id === option.column?.id);
    };

    /** @type { (option: Option | string | null) => string } */
    const getOptionLabel = (option) => {
        if (option && typeof option === 'object') {
            return option.column?.id
                ? option.column.alias || option.column.name
                : option.title || '';
        }

        return option || '';
    };

    /** @type { Option | undefined } */
    const value = useMemo(() => {
        const { column } = item;

        if (column?.id) {
            return {
                title: column.alias || column.name,
                subtitle: [
                    column.schema,
                    column.table,
                    column.name,
                ].join('.'),
                category: 'Fields',
                column,
            };
        }

        return undefined;
    }, [item]);

    return (
        <InputTooltip content={!open ? value?.subtitle : ''}>
            <Autocomplete
                label="Field"
                width="100"
                spacing="p-0 pr-2"
                options={options}
                onChange={onChangeField}
                onInputChange={onChangeField}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={value}
                multiple={false}
                getOptionSearchableString={getOptionLabel}
                getOptionSelected={getOptionSelected}
                error={status === 'error' && !item.column?.id}
            />
        </InputTooltip>
    );
};

/**
 * @typedef { object } SortProps
 * @prop { Item } item
 * @prop { number } index
 * @prop { Status } status
 * @prop { (index: number, item: Item) => void } updateField
 */

/** @type { React.FC<SortProps> } */
const Sort = ({ item, index, status, updateField }) => {
    const error = status === 'error' && !item.direction;

    return (
        <Select
            value={item.direction || ''}
            onChange={e => updateField(index, { ...item, direction: e.target.value })}
            spacing="p-0 pr-2"
            error={error}
            alwaysShowError={error}
            noFloatLabel
        >
            <SelectItem value="" disabled>
                Select
            </SelectItem>

            {Object.entries(directions).map(([direction, { icon, label }]) => (
                <SelectItem
                    key={direction}
                    value={direction}
                    selected={direction === item.direction}
                    disabled={direction === item.direction}
                >
                    <FlexLayout>
                        <Icon
                            icon={icon}
                            color="secondaryText"
                            spacing="mr-2"
                        />
                        {label}
                    </FlexLayout>
                </SelectItem>
            ))}
        </Select>
    );
};

/**
 * @typedef { object } AddProps
 * @prop { Item } item
 * @prop { number } index
 * @prop { Item[] } items
 * @prop { Option[] } options
 * @prop { (index: number) => void } addField
 */

/** @type { React.FC<AddProps> } */
const Add = ({ item, index, items, options, addField }) => {
    const disabled = !options.some(o => !o.disabled)
        || items.length === options.length
        || !item.column?.id;

    return (
        <Button
            round
            type="plain"
            color="secondary"
            onClick={() => addField(index + 1)}
            disabled={disabled}
            spacing="m-0 p-1"
        >
            <Icon
                icon="plus-circle"
                color={disabled ? 'interface' : 'secondary'}
            />
        </Button>
    );
};

/**
 * @typedef { object } ActionsProps
 * @prop { Item } item
 * @prop { number } index
 * @prop { Item[] } items
 * @prop { (index: number) => void } removeField
 */

/** @type { React.FC<ActionsProps> } */
const Remove = ({ item, index, items, removeField }) => {
    const disabled = items.length <= 1 && !item.column?.id;

    return (
        <Button
            round
            type="plain"
            color="error"
            onClick={() => removeField(index)}
            disabled={disabled}
            spacing="m-0 p-1"
        >
            <Icon
                icon="minus-circle"
                color={disabled ? 'interface' : 'error'}
            />
        </Button>
    );
};

/** @type { React.FC<{ children: React.ReactNode, width?: string, spacing?: string }> } */
const FlexLayout = ({ children, ...props }) => (
    <Layout flex alignItems="center" {...props}>
        {children}
    </Layout>
);

/** @type { React.FC<{ children: React.ReactNode, spacing?: string }> } */
const Label = ({ children, ...props }) => (
    <Typography variant="subtitle1" color="text" spacing="mb-0" {...props}>
        {children}
    </Typography>
);

/** @type { React.FC<{ children: React.ReactNode, spacing?: string, color?: string }> } */
const Help = ({ children, ...props }) => (
    <Typography variant="body2" color="secondaryText" spacing="mb-0" {...props}>
        {children}
    </Typography>
);

/** @type { React.FC } */
const Error = () => (
    <Status>
        <Icon
            prefix="fas"
            icon="exclamation-circle"
            size="sm"
            color="error"
            spacing="mr-2"
        />
        <Help>
            There is at least one incomplete condition
        </Help>
    </Status>
);

/** @type { React.FC } */
const Success = () => (
    <Status>
        <Icon
            icon="check"
            size="sm"
            color="secondary"
            spacing="mr-2"
        />
        <Help>
            Saved
        </Help>
    </Status>
);

const Status = styled(({ className, children }) => (
    <Layout className={className} flex alignItems="center">
        {children}
    </Layout>
))`
    && {
        padding: 0.55rem 1rem 0.45rem;
    }
`;

const InputTooltip = styled(({ className, content, children }) => ((
    <Tooltip
        block
        className={className}
        content={content}
        enterDelay={1000}
        interactive={false}
        placement="bottom-start"
    >
        {children}
    </Tooltip>
)))`
    width: 100%;
`;

const Draggable = styled.div`
    margin: 0 -${spacings[2]}rem;
    padding: ${spacings[1]}rem ${spacings[2]}rem;

    ${({ draggable }) => draggable && css`
        cursor: grab;

        :active {
            cursor: grabbing;
        }
    `}
`;

const Wrapper = styled(Layout)`
    width: 90vw;
    max-width: 100%;
`;

export default OrderDialog;
