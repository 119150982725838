import { random } from 'lodash';
import { getRandomString } from '../../../../lib/get-random-string';
import { getCookie, deleteCookie } from '../../../../lib/cookie';

/**
 * Read: https://auth0.com/docs/configure/attack-protection/state-parameters
 */

const OAUTH_STATE_KEY = 'oauth-state-key';

/** @type { () => string } */
export const generateOauthState = () => {
    const state = getRandomString(random(16, 32)); // pass random length
    sessionStorage.setItem(OAUTH_STATE_KEY, state);
    return state;
};

/** @type { (state: any) => boolean } */
export const validateOauthState = (state) => {
    const storedState = sessionStorage.getItem(OAUTH_STATE_KEY);
    sessionStorage.removeItem(OAUTH_STATE_KEY);

    // In the case the OAuth 2 authorization is done by the server,
    // the OAuth State Key is being saved as a cookie on response.
    const cookieState = getCookie(OAUTH_STATE_KEY);
    deleteCookie(OAUTH_STATE_KEY);

    return storedState === state || cookieState === state;
};
