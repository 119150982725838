import React, { useCallback } from 'react';
import {
    Button,
    Typography,
    Layout,
    Loader,
    Card,
    Divider,
    Chip,
    Tooltip,
    SearchInput,
    TableSortLabel,
    Switch,
} from 'ui-components';

import PageLayout from '../../shared/page-layout';
import Pagination from '../../shared/pagination';
import PageError from '../../shared/page-error';
import TablesListItem from './tables-list-item';
import CreateFolderDialog from './create-folder-dialog';
import MoveObjectDialog from './move-object-dialog';
import { DeleteObjectDialog, ManageViewersDialog } from '../../shared/database-objects';
import BatchDeleteObjectDialog from './batch-delete-objects-dialog';
import MaterializeViewDialog from './materialize-view-dialog';
import useTablesPage from './use-tables-page';
import Feature from '../../shared/feature';
import BatchActions from '../../shared/batch-actions';
import PageHeader from '../../shared/page-header';

/** @typedef { import('models/database-objects/__types').DatabaseObject } DatabaseObject */
/** @typedef { import('models/database-objects/__types').Table } Table */
/** @typedef { import('models/database-objects/__types').Folder } Folder */
/** @typedef { import('models/database-objects/__types').View } View */
/** @typedef { import('./use-tables-page').SortKey } SortKey */
/** @typedef { import('./use-tables-page').SelectableObject } SelectableObject */
/** @typedef { import('app/__types').PageProps } PageProps */

/** @typedef { DatabaseObject[]= } DatabaseObjectsState */
/** @typedef { DatabaseObject= } DatabaseObjectState */

/** @typedef { { width: number, sortBy: SortKey, label: string } } Header */

/** @type { Header[] } */
const headers = [
    { width: 45, sortBy: 'name', label: 'Name' },
    { width: 15, sortBy: 'rows', label: 'Table Rows' },
    { width: 15, sortBy: 'schema', label: 'Schema' },
    { width: 15, sortBy: 'size', label: 'Data Size' },
];

/** @type { React.FC<PageProps> } */
const TablesPage = () => {
    const {
        loading,
        error,
        objects,
        objectsInPage,
        objectsToDisplay,
        totalSize,
        totalRows,
        page,
        userCanManageTables,
        pageSize,
        objectToMove,
        sortBy,
        sortAscending,
        selectedObjects,
        allSelected,
        toggleSelectAll,
        isObjectSelected,
        clearSelectedObjects,
        toggleSelectedObject,
        setPage,
        changeSort,

        createDialogOpen,
        parentFolderId,
        closeCreateDialog,
        openCreateDialog,
        createFolder,

        moveDialogOpen,
        openMoveDialog,
        closeMoveDialog,
        moveObjectToFolder,

        viewersDialogOpen,
        itemsToManage,
        openViewersDialog,
        closeViewersDialog,

        materializeDialogOpen,
        viewToMaterialize,
        userCanMaterialize,
        materializeView,
        openMaterializeDialog,
        closeMaterializeDialog,

        deleteDialogOpen,
        objectToDelete,
        openDeleteDialog,
        closeDeleteDialog,
        fetchObjectDependencies,
        deleteObject,

        batchObjectToDelete,
        batchDeleteDialogOpen,
        closeBatchDeleteDialog,
        openBatchDeleteDialog,


        searchTerm,
        onChangeSearch,
    } = useTablesPage();

    const moveObjectDialogProps = {
        open: moveDialogOpen,
        onClose: closeMoveDialog,
        objects,
        objectToMove,
        moveObjectToFolder,
    };

    const deleteObjectDialogProps = {
        open: deleteDialogOpen,
        onClose: closeDeleteDialog,
        objectToDelete,
        deleteObject,
        fetchObjectDependencies,
    };

    const batchDeleteObjectDialogProps = {
        open: batchDeleteDialogOpen,
        onClose: closeBatchDeleteDialog,
        objectsToDelete: batchObjectToDelete,
        deleteObject,
        fetchObjectDependencies,
    };

    const materializeViewDialogProps = {
        open: materializeDialogOpen,
        onClose: closeMaterializeDialog,
        viewToMaterialize,
        userCanMaterialize,
        materializeView,
    };

    const viewersDialogProps = {
        open: viewersDialogOpen,
        onClose: closeViewersDialog,
        objectsToManage: itemsToManage,
    };

    const createFolderDialogProps = {
        open: createDialogOpen,
        onClose: closeCreateDialog,
        createFolder,
        parentFolderId,
    };

    const paginationProps = {
        page,
        updatePage: setPage,
        totalItems: objectsToDisplay.length,
        pageSize,
    };

    const batchActions = [
        {
            name: 'Delete',
            action: () => {
                openBatchDeleteDialog(
                    /** @type {SelectableObject[]} */ (selectedObjects),
                );
            },
        },
        {
            name: 'Manage Viewers',
            action: () => {
                openViewersDialog(
                    /** @type {SelectableObject[]} */ (selectedObjects),
                );
            },
        },
    ];

    const sizeTooltip = totalSize
        + ' reflects the storage used from all the'
        + ' tables generated by connectors and views.';

    const numberOfSelected = selectedObjects.length;

    const disableSelectAll = !objectsInPage.length;

    return (
        <PageLayout title="Tables">

            <PageHeader
                title="Tables"
                titleAdornment={(
                    <>
                        <Tooltip interactive={false} content={sizeTooltip}>
                            <Chip label={totalSize} spacing="ml-3" />
                        </Tooltip>
                        <SearchInput
                            label="Search Tables"
                            spacing="ml-6"
                            noFloatLabel
                            value={searchTerm}
                            onChange={onChangeSearch}
                            disabled={loading}
                            autoFocus
                        />
                    </>
                )}
            >
                <Feature databaseType="redshift">
                    {userCanManageTables && (
                        <Button
                            onClick={() => openCreateDialog(null)}
                            spacing="mr-0"
                            leftIcon="folder-plus"
                            disabled={loading}
                        >
                            Create Folder
                        </Button>
                    )}
                </Feature>
                <Button
                    link={'/workbench'}
                    color="primary"
                    spacing="mr-0 ml-3"
                    disabled={loading}
                >
                    Query
                </Button>
            </PageHeader>

            {userCanManageTables && (
                <BatchActions
                    selectedObjects={selectedObjects}
                    actions={batchActions}
                    clearSelectedObjects={clearSelectedObjects}
                />
            )}

            {loading && (
                <Layout spacing="p-7">
                    <Loader big active message="Loading Tables" />
                </Layout>
            )}

            {!loading && error && (
                <PageError
                    title="Oops! There was an error loading tables."
                    error={error}
                />
            )}

            {!loading && !error && (
                <>
                    <Card contentSpacing="p-0">
                        <TablesListHeader
                            sortBy={sortBy}
                            sortAscending={sortAscending}
                            allSelected={allSelected}
                            changeSort={changeSort}
                            toggleSelectAll={toggleSelectAll}
                            disableSelectAll={disableSelectAll}
                            userCanManageTables={userCanManageTables}
                        />

                        <Divider spacing="my-0" />

                        <Layout>
                            {objectsInPage.map(object => (
                                <TablesListItem
                                    object={object}
                                    key={object.id}
                                    indent={0}
                                    userCanManageTables={userCanManageTables}
                                    userCanMaterialize={userCanMaterialize}
                                    openMoveDialog={openMoveDialog}
                                    openDeleteDialog={openDeleteDialog}
                                    openViewersDialog={openViewersDialog}
                                    openCreateDialog={openCreateDialog}
                                    openMaterializeDialog={openMaterializeDialog}
                                    isSelected={isObjectSelected}
                                    actionsDisabled={!!numberOfSelected}
                                    onSelected={toggleSelectedObject}
                                />
                            ))}

                            {!objectsInPage.length && (
                                <Layout spacing="px-4">
                                    {searchTerm && (
                                        <Typography
                                            variant="subtitle1"
                                            spacing="py-6"
                                            align="center"
                                        >
                                            No items match the search term:
                                            <Typography
                                                inline
                                                component="span"
                                                variant="subtitle1"
                                                weight="medium"
                                            >
                                                {searchTerm}
                                            </Typography>
                                        </Typography>
                                    )}

                                    {!searchTerm && (
                                        <>
                                            <Typography
                                                variant="subtitle1"
                                                spacing="mt-6"
                                                align="center"
                                            >
                                                There are no tables or views in your Data Warehouse
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                spacing="mt-3 mb-6"
                                                align="center"
                                            >
                                                Collect data from a Connector to populate tables.
                                            </Typography>
                                        </>
                                    )}
                                </Layout>
                            )}
                        </Layout>
                    </Card>

                    <TablesListTotals totalSize={totalSize} totalRows={totalRows} />

                    <Pagination {...paginationProps} />
                </>
            )}

            <MoveObjectDialog {...moveObjectDialogProps} />

            <DeleteObjectDialog {...deleteObjectDialogProps} />

            <BatchDeleteObjectDialog {...batchDeleteObjectDialogProps} />

            <CreateFolderDialog {...createFolderDialogProps} />

            <MaterializeViewDialog {...materializeViewDialogProps} />

            <ManageViewersDialog {...viewersDialogProps} />

        </PageLayout>
    );
};

/**
 * @typedef { object } TablesListHeaderProps
 * @prop { SortKey } sortBy
 * @prop { boolean } sortAscending
 * @prop { boolean } allSelected
 * @prop { boolean } disableSelectAll
 * @prop { boolean } userCanManageTables
 * @prop { () => void } toggleSelectAll
 * @prop { (sortBy: SortKey) => void } changeSort
 */

/** @type { React.FC<TablesListHeaderProps> } */
const TablesListHeader = ({
    sortBy,
    sortAscending,
    allSelected,
    disableSelectAll,
    userCanManageTables,
    toggleSelectAll,
    changeSort,
}) => (
    <Layout flex alignItems="center" justifyContent="space-between" spacing="p-5">

        {headers.map((header, i) => (
            <Layout key={header.sortBy} width={header.width}>
                {i === 0 && userCanManageTables && (
                    <Switch
                        type="checkbox"
                        checked={allSelected}
                        label={''}
                        onChange={toggleSelectAll}
                        disabled={disableSelectAll}
                        spacing="mr-2 -ml-1 px-1"
                    />
                )}
                <SortLabel
                    sortBy={header.sortBy}
                    active={header.sortBy === sortBy}
                    direction={sortAscending ? 'asc' : 'desc'}
                    changeSort={changeSort}
                >
                    {header.label}
                </SortLabel>
            </Layout>
        ))}

        <Layout width="10">
            {/* Empty placeholder column for ... button */}
        </Layout>
    </Layout>
);

/** @type { React.FC<{totalSize: string, totalRows: string}> } */
const TablesListTotals = ({ totalSize, totalRows }) => (
    <Card spacing="mt-2" contentSpacing="px-5 py-4">
        <Layout flex alignItems="center" justifyContent="space-between">
            <Layout width="45">
                <Typography
                    spacing="p-0 m-0"
                >
                    Total Sum:
                </Typography>
            </Layout>
            <Layout width="15">
                <Typography spacing="p-0 m-0">
                    {totalRows} rows
                </Typography>
            </Layout>
            <Layout width="15">
                {/* Empty column because there is no sum for 'schema' */}
            </Layout>
            <Layout width="15">
                <Typography spacing="p-0 m-0">
                    {totalSize}
                </Typography>
            </Layout>
            <Layout width="10">
                {/* Empty placeholder column for ... button */}
            </Layout>
        </Layout>
    </Card>
);

/**
 * @typedef { object } SortLabelProps
 * @prop { SortKey } sortBy
 * @prop { boolean } active
 * @prop { 'asc' | 'desc' } direction
 * @prop { (sortBy: SortKey) => void } changeSort
 */

/** @type { React.FC<SortLabelProps> } */
const SortLabel = ({ children, changeSort, sortBy, ...props }) => {
    const onSortClicked = useCallback(() => {
        changeSort(sortBy);
    }, [changeSort, sortBy]);

    return (
        <TableSortLabel onClick={onSortClicked} {...props}>
            {children}
        </TableSortLabel>
    );
};

export default TablesPage;
