import { useRef } from 'react';

/** @typedef { { weight: number, elements: HTMLDivElement[] } } DragOverOptions */
/** @typedef { number } Position */

/**
 * @typedef { React.Dispatch<React.SetStateAction<T[]>> } SetItems
 * @template [T=any]
 */

/** @type { (e: React.DragEvent<HTMLDivElement>, from: Position) => void } */
function onDragStart(e, from) {
    e.stopPropagation();
    e.dataTransfer.setData('text/plain', String(from));
    e.dataTransfer.effectAllowed = 'copy';
}

/** @type { (e: React.DragEvent<HTMLDivElement>) => void } */
function onDragOver(e) {
    e.stopPropagation();
    e.preventDefault();
}

/** @type { (e: React.DragEvent<HTMLDivElement>) => { from?: Position } } */
function getData(e) {
    const num = e.dataTransfer.getData('text/plain');
    const from = isNumeric(num) ? Number(num) : undefined;
    return { from };
}

/** @type { (n: any) => boolean } */
function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 * @typedef { object } Props
 * @prop { SetItems } setItems
 */

/** @param { Props } props */
function useDraggable({ setItems }) {
    const uniqueIdRef = useRef(Math.random().toString(36).substring(2));

    const dragOverRef = useRef(
        /** @type { { [className: string]: DragOverOptions } } */ ({})
    );

    /** @type { (index: number) => string } */
    const getClassName = (index) => `${uniqueIdRef.current}-${index}`;

    /** @type { (e: React.DragEvent<HTMLDivElement>, index: number) => void } */
    const onDragEnter = (e, index) => {
        onDragOver(e);

        // The class name is used to identify the column.
        const className = getClassName(index);

        // The weight simply means how many elements (cells) within
        // the same column (by class name) have been dragged over.
        const weight = dragOverRef.current[className]?.weight ?? 0;

        // The elements are the previous cells within the same column
        // (by class name) that have been dragged over up until now.
        const elements = dragOverRef.current[className]?.elements ?? [];

        // The new elements are the current cells within the same column.
        const newElements = Array.from(/** @type { HTMLCollectionOf<HTMLDivElement> } */ (
            document.getElementsByClassName(className)
        ));

        /** @type { DragOverOptions } */
        const options = {
            weight: Math.min(Math.max(weight + 1, 1), 2), // range: 1 .. 2
            elements: [...elements, ...newElements],
        };

        dragOverRef.current[className] = options;

        Object.entries(dragOverRef.current).forEach(([key, options]) => {
            // If the key is the same as the current column, then:
            if (key === className) {
                options.elements.forEach(element => {
                    element.style.opacity = '0.6';
                });
                return;
            }

            // If the key is not the same as the current column, then:
            while (options.elements.length) {
                const element = options.elements.shift();

                if (element) {
                    element.style.opacity = '1';
                }
            }

            // Reset the weight
            options.weight = 0;
        });
    };

    /** @type { (e: React.DragEvent<HTMLDivElement>, index: number) => void } */
    const onDragLeave = (e, index) => {
        onDragOver(e);

        // The class name is used to identify the column.
        const className = getClassName(index);

        // The weight simply means how many elements (cells) within
        // the same column (by class name) have been dragged over.
        const weight = dragOverRef.current[className]?.weight ?? 0;

        // The elements are the previous cells within the same column
        // (by class name) that have been dragged over up until now.
        const elements = dragOverRef.current[className]?.elements ?? [];

        const options = {
            weight: Math.max(Math.min(weight - 1, 1), 0), // range: 0 .. 1
            elements,
        };

        dragOverRef.current[className] = options;

        if (options.weight <= 0) {
            while (options.elements.length) {
                const element = options.elements.shift();

                if (element) {
                    element.style.opacity = '1';
                }
            }

            options.weight = 0;
        }
    };

    /** @type { (e: React.DragEvent<HTMLDivElement>, to: Position) => void } */
    const onDrop = (e, to) => {
        onDragOver(e);

        Object.values(dragOverRef.current).forEach((options) => {
            while (options.elements.length) {
                const element = options.elements.shift();

                if (element) {
                    element.style.opacity = '1';
                }
            }

            options.weight = 0;
        });

        const { from } = getData(e);

        setItems(items => {
            if (from !== undefined && to !== undefined) {
                const fromIndex = Math.max(from, 0);
                const toIndex = Math.max(to, 0);

                if (fromIndex !== toIndex) {
                    const foundItem = items[fromIndex];

                    if (foundItem) {
                        items.splice(fromIndex, 1); // remove
                        items.splice(toIndex, 0, foundItem);
                    }

                    return [...items];
                }
            }

            return items;
        });
    };

    return {
        getClassName,
        onDragEnter,
        onDragLeave,
        onDrop,
    };
}

export {
    onDragStart,
    onDragOver,
};

export default useDraggable;
