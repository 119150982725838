/* eslint-disable complexity */
import React from 'react';

import {
    Typography,
    Button,
    Layout,
    Card,
    Loader,
    ExpansionPanelHeader,
} from 'ui-components';

import useQueriesPage from './use-queries-page';
import LastUpdated from '../../shared/last-updated';
import CancelQueryDialog from '../../shared/cancel-query-dialog';
import QueryListItem from './query-list-item';
import PageLayout from '../../shared/page-layout';
import PageHeader from '../../shared/page-header';
import Pagination from '../../shared/pagination';

/** @typedef { import('../../app/__types').PageProps } PageProps */

/** @type { React.FC<PageProps> } */
const QueriesPage = () => {

    const {
        queries,
        queriesUpdatedAt,
        loading,
        loaded,
        page,
        pageSize,
        fetching,
        nextPageDisabled,
        enableLoading,
        updatePage,
        openCancelQueryDialog,
        closeCancelQueryDialog,
        queryToCancel,
        onQueryCancelled,
    } = useQueriesPage();

    const showLoader = loading;
    const showNoResults = !loading && loaded && !queries?.length;
    const showQueries = !loading && loaded && !!queries?.length;

    return (
        <PageLayout spacing="pt-5">
            <PageHeader title="Queries">
                <Layout flex alignItems="center">
                    <Layout
                        flex
                        flexDirection="column"
                        alignItems="flex-end"
                        justifyContent="center"
                    >
                        {!!queriesUpdatedAt && (
                            <Typography color="secondaryText" spacing="mr-2">
                                <LastUpdated timestamp={queriesUpdatedAt} />
                            </Typography>
                        )}
                    </Layout>
                    <Button
                        color="primary"
                        disabled={fetching || loading}
                        onClick={enableLoading}
                        spacing="mr-0"
                    >
                        Refresh
                    </Button>
                </Layout>
            </PageHeader>

            <Layout spacing="mt-6">
                {showLoader && (
                    <Card flat contentSpacing="p-6">
                        <Layout spacing="mt-2">
                            <Loader big active relative message="Loading Queries" />
                        </Layout>
                    </Card>
                )}
                {showNoResults && (
                    <Card flat contentSpacing="p-6">
                        <Typography variant="body1" align="center" spacing="my-4">
                            No queries found within the last 2 days.
                        </Typography>
                    </Card>
                )}

                {showQueries && (
                    <>
                        <QueriesListHeader />
                        {queries.map(q => (
                            <QueryListItem
                                query={q}
                                key={q.id}
                                openCancelQueryDialog={openCancelQueryDialog}
                            />
                        ))}
                        <Pagination
                            page={page}
                            pageSize={pageSize}
                            updatePage={updatePage}
                            nextDisabled={nextPageDisabled}
                        />
                    </>
                )}
            </Layout>

            <CancelQueryDialog
                key={`cancel-query-dialog-${!!queryToCancel?.id}`}
                queryToCancel={queryToCancel}
                onQueryCancelled={onQueryCancelled}
                closeDialog={closeCancelQueryDialog}
            />

        </PageLayout>
    );
};

/** @type { React.FC } */
const QueriesListHeader = () => (
    <ExpansionPanelHeader flat contentSpacing="pl-4 pr-1">
        <Layout flex alignItems="center" justifyContent="space-between">
            <Layout width="20" spacing="pr-2 pr-lg-3">
                <Typography color="secondaryText">
                    Creation Time (UTC)
                </Typography>
            </Layout>
            <Layout width="30" spacing="pr-2 pr-lg-3">
                <Typography color="secondaryText">
                    SQL
                </Typography>
            </Layout>
            <Layout width="15" spacing="pr-2 pr-lg-3">
                <Typography color="secondaryText">
                    User
                </Typography>
            </Layout>
            <Layout width="15" spacing="pr-2 pr-lg-3">
                <Typography color="secondaryText">
                    Status
                </Typography>
            </Layout>
            <Layout width="10" spacing="pr-2 pr-lg-3">
                <Typography color="secondaryText">
                    Duration
                </Typography>
            </Layout>

            <Layout width="10">
                {/* Empty placeholder column for Cancel button */}
            </Layout>
        </Layout>
    </ExpansionPanelHeader>
);

export default QueriesPage;
