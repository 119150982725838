import React from 'react';
import styled from 'styled-components';

import {
    Divider,
    Icon,
    Layout,
    ListItem,
    Input,
    Switch,
    Typography,
    Select,
    SelectItem,
    Callout,
    Tooltip,
} from 'ui-components';

import {
    Scroller,
    SourceIcon,
} from './shared';

import { cdnDataSourceIcon } from '../../../../lib/cdn-icon';

/** @typedef { import('models/sources').SourceDefaults } SourceDefaults */
/** @typedef { import('models/sources').Source } Source */
/** @typedef { import('models/recipes').Recipe } Recipe */
/** @typedef { import('models/recipes/types').Output } Output */
/** @typedef { import('./use-recipes-dialog').OutputReq } OutputReq */
/** @typedef { import('./use-recipes-dialog').default } useRecipesDialog */

/** @typedef { ReturnType<useRecipesDialog> } useRecipes */

/**
 * @typedef { object } Props
 * @prop { useRecipes['getSourceSchemaAndDestination'] } getSourceSchemaAndDestination
 * @prop { useRecipes['toggleSelectedRecipe'] } toggleSelectedRecipe
 * @prop { useRecipes['isPartiallySelected'] } isPartiallySelected
 * @prop { useRecipes['isOutputSelected'] } isOutputSelected
 * @prop { useRecipes['toggleSelectedOutput'] } toggleSelectedOutput
 * @prop { useRecipes['isRecipeSelected'] } isRecipeSelected
 * @prop { useRecipes['selectedSources'] } selectedSources
 * @prop { Recipe } selectedRecipe
 * @prop { useRecipes['failedQueries'] } failedQueries
 * @prop { useRecipes['destinationPrefix'] } destinationPrefix
 * @prop { useRecipes['destinationSchema'] } destinationSchema
 * @prop { useRecipes['setDestinationPrefix'] } setDestinationPrefix
 * @prop { useRecipes['setDestinationSchema'] } setDestinationSchema
 * @prop { useRecipes['resetConfig'] } resetConfig
 * @prop { useRecipes['dryRunning'] } dryRunning
 * @prop { useRecipes['deselectErroredOutputs'] } deselectErroredOutputs
 * @prop { useRecipes['schemas'] } schemas
 */

/** @type { React.FC<Props> } */
export const RecipesConfigStep = ({
    getSourceSchemaAndDestination,
    toggleSelectedRecipe,
    isPartiallySelected,
    isOutputSelected,
    toggleSelectedOutput,
    isRecipeSelected,
    selectedSources,
    selectedRecipe,
    destinationPrefix,
    destinationSchema,
    setDestinationPrefix,
    setDestinationSchema,
    resetConfig,
    failedQueries,
    dryRunning,
    deselectErroredOutputs,
    schemas,
}) => {

    /** @type { (e: React.ChangeEvent<HTMLInputElement>) => void } */
    const onChangeSchema = (e) => {
        setDestinationSchema(e.target.value);
    };

    /** @type { (e: React.ChangeEvent<HTMLInputElement>) => void } */
    const onChangePrefix = (e) => {
        setDestinationPrefix(e.target.value);
    };

    return (
        <Layout flex>
            <Scroller width="50" spacing="-mb-5">

                <Typography spacing="my-5" color="secondaryText" variant="body2">
                    Selected views
                </Typography>

                <Layout spacing="mb-3">
                    {selectedSources.map((source) => {
                        if (!source) {
                            return null;
                        }
                        return (
                            <ListItem
                                label={source.title}
                                color="secondaryText"
                                spacing="pl-0"
                                key={source.id}
                            >
                                <Layout flex alignItems="center" spacing="ml-0">
                                    <SourceIcon
                                        src={cdnDataSourceIcon(source.type)}
                                    />
                                    <Layout>
                                        <Typography color="text" spacing="mb-0" variant="body1">
                                            {source.title}
                                        </Typography>
                                        <Typography color="secondaryText" variant="caption">
                                            {getSourceSchemaAndDestination(source)}
                                        </Typography>
                                    </Layout>
                                </Layout>
                            </ListItem>
                        );
                    })}
                </Layout>

                <React.Fragment>
                    <ListItem
                        label={selectedRecipe.title || selectedRecipe.name}
                        color="secondaryText"
                        spacing="pl-0 pt-1 pb-0"
                    >
                        <div onClick={e => e.stopPropagation()}>
                            <Switch
                                type="checkbox"
                                label={''}
                                checked={isRecipeSelected(selectedRecipe)}
                                onChange={() => toggleSelectedRecipe(selectedRecipe)}
                                spacing="mr-1 -ml-2 p-1"
                                indeterminate={isPartiallySelected(selectedRecipe)}
                                disabled
                            />
                        </div>
                        <Typography color="text" variant="body1" spacing="my-0">
                            {selectedRecipe.title || selectedRecipe.name}
                        </Typography>
                    </ListItem>

                    {selectedRecipe.outputs.map(output => {
                        const { name } = output;
                        const id = [selectedRecipe.name, name].join('.');
                        const selected = isOutputSelected(output);
                        const error = failedQueries.find(fc => fc.output === output);

                        return (
                            <ListItem
                                key={id}
                                label={name}
                                spacing="pt-1 pb-0"
                            >
                                <div onClick={e => e.stopPropagation()}>
                                    <Switch
                                        type="checkbox"
                                        checked={selected}
                                        label={''}
                                        onChange={
                                            () => toggleSelectedOutput(output)
                                        }
                                        spacing="mr-1 -ml-3 p-1"
                                    />
                                </div>

                                <Typography color="text" noWrap>
                                    {error && (
                                        <Icon
                                            prefix="fas"
                                            icon="exclamation-circle"
                                            color="error"
                                            spacing="mr-2"
                                        />
                                    )}
                                    {name}
                                </Typography>

                            </ListItem>
                        );
                    })}
                </React.Fragment>
            </Scroller>

            <Divider vertical spacing="m-0 -mb-5" />

            <Scroller width="50" spacing="pl-4 -mb-5">

                <Typography spacing="my-5" color="secondaryText" variant="body2">
                    Advanced settings (
                    <ClickableText onClick={() => resetConfig()}>Reset</ClickableText>
                    )
                </Typography>

                <Layout flex alignItems="center" spacing="mt-6 mb-2">
                    <Typography color="text" variant="body1" spacing="m-0">
                        Destination Schema
                    </Typography>
                    <Tooltip
                        content="The name of the target schema where you wish to save the data."
                    >
                        <Icon icon="info-circle" spacing="ml-2" color="secondaryText" />
                    </Tooltip>
                </Layout>

                <Select
                    width="100"
                    value={destinationSchema}
                    error={!destinationSchema}
                    onChange={onChangeSchema}
                    noFloatLabel
                    spacing="px-0"
                >
                    {schemas?.map((schema) => (
                        <SelectItem value={schema.name} key={schema.name}>
                            {schema.name}
                        </SelectItem>
                    ))}
                </Select>

                <Layout flex alignItems="center" spacing="mt-6 mb-2">
                    <Typography color="text" variant="body1" spacing="m-0">
                        Destination Prefix
                    </Typography>
                    <Tooltip
                        content="The destination prefix is used to affect where
                        the incoming data is stored. All alphabetic characters
                        are converted to lowercase."
                    >
                        <Icon icon="info-circle" spacing="ml-2" color="secondaryText" />
                    </Tooltip>
                </Layout>

                <Input
                    width="100"
                    noFloatLabel
                    label="Destination"
                    spacing="px-0 mb-6"
                    value={destinationPrefix}
                    error={!destinationPrefix}
                    onChange={onChangePrefix}
                />

                {!dryRunning && (
                    <RecipesError
                        failedQueries={failedQueries}
                        deselectErroredOutputs={deselectErroredOutputs}
                    />
                )}

            </Scroller>
        </Layout>
    );
};


/**
 * @typedef { object } ErrorProps
 * @prop { useRecipes['failedQueries'] } failedQueries
 * @prop { useRecipes['deselectErroredOutputs'] } deselectErroredOutputs
 */

/** @type { React.FC<ErrorProps> } */
export const RecipesError = ({
    failedQueries,
    deselectErroredOutputs,
}) => {

    if (!failedQueries.length) {
        return null;
    }

    const firstFailed = failedQueries[0];
    const error = firstFailed.error;

    return (
        <Callout flat color="error">
            <Layout flex alignItems="center">
                <Icon icon="exclamation-circle" prefix="fas" />
                <Layout spacing="ml-3">
                    {error?.code === 'NotFound' && (
                        <>
                            Cannot run recipe because one or more{' '}
                            of the required resources doesn&apos;t exist.
                            <br/>
                            {!!error.details?.resource && (
                                <>({error.details?.resource})</>
                            )}
                        </>
                    )}
                    {error?.code === 'Duplicate' && (
                        <>
                            Cannot run recipe because one or more views already exist.{' '}
                            You can try changing the destination schema/prefix, or{' '}
                            <ClickableText onClick={deselectErroredOutputs}>
                                deselect the view(s)
                            </ClickableText>.
                        </>
                    )}
                    {
                        error?.code !== 'NotFound' && error?.code !== 'Duplicate' && (
                            <>
                                {error?.message}
                                <br/>
                                {!!error?.details?.resource && (
                                    <>({error?.details?.resource})</>
                                )}
                            </>
                        )
                    }
                </Layout>
            </Layout>
        </Callout>
    );
};


const ClickableText = styled('a')`
  text-decoration: underline;
  color: inherit;
  cursor: pointer;
`;

export default RecipesConfigStep;
