import {
    uniqWith,
    isEqual,
} from 'lodash';

import {
    get,
    post,
    put,
    del,
} from '../../lib/ajax';

import { addBreadcrumb } from '../../lib/error-reporter';

/** @typedef { import('./__types').Source } Source */
/** @typedef { import('./__types').SourceDefaults } SourceDefaults */
/** @typedef { import('./__types').SourceType } SourceType */
/** @typedef { import('./__types').CollectConfig } CollectConfig */
/** @typedef { import('./__types').Param } Param */
/** @typedef { import('./__types').CollectJob } CollectJob */
/** @typedef { import('./__types').Schedule } Schedule */

/** @typedef { import('./__types').DynamicParamResponse } DynamicParamResponse */
/** @typedef { import('./__types').DynamicParamArgs } DynamicParamArgs */

/** @typedef { import('./__types').ValidationParamResponse} ValidationParamResponse */

/** @typedef { import('./__types').OAuth1Param } OAuth1Param */
/** @typedef { import('./__types').OAuth2Param } OAuth2Param */


/** @type { (sourceTypeId: SourceType['id']) => Promise<SourceType> } */
export const fetchSourceType = async (sourceTypeId) => {
    try {
        const { data: sourceType } = await get('/sources/types/' + sourceTypeId);
        return sourceType;
    } catch (error) {
        addBreadcrumb('Could not fetch a source type', { sourceTypeId });
        throw error;
    }
};


/** @type { (sourceId: Source['id']) => Promise<Source> } */
export const fetchSource = async (sourceId) => {
    try {
        const { data: source } = await get(`/sources/${sourceId}`);
        return source;
    } catch (/** @type { any } */ error) {
        addBreadcrumb('Could not fetch source', { sourceId });
        if (error.status === 404) {
            error.code = 'NOT_FOUND';
        }
        throw error;
    }
};

/** @type { (sourceId: Source['id']) => Promise<Partial<CollectConfig>> } */
export const fetchSourceCollectConfig = async (sourceId) => {
    try {
        const { data: collectConfig } = await get(`/collects/${sourceId}`);
        return collectConfig;
    } catch (/** @type { any } */ error) {
        if (error.status === 404) {
            return {};
        }
        addBreadcrumb('Could not fetch source collect config', { sourceId });
        throw error;
    }
};

/** @type { (cc: Partial<CollectConfig>, sourceId: Source['id']) => Promise<void> } */
export const saveSourceCollectConfig = async (collectConfig, sourceId) => {
    try {
        const { data: savedCollectConfig } = await put(
            `/collects/${sourceId}`,
            { body: collectConfig },
        );
        return savedCollectConfig;
    } catch (/** @type { any } */ error) {
        if (error.status === 404) {
            const { data: savedCollectConfig } = await post(
                `/collects/${sourceId}`,
                { body: collectConfig },
            );
            return savedCollectConfig;
        }
        addBreadcrumb('Could not save source collect config', { sourceId });
        throw error;
    }
};

/** @type { (sourceId: Source['id']) => Promise<void> } */
export const deleteSourceCollectConfig = async (sourceId) => {
    try {
        await del(`/collects/${sourceId}`);
    } catch (/** @type { any } */ error) {
        if (error.status === 404) {
            return;
        }
        addBreadcrumb('Could not delete source collect config', { sourceId });
        throw error;
    }
};

/** @type { (sourceId: Source['id']) => Promise<SourceDefaults> } */
export const fetchSourceDefaults = async (sourceId) => {
    try {
        const { data: sourceDefaults } = await get(`/sources/${sourceId}/defaults`);
        return sourceDefaults;
    } catch (/** @type { any } */ error) {
        addBreadcrumb('Could not fetch source defaults', { sourceId });
        if (error.status === 404) {
            error.code = 'NOT_FOUND';
        }
        throw error;
    }
};

/** @type { () => Promise<SourceType[]> } */
export const fetchSourceTypes = async () => {
    try {
        const { data: sourceTypes } = await get('/sources/types');
        return sourceTypes;
    } catch (error) {
        addBreadcrumb('Could not fetch source types');
        throw error;
    }
};

/** @type { (query?: Object) => Promise<Source[]> } */
export const fetchSourceList = async (query) => {
    try {
        const { data: sources } = query
            ? await get('/sources/', { query })
            : await get('/sources');

        return sources;
    } catch (error) {
        addBreadcrumb('Could not fetch source list');
        throw error;
    }
};


/** @type { (query?: Object) => Promise<Source[]> } */
export const fetchAllSources = async (query) => {
    const pageSize = 1000;

    /** @type { (prevSources?: Source[], page?: number) => Promise<Source[]> } */
    const combineSourcePages = async (prevSources = [], page = 0) => {
        const { data: sources } = await get('/sources', {
            query: {
                ...query,
                $offset: page * pageSize,
                $limit: pageSize + 1,
            },
        });
        if (sources.length > pageSize) {
            return combineSourcePages([...prevSources, ...sources], page + 1);
        }
        return [...prevSources, ...sources];
    };

    try {
        const fetchedSources = await combineSourcePages();
        // The sources list endpoint can return more then the specified limit
        // (Legacy behavior from having both DSS and legacy sources)
        // so we can have duplicates we must remove
        const dedupedSources = uniqWith(fetchedSources, isEqual);
        return dedupedSources;
    } catch (error) {
        addBreadcrumb('Could not fetch source list');
        throw error;
    }
};


/** @type { (jobId: CollectJob['id']) => Promise<CollectJob> } */
export const fetchSourceJob = async (jobId) => {
    try {
        const { data: job } = await get(`/sources/history/${jobId}`);
        return job;
    } catch (error) {
        addBreadcrumb('Could not fetch source job');
        throw error;
    }
};


/** @type { (typeId: SourceType['id'], title: Source['title']) => Promise<Source> } */
export const createSource = async (typeId, title) => {
    const { data } = await post('/sources', {
        body: {
            type: typeId,
            title,
        },
    });
    return data;
};

/** @type { (source: Source) => Promise<void> } */
export const collectSource = async (source) => {
    await post('/sources/collect', { body: source });
};

/** @type { (source: Source) => Promise<void> } */
export const cancelSourceJob = async (source) => {
    if (!source.job?.id) {
        throw new Error('Can\'t cancel collection on a source without a job');
    }
    await del(`/jobs/${source.job.id}`);
};


/** @type { (source: Source) => Promise<void> } */
export const saveSource = async (source) => {
    await put(`/sources/${source.id}`, { body: source });
};


/** @type { (sourceId: Source['id']) => Promise<void> } */
export const deleteSource = async (sourceId) => {
    await del(`/sources/${sourceId}`);
};


/** @type { (sourceId: Source['id'], title?: Source['title']) => Promise<Source> } */
export const cloneSource = async (sourceId, title) => {
    const { data } = await post(`/sources/${sourceId}/clone`, { body: { title } });
    return data;
};


/** @type { (sourceId: Source['id'], ids: Source['id'][], params: Param[]) => Promise<void> } */
export const syncSources = async (sourceId, ids, params) => {
    await post(`/sources/${sourceId}/sync`, { body: { ids, params } });
};


/** @type { (source: Source) => Promise<Array<CollectJob>> } */
export const fetchSourceHistory = async (source) => {
    const query = {
        source: source.id,
        sort: '-id',
        limit: 10,
    };
    const { data: history } = await get('/sources/history', {
        query,
    });
    return history;
};


/**
 * @param { Source } source
 * @param { Param['name'] } name
 * @returns { Promise<ValidationParamResponse> }
 */
export const fetchValidationParam = async (source, name) => {
    const { data } = await post(`/sources/${source.type}/${name}`, {
        body: source,
    });
    return data;
};


/**
 * @param { Source } source
 * @param { Param['name'] } name
 * @param { DynamicParamArgs } args
 * @returns { Promise<DynamicParamResponse> }
 */
export const fetchDynamicParam = async (source, name, args) => {
    const { data } = await post(`/sources/${source.type}/${name}`, {
        body: source,
        query: args,
    });
    return data;
};


/** @type { (source: Source, schedule: Schedule) => Promise<void> } */
export const createSourceSchedule = async (source, schedule) => {
    const payload = {
        ...schedule,
        payload: {
            id: source.id,
            type: source.type,
            title: source.title,
        },
    };
    await post('/scheduler', { body: payload });
};

/** @type { (source: Source, schedule: Schedule) => Promise<void> } */
export const updateSourceSchedule = async (source, schedule) => {
    const payload = {
        ...schedule,
        payload: {
            id: source.id,
            type: source.type,
            title: source.title,
        },
    };
    await put(`/scheduler/${source.id}-collect`, { body: payload });
};

/** @type { (source: Source) => Promise<void> } */
export const deleteSourceSchedule = async (source) => {
    await del(`/scheduler/${source.id}-collect`);
};
