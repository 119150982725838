import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInput } from 'ui-components';

import { useRequest } from '../../lib/ajax';
import { useSession } from '../../shared/context-providers';
import gotoUrl from '../../lib/goto-url';

import {
    trackSignupFormInvalid,
    trackSignupVerification,
} from './tracking';

/** @typedef { import('../../app/__types').PageProps } PageProps */
/** @typedef { import('../../app/__types').Session } Session */
/** @typedef { import('../../app/__types').Database['type'] } DatabaseType */

/**
 * @typedef { object } DatabaseRegionOption
 * @prop { string } title - what to show to the user
 * @prop { string } value - what the server expects
 */

/**
 * @param { string } token
 * @param { Session['user'] } user
 * @param { Session['database'] } database
 * @returns { boolean }
 */
function isFromOAuth(token, user, database) {
    return token === 'oauth' && !!user.id && !database.id;
}

/** @type { (t: DatabaseType) => boolean } */
function shouldShowDatabaseRegion(databaseType) {
    return databaseType === 'bigquery';
}

/**
 * @typedef { object } Props
 * @prop { string } tokenToValidate
 */

/**
 * @typedef { object } ReturnProps
 * @prop { string } databaseName
 * @prop { string } password
 * @prop { string } email
 * @prop { string } username
 * @prop { string } fname
 * @prop { DatabaseType } databaseType
 * @prop { string } databaseRegion
 * @prop { DatabaseRegionOption[] } databaseRegionOptions
 * @prop { boolean } showDatabaseType
 * @prop { boolean } showDatabaseRegion
 * @prop { boolean } databaseNameIsValid
 * @prop { boolean } passwordIsValid
 * @prop { boolean } usernameIsValid
 * @prop { boolean } tokenIsValid
 * @prop { string } passwordLabel
 * @prop { boolean } validateIsLoading
 * @prop { boolean } completeIsLoading
 * @prop { { message: string } } validateError
 * @prop { { message: string } } completeError
 * @prop { boolean } completeIsDisabled
 * @prop { () => void } toggleShowDatabaseType
 * @prop { () => void } signOut
 * @prop { (value: string) => void } onDatabaseNameChanged
 * @prop { (value: string) => void } onPasswordChanged
 * @prop { (value: string) => void } onUsernameChanged
 * @prop { (value: string) => void } onDatabaseTypeChanged
 * @prop { (value: string) => void } onDatabaseRegionChanged
 * @prop { (valid: boolean) => void } onDatabaseNameIsValidChanged
 * @prop { (valid: boolean) => void } onPasswordIsValidChanged
 * @prop { (valid: boolean) => void } onUsernameIsValidChanged
 * @prop { () => void } onCompleteSignupClicked
 * @prop { () => void } onFormEnterPressed
 */

/** @type { (props: Props) => ReturnProps } */
function useCompleteSignupForm({ tokenToValidate }) {
    const [session, sessionActions] = useSession();

    const { user, database } = session;

    const navigate = useNavigate();

    const databaseNameInput = useInput('Database name');
    const passwordInput = useInput('Password');
    const usernameInput = useInput('Username');

    const [databaseType, setDatabaseType] = useState(
        /** @type { DatabaseType } */ ('bigquery')
    );
    const databaseRegionOptions = [
        { title: 'US (default)', value: 'us' },
        { title: 'EU', value: 'eu' },
    ];
    const [databaseRegion, setDatabaseRegion] = useState(databaseRegionOptions[0].value);

    const [showDatabaseType, setShowDatabaseType] = useState(false);
    const [showDatabaseRegion, setShowDatabaseRegion] = useState(
        shouldShowDatabaseRegion(databaseType)
    );

    const databaseName = databaseNameInput.value;
    const username = usernameInput.value;
    const password = passwordInput.value;
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [fname, setFname] = useState('');

    const [databaseNameIsValid, setDatabaseNameIsValid] = useState(false);
    const [passwordIsValid, setPasswordIsValid] = useState(false);
    const [usernameIsValid, setUsernameIsValid] = useState(false);
    const [tokenIsValid, setTokenIsValid] = useState(false);
    const [validating, setValidating] = useState(true);
    const [completeIsLoading, setCompleteIsLoading] = useState(false);
    const [passwordLabel, setPasswordLabel] = useState('Set your Panoply password');

    const { go: validate, ...validateReq } = useRequest(`/signups/${tokenToValidate}`);
    const { go: complete, ...completeReq } = useRequest(`/signups/${token}`, 'POST', {
        dbname: databaseName,
        dbtype: databaseType,
        region: databaseRegion,
        password,
        email,
        username,
    });
    const { go: login, ...loginReq } = useRequest('/users/login', 'POST', {
        password,
        email,
    });

    const validateIsLoading = validating || validateReq.loading;

    const validateError = validateReq.error;
    const completeError = completeReq.error || loginReq.error;

    const completeIsDisabled = completeIsLoading
        || !databaseNameIsValid
        || !passwordIsValid
        || !usernameIsValid;

    const onDatabaseNameChanged = databaseNameInput.bind.onChange;
    const onPasswordChanged = passwordInput.bind.onChange;
    const onUsernameChanged = usernameInput.bind.onChange;

    const onDatabaseTypeChanged = useCallback((e) => {
        const { value } = e.target;

        setDatabaseType(value);
        setShowDatabaseRegion(shouldShowDatabaseRegion(value));
    }, []);

    const onDatabaseRegionChanged = useCallback((e) => {
        setDatabaseRegion(e.target.value);
    }, []);

    const toggleShowDatabaseType = useCallback(() => {
        setShowDatabaseType((s) => !s);
    }, []);

    const onDatabaseNameIsValidChanged = useCallback((valid) => {
        setDatabaseNameIsValid(valid);
    }, []);

    const onPasswordIsValidChanged = useCallback((valid) => {
        setPasswordIsValid(valid);
    }, []);

    const onUsernameIsValidChanged = useCallback((valid) => {
        setUsernameIsValid(valid);
    }, []);

    const onCompleteSignupClicked = useCallback(() => {
        if (!databaseNameIsValid || !passwordIsValid || !usernameIsValid || !email) {
            trackSignupFormInvalid(databaseNameIsValid, passwordIsValid);
            return;
        }
        setCompleteIsLoading(true);
        complete()
            .then((res) => {
                if (!res.ok) {
                    setCompleteIsLoading(false);
                    return res;
                }
                return login();
            })
            .then(async (res) => {
                if (!res?.ok) {
                    setCompleteIsLoading(false);
                    return;
                }

                await sessionActions.refreshSession();

                navigate('/sources/new', { replace: true });
            });
    }, [
        databaseNameIsValid,
        passwordIsValid,
        usernameIsValid,
        email,
        complete,
        login,
        sessionActions.refreshSession,
    ]);

    const onFormEnterPressed = useCallback((e) => {
        if (completeIsDisabled || e.key !== 'Enter') {
            return;
        }
        onCompleteSignupClicked();
    }, [completeIsDisabled, onCompleteSignupClicked]);

    const signOut = useCallback(() => {
        gotoUrl('/users/logout');
    }, []);

    useEffect(() => {

        if (!tokenToValidate || tokenIsValid) {
            return;
        }

        trackSignupVerification();

        // If the user already has a database
        // then they shouldn't be on this page
        if (user.id && database.id) {
            navigate('/', { replace: true });
            return;
        }

        // If the user is from the OAuth Signup (Google)
        // then there is no need to validate the token
        if (isFromOAuth(tokenToValidate, user, database)) {
            const { id, email, fname } = user;

            setToken(id);
            setEmail(email);
            setFname(fname || '');
            setTokenIsValid(true);
            setValidating(false);
            setPasswordLabel('Set your data warehouse password');
            return;
        }

        // If the user is not from the OAuth Signup
        // then there is a need to validate the token
        validate().then((res) => {
            if (!res.ok || !res.data.email) {
                return;
            }
            setToken(tokenToValidate);
            setEmail(res.data.email);
            setFname(res.data.fname);
            setTokenIsValid(true);
            setValidating(false);
        });
    }, [
        tokenToValidate,
        tokenIsValid,
        database,
        user,
        navigate,
        validate,
    ]);

    return {
        databaseName,
        password,
        username,
        email,
        fname,
        databaseType,
        databaseRegion,
        databaseRegionOptions,
        showDatabaseType,
        showDatabaseRegion,
        databaseNameIsValid,
        passwordIsValid,
        usernameIsValid,
        tokenIsValid,
        passwordLabel,
        validateIsLoading,
        completeIsLoading,
        validateError,
        completeError,
        completeIsDisabled,
        signOut,
        onDatabaseNameChanged,
        onPasswordChanged,
        onUsernameChanged,
        toggleShowDatabaseType,
        onDatabaseTypeChanged,
        onDatabaseRegionChanged,
        onDatabaseNameIsValidChanged,
        onPasswordIsValidChanged,
        onUsernameIsValidChanged,
        onCompleteSignupClicked,
        onFormEnterPressed,
    };
}

export default useCompleteSignupForm;
