
import React, { useCallback, useEffect, useRef } from 'react';
import {
    Select, SelectItem, Loader, Layout, Callout,
} from 'ui-components';
import MarkdownRenderer from '../../../../shared/markdown-renderer';

/** @typedef { import('models/sources/__types').DynamicSelectParam } Param */
/** @typedef { import('models/sources/__types').SelectParamOption } ParamOption */
/** @typedef { import('models/sources/__types').DynamicParamArgs } DynamicParamArgs */
/** @typedef { import('models/sources/__types').DynamicParamResponse } DynamicParamResponse */

/**
 * @typedef { object } State
 * @prop { boolean= } loading
 * @prop { string= } error
 * @prop { ParamOption[] } options
 */

/**
 * @typedef { object } Props
 * @prop { Param } param
 * @prop { string } value
 * @prop { State } state
 * @prop { (value: State) => void } setState
 * @prop { (value: State) => void } setValue
 * @prop { (args: DynamicParamArgs) => Promise<DynamicParamResponse['data']>} loadOptions
 */

/** @type { React.FC<Props> } */
const SourceDynamicSelectParam = ({
    param,
    value,
    setValue,
    state,
    setState,
    loadOptions,
}) => {
    // Used to keep track of most recent req to prevent UI jitter
    const requestIdRef = useRef(0);

    const { options = [], loading = false, error } = state;

    const shouldLoadOptions = !options.length && !loading && !error;

    const onSelectChanged = useCallback((e) => {
        setValue(e.target.value);
    }, [setValue]);

    const load = useCallback(async () => {
        setState({ ...state, loading: true, error: undefined });

        // We use this later to tell if a newer request has already been sent
        // If so we do nothing with the response in order to prevent UI jitter.
        const requestId = Date.now();
        requestIdRef.current = requestId;

        try {
            const { values } = await loadOptions({});
            if (requestId !== requestIdRef.current) {
                return;
            }
            setState({
                ...state,
                options: values,
                loading: false,
            });
        } catch (e) {
            if (requestId !== requestIdRef.current) {
                return;
            }
            setState({
                ...state,
                loading: false,
                error: e.message,
            });
        }
    }, [setState, state, loadOptions]);

    useEffect(() => {
        if (shouldLoadOptions) {
            load();
        }
    }, [shouldLoadOptions, load]);

    if (error) {
        return <Callout color="error">{error}</Callout>;
    }

    if (loading) {
        return (
            <Layout spacing="ml-4">
                <Loader active />
            </Layout>
        );
    }

    return (
        <Layout relative width="100" mdWidth="50">
            <Select
                onChange={onSelectChanged}
                value={value || ''}
                noFloatLabel
                width="100"
                spacing="mx-0 px-0"
                helperText={param.description && (
                    <MarkdownRenderer source={param.description} />
                )}
            >
                <SelectItem disabled value="">
                    {param.placeholder || 'Select'}
                </SelectItem>
                {!!options.length && options.map(option => (
                    <SelectItem
                        key={option.name}
                        value={option.value || option.name}
                        disabled={option.disabled || false}
                    >
                        {option.name}
                    </SelectItem>
                ))}
            </Select>
        </Layout>
    );
};

export default SourceDynamicSelectParam;
