import React from 'react';
import { Dialog, Button, Typography, Icon } from 'ui-components';


/** @typedef { import('./__types').Mode } Mode */

/**
 * @typedef { object } WarningDialogProps
 * @prop { () => void } onClose
 * @prop { () => void } onConfirm
 * @prop { boolean } open
 * @prop { { label: string, warning: string} } newMode
 */

/** @type { React.FC<WarningDialogProps> } */
const WorkbenchModeDialog = ({ onClose, open, onConfirm, newMode }) => {
    return (
        <Dialog
            key={`warning-dialog-${open}`}
            isOpen={open}
            onClose={onClose}
            actions={[
                <Button
                    type="plain"
                    key="cancel"
                    onClick={onClose}

                >
                    Cancel
                </Button>,
                <Button
                    color="primary"
                    key="confirm"
                    onClick={onConfirm}
                >
                    Yes, Switch to {newMode?.label}
                </Button>,
            ]}
        >
            <Typography
                variant="h6"
                align="center"
                color="warning"
                spacing="mb-4"
            >
                <Icon
                    prefix="fas"
                    icon="exclamation-circle"
                    size="lg"
                />
            </Typography>
            <Typography
                spacing="mt-2"
                color="text"
                variant="h6"
                align="center">
                Switch to {newMode.label} ?
            </Typography>
            <Typography
                spacing="mt-2"
                color="text"
                variant="body1"
                align="center">
                {newMode.warning}
            </Typography>
        </Dialog>
    );
};


export default WorkbenchModeDialog;
