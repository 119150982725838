/* eslint-disable complexity */
import React, { useState, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';

import {
    Grid,
    Card,
    Loader,
    Typography,
    Layout,
    Button,
    Icon,
    Anchor,
    Tooltip,
    ToggleButton,
} from 'ui-components';

import {
    getParentQueryStatus,
    executeQuery,
} from '../../models/queries';

import { fetchReport } from '../../models/reports';
import { reportError } from '../../lib/error-reporter';
import WorkbenchVisualization from '../../shared/workbench/workbench-visualization';
import useResizeObserver from '../../shared/workbench/use-resize-observer';
import { items as visualizationItems } from '../../shared/workbench/use-workbench-visualization';
import LastUpdated from '../../shared/last-updated';
import Time from '../../shared/time';
import { removeEmpty } from './use-dashboard-page';
import ShareDropdown from './share-dropdown';

/** @typedef { import('models/reports').Report } Report */
/** @typedef { import('models/reports').SingleValueReport } SingleValueReport */
/** @typedef { import('models/reports').VAxisFormat } VAxisFormat */
/** @typedef { import('models/dashboards').DashboardItem } DashboardItem */
/** @typedef { import('models/dashboards').Widget } Widget */
/** @typedef { import('models/dashboards').Position } Position */
/** @typedef { import('./types').Mode } Mode */
/** @typedef { import('./types').Layout } Layout */
/** @typedef { import('./types').DroppingItem } DroppingItem */
/** @typedef { import('./types').SharingItem } SharingItem */
/** @typedef { Exclude<VAxisFormat, 'percent'> } MinimizableVAxisFormat */

/** @type { (report: Report) => report is SingleValueReport } */
function isSingleValue(report) {
    return report.type === 'single-value';
}

/** @type { (report: SingleValueReport) => VAxisFormat | undefined } */
function getVAxisFormat(report) {
    const item = visualizationItems[report.type];

    const defaultVAxisFormat = /** @type { VAxisFormat= } */ (
        item.config?.vAxisFormat.default
    );

    const { vAxisFormat } = report.config;

    return vAxisFormat || defaultVAxisFormat;
}

/** @type { (report: SingleValueReport) => boolean } */
function canMinimize(report) {
    const vAxisFormat = getVAxisFormat(report);

    return ['number', 'currency'].some(format => (
        vAxisFormat?.endsWith(format)
    ));
}

/** @type { (report: SingleValueReport) => boolean } */
function isMinimized(report) {
    return ['short-'].some(format => (
        report.config.vAxisFormat?.startsWith(format)
    ));
}

/** @type { (report: Report, w: number) => 'right' | 'bottom' } */
function getLegendPosition(report, w) {
    return report.type === 'pie-chart' && w > 4
        ? 'right'
        : 'bottom';
}

/**
 * @typedef { object } DashboardGridProps
 * @prop { Layout[] } layout
 * @prop { boolean } editable
 * @prop { DashboardItem[] } items
 * @prop { DroppingItem } [droppingItem]
 * @prop { number } refreshedAt
 * @prop { (newLayout: Layout[]) => void } changeLayout
 * @prop { (id: DashboardItem['id']) => Promise<void> } deleteDashboardItem
 * @prop { (item: Layout) => void } addDroppedItem
 * @prop { (sharingItem?: SharingItem) => void } toggleShareDialog
 */

/** @type { React.FC<DashboardGridProps> } */
const DashboardGrid = ({
    layout: currentLayout,
    editable,
    items,
    droppingItem,
    refreshedAt,
    changeLayout,
    deleteDashboardItem,
    addDroppedItem,
    toggleShareDialog,
}) => {
    const [layout, filteredItems] = useMemo(() => {
        const filteredItems = items
            .filter(({ position: { x, y } }) => x !== -1 && y !== -1)
            .map(item => ({
                ...item,
                position: {
                    ...item.position,
                    ...removeEmpty(currentLayout.find(l => l.i === item.id) || {}),
                },
            }));

        const layout = filteredItems.map(({ id, position }) => ({
            i: id,
            w: 12,
            h: 10,
            ...position,
        }));

        return [layout, filteredItems];
    }, [items, currentLayout]);

    return (
        <Grid
            layout={layout}
            onLayoutChange={layout => {
                changeLayout(layout);
            }}
            onDrop={(layout, item) => {
                changeLayout(layout);
                addDroppedItem(item);
            }}
            draggableCancel=".button, .anchor, .resizer"
            isResizable={editable}
            isDraggable={editable}
            isDroppable={editable}
            droppingItem={droppingItem}
        >
            {filteredItems.map(({ id, widget, position }) => {
                return (
                    <Item key={id}>
                        <Widget
                            id={id}
                            widget={widget}
                            position={position}
                            refreshedAt={refreshedAt}
                            editable={editable}
                            deleteDashboardItem={deleteDashboardItem}
                            toggleShareDialog={toggleShareDialog}
                        />
                    </Item>
                );
            })}
        </Grid>
    );
};

/**
 * @typedef { object } WidgetProps
 * @prop { DashboardItem['id'] } id
 * @prop { Widget } widget
 * @prop { Position } position
 * @prop { number } refreshedAt
 * @prop { boolean } editable
 * @prop { (id: DashboardItem['id']) => Promise<void> } deleteDashboardItem
 * @prop { (sharingItem?: SharingItem) => void } toggleShareDialog
 */

/** @type { React.FC<WidgetProps> } */
const Widget = (props) => {
    const { widget } = props;

    switch (widget.type) {
        case 'report-ref':
            return <ReportRefWidget {...props} />;
        default:
            return <UnknownWidget {...props} />;
    }
};

/** @type { React.FC<WidgetProps> } */
const ReportRefWidget = ({
    id,
    widget,
    position,
    refreshedAt,
    editable,
    deleteDashboardItem,
    toggleShareDialog,
}) => {
    const {
        visualizationHeight,
        visualizationWidth,
        resizeListenerElemRef,
    } = useResizeObserver({ borderHeight: 0 });

    const {
        data,
        isLoading,
        isRefetching,
        isError,
        dataUpdatedAt: updatedAt,
        refetch,
    } = useQuery({
        queryKey: ['report-data', widget.id],
        queryFn: async () => {
            const report = await fetchReport(widget.id);

            try {
                switch (report.query.type) {
                    case 'sql': {
                        const results = await executeQuery(report.query.sql, {
                            polling: true,
                        });

                        const status = getParentQueryStatus(results);
                        const queryData = results[0]?.data;

                        return { report, status, queryData };
                    }
                }
            } catch (/** @type { any } */ err) {
                reportError(err);
            }

            return { report, status: 'Done' };
        },
        staleTime: Infinity,
        gcTime: Infinity,
    });

    const [deleteOpen, setDeleteOpen] = useState(false);

    const [editedReport, setEditedReport] = useState(
        /** @type { Report= } */ (data?.report),
    );

    const toggleDelete = () => {
        setDeleteOpen(open => !open);
    };

    /** @type { (report: SingleValueReport) => void } */
    const toggleMinimize = (report) => {
        const vAxisFormat = getVAxisFormat(report);

        const newVAxisFormat = /** @type { MinimizableVAxisFormat } */ (
            isMinimized(report)
                ? vAxisFormat?.replace('short-', '')
                : `short-${vAxisFormat}`
        );

        setEditedReport({
            ...report,
            config: {
                ...report.config,
                vAxisFormat: newVAxisFormat,
            },
        });
    };

    const refreshedAtRef = useRef(refreshedAt);

    const refresh = async () => {
        const { data } = await refetch();

        setEditedReport(data?.report);
    };

    useEffect(() => {
        if (refreshedAt === refreshedAtRef.current) {
            return;
        }

        refreshedAtRef.current = refreshedAt;

        refresh();
    }, [refreshedAt]);

    const status = data?.status;
    const queryData = data?.queryData;
    const report = editedReport || data?.report;

    const { w = 0 } = position;

    const shouldShowError = () => isError || status !== 'Done';
    const color = report?.config?.colorPalette || 'table-text';
    const noBorder = !report?.config?.showBorders;

    return (
        <WidgetCard id={widget.id} contentSpacing="p-0">
            <ReportRefWidgetHeader
                report={report}
                editable={editable}
                deleteOpen={deleteOpen}
                toggleDelete={toggleDelete}
                toggleMinimize={toggleMinimize}
                toggleShareDialog={toggleShareDialog}
            />

            <ResizeListener ref={resizeListenerElemRef}>
                {deleteOpen && (
                    <ReportRefWidgetDelete
                        id={id}
                        toggleDelete={toggleDelete}
                        deleteDashboardItem={deleteDashboardItem}
                    />
                )}

                {!isLoading && shouldShowError() && !deleteOpen && (
                    <ReportRefWidgetError
                        isRefetching={isRefetching}
                        refresh={refresh}
                    />
                )}

                {report && queryData && !shouldShowError() && !deleteOpen && (
                    <WorkbenchVisualization
                        queryData={queryData}
                        queryIsRunning={false}
                        visualizationReport={report}
                        visualizationHeight={visualizationHeight}
                        visualizationWidth={visualizationWidth}
                        legendPosition={getLegendPosition(report, w)}
                        noBorder={noBorder}
                        color={color}
                    />
                )}

                <Loader
                    active={isLoading || (!report && !shouldShowError())}
                    absolute
                    overlay
                    big
                    message="Loading Data"
                />
            </ResizeListener>

            <ReportRefWidgetFooter
                isRefetching={isRefetching}
                updatedAt={updatedAt}
                showError={shouldShowError()}
                editable={editable}
                refresh={refresh}
            />
        </WidgetCard>
    );
};

/**
 * @typedef { object } ReportRefWidgetHeaderProps
 * @prop { Report } [report]
 * @prop { boolean } editable
 * @prop { boolean } deleteOpen
 * @prop { () => void } toggleDelete
 * @prop { (report: SingleValueReport) => void } toggleMinimize
 * @prop { (sharingItem?: SharingItem) => void } toggleShareDialog
 */

/** @type { React.FC<ReportRefWidgetHeaderProps> } */
const ReportRefWidgetHeader = ({
    report,
    editable,
    deleteOpen,
    toggleDelete,
    toggleMinimize,
    toggleShareDialog,
}) => (
    <WidgetHeader
        flex
        alignItems="center"
        justifyContent="space-between"
    >
        <Title variant="body1" spacing="m-0 ml-3">
            {report?.title || <>&nbsp;</>}
        </Title>

        <Layout
            flex
            alignItems="center"
            justifyContent="flex-end"
            spacing="px-1"
        >
            {report && !deleteOpen && (
                <>
                    <Tooltip
                        className="tooltip-wrap"
                        content="Open in Workbench"
                        interactive={false}
                        placement="top"
                    >
                        <Button
                            square
                            type="plain"
                            spacing="m-0 p-1"
                            onClick={() => (
                                window.open(`/#/workbench/reports/${report.id}`, '_blank')
                            )}
                        >
                            <Icon icon="external-link" size="sm" />
                        </Button>
                    </Tooltip>

                    {isSingleValue(report) && canMinimize(report) && (
                        <Tooltip
                            className="tooltip-wrap"
                            content={isMinimized(report) ? 'Long Format' : 'Short Format'}
                            interactive={false}
                            placement="top"
                        >
                            <ToggleButton
                                square
                                type="plain"
                                color="secondaryText"
                                spacing="m-0 p-1"
                                onClick={() => toggleMinimize(report)}
                                active={isMinimized(report)}
                            >
                                <Icon icon="arrows-minimize" size="sm" />
                            </ToggleButton>
                        </Tooltip>
                    )}

                    {!editable && (
                        <ShareDropdown
                            title={report.title}
                            reportId={report.id}
                            toggleShareDialog={toggleShareDialog}
                            spacing="m-0 p-1"
                            compact
                        />
                    )}
                </>
            )}

            {editable && !deleteOpen && (
                <Tooltip
                    className="tooltip-wrap"
                    content="Remove from Dashboard"
                    interactive={false}
                    placement="top"
                >
                    <Button
                        square
                        type="plain"
                        spacing="m-0 p-1"
                        onClick={toggleDelete}
                    >
                        <Icon icon="trash" size="sm" color="error" />
                    </Button>
                </Tooltip>
            )}
        </Layout>
    </WidgetHeader>
);

/**
 * @typedef { object } ReportRefWidgetFooterProps
 * @prop { boolean } isRefetching
 * @prop { number } updatedAt
 * @prop { boolean } showError
 * @prop { boolean } editable
 * @prop { () => void } refresh
 */

/** @type { React.FC<ReportRefWidgetFooterProps> } */
const ReportRefWidgetFooter = ({
    isRefetching,
    updatedAt,
    showError,
    editable,
    refresh,
}) => (
    <WidgetFooter
        flex
        alignItems="center"
        justifyContent="flex-end"
        spacing="px-3"
    >
        {!editable && !showError ? (
            <>
                <Typography
                    inline
                    className="anchor-wrap"
                    component="span"
                    variant="body2"
                    color="secondaryText"
                    spacing="m-0"
                >
                    {!isRefetching && (
                        <Anchor
                            hoverColor="primary"
                            onClick={refresh}
                        >
                            Refresh
                        </Anchor>
                    )}

                    <Loader
                        color="interface"
                        active={isRefetching}
                    />
                </Typography>

                {updatedAt > 0 && (
                    <NoWrapTypography
                        inline
                        component="span"
                        variant="body2"
                        color="interface"
                        spacing="m-0 ml-1"
                        align="right"
                    >
                        <Time timestamp={updatedAt}>
                            <LastUpdated
                                timestamp={updatedAt}
                                withoutPrefix
                                withoutSuffix
                            />
                            {' '}
                            old
                        </Time>
                    </NoWrapTypography>
                )}
            </>
        ) : (
            <>
                &nbsp;
            </>
        )}
    </WidgetFooter>
);

/**
 * @typedef { object } ReportRefWidgetErrorProps
 * @prop { boolean } isRefetching
 * @prop { () => void } refresh
 */

/** @type { React.FC<ReportRefWidgetErrorProps> } */
const ReportRefWidgetError = ({ isRefetching, refresh }) => (
    <WidgetLayout
        flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
    >
        <Typography
            variant="h6"
            align="center"
            color="error"
            spacing="mb-4"
        >
            <Icon
                prefix="fas"
                icon="exclamation-circle"
                size="lg"
            />
        </Typography>

        <Typography
            variant="body2"
            align="center"
            color="error"
            spacing="mb-4"
        >
            Failed to load
        </Typography>

        <Button
            onClick={refresh}
            loading={isRefetching}
        >
            Retry
        </Button>
    </WidgetLayout>
);

/**
 * @typedef { object } ReportRefWidgetDeleteProps
 * @prop { DashboardItem['id'] } id
 * @prop { () => void } toggleDelete
 * @prop { (id: DashboardItem['id']) => Promise<void> } deleteDashboardItem
 */

/** @type { React.FC<ReportRefWidgetDeleteProps> } */
const ReportRefWidgetDelete = ({
    id,
    toggleDelete,
    deleteDashboardItem,
}) => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');

    const del = async () => {
        setLoading(true);
        setStatus('');

        try {
            await deleteDashboardItem(id);
            setStatus('success');

        } catch (/** @type { any } */ err) {
            reportError(err);
            setStatus('error');

        } finally {
            setLoading(false);
        }
    };

    return (
        <WidgetLayout
            flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
        >
            {status === 'error' && (
                <Typography
                    variant="body2"
                    align="center"
                    color="error"
                    spacing="mb-4"
                >
                    Failed to remove
                </Typography>
            )}

            <Button
                type="outline"
                onClick={del}
                color="error"
                spacing="mb-4"
                loading={loading}
            >
                Remove from Dashboard
            </Button>

            <Button
                onClick={toggleDelete}
                disabled={loading}
            >
                Cancel
            </Button>
        </WidgetLayout>
    );
};

/** @type { React.FC<WidgetProps> } */
const UnknownWidget = ({ widget }) => {
    return (
        <WidgetCard>
            Unknown widget type: {widget.type}
        </WidgetCard>
    );
};

const WidgetCard = styled(Card)`
    display: flex;
    flex-direction: column;
    height: 100%;

    & > .MuiCardContent-root {
        flex-grow: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
`;

const WidgetLayout = styled(Layout)`
    height: 100%;
`;

const WidgetHeader = styled(Layout)`
    min-height: 38px;
`;

const WidgetFooter = styled(Layout)`
    min-height: 32px;
`;

const ResizeListener = styled.div`
    overflow: hidden;
    height: 100%;
`;

const Title = styled(Typography)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-grow: 1;
`;

const Item = styled.div`
    overflow: hidden;
    height: 100%;
`;

const NoWrapTypography = styled(Typography)`
    white-space: nowrap;
`;

export default DashboardGrid;
