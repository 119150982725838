import React from 'react';

/**
 * @typedef { object } TimeProps
 * @prop { number } timestamp
 * @prop { React.ReactNode } children
 */

/** @type { React.FC<TimeProps> } */
const Time = ({ timestamp, children }) => {
    return (
        <time dateTime={new Date(timestamp).toISOString()}>
            {children}
        </time>
    );
};

export default Time;
