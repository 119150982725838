import React from 'react';
import styled from 'styled-components';
import {
    Layout,
} from 'ui-components';

const NestedPageWrapper = styled(({ className, children, spacing }) => {
    return (
        <Layout className={className} spacing={`my-7 px-5 ${spacing || ''}`}>
            {children}
        </Layout>
    );
})`
    margin-left: auto;
    margin-right: auto;
    height: auto;
    ${({ maxWidth }) => maxWidth && `
        max-width: 1122px;
    `}
`;

export default NestedPageWrapper;
