import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import { range } from 'lodash';

import {
    Layout,
    Typography,
    Loader,
    Icon,
    Card,
    Progress,
    Tooltip,
    Select,
    SelectItem,
    Button,
    colors,
} from 'ui-components';

import Feature from '../../../shared/feature';
import { formatBytes } from '../../../shared/formatters';
import { GIBIBYTE } from '../../../shared/constants';
import ReportsDrawer, { ReportsSelect } from './reports-drawer';
import useReportsDrawer from './use-reports-drawer';

/** @typedef { import('../__types').QuotasState } QuotasState */
/** @typedef { import('../__types').ChangeEvent } ChangeEvent */

/** @typedef { { getLabel: (m: number) => string } } MonthProps */

/** @type { Record<number, MonthProps> } */
const months = {
    0: {
        getLabel: m => `${m} months ago`,
    },
    1: {
        getLabel: () => 'This month',
    },
    2: {
        getLabel: () => 'Last month',
    },
};

/** @type { (month: number) => MonthProps } */
const getMonthProps = month => months[month] || months[0];

/** @type { (p: number | string, t: number) => number } */
export function percentage(partialValue = 1, totalValue = 1) {
    if (totalValue === 0) {
        return 100;
    }
    return Math.min(100, (100 * Number(partialValue)) / totalValue);
}

/** @type { React.FC<{ quotas: QuotasState }> } */
const Usage = ({ quotas }) => {
    const { loading, rowsExtracted, storage, queryBytes } = quotas;
    const [month, setMonth] = useState(0);

    const onMonthChanged = useCallback((/** @type { ChangeEvent } */ e) => {
        setMonth(Number(e.target.value));
    }, []);

    const onMonthCleared = useCallback(() => {
        setMonth(0);
    }, []);

    const {
        report,
        onReportChanged,
        onReportCleared,
    } = useReportsDrawer();

    return (
        <Layout spacing="mb-5">
            <Layout flex alignItems="center" justifyContent="space-between" spacing="mb-3">
                <Layout flex alignItems="center">
                    <Typography weight="medium">
                        Usage
                    </Typography>

                    <MonthsSelect
                        loading={loading}
                        month={month}
                        onMonthChanged={onMonthChanged}
                        onMonthCleared={onMonthCleared}
                    />
                </Layout>

                <ReportsSelect
                    report={report}
                    onReportChanged={onReportChanged}
                    disabled={loading}
                />

                <ReportsDrawer
                    month={month}
                    report={report}
                    onReportChanged={onReportChanged}
                    onReportCleared={onReportCleared}
                />
            </Layout>

            <Card contentSpacing="p-0">
                <Layout flex>
                    <RowsExtractedUsage
                        loading={loading}
                        rowsExtracted={rowsExtracted}
                        month={month}
                    />

                    <StorageUsage
                        loading={loading}
                        storage={storage}
                        month={month}
                    />

                    <QueryBytesUsage
                        loading={loading}
                        queryBytes={queryBytes}
                        month={month}
                    />
                </Layout>
            </Card>
        </Layout>
    );
};

/**
 * @typedef { object } MonthsSelectProps
 * @prop { QuotasState['loading'] } loading
 * @prop { number } month
 * @prop { (e: ChangeEvent) => void } onMonthChanged
 * @prop { () => void } onMonthCleared
 */

/** @type { React.FC<MonthsSelectProps> } */
const MonthsSelect = ({ loading, month, onMonthChanged, onMonthCleared }) => (
    <>
        <Select
            value={String(month)}
            onChange={onMonthChanged}
            disabled={loading}
            spacing="p-0 pl-3 pr-1"
            noFloatLabel
        >
            {range(0, 7).map(i => (
                <SelectItem key={`key-${i}`} value={String(i)}>
                    {getMonthProps(i + 1).getLabel(i)}
                </SelectItem>
            ))}
        </Select>

        {month > 0 && (
            <Button
                round
                type="plain"
                spacing="m-0 p-2"
                onClick={onMonthCleared}
            >
                <Icon icon="times-circle" prefix="fas" size="sm" />
            </Button>
        )}
    </>
);

/**
 * @typedef { object } RowsExtractedUsageProps
 * @prop { QuotasState['loading'] } loading
 * @prop { QuotasState['rowsExtracted'] } rowsExtracted
 * @prop { number } month
 */

/** @type { React.FC<RowsExtractedUsageProps> } */
const RowsExtractedUsage = ({ loading, rowsExtracted, month }) => {

    const rowsUsed = rowsExtracted?.used?.[month]?.quantity;
    const rowsPrev = rowsExtracted?.used?.[month + 1]?.quantity;
    const rowsLimit = rowsExtracted?.limit;

    const currMonth = month === 0;
    const hideRows = rowsUsed == null;

    if (hideRows) {
        return null;
    }

    const rowsUsedPercent = currMonth && rowsLimit
        ? percentage(rowsUsed, rowsLimit)
        : undefined;

    const formattedRowsUsed = numeral(rowsUsed).format('0.[00]a');
    const formattedRowsPrev = numeral(rowsPrev || 0).format('0.[00]a');
    const formattedRowsLimit = numeral(rowsLimit).format('0.[00]a');
    const longFormattedRowsUsed = numeral(rowsUsed).format('0,0');

    return (
        <UsagePanel
            title="Rows Extracted"
            icon="sync"
            usageLabel={`${formattedRowsUsed} rows`}
            usagePeriod={rowsPrev
                ? `${formattedRowsPrev} previous month`
                : (currMonth ? 'This month' : 'That month')}
            usagePercent={rowsUsedPercent}
            tooltipContent={
                <>
                    <Typography spacing="mb-2">
                        Rows Extracted
                    </Typography>
                    <Typography spacing="mb-2">
                        The number of rows extracted by all of your
                        Panoply connectors this month is{' '}
                        <Typography component="span" weight="semibold" inline>
                            {longFormattedRowsUsed}
                        </Typography>
                        {' '}rows.
                    </Typography>
                    {rowsLimit && (
                        <Typography>
                            Your current plan allows up to {formattedRowsLimit}{' '}
                            rows extracted each month.
                        </Typography>
                    )}
                </>
            }
            loading={loading}
        />
    );
};

/**
 * @typedef { object } StorageUsageProps
 * @prop { QuotasState['loading'] } loading
 * @prop { QuotasState['storage'] } storage
 * @prop { number } month
 */

/** @type { React.FC<StorageUsageProps> } */
const StorageUsage = ({ loading, storage, month }) => {

    const storageBytesUsed = storage?.used?.[month]?.quantity;
    const storageBytesPrev = storage?.used?.[month + 1]?.quantity;
    const storageBytesAllowed = storage?.limit;

    const currMonth = month === 0;
    const hideStorage = storageBytesUsed == null || storageBytesAllowed == null;

    if (hideStorage) {
        return null;
    }

    // Used storage is defined in bytes and allowed storage is defined in gibibytes
    const formattedStorageUsed = storageBytesUsed !== '0'
        ? formatBytes(storageBytesUsed)
        : 'N/A';

    const formattedStoragePrev = storageBytesPrev !== '0'
        ? formatBytes(storageBytesPrev || 0)
        : 'N/A';

    const formattedStorageAllowed = formatBytes(storageBytesAllowed, GIBIBYTE);

    const storageUsagePercent = currMonth
        ? percentage(storageBytesUsed, (storageBytesAllowed) * GIBIBYTE)
        : undefined;

    return (
        <UsagePanel
            title="Storage"
            icon="database"
            usageLabel={formattedStorageUsed}
            usagePeriod={storageBytesPrev
                ? `${formattedStoragePrev} previous month`
                : (currMonth ? 'This month' : 'That month')}
            usagePercent={storageUsagePercent}
            tooltipContent={
                <>
                    <Typography spacing="mb-2">
                        Storage
                    </Typography>
                    <Typography spacing="mb-2">
                        The storage used across the entire warehouse
                        (connectors, temp tables, etc) is {' '}
                        <Typography component="span" weight="semibold" inline>
                            {formattedStorageUsed}
                        </Typography>.
                    </Typography>
                    <Typography>
                        Your current plan allows up to {formattedStorageAllowed} storage.
                    </Typography>
                </>
            }
            loading={loading}
        />
    );
};

/**
 * @typedef { object } QueryBytesUsageProps
 * @prop { QuotasState['loading'] } loading
 * @prop { QuotasState['queryBytes'] } queryBytes
 * @prop { number } month
 */

/** @type { React.FC<QueryBytesUsageProps> } */
const QueryBytesUsage = ({ loading, queryBytes, month }) => {

    const queryBytesUsed = queryBytes?.used ? queryBytes?.used?.[month]?.quantity : null;
    const queryBytesPrev = queryBytes?.used ? queryBytes?.used?.[month + 1]?.quantity : null;
    const queryBytesAllowed = queryBytes?.limit;

    const currMonth = month === 0;
    const hideQueryBytes = queryBytesUsed == null || queryBytesAllowed == null;

    if (hideQueryBytes) {
        return null;
    }

    // Used QB are defined in bytes and allowed bytes are defined in gibibytes
    const formattedQueryUsed = formatBytes(queryBytesUsed);
    const formattedQueryPrev = formatBytes(queryBytesPrev || 0);
    const formattedQueryAllowed = formatBytes(queryBytesAllowed, GIBIBYTE);

    const queryUsagePercent = currMonth
        ? percentage(queryBytesUsed || 0, (queryBytesAllowed || 0) * GIBIBYTE)
        : undefined;

    return (
        <Feature databaseType="bigquery">
            <UsagePanel
                title="Query Bytes"
                icon="tasks-alt"
                usageLabel={formattedQueryUsed}
                usagePeriod={queryBytesPrev
                    ? `${formattedQueryPrev} previous month`
                    : (currMonth ? 'This month' : 'That month')}
                usagePercent={queryUsagePercent}
                tooltipContent={
                    <>
                        <Typography spacing="mb-2">Query Bytes</Typography>
                        <Typography spacing="mb-2">
                            The queries processed across the entire warehouse this month
                            (including connected BI tools and external workbenches) is{' '}
                            <Typography component="span" weight="semibold" inline>
                                {formattedQueryUsed}
                            </Typography>.
                        </Typography>
                        <Typography>
                            Your current plan allows up to {formattedQueryAllowed} queries
                            processed each month.
                        </Typography>
                    </>
                }
                loading={loading}
            />
        </Feature>
    );
};

/**
 * @typedef {object} UsagePanelProps
 * @prop { string } icon
 * @prop { string } title
 * @prop { string= } usageLabel
 * @prop { string= } usagePeriod
 * @prop { number= } usagePercent
 * @prop { React.ReactNode= } tooltipContent
 * @prop { boolean= } loading
 */

/** @type { React.FC<UsagePanelProps> } */
const UsagePanel = ({
    icon,
    title,
    usageLabel,
    usagePeriod,
    usagePercent,
    tooltipContent,
    loading,
}) => {

    const hideProgress = usagePercent == null;
    const progressColor = usagePercent && usagePercent > 95
        ? 'error'
        : 'primary';

    return (
        <Panel spacing="p-4 p-lg-5">
            <Layout flex alignItems="center">
                <Icon icon={icon} spacing="mr-3" />
                <Typography variant="body1" weight="medium" spacing="mb-0">
                    {title}
                </Typography>
            </Layout>

            {loading ? (
                <Loader spacing="mt-4 p-4" active />
            ) : (
                <Layout spacing="mt-4 p-4">
                    <Layout flex alignItems="center" justifyContent="space-between">
                        <Layout>
                            <PanelLabel variant="h4" weight="normal" spacing="mb-0">
                                {usageLabel}
                            </PanelLabel>
                            <Typography variant="body2" color="secondaryText">
                                {usagePeriod}
                            </Typography>
                        </Layout>
                        {tooltipContent && (
                            <Tooltip content={tooltipContent}>
                                <Icon icon="info-circle" color="secondaryText" />
                            </Tooltip>
                        )}
                    </Layout>

                    <UsageProgress
                        flat
                        hidden={hideProgress}
                        value={usagePercent}
                        color={progressColor}
                        spacing="mt-4"
                    />
                </Layout>
            )}
        </Panel>
    );
};

const UsageProgress = styled(Progress)`
    visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
`;

const PanelLabel = styled(Typography)`
    &&& {
        font-family: inherit;
    }
`;

const Panel = styled(Layout)`
    border-right: 1px solid ${colors.secondaryInterface};
    width: 100%;
    min-height: 180px;
    box-sizing: border-box;

    &:last-child {
        border-right: none;
    }
`;

export default Usage;
