/* eslint-disable complexity */
import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { formatBytes } from '../../shared/formatters';

import {
    Layout,
    Divider,
    Typography,
    ExpansionPanel,
    Icon,
    Button,
    Card,
    Tooltip,
} from 'ui-components';

import { useSession } from '../context-providers';
import { extractStatusData, statuses } from '../../models/jobs/status';
import JobStatus from './job-status';

/** @typedef { import('../../models/jobs/__types').Job } Job */

/**
 * @typedef { object } Props
 *
 * @prop { Job } job
 * @prop { (job: Job) => void } [onCancelJob]
 * @prop { (job: Job) => void } [onExportJob]
 * @prop { (job: Job) => string } [getJobSourceType]
 * @prop { (job: Job) => string } [getJobSourceTitle]
 * @prop { boolean } [flat]
 */

/** @type { React.FC<Props> } */
const JobsListItem = React.memo(({
    job,
    onCancelJob,
    onExportJob,
    getJobSourceType,
    getJobSourceTitle,
    flat = false,
}) => {
    const [session] = useSession();
    const { user, database } = session;

    const isBQ = database.type === 'bigquery';

    const userFacingJob = { ...job };

    const rowsExtracted = userFacingJob?.usage?.rowsExtracted;
    const isMaterialize = userFacingJob.type === 'materialize';
    const bytesBilled = userFacingJob.summary?.bytesBilled;

    const isExport = userFacingJob.type === 'export';
    const canExport = !!(userFacingJob.output
        && userFacingJob.output.result
        && userFacingJob.output.result.url
    );

    // Normalize type
    if (isMaterialize) {
        if (userFacingJob.data && !userFacingJob.data.name) {
            userFacingJob.name = 'materialize';
        } else if (userFacingJob.data && userFacingJob.data.aqm) {
            userFacingJob.type = 'materialize-aqm';
        } else {
            userFacingJob.type = 'materialize-view';
        }
    }

    const onCancelJobClicked = useCallback((e) => {
        e.stopPropagation();
        onCancelJob?.(job);
    }, [job, onCancelJob]);

    // If this kind of functionality grows it should be moved to new file
    const getDescriptionFromType = (type) => {
    /* eslint-disable */
    const descriptions = {
      'collect': 'Automatically collecting data at a regularly scheduled time.',
      'collect-direct': 'Manually collecting data one time.',
      'materialize': 'A materialization of a view or a query.',
      'materialize-aqm': 'Panoply’s AI machine learning algorithm which detects frequently run and long running queries, sub-queries or views. The AI automatically materializes the data to improve query performance. The AI also de-materializes the data if usage drops.',
      'materialize-view': 'A materialization manually created by a user to improve the speed of a query. The materialization generates a table with the cached results of the query.',
      'export': 'Data exported by a user as a .CSV file. The data is from a query run in the Analyze tool.',
      'alter': 'A change to the metadata of any table or to any table column name. The change must be user initiated.',
    };
    /* eslint-enable */

        // disguise 'replay-datalogs' as collect jobs
        descriptions['replay-datalogs'] = descriptions.collect;

        return descriptions[type] || type;
    };

    const {
        status: userFacingStatus,
        finished: jobIsDone,
    } = extractStatusData(userFacingJob);

    const formatSuccessOutput = () => {
        const results = ['Finished successfully.'];

        switch (userFacingJob.type) {
            case 'collect':
            case 'collect-direct': {
                const { tables, added, updated } = userFacingJob.summary || {};

                if (tables) {
                    results.push(
                        `${tables.length} tables affected.`,
                        `${added} rows were added (total).`,
                        `${updated} rows were updated (total).`,
                        'tables affected:',
                        ...tables
                    );
                }
                break;
            }
            case 'export':
                results.push('Export file is ready for download.');
                break;
            default:
                break;
        }

        return results;
    };

    const showOutput = jobIsDone && (
        (userFacingJob.output && userFacingJob.ended_at)
        || userFacingJob.error
        || (userFacingJob.output && userFacingJob.output.error)
    );

    const isCancelable = useMemo(() => {

        /* eslint-disable quote-props */
        const constraints = {
            '*': ['pending'],
            'collect': ['booting', 'running'],
            'collect-direct': ['booting', 'running'],
        };
        /* eslint-enable quote-props */

        const general = constraints['*'];
        const special = constraints[userFacingJob.type] || [];
        const validStatuses = [...general, ...special];

        // @ts-ignore
        return [statuses.Running, statuses.Pending].includes(userFacingStatus)
            && validStatuses.includes(userFacingJob.status);

    }, [userFacingJob]);

    const formatDate = (date) => {
        const d = moment.utc(date);
        if (!d.isValid()) {
            return null;
        }
        return d.format('MMM DD, YYYY [@] HH:mm [UTC]');
    };

    const formatType = (type) => {
    /* eslint-disable quote-props */
        const types = {
            'collect': 'Scheduled collection',
            'collect-direct': 'Manual collection',
            'materialize': 'Materialization',
            'materialize-aqm': 'Panoply AI materialization',
            'materialize-view': 'View materialization',
            'export': 'Export',
            'alter': 'Alter',
        };
        /* eslint-enable quote-props */

        // disguise 'replay-datalogs' as collect jobs
        types['replay-datalogs'] = types.collect;

        return types[type] || type;
    };

    const formattedType = formatType(userFacingJob.type);

    const onExportJobClicked = useCallback(() => {
        onExportJob?.(job);
    }, [onExportJob, job]);

    const makeExpansionPanelLabel = () => {

        const jobSourceType = getJobSourceType?.(userFacingJob);
        const jobSourceTitle = getJobSourceTitle?.(userFacingJob);

        /* eslint-disable complexity */
        const getJobLabel = () => {
            const { type, data } = userFacingJob;
            let name = data?.name;

            if (['collect', 'collect-direct', 'replay-datalogs'].includes(type)) {
                if (jobSourceTitle || jobSourceType) {
                    name = jobSourceTitle || jobSourceType;
                    if (jobSourceType && name !== jobSourceType) {
                        name = `${name} [${jobSourceType}]`;
                    }
                }
            }

            if (type === 'alter') {
                const { schema, table } = data?.arguments || {};
                name = database.type === 'bigquery'
                    ? `Alter \`${schema}.${table}\``
                    : `Alter "${schema}"."${table}"`;

                switch (data?.type) {
                    case 'columnName':
                        name = `${name} column name`;
                        break;
                    case 'columnDataType':
                        name = `${name} column data type`;
                        break;
                    default:
                        // do nothing
                        break;
                }
            }

            if (type === 'export') {
                name = data?.sql.replace('\n', ' ');
            }

            return name || formattedType;
        };

        const jobLabel = getJobLabel();

        return (
            <Layout
                width="100"
                spacing="py-2"
                flex
                alignItems="center"
                justifyContent="space-between"
            >
                <Layout width="30" spacing="pr-3" flex>
                    <Typography noWrap color="text">
                        {jobLabel}
                    </Typography>
                    {jobLabel !== formattedType && (
                        <Typography noWrap color="secondaryText" spacing="ml-1">
                            ({formattedType})
                        </Typography>
                    )}
                </Layout>

                <Layout width="20" spacing="pr-3">
                    <Typography noWrap>{formatDate(userFacingJob.created_at)}</Typography>
                </Layout>

                <Layout width="15" spacing="pr-3">
                    <JobStatus job={job} />
                </Layout>

                <Layout width="15" spacing="pr-3">
                    <Typography noWrap>{isNaN(rowsExtracted) ? '' : rowsExtracted}</Typography>
                </Layout>

                {isBQ && (
                    <Layout width="15" spacing="pr-3">
                        <Typography noWrap>
                            {bytesBilled == null ? '' : formatBytes(bytesBilled)}
                        </Typography>
                    </Layout>
                )}

                <CancelColumn spacing="-my-1 pr-0">
                    {isCancelable && onCancelJob && (
                        <Tooltip
                            content="Stop collecting"
                            placement="top"
                            interactive={false}
                        >
                            <CancelButton
                                onClick={onCancelJobClicked}
                                type="plain"
                                spacing="m-0 py-1"
                            >
                                <Icon icon="stop-circle" size="sm" />
                            </CancelButton>
                        </Tooltip>
                    )}
                </CancelColumn>

            </Layout>
        );
    };

    return (
        <ExpansionPanel
            key={job.id}
            labelComponent={makeExpansionPanelLabel()}
            flat={flat}
        >
            <Layout flex={false} width="100">
                <Layout spacing="mt-4 mb-3" flex>
                    <DetailsKey spacing="mr-3">Description</DetailsKey>
                    <Typography color="secondaryText">
                        {getDescriptionFromType(userFacingJob.type)}
                    </Typography>
                </Layout>
                <Divider spacing="mt-2" />
                <Layout spacing="mt-4 mb-3" flex>
                    <DetailsKey spacing="mr-3">Job ID</DetailsKey>
                    <Typography color="secondaryText">
                        {userFacingJob.id}
                    </Typography>
                </Layout>
                <Divider spacing="mt-2" />
                <Layout spacing="mt-4 mb-3" flex>
                    <DetailsKey spacing="mr-3">Status</DetailsKey>
                    <Typography color="secondaryText">
                        {userFacingStatus}
                    </Typography>
                </Layout>
                <Divider spacing="mt-2" />
                <Layout spacing="mt-4 mb-3" flex>
                    <DetailsKey spacing="mr-3">Created</DetailsKey>
                    <Typography color="secondaryText">
                        {formatDate(userFacingJob.created_at)}
                    </Typography>
                </Layout>

                {jobIsDone && userFacingJob.ended_at && (
                    <Layout spacing="mt-4 mb-3" flex>
                        <DetailsKey spacing="mr-3">Completed</DetailsKey>
                        <Typography color="secondaryText">
                            {formatDate(userFacingJob.ended_at)}
                        </Typography>
                    </Layout>
                )}

                {isMaterialize && userFacingJob?.data?.name && (
                    <>
                        <Divider spacing="mt-2" />
                        <Layout spacing="mt-4 mb-3" flex>
                            <DetailsKey spacing="mr-3">View Name</DetailsKey>
                            <Typography color="secondaryText">
                                {userFacingJob.data.name}
                            </Typography>
                        </Layout>
                    </>
                )}

                {onExportJob && isExport && (
                    <>
                        <Divider spacing="mt-2" />
                        <Layout spacing="mt-4 mb-3" flex>
                            <DetailsKey spacing="mr-3">Download File</DetailsKey>
                            <Layout width="100">
                                <Button
                                    color="primary"
                                    spacing="ml-0"
                                    disabled={!canExport}
                                    onClick={onExportJobClicked}
                                >
                                    Download .CSV
                                </Button>
                            </Layout>
                        </Layout>
                        <Divider spacing="mt-2" />
                        <Layout spacing="mt-4 mb-3" flex>
                            <DetailsKey spacing="mr-3">SQL Query</DetailsKey>
                            <Layout width="100">
                                <Card
                                    color="secondaryInterface"
                                    flat
                                    width="100"
                                    contentSpacing="py-2 px-3 m-0"
                                >
                                    <OutputLine color="secondaryText">
                                        {userFacingJob?.data?.sql}
                                    </OutputLine>
                                </Card>
                            </Layout>
                        </Layout>
                    </>
                )}

                {showOutput && (
                    <>
                        <Divider spacing="mt-2" />
                        <Layout spacing="mt-4 mb-3" flex>
                            <DetailsKey spacing="mr-3">Output</DetailsKey>
                            <Layout width="100">
                                <ScrollableCard
                                    color="secondaryInterface"
                                    flat
                                    width="100"
                                    contentSpacing="py-2 px-3 m-0"
                                >
                                    {(userFacingJob.error || userFacingJob?.output?.error)
                                        && userFacingJob.status === 'error'
                                        ? (
                                            <OutputLine color="error">
                                                {userFacingJob.error
                                                    || userFacingJob?.output?.error?.message}
                                            </OutputLine>
                                        ) : (
                                            formatSuccessOutput().map(line => (
                                                <OutputLine color="secondaryText" key={line}>
                                                    {line}
                                                </OutputLine>
                                            ))
                                        )}
                                </ScrollableCard>
                            </Layout>
                        </Layout>
                    </>
                )}

                {(user.admin || user.developer) && (
                    <>
                        <Divider spacing="mt-2" />
                        <Layout spacing="mt-4 mb-3">
                            <ExpansionPanel
                                labelComponent={(
                                    <Layout>
                                        <Typography variant="subtitle2">Admin Dumpster</Typography>
                                        <Typography variant="caption">
                                            Caution JSON Inside
                                        </Typography>
                                    </Layout>
                                )}
                            >
                                <PreFormattedText>
                                    {JSON.stringify(job, null, 4)}
                                </PreFormattedText>
                            </ExpansionPanel>
                        </Layout>
                    </>
                )}

            </Layout>
        </ExpansionPanel>
    );
});

const PreFormattedText = styled('pre')`
    overflow: auto;
`;

const CancelColumn = styled(Layout)`
  min-width: 64px;
`;

const OutputLine = styled(Typography)`
  && {
    font-family: 'Roboto Mono', monospace;
    word-break: break-word;
  }
`;

const ScrollableCard = styled(Card)`
    && {
        overflow: auto;
        max-height: 214px;
      }
`;

const DetailsKey = styled(Typography)`
  min-width: 160px;
`;

const CancelButton = styled(Button)`
  min-width: 64px;
`;

export default JobsListItem;
