/** @typedef { Record<string, any> } Data */
/** @typedef { Record<string, any> } Properties */

/** @typedef { number } SelectedVariant */
/** @typedef { string } ExperimentId */
/** @typedef { Record<ExperimentId, SelectedVariant> } Experiments */

/**
 * @typedef { object } OldTagManagerData
 * @prop { string } event
 * @prop { string } [event_name]
 * @prop { Properties } [event_properties]
 * @prop { Data } [data]
 * @prop { PageData } [page]
 * @prop { UserData } [user]
 * @prop { object } [data]
 * @prop { Experiments } [experiments]
 */

/**
 * @typedef { object } NewTagManagerData
 * @prop { string } event this is the actual event name - we must have an 'event' field in our
 * datalayer object, because it triggers the event in GTM platform. in our case we use 'event'
 * as the event name.
 * https://developers.google.com/tag-platform/devguides/datalayer#use_a_data_layer_with_event_handlers
 * @prop { string } type
 * @prop { string } action
 * @prop { string } date
 * @prop { string } [user]
 * @prop { string } [db]
 * @prop { string } [value]
 * @prop { Experiments } [experiments]
 */

/**
 * @typedef { object } PageData
 * @prop { string } page_name
 * @prop { string } page_path
 */

/**
 * @typedef { object } UserData
 *
 * @prop { string } email
 * @prop { string } fname
 * @prop { string } lname
 * @prop { string } user_id
 * @prop { string } database_id
 * @prop { string } database_name
 * @prop { string } database_type
 * @prop { string } database_owner_id
 * @prop { string } database_created_at
 * @prop { boolean } database_on_trial
 * @prop { string } plan
 */

/**
 * Adds an object to GTM data layer.
 *
 * @type { (d: OldTagManagerData | NewTagManagerData) => void }
 */
function addToDataLayer(data) {
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push(data);
}


/**
 * @typedef {(
 *  'click' |
 *  'input' |
 *  'selection' |
 *  'add' |
 *  'remove' |
 *  'search' |
 *  'toggle' |
 *  'visit' )} Type
 */

/**
 * @typedef { object } TrackEventNew
 * @prop { string } event this is the actual event name - we must have an 'event' field in our
 * datalayer object, because it triggers the event in GTM platform. in our case we use 'event'
 * as the event name.
 * https://developers.google.com/tag-platform/devguides/datalayer#use_a_data_layer_with_event_handlers
 * @prop { Type } type
 * @prop { string } action
 * @prop { string } [db]
 * @prop { string } [user]
 * @prop { string } [value]
 */

/**
 * @type {(e: TrackEventNew) => void}
 */
function track(e) {
    const { event, type, action, user, db, value } = e;

    const data = /** @type { NewTagManagerData } */ ({
        event,
        type,
        action,
        date: new Date().toISOString(),
    });

    if (user) {
        data.user = user;
    }

    if (db) {
        data.db = db;
    }

    if (value) {
        data.value = value;
    }

    const experiments = getActiveExperiments();

    if (experiments) {
        data.experiments = experiments;
    }

    addToDataLayer(data);
}


/**
 * Begin of @deprecated Types, these types are deprecated and should be
 * removed once we change the PageView event to use  the new event structure
 */

/**
 * @deprecated
 * @typedef { Omit<import('models/tracking').TrackEvent, 'data'> & {
 *     props?: object
 * } } TrackEventOld
 */

/**
 * @deprecated
 * old tracking function, this one is only used for the PageView event and should
 * be removed once the events start using the new structure
 * @type { (e: TrackEventOld) => void }
*/
function oldTrack(event) {
    /** @type { OldTagManagerData } */
    const data = {
        event: event.type || event.name,
        event_name: event.name,
        event_properties: event.props || {},
        data: undefined,
    };

    if (event.page) {
        data.page = event.page;
    }

    if (event.user) {
        data.user = event.user;
    }

    const experiments = getActiveExperiments();
    if (experiments) {
        data.experiments = experiments;
    }

    addToDataLayer(data);
}

/**
 * Checks gaData global for status of running experiments
 *
 * @type { () => Experiments | undefined }
 */
function getActiveExperiments() {
    // @ts-ignore
    if (!window.gaData) {
        return undefined;
    }

    // @ts-ignore
    const gaData = Object.values(window.gaData)[0];
    if (gaData?.experiments) {
        return gaData.experiments;
    }
    return undefined;
}

export { oldTrack, track };
