import {
    useEffect,
    useState,
    useCallback,
} from 'react';

import { useNavigate } from 'react-router-dom';

import {
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';

import * as SM from '../../models/sources';
import { reportError } from '../../lib/error-reporter';
import useBatchActions from '../../shared/use-batch-actions';
import { trackAddConnector, trackFilterDropdownSelection, trackPrevAndNextBtns } from './tracking';


/** @typedef { import('models/sources/__types').Source } Source */
/** @typedef { import('models/sources/__types').SourceType } SourceType */
/** @typedef { import('models/sources/__types').Param } Param */
/** @typedef { import('models/sources/__types').Schedule } Schedule */

/** @typedef { Source[]= } SourcesState */
/** @typedef { SourceType[]= } SourceTypesState */
/** @typedef { import('./__types').SourcesFilter } SourcesFilter */

const PAGE_SIZE = 20;

const useSourceListPage = () => {

    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [page, setPage] = useState(0);

    const [search, setSearch] = useState('');

    const [filter, setFilter] = useState('');

    const {
        data: sources,
        error: sourcesError,
        isLoading: sourcesLoading,
        refetch,
    } = useQuery({
        queryKey: ['sources', page, search, filter, PAGE_SIZE],
        queryFn: () => {
            const $filter = filter ? resolveUserFacingFilter(filter) : undefined;

            /** @type {SourcesFilter} */
            const sourcesQuery = {
                $offset: page * PAGE_SIZE,
                // We load 1 more source then we display
                // To tell if there is a new page of results
                $limit: PAGE_SIZE + 1,
                $search: search ? {
                    field: 'title',
                    text: search,
                } : undefined,
                $filter,
            };

            return SM.fetchSourceList(sourcesQuery);
        },
    });

    const {
        data: sourceTypes,
        error: sourceTypesError,
        isLoading: sourceTypesLoading,
    } = useQuery({ queryKey: ['sourceTypes'], queryFn: SM.fetchSourceTypes });

    /** @type {(newSources: Source[]) => void} */
    const setSources = useCallback((newSources) => {
        queryClient.setQueryData(['sources', page, search, filter], newSources);
    }, [page, search, filter]);

    const loading = sourcesLoading || sourceTypesLoading;
    const loadingError = sourcesError || sourceTypesError;

    const [bulkDeleteDialogOpen, setBulkDeleteDialogOpen] = useState(false);
    const [bulkStopDialogOpen, setBulkStopDialogOpen] = useState(false);

    const [bulkScheduleDialogOpen, setBulkScheduleDialogOpen] = useState(false);


    const sourceLists = getSourceLists(sources || []);

    const sourcesToDisplay = sourceLists
        .reduce((arr, list) => arr.concat(list.slice(0, PAGE_SIZE)), [])
        .sort(sortByCreatedAtDesc);

    const {
        selectedObjects,
        allSelected,
        toggleSelectedObject,
        clearSelectedObjects,
        toggleSelectAll,
        isObjectSelected,
    } = useBatchActions(sourcesToDisplay);

    const selectedSources = /** @type {Source[]} */ (selectedObjects);

    const userHasNoSources = !loading && sources && sources.length < 1 && !search && !filter;
    const canNextPage = sourceLists.some(list => list.length > PAGE_SIZE);
    const canPrevPage = page > 0;

    /** @param {string | string[]} status */
    const makeJobStatusFilter = (status) => ({ job: { status } });

    /** @param {string} status */
    const resolveUserFacingFilter = (status) => {
        switch (status) {

            case 'Pending':
                return makeJobStatusFilter(['pending', 'booting']);
            case 'Running':
                return makeJobStatusFilter('running');
            case 'Success':
                return makeJobStatusFilter('success');
            case 'Failed/Canceled':
                return makeJobStatusFilter(['error', 'ext_error']);

            default:
                return {};
        }
    };

    /** @type { (e: React.ChangeEvent<HTMLInputElement>) => void } */
    const onFilterChanged = useCallback((e) => {
        setFilter(e.target.value);
        setPage(0);
        trackFilterDropdownSelection(e.target.value);
    }, []);

    const nextPage = useCallback(() => {
        setPage(page + 1);
        trackPrevAndNextBtns('next');
    }, [page]);

    const prevPage = useCallback(() => {
        if (page !== 0) {
            setPage(page - 1);
            trackPrevAndNextBtns('prev');
        }
    }, [page]);

    /** @type { (e: React.ChangeEvent<HTMLInputElement>) => void } */
    const onSearchChanged = useCallback((e) => {
        setSearch(e.target.value);
        setPage(0);
        clearSelectedObjects();
    }, [clearSelectedObjects]);

    /** @type { () => void } */
    const addSource = useCallback(() => {
        trackAddConnector();
        navigate('/sources/new');
    }, []);

    /** @type { (source: Source) => SourceType | null } */
    const getMatchingSourceType = useCallback((source) => {
        const match = sourceTypes && sourceTypes.find(t => t.id === source.type);
        if (!match) {
            const error = new Error(`Cant find matching sourceType for id ${source.type}`);
            reportError(error);
            return null;
        }
        return match;
    }, [sourceTypes]);


    /** @type { (numberOfDeleted?: number) => void } */
    const onSourceDeleted = useCallback(async (numberOfDeleted) => {
        if (!sources) {
            const error = new Error('Delete source called before sources loaded');
            reportError(error);
            return;
        }
        // If we deleted more sources then is on the page go back a page
        if (sources.length <= (numberOfDeleted || 1)) {
            if (page > 0) {
                prevPage(); // Changing page fetches sources as well
                return;
            }
        }
        queryClient.removeQueries({ queryKey: ['sources'], exact: false });
        refetch();

    }, [sources, prevPage, queryClient.removeQueries, refetch]);

    /** @type { (source: Source, newTitle: string) => Promise<void> } */
    const updateSourceTitle = useCallback(async (sourceToUpdate, newTitle) => {
        if (!sources) {
            const error = new Error('Update source called before sources loaded');
            reportError(error);
            return;
        }
        const updatedSource = { ...sourceToUpdate, title: newTitle };
        try {
            const sourceType = getMatchingSourceType(sourceToUpdate);
            if (!sourceType) {
                const error = new Error('updateSourceTitle called with no matching sourceType');
                reportError(error);
                return;
            }
            const flattenedParams = SM.getFlattenedParams(sourceType.params);
            await SM.saveSource(updatedSource, flattenedParams);
        } catch (/** @type { any } */ error) {
            reportError(error, { sourceId: sourceToUpdate.id });
        }

        // Optimistic update
        setSources(sources.map((source) => {
            if (source.id === sourceToUpdate.id) {
                return updatedSource;
            }
            return source;
        }));

    }, [sources, page, getMatchingSourceType, setSources]);

    /** @type { (sourceToUpdate: Source, newJob: Source['job']) => void } */
    const updateSourceJob = useCallback((sourceToUpdate, newJob) => {

        if (!sources) {
            const error = new Error('updateSourceStatus called before sources loaded');
            reportError(error);
            return;
        }

        setSources(sources.map((source) => {
            if (source.id === sourceToUpdate.id) {
                return { ...source, job: newJob };
            }
            return source;
        }));
    }, [sources]);

    /** @type { () => void } */
    const openBulkDeleteDialog = useCallback(() => {
        setBulkDeleteDialogOpen(true);
    }, []);

    /** @type { () => void } */
    const closeBulkDeleteDialog = useCallback(() => {
        setBulkDeleteDialogOpen(false);
        clearSelectedObjects();
    }, [clearSelectedObjects]);

    /** @type { () => void } */
    const openBulkScheduleDialog = useCallback(() => {
        setBulkScheduleDialogOpen(true);
    }, []);

    /** @type { () => void } */
    const closeBulkScheduleDialog = useCallback(() => {
        setBulkScheduleDialogOpen(false);
        clearSelectedObjects();
    }, [clearSelectedObjects]);

    /** @type { (source: Source, schedule: Schedule) => void } */
    const onScheduled = useCallback((source, schedule) => {
        if (!sources) {
            return;
        }
        const updatedSources = sources.map((s) => {
            if (s.id === source.id) {
                return {
                    ...s,
                    schedule: schedule.dayOfWeek,
                    schedule_hour: schedule.hour,
                    schedule_minute: schedule.minute,
                };
            }
            return s;
        });
        setSources(updatedSources);
    }, [sources]);

    const openBulkStopDialog = useCallback(() => {
        setBulkStopDialogOpen(true);
    }, []);

    const closeBulkStopDialog = useCallback(() => {
        setBulkStopDialogOpen(false);
        clearSelectedObjects();
    }, []);

    useEffect(() => {
        // to prevent bug when loading hook haven't been set yet
        const id = setTimeout(() => {
            if (userHasNoSources) {
                addSource();
            }
        });
        return () => clearTimeout(id);
    }, []);

    const filterOptions = [
        { value: 'Pending', display: 'Pending' },
        { value: 'Running', display: 'Running' },
        { value: 'Success', display: 'Success' },
        { value: 'Failed/Canceled', display: 'Failed/Canceled' },
    ];

    const clearAllFilters = useCallback(() => {
        setFilter('');
        setSearch('');
        setPage(0);
    }, []);

    return {
        sources: sourcesToDisplay,
        sourceTypes,
        userHasNoSources,
        loading,
        loadingError,
        search,
        page,
        canNextPage,
        canPrevPage,
        nextPage,
        prevPage,
        updateSourceJob,
        onSearchChanged,
        onSourceDeleted,
        updateSourceTitle,
        addSource,
        getMatchingSourceType,
        filter,
        setFilter,
        onFilterChanged,
        filterOptions,
        clearAllFilters,

        selectedObjects: selectedSources,
        allSelected,
        toggleSelectedObject,
        clearSelectedObjects,
        toggleSelectAll,
        isObjectSelected,
        bulkDeleteDialogOpen,
        closeBulkDeleteDialog,
        openBulkDeleteDialog,
        bulkScheduleDialogOpen,
        closeBulkScheduleDialog,
        openBulkScheduleDialog,
        onScheduled,

        bulkStopDialogOpen,
        openBulkStopDialog,
        closeBulkStopDialog,
    };
};

/** @typedef { Source[] } SourceList */

/** @type { (sources: Source[]) => [...SourceList[]] } */
function getSourceLists(sources) {
    const listsMap = sources.reduce((map, source) => {
        map[source.sourceList] = map[source.sourceList] || [];
        map[source.sourceList].push(source);

        return map;
    }, /** @type { { [key: string]: Source[] } } */ ({}));

    return Object.values(listsMap);
}

/** @type { (a: Source, b: Source) => number } */
function sortByCreatedAtDesc(a, b) {
    const valueA = a.createdAt || '';
    const valueB = b.createdAt || '';

    if (valueA > valueB) {
        return -1;
    }

    if (valueA < valueB) {
        return 1;
    }

    return 0;
}

export default useSourceListPage;
