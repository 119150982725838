import { useState, useCallback, useEffect } from 'react';

import { useRequest } from '../../lib/ajax';
import { useToast, useSession } from '../context-providers';
import usePlanFilters, { pricingTypes } from './use-plan-filters';

/** @typedef { import('app/__types').Plan } Plan */

/**
 * @typedef { object } Address
 * @prop { string } country
 * @prop { string | null } [city]
 * @prop { string | null } [line1]
 * @prop { string | null } [line2]
 * @prop { string | null } [postal_code]
 * @prop { string | null } [state]
 */

/**
 * @typedef { object } Subscription
 * @prop { string } captcha
 * @prop { Address } [address]
 * @prop { string } name
 * @prop { string } card
 * @prop { string } [email]
 * @prop { Plan } [plan]
 */

function useBilling() {
    const { openToast } = useToast();
    const [session, sessionActions] = useSession();
    const { user, database, billing } = session;
    const { updateSession } = sessionActions;
    const { filterPlans } = usePlanFilters();

    const [plans, setPlans] = useState(/** @type { Plan[] } */ ([]));
    const [subscription, setSubscription] = useState(/** @type { Subscription } */ ({}));
    const [visiblePlans, setVisiblePlans] = useState(/** @type { Plan[] } */ ([]));
    const [selectedPlan, setSelectedPlan] = useState(/** @type { Plan } */ ({}));
    const [pricingType, setPricingType] = useState(pricingTypes.annualMonth);
    const [showAnnualSwitch, setShowAnnualSwitch] = useState(true);

    const billingReq = useRequest(`/billing/${database.id}`);
    const createReq = useRequest('/billing', 'POST', subscription);
    const updateReq = useRequest(`/billing/${database.id}`, 'PUT', subscription);

    /** @type { (subscription: Subscription) => void } */
    const onUpdate = useCallback((subscription) => {
        setSubscription(s => ({ ...s, ...subscription, plan: selectedPlan }));
    }, [selectedPlan]);

    /** @type { (plan: Plan) => void  } */
    const onSelect = useCallback((plan) => {
        setSelectedPlan(plan);
    }, []);

    const onCancel = useCallback(() => {
        setSelectedPlan(/** @type { Plan } */ ({}));
    }, []);

    /** @type { (result: { ok: boolean, data: any }) => void } */
    const onBillingComplete = useCallback(({ ok, data }) => {

        if (!ok) {
            openToast('Could not fetch plans, please try again later');
            return;
        }

        let { plans } = data;

        updateSession({
            billing: {
                ...billing,
                plan: data.plan,
                onTrial: data.onTrial,
            },
        });

        if (!data.updatable) {
            // Not all subscriptions can be updated by the user. Some of the
            // subscriptions require internal Panoply intervention.
            plans = plans.map(/** @type { (p: Plan) => Plan } */ (p) => ({ ...p, disabled: true }));
        }

        setPlans(plans);

        setSubscription(s => ({
            ...s,
            captcha: '',
            card: data.card?.id,
            email: data.email || user.email,
            name: data.name,
            address: data.address,
        }));
    }, [billing, user.email, updateSession, openToast]);

    /** @type { (result: { ok: boolean, data: any }) => void } */
    const onUpdateComplete = useCallback(({ ok, data }) => {
        setSelectedPlan(/** @type { Plan } */ ({}));

        if (!ok) {
            openToast('An error has occurred, please try again later');
            return;
        }

        openToast('Plan updated successfully');

        onBillingComplete({ ok, data });
    }, [database.id, onBillingComplete, openToast]);

    useEffect(() => {
        if (!subscription.captcha) {
            return;
        }

        if (!subscription.card) {
            createReq.go().then(onUpdateComplete);
        } else {
            updateReq.go().then(onUpdateComplete);
        }
    }, [subscription]);

    useEffect(() => {
        billingReq.go().then(onBillingComplete);
    }, [database.id]);

    useEffect(() => {
        const annualPricesPresent = plans?.filter(el => el.pricingType === pricingTypes.annualMonth)
            .length > 0;

        const custom = billing.plan?.type === 'custom';

        setShowAnnualSwitch(annualPricesPresent && !custom);

        if (billing.plan) {
            setPricingType(billing.plan.pricingType);
        } else {
            setPricingType(
                annualPricesPresent ? pricingTypes.annualMonth : pricingTypes.monthToMonth,
            );
        }
    }, [plans, billing.plan]);

    useEffect(() => {
        setVisiblePlans(filterPlans({
            currentPlan: billing.plan,
            plans,
            pricingType,
            showAnnualSwitch,
        }));
    }, [billing, plans, pricingType, showAnnualSwitch]);

    return {
        loading: billingReq.loading,
        billing,
        selectedPlan,
        subscription,
        visiblePlans,
        onSelect,
        onCancel,
        onUpdate,
        pricingType,
        setPricingType,
        showAnnualSwitch,
    };
}

export default useBilling;
