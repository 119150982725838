import React, { useState } from 'react';
import styled from 'styled-components';

import {
    Divider,
    Icon,
    Layout,
    Typography,
    Link,
    colors,
} from 'ui-components';

import cdnIcon from '../../../../lib/cdn-icon';

/** @typedef { import('models/sources').SourceDefaults } SourceDefaults */
/** @typedef { import('models/sources').Source } Source */
/** @typedef { import('models/recipes').Recipe } Recipe */
/** @typedef { import('models/recipes/types').Output } Output */
/** @typedef { import('./use-recipes-dialog').OutputReq } OutputReq */

/**
 * @typedef { object } Props
 * @prop { Recipe | null } selectedParent
 * @prop { OutputReq[] } successfulQueries
 * @prop { OutputReq[] } failedQueries
 * @prop { string } destinationPrefix
 * @prop { string } destinationSchema
 * @prop { () => void } reset
 */

/** @type { React.FC<Props> } */
export const RecipesDialogFinished = ({
    selectedParent,
    successfulQueries,
    failedQueries,
    destinationSchema,
    destinationPrefix,
    reset,
}) => {

    const mainDashboard = selectedParent?.dashboards?.[0];

    return (
        <Layout width="100">
            {mainDashboard && successfulQueries.length > 0 && (
                <>
                    <Layout flex flexDirection="column" alignItems="center" spacing="pt-4 px-4">
                        <img src={cdnIcon('icon-recipe-dashboard.svg')} />
                        <Typography spacing="mt-5" align="center" color="secondaryText">
                            The {selectedParent?.title} Recipe includes a Dashboard template.{' '}
                            <TextLink
                                target="_blank"
                                rel="noreferrer"
                                href={mainDashboard.url}
                            >
                                Get the Dashboard now
                            </TextLink>
                        </Typography>
                    </Layout>
                    <Divider spacing="my-6 -mx-4" />
                </>
            )}

            {successfulQueries.length > 0 && (
                <>
                    <Layout flex flexDirection="column" alignItems="center" spacing="px-4">
                        <Icon icon="check-circle" color="secondary" />
                        <Typography spacing="mt-5 mb-3" align="center" color="secondaryText">
                            You’ve just saved the following views.
                            You can now visualize it directly in your{' '}
                            <Link link="/connect" color="primary" inline>BI tool</Link>{' '}
                            or{' '}
                            <Link
                                link={true}
                                onClick={reset}
                                color="primary"
                                inline
                            >
                                save another view
                            </Link>
                        </Typography>
                        {successfulQueries.map(successfulQuery => (
                            <React.Fragment key={successfulQuery.output.name}>
                                <Code>
                                    {destinationSchema}.
                                    {destinationPrefix}
                                    {successfulQuery.output.name}
                                </Code>
                                <Layout spacing="mb-2" />
                            </React.Fragment>
                        ))}
                    </Layout>
                    {failedQueries.length > 0 && (
                        <Divider spacing="my-6 -mx-4" />
                    )}
                </>
            )}

            {failedQueries.length > 0 && (
                <Layout flex flexDirection="column" alignItems="center" spacing="px-4">
                    <Icon icon="times-circle" color="error" />
                    <Typography spacing="mt-5 mb-3" align="center" color="secondaryText">
                        The following views could not be saved
                    </Typography>
                    {failedQueries.map(failedQuery => (
                        <FailedQuery
                            key={failedQuery.output.name}
                            failedQuery={failedQuery}
                        />
                    ))}
                </Layout>
            )}
        </Layout>
    );
};

/**
 * @typedef { object } FailedQueryProps
 * @prop { OutputReq } failedQuery
 */

/** @type { React.FC<FailedQueryProps> } */
export const FailedQuery = ({
    failedQuery,
}) => {
    const [showError, setShowError] = useState(false);
    return (
        <>
            <Code>
                <Layout flex>
                    {failedQuery.output.name}
                    {!showError && (
                        <Link spacing="ml-2" onClick={() => setShowError(true)}>(Show Error)</Link>
                    )}
                </Layout>
                {showError && (
                    <>
                        <br/> {failedQuery.error?.message}
                        <br/> ({failedQuery.error?.details.resource})
                    </>
                )}
            </Code>
            <Layout spacing="mb-2" />
        </>
    );
};

const Code = styled('code')`
    border: 1px solid ${colors.secondaryInterface};
    background: ${colors.secondaryBackground};
    border-radius: 0.25rem;
    padding: 0.125rem 0.25rem;
    font-size: 15px;
`;

const TextLink = styled('a')`
    cursor: pointer;
    font-weight: 500;
`;

export default RecipesDialogFinished;
