import React from 'react';
import PageLayout from '../../shared/page-layout';

import RotateServiceAccountForm from './rotate-service-account-form';
import RotateServiceAccountSuccess from './rotate-service-success';
import useRotateServicePage from './use-rotate-service-page';

/** @type { React.FC } */
const RotateServiceAccountPage = () => {
    const {
        loading,
        success,
        error,
        onRotateServiceAccountClicked,
    } = useRotateServicePage();

    if (success) {
        return (
            <PageLayout>
                <RotateServiceAccountSuccess />
            </PageLayout>
        );
    }

    const formProps = {
        loading,
        error,
        onRotateServiceAccountClicked,
    };

    return (
        <PageLayout>
            <RotateServiceAccountForm {...formProps} />
        </PageLayout>
    );
};

export default RotateServiceAccountPage;
