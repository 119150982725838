/* eslint-disable complexity */
import React from 'react';
import styled from 'styled-components';

import {
    Layout,
    Typography,
    Loader,
    Button,
    Card,
    Divider,
    SearchInput,
    Select,
    SelectItem,
    Link,
    Switch,
    colors,
} from 'ui-components';

import SourceListItem from './source-list-item';
import useSourceListPage from './use-source-list-page';
import BatchDeleteSourceDialog from './batch-delete-dialog';
import BatchScheduleSourceDialog from './batch-schedule-dialog';
import BatchStopSourceDialog from './batch-stop-dialog';
import PageError from '../../shared/page-error';
import PageLayout from '../../shared/page-layout';
import BatchActions from '../../shared/batch-actions';
import PageHeader from '../../shared/page-header';
import { trackAllConnectorsSelection, trackFilterDropdown } from './tracking';


/** @typedef { import('../../app/__types').PageProps } PageProps */

/** @type { React.FC<PageProps> } */
const SourceListPage = () => {

    const {
        sources,
        loading,
        loadingError,
        search,
        page,
        canNextPage,
        canPrevPage,
        nextPage,
        prevPage,
        userHasNoSources,
        updateSourceJob,
        onSearchChanged,
        onSourceDeleted,
        updateSourceTitle,
        addSource,
        getMatchingSourceType,
        filter,
        onFilterChanged,
        filterOptions,
        clearAllFilters,

        selectedObjects,
        allSelected,
        toggleSelectedObject,
        clearSelectedObjects,
        toggleSelectAll,
        isObjectSelected,
        openBulkDeleteDialog,
        closeBulkDeleteDialog,
        bulkDeleteDialogOpen,
        bulkScheduleDialogOpen,
        closeBulkScheduleDialog,
        openBulkScheduleDialog,
        onScheduled,
        openBulkStopDialog,
        closeBulkStopDialog,
        bulkStopDialogOpen,
    } = useSourceListPage();

    const batchActions = [
        {
            name: 'Stop Collection',
            action: () => {
                openBulkStopDialog();
            },
        },
        {
            name: 'Delete',
            action: () => {
                openBulkDeleteDialog();
            },
        },
        {
            name: 'Schedule',
            action: () => {
                openBulkScheduleDialog();
            },
        },
    ];

    return (
        <PageLayout title="Connectors">

            <PageHeader
                title="Connectors"
                titleAdornment={(
                    <>
                        {!userHasNoSources && (
                            <SearchInput
                                label="Search by name"
                                spacing="ml-6"
                                noFloatLabel
                                value={search}
                                debounceDuration={500}
                                onChange={onSearchChanged}
                                autoFocus
                            />
                        )}

                        <Select
                            key={filter}
                            value={filter}
                            onChange={onFilterChanged}
                            spacing="ml-0"
                            noFloatLabel
                            disabled={loading}
                            onOpen={() => trackFilterDropdown()}
                        >
                            <SelectItem value="">
                                <StyledSpan>Filter by status</StyledSpan>
                            </SelectItem>
                            {filterOptions.map(el => (
                                <SelectItem value={el.value} key={el.value}>
                                    {el.display}
                                </SelectItem>
                            ))}
                        </Select>
                    </>
                )}
            >
                <Button
                    color="primary"
                    spacing="mr-0"
                    onClick={addSource}
                    disabled={loading}
                >
                    Add Connector
                </Button>
            </PageHeader>


            <BatchActions
                selectedObjects={selectedObjects}
                clearSelectedObjects={clearSelectedObjects}
                actions={batchActions}
            />

            <BatchDeleteSourceDialog
                open={bulkDeleteDialogOpen}
                sourcesToDelete={selectedObjects}
                onClose={closeBulkDeleteDialog}
                onDeleted={onSourceDeleted}
                getMatchingSourceType={getMatchingSourceType}
            />

            <BatchScheduleSourceDialog
                open={bulkScheduleDialogOpen}
                sourcesToSchedule={selectedObjects}
                onClose={closeBulkScheduleDialog}
                onScheduled={onScheduled}
                getMatchingSourceType={getMatchingSourceType}
            />

            <BatchStopSourceDialog
                open={bulkStopDialogOpen}
                sourcesToStop={selectedObjects}
                onClose={closeBulkStopDialog}
            />

            {loading && (
                <Layout spacing="p-8">
                    <Loader active big message="Loading Connectors" />
                </Layout>
            )}

            {!loading && loadingError && (
                <PageError
                    title="Oops! There was an error when loading connectors."
                    error={loadingError}
                />
            )}

            {!loading && !loadingError && sources && (
                <>
                    <Card contentSpacing="p-0">
                        <Layout flex flexDirection="column">
                            <SourcesListHeader
                                allSelected={allSelected}
                                toggleSelectAll={toggleSelectAll}
                                disableSelectAll={userHasNoSources}
                            />
                            <Divider spacing="m-0" />

                            {userHasNoSources && (
                                <Typography variant="subtitle1" spacing="py-6" align="center">
                                    There are no Connectors in your warehouse.
                                    Add a Connector above.
                                    <Typography
                                        inline
                                        component="span"
                                        variant="subtitle1"
                                        weight="medium"
                                    >
                                        &nbsp;{search}
                                    </Typography>
                                </Typography>
                            )}

                            {sources.length === 0 && (search || filter) && (
                                <Typography
                                    component="div"
                                    variant="subtitle1"
                                    spacing="py-6"
                                    align="center"
                                >
                                    No Connectors match {search && (
                                        <>
                                            the search term: <Term>{search}</Term>
                                        </>
                                    )} {filter && (
                                        <>
                                            {search && 'and'} the <Term>{filter}</Term> filter
                                        </>
                                    )}
                                    <Link
                                        color="primary"
                                        spacing="my-4"
                                        onClick={clearAllFilters}
                                    >
                                        Clear&nbsp;
                                        {search && `Search${filter && ' and '}`}
                                        {filter && 'Filters'}
                                    </Link>
                                </Typography>
                            )}

                            {sources.length > 0 && sources.map((source) => {
                                const sourceType = getMatchingSourceType(source);
                                if (!sourceType) {
                                    return null;
                                }
                                return (
                                    <SourceListItem
                                        key={source.id}
                                        source={source}
                                        sourceType={sourceType}
                                        updateSourceJob={updateSourceJob}
                                        updateSourceTitle={updateSourceTitle}
                                        onSourceDeleted={onSourceDeleted}
                                        isSelected={isObjectSelected}
                                        toggleSelected={toggleSelectedObject}
                                        actionsDisabled={selectedObjects.length > 0}
                                    />
                                );
                            })}
                        </Layout>
                    </Card>

                    <Layout flex justifyContent="flex-end" alignItems="center" spacing="mt-3">
                        <Typography color="secondaryText" variant="body2" spacing="mb-0">
                            Page {page + 1}
                        </Typography>
                        <Button disabled={!canPrevPage} onClick={prevPage} spacing="mt-0 mr-2 ml-4">
                            Prev
                        </Button>
                        <Button disabled={!canNextPage} onClick={nextPage} spacing="m-0">
                            Next
                        </Button>
                    </Layout>
                </>
            )}

        </PageLayout>
    );
};

// for default placeholder color
const StyledSpan = styled.span`
    color: ${colors.interface};
`;

/** @type {React.FC<{ children: React.ReactNode }>} */
const Term = ({ children }) => (
    <Typography
        inline
        component="span"
        variant="subtitle1"
        weight="medium"
    >
        {children}
    </Typography>
);
/**
 * @typedef { object } SourcesListHeaderProps
 * @prop { boolean } allSelected
 * @prop { boolean= } disableSelectAll
 * @prop { () => void } toggleSelectAll
 */
/** @type { React.FC<SourcesListHeaderProps> } */
const SourcesListHeader = ({
    allSelected,
    toggleSelectAll,
    disableSelectAll,
}) => {
    const onChange = () => {
        const newAllSelected = !allSelected;
        trackAllConnectorsSelection(String(newAllSelected));
        toggleSelectAll();
    };

    return (
        <Layout flex alignItems="center" justifyContent="space-between" spacing="px-5 py-5">
            <Layout flex alignItems="center" width="45" spacing="mr-2">
                <Switch
                    type="checkbox"
                    checked={allSelected}
                    label={''}
                    onChange={onChange}
                    disabled={disableSelectAll}
                    spacing="mr-2 -ml-1 px-1"
                />
                <Typography color="secondaryText">
                    Name
                </Typography>
            </Layout>
            <Layout width="15">
                <Typography color="secondaryText">
                    Schedule
                </Typography>
            </Layout>
            <Layout width="15">
                <Typography color="secondaryText">
                    Last Ran
                </Typography>
            </Layout>
            <Layout width="15">
                <Typography color="secondaryText">
                    Status
                </Typography>
            </Layout>

            <Layout width="10">
                {/* Empty placeholder column for ... button */}
            </Layout>
        </Layout>
    );
};

export default SourceListPage;
