import React, { useEffect } from 'react';
import { Layout } from 'ui-components';
import FormRow from '../form-row';
import FormPanel from '../form-panel';
import { SourceParam } from './form';
import { isGroupParam } from '../../../models/sources';

/** @typedef { import('models/sources').Source } Source */
/** @typedef { import('models/sources').SourceType } SourceType */
/** @typedef { import('models/sources').Param } Param */
/** @typedef { import('models/sources').FlattenedParam } FlattenedParam */
/** @typedef { import('models/sources').DynamicParamArgs } DynamicParamArgs */
/** @typedef { import('models/sources').DynamicParamResponse } DynamicParamResponse */
/** @typedef { import('models/sources').ValidationParamResponse } ValidationParamResponse */
/** @typedef { import('models/sources/__types').GroupParam } GroupParam */
/** @typedef { import('models/sources/__types').ListParamValue } ListParamValue */
/** @typedef { import('models/sources/__types').FieldValidationErrors } FieldValidationErrors */
/** @typedef { import('models/schemas/__types').Schema } Schema */

/**
 * @typedef { object } Props
 * @prop { Source } source
 * @prop { SourceType } sourceType
 * @prop { (name: Param['name']) => any } getParamValue
 * @prop { (name: Param['name'], value: any) => void } setParamValue
 * @prop { (name: Param['name']) => any } getParamState
 * @prop { (name: Param['name'], newState: any) => void } setParamState
 * @prop { (name: Param['name']) => boolean } areParamDepsMet
 * @prop { (param: Param) => boolean } isParamRequired
 * @prop { (
 *  name: Param['name'],
 *  args: DynamicParamArgs
 * ) => Promise<DynamicParamResponse['data']>
 * } loadDynamicParam
 * @prop { (name: Param['name']) => Promise<ValidationParamResponse>} loadValidationParam
 * @prop { FieldValidationErrors } fieldValidationErrors
 * @prop { () => Promise<Schema[]>} loadSchemas
 * @prop { GroupParam } param
 * @prop { FlattenedParam[] } flattenedParams
 */

/** @type { React.FC<Props> } */
const GroupParam = (
    props,
) => {

    const {
        param,
        flattenedParams,
        setParamValue,
        getParamValue,
        areParamDepsMet,
        isParamRequired,
    } = props;

    const visibleParams = param.params.filter(param => {
        return !param.hidden && areParamDepsMet(param.name);
    });

    useEffect(() => {
        const disallowedNestedTypes = ['ssl', 'ssh'];
        const badParam = param.params.find(p => disallowedNestedTypes.includes(p.type));
        if (badParam) {
            throw new Error('ssl and ssh params are not allowed to be nested in groups');
        }
    });

    return (
        <Layout spacing="mt-5">
            <FormPanel
                label={param.title}
                tooltipContent={param.help}
                headerContent=""
            >
                {visibleParams.map(nestedParam => {
                    if (isGroupParam(nestedParam)) {
                        return (
                            <SourceParam
                                {...props}
                                key={nestedParam.name}
                                param={nestedParam}
                                category={param.category}
                                getParamValue={getParamValue}
                                setParamValue={setParamValue}
                                flattenedParams={flattenedParams}
                            />
                        );
                    }
                    return (
                        <FormRow
                            key={nestedParam.name}
                            label={nestedParam.title}
                            tooltipContent={nestedParam.help}
                            noMinHeight={!nestedParam.title}
                            required={isParamRequired(nestedParam)}
                            showOptionalLabel={param.category === 'general'}
                        >
                            <SourceParam
                                {...props}
                                param={nestedParam}
                                category={param.category}
                                getParamValue={getParamValue}
                                setParamValue={setParamValue}
                                flattenedParams={flattenedParams}
                            />
                        </FormRow>

                    );
                })}
            </FormPanel>
        </Layout>
    );
};

export default GroupParam;
