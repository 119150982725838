import { useEffect, useRef, useCallback, useState } from 'react';
import { useRequest } from '../../lib/ajax';
import { useSession } from '../../shared/context-providers';

/** @typedef { import('../../app/__types').Session } Session */
/** @typedef { import('./__types').UseInsightsPageData } UseInsightsPageData */

/** @type { () => UseInsightsPageData } */
function useInsightsPage() {
    const [session] = useSession();
    const { database } = session;

    const [databaseDetailsOpen, setDatabaseDetailsOpen] = useState(false);

    // Fix for (deprecated) useRequest having loading: false initially
    // To prevent layout flashing
    const startedLoadingRef = useRef(false);

    const {
        go: fetchBilling,
        ...billingReq
    } = useRequest(`/billing/${database.id}`);

    const toggleDatabaseDetailsModal = useCallback(() => {
        setDatabaseDetailsOpen((currentValue) => !currentValue);
    }, []);

    useEffect(() => {
        if (!database.id) {
            return;
        }
        fetchBilling();
    }, [database, fetchBilling]);

    useEffect(() => {
        startedLoadingRef.current = true;
    }, []);

    const hasntStartedLoadingYet = startedLoadingRef.current === false;

    const loadingMetrics = hasntStartedLoadingYet;

    const loadingOnboarding = billingReq.loading
        || hasntStartedLoadingYet;


    return {
        loadingOnboarding,
        loadingMetrics,
        databaseDetailsOpen,
        toggleDatabaseDetailsModal,
    };
}

export default useInsightsPage;
