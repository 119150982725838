import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { colors } from 'ui-components';

const DashboardIcon = styled(({ className }) => (
    <span className={className}>
        <span></span>
        <span></span>
        <span></span>
    </span>
))`
    display: inline-block;
    position: relative;
    width: 1.75em;
    height: 1.75em;
    vertical-align: top;
    border-radius: 0.25em;
    border: 0.0625em solid ${colors.interface};

    & > span {
        display: block;
        position: absolute;
        width: 0.625em;
        height: 0.5em;
        top: 0.375em;
        left: 0.25em;
        border-radius: 0.125em;
        background: ${colors.primary.main};

        ::before {
            content: '';
            display: block;
            position: absolute;
            width: 0.375em;
            height: 0.25em;
            top: 0.125em;
            left: 0.125em;
            border-radius: 0.0625em;
            background-color: ${lighten(0.2, colors.primary.main)};
        }
    }

    & > span:nth-child(2) {
        width: 0.375em;
        height: 0.75em;
        left: 1em;
        background: ${colors.notice.main};

        ::before {
            width: 0.125em;
            height: 0.5em;
            background-color: ${lighten(0.2, colors.notice.main)};
        }
    }

    & > span:nth-child(3) {
        width: 0.5em;
        height: 0.375em;
        left: 0.375em;
        top: 1em;
        background: ${colors.secondary.main};

        ::before {
            width: 0.25em;
            height: 0.125em;
            background-color: ${lighten(0.2, colors.secondary.main)};
        }
    }
`;

export default DashboardIcon;
