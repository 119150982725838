import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
    Button,
} from 'ui-components';
import { getDaysRemaining, getTrialMessageByDays } from '../../lib/get-trial-message';

/** @typedef { import('../../app/__types').Session } Session */

/**
 * @typedef { object } Props
 * @prop { Session['database'] } database
 * @prop { Session['billing'] } billing
 * @prop { string } [spacing]
 */

/** @type { React.FC<Props> } */
const TrialMessage = ({ database, billing, spacing }) => {
    const daysRemaining = useMemo(() => getDaysRemaining(database, billing), [database, billing]);

    const upgradeButtonColor = daysRemaining >= 16
        ? 'secondary'
        : daysRemaining >= 8 ? 'warning' : 'error';


    const upgradeButtonType = daysRemaining > 0
        ? 'outline'
        : undefined; // empty string is an invalid value, use `undefined` instead

    return (
        <>
            <VisibleOnMediumScreen>
                <Button
                    color={upgradeButtonColor}
                    type={upgradeButtonType}
                    link="/billing"
                    spacing={`m-0 p-1 px-3 ${spacing || ''}`}
                >
                    {getTrialMessageByDays(daysRemaining)}
                </Button>
            </VisibleOnMediumScreen>
        </>
    );
};


const VisibleOnMediumScreen = styled('span')`
    display: none;

    @media (min-width: 408px) and (max-width: 814px) {
        display: inline-block;
    }

    @media (min-width: 1240px) {
        display: inline-block;
    }
`;

export default TrialMessage;
