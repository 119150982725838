import React from 'react';
import { isNumber, ceil } from 'lodash';

import {
    Layout,
    Typography,
    Button,
    Icon,
} from 'ui-components';

/** @typedef { import('models/sources/__types').ListParamTotalItems } TotalItems */

/**
 * @typedef { object } Props
 * @prop { number } page
 * @prop { number } pageSize
 * @prop { TotalItems= } totalItems
 * @prop { boolean= } nextDisabled
 * @prop { boolean= } [loading=false]
 * @prop { boolean= } [compact=false]
 * @prop { (newPage: number) => void } updatePage
 * @prop { 'plain' | 'solid' } [type]
 * @prop { string } [spacing]
 */

/** @type { React.FC<Props> } */
const Pagination = ({
    page,
    pageSize,
    totalItems,
    nextDisabled,
    loading,
    compact,
    updatePage,
    type = 'solid',
    ...props
}) => {
    const prevButtonProps = {
        onClick: () => updatePage(page - 1),
        disabled: isPrevDisabled({ page, loading }),
        type,
        square: !!compact,
        spacing: `mt-0 mr-2 ml-4 ${compact ? 'p-2' : ''}`,
    };

    const nextButtonProps = {
        onClick: () => updatePage(page + 1),
        disabled: isNextDisabled({
            page,
            pageSize,
            totalItems,
            nextDisabled,
        }),
        loading,
        type,
        square: !!compact,
        spacing: `m-0 ${compact ? 'p-2' : ''}`,
    };

    const totalPages = getTotalPages({ pageSize, totalItems });

    return (
        <Layout flex justifyContent="flex-end" alignItems="center" spacing="mt-3" {...props}>
            <Typography color="secondaryText" variant="body2" spacing="mb-0" noWrap>
                Page {page + 1} {totalPages > 0 && `of ${totalPages}`}
            </Typography>

            <Button {...prevButtonProps}>
                {compact ? <Icon icon="angle-left" /> : 'Prev'}
            </Button>

            <Button {...nextButtonProps}>
                {compact ? <Icon icon="angle-right" /> : 'Next'}
            </Button>
        </Layout>
    );
};

/**
 * @typedef { object } GetTotalPagesParams
 * @prop { number } pageSize
 * @prop { TotalItems } [totalItems]
 */

/** @type { (params: GetTotalPagesParams) => number } */
export function getTotalPages({ pageSize, totalItems }) {
    if (isNumber(totalItems)) {
        return ceil(totalItems / pageSize);
    }

    return 0;
}

/**
 * @typedef { object } IsPrevDisabledParams
 * @prop { number } page
 * @prop { boolean } [loading]
 */

/** @type { (params: IsPrevDisabledParams) => boolean } */
export function isPrevDisabled({ page, loading }) {
    return loading || page <= 0;
}

/**
 * @typedef { object } IsNextDisabledParams
 * @prop { number } page
 * @prop { number } pageSize
 * @prop { TotalItems } [totalItems]
 * @prop { boolean } [nextDisabled]
 */

/** @type { (params: IsNextDisabledParams) => boolean } */
export function isNextDisabled({ page, pageSize, totalItems, nextDisabled }) {
    if (nextDisabled) {
        return true;
    }

    if (isNumber(totalItems)) {
        return totalItems <= (page + 1) * pageSize;
    }

    return false;
}

export default Pagination;
