import React, {
    useRef,
    useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionCallout } from 'ui-components';
import { useSession } from '../../../shared/context-providers';
import SuccessIconPath from '../../../images/icon-success.svg';

/** @typedef { import('models/sources/__types').SourceType } SourceType */
/** @typedef { import('models/sources/__types').Source } Source */


/**
 * @typedef { object } Props
 * @prop { SourceType } sourceType
 * @prop { Source } source
 */

const LINK_URL = '/explore';

/** @type { React.FC<Props> } */
const PostCollectBanner = React.memo(({
    sourceType,
    source,
}) => {

    const [session] = useSession();
    const didLogImpression = useRef(false);
    const navigate = useNavigate();
    const onTrial = session.billing && session.billing.onTrial;
    const lastCollectWasSuccess = source.job?.status === 'success';

    const goToTables = useCallback(() => {
        navigate(LINK_URL);
    }, [sourceType.title]);

    const logClose = useCallback(() => {
        didLogImpression.current = true;
    }, [sourceType.title]);

    const shouldShowBanner = lastCollectWasSuccess
        && onTrial;

    return (
        <ActionCallout
            active={shouldShowBanner}
            label="Woo-hoo! Your data collected successfully."
            linkText="Take me to my data"
            iconUrl={SuccessIconPath}
            bannerId="panoply-collect-closed"
            onClick={goToTables}
            spacing="my-3"
            onClose={logClose}
        />
    );
});

export default PostCollectBanner;
