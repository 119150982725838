import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';

import { getStatus, statuses } from '../../../models/jobs/status';
import { routesMap } from './routes';
import NavLink from './nav-link';
import { NavListItem } from './source-side-navigation';

const { history } = routesMap;
/** @typedef {import('models/sources/__types').Source} Source */
/** @typedef {import('models/sources/__types').CollectJob} CollectJob */

/** @param {CollectJob} [job] */
const wasHistoryCollected = job => !(!job || !Object.keys(job).length);

/** @type {React.FC<{to: string, isCurrentRoute: boolean, source?: Source}>} */
const HistoryNavLink = ({ to, isCurrentRoute, source }) => {
    const collected = wasHistoryCollected(source?.job);

    const guardHistoryRoute = useCallback(e => {
        if (!collected) {
            e.preventDefault();
        }
    }, [collected]);

    const lastCollect = source?.job?.starttime
        ? moment(source.job.starttime).fromNow()
        : null;

    const [jobStatus, setJobStatus] = useState('');
    useEffect(() => {
        if (!source?.job || !Object.keys(source?.job).length) {
            setJobStatus('');
        } else {
            setJobStatus(getStatus(source.job) || '');
        }
    }, [source]);

    const [historyCaption, setHistoryCaption] = useState('');
    useEffect(() => {
        switch (jobStatus) {
            case statuses.Running:
            case statuses.Pending:
            case statuses.Terminating:
                setHistoryCaption(jobStatus);
                break;
            default:
                setHistoryCaption(`Last Run: ${collected ? lastCollect : 'Never'}`);
        }
    }, [jobStatus, collected, lastCollect]);

    const [historyIconColor, setHistoryIconColor] = useState('secondaryText');
    useEffect(() => {
        switch (jobStatus) {
            case statuses.Failed:
                setHistoryIconColor('error');
                break;
            case statuses.Running:
            case statuses.Success:
                setHistoryIconColor('secondary');
                break;
            default:
                setHistoryIconColor('secondaryText');
        }
    }, [jobStatus, collected]);

    const [historyIconName, setHistoryIconName] = useState('circle-notch');
    useEffect(() => {
        switch (jobStatus) {
            case statuses.Failed:
                setHistoryIconName('exclamation-circle');
                break;
            case statuses.Canceled:
                setHistoryIconName('times-circle');
                break;
            case statuses.Success:
                setHistoryIconName('check-circle');
                break;
            case statuses.Pending:
            case statuses.Running:
            case statuses.Terminating:
                setHistoryIconName('circle');
                break;
            default:
                setHistoryIconName('circle-notch');
        }
    }, [jobStatus, collected]);

    return (
        <NavLink
            to={to}
            onClick={guardHistoryRoute}
            disabled={!collected}
        >
            <NavListItem
                label={history.label}
                button
                color={isCurrentRoute ? 'primary' : undefined}
                selected={isCurrentRoute}
                disabled={!collected}
                prefix={historyIconName === 'circle' ? 'fas' : undefined}
                iconName={historyIconName}
                iconColor={historyIconColor}
                caption={historyCaption}
            />
        </NavLink>
    );
};

export default HistoryNavLink;
