import React from 'react';
import styled from 'styled-components';

import {
    Typography,
    Button,
    Icon,
} from 'ui-components';

import {
    getTotalPages,
    isNextDisabled,
    isPrevDisabled,
} from '../pagination';

/** @typedef { import('models/queries/__types').QueryResult } QueryResult */

/**
 * @typedef { object } Props
 *
 * @prop { number } page
 * @prop { number } pageSize
 * @prop { boolean } pageLoading
 * @prop { QueryResult } [queryResultPage]
 * @prop { QueryResult } [activeResult]
 * @prop { (newPage: number) => void } updatePage
 */

/** @type { React.FC<Props> } */
const WorkbenchPagination = ({
    page,
    pageSize,
    pageLoading,
    queryResultPage,
    activeResult,
    updatePage,
}) => {
    const rowCount = queryResultPage?.data.rows?.length || 0;
    const rowIndex = page * pageSize;

    const totalItems = queryResultPage?.data?.count || 0;
    const totalPages = getTotalPages({ pageSize, totalItems });
    const nextDisabled = queryResultPage?.data.pageSize !== pageSize || !activeResult?.id;
    const loading = pageLoading && queryResultPage?.data.pageSize === pageSize;

    const buttonProps = {
        type: /** @type { 'plain' } */ ('plain'),
        square: true,
        spacing: 'm-0 p-1',
    };

    const prevButtonProps = {
        onClick: () => updatePage(page - 1),
        disabled: isPrevDisabled({ page, loading }),
    };

    const nextButtonProps = {
        onClick: () => updatePage(page + 1),
        disabled: isNextDisabled({
            page,
            pageSize,
            totalItems,
            nextDisabled,
        }),
        loading,
    };

    return (
        <>
            {rowCount > 0 && (
                <Caption color="interface">
                    Rows {1 + rowIndex}
                    {' - '}
                    {rowCount + rowIndex}
                </Caption>
            )}

            <Button {...buttonProps} {...prevButtonProps}>
                <Icon icon="angle-left" />
            </Button>

            <Caption>
                Page {page + 1} {totalPages > 0 && `of ${totalPages}`}
            </Caption>

            <Button {...buttonProps} {...nextButtonProps}>
                <Icon icon="angle-right" />
            </Button>
        </>
    );
};

const Caption = styled(({ children, ...props }) => (
    <Typography
        inline
        component="span"
        color="secondaryText"
        spacing="m-0 mx-1"
        noWrap
        {...props}
    >
        {children}
    </Typography>
))`
    && {
        line-height: 1.5;
    }
`;

export default WorkbenchPagination;
