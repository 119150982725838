import { useState, useCallback, useEffect } from 'react';
import { cloneDeep } from 'lodash';

import { useSession } from '../context-providers';
import * as QueryBuilder from '../../lib/query-builder';
import { reportError } from '../../lib/error-reporter';
import useDebounce from '../use-debounce';

/** @typedef { import('lib/query-builder').Config } Config */
/** @typedef { import('lib/query-builder').Column } Column */
/** @typedef { import('lib/query-builder').Position } Position */
/** @typedef { import('lib/query-builder').Filter } Filter */
/** @typedef { import('lib/query-builder').Order } Order */
/** @typedef { import('lib/query-builder').Join } Join */
/** @typedef { import('lib/query-builder').JoinTable } JoinTable */
/** @typedef { (config: Config) => Config } Callback */

/** @typedef { ReturnType<useWorkbenchQueryBuilder> } WorkbenchQueryBuilder */

function useWorkbenchQueryBuilder() {
    const [session] = useSession();
    const { database, user } = session;
    const dbType = database?.type || 'redshift';
    const { username } = user;

    const [history, setHistory] = useState(() => {
        try {
            const item = localStorage.getItem('query-builder-history');

            if (item) {
                const parsed = JSON.parse(item);

                if (parsed) {
                    const { username: storedUsername, history = [] } = parsed;

                    if (storedUsername === username
                        && Array.isArray(history)
                        && history.length > 0
                        && history.every(QueryBuilder.isValidConfig)
                    ) {
                        return history.map(config => (
                            QueryBuilder.changeDbType(config, dbType)
                        ));
                    }
                }
            }
        } catch (/** @type { any } */ err) {
            reportError(err);
        }

        return [QueryBuilder.createConfig(dbType)];
    });

    const [cursor, setCursor] = useState(() => {
        try {
            const item = localStorage.getItem('query-builder-cursor');

            if (item) {
                const parsed = JSON.parse(item);

                if (parsed) {
                    const { username: storedUsername, cursor } = parsed;

                    if (storedUsername === username
                        && typeof cursor === 'number'
                        && history[cursor] != null
                    ) {
                        return cursor;
                    }
                }
            }
        } catch (/** @type { any } */ err) {
            reportError(err);
        }

        return 0;
    });

    const hasUndoChange = cursor < history.length - 1;
    const hasRedoChange = cursor > 0;

    const config = history[cursor];

    const {
        hasColumns,
        allColumns,
        allTables,
        pending,
        hideHidden,
        someHidden,
        queryFilter,
        queryOrder,
        queryLimit,
    } = QueryBuilder.getParams(config);

    const pendingColumn = pending?.column;

    const builtQuery = useDebounce(
        QueryBuilder.buildQuery(config),
        history.length > 2 ? 300 : 0
    );

    /** @type { (step: number) => void } */
    const moveCursor = useCallback((step) => {
        setCursor(cursor => {
            const newCursor = cursor + step;

            try {
                localStorage.setItem('query-builder-cursor', JSON.stringify({
                    username,
                    cursor: newCursor,
                }));
            } catch (/** @type { any } */ err) {
                reportError(err);
            }

            return newCursor;
        });
        setHistory(history => [...history]);
    }, [username]);

    const undoChange = useCallback(() => {
        moveCursor(1);
    }, [moveCursor]);

    const redoChange = useCallback(() => {
        moveCursor(-1);
    }, [moveCursor]);

    /** @type { (...callbacks: Callback[]) => void } */
    const changeConfig = useCallback((...callbacks) => {
        setCursor(cursor => {
            setHistory(history => {
                const newHistory = [
                    callbacks.reduce(
                        (config, fn) => fn(config),
                        cloneDeep(history[cursor])
                    ),
                    ...history.slice(cursor, cursor + 100),
                ];

                try {
                    localStorage.setItem('query-builder-history', JSON.stringify({
                        username,
                        history: newHistory,
                    }));
                } catch (/** @type { any } */ err) {
                    reportError(err);
                }

                return newHistory;
            });

            try {
                localStorage.setItem('query-builder-cursor', JSON.stringify({
                    username,
                    cursor: 0,
                }));
            } catch (/** @type { any } */ err) {
                reportError(err);
            }

            return 0;
        });
    }, [username]);

    const clearConfig = useCallback(() => {
        changeConfig(() => QueryBuilder.createConfig(dbType));
    }, [dbType]);

    /** @type { (column: Omit<Column, 'id'>, position?: Position) => void } */
    const addColumn = useCallback((column, position) => {
        const table = {
            schema: column.schema,
            name: column.table,
        };

        changeConfig(config => {
            if (!QueryBuilder.hasTables(config)) {
                QueryBuilder.changeMainTable(config, table);
            }

            if (!QueryBuilder.hasTable(config, table)) {
                return QueryBuilder.changePending(config, column, position);
            }

            return QueryBuilder.addColumn(config, column, position);
        });
    }, [changeConfig]);

    /** @type { (position: Position) => void } */
    const removeColumn = useCallback((position) => {
        changeConfig(config => {
            QueryBuilder.removeColumn(config, position);

            const { where, having } = QueryBuilder.getFilter(config);
            QueryBuilder.changeFilter(config, where, having);

            const orderBy = QueryBuilder.getOrder(config);
            return QueryBuilder.changeOrder(config, orderBy);
        });
    }, [changeConfig]);

    /** @type { (from: Position, to: Position) => void } */
    const moveColumn = useCallback((from, to) => {
        changeConfig(config => (
            QueryBuilder.moveColumn(config, from, to)
        ));
    }, [changeConfig]);

    /** @type { (position: Position) => void } */
    const toggleColumn = useCallback((position) => {
        changeConfig(config => (
            QueryBuilder.toggleColumn(config, position)
        ));
    }, [changeConfig]);

    /** @type { (params: Omit<JoinTable, 'alias'>) => void } */
    const addJoinTable = useCallback((params) => {
        changeConfig(config => {
            QueryBuilder.addJoinTable(config, params);

            const pending = QueryBuilder.getPending(config);

            if (pending) {
                const { column, position } = pending;
                QueryBuilder.addColumn(config, column, position);
            }

            return QueryBuilder.clearPending(config);
        });
    }, [changeConfig]);

    /** @type { (position: number, join: Join) => void } */
    const changeJoinTable = useCallback((position, join) => {
        changeConfig(config => (
            QueryBuilder.changeJoinTable(config, position, join)
        ));
    }, [changeConfig]);

    /** @type { (position: number) => void } */
    const removeJoinTable = useCallback((position) => {
        changeConfig(config => (
            QueryBuilder.removeJoinTable(config, position)
        ));
    }, [changeConfig]);

    /** @type { (position: Position, alias: NonNullable<Column['alias']>) => void } */
    const changeAlias = useCallback((position, alias) => {
        changeConfig(config => (
            QueryBuilder.changeAlias(config, position, alias)
        ));
    }, [changeConfig]);

    /** @type { (position: Position, newFunction: Column['function']) => void } */
    const changeFunction = useCallback((position, newFunction) => {
        changeConfig(config => (
            QueryBuilder.changeFunction(config, position, newFunction)
        ));
    }, [changeConfig]);

    /** @type { (limit?: number) => void } */
    const changeLimit = useCallback((limit) => {
        changeConfig(config => QueryBuilder.changeLimit(config, limit));
    }, [changeConfig]);

    /** @type { (where: Filter[], having: Filter[]) => void } */
    const changeFilter = useCallback((where, having) => {
        changeConfig(config => QueryBuilder.changeFilter(config, where, having));
    }, [changeConfig]);

    /** @type { (orderBy: Order[]) => void } */
    const changeOrder = useCallback((orderBy) => {
        changeConfig(config => QueryBuilder.changeOrder(config, orderBy));
    }, [changeConfig]);

    const clearColumns = useCallback(() => {
        changeConfig(config => QueryBuilder.clearColumns(config));
    }, [changeConfig]);

    const toggleHidden = useCallback(() => {
        changeConfig(config => QueryBuilder.toggleHidden(config));
    }, [changeConfig]);

    useEffect(() => {
        setHistory(history => (
            history.map(config => (
                QueryBuilder.changeDbType(config, dbType)
            ))
        ));
    }, [dbType]);

    return {
        addColumn,
        removeColumn,
        moveColumn,
        toggleColumn,
        changeAlias,
        changeFunction,
        clearColumns,
        clearConfig,
        toggleHidden,
        addJoinTable,
        changeJoinTable,
        removeJoinTable,
        changeLimit,
        changeFilter,
        changeOrder,
        undoChange,
        redoChange,
        hasColumns,
        allColumns,
        allTables,
        pendingColumn,
        hideHidden,
        someHidden,
        builtQuery,
        queryFilter,
        queryOrder,
        queryLimit,
        hasUndoChange,
        hasRedoChange,
    };
}

export default useWorkbenchQueryBuilder;
