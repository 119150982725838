import React, { useState } from 'react';
import {
    Dropdown,
    ToggleButton,
    List,
    ListItem,
    Icon,
} from 'ui-components';
import WorkbenchModeDialog from './mode-selector-warning-dialog';

/** @typedef { import('./__types').Mode } Mode */

/** @type { Record<Mode, { label: string, warning: string }> } */
export const modes = {
    'sql': {
        label: 'SQL',
        warning: 'Once you switch to SQL mode, you will not be able to return to the Query Builder',
    },
    'query-builder': {
        label: 'Query Builder',
        warning: 'Switching to Query Builder will clear your current query and will reload the '
            + 'latest query used in the Query Builder',
    },
};

/**
 * @typedef { object } ModeSelectorDropdownProps
 * @prop { Mode } mode
 * @prop { (newMode: Mode) => void } onModeChanged
 * @prop { string } [spacing]
 */

/** @type { React.FC<ModeSelectorDropdownProps> } */
const ModeSelectorDropdown = ({
    mode,
    onModeChanged,
    spacing,
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedMode, setSelectedMode] = useState(mode);

    const openDialog = ({ mode }) => {
        if (!mode) {
            return;
        }
        toggleDropdown();
        setSelectedMode(mode);
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };


    const toggleDropdown = () => {
        setDropdownOpen(open => !open);
    };

    /** @type { (newMode: Mode) => void } */
    const updateMode = (newMode) => {
        onModeChanged(newMode);
        setDropdownOpen(false);
    };

    return (
        <>
            <Dropdown
                opener={
                    <ToggleButton
                        active={dropdownOpen}
                        onClick={toggleDropdown}
                        spacing={spacing}
                    >
                        {modes[mode].label}
                        <Icon prefix="fas" icon="caret-down" spacing="ml-1" />
                    </ToggleButton>
                }
                open={dropdownOpen}
                onClose={toggleDropdown}
            >
                <List>
                    {Object.entries(modes).map(([key, { label }]) => (
                        <ListItem
                            key={key}
                            label={label}
                            selected={key === mode}
                            disabled={key === mode}
                            onClick={() => openDialog({ mode: key })}
                            button
                        />
                    ))}
                </List>
            </Dropdown>

            <WorkbenchModeDialog
                open={dialogOpen}
                onClose={closeDialog}
                onConfirm={() => updateMode((selectedMode))}
                newMode={modes[selectedMode]}
            />
        </>
    );
};

export default ModeSelectorDropdown;
