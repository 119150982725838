import React, { useEffect } from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';

import {
    Dialog,
    Divider,
    Button,
    Layout,
    Typography,
    Tooltip,
    Icon,
    colors,
} from 'ui-components';

import JoinIcon from './join-icon';
import useJoinTable from './use-join-table';

/** @typedef { import('lib/query-builder').Column } Column */
/** @typedef { import('lib/query-builder').MainTable } MainTable */
/** @typedef { import('lib/query-builder').JoinTable } JoinTable */
/** @typedef { import('lib/query-builder').Tables } Tables */
/** @typedef { import('lib/query-builder').Position } Position */
/** @typedef { import('./workbench-menu').JoinToEdit } JoinToEdit */

/**
 * @typedef { object } Props
 * @prop { boolean } open
 * @prop { () => void } onClose
 * @prop { Column[] } allColumns
 * @prop { Tables } allTables
 * @prop { (joinToEdit: JoinToEdit) => void } openEditJoinDialog
 * @prop { (position: number) => void } removeJoinTable
 */

/** @type { React.FC<Props> } */
const JoinDialog = ({
    open,
    onClose,
    allColumns,
    allTables,
    openEditJoinDialog,
    removeJoinTable,
}) => {
    useEffect(() => {
        if (!open) {
            return () => {};
        }

        /** @type { (e: KeyboardEvent) => void } */
        const onKeyDown = e => {
            const activeTag = document.activeElement?.tagName.toLowerCase();

            if (e.key === 'Escape') {
                e.preventDefault();
                onClose();
            } else if (e.key === 'Enter' && activeTag !== 'input') {
                e.preventDefault();
                onClose();
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    }, [open, onClose]);

    const [mainTable, ...joinTables] = allTables;

    if (!mainTable) {
        return null;
    }

    return (
        <Dialog
            compact
            title="JOIN Tables"
            isOpen={open}
            onClose={onClose}
            actions={[
                <Button
                    key="new"
                    type="plain"
                    spacing="ml-0"
                    leftIcon="plus"
                    onClick={() => openEditJoinDialog(
                        joinTables.length === 0 ? { mainTable } : {}
                    )}
                >
                    New JOIN
                </Button>,
                <Button
                    key="close"
                    color="primary"
                    spacing="mr-0"
                    onClick={onClose}
                >
                    Close
                </Button>,
            ]}
        >
            <Wrapper>
                <Divider spacing="-mx-4 mt-0 mb-5" />

                {joinTables.length === 0 && (
                    <Help align="center">
                        Add additional columns from the Workbench, or manually add a new JOIN
                    </Help>
                )}

                {joinTables.map((joinTable, position) => (
                    <React.Fragment key={position}>
                        {position > 0 && <Divider spacing="-mx-4 my-5" />}

                        <Join
                            allColumns={allColumns}
                            allTables={allTables}
                            joinTable={joinTable}
                            position={position}
                            removeJoinTable={removeJoinTable}
                            {...position === 0 ? {
                                mainTable,
                                onClick: () => openEditJoinDialog({
                                    mainTable,
                                    joinTable,
                                    position,
                                }),
                            } : {
                                onClick: () => openEditJoinDialog({
                                    joinTable,
                                    position,
                                }),
                            }}
                            spacing="-mx-4 -my-5 px-4 py-5"
                        />
                    </React.Fragment>
                ))}
            </Wrapper>
        </Dialog>
    );
};

/**
 * @typedef { object } JoinProps
 * @prop { Column[] } allColumns
 * @prop { Tables } allTables
 * @prop { JoinTable } joinTable
 * @prop { MainTable } [mainTable]
 * @prop { number } position
 * @prop { (position: number) => void } removeJoinTable
 * @prop { () => void } onClick
 * @prop { string } [spacing]
 */

/** @type { React.FC<JoinProps> } */
const Join = ({
    allColumns,
    allTables,
    joinTable,
    mainTable,
    position,
    removeJoinTable,
    ...props
}) => {
    const { noFields } = useJoinTable({ allColumns, allTables, joinTable, position });

    /** @type { React.MouseEventHandler<HTMLButtonElement> } */
    const onRemove = e => {
        e.preventDefault();
        e.stopPropagation();

        if (noFields) {
            removeJoinTable(position);
        }
    };

    const isLastJoin = position === allTables.length - 2;

    return (
        <JoinRow flex alignItems="center" {...props}>
            <Layout>
                <JoinIcon type={joinTable.join.type} />
            </Layout>

            <Layout grow="1" spacing="ml-4">
                <Typography variant="body1" color="text" spacing="mb-0">
                    {mainTable && (
                        <>
                            {mainTable.schema}.{mainTable.name}{' '}
                        </>
                    )}
                    <Typography
                        inline
                        variant="body1"
                        weight="medium"
                        spacing="mb-0"
                    >
                        {joinTable.join.type.toUpperCase()} JOIN
                    </Typography>

                    {' '}{joinTable.schema}.{joinTable.name}
                </Typography>

                {noFields && (
                    <Help>
                        No fields from
                        &quot;{joinTable.schema}.{joinTable.name}&quot;
                        were found in your query
                    </Help>
                )}
            </Layout>

            {noFields && (
                <Layout>
                    <Tooltip
                        className={`tooltip-wrap ${isLastJoin ? 'last-join' : ''}`}
                        content="Remove JOIN"
                        interactive={false}
                        placement="top"
                    >
                        <Button
                            square
                            type="plain"
                            spacing="m-0 ml-2 p-2"
                            onClick={onRemove}
                        >
                            <Icon icon="trash" />
                        </Button>
                    </Tooltip>
                </Layout>
            )}
        </JoinRow>
    );
};

/**
 * @typedef { object } HelpProps
 * @prop { React.ReactNode } children
 * @prop { string } [spacing]
 * @prop { string } [color]
 * @prop { 'center' } [align]
 */

/** @type { React.FC<HelpProps> } */
const Help = ({ children, ...props }) => (
    <Typography
        variant="body2"
        color="secondaryText"
        spacing="mb-0"
        {...props}
    >
        {children}
    </Typography>
);

const Wrapper = styled(Layout)`
    width: 90vw;
    max-width: 100%;
`;

const JoinRow = styled(Layout)`
    .tooltip-wrap:not(.last-join) {
        visibility: hidden;
    }

    &:hover {
        background-color: ${transparentize(0.95, colors.primary.main)};

        .tooltip-wrap {
            visibility: visible;
        }
    }
`;

export default JoinDialog;
