import React from 'react';
import { Link as ClickLink, Typography } from 'ui-components';

/** @type { React.FC } */
const ChangePasswordFailure = () => (
    <>
        <Typography variant="h5" weight="medium" spacing="mb-0">
            Oops! Something went wrong
        </Typography>

        <Typography spacing="my-5">
            It looks like you clicked on a password reset link
            that is no longer valid.
        </Typography>

        <Typography color="secondaryText">
            Please

            <ClickLink
                inline
                component="span"
                color="primary"
                spacing="mx-1"
                link="/users/reset"
            >
                try again
            </ClickLink>

            and we’ll send you a new link.
        </Typography>
    </>
);

export default ChangePasswordFailure;
