import React, { useState, useCallback, useEffect } from 'react';

import {
    Button,
    Dialog,
    Layout,
    Typography,
    Switch,
    Loader,
    Callout,
} from 'ui-components';

import { useSession } from '../context-providers';

import { reportError } from '../../lib/error-reporter';

/** @typedef { import('models/database-objects/__types').DatabaseObject } DatabaseObject */
/** @typedef { import('models/database-objects/__types').View } View */
/** @typedef { import('lib/ajax').AjaxResponse= } AjaxError */

/**
 * @typedef { object } Props
 * @prop { boolean } open
 * @prop { () => void } onClose
 * @prop { DatabaseObject= } objectToDelete
 * @prop { (object: DatabaseObject) => Promise<View[]> } fetchObjectDependencies
 * @prop { (object: DatabaseObject) => Promise<void> } deleteObject
 */

/** @type { React.FC<Props> } */
const DeleteObjectDialog = ({
    open, onClose, objectToDelete, deleteObject, fetchObjectDependencies,
}) => {

    const [session] = useSession();
    const [loading, setLoading] = useState(false);

    /** @type {[AjaxError, React.Dispatch<AjaxError>]} */
    const [error, setError] = useState();

    /** @type { [View[] | undefined, React.Dispatch<View[] | undefined>] } */
    const [dependencies, setDependencies] = useState();

    const [loadingDependencies, setLoadingDependencies] = useState(false);

    const [didConfirm, setDidConfirm] = useState(false);

    const onDeleteClicked = useCallback(async () => {
        if (!objectToDelete?.id) {
            return;
        }
        setLoading(true);
        try {
            await deleteObject(objectToDelete);
            onClose();
        } catch (/** @type { any } */ err) {
            reportError(err, { objectId: objectToDelete.id });
            setError(err);
        }
        setLoading(false);
    }, [objectToDelete, deleteObject, onClose]);

    /** @type { (e:  React.ChangeEvent<HTMLInputElement>) => void } */
    const onChangeConfirm = useCallback((e) => {
        setDidConfirm(e.target.checked);
    }, []);

    const handleDependencyChecks = useCallback(() => {

        if (session.database.type !== 'redshift') {
            return;
        }

        if (!objectToDelete) {
            return;
        }
        const loadDependencies = async () => {
            setLoadingDependencies(true);
            try {
                const deps = await fetchObjectDependencies(objectToDelete);

                // The deps, for some reason, contain the current object (in case of a view)
                const filteredDeps = deps.filter(dep => dep.id !== objectToDelete.id);

                setDependencies(filteredDeps);
            } catch (/** @type { any } */ err) {
                reportError(err, { objectId: objectToDelete.id });
                setError(err);
            }
            setLoadingDependencies(false);
        };

        const shouldLoadDeps = open
            && objectToDelete
            && objectToDelete.type !== 'folder'
            && !dependencies;

        if (shouldLoadDeps) {
            loadDependencies();
        }
    }, [open, objectToDelete, fetchObjectDependencies, session.database.type]);

    useEffect(handleDependencyChecks, [handleDependencyChecks]);

    useEffect(() => {
        if (open) {
            return;
        }
        setDependencies(undefined);
        setLoadingDependencies(false);
        setError(undefined);
        setLoading(false);
        setDidConfirm(false);
    }, [open]);

    const objectIsFolder = objectToDelete?.type === 'folder';

    const hasChildren = !!(objectIsFolder
        && (objectToDelete.items && objectToDelete.items.length));

    const disabled = (objectIsFolder)
        ? hasChildren
        : loadingDependencies || !didConfirm;

    useEffect(() => {
        /** @type { (e: KeyboardEvent) => void } */
        const onKeyDown = e => {
            if (e.key === 'Escape' && !loading) {
                e.preventDefault();
                if (open) {
                    onClose();
                }
            } else if (e.key === 'Enter' && !loading && !disabled) {
                e.preventDefault();
                if (open) {
                    onDeleteClicked();
                }
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    }, [loading, disabled, open, onClose, onDeleteClicked]);

    if (!objectToDelete?.id) {
        return null;
    }

    const deleteFolderObjectProps = {
        objectToDelete,
        hasChildren,
    };

    const deleteNonFolderObjectProps = {
        objectToDelete,
        loadingDependencies,
        dependencies,
        didConfirm,
        onChangeConfirm,
    };

    const actions = (
        <>
            <Button onClick={onClose} disabled={loading}>Cancel</Button>
            <Button
                color="primary"
                loading={loading}
                onClick={onDeleteClicked}
                disabled={disabled}
                spacing="mr-0"
            >
                Delete
            </Button>
        </>
    );

    return (
        <Dialog
            title={`Delete ${objectToDelete.type}`}
            isOpen={open}
            onClose={onClose}
            actions={actions}
        >

            {objectIsFolder && (
                <DeleteFolderObject {...deleteFolderObjectProps} />
            )}

            {!objectIsFolder && (
                <DeleteNonFolderObject {...deleteNonFolderObjectProps} />
            )}

            {error && (
                <Layout spacing="mt-4">
                    <Typography variant="subtitle1" color="error">
                        Oops! an error was encountered when deleting the item.
                    </Typography>
                    <Typography variant="body1" color="error">
                        {(error.data && error.data.message) || error.status}
                    </Typography>
                </Layout>
            )}
        </Dialog>
    );
};


/**
 * @typedef { object } DeleteFolderObjectProps
 * @prop { boolean } hasChildren
 * @prop { DatabaseObject } objectToDelete
 */

/** @type { React.FC<DeleteFolderObjectProps> } */
const DeleteFolderObject = ({ hasChildren, objectToDelete }) => (
    hasChildren ? (
        <Layout spacing="mt-0">
            <Typography variant="subtitle1">
                Only empty folders can be deleted.
            </Typography>
            <Typography variant="body1">
                Please remove all the folder contents before deleting
            </Typography>
        </Layout>
    ) : (
        <Layout spacing="mt-0">
            <Typography variant="subtitle1" weight="medium">
                Are you sure you want to delete &quot;{objectToDelete.name}&quot;?
            </Typography>
            <Typography variant="body1">
                This action will permanently remove the following folder:
                {objectToDelete.name}
            </Typography>
        </Layout>
    )
);


/**
 * @typedef { object } DeleteNonFolderObjectProps
 * @prop { DatabaseObject } objectToDelete
 * @prop { boolean } loadingDependencies
 * @prop { View[]= } dependencies
 * @prop { boolean } didConfirm
 * @prop { (e: React.ChangeEvent) => void } onChangeConfirm
 */

/** @type { React.FC<DeleteNonFolderObjectProps> } */
const DeleteNonFolderObject = ({
    objectToDelete,
    loadingDependencies,
    dependencies,
    didConfirm,
    onChangeConfirm,
}) => (
    <Layout spacing="mt-0">
        <Typography variant="subtitle1" weight="medium">
            Are you sure you want to delete {objectToDelete.type} {objectToDelete.name}?
        </Typography>

        <Layout spacing="mt-0">

            {loadingDependencies && (
                <Layout spacing="mt-5">
                    <Loader
                        active
                        message={`Loading dependencies for ${objectToDelete.name}`}
                    />
                </Layout>
            )}

            {dependencies && !!dependencies.length && (
                <Callout color="warning" spacing="mt-3">
                    <Typography component="span" variant="subtitle1" color="text">
                        Deleting this {objectToDelete.type} will also remove the following views:
                    </Typography>
                    {dependencies.map(dep => (
                        <Typography
                            key={dep.id}
                            component="span"
                            color="text"
                            weight="medium"
                        >
                            {dep.name}
                        </Typography>
                    ))}
                </Callout>
            )}

            {!loadingDependencies && (
                <>
                    <Switch
                        type="checkbox"
                        checked={didConfirm}
                        label={
                            objectToDelete.type === 'table'
                                ? 'Yes, permanently remove this table and all its data'
                                : `Yes, permanently remove this ${objectToDelete.type}`
                        }
                        onChange={onChangeConfirm}
                        spacing="mt-3"
                    />
                    {didConfirm && (
                        <Typography color="error" variant="body1" spacing="mt-2">
                            Warning: This action cannot be undone.
                        </Typography>
                    )}
                </>
            )}

        </Layout>

    </Layout>
);

export default DeleteObjectDialog;
