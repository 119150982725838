import { get, post, put, del } from '../../lib/ajax';
import { addBreadcrumb } from '../../lib/error-reporter';

/** @typedef { import('./types').Report } Report */
/** @typedef { import('./types').SingleValueReport } SingleValueReport */
/** @typedef { import('./types').VAxisFormat } VAxisFormat */
/** @typedef { import('./types').CreateReport } CreateReport */
/** @typedef { import('./types').FetchReport } FetchReport */
/** @typedef { import('./types').FetchReports } FetchReports */
/** @typedef { import('./types').EditReport } EditReport */
/** @typedef { import('./types').RemoveReport } RemoveReport */

/** @type { CreateReport } */
export const createReport = async (params) => {
    const { title, type, config, query, theme } = params;

    try {
        const { data: report } = await post('/reports', {
            body: {
                title,
                type,
                config,
                query,
                theme,
            },
        });

        return report;

    } catch (error) {
        addBreadcrumb('Failed to create a report', { error });

        throw error;
    }
};

/** @type { FetchReport } */
export const fetchReport = async (id) => {
    try {
        const { data: report } = await get('/reports/' + encodeURIComponent(id));

        return report;

    } catch (error) {
        addBreadcrumb('Failed to get a report', { error });

        throw error;
    }
};

/** @type { FetchReports } */
export const fetchReports = async (params = {}) => {
    const { title } = params;

    try {
        const { data: reports } = await get('/reports', {
            query: {
                title,
            },
        });

        return reports;

    } catch (error) {
        addBreadcrumb('Failed to list reports', { error });

        throw error;
    }
};

/** @type { EditReport } */
export const editReport = async (id, params) => {
    const { title, type, config, query, theme } = params;

    try {
        const { data: report } = await put('/reports/' + encodeURIComponent(id), {
            body: {
                title,
                type,
                config,
                query,
                theme,
            },
        });

        return report;

    } catch (error) {
        addBreadcrumb('Failed to edit a report', { error });

        throw error;
    }
};

/** @type { RemoveReport } */
export const removeReport = async (id) => {
    try {
        await del('/reports/' + encodeURIComponent(id));

    } catch (error) {
        addBreadcrumb('Failed to remove a report', { error });

        throw error;
    }
};
