import { useEffect } from 'react';
import { useRequest } from '../../lib/ajax';

/** @typedef { import('./__types').PreviewHookProps } PreviewHookProps */
/** @typedef { import('./__types').PreviewReturnData } PreviewReturnData */

/**
 * @typedef { object } ReturnData
 * @prop { object[] } preview
 * @prop { boolean } loadingPreview
 */

/**
 * @param { PreviewHookProps } props
 * @returns { PreviewReturnData }
 */
function useTablePreview({ id, openToast }) {
    const previewReq = useRequest(`/tables/${id}/preview`);
    const dataIsReady = !previewReq.error && previewReq.data;

    useEffect(() => {
        if (!id) {
            return;
        }
        previewReq.go();
    }, [id]);

    useEffect(() => {
        if (!previewReq.error) {
            return;
        }
        openToast('Failed to load the preview data.');
    }, [previewReq.error]);

    return {
        preview: dataIsReady ? previewReq.data.rows : [],
        loadingPreview: previewReq.loading,
    };
}

export default useTablePreview;
