import React from 'react';

import PasswordLayout from '../../shared/auth/password-layout';

import ResetPasswordForm from './reset-password-form';
import ResetPasswordSuccess from './reset-password-success';
import useResetPasswordPage from './use-reset-password-page';

/** @typedef { import('../../app/__types').PageProps } PageProps */

/** @type { React.FC<PageProps> } */
const ResetPasswordPage = ({ email: initialEmail }) => {
    const {
        email,
        emailIsValid,
        loading,
        disabled,
        success,
        error,
        onEmailChanged,
        onResetPasswordClicked,
        onEnterPressed,
    } = useResetPasswordPage({ initialEmail });

    if (success) {
        return (
            <PasswordLayout title="Instructions sent!">
                <ResetPasswordSuccess email={email} />
            </PasswordLayout>
        );
    }

    const formProps = {
        email,
        emailIsValid,
        loading,
        disabled,
        error,
        onEmailChanged,
        onResetPasswordClicked,
        onEnterPressed,
    };

    return (
        <PasswordLayout title="Reset Password">
            <ResetPasswordForm {...formProps} />
        </PasswordLayout>
    );
};

export default ResetPasswordPage;
