import { useCallback, useMemo } from 'react';

/** @typedef { import('models/sources/__types').AutocompleteParamItem } ParamOption */

/**
 * @typedef { object } Option
 * @prop { any } value
 * @prop { boolean= } disabled
 * @prop { string= } category
 */

function useValidatedValues(
    /** @type { Option[] } */ value,
    /** @type { Option[] } */ options,
) {

    const isOptionSelected = useCallback((option, testValue) => {
        return JSON.stringify(option.value) === JSON.stringify(testValue.value);
    }, []);

    /**
     * Checks `values` against provided `options`
     * If the value is not present in options or if the matching option is `disabled`
     * Then adds `error: true` and `category: invalid`
     *
     * If the matching option is found but non-value properties are different
     * The properties on the `option` take precidence
     */
    const validatedValues = useMemo(() => {
        return value.map((selectedOption) => {
            const matchingOption = options.find((option) => (
                isOptionSelected(option, selectedOption)
            ));
            if (matchingOption) {
                return {
                    ...matchingOption,
                    error: matchingOption.disabled,
                    disabled: false,
                    category: matchingOption.disabled ? 'Invalid' : matchingOption.category,
                };
            }
            return {
                ...selectedOption,
                error: true,
                category: 'Invalid',
            };
        });
    }, [options, value]);

    const invalidValues = useMemo(() => {
        return validatedValues.filter(({ error }) => error);
    }, [validatedValues]);

    /**
    Adds an option for each invalid value so they can be deselected from the list.
    Once the invalid values are deselected they will disapear.
    */
    const validatedOptions = useMemo(() => {
        return [
            ...invalidValues,
            ...options,
        ];
    }, [validatedValues, options, value]);

    return {
        validatedOptions,
        validatedValues,
        invalidValues,
        isOptionSelected,
    };

}

export default useValidatedValues;
