import {
    get,
    put,
    post,
    del,
} from '../../lib/ajax';

import { updateTable } from './tables';
import { updateView } from './views';

/** @typedef { import('./__types').DatabaseObject } DatabaseObject */
/** @typedef { import('./__types').Table } Table */
/** @typedef { import('./__types').View } View */
/** @typedef { import('./__types').Folder } Folder */

/** @type { (folder: any) => Folder } */
const normalizeFolder = folder => ({
    ...folder,
    type: 'folder',
    items: [],
    metadata: {
        ...folder.metadata,
        rows: 0,
        size: 0,
    },
});

/** @type { (folders: any[]) => Folder[] } */
const normalizeFolders = folders => folders.map(normalizeFolder);

/** @type { () => Promise<Folder[]> } */
export const fetchFolders = async () => {
    const { data: folders } = await get('/folders');
    return normalizeFolders(folders);
};

/** @type { (name: Folder['name'], parentFolderId: Folder['id'] | null) => Promise<Folder> } */
export const createFolder = async (name, parentFolderId) => {
    const newFolder = (parentFolderId)
        ? { name, parent: parentFolderId }
        : { name };

    const { data: folder } = await post('/folders', { body: newFolder });
    return normalizeFolder(folder);
};

/** @type { (folder: Folder) => Promise<void> } */
export const deleteFolder = async (folder) => {
    await del(`/folders/${encodeURIComponent(folder.id)}`);
};

/** @type { (folder: Folder) => Promise<Folder> } */
export const updateFolder = async (folder) => {
    const { data: updatedFolder } = await put(`/folders/${encodeURIComponent(folder.id)}`, {
        body: {
            id: folder.id,
            name: folder.name,
            parent: folder.parent || null,
        },
    });
    return normalizeFolder(updatedFolder);
};

/** @type { (object: DatabaseObject, folderId: Folder['id']) => Promise<DatabaseObject> } */
export const moveObjectToFolder = async (object, folderId) => {

    const objectToUpdate = {
        ...object,
        parent: folderId,
        folder: folderId,
    };

    if (objectToUpdate.type === 'folder') {
        return updateFolder(objectToUpdate);
    }
    if (objectToUpdate.type === 'table') {
        return updateTable(objectToUpdate);
    }
    if (objectToUpdate.type === 'view') {
        return updateView(objectToUpdate);
    }

    throw new Error('moveObjectToFolder called with invalid arguments');
};

/** @type { (object: DatabaseObject) => number } */
export const getFolderSize = (object) => {

    const nestedItems = object.items;

    if (!nestedItems || !nestedItems.length) {
        return object.metadata.size;
    }

    const folderSize = nestedItems.reduce((acc, nestedObject) => {

        if (nestedObject.type === 'view') {
            return acc;
        }

        if (nestedObject.items && nestedObject.items.length) {
            const nestedItemSize = getFolderSize(nestedObject);
            return Number(acc) + Number(nestedItemSize);
        }

        return Number(acc) + Number(nestedObject.metadata.size);
    }, 0);

    return Number(folderSize);
};

/** @type { (object: DatabaseObject) => number } */
export const getFolderRows = (object) => {

    const nestedItems = object.items;

    if (!nestedItems || !nestedItems.length) {
        return object.metadata.rows;
    }

    const folderSize = nestedItems.reduce((acc, nestedObject) => {

        if (nestedObject.type === 'view') {
            return acc;
        }

        if (nestedObject.items && nestedObject.items.length) {
            const nestedObjectRows = getFolderRows(nestedObject);
            return Number(acc) + Number(nestedObjectRows);
        }

        return Number(acc) + Number(nestedObject.metadata.rows);
    }, 0);

    return folderSize;
};


/** @type { (objects: DatabaseObject[]) => DatabaseObject[] } */
export const populateFoldersMetadata = objects => objects.map((object) => {
    if (object.type !== 'folder') {
        return object;
    }

    return {
        ...object,
        metadata: {
            ...object.metadata,
            size: getFolderSize(object),
            rows: getFolderRows(object),
        },
        items: populateFoldersMetadata(object.items),
    };
});

/** @type { (folder: Folder, list: DatabaseObject[]) => DatabaseObject[] } */
export const getFolderChildrenFromList = (folder, list) => list.filter(f => f.parent === folder.id)
    .map((f) => {
        if (f.type !== 'folder') {
            return f;
        }
        return {
            ...f,
            items: [
                ...f.items,
                ...getFolderChildrenFromList(f, list),
            ],
        };
    });
