import React from 'react';

import {
    SchemaLock,
    LoadStrategy,
    Fields,
    useCollectConfig,
} from '../../shared/source/collect-config';

/** @typedef { import('models/sources/__types').Source } Source */
/** @typedef { import('models/sources/__types').CollectConfig } CollectConfig */

/**
 * @typedef { import('shared/source/collect-config/types').CollectConfigParam } CollectConfigParam
 */

/**
 * @typedef { object } Props
 * @prop { Source } source
 * @prop { Partial<CollectConfig> } [collectConfig]
 * @prop { (cc: Partial<CollectConfig>, replace?: boolean) => void } setCollectConfig
*/

/** @type { React.FC<Props> } */
const CollectConfig = ({
    source,
    collectConfig,
    setCollectConfig,
}) => {
    const collectConfigParams = useCollectConfig(collectConfig, setCollectConfig);

    const params = collectConfigParams.filter(param => !param.hidden);

    return (
        <>
            {params.map(param => (
                <CollectConfigParam
                    key={param.name}
                    source={source}
                    {...param}
                />
            ))}
        </>
    );
};

/** @type { React.FC<{ source: Source } & CollectConfigParam> } */
const CollectConfigParam = ({ source, title, name, type, value, setValue, props }) => {
    switch (type) {
        case 'schema-lock':
            return (
                <SchemaLock
                    key={name}
                    value={value}
                    setValue={setValue}
                    disabled={!source.lastTimeSucceed}
                    {...props}
                />
            );
        case 'load-strategy':
            return (
                <LoadStrategy
                    key={name}
                    value={value}
                    setValue={setValue}
                    {...props}
                />
            );
        case 'fields':
            return (
                <Fields
                    key={name}
                    title={title}
                    value={value}
                    setValue={setValue}
                    {...props}
                />
            );
        default:
            return null;
    }
};

export default CollectConfig;
