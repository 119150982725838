
import useRouter from './use-router';
import useFullbleed from './use-fullbleed';
import { useFeedback, useToast } from '../shared/context-providers';

/** @typedef { import('./__types').PageRoute } Route */
/** @typedef { import('./__types').Feedback } Feedback */
/** @typedef { import('./__types').Toast } Toast */
/** @typedef { import('./__types').PageProps } PageProps */

/**
 * @typedef { object } ReturnProps
 * @prop { Route[] } routes
 * @prop { boolean } fullbleed
 * @prop { Feedback } feedback
 * @prop { Toast } toast
 * @prop { PageProps['sendFeedback'] } sendFeedback
 * @prop { PageProps['openToast'] } openToast
 */

/** @type { () => ReturnProps } */
function useApp() {
    const { routes } = useRouter();
    const { fullbleed } = useFullbleed();
    const { feedback, sendFeedback } = useFeedback();
    const { toast, openToast } = useToast();

    return {
        routes,
        fullbleed,
        feedback,
        toast,
        sendFeedback,
        openToast,
    };
}

export default useApp;
