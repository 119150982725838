import React from 'react';
import styled from 'styled-components';

import {
    Button,
    Icon,
    Layout,
    Loader,
    colors,
    breakpoints,
    spacings,
} from 'ui-components';

import { useIsFetching } from '@tanstack/react-query';

import { useOnboarding } from '../../shared/context-providers';

import logoPath from '../../images/logo-panoply-big.svg';

import HeaderNav from './header-nav';
import TrialMessage from './trial-message';
import WarehousesMenu from './warehouses-menu';
import HelpMenu from './help-menu';
import UserMenu from './user-menu';
import useAppHeader from './use-app-header';


/** @type { React.FC } */
const AppHeader = () => {
    const {
        nav,
        user,
        database,
        databases,
        billing,
        navOpen,
        path,
        items,
        showTrialMessage,
        toggleNavOpen,
        navBreadCrumbItems,
        showBreadCrumbNav,
        setShowBreadCrumbNav,
        setNavIsHovered,
        desktop,
        changeDatabase,
    } = useAppHeader();

    const [onboarding] = useOnboarding();
    const fetching = useIsFetching({ type: 'active' });

    const onMouseEnter = () => setNavIsHovered(true);
    const onMouseLeave = () => setNavIsHovered(false);
    const hideBreadCrumbsNav = () => setShowBreadCrumbNav(false);

    return (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Header
                flex
                relative
                width="100"
                alignItems="center"
                justifyContent="space-between"
                bgColor={colors.white}
                spacing="pl-4 pr-4"
            >
                <Layout flex alignItems="center">
                    {!onboarding.active && (
                        <NavToggle
                            open={navOpen}
                            onClick={toggleNavOpen}
                            spacing="m-0 p-3"
                        />
                    )}

                    <Logo
                        src={logoPath}
                        width="30"
                        height="35"
                        alt="Panoply"
                        onMouseEnter={hideBreadCrumbsNav}
                    />

                    {!onboarding.active && (
                        <HeaderNav
                            path={path}
                            items={items}
                            toggleOpen={toggleNavOpen}
                            open={navOpen}
                            navBreadCrumbItems={navBreadCrumbItems}
                            showBreadCrumbNav={showBreadCrumbNav}
                            setShowBreadCrumbNav={setShowBreadCrumbNav}
                            desktop={desktop}
                            spacing="px-5"
                        />
                    )}
                </Layout>

                <Layout
                    flex
                    alignItems="center"
                    justifyContent="flex-end"
                    width="100"
                >
                    <FetchingIndicator
                        active={!!fetching}
                        relative
                    />

                    {showTrialMessage && (
                        <TrialMessage
                            database={database}
                            billing={billing}
                            spacing="mr-4 mr-xl-5"
                        />
                    )}

                    {!onboarding.active && (
                        <>
                            <WarehousesMenu
                                database={database}
                                databases={databases}
                                changeDatabase={changeDatabase}
                                spacing="m-0 p-1 px-3"
                            />

                            <HelpMenu
                                billing={billing}
                                spacing="mx-4 mx-xl-5"
                            />

                            <UserMenu
                                nav={nav}
                                user={user}
                                database={database}
                                spacing="ml-0 my-2"
                            />
                        </>
                    )}
                </Layout>
            </Header>
        </div>
    );
};

/**
 * @typedef { object } NavToggleProps
 * @prop { boolean } open
 * @prop { () => void } onClick
 * @prop { string } [spacing]
 */

/** @type { (props: NavToggleProps) => React.ReactElement } */
function NavToggle({ open, ...props }) {
    return (
        <VisibleOnSmallScreen>
            <Button round type="plain" {...props}>
                <Icon icon={open ? 'times' : 'bars'} />
            </Button>
        </VisibleOnSmallScreen>
    );
}

const Header = styled(Layout)`
    height: 60px;
    z-index: 102;
    border-bottom: 1px solid ${colors.secondaryInterface};
`;

const Logo = styled('img')`
    vertical-align: top;
    margin-left: 0.25rem;
`;

const FetchingIndicator = styled(Loader)`
    && {
        width: 40px;
        margin-right: ${spacings[3]}rem;
    }
`;

const VisibleOnSmallScreen = styled('span')`
    display: inline-block;

    @media (min-width: ${breakpoints.md}) {
        display: none;
    }
`;

export default AppHeader;
