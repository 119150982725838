import React from 'react';
import {
    Layout,
    Loader,
    Typography,
} from 'ui-components';

import { SourcePageContext } from './use-source-page/source-page-context';
import PageLayout from '../../shared/page-layout';
import PageHelmet from '../../shared/page-helmet';
import useSourcePage from './use-source-page';

import SourceSideNavigation from './router/source-side-navigation';
import SourceNestedRouter from './router/source-nested-router';
import Dialogs from './dialogs';

/** @typedef { import('../../app/__types').PageProps } PageProps */

/** @type { React.FC<PageProps> } */
const SourcePage = ({ sourceId }) => {
    const sourcePageProps = useSourcePage(sourceId);

    const {
        loading,
        loadingError,
        source,
        sourceType,
        refreshingError,
        schedule,
    } = sourcePageProps;

    if ((!loading && loadingError) || refreshingError) {
        return (
            <PageLayout title="Oops!">
                <Layout spacing="p-8">
                    <Typography variant="h6" align="center">
                        Oops! There was an error when loading this source
                    </Typography>
                    <Typography variant="subtitle1" align="center">
                        {loadingError || refreshingError}
                    </Typography>
                </Layout>
            </PageLayout>
        );
    }

    return (
        <>
            {(loading || !sourceType || !source || !schedule) ? (
                <PageLayout title="Loading...">
                    <Layout spacing="p-8">
                        <Loader active big message="Loading Source" />
                    </Layout>
                </PageLayout>
            ) : (
                <SourcePageContext.Provider value={sourcePageProps}>
                    <PageHelmet title={source.title} />
                    <Dialogs />

                    <Layout flex width="100">
                        <SourceSideNavigation />
                        <SourceNestedRouter />
                    </Layout>
                </SourcePageContext.Provider>
            )}
        </>
    );
};

export default SourcePage;
