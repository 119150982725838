const descriptorKeys = [
    'sources',
    'storage',
    'support',
    'users',
    'feature_1',
    'feature_2',
    'feature_3',
];

function formatDescriptors(descriptors = {}) {
    return descriptorKeys
        .filter(key => Boolean(descriptors[key]))
        .map(key => ({ key, value: descriptors[key] }));
}

function formatPeriod(period) {
    switch (period) {
        case 'month':
            return 'monthly';
        case '3 months':
            return 'quarterly';
        case 'year':
            return 'annually';
        default:
        // e.g. every 4 months
            return 'every' + period.toLowerCase();
    }
}

function formatInterval(interval) {
    switch (interval) {
        case 'month':
            return 'mo';
        case 'quarter':
            return 'qr';
        case 'year':
            return 'yr';
        default:
            return interval.toLowerCase();
    }
}

export {
    formatPeriod,
    formatInterval,
    formatDescriptors,
};
