import React from 'react';

import {
    Button,
} from 'ui-components';

import { exportQueryResult } from '../../models/queries';
import { useToast } from '../context-providers';

/** @typedef { import('models/queries/__types').QueryResult } QueryResult */

/**
 * @typedef { object } Props
 * @prop { QueryResult } [queryResultPage]
 * @prop { boolean } isSelectQuery
 * @prop { string } [queryError]
 * @prop { (id: QueryResult['id'], updates: Partial<QueryResult>) => void } updateQueryResult
 */

/** @type { React.FC<Props> } */
const WorkbenchExport = ({
    queryResultPage,
    isSelectQuery,
    queryError,
    updateQueryResult,
}) => {
    const { openToast } = useToast();

    const canExport = !queryResultPage?.exportingCsv
        && !!queryResultPage?.data?.rows?.length
        && isSelectQuery
        && !queryError;

    const exportQuery = async () => {
        if (!queryResultPage?.id) {
            return;
        }

        updateQueryResult(queryResultPage.id, {
            exportingCsv: true,
            exportStartTime: new Date(),
        });

        openToast('Preparing export data');

        try {
            const url = queryResultPage.outputFileUrl
                || await exportQueryResult(queryResultPage);

            updateQueryResult(queryResultPage.id, {
                dataExported: true,
                outputFileUrl: url,
            });

            window.location.assign(url); // download csv file
            openToast('Downloading exported data');

        } catch (e) {
            openToast({
                message: 'Could not export data',
                type: 'error',
            });

        } finally {
            updateQueryResult(queryResultPage?.id, {
                exportingCsv: false,
                exportEndTime: new Date(),
            });
        }
    };

    return (
        <Button
            square
            type="plain"
            spacing="m-0 mr-2 p-1"
            onClick={exportQuery}
            disabled={!canExport}
            leftIcon="download"
        >
            Export
        </Button>
    );
};

export default WorkbenchExport;
