import React, { useState, useEffect } from 'react';

import { Layout } from 'ui-components';

import PostCollectBanner from './post-collect-banner';
import SourceErrorBanner from './source-error-banner';
import RecipesBanner from './recipes-banner';

import { useSourcePageContext } from '../use-source-page/source-page-context';
import { statuses, getStatus } from '../../../models/jobs/status';

const SourceDialogsAndBanners = () => {

    const {
        source,
        sourceType,
        canUseRecipes,
    } = useSourcePageContext();

    const [status, setStatus] = useState(source?.job && getStatus(source.job));

    useEffect(() => {
        setStatus(source?.job && getStatus(source.job));
    }, [source]);

    if (source?.job?.error && status === statuses.Failed) {
        return (
            <SourceErrorBanner
                job={source.job}
                title={source.title}
            />
        );
    }

    if (!source || !sourceType) {
        return null;
    }

    if (status === statuses.Success) {
        return (
            <>
                <Layout spacing="my-5">
                    <RecipesBanner
                        source={source}
                        canUseRecipes={canUseRecipes}
                    />
                    <PostCollectBanner
                        sourceType={sourceType}
                        source={source}
                    />
                </Layout>
            </>
        );
    }

    return null;
};

export default SourceDialogsAndBanners;
