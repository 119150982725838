import {
    get,
    put,
    del,
} from '../../lib/ajax';
import { addBreadcrumb } from '../../lib/error-reporter';

/** @typedef { import('./__types').DatabaseObject } DatabaseObject */
/** @typedef { import('./__types').View } View */
/** @typedef { import('./__types').Query } Query */

/** @type { (view: any) => View } */
export const normalizeView = view => ({
    ...view,
    type: 'view',
    metadata: {
        ...view.metadata,
        size: Number((view.metadata && view.metadata.size) || 0),
        rows: Number((view.metadata && view.metadata.tbl_rows) || 0),
    },
    // Normalize data since views have 'schemaname' and not 'schema'
    schema: view.schemaname,
    // folders use parent & items use folder so make everything use parent
    parent: view.folder,
});

/** @type { (views: any[]) => View[] } */
export const normalizeViews = views => views.map(normalizeView);

/** @type { (view: View) => Promise<View> } */
export const updateView = async (view) => {
    try {
        const { data: updatedView } = await put(`/views/${encodeURIComponent(view.id)}`, {
            body: view,
            query: {
                $with: 'metadata',
                $withOwner: true,
            },
        });

        return normalizeView(updatedView);
    } catch (error) {
        addBreadcrumb('Failed to update a view', { error });

        throw error;
    }
};

/** @type { (view: View) => Promise<void> } */
export const deleteView = async (view) => {
    try {
        await del(`/views/${encodeURIComponent(view.id)}`);

    } catch (error) {
        addBreadcrumb('Failed to fetch views', { error });

        throw error;
    }
};

/** @type { (params?: { query?: Query }) => Promise<View[]> } */
export const fetchViews = async (params) => {
    const { query } = params || {};

    try {
        const { data: views } = await get('/views', { query });

        return normalizeViews(views);

    } catch (error) {
        addBreadcrumb('Failed to fetch views', { error });

        throw error;
    }
};

/** @type { (id: string) => Promise<View> } */
export const fetchView = async (id) => {
    try {
        const { data: view } = await get(`/views/${encodeURIComponent(id)}`);

        return normalizeView(view);

    } catch (error) {
        addBreadcrumb('Failed to fetch a view', { error });

        throw error;
    }
};

/** @type { (view: View) => Promise<View[]> } */
export const fetchViewDependencies = async (view) => {
    try {
        const { data: fetchedView } = await get(`/views/${encodeURIComponent(view.id)}`, {
            query: {
                $withDepends: true,
            },
        });

        if (!fetchedView.views) {
            return [];
        }

        const dependencies = normalizeViews(fetchedView.views);

        return dependencies;

    } catch (error) {
        addBreadcrumb('Failed to fetch view dependencies', { error });

        throw error;
    }
};
