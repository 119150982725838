import {
    isArray,
    isBoolean,
    isEmpty,
    isObject,
    isPlainObject,
    uniq,
    get,
    set,
    isEqual,
} from 'lodash';

import { Query, isValueEmpty } from '../../lib/mingo';

import * as IO from './io';
import * as Normalizers from './normalizers';
import * as Validators from './validators';

/** @typedef { import('./__types').Source } Source */
/** @typedef { import('./__types').SourceDefaults } SourceDefaults */
/** @typedef { import('./__types').Schedule } Schedule */
/** @typedef { import('./__types').SourceType } SourceType */
/** @typedef { import('./__types').Param } Param */
/** @typedef { import('./__types').FlattenedParam } FlattenedParam */
/** @typedef { import('./__types').GroupParam } GroupParam */
/** @typedef { import('./__types').ParamName } ParamName */
/** @typedef { import('./__types').Predicate } Predicate */
/** @typedef { import('./__types').DynamicParam } DynamicParam */
/** @typedef { import('./__types').OAuthParam } OAuthParam */
/** @typedef { import('./__types').DynamicParamResponse } DynamicParamResponse */
/** @typedef { import('./__types').DynamicParamArgs } DynamicParamArgs */
/** @typedef { import('./__types').ValidationParam } ValidationParam */
/** @typedef { import('./__types').ValidationParamResponse } ValidationParamResponse */
/** @typedef { import('./__types').FieldValidationErrors } FieldValidationErrors */
/** @typedef { import('../../app/__types').Session['database']['type'] } DbType */

export {
    createSource,
    collectSource,
    deleteSource,
    syncSources,
    createSourceSchedule,
    fetchSourceCollectConfig,
    saveSourceCollectConfig,
    deleteSourceCollectConfig,
    updateSourceSchedule,
    deleteSourceSchedule,
    fetchSourceHistory,
    fetchSourceTypes,
    fetchSourceList,
    fetchSourceJob,
    cancelSourceJob,
    fetchAllSources,
} from './io';

/** @type { (id: Source['id']) => Promise<[Source, SourceType, FlattenedParam[]]> } */
export const fetchSourceAndType = async (sourceId) => {
    const source = await IO.fetchSource(sourceId);
    const sourceType = await IO.fetchSourceType(source.type);

    const normalizedSourceType = Normalizers.normalizeSourceType(sourceType);
    const flattenedParams = getFlattenedParams(normalizedSourceType.params);

    const normalizedSource = Normalizers.normalizeSource(source, flattenedParams);

    return [normalizedSource, normalizedSourceType, flattenedParams];
};

/** @type { (id: Source['id']) => Promise<SourceDefaults> } */
export const fetchSourceDefaults = async (sourceId) => {
    const sourceDefaults = await IO.fetchSourceDefaults(sourceId);
    return sourceDefaults;
};

/** @type { (sourceId: Source['id'], flattenedParams: FlattenedParam[]) => Promise<Source> } */
export const refreshSource = async (sourceId, flattenedParams) => {
    const source = await IO.fetchSource(sourceId);
    return Normalizers.normalizeSource(source, flattenedParams);
};

/** @type { (source: Source, flattenedParams: FlattenedParam[]) => Promise<void> } */
export const saveSource = async (source, flattenedParams) => {
    const denormalized = Normalizers.denormalizeSource(source, flattenedParams);
    await IO.saveSource(denormalized);
};

/** @type { (from: Source, to: Source, flattenedParams: FlattenedParam[]) => Source } */
export const copyParamValues = (from, to, flattenedParams) => {
    return flattenedParams
        .filter(([param]) => isCopiableParam(param))
        .reduce((source, flattenedParam) => {
            const newValue = getParamValue(
                from,
                flattenedParam
            );

            const newSource = setParamValueWithoutReset(
                source,
                flattenedParam,
                newValue,
            );

            return newSource;
        }, to);
};

// Param handling


/** @type { (param: Param) => param is GroupParam } */
export const isGroupParam = param => param.type === 'group';

/** @type { (param: Param) => param is OAuthParam } */
export const isOAuthParam = param => ['oauth1', 'oauth2'].includes(param.type);

/** @type { (param: Param) => boolean } */
export const isHiddenParam = param => param.hidden === true;

/** @type { (param: Param) => boolean } */
export const isSyncableParam = param => !isValidationParam(param) && !isHiddenParam(param);

/** @type { (param: Param) => boolean } */
export const isCopiableParam = param => isSyncableParam(param) && !isGroupParam(param);

/** @type { (value: any) => value is Predicate } */
export const isPredicate = (value) => {
    return isPlainObject(value);
};

/** @type { (predicate: Predicate, flattenedParams: FlattenedParam[]) => ParamName[] } */
export const getPredicateDeps = (predicate, flattenedParams) => {
    const deps = Object.keys(predicate)
        // Go deeper to get all the deep keys
        // Ex: { a: { b: 1 } } -> ['a', 'b']
        .reduce((deps, /** @type { ParamName } */ dep) => {
            deps.push(dep);

            const subs = /** @type { Predicate[] } */ (
                isArray(predicate[dep])
                    ? predicate[dep]
                    : [predicate[dep]]
            );

            subs.filter(isPredicate).forEach(p => {
                deps.push(...getPredicateDeps(p, flattenedParams));
            });

            return deps;
        }, /** @type { ParamName[] } */ ([]))

        // Filter operators (ex: $or, $and etc.)
        .filter(dep => {
            if (dep.startsWith('$')) {
                return false;
            }
            return true;
        })

        .map((dep) => {
            const segments = dep.split('.');
            if (segments.length <= 1) {
                return dep;
            }
            const indexToSplit = segments.findIndex((segment) => {
                const paramMatchingSegment = flattenedParams.find(([param]) => {
                    return param.name === segment;
                });
                if (segment.startsWith('$')) {
                    return true;
                }
                if (!Number.isNaN(Number(segment))) {
                    return true;
                }
                if (flattenedParams.length && !paramMatchingSegment) {
                    return true;
                }
                return false;
            });
            if (indexToSplit === -1) {
                return dep;
            }
            return segments.slice(0, indexToSplit).join('.');
        });

    // Filter duplicates
    return uniq(deps);
};

/** @type { (param: Param, flattenedParams: FlattenedParam[]) => ParamName[] } */
export const getParamDeps = (param, flattenedParams) => {
    const conditions = param.conditions || {};
    if (isPredicate(conditions.isVisible)) {
        return getPredicateDeps(conditions.isVisible, flattenedParams);
    }
    return [];
};

/** @type { (param: Param) => any } */
const getParamInitialValue = (param) => {
    switch (param.type) {
        // These do not support default values
        case 'oauth1':
        case 'oauth2':
        case 'upload':
            return null;

        case 'dynamic-list':
        case 'static-list':
            return [];

        case 'static-autocomplete':
            return [];

        case 'dynamic-autocomplete':
            return [];

        case 'group':
            return {};

        default:
            return 'default' in param ? param.default : null;
    }
};

/** @type { (source: Source) => any } */
const getOAuthParamValue = (source) => {
    // Grab the values for the keys in oauthValueKeys from source
    const value = (source?.__clientBackCompat?.oauthValueKeys || [])
        .reduce((val, key) => {
            if (isEmpty(source[key])) {
                return val;
            }
            return { ...val, [key]: source[key] };
        }, {});

    return isEmpty(value) ? null : value;
};

/** @type { (param: Param['name'], groups?: GroupParam[]) => string } */
export const getParamValuePath = (paramName, groups) => {
    if (!groups) {
        return paramName;
    }

    const namespacedGroups = groups.filter(group => group.namespace === true);

    if (!namespacedGroups.length) {
        return paramName;
    }

    return groups.filter(group => group.namespace === true)
        .map((group) => group.name).join('.') + '.' + paramName;
};

/** @type { (source: Source, flattenedParam: FlattenedParam) => any } */
export const getParamValue = (source, flattenedParam) => {
    const [param, groups] = flattenedParam;

    /**
     * Special OAuth param handling:
     * Since we're currently spreading oauth param values directly over source
     * we must read these values using source.__clientBackCompat.oauthValueKeys.
     *
     * Once we make the switch to source.params[param.name], and sources stop
     * expecting oauth param values to be spread over source this should be safe
     * to remove.
     */
    if (isOAuthParam(param)) {
        return getOAuthParamValue(source);
    }

    const valuePath = getParamValuePath(param.name, groups);

    // NOTE: this should be source.params in the future.
    const value = get(source, valuePath);

    const valueUnset = isValueEmpty(value);

    return (
        valueUnset
            ? getParamInitialValue(param)
            : value
    );
};

/** @type { (source: Source, value: any) => Source } */
export const setOAuthParamValue = (source, value) => {
    if (!isObject(value) && value !== null) {
        throw new Error('Received an invalid value while trying to set an oAuth param');
    }

    const clearingValue = value == null;

    if (clearingValue) {
        const keysToClear = (source?.__clientBackCompat?.oauthValueKeys || []);
        return {
            ...source,
            ...keysToClear.reduce((acc, key) => (
                { ...acc, [key]: null }
            ), {}),
        };
    }

    return {
        __clientBackCompat: {
            ...source.__clientBackCompat,
            oauthValueKeys: Object.keys(value),
        },
        ...source,
        ...value,
    };
};

/** @type { (param: Param, source: Source) => boolean } */
export const shouldResetDeps = (param, source) => {
    const conditions = param.conditions || {};

    if (isPredicate(conditions.resetDeps)) {
        const query = new Query(conditions.resetDeps);
        return query.test(source);
    }

    if (isBoolean(conditions.resetDeps)) {
        return conditions.resetDeps;
    }

    return true;
};

/** @type { (param: Param, flattenedParams: FlattenedParam[]) => FlattenedParam[] } */
export const getParamDepsParams = (param, flattenedParams) => {
    const deps = getParamDeps(param, flattenedParams);

    const params = flattenedParams
        .filter(([param, groups]) => {
            const paramPath = getParamValuePath(param.name, groups);
            return deps.includes(paramPath);
        });

    return params;
};

/** @type { (fp: FlattenedParam[], flattenedParam: FlattenedParam) => FlattenedParam[] } */
export const getDependentParams = (flattenedParams, flattenedParam) => {
    const [param, groups] = flattenedParam;

    const paramPath = getParamValuePath(param.name, groups);

    const dependantParams = flattenedParams
        .filter(([param]) => {
            const deps = getParamDeps(param, flattenedParams);
            return deps.includes(paramPath);
        });

    return dependantParams;
};

/** @typedef { Param['name'][] } AffectedParams */
/**
 * @param { FlattenedParam[] } flattenedParams
 * @param { Source } source
 * @param { FlattenedParam } flattenedParam
 * @param { any } value
 * @param { Param | null } [rootParam]
 * @returns { [Source, AffectedParams] } - A modified source and a list of the
 * params affected
 */
export const setParamValue = (
    flattenedParams,
    source,
    flattenedParam,
    value,
    rootParam = null
) => {
    const [param, groups] = flattenedParam;
    const resetDeps = shouldResetDeps(rootParam || param, source);

    const dependantParams = getDependentParams(flattenedParams, flattenedParam);

    // Get the source with param dependencies all reset to initial values
    const [sourceAfterDepsReset, affectedParams] = dependantParams
        .reduce(
            ([src, affectedParams], dependantParam) => {
                const [dep] = dependantParam;

                const initVal = resetDeps
                    ? getParamInitialValue(dep)
                    : getParamValue(src, dependantParam);

                const [updatedSrc, affected] = setParamValue(
                    flattenedParams,
                    src,
                    dependantParam,
                    initVal,
                    rootParam || param,
                );

                return [updatedSrc, [...affectedParams, ...affected]];
            },
            /** @type { [Source, AffectedParams] } */ ([source, []]),
        );

    // See OAuth special handling note in getParamValue
    if (isOAuthParam(param)) {
        return [
            setOAuthParamValue(sourceAfterDepsReset, value),
            [...affectedParams, param.name],
        ];
    }

    const valuePath = getParamValuePath(param.name, groups);
    const sourceWithValue = set(sourceAfterDepsReset, valuePath, value);

    return [sourceWithValue, [...affectedParams, param.name]];
};

/**
 * @param { Source } source
 * @param { FlattenedParam } flattenedParam
 * @param { any } value
 * @returns { Source } - Modified Source
 */
export const setParamValueWithoutReset = (source, flattenedParam, value) => {
    const [param, groups] = flattenedParam;

    // See OAuth special handling note in getParamValue
    if (isOAuthParam(param)) {
        return setOAuthParamValue(source, value);
    }

    const valuePath = getParamValuePath(param.name, groups);
    const sourceWithValue = set(source, valuePath, value);

    return sourceWithValue;
};

/** @type { (params: Param[], groups?: GroupParam[]) => FlattenedParam[] } */
export const getFlattenedParams = (params, groups = []) => {
    return params.reduce((list, param) => {
        if (isGroupParam(param)) {
            const group = { ...param, params: [] };

            list.push(
                [group, groups],
                ...getFlattenedParams(param.params, [...groups, group])
            );
        } else {
            list.push([param, groups]);
        }

        return list;
    }, /** @type { FlattenedParam[] } */ ([]));
};

/** @type { (flattenedParams: FlattenedParam[], name: Param['name']) => FlattenedParam } */
export const findFlattenedParam = (flattenedParams, name) => {
    const found = flattenedParams.find(([param]) => param.name === name);

    if (found) {
        return found;
    }

    throw new Error(`Unknown param: ${name}`);
};

/** @type { (s: Source, fp: FlattenedParam) => boolean } */
export const isParamValueEmpty = (source, flattenedParam) => {
    const value = getParamValue(source, flattenedParam);
    return isValueEmpty(value);
};

/** @type { (s: Source, p: Param) => boolean } */
export const isParamRequired = (source, param) => {
    const isRequired = param.conditions?.isRequired;

    if ('required' in param) {
        const warning = 'Usage of "required" on source params is deprecated. '
            + 'Use conditions.isRequired instead';
        // eslint-disable-next-line no-console
        console.warn(warning);
        return param.required || false;
    }
    if (isBoolean(isRequired)) {
        return isRequired;
    }
    if (isPredicate(isRequired)) {
        const query = new Query(isRequired);
        return query.test(source);
    }
    return false;
};

/**
 * This function returns a boolean indicating whether all of a param's list
 * of dependencies have values.
 * NOTE: this does not traverse up the dependency tree to check if the
 * dependency params have their dependencies met.
 * @param { Source } source
 * @param { Param } param
 * @returns { boolean }
 */
export const areParamDepsMet = (source, param) => {
    const conditions = param.conditions || {};

    if (isPredicate(conditions.isVisible)) {
        const query = new Query(conditions.isVisible);
        return query.test(source);
    }

    return true;
};

/** @type { (source: Source, flattenedParams: FlattenedParam[]) => FieldValidationErrors } */
export const getFieldValidationErrors = (source, flattenedParams) => (
    [
        Validators.validatePrimaryKey,
        Validators.validateDestination,
        Validators.validateDestinationPrefix,
        Validators.validateSSL,
        Validators.validateSSH,
        Validators.validateMatrixParams,
        Validators.validateDelimiter,
    ].reduce(
        (errors, validate) => ({ ...errors, ...validate(source, flattenedParams) }),
        {},
    )
);


// Validation param

/** @type { (param: Param) => param is ValidationParam } */
export const isValidationParam = param => param.type === 'validation';

/**
 * @param { Source } source
 * @param { FlattenedParam[] } flattenedParams
 * @param { FlattenedParam } flattenedParam
 * @returns { Promise<ValidationParamResponse> }
 */
export const loadValidationParam = async (source, flattenedParams, flattenedParam) => {
    const [param] = flattenedParam;

    if (!isValidationParam(param)) {
        throw new Error('Cannot load a non-validation param');
    }

    const denormalizedSource = Normalizers.denormalizeSource(source, flattenedParams);
    const response = await IO.fetchValidationParam(denormalizedSource, param.name);

    if (response == null) {
        throw new Error('Received an empty response for a validation param');
    }

    return Normalizers.normalizeValidationParamResponse(response);
};


// Dynamic params


/** @type { (param: Param) => param is DynamicParam } */
const isParamDynamic = param => [
    'dynamic-list',
    'dynamic-select',
    'dynamic-autocomplete',
].includes(param.type);

/**
 * @param { Source } source
 * @param { FlattenedParam[] } flattenedParams
 * @param { FlattenedParam } flattenedParam
 * @param { DynamicParamArgs } args
 * @returns { Promise<DynamicParamResponse> }
 */
export const loadDynamicParam = async (source, flattenedParams, flattenedParam, args) => {
    const [param] = flattenedParam;

    if (!isParamDynamic(param)) {
        throw new Error('Cannot load a non-dynamic param');
    }

    const denormalizedSource = Normalizers.denormalizeSource(source, flattenedParams);
    // TODO: Remove this before merge (when backend fixed https://panoply.atlassian.net/browse/PAN-2663)
    denormalizedSource[param.name] = [];
    const response = await IO.fetchDynamicParam(denormalizedSource, param.name, args);

    if (response == null) {
        throw new Error('Received an empty response for a dynamic param');
    }

    return Normalizers.normalizeDynamicParamResponse(param, response);
};


// Collect

/** @type { (s: Source) => boolean } */
export const isSourceCollecting = source => (
    !!source.job
    && !!source.job.id
    && (
        !source.job.status
        || ['pending', 'running', 'booting'].includes(source.job.status)
    )
);

/** @type { (s: Source, flattenedParams: FlattenedParam[]) => boolean } */
export const isSourceCollectable = (source, flattenedParams) => {
    return flattenedParams
        .filter(([param]) => isParamRequired(source, param))
        .every(flattenedParam => {
            const [param] = flattenedParam;
            return !isParamValueEmpty(source, flattenedParam)
                && areParamDepsMet(source, param);
        });
};

/** @type { (source: Source) => Schedule } */
export const getSourceSchedule = source => ({
    type: 'collect',
    dayOfWeek: source.schedule,
    hour: source.schedule_hour,
    minute: source.schedule_minute,
    disabled: source.schedule_disabled,
});

/** @type { (schedule: Schedule, source: Source, sourceType: SourceType) => Promise<void> } */
export const syncSourceSchedule = async (schedule, source, sourceType) => {

    const lastSavedSchedule = getSourceSchedule(source);

    // Don't do anything if schedule didn't change
    if (isEqual(lastSavedSchedule, schedule) || !sourceType.schedulable) {
        return;
    }

    const wasScheduled = !!lastSavedSchedule?.dayOfWeek;
    const currentlyScheduled = !!schedule.dayOfWeek;
    const needsUpdate = wasScheduled && currentlyScheduled;
    const needsDelete = wasScheduled && !currentlyScheduled;
    const needsCreate = !wasScheduled && currentlyScheduled;

    if (needsUpdate) {
        await IO.updateSourceSchedule(source, schedule);
    }
    if (needsCreate) {
        await IO.createSourceSchedule(source, schedule);
    }
    if (needsDelete) {
        await IO.deleteSourceSchedule(source);
    }
};

/** @type { (sourceId: Source['id'], title?: Source['title']) => Promise<Source> } */
export const cloneSource = async (sourceId, title) => {
    const clonedSource = await IO.cloneSource(sourceId, title);

    const collectConfig = await IO.fetchSourceCollectConfig(sourceId);

    // If the CC has any actual values saved on it then clone it
    if (Object.values(collectConfig).length > 0) {
        await IO.saveSourceCollectConfig(
            collectConfig, clonedSource.id,
        );
    }

    return clonedSource;
};
