import React from 'react';
import styled from 'styled-components';

import {
    Button,
    Icon,
    Tooltip,
    Dropdown,
    List,
    ListItem,
} from 'ui-components';

/** @typedef { import('./__types').MenuItem } MenuItem */

/**
 * @typedef { object } Props
 * @prop { boolean } open
 * @prop { () => void } onClick
 * @prop { MenuItem[] } items
 * @prop { string } spacing
 */

/** @type { React.FC<Props> } */
const WorkbenchMenuDropDown = ({
    open, onClick, items, ...props
}) => {
    if (!items.length) {
        return null;
    }

    return (
        <Dropdown
            open={open}
            onClose={onClick}
            opener={(
                <Button square type="plain" onClick={onClick} {...props}>
                    <Icon icon="ellipsis-v" />
                </Button>
            )}
        >
            <DropdownList>
                {items.map((/** @type { MenuItem } */ item) => {
                    const onItemClicked = () => {
                        if (item.onClick) {
                            item.onClick();
                        }
                        if (onClick) {
                            onClick(); // close
                        }
                    };
                    return <DropdownListItem key={item.label} {...item} onClick={onItemClicked} />;
                })}
            </DropdownList>
        </Dropdown>
    );
};

/**
 * @param { MenuItem } props
 * @returns { React.ReactElement }
 */
function DropdownListItem({
    tooltip, icon, loading, disabled, active, ...props
}) {
    return (
        <ListTooltip placement="right" content={tooltip} interactive={false}>
            <ListItem
                button
                iconName={icon}
                iconColor={active ? 'primary' : 'secondaryText'}
                disabled={disabled || loading}
                {...props}
            />
        </ListTooltip>
    );
}

const DropdownList = styled(List)`
    min-width: 168px;
`;

const ListTooltip = styled(Tooltip)`
    display: flex;
`;

export default WorkbenchMenuDropDown;
