import React from 'react';
import styled from 'styled-components';

import {
    Image,
    Layout,
    Link as ClickLink,
    colors,
} from 'ui-components';

import gotoUrl from '../../lib/goto-url';

import PageLayout from '../page-layout';

import panoplyLogoPath from '../../images/logo-panoply-horizontal-white.svg';

function gotoHome() {
    gotoUrl('https://panoply.io/');
}

/**
 * @typedef { object } Props
 * @prop { React.ReactNode } children
 * @prop { string } [title]
 * @prop { string } [spacing]
 */

/** @type { React.FC<Props> } */
const PasswordLayout = ({ children, title, ...props }) => (
    <PageLayout title={title} fullbleed>
        <Container flex alignItems="center" justifyContent="center" {...props}>
            <Layout>
                <Logo />

                <ContentLayout spacing="mt-6 p-6">
                    {children}
                </ContentLayout>
            </Layout>
        </Container>
    </PageLayout>
);

function Logo() {
    return (
        <ClickLink component="span" onClick={gotoHome}>
            <Image
                src={panoplyLogoPath}
                width="100"
                height="50px"
                size="contain"
                repeat="no-repeat"
                position="center"
                align="center"
            />
        </ClickLink>
    );
}

const Container = styled(Layout)`
    background-color: ${colors.primary.main};
    min-height: 100%;
`;

const ContentLayout = styled(Layout)`
    width: 420px;
    border-radius: 4px;
    background-color: ${colors.white};
`;

export default PasswordLayout;
