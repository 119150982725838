import React, { useEffect, useRef, useMemo } from 'react';
import { isEqual, cloneDeep } from 'lodash';

import {
    Layout,
    Callout,
} from 'ui-components';

import {
    getParamDepsParams,
} from '../../../models/sources';

/** @typedef { import('models/sources/__types').SourceType } SourceType */
/** @typedef { import('models/sources/__types').ValidationParam } Param */
/** @typedef { import('models/sources/__types').FlattenedParam } FlattenedParam */
/** @typedef { import('models/sources/__types').ValidationParamValue } Value */
/** @typedef { import('models/sources/__types').ValidationParamResponse } Response */

/**
 * @typedef { object } State
 * @prop { boolean= } loading
 * @prop { string= } error
 */

/**
 * @typedef { object } Props
 * @prop { Param } param
 * @prop { State } state
 * @prop { (state: State) => void } setState
 * @prop { (name: Param['name']) => any } getParamValue
 * @prop { () => Promise<Response> } loadError
 * @prop { FlattenedParam[] } flattenedParams
 */

/** @type { React.FC<Props> } */
const ValidationParam = (props) => {
    const {
        param,
        state,
        setState,
        getParamValue,
        loadError,
        flattenedParams,
    } = props;

    const valuesRef = useRef(/** @type { any[] } */ ([]));

    const deps = useMemo(() => {
        return getParamDepsParams(param, flattenedParams);
    }, [param, flattenedParams]);

    const values = useMemo(() => {
        return deps.map(([p]) => getParamValue(p.name));
    }, [deps, getParamValue]);

    useEffect(() => {
        if (isEqual(valuesRef.current, values)) {
            return () => {};
        }

        const load = async () => {
            valuesRef.current = cloneDeep(values);

            try {
                setState({ loading: true, error: '' });

                const { ok, message } = await loadError();

                setState({
                    loading: false,
                    error: !ok ? message : '',
                });
            } catch (/** @type { any } */ e) {
                setState({ loading: false, error: e.message });
            }
        };

        const id = setTimeout(load, 0);

        return () => clearTimeout(id);
    }, [values, state, setState, loadError]);

    if (!state.error) {
        return null;
    }

    return (
        <Layout width="100">
            <Callout color="error" spacing="mb-3">
                {state.error}
            </Callout>
        </Layout>
    );
};

export default ValidationParam;
