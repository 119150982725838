import { useCallback, useMemo } from 'react';

import useSession from '../../context-providers/use-session';

/** @typedef { import('models/sources/__types').CollectConfig } CollectConfig */
/** @typedef { import('./types').CollectConfigParam } CollectConfigParam */
/** @typedef { import('./types').SchemaLockParam } SchemaLockParam */
/** @typedef { import('./types').FieldsParam } FieldsParam */
/** @typedef { import('./types').ConfigName } ConfigName */
/** @typedef { import('./types').ConfigValue } ConfigValue */
/** @typedef { import('./types').PreferMonthFirst } PreferMonthFirst */
/** @typedef { import('./types').LoadStrategy } LoadStrategy */
/** @typedef { import('./types').Structure } Structure */
/** @typedef { import('./types').Fields } Fields */

/** @type { Array<{ value: Structure, label: string }> } */
const globalNestedTypes = [
    {
        value: 'table',
        label: 'Create one-to-many tables',
    },
    {
        value: 'flat',
        label: 'Flatten on parent table',
    },
    {
        value: 'json',
        label: 'Ingest as JSON data type',
    },
];

/** @type { Array<{ value: Structure, label: string }> } */
const fieldNestedTypes = [
    ...globalNestedTypes,
    {
        value: 'pivot',
        label: 'Pivot data in subtable',
    },
];

/** @type { Array<{ value: PreferMonthFirst, label: string }> } */
const fieldDateFormats = [
    {
        value: 'true',
        label: 'Month first',
    },
    {
        value: 'false',
        label: 'Day first',
    },
];

/** @type { Structure } */
const defaultNestedType = 'table';

/** @type { PreferMonthFirst } */
const defaultDateFormat = 'true';

/** @type { { '*': { '*': { structure: Structure } } } } */
const defaultFields = {
    '*': {
        '*': {
            structure: defaultNestedType,
        },
    },
};

/** @type { (cc?: Partial<CollectConfig>) => SchemaLockParam['props'] } */
const getSchemaLockParamProps = (collectConfig) => ({
    indeterminate: collectConfig?.columnLock
        !== collectConfig?.dataTypeLock,
});

/** @type { (cc?: Partial<CollectConfig>) => FieldsParam['props'] } */
const getDateFormatParamProps = (collectConfig) => ({
    configName: 'preferMonthFirst',
    configValue: collectConfig?.fields?.['*']['*'].preferMonthFirst
        || defaultDateFormat,
    description: [
        'Define the date format (month first or day first)',
        'for the date/timestamp values',
    ].join(' '),
    link: 'https://panoply.io/docs/advanced-settings',
    globalGroup: {
        name: 'Date Format',
        label: 'Default Behavior',
        help: [
            'Panoply\'s ingestion engine will use this',
            'configuration to identify dates and ingest',
            'them as relevant data types in Panoply',
        ].join(' '),
        options: fieldDateFormats,
    },
    fieldGroup: {
        name: 'Date Format',
        label: 'Field Configuration',
        help: [
            'Override the default behavior for specific paths.',
            'Configure date fields paths to either identify',
            'them as month or day first',
        ].join(' '),
        options: fieldDateFormats,
    },
});

/** @type { (cc?: Partial<CollectConfig>) => FieldsParam['props'] } */
const getNestedDataParamProps = (collectConfig) => ({
    configName: 'structure',
    configValue: collectConfig?.fields?.['*']['*'].structure
        || defaultNestedType,
    description: [
        'Changing the Nested Data settings will affect',
        'the data model of your Panoply tables',
    ].join(' '),
    link: 'https://panoply.io/docs/advanced-settings',
    globalGroup: {
        name: 'Nested Type',
        label: 'Default Behavior',
        help: [
            'Panoply\'s ingestion engine will use this',
            'configuration to ingest objects as additional tables',
            'or flattened columns on the top-most parent table',
        ].join(' '),
        options: globalNestedTypes,
    },
    fieldGroup: {
        name: 'Nested Type',
        label: 'Field Configuration',
        help: [
            'Override the default behavior for specific paths.',
            'Configure objects\' paths to either create additional',
            'tables or flattened columns on the top-most parent table',
        ].join(' '),
        options: fieldNestedTypes,
    },
});

/**
 * @param { boolean } value
 * @param { Partial<CollectConfig> } [collectConfig]
 * @returns { Partial<CollectConfig> }
 */
const setCollectConfigSchemaLock = (value, collectConfig) => ({
    ...collectConfig,
    columnLock: value,
    dataTypeLock: value,
});

/**
 * @param { LoadStrategy } value
 * @param { Partial<CollectConfig> } [collectConfig]
 * @returns { Partial<CollectConfig> }
 */
const setCollectConfigLoadStrategy = (value, collectConfig) => ({
    ...collectConfig,
    loadStrategy: value,
});

/**
 * @param { Fields } value
 * @param { Partial<CollectConfig> } [collectConfig]
 * @returns { Partial<CollectConfig> }
 */
const setCollectConfigFields = (value, collectConfig) => ({
    ...collectConfig,
    fields: value,
});

/**
 * @param { CollectConfigParam } param
 * @param { Partial<CollectConfig> } collectConfig
 * @returns { Partial<CollectConfig> }
 */
const setCollectConfigParamValue = (param, collectConfig) => {
    if (param.value == null) {
        return collectConfig;
    }

    switch (param.type) {
        case 'schema-lock': {
            return {
                ...collectConfig,
                ...setCollectConfigSchemaLock(param.value),
            };
        }
        case 'fields': {
            return {
                ...collectConfig,
                ...setCollectConfigFields(param.value),
            };
        }
        case 'load-strategy': {
            return {
                ...collectConfig,
                ...setCollectConfigLoadStrategy(param.value),
            };
        }
        default: {
            throw new Error(
                'setCollectConfigParamValue called with unknown param type ' + param['type'],
            );
        }
    }
};

/**
 * @param { Partial<CollectConfig> } [collectConfig]
 * @param { (cc: Partial<CollectConfig>, replace?: boolean) => void } [setCollectConfig]
 */
const useCollectConfig = (collectConfig, setCollectConfig) => {
    const [session] = useSession();
    const { database } = session;

    const showCollectConfig = database.type !== 'redshift';

    /** @type { (value: boolean) => void } */
    const setSchemaLock = useCallback((value) => {
        const newCollectConfig = setCollectConfigSchemaLock(value, collectConfig);
        setCollectConfig?.(newCollectConfig);
    }, [collectConfig, setCollectConfig]);

    /** @type { (value: Fields) => void } */
    const setFields = useCallback((value) => {
        const newCollectConfig = setCollectConfigFields(value, collectConfig);
        setCollectConfig?.(newCollectConfig, true);
    }, [collectConfig, setCollectConfig]);

    /** @type { (value: LoadStrategy) => void } */
    const setLoadStrategy = useCallback((value) => {
        const newCollectConfig = setCollectConfigLoadStrategy(value, collectConfig);
        setCollectConfig?.(newCollectConfig);
    }, [collectConfig, setCollectConfig]);

    /** @type { CollectConfigParam[] } */
    const params = useMemo(() => [
        {
            title: 'Schema Lock',
            name: 'schema-lock',
            type: 'schema-lock',
            value: !!collectConfig?.columnLock && !!collectConfig?.dataTypeLock,
            setValue: setSchemaLock,
            hidden: !showCollectConfig,
            props: getSchemaLockParamProps(collectConfig),
        },
        {
            title: 'Load Strategy',
            name: 'load-strategy',
            type: 'load-strategy',
            value: collectConfig?.loadStrategy,
            setValue: setLoadStrategy,
            hidden: !showCollectConfig,
            props: {},
        },
        {
            title: 'Date Format',
            name: 'date-format',
            type: 'fields',
            value: collectConfig?.fields || defaultFields,
            setValue: setFields,
            hidden: !showCollectConfig,
            props: getDateFormatParamProps(collectConfig),
        },
        {
            title: 'Nested Data',
            name: 'nested-data',
            type: 'fields',
            value: collectConfig?.fields || defaultFields,
            setValue: setFields,
            hidden: !showCollectConfig,
            props: getNestedDataParamProps(collectConfig),
        },
    ], [
        showCollectConfig,
        collectConfig,
        setSchemaLock,
        setLoadStrategy,
        setFields,
    ]);

    return params;
};

export default useCollectConfig;

export { setCollectConfigParamValue };
