import React, { useCallback } from 'react';
import {
    Card,
    Layout,
    Typography,
    Switch,
    Radio,
    RadioGroup,
} from 'ui-components';
import styled from 'styled-components';

/** @typedef { import('./__types').Filters } Filters */

/**
 * @typedef { object } Props
 *
 * @prop { Filters } filters
 * @prop { boolean } isAdminMode
 * @prop { (group: string, filterName: string | string[]) => void } updateFilters
 * @prop { (value: string) => void } updateTimeRange
 * @prop { (value: string) => void } updateTimeField
 */

/** @type { React.FC<Props> } */
const JobsFilters = ({
    filters, isAdminMode, updateFilters, updateTimeRange, updateTimeField,
}) => {
    const onTimeFieldChanged = useCallback((e) => {
        updateTimeField(e.target.value);
    }, [updateTimeField]);

    const onTimeRangeChanged = useCallback((e) => {
        updateTimeRange(e.target.value);
    }, [updateTimeRange]);

    return (
        <Card spacing="mt-5" width="100">
            <Layout flex>
                <Layout width="34" spacing="mr-3">
                    <Typography variant="subtitle1" color="secondaryText">Time</Typography>

                    <RadioGroup
                        value={filters.time.field}
                        onChange={onTimeFieldChanged}
                        row
                    >
                        <Radio value="created_at" label="Created" />
                        <Radio value="ended_at" label="Completed" />
                    </RadioGroup>

                    <Card flat color="interface" contentSpacing="p-1" spacing="mt-3">
                        <RadioGroup
                            spacing="ml-2"
                            value={filters.time.range}
                            onChange={onTimeRangeChanged}
                        >
                            <TimeRadio value="24" label="Last 24 Hours" />
                            <TimeRadio value="72" label="Last 72 Hours" />
                            <TimeRadio value="168" label="Last 7 Days" />
                            <TimeRadio value="" label="All" />
                        </RadioGroup>
                    </Card>

                </Layout>
                <Layout width="33" spacing="mx-3">
                    <Typography variant="subtitle1" color="secondaryText">Type</Typography>

                    <Switch
                        type="checkbox"
                        checked={filters.type.collect}
                        label="Scheduled Collection"
                        onChange={updateFilters('type', 'collect')}
                        spacing="-ml-1"
                    />
                    <br/>
                    <Switch
                        type="checkbox"
                        checked={filters.type['collect-direct']}
                        label="Manual Collection"
                        onChange={updateFilters('type', 'collect-direct')}
                        spacing="-ml-1"
                    />
                    <br/>

                    <Switch
                        type="checkbox"
                        checked={filters.type.materialize}
                        label="Materialization"
                        onChange={updateFilters('type', 'materialize')}
                        spacing="-ml-1"
                    />
                    <br/>
                    <Switch
                        type="checkbox"
                        checked={filters.type.export}
                        label="Export"
                        onChange={updateFilters('type', 'export')}
                        spacing="-ml-1"
                    />
                    <br/>
                    <Switch
                        type="checkbox"
                        checked={filters.type.alter}
                        label="Alter"
                        onChange={updateFilters('type', 'alter')}
                        spacing="-ml-1"
                    />
                    <br/>

                    {isAdminMode && (
                        <>
                            <Switch
                                type="checkbox"
                                checked={filters.type['suspend-database']}
                                label="Suspend Database"
                                onChange={updateFilters('type', 'suspend-database')}
                                spacing="-ml-1"
                            />
                            <br/>
                            <Switch
                                type="checkbox"
                                checked={filters.type['unsuspend-database']}
                                label="Unsuspend Database"
                                onChange={updateFilters('type', 'unsuspend-database')}
                                spacing="-ml-1"
                            />
                            <br/>
                            <Switch
                                type="checkbox"
                                checked={filters.type['reassign-database']}
                                label="Reassign Database"
                                onChange={updateFilters('type', 'reassign-database')}
                                spacing="-ml-1"
                            />
                            <br/>
                            <Switch
                                type="checkbox"
                                checked={filters.type['archive-table']}
                                label="Archive Table"
                                onChange={updateFilters('type', 'archive-table')}
                                spacing="-ml-1"
                            />
                            <br/>
                            <Switch
                                type="checkbox"
                                checked={filters.type['replay-datalogs']}
                                label="Replay Source"
                                onChange={updateFilters('type', 'replay-datalogs')}
                                spacing="-ml-1"
                            />
                            <br/>
                        </>
                    )}

                </Layout>
                <Layout width="33" spacing="ml-3">
                    <Typography variant="subtitle1" color="secondaryText">Status</Typography>

                    <Switch
                        type="checkbox"
                        checked={filters.status.pending}
                        label="Pending"
                        onChange={updateFilters('status', 'pending')}
                        spacing="-ml-1"
                    />
                    <br/>
                    <Switch
                        type="checkbox"
                        checked={filters.status.running}
                        label="Running"
                        onChange={updateFilters('status', ['running', 'booting'])}
                        spacing="-ml-1"
                    />
                    <br/>
                    <Switch
                        type="checkbox"
                        checked={filters.status.success}
                        label="Success"
                        onChange={updateFilters('status', 'success')}
                        spacing="-ml-1"
                    />
                    <br/>
                    <Switch
                        type="checkbox"
                        checked={filters.status.error}
                        label="Failed / Canceled"
                        onChange={updateFilters('status', ['error', 'ext_error'])}
                        spacing="-ml-1"
                    />
                    <br/>
                </Layout>
            </Layout>
        </Card>
    );
};

const TimeRadio = styled(Radio)`
  max-height: 35px;
`;

export default JobsFilters;
