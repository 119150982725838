import React, { useState, useEffect } from 'react';
import {
    Layout, Typography, Loader,
} from 'ui-components';

import { useSourcePageContext } from '../use-source-page/source-page-context';
import JobCardList from '../../../shared/job/job-card-list';
import Banners from '../banners';
import NestedPageWrapper from './nested-page-wrapper';

/** @typedef { import('../../../models/sources/__types').CollectJob } CollectJob */

/**
 * @typedef { object } JobsListProps
 * @prop { CollectJob[]= } jobs
 */

/** @type { React.FC<JobsListProps> } */
const JobsList = ({ jobs }) => {
    if (!jobs) {
        return null;
    }

    if (!jobs.length) {
        return (
            <Layout>
                <Typography variant="subtitle1">
                    You haven&apos;t collected any data from this connector yet.
                </Typography>
                <Typography variant="body2">
                    A list of your job history and statuses will appear here.
                </Typography>
            </Layout>
        );
    }

    return (
        <Layout>
            <JobCardList jobs={jobs} />
        </Layout>
    );
};

const History = () => {

    const {
        source,
        loadHistory,
        historyLoading,
        historyError,
        historyJobs,
    } = useSourcePageContext();

    const [loads, setLoads] = useState(0); // to force rerender

    useEffect(() => {
        loadHistory().then(() => setLoads(l => l + 1));
    }, [source]);

    if (historyLoading && !loads) {
        return (
            <Layout spacing="p-7">
                <Loader big active message="Loading Job History" />
            </Layout>
        );
    }

    return (
        <NestedPageWrapper maxWidth>
            <Typography variant="h6" spacing="m-0 py-2">Collection History</Typography>

            <Banners />

            <Layout spacing="my-5">
                {!historyError && (
                    <JobsList jobs={historyJobs || []} />
                )}

                {historyError && (
                    <>
                        <Typography variant="subtitle1">
                            We encountered an error while loading your source history
                        </Typography>

                        <Typography variant="body2" color="error">
                            {historyError}
                        </Typography>
                    </>
                )}
            </Layout>
        </NestedPageWrapper>
    );
};

export default History;
