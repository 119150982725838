import React, { useState, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { isPlainObject } from 'lodash';
import { transparentize } from 'polished';

import {
    Dialog,
    Divider,
    Button,
    Layout,
    Typography,
    Tooltip,
    Icon,
    Autocomplete,
    List,
    ListItem,
    Dropdown,
    ToggleButton,
    spacings,
    colors,
} from 'ui-components';

import {
    isNumericColumn,
    isStringColumn,
    isDateColumn,
    isTimeColumn,
    isDatetimeColumn,
    isBooleanColumn,
} from '../../models/columns';

import useJoinTable from './use-join-table';
import useJoinColumns, { findTableOption } from './use-join-columns';
import useDraggable, { onDragStart, onDragOver } from './use-draggable';
import JoinTypeSelectorDropdown from './join-type-selector-dropdown';
import JoinIcon from './join-icon';

/** @typedef { import('lib/query-builder').Logic } Logic */
/** @typedef { import('lib/query-builder').ComparisonOperator } ComparisonOperator */
/** @typedef { import('lib/query-builder').JoinType } JoinType */
/** @typedef { import('lib/query-builder').MainTable } MainTable */
/** @typedef { import('lib/query-builder').JoinTable } JoinTable */
/** @typedef { import('lib/query-builder').JoinColumn } JoinColumn */
/** @typedef { import('lib/query-builder').Column } Column */
/** @typedef { import('lib/query-builder').Tables } Tables */
/** @typedef { import('lib/query-builder').Join } Join */
/** @typedef { import('models/columns').ColumnItem } ColumnItem */
/** @typedef { import('./__types').TableData } TableData */
/** @typedef { import('./__types').ViewData } ViewData */
/** @typedef { Required<Pick<TableData, 'name' | 'schema'>> } Table */
/** @typedef { Option | string } OptionType */
/** @typedef { OptionType | OptionType[] | null } Value */
/** @typedef { 'error' | 'success' | '' } Status */
/** @typedef { { data?: Partial<Filter> } } Item */
/** @typedef { 'date' | 'time' | 'datetime' } DateType */
/** @typedef { 'text' | 'number' | 'boolean' } TextType */
/** @typedef { TextType | DateType } ValueType */
/** @typedef { ComparisonOperator } Operator */
/** @typedef { number } Index */

/**
 * @typedef { object } Option
 * @prop { string } title
 * @prop { string } [subtitle]
 * @prop { string } [category]
 * @prop { string } [tooltip]
 * @prop { TableData | ViewData } [table]
 * @prop { ColumnItem } [column]
 * @prop { boolean } [disabled]
 * @prop { boolean } [error]
 */

/**
 * @typedef { import('lib/query-builder/types').Filter<JoinColumn, Operator, JoinColumn> } Filter
 */

/**
 * @typedef { React.Dispatch<React.SetStateAction<T>> } SetItems
 * @template [T=Item[]]
 */

/** @type { JoinType } */
const defaultJoinType = 'inner';

/** @type { Operator } */
const defaultOperator = '=';

/**
 * @typedef { object } OperatorConfig
 * @prop { string } label
 * @prop { string } icon
 * @prop { ValueType[] } types
 * @prop { boolean } [disabled]
 */

/** @typedef { Record<Operator, OperatorConfig> } Operators */

/** @type { Operators } */
const allOperators = {
    '=': {
        label: 'Equals',
        icon: 'equals',
        types: ['text', 'number', 'date', 'time', 'datetime', 'boolean'],
    },
    '!=': {
        label: 'Not equals',
        icon: 'not-equal',
        types: ['text', 'number', 'date', 'time', 'datetime', 'boolean'],
    },
    '>': {
        label: 'Greater than',
        icon: 'greater-than',
        types: ['text', 'number', 'date', 'time', 'datetime'],
    },
    '>=': {
        label: 'Greater than or equal',
        icon: 'greater-than-equal',
        types: ['text', 'number', 'date', 'time', 'datetime'],
    },
    '<': {
        label: 'Less than',
        icon: 'less-than',
        types: ['text', 'number', 'date', 'time', 'datetime'],
    },
    '<=': {
        label: 'Less than or equal',
        icon: 'less-than-equal',
        types: ['text', 'number', 'date', 'time', 'datetime'],
    },
};

/**
 * @param { JoinColumn } column1
 * @param { ColumnItem } [column2]
 * @return { boolean }
 */
function isSameColumn(column1, column2) {
    if (!column2) {
        return false;
    }

    return column1.name === column2.name
        && column1.table === column2.table
        && column1.schema === column2.schema;
}

/**
 * @param { Table } table1
 * @param { MainTable | JoinTable } [table2]
 * @return { boolean }
 */
function isSameTable(table1, table2) {
    if (!table2) {
        return false;
    }

    return table1.name === table2.name
        && table1.schema === table2.schema;
}

/**
 * @param { Filter[] } filters
 * @returns { Item[] }
 */
function normalizeFilters(filters) {
    const items = filters.map(data => {
        return /** @type { Item } */ ({ data });
    });

    return items.length ? items : [{}];
}

/** @type { (option: Option | null) => ValueType } */
function resolveValueType(option) {
    const { column } = option || {};

    if (column?.id) {
        switch (true) {
            case isStringColumn(column.dataType):
                return 'text';

            case isNumericColumn(column.dataType):
                return 'number';

            case isDateColumn(column.dataType):
                return 'date';

            case isTimeColumn(column.dataType):
                return 'time';

            case isDatetimeColumn(column.dataType):
                return 'datetime';

            case isBooleanColumn(column.dataType):
                return 'boolean';
        }
    }

    return 'text';
}

/**
 * Determine the index of the first open parenthesis
 *
 * @type { (items: Item[], index: number) => number }
 */
function findOpen(items, index) {
    let j = 0;

    for (let i = items.length - 1; i > index; i--) {
        const { open = 0, close = 0 } = items[i].data || {};
        j -= close;
        j += open;
    }

    for (let i = index; i >= 0; i--) {
        if (!items.length || (i === 0 && index === items.length - 1)) {
            break;
        }

        const { open = 0, close = 0 } = items[i].data || {};
        j -= close;
        j += open;

        if (j > 0) {
            return i;
        }
    }

    return index !== items.length - 1 ? 0 : 1;
}

/**
 * Determine the index of the first close parenthesis
 *
 * @type { (items: Item[], index: number) => number }
 */
function findClose(items, index) {
    let j = 0;

    for (let i = 0; i < index; i++) {
        const { open = 0, close = 0 } = items[i].data || {};
        j -= open;
        j += close;
    }

    for (let i = index; i < items.length; i++) {
        if (i === items.length - 1 && index === 0) {
            break;
        }

        const { open = 0, close = 0 } = items[i].data || {};
        j -= open;
        j += close;

        if (j > 0) {
            return i;
        }
    }

    return index !== 0 ? items.length - 1 : items.length - 2;
}

/**
 * Count the number of open parenthesis
 *
 * @type { (items: Item[]) => number }
 */
function countOpen(items) {
    return items.reduce((acc, item) => {
        const count = item.data?.open || 0;
        return acc + count;
    }, 0);
}

/**
 * Count the number of close parenthesis
 *
 * @type { (items: Item[]) => number }
 */
function countClose(items) {
    return items.reduce((acc, item) => {
        const count = item.data?.close || 0;
        return acc + count;
    }, 0);
}

/**
 * Calculate the nesting level of the current item
 *
 * @type { (items: Item[], index: number) => number }
 */
function calculateNesting(items, index) {
    let nesting = 0;

    for (let i = 0; i < index; i++) {
        const { open = 0, close = 0 } = items[i].data || {};
        nesting += open;
        nesting -= close;
    }

    return nesting > -1 ? nesting : 0;
}

/** @type { (items: Item[]) => Item[] } */
function filterItems(items) {
    return items.filter(item => Object.keys(item).length);
}

/** @type { (items: Item[]) => items is Array<{ data: Filter }> } */
function validateItems(items) {
    return items.length > 0
        && items.every(validateValue)
        && validateParens(items);
}

/** @type { (column?: JoinColumn) => boolean } */
function validateTable(column) {
    if (!column?.table || !column?.schema) {
        return false;
    }

    return true;
}

/** @type { (column?: JoinColumn) => boolean } */
function validateColumn(column) {
    if (!column?.name || !validateTable(column)) {
        return false;
    }

    return true;
}

/** @type { (item: Item) => boolean } */
function validateField(item) {
    const { data } = item;

    if (!validateColumn(data?.column) || !data?.operator) {
        return false;
    }

    return true;

}

/** @type { (item: Item) => boolean } */
function validateValue(item) {
    const { data } = item;

    if (!validateColumn(data?.value) || !validateField(item)) {
        return false;
    }

    return true;
}

/** @type { (items: Item[]) => boolean } */
function validateParens(items) {
    const openTotal = countOpen(items);
    const closeTotal = countClose(items);

    if (openTotal !== closeTotal) {
        return false;
    }

    for (let i = 0; i < items.length; i++) {
        let j = 0;

        for (let k = i; k < items.length; k++) {
            const { open = 0, close = 0 } = items[k].data || {};

            j -= close;
            j += open;
        }

        if (j > 0) {
            return false;
        }
    }

    return true;
}

/** @type { <T extends MainTable | JoinTable>(table?: T) => table is T } */
function filterTable(table) {
    return table != null;
}

/** @type { (table: TableData | ViewData) => table is Table } */
function isTable(table) {
    return table.name != null && table.schema != null;
}

/** @type { <T extends Table>(a: T, b: T) => number } */
function sortByName(a, b) {
    return a.name.localeCompare(b.name);
}

/** @type { <T extends Table>(a: T, b: T) => number } */
function sortBySchema(a, b) {
    return a.schema.localeCompare(b.schema);
}

/** @type { (value: Value) => value is Option } */
function isOptionObject(value) {
    return isPlainObject(value);
}

/** @type { (option: OptionType | null) => string } */
function getOptionLabel(option) {
    if (isOptionObject(option)) {
        return option.title || '';
    }

    return option || '';
}

/**
 * @typedef { object } Props
 * @prop { boolean } open
 * @prop { () => void } onClose
 * @prop { TableData[] } tables
 * @prop { ViewData[] } views
 * @prop { Column[] } allColumns
 * @prop { Tables } allTables
 * @prop { JoinTable } [joinTable]
 * @prop { MainTable } [mainTable]
 * @prop { number } [position]
 * @prop { (params: Omit<JoinTable, 'alias'>) => void } addJoinTable
 * @prop { (position: number, join: Join) => void } changeJoinTable
 * @prop { (position: number) => void } removeJoinTable
 */

/** @type { React.FC<Props> } */
const EditJoinDialog = ({
    open,
    onClose,
    tables,
    views,
    allColumns,
    allTables,
    joinTable,
    mainTable,
    position,
    addJoinTable,
    changeJoinTable,
    removeJoinTable,
}) => {
    const [type, setType] = useState(
        joinTable?.join.type || defaultJoinType
    );

    const [table, setTable] = useState(
        /** @type { Table= } */ (joinTable && {
            name: joinTable.name,
            schema: joinTable.schema,
        })
    );

    const [status, setStatus] = useState(
        /** @type { Status } */ ('')
    );

    const { noFields } = useJoinTable({ allColumns, allTables, joinTable, position });

    const availableTables = useMemo(() => {
        return allTables.slice(0, (position || 0) + 2).reverse()
            .filter(filterTable);
    }, [allTables, position]);

    const fieldTables = useMemo(() => {
        if (!table || availableTables.some(t => isSameTable(table, t))) {
            return availableTables;
        }

        const fieldTables = [...availableTables];
        fieldTables.push({ ...table, alias: '' });

        return fieldTables;
    }, [availableTables, table]);

    const valueTables = useMemo(() => {
        if (!table || availableTables.some(t => isSameTable(table, t))) {
            return availableTables;
        }

        const valueTables = [...availableTables];
        valueTables.unshift({ ...table, alias: '' });

        return valueTables;
    }, [availableTables, table]);

    const joinTableOptions = useMemo(() => {
        /** @type { Option[] } */
        const options = [];

        return options.concat((/** @type { (TableData | ViewData)[] } */ ([]))
            .concat(tables, views)
            .filter(isTable)
            .sort(sortBySchema)
            .sort(sortByName)
            .reduce((acc, table) => {
                const title = [table.schema, table.name].join('.');

                return acc.concat({
                    title,
                    tooltip: title,
                    category: 'Tables',
                    disabled: allTables.some(t => isSameTable(table, t)),
                    table,
                });
            }, /** @type { Option[] } */ ([])));
    }, [tables, views, allTables]);

    const fieldTableOptions = useMemo(() => {
        return fieldTables.map(table => {
            const option = findTableOption(table, joinTableOptions);
            const title = [table.schema, table.name].join('.');

            /** @type { Option } */
            const newOption = {
                ...option,
                title,
                tooltip: title,
                category: 'Available tables',
                disabled: false,
            };

            return newOption;
        });
    }, [fieldTables, joinTableOptions]);

    const valueTableOptions = useMemo(() => {
        return valueTables.map(table => {
            const option = findTableOption(table, joinTableOptions);
            const title = [table.schema, table.name].join('.');

            /** @type { Option } */
            const newOption = {
                ...option,
                title,
                tooltip: title,
                category: 'Available tables',
                disabled: false,
            };

            return newOption;
        });
    }, [valueTables, joinTableOptions]);

    /** @type { (items: Item[], table?: Table) => Item } */
    const normalizeFirstItem = (items, table) => {
        /** @type { Item } */
        const item = { ...items[0] };

        if (table) {
            const option = findTableOption(table, valueTableOptions);

            if (option) {
                item.data = {
                    ...item.data,
                    value: {
                        name: '',
                        schema: table.schema,
                        table: table.name,
                        ...item.data?.value,
                    },
                };
            }
        }

        if (mainTable) {
            const option = findTableOption(mainTable, fieldTableOptions);

            if (option) {
                item.data = {
                    ...item.data,
                    column: {
                        name: '',
                        schema: mainTable.schema,
                        table: mainTable.name,
                        ...item.data?.column,
                    },
                };
            }
        }

        return item;
    };

    const [items, setItems] = useState(() => {
        const filters = joinTable?.join.on || [];
        const items = normalizeFilters(filters);
        items[0] = normalizeFirstItem(items, table);
        return items;
    });

    const {
        getClassName,
        onDragEnter,
        onDragLeave,
        onDrop,
    } = useDraggable({ setItems });

    /** @type { (index: number, item: Item) => void } */
    const updateField = (index, item) => {
        const { data } = item;

        setItems(items => {
            const newItems = [...items];
            newItems[index] = { data };
            return newItems;
        });

        setStatus('');
    };

    /** @type { (index: number) => void } */
    const addField = (index) => {
        setItems(items => {
            const newItems = [...items];
            newItems.splice(index, 0, {});
            return newItems;
        });

        setStatus('');
    };

    /** @type { (index: number) => void } */
    const removeField = (index) => {
        setItems(items => {
            const newItems = [...items];
            newItems.splice(index, 1);
            return newItems;
        });

        setStatus('');
    };

    /** @type { (newType: JoinType) => void } */
    const changeType = (newType) => {
        setType(newType);
    };

    /** @type { (newTable: Table) => void } */
    const changeTable = (newTable) => {
        setTable(newTable);
    };

    /** @type { () => void } */
    const clearTable = () => {
        setTable(undefined);
        setItems([{}]);
        setStatus('');
    };

    const onSave = useCallback(() => {
        setStatus('');

        const filteredItems = filterItems(items);

        if (!validateItems(filteredItems) || !table) {
            setStatus('error');
            return;
        }

        setStatus('success');

        const join = { type, on: filteredItems.map(item => item.data) };

        if (position == null) {
            addJoinTable({ ...table, join });
        } else {
            changeJoinTable(position, join);
        }

        onClose();
    }, [
        type,
        table,
        items,
        position,
        addJoinTable,
        changeJoinTable,
        onClose,
    ]);

    const onRemove = () => {
        if (!noFields || position == null) {
            return;
        }

        removeJoinTable(position);

        onClose();
    };

    useEffect(() => {
        updateField(0, normalizeFirstItem(items, table));
    }, [table]);

    useEffect(() => {
        if (!open) {
            return () => {};
        }

        /** @type { (e: KeyboardEvent) => void } */
        const onKeyDown = e => {
            const activeTag = document.activeElement?.tagName.toLowerCase();

            if (e.key === 'Escape') {
                e.preventDefault();
                onClose();
            } else if (e.key === 'Enter' && activeTag !== 'input') {
                e.preventDefault();
                onSave();
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    }, [open, onClose, onSave]);

    const actions = [
        <Button
            key="cancel"
            type="plain"
            spacing="ml-0"
            onClick={onClose}
        >
            Cancel
        </Button>,
        <Button
            key="save"
            color="primary"
            spacing="mr-0"
            onClick={onSave}
        >
            JOIN
        </Button>,
    ];

    if (noFields && position != null) {
        actions.unshift(
            <Button
                key="remove"
                type="plain"
                spacing="ml-0"
                leftIcon="trash"
                onClick={onRemove}
            >
                Remove JOIN
            </Button>
        );
    }

    switch (status) {
        case 'error':
            actions.unshift(<Error key="error" />);
            break;

        case 'success':
            actions.unshift(<Success key="success" />);
            break;
    }

    const bodyRowProps = {
        fieldTableOptions,
        valueTableOptions,
        items,
        status,
        draggable: items.length > 1,
        updateField,
        addField,
        removeField,
        onDragEnter,
        onDragLeave,
        onDrop,
    };

    return (
        <Dialog
            big={!!table}
            title="JOIN"
            isOpen={open}
            onClose={onClose}
            actions={actions}
        >
            <Wrapper>
                <Divider spacing="-mx-4 mt-0 mb-5" />

                <JoinBar
                    type={type}
                    table={table}
                    mainTable={mainTable}
                    position={position}
                    joinTableOptions={joinTableOptions}
                    changeType={changeType}
                    changeTable={changeTable}
                    clearTable={clearTable}
                    spacing="-mx-4 -my-5 p-4"
                />

                {table && (
                    <>
                        {noFields && joinTable && (
                            <>
                                <Divider spacing="-mx-4 mt-5 mb-0" />

                                <Layout
                                    bgColor={transparentize(0.8, colors.warning.main)}
                                    spacing="-mx-4 mt-0 -mb-5 p-4"
                                >
                                    <Warning variant="body2" spacing="mb-0" align="center">
                                        No fields from
                                        &quot;{joinTable.schema}.{joinTable.name}&quot;
                                        were found in your query
                                    </Warning>
                                </Layout>
                            </>
                        )}

                        <Divider spacing="-mx-4 my-5" />

                        <HeaderRow />

                        {(items.length ? items : [{}]).map((item, index) => (
                            <BodyRow
                                key={`item-${index}_${item.data?.column?.name || 'empty'}`}
                                className={getClassName(index)}
                                item={item}
                                index={index}
                                {...bodyRowProps}
                            />
                        ))}
                    </>
                )}
            </Wrapper>
        </Dialog>
    );
};

/**
 * @typedef { object } JoinBarProps
 * @prop { JoinType } type
 * @prop { Table } [table]
 * @prop { MainTable } [mainTable]
 * @prop { number } [position]
 * @prop { Option[] } joinTableOptions
 * @prop { (newType: JoinType) => void } changeType
 * @prop { (newTable: Table) => void } changeTable
 * @prop { () => void } clearTable
 * @prop { string } [spacing]
 */

/** @type { React.FC<JoinBarProps> } */
const JoinBar = ({
    type,
    table,
    mainTable,
    position,
    joinTableOptions,
    changeType,
    changeTable,
    clearTable,
    ...props
}) => {
    /**
     * @type { (
     *     event: React.ChangeEvent<HTMLInputElement | HTMLDivElement>,
     *     value: Value
     * ) => void }
     */
    const onChange = (e, newValue) => {
        e.preventDefault();
        e.stopPropagation();

        if (!isOptionObject(newValue)) {
            return;
        }

        const [schema, name] = newValue.title.split('.');

        changeTable({ name, schema });
    };

    /** @type { () => void } */
    const onDelete = () => {
        clearTable();
    };

    return (
        <Layout
            flex
            alignItems="center"
            justifyContent="center"
            bgColor={colors.secondaryBackground}
            {...props}
        >
            {mainTable && (
                <Typography
                    variant="body1"
                    color="text"
                    weight="medium"
                    spacing="mb-0 mr-4"
                >
                    {mainTable.schema}.{mainTable.name}
                </Typography>
            )}

            <Layout>
                <JoinIcon type={type} />
            </Layout>

            <Layout>
                <JoinTypeSelectorDropdown
                    type={type}
                    changeType={changeType}
                    spacing="mx-2 px-2"
                />
            </Layout>

            {table ? (
                <Layout flex alignItems="center">
                    <Typography
                        variant="body1"
                        color="text"
                        weight="medium"
                        spacing="mb-0"
                    >
                        {table.schema}.{table.name}
                    </Typography>

                    {position == null && (
                        <Tooltip
                            className="tooltip-wrap"
                            content="Clear table"
                            interactive={false}
                            placement="top"
                        >
                            <Button
                                square
                                type="plain"
                                spacing="m-0 ml-2 p-2"
                                onClick={onDelete}
                            >
                                <Icon icon="trash" />
                            </Button>
                        </Tooltip>
                    )}
                </Layout>
            ) : (
                <Layout>
                    <TableSelector
                        label="Select a table"
                        type="text"
                        width="100"
                        spacing="p-0"
                        options={joinTableOptions}
                        onChange={onChange}
                        getOptionSearchableString={getOptionLabel}
                        multiple={false}
                    />
                </Layout>
            )}
        </Layout>
    );
};

/** @type { React.FC } */
const HeaderRow = () => (
    <FlexLayout>
        <FlexLayout spacing="mr-4">
            <Dragger color="white" />
        </FlexLayout>

        <FlexLayout width="47">
            <FlexLayout width="47">
                <Label>
                    Table
                </Label>
            </FlexLayout>

            <FlexLayout grow="1">
                <Label>
                    Field
                </Label>
            </FlexLayout>
        </FlexLayout>

        <FlexLayout grow="1">
            <FlexLayout width="45">
                <Label>
                    Table
                </Label>
            </FlexLayout>

            <FlexLayout grow="1">
                <Label>
                    Field
                </Label>
            </FlexLayout>
        </FlexLayout>
    </FlexLayout>
);

/**
 * @typedef { object } BodyRowProps
 * @prop { string } className
 * @prop { Item } item
 * @prop { number } index
 * @prop { Item[] } items
 * @prop { Option[] } fieldTableOptions
 * @prop { Option[] } valueTableOptions
 * @prop { Status } status
 * @prop { boolean } draggable
 * @prop { (index: number, item: Item) => void } updateField
 * @prop { (index: number) => void } addField
 * @prop { (index: number) => void } removeField
 * @prop { (e: React.DragEvent<HTMLDivElement>, index: number) => void } onDragEnter
 * @prop { (e: React.DragEvent<HTMLDivElement>, index: number) => void } onDragLeave
 * @prop { (e: React.DragEvent<HTMLDivElement>, to: Index) => void } onDrop
 */

/** @type { React.FC<BodyRowProps> } */
const BodyRow = ({
    className,
    item,
    index,
    fieldTableOptions,
    valueTableOptions,
    items,
    status,
    draggable,
    updateField,
    addField,
    removeField,
    onDragEnter,
    onDragLeave,
    onDrop,
}) => {
    const { options: fieldColumnOptions } = useJoinColumns(
        fieldTableOptions,
        item.data?.column
    );

    const { options: valueColumnOptions } = useJoinColumns(
        valueTableOptions,
        item.data?.value
    );

    /** @type { Option | null } */
    const fieldColumnOption = useMemo(() => {
        const column = item.data?.column;

        if (column?.name) {
            const option = fieldColumnOptions.find(o => o.title === column.name);

            if (option) {
                return option;
            }
        }

        return null;
    }, [item, fieldColumnOptions]);

    /** @type { Option | null } */
    const valueColumnOption = useMemo(() => {
        const value = item.data?.value;

        if (value?.name) {
            const option = valueColumnOptions.find(o => o.title === value.name);

            if (option) {
                return option;
            }
        }

        return null;
    }, [item, valueColumnOptions]);

    const fieldColumnType = useMemo(() => {
        return resolveValueType(fieldColumnOption);
    }, [fieldColumnOption]);

    const valueColumnType = useMemo(() => {
        return resolveValueType(valueColumnOption);
    }, [valueColumnOption]);

    const operators = useMemo(() => {
        return Object.entries(allOperators).reduce((acc, [key, value]) => {
            if (value.types.includes(fieldColumnType)) {
                return { ...acc, [key]: value };
            }

            return acc;
        }, /** @type { Operators } */ ({}));
    }, [fieldColumnType]);

    const nesting = useMemo(() => {
        return calculateNesting(items, index);
    }, [items, index]);

    /** @type { (operator: Operator) => void } */
    const updateOperator = (operator) => {
        updateField(index, { ...item, data: { ...item.data, operator } });
    };

    useEffect(() => {
        if (!Object.keys(item).length) {
            return;
        }

        const operator = item.data?.operator;

        if (!operator || !operators[operator] || operators[operator]?.disabled) {
            const keys = /** @type { Operator[] } */ (Object.keys(operators));
            const filteredOperators = keys.filter(key => !operators[key]?.disabled);

            const newOperator = filteredOperators[0] || defaultOperator;
            updateOperator(newOperator);
        }
    }, [operators]);

    const operator = item.data?.operator || defaultOperator;

    return (
        <Draggable
            className={className}
            {...draggable && {
                draggable,
                onDrop: e => onDrop(e, index),
                onDragStart: e => onDragStart(e, index),
                onDragEnter: e => onDragEnter(e, index),
                onDragLeave: e => onDragLeave(e, index),
                onDragOver,
            }}
        >
            <FlexLayout>
                <FlexLayout spacing={'mr-4' + '+4'.repeat(nesting)}>
                    <Dragger color={draggable ? 'interface' : 'secondaryInterface'} />
                </FlexLayout>

                <FlexLayout width="47">
                    <FlexLayout width="47">
                        <Logic
                            item={item}
                            index={index}
                            updateField={updateField}
                        />

                        <Open
                            item={item}
                            index={index}
                            items={items}
                            status={status}
                            updateField={updateField}
                        />

                        <FieldTable
                            item={item}
                            index={index}
                            operator={operator}
                            fieldTableOptions={fieldTableOptions}
                            status={status}
                            updateField={updateField}
                        />
                    </FlexLayout>

                    <FlexLayout grow="1">
                        <Field
                            item={item}
                            index={index}
                            operator={operator}
                            fieldColumnOptions={fieldColumnOptions}
                            fieldColumnOption={fieldColumnOption}
                            valueColumnType={valueColumnType}
                            status={status}
                            updateField={updateField}
                        />

                        <Operator
                            operators={operators}
                            operator={operator}
                            updateOperator={updateOperator}
                        />
                    </FlexLayout>
                </FlexLayout>

                <FlexLayout grow="1">
                    <FlexLayout width="45">
                        <ValueTable
                            item={item}
                            index={index}
                            valueTableOptions={valueTableOptions}
                            status={status}
                            updateField={updateField}
                        />
                    </FlexLayout>

                    <FlexLayout grow="1">
                        <Value
                            item={item}
                            index={index}
                            status={status}
                            valueColumnOptions={valueColumnOptions}
                            valueColumnOption={valueColumnOption}
                            fieldColumnType={fieldColumnType}
                            updateField={updateField}
                        />

                        <Add
                            item={item}
                            index={index}
                            addField={addField}
                        />

                        <Remove
                            item={item}
                            index={index}
                            items={items}
                            removeField={removeField}
                        />

                        <Close
                            item={item}
                            index={index}
                            items={items}
                            status={status}
                            updateField={updateField}
                        />
                    </FlexLayout>
                </FlexLayout>
            </FlexLayout>
        </Draggable>
    );
};

/** @type { React.FC<{ color?: string }> } */
const Dragger = ({ color }) => (
    <Icon icon="equals" size="sm" color={color} />
);

/**
 * @typedef { object } LogicProps
 * @prop { Item } item
 * @prop { number } index
 * @prop { (index: number, item: Item) => void } updateField
 */

/** @type { React.FC<LogicProps> } */
const Logic = ({ item, index, updateField }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(open => !open);
    };

    /** @type { (logic: Logic) => void } */
    const updateLogic = (logic) => {
        updateField(index, { ...item, data: { ...item.data, logic } });
        setDropdownOpen(false);
    };

    if (index === 0) {
        return null;
    }

    const logic = item.data?.logic || 'and';

    return (
        <FlexLayout>

            <Dropdown
                opener={
                    <Button
                        square
                        type="plain"
                        onClick={toggleDropdown}
                        spacing="m-0 px-2 py-2 mr-2"
                    >
                        {logic.toUpperCase()}
                    </Button>
                }
                open={dropdownOpen}
                onClose={toggleDropdown}
            >
                <List>
                    {/** @type { Logic[] } */ (['and', 'or']).map(value => (
                        <ListItem
                            key={value}
                            selected={value === logic}
                            disabled={value === logic}
                            onClick={() => updateLogic(value)}
                            button
                        >
                            <Text>
                                {value.toUpperCase()}
                            </Text>
                        </ListItem>
                    ))}
                </List>
            </Dropdown>
        </FlexLayout>
    );
};

/**
 * @typedef { object } OperatorProps
 * @prop { Operators } operators
 * @prop { Operator } operator
 * @prop { (operator: Operator) => void } updateOperator
 */

/** @type { React.FC<OperatorProps> } */
const Operator = ({ operators, operator, updateOperator }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(open => !open);
    };

    /** @type { (operator: Operator) => void } */
    const changeOperator = (operator) => {
        updateOperator(operator);
        setDropdownOpen(false);
    };

    const { label, icon } = allOperators[operator];

    return (
        <FlexLayout>
            <Dropdown
                opener={
                    <Button
                        square
                        type="plain"
                        onClick={toggleDropdown}
                        spacing="m-0 px-2 py-2 mr-2"
                    >
                        {icon ? <Icon icon={icon} /> : label?.toUpperCase()}
                    </Button>
                }
                open={dropdownOpen}
                onClose={toggleDropdown}
            >
                <List>
                    {Object.entries(operators).map(([key, { label, icon, disabled }]) => (
                        <ListItem
                            key={key}
                            selected={key === operator}
                            disabled={key === operator || disabled}
                            onClick={() => changeOperator(/** @type { Operator } */ (key))}
                            button
                        >
                            <Text>
                                {icon ? <Icon icon={icon} /> : label?.toUpperCase()}
                            </Text>
                        </ListItem>
                    ))}
                </List>
            </Dropdown>
        </FlexLayout>
    );
};

/**
 * @typedef { object } OpenProps
 * @prop { Item } item
 * @prop { number } index
 * @prop { Status } status
 * @prop { Item[] } items
 * @prop { (index: number, item: Item) => void } updateField
 */

/** @type { React.FC<OpenProps> } */
const Open = ({ item, index, status, items, updateField }) => {
    const { open = 0 } = item.data || {};

    /** @type { (i: number) => void } */
    const updateOpen = i => {
        const newOpen = i === open ? open + 1 : open - 1;
        updateField(index, { ...item, data: { ...item.data, open: newOpen } });
    };

    const canOpen = useMemo(() => {
        const endIndex = findClose(items, index);
        const slicedItems = items.slice(index, endIndex + 1);

        const openCount = countOpen(slicedItems);
        const closeCount = countClose(slicedItems);

        const openTotal = countOpen(items);
        const closeTotal = countClose(items);

        const currentItem = items[index];

        const canOpen = slicedItems.length > openCount + 1
            && openCount === closeCount
            && openTotal === closeTotal
            && !currentItem.data?.close;

        return canOpen;
    }, [index, items]);

    const error = useMemo(() => {
        return status === 'error' && !validateParens(items);
    }, [status, items]);

    const num = canOpen ? open + 1 : open;

    return (
        <FlexLayout spacing={num ? 'mr-2' : ''}>
            {new Array(num).fill(0).map((_, i) => (
                <ToggleButton
                    key={`open-${i}`}
                    square
                    type="plain"
                    active={error}
                    color={error ? 'error' : 'default'}
                    onClick={() => updateOpen(i)}
                    spacing="m-0 px-0 py-3"
                >
                    <Icon
                        icon="bracket-round"
                        color={(i < open || !canOpen
                            ? undefined
                            : 'secondaryInterface'
                        )}
                    />
                </ToggleButton>
            )).reverse()}
        </FlexLayout>
    );
};

/** @typedef { OpenProps } CloseProps */

/** @type { React.FC<CloseProps> } */
const Close = ({ item, index, status, items, updateField }) => {
    const { close = 0 } = item.data || {};

    /** @type { (i: number) => void } */
    const updateClose = (i) => {
        const newClose = i === close ? close + 1 : close - 1;
        updateField(index, { ...item, data: { ...item.data, close: newClose } });
    };

    const canClose = useMemo(() => {
        const startIndex = findOpen(items, index);
        const slicedItems = items.slice(startIndex, index + 1);

        const openCount = countOpen(slicedItems);
        const closeCount = countClose(slicedItems);

        const openTotal = countOpen(items);
        const closeTotal = countClose(items);

        const firstCount = countOpen(slicedItems.slice(0, -1));
        const lastCount = countClose(slicedItems.slice(-1));

        const currentItem = items[index];

        const canClose = slicedItems.length > closeCount + 1
            && openCount > closeCount
            && openTotal > closeTotal
            && (firstCount > lastCount || !lastCount)
            && !currentItem.data?.open;

        return canClose;
    }, [index, items]);

    const error = useMemo(() => {
        return status === 'error' && !validateParens(items);
    }, [status, items]);

    const num = canClose ? close + 1 : close;

    return (
        <FlexLayout>
            {new Array(num).fill(0).map((_, i) => (
                <ToggleButton
                    key={`close-${i}`}
                    square
                    type="plain"
                    active={error}
                    color={error ? 'error' : 'default'}
                    onClick={() => updateClose(i)}
                    spacing="m-0 px-0 py-3"
                >
                    <Icon
                        icon="bracket-round-right"
                        color={(i < close
                            ? undefined
                            : 'secondaryInterface'
                        )}
                    />
                </ToggleButton>
            ))}
        </FlexLayout>
    );
};

/**
 * @typedef { object } FieldTableProps
 * @prop { Item } item
 * @prop { number } index
 * @prop { Operator } operator
 * @prop { Option[] } fieldTableOptions
 * @prop { Status } status
 * @prop { (index: number, item: Item) => void } updateField
 */

/** @type { React.FC<FieldTableProps> } */
const FieldTable = ({
    item,
    index,
    operator,
    fieldTableOptions,
    status,
    updateField,
}) => {
    const [open, setOpen] = useState(false);

    /**
     * @type { (
     *     e: React.ChangeEvent<HTMLInputElement | HTMLDivElement>,
     *     newValue: Value
     * ) => void }
     */
    const onChangeField = (e, newValue) => {
        if (isOptionObject(newValue)) {
            const [schema, name] = newValue.title.split('.');
            const table = { name, schema };

            const option = findTableOption(table, fieldTableOptions);

            if (option) {
                const data = {
                    ...item.data,
                    column: {
                        name: '',
                        schema,
                        table: name,
                    },
                    operator,
                };

                updateField(index, { ...item, data });
            }
        } else if (newValue === null) {
            const data = { ...item.data, column: undefined };

            updateField(index, { ...item, data });
        }
    };

    /** @type { (option: OptionType) => boolean } */
    const getOptionDisabled = (option) => {
        if (!isOptionObject(option)) {
            return false;
        }

        const { column, value } = item.data || {};

        for (const joinColumn of [column, value]) {
            if (joinColumn?.table) {
                const { schema, table } = joinColumn;
                const title = [schema, table].join('.');

                if (option.title === title) {
                    return true;
                }
            }
        }

        return false;
    };

    /** @type { Option | null } */
    const value = useMemo(() => {
        const column = item.data?.column;

        if (column?.table) {
            const { schema, table: name } = column;
            const table = { name, schema };

            const option = findTableOption(table, fieldTableOptions);

            if (option) {
                return option;
            }
        }

        return null;
    }, [item, fieldTableOptions]);

    return (
        <InputTooltip content={!open && value ? value?.title : ''}>
            <Autocomplete
                label="Table"
                type="text"
                width="100"
                spacing="p-0 pr-2"
                options={fieldTableOptions}
                onChange={onChangeField}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                multiple={false}
                value={value}
                getOptionSearchableString={getOptionLabel}
                getOptionDisabled={getOptionDisabled}
                error={status === 'error' && !validateTable(item.data?.column)}
            />
        </InputTooltip>
    );
};

/**
 * @typedef { object } FieldProps
 * @prop { Item } item
 * @prop { number } index
 * @prop { Operator } operator
 * @prop { Option[] } fieldColumnOptions
 * @prop { Option | null } fieldColumnOption
 * @prop { ValueType } valueColumnType
 * @prop { Status } status
 * @prop { (index: number, item: Item) => void } updateField
 */

/** @type { React.FC<FieldProps> } */
const Field = ({
    item,
    index,
    operator,
    fieldColumnOptions,
    fieldColumnOption,
    valueColumnType,
    status,
    updateField,
}) => {
    const [open, setOpen] = useState(false);

    /**
     * @type { (
     *     e: React.ChangeEvent<HTMLInputElement | HTMLDivElement>,
     *     newValue: Value
     * ) => void }
     */
    const onChangeField = (e, newValue) => {
        if (isOptionObject(newValue) && newValue.column?.id) {
            const { name, table, schema } = newValue.column;

            const data = {
                ...item.data,
                column: {
                    name,
                    table,
                    schema,
                },
                operator,
            };

            updateField(index, { ...item, data });
        } else if (newValue === null) {
            const data = {
                ...item.data,
                column: item.data?.column && {
                    ...item.data?.column,
                    name: '',
                },
            };

            updateField(index, { ...item, data });
        }
    };

    /** @type { (option: OptionType) => boolean } */
    const getOptionDisabled = (option) => {
        if (!isOptionObject(option)) {
            return false;
        }

        const value = item.data?.value;

        if (value?.name) {
            if (isSameColumn(value, option.column)) {
                return true;
            }

            const fieldColumnType = resolveValueType(option);

            if (valueColumnType !== fieldColumnType) {
                return true;
            }
        }

        return false;
    };

    return (
        <InputTooltip content={!open && fieldColumnOption ? fieldColumnOption?.title : ''}>
            <Autocomplete
                label="Field"
                width="100"
                spacing="p-0 pr-2"
                options={fieldColumnOptions}
                onChange={onChangeField}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={fieldColumnOption}
                multiple={false}
                getOptionSearchableString={getOptionLabel}
                getOptionDisabled={getOptionDisabled}
                error={status === 'error' && !validateField(item)}
            />
        </InputTooltip>
    );
};

/**
 * @typedef { object } ValueTableProps
 * @prop { Item } item
 * @prop { number } index
 * @prop { Option[] } valueTableOptions
 * @prop { Status } status
 * @prop { (index: number, item: Item) => void } updateField
 */

/** @type { React.FC<ValueTableProps> } */
const ValueTable = ({
    item,
    index,
    valueTableOptions,
    status,
    updateField,
}) => {
    const [open, setOpen] = useState(false);

    /**
     * @type { (
     *     e: React.ChangeEvent<HTMLInputElement | HTMLDivElement>,
     *     newValue: Value
     * ) => void }
     */
    const onChangeField = (e, newValue) => {
        if (isOptionObject(newValue)) {
            const [schema, name] = newValue.title.split('.');
            const table = { name, schema };

            const option = findTableOption(table, valueTableOptions);

            if (option) {
                const data = {
                    ...item.data,
                    value: {
                        name: '',
                        schema,
                        table: name,
                    },
                };

                updateField(index, { ...item, data });
            }
        } else if (newValue === null) {
            const data = { ...item.data, value: undefined };

            updateField(index, { ...item, data });
        }
    };

    /** @type { (option: OptionType) => boolean } */
    const getOptionDisabled = (option) => {
        if (!isOptionObject(option)) {
            return false;
        }

        const { column, value } = item.data || {};

        for (const joinColumn of [column, value]) {
            if (joinColumn?.table) {
                const { schema, table } = joinColumn;
                const title = [schema, table].join('.');

                if (option.title === title) {
                    return true;
                }
            }
        }

        return false;
    };

    /** @type { Option | null } */
    const value = useMemo(() => {
        const value = item.data?.value;

        if (value?.table) {
            const { schema, table: name } = value;
            const table = { name, schema };

            const option = findTableOption(table, valueTableOptions);

            if (option) {
                return option;
            }
        }

        return null;
    }, [item, valueTableOptions]);

    return (
        <InputTooltip content={!open && value ? value?.title : ''}>
            <Autocomplete
                label="Table"
                type="text"
                width="100"
                spacing="p-0 pr-2"
                options={valueTableOptions}
                onChange={onChangeField}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                multiple={false}
                value={value}
                getOptionSearchableString={getOptionLabel}
                getOptionDisabled={getOptionDisabled}
                error={status === 'error' && !validateTable(item.data?.value)}
            />
        </InputTooltip>
    );
};

/**
 * @typedef { object } ValueProps
 * @prop { Item } item
 * @prop { number } index
 * @prop { Status } status
 * @prop { Option[] } valueColumnOptions
 * @prop { Option | null } valueColumnOption
 * @prop { ValueType } fieldColumnType
 * @prop { (index: number, item: Item) => void } updateField
 */

/** @type { React.FC<ValueProps> } */
const Value = ({
    item,
    index,
    status,
    valueColumnOptions,
    valueColumnOption,
    fieldColumnType,
    updateField,
}) => {
    const [open, setOpen] = useState(false);

    /**
     * @type { (
     *     e: React.ChangeEvent<HTMLInputElement | HTMLDivElement>,
     *     newValue: Value
     * ) => void }
     */
    const onChangeField = (e, newValue) => {
        if (isOptionObject(newValue) && newValue.column?.id) {
            const { name, table, schema } = newValue.column;

            const data = {
                ...item.data,
                value: {
                    name,
                    table,
                    schema,
                },
            };

            updateField(index, { ...item, data });
        } else if (newValue === null) {
            const data = {
                ...item.data,
                value: item.data?.value && {
                    ...item.data?.value,
                    name: '',
                },
            };

            updateField(index, { ...item, data });
        }
    };

    /** @type { (option: OptionType) => boolean } */
    const getOptionDisabled = (option) => {
        if (!isOptionObject(option)) {
            return false;
        }

        const column = item.data?.column;

        if (column?.name) {
            if (isSameColumn(column, option.column)) {
                return true;
            }

            const valueColumnType = resolveValueType(option);

            if (fieldColumnType !== valueColumnType) {
                return true;
            }
        }

        return false;
    };

    return (
        <InputTooltip content={!open && valueColumnOption ? valueColumnOption?.title : ''}>
            <Autocomplete
                label="Field"
                width="100"
                spacing="p-0 pr-2"
                options={valueColumnOptions}
                onChange={onChangeField}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={valueColumnOption}
                multiple={false}
                getOptionSearchableString={getOptionLabel}
                getOptionDisabled={getOptionDisabled}
                error={status === 'error' && !validateValue(item)}
            />
        </InputTooltip>
    );
};

/**
 * @typedef { object } AddProps
 * @prop { Item } item
 * @prop { number } index
 * @prop { (index: number) => void } addField
 */

/** @type { React.FC<AddProps> } */
const Add = ({ item, index, addField }) => {
    const disabled = !item.data?.column?.name;

    return (
        <Button
            round
            type="plain"
            color="secondary"
            onClick={() => addField(index + 1)}
            disabled={disabled}
            spacing="m-0 p-1"
        >
            <Icon
                icon="plus-circle"
                color={disabled ? 'interface' : 'secondary'}
            />
        </Button>
    );
};

/**
 * @typedef { object } ActionsProps
 * @prop { Item } item
 * @prop { number } index
 * @prop { Item[] } items
 * @prop { (index: number) => void } removeField
 */

/** @type { React.FC<ActionsProps> } */
const Remove = ({ item, index, items, removeField }) => {
    const disabled = items.length <= 1 && !item.data?.column?.name;

    return (
        <Button
            round
            type="plain"
            color="error"
            onClick={() => removeField(index)}
            disabled={disabled}
            spacing="m-0 p-1"
        >
            <Icon
                icon="minus-circle"
                color={disabled ? 'interface' : 'error'}
            />
        </Button>
    );
};

const Warning = styled(Typography)`
    &&& {
        color: ${colors.warning.dark};
    }
`;

/**
 * @typedef { object } HelpProps
 * @prop { React.ReactNode } children
 * @prop { string } [spacing]
 * @prop { string } [color]
 * @prop { 'center' } [align]
 */

/** @type { React.FC<HelpProps> } */
const Help = ({ children, ...props }) => (
    <Typography
        variant="body2"
        color="secondaryText"
        spacing="mb-0"
        {...props}
    >
        {children}
    </Typography>
);

/** @type { React.FC } */
const Error = () => (
    <Status>
        <Icon
            prefix="fas"
            icon="exclamation-circle"
            size="sm"
            color="error"
            spacing="mr-2"
        />
        <Help>
            There is at least one incomplete condition
        </Help>
    </Status>
);

/** @type { React.FC } */
const Success = () => (
    <Status>
        <Icon
            icon="check"
            size="sm"
            color="secondary"
            spacing="mr-2"
        />
        <Help>
            Saved
        </Help>
    </Status>
);

const Status = styled(({ className, children }) => (
    <Layout className={className} flex alignItems="center">
        {children}
    </Layout>
))`
    && {
        padding: 0.55rem 1rem 0.45rem;
    }
`;

const TableSelector = styled(Autocomplete)`
    min-width: 15rem;

    .form-control {
        height: 40px;
    }
`;

/**
 * @typedef { object } InputTooltipProps
 * @prop { React.ReactNode } children
 * @prop { string } [className]
 * @prop { string } [width]
 * @prop { string } [placement]
 * @prop { string } [content]
 * @prop { number } [enterDelay]
 */

const InputTooltip = styled((/** @type { InputTooltipProps } */ {
    className,
    content,
    placement,
    enterDelay,
    children,
}) => ((
    <Tooltip
        block
        className={className}
        content={content}
        enterDelay={enterDelay || 1000}
        interactive={false}
        placement={placement || 'bottom-start'}
    >
        {children}
    </Tooltip>
)))`
    width: ${({ width }) => width || 100}%;
`;

/**
 * @typedef { object } FlexLayoutProps
 * @prop { React.ReactNode } children
 * @prop { string } [spacing]
 * @prop { string } [width]
 * @prop { string } [grow]
 * @prop { 'center' } [justifyContent]
 * @prop { 'column' } [flexDirection]
 */

/** @type { React.FC<FlexLayoutProps> } */
const FlexLayout = ({ children, ...props }) => (
    <NoWrap flex alignItems="center" {...props}>
        {children}
    </NoWrap>
);

/**
 * @typedef { object } TextProps
 * @prop { React.ReactNode } children
 * @prop { string } [spacing]
 */

/** @type { React.FC<TextProps> } */
const Text = ({ children, ...props }) => (
    <Typography
        variant="body1"
        color="secondaryText"
        weight="medium"
        align="center"
        spacing="mb-0"
        {...props}
    >
        {children}
    </Typography>
);

/**
 * @typedef { object } LabelProps
 * @prop { React.ReactNode } children
 * @prop { string } [spacing]
 */

/** @type { React.FC<LabelProps> } */
const Label = ({ children, ...props }) => (
    <Typography variant="subtitle1" color="text" spacing="mb-0" {...props}>
        {children}
    </Typography>
);

const Draggable = styled.div`
    margin: 0 -${spacings[2]}rem;
    padding: ${spacings[1]}rem ${spacings[2]}rem;

    ${({ draggable }) => draggable && `
        cursor: grab;
        :active {
            cursor: grabbing;
        }
    `}
`;

const NoWrap = styled(Layout)`
    && {
        white-space: nowrap;
    }
`;

const Wrapper = styled(Layout)`
    width: 100vw;
    max-width: 100%;
`;

export default EditJoinDialog;
