export default {
    env: 'production',
    hostname: [
        /^https:\/\/platform\.panoply\.io\//,
    ],
    stripe: {
        apiKey: 'pk_live_k5vUDzqV0u4MmUknahFqdB9D',
    },
    recaptcha: {
        siteKey: '6Lfrn90UAAAAAN5mDwGoA-oYK-nJM57hZFab_whE',
    },
};
