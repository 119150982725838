import React from 'react';

import {
    Button,
    Callout,
    Input,
    Typography,
} from 'ui-components';

/**
 * @typedef { object } Props
 * @prop { string } email
 * @prop { boolean } emailIsValid
 * @prop { boolean } loading
 * @prop { boolean } disabled
 * @prop { { message: string } } error
 * @prop { (newEmail: string) => void } onEmailChanged
 * @prop { () => void } onResetPasswordClicked
 * @prop { () => void } onEnterPressed
 */

/** @type { React.FC<Props> } */
const ResetPasswordForm = ({
    email,
    emailIsValid,
    loading,
    disabled,
    error,
    onEmailChanged,
    onResetPasswordClicked,
    onEnterPressed,
}) => {

    const emailProps = {
        label: 'Email',
        name: 'email',
        value: email,
        error: !emailIsValid,
        disabled: loading,
        onKeyDown: onEnterPressed,
        onChange: onEmailChanged,
    };

    const buttonProps = {
        color: 'primary',
        onClick: onResetPasswordClicked,
        loading,
        disabled,
    };

    return (
        <>
            <Typography variant="h4" align="center" spacing="mb-6">
                Forgot your password?
            </Typography>

            {error && (
                <Callout color="error" spacing="mb-5">
                    {error.message}
                </Callout>
            )}

            <Typography align="center" variant="subtitle1" spacing="mb-2">
                Enter your email and we’ll send you instructions
            </Typography>

            <Input width="100" spacing="p-0 mb-5" noFloatLabel {...emailProps} />

            <Button width="100" spacing="m-0" {...buttonProps}>
                Send Me Instructions
            </Button>
        </>
    );
};

export default ResetPasswordForm;
