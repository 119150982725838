import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import {
    Dropdown,
    Icon,
    List,
    ListItem,
    Tooltip,
    ToggleButton,
} from 'ui-components';

/** @typedef { import('../../app/__types').Session } Session */

/**
 * @typedef { object } Item
 *
 * @prop { string } id
 * @prop { string } text
 * @prop { string } url
 * @prop { (b: Session['billing']) => boolean } [isHidden]
 */

/** @type { Item[] } */
const items = [
    {
        id: 'docs_and_faq',
        text: 'Documentation',
        url: 'https://panoply.io/docs/',
    },
    {
        id: 'get_personalized_demo',
        text: 'Get a Personalized Demo',
        url: 'https://www.panoply.io/request-a-demo/',
        isHidden: billing => !billing.onTrial,
    },
];

/**
 * @typedef { object } Props
 *
 * @prop { Session['billing'] } billing
 * @prop { string } [spacing]
 */

/** @type { React.FC<Props> } */
const HelpMenu = ({ billing, ...props }) => {
    const [open, setOpen] = useState(false);

    const toggleOpen = useCallback(() => {
        setOpen(!open);
    }, [open]);

    return (
        <Dropdown
            id="help"
            opener={open ? (
                <HelpToggle active {...props} />
            ) : (
                <Tooltip content="Help & Support" interactive={false}>
                    <HelpToggle onClick={toggleOpen} {...props} />
                </Tooltip>
            )}
            open={open}
            onClose={toggleOpen}
        >
            <List>
                {items.map((item) => {
                    if (item.isHidden && item.isHidden(billing)) {
                        return null;
                    }

                    const onClicked = () => {
                        toggleOpen();
                        window.open(item.url, '_blank');
                    };

                    return (
                        <ListItem
                            key={item.id}
                            button
                            onClick={onClicked}
                            label={item.text}
                        />
                    );
                })}
            </List>
        </Dropdown>
    );
};

/**
 * @typedef { object } ToggleProps
 * @prop { boolean } [active]
 * @prop { () => void } [onClick]
 */

/** @type { (props: ToggleProps) => React.ReactElement } */
const HelpToggle = styled(props => (
    <ToggleButton
        round
        type="plain"
        color="secondaryText"
        {...props}
    >
        <Icon icon="question-circle" />
    </ToggleButton>
))`
    &&& {
        padding: 11px;
    }
`;

export default HelpMenu;
