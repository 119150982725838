import { useEffect, useState } from 'react';
import { useLocation, useNavigate, matchPath } from 'react-router-dom';

import {
    AddSourcePage,
    AcceptInvitePage,
    BillingPage,
    ChangePasswordPage,
    CompleteSignupPage,
    ConnectPage,
    HomePage,
    InsightsPage,
    JobsPage,
    LoginPage,
    NotificationsPage,
    ReactivateAccountPage,
    ResetPasswordPage,
    SignupPage,
    SourceListPage,
    SourcePage,
    TablePage,
    TablesPage,
    UsersPage,
    WarehousesPage,
    WorkbenchPage,
    RotateServiceAccountPage,
    QueriesPage,
    MfaPage,
    DashboardPage,
    DashboardsPage,
} from '../pages';

import { useSession, useOnboarding } from '../shared/context-providers';

/** @typedef { import('./__types').Redirect } Redirect */
/** @typedef { import('./__types').Session } Session */
/** @typedef { import('./__types').PageRoute } Route */
/** @typedef { import('./__types').PageProps } PageProps */

/** @type { Route[] } */
const publicRoutes = [
    {
        id: 'home',
        name: 'home',
        path: '/',
        redirect: {
            to: '/login',
        },
        end: true,
    },
    {
        id: 'accept-invite',
        name: 'accept-invite',
        path: '/databases/invite/:token',
        Component: AcceptInvitePage,
    },
    {
        id: 'login',
        name: 'login',
        path: '/login',
        Component: LoginPage,
    },
    {
        id: 'complete-signup',
        name: 'complete-signup',
        path: '/signup/:token',
        Component: CompleteSignupPage,
    },
    {
        id: 'signup',
        name: 'signup',
        path: '/signup',
        Component: SignupPage,
    },
    {
        id: 'change-password',
        name: 'change-password',
        path: '/users/change/:token',
        Component: ChangePasswordPage,
    },
    {
        id: 'reset-password',
        name: 'reset-password',
        path: '/users/reset',
        Component: ResetPasswordPage,
    },
];

/** @type { Route[] } */
const deactivatedAccountRoutes = [
    {
        id: 'reactivate-account',
        name: 'reactivate-account',
        path: '/reactivate-account',
        Component: ReactivateAccountPage,
        end: true,
    },
    {
        id: 'accept-invite',
        name: 'accept-invite',
        path: '/databases/invite/:token',
        Component: AcceptInvitePage,
    },
    {
        id: 'fallback',
        name: 'fallback',
        redirect: {
            to: '/reactivate-account',
        },
    },
];

/** @type { Route[] } */
const defaultRoutes = [
    {
        id: 'home',
        name: 'home',
        path: '/',
        Component: HomePage,
        end: true,
    },
    {
        id: 'accept-invite',
        name: 'accept-invite',
        path: '/databases/invite/:token',
        Component: AcceptInvitePage,
    },
    {
        id: 'billing',
        name: 'billing',
        path: '/billing',
        Component: BillingPage,
    },
    {
        id: 'connect',
        name: 'connect',
        path: '/connect',
        Component: ConnectPage,
    },
    {
        id: 'databases',
        name: 'warehouses',
        path: '/databases',
        Component: WarehousesPage,
    },
    {
        id: 'tables',
        name: 'table',
        path: '/explore/:id',
        Component: TablePage,
    },
    {
        id: 'tables',
        name: 'table-list',
        path: '/explore',
        Component: TablesPage,
    },
    {
        id: 'insights',
        name: 'insights',
        path: '/insights',
        Component: InsightsPage,
    },
    {
        id: 'jobs',
        name: 'jobs',
        path: '/jobs',
        Component: JobsPage,
    },
    {
        id: 'notifications',
        name: 'notifications',
        path: '/notifications/settings',
        Component: NotificationsPage,
    },
    {
        id: 'data-sources',
        name: 'add-source-search',
        path: '/sources/new/:search',
        Component: AddSourcePage,
    },
    {
        id: 'data-sources',
        name: 'add-source',
        path: '/sources/new',
        Component: AddSourcePage,
    },
    {
        id: 'data-sources',
        name: 'source',
        path: '/sources/:sourceId/*',
        Component: SourcePage,
    },
    {
        id: 'data-sources',
        name: 'source-list',
        path: '/sources',
        Component: SourceListPage,
    },
    {
        id: 'teams',
        name: 'teams',
        path: '/teams',
        Component: UsersPage,
    },
    {
        id: 'rotate-service-account',
        name: 'rotate-service-account',
        path: '/users/credentials/rotate',
        Component: RotateServiceAccountPage,
    },
    {
        id: 'change-password',
        name: 'change-password',
        path: '/users/change/:token',
        Component: ChangePasswordPage,
    },
    {
        id: 'reset-password',
        name: 'reset-password',
        path: '/users/reset',
        Component: ResetPasswordPage,
    },
    {
        id: 'analyze',
        name: 'workbench-item',
        path: '/workbench/:type/:schema?/:id',
        Component: WorkbenchPage,
    },
    {
        id: 'analyze',
        name: 'workbench',
        path: '/workbench',
        Component: WorkbenchPage,
    },
    {
        id: 'queries',
        name: 'queries',
        path: '/queries',
        Component: QueriesPage,
    },
    {
        id: 'mfa',
        name: 'mfa',
        path: '/mfa',
        Component: MfaPage,
    },
    {
        id: 'dashboards',
        name: 'dashboard',
        path: '/dashboards/:id',
        Component: DashboardPage,
    },
    {
        id: 'dashboards',
        name: 'dashboards',
        path: '/dashboards',
        Component: DashboardsPage,
    },
    {
        id: 'fallback',
        name: 'fallback',
        redirect: {
            to: '/',
        },
    },
];

/** @type { (s: Session, p: string) => Redirect } */
function getRedirect({ user }, pathname = '/') {
    if (!user.id) {
        if (pathname === '/login') {
            return { to: '/login' };
        }

        return {
            to: '/login',
            state: { url: window.location.href },
        };
    }

    return { to: '/' };
}

/** @type { (s: Session, r: Redirect) => Route[] } */
function getRoutes({ user, database, nav }, redirect) {
    if (!user.id) {
        return [...publicRoutes, {
            id: 'fallback',
            name: 'fallback',
            redirect,
        }];
    }

    const routes = !database.id || database.status !== 'active'
        ? deactivatedAccountRoutes
        : defaultRoutes;

    if (nav.exclude.length > 0) {
        return routes.filter(route => !nav.exclude.includes(route.id));
    }

    return routes;
}

/** @type { () => { routes: Route[] } } */
function useRouter() {
    const [session, sessionActions] = useSession();
    const { updateSession } = sessionActions;

    const location = useLocation();

    const { pathname, state } = location;

    const [routes, setRoutes] = useState(/** @type { Route[] } */([]));

    const navigate = useNavigate();

    const [onboarding] = useOnboarding();

    useEffect(() => {
        // remove all params that should not be in the url
        const queryParams = new URLSearchParams(window.location.search);
        let shouldUpdateQs = false;

        const marketingParams = [
            'utm_source',
            'utm_medium',
            'utm_campaign',
            'utm_term',
            'utm_content',
        ];
        const allowedParams = [...marketingParams];

        for (const key of Array.from(queryParams.keys())) {
            if (!allowedParams.includes(key)) {
                queryParams.delete(key);
                if (!shouldUpdateQs) {
                    shouldUpdateQs = true;
                }
            }
        }

        if (shouldUpdateQs) {
            window.location.search = queryParams.toString();
        }
    }, []);

    useEffect(() => {
        if (!state || !state.url) {
            return;
        }
        updateSession({ afterLoginRedirect: state.url });
    }, [state]);

    useEffect(() => {

        if (!onboarding.didLoad) {
            return;
        }

        const onboardingRoutes = [
            '/sources/new',
            ...publicRoutes.map(r => r.path),
        ];

        const currentPathIsInOnboarding = onboardingRoutes.find(
            path => matchPath({ path: path || '/', end: true }, pathname),
        );

        // If onboarding dont allow other pages
        if (onboarding.active && !currentPathIsInOnboarding) {
            navigate('/sources/new');
            return;
        }
    }, [session, pathname, onboarding.didLoad, onboarding.active]);

    useEffect(() => {
        if (!session.csrfToken) {
            return;
        }
        const redirect = getRedirect(session, pathname);
        setRoutes(getRoutes(session, redirect));
    }, [session, pathname]);

    return {
        routes,
    };
}

/** A static list of all regular routes */
const allRoutes = [
    ...publicRoutes,
    ...deactivatedAccountRoutes,
    ...defaultRoutes,
];

export { allRoutes };

export default useRouter;
