import React from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';

import {
    Layout,
    Typography,
    Button,
    Dialog,
    Anchor,
    colors,
} from 'ui-components';

import cdnIcon, { cdnDataSourceIcon } from '../../lib/cdn-icon';
import Connection from '../../shared/database-connection';
import openSupportChat from '../../shared/use-support-chat';

/** @typedef { import('./__types').Integration } Integration */
/** @typedef { import('./../../models/sources').Source } Source */

/**
 * @typedef { import('styled-components').ThemedStyledFunction<C, any, O> } Styled
 * @template { 'div' } C
 * @template { object } [O={}]
 */

/**
 * @typedef { object } Props
 * @prop { Integration } integration
 * @prop { () => void } onGoBackClicked
 * @prop { () => void } openDetailsModal
 * @prop { () => void } closeDetailsModal
 * @prop { () => void } requestDataSource
 * @prop { () => void } addFlexConnector
 * @prop { boolean } detailsModalOpen
 */

/** @type { React.FC<Props> } */
const IntegrationInfo = ({
    integration,
    onGoBackClicked,
    detailsModalOpen,
    openDetailsModal,
    closeDetailsModal,
    addFlexConnector,
}) => {

    const { type, title, isAPI } = integration;

    const chat = () => openSupportChat(`How to get ${title} data into Panoply?`);

    return (
        <Layout width="100" flex flexDirection="column" alignItems="center" justifyContent="center">
            <InfoWrapper flex>

                <Layout spacing="mt-6 mb-8 ml-6 mr-0" width="100">

                    {type === 'etl' && (
                        <>
                            <Typography
                                variant="body1"
                                spacing="mt-2 mb-6"
                                weight="medium"
                            >
                                Connect a partner ETL tool to Panoply
                            </Typography>

                            <Typography variant="body2" spacing="mb-5">
                                Step 1.{' '}
                                <DetailsLink
                                    href="https://www.stitchdata.com/data-warehouses/panoply/"
                                    target="_blank"
                                >
                                    Learn More about Stitch
                                </DetailsLink>
                            </Typography>

                            <Typography variant="body2" spacing="mb-5">
                                Step 2.{' '}
                                <DetailsLink
                                    href="https://panoply.io/docs/connecting-etl-tools"
                                    target="_blank"
                                >
                                    Connect Stitch to Panoply
                                </DetailsLink>
                            </Typography>

                            <Typography variant="body2" spacing="mb-5">
                                Step 3. Give Stitch your{' '}
                                <DetailsLink onClick={openDetailsModal}>
                                    Panoply connection details
                                </DetailsLink>
                            </Typography>

                            <Typography variant="body2" spacing="mb-5">
                                Step 4. Once synced,{' '}
                                <DetailsLink target="_blank" href="/#/explore">
                                    find your data in Panoply
                                </DetailsLink>
                            </Typography>
                        </>
                    )}

                    {type === 'other' && !isAPI && (
                        <>
                            <Typography
                                variant="body1"
                                spacing="mt-2 mb-6"
                                weight="medium"
                            >
                                Connect Panoply to other sources
                            </Typography>

                            <Typography variant="body2">
                                We don’t have a snap connector for
                                <DataSourceName> {title}</DataSourceName>.
                            </Typography>
                            <Typography variant="body2">
                                <DetailsLink onClick={chat}>
                                    Chat with us {' '}
                                </DetailsLink>
                                to find out how to bring
                                <DataSourceName> {title}</DataSourceName>
                                {' '} data into Panoply.
                            </Typography>
                        </>
                    )}

                    {type === 'other' && isAPI && (
                        <>
                            <Typography
                                variant="body1"
                                spacing="mt-2 mb-6"
                                weight="medium"
                            >
                                Connect Panoply to {title} with our
                                {' '}
                                <Anchor onClick={addFlexConnector}>
                                    Flex Connector
                                </Anchor>
                            </Typography>

                            <Typography variant="body2">
                                <Anchor color="primary" onClick={chat}>
                                    Chat with us
                                </Anchor>
                                {' '}
                                and we will be happy to help you connect.
                            </Typography>
                        </>
                    )}

                </Layout>

                <IntegrationDiagram integration={integration} />

            </InfoWrapper>

            <Button spacing="mt-6" type="plain" color="primary" onClick={onGoBackClicked}>
                Go Back
            </Button>

            <Dialog
                isOpen={detailsModalOpen}
                onClose={closeDetailsModal}
            >
                <Layout spacing="-m-4 -mb-5"><Connection /></Layout>
            </Dialog>
        </Layout>
    );
};

/** @type { React.FC<{ integration: Integration }> } */
const IntegrationDiagram = ({ integration }) => {
    const { type, isAPI } = integration;

    const diagramName = (() => {
        switch (type) {
            case 'etl':
                return 'vertical-integration-diagram-etl.svg';
            case 'other':
                return 'vertical-integration-diagram-partner.svg';
            default:
                return '';
        }
    })();

    return (
        <Layout spacing="pt-4 pb-4 pr-4 pl-0">
            <Layout relative>
                <img src={cdnIcon(diagramName, 'ui')} />

                <IntegrationIcon icon={integration.icon} />

                {type === 'other' && isAPI && (
                    <ConnectorIcon
                        icon={cdnDataSourceIcon('flexconnector')}
                    />
                )}
            </Layout>
        </Layout>
    );
};

const InfoWrapper = styled(Layout)`
    background-color: ${transparentize(0.9, colors.interface)};
    border: 1px solid ${colors.secondaryInterface};
    border-radius: 5px;
`;

const DetailsLink = styled('a')`
    cursor: pointer;
    color: ${colors.primary};
`;

const DataSourceName = styled('span')`
    color: black;
    font-weight: bold;
`;

const IntegrationIcon = /** @type { Styled<'div', { icon: string }> } */ (styled.div)`
    position: absolute;
    left: 67px;
    top: 38px;
    width: 46px;
    height: 46px;
    background-color: #fff;
    background-image: ${({ icon }) => `url(${icon})`};
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
`;

const ConnectorIcon = styled(IntegrationIcon)`
    top: 202px;
`;

export default IntegrationInfo;
