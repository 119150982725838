import moment from 'moment';

/** @typedef { import('../app/__types').Session } Session */


/**
 * @param { Session['database'] } database
 * @param { Session['billing'] } billing
 * @returns { number }
 */
export function getDaysRemaining(database, billing) {
    const end = getTrialEnd(database.created_at, billing.expires_at);
    const now = moment.utc();
    const days = end.diff(now, 'days') + 1;
    return days;
}


/**
 * @param { number } days
 * @returns { string }
 */
export function getTrialMessageByDays(days) {
    if (days <= 0) {
        return 'Trial Expired';
    } else if (days > 0 && days <= 7) {
        return `${days} Day${days > 1 ? 's' : ''} Left`;
    }
    return `${days} Days Left`;
}

/**
 * @param { Session['database'] } database
 * @param { Session['billing'] } billing
 * @returns { string }
 */
function getTrialMessage(database, billing) {
    const days = getDaysRemaining(database, billing);
    return getTrialMessageByDays(days);
}

/**
 * @param { Session['database']['created_at'] } createdAt
 * @param { Session['billing']['expires_at'] } expiresAt
 * @returns { moment.Moment }
 */
function getTrialEnd(createdAt, expiresAt) {
    if (expiresAt) {
        return moment.utc(expiresAt);
    }
    // A fallback for the existing trial accounts
    return moment.utc(createdAt).add(21, 'days');
}


export default getTrialMessage;
