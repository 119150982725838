import React from 'react';
import styled from 'styled-components';
import { Divider } from 'ui-components';

import WorkbenchStatusBar from './workbench-status-bar';
import WorkbenchPageSizeBar, { pageSizeBarHeight } from './workbench-page-size-bar';
import WorkbenchCallToAction from './workbench-call-to-action';
import WorkbenchVisualization from './workbench-visualization';
import WorkbenchVisualizationConfig from './workbench-visualization-config';
import useWorkbenchVisualization from './use-workbench-visualization';
import QueryResultSaveAsDialog from './query-result-save-as-dialog';
import useResizeObserver from './use-resize-observer';
import WorkbenchVisualizationConfigOpener from './workbench-visualization-config-opener';
import useWorkbenchVisualizationDrawer from './use-workbench-visualization-drawer';

/** @typedef { import('models/queries/__types').Status } Status */
/** @typedef { import('models/queries/__types').QueryResult } QueryResult */
/** @typedef { import('./__types').ViewData } ViewData */
/** @typedef { import('./__types').TableData } TableData */
/** @typedef { import('./__types').SchemaData } SchemaData */
/** @typedef { import('./__types').ReportData } ReportData */
/** @typedef { import('./use-workbench-data').WorkbenchData } WorkbenchData */

/**
 * @typedef { import('./use-workbench-query-results').WorkbenchQueryResults } WorkbenchQueryResults
 */

/**
 * @typedef { object } Props
 * @prop { string= } queryError
 * @prop { QueryResult[] } queryResults
 * @prop { Date= } queryStartedAt,
 * @prop { Date=} queryEndedAt
 * @prop { boolean } queryIsRunning
 * @prop { (id: QueryResult['id'], updates: Partial<QueryResult>) => void } updateQueryResult
 * @prop { number } pageSize
 * @prop { ViewData[] } views
 * @prop { TableData[] } tables
 * @prop { SchemaData[] } schemas
 * @prop { ReportData[] } reports
 * @prop { boolean } loadingViews
 * @prop { boolean } loadingTables
 * @prop { boolean } loadingSchemas
 * @prop { boolean } loadingReports
 * @prop { WorkbenchData['fetchViews'] } fetchViews
 * @prop { WorkbenchData['fetchReports'] } fetchReports
 * @prop { number } page
 * @prop { boolean } pageLoading
 * @prop { QueryResult } [queryResultPage]
 * @prop { QueryResult } [activeResult]
 * @prop { (result: QueryResult) => void } changeActiveQueryResult
 * @prop { (page: number) => void } updatePage
 * @prop { (pageSize: number) => void } updatePageSize
 * @prop { Status } [parentQueryStatus]
 * @prop { boolean } isSelectQuery
 * @prop { boolean } showSaveAsDialog
 * @prop { boolean } showVisualization
 * @prop { boolean } showCallToAction
 * @prop { boolean } saveAsDialogOpen
 * @prop { boolean } saveDialogOpen
 * @prop { () => void } toggleSaveAsDialog
 * @prop { () => void } toggleSaveDialog
 * @prop { () => void } toggleDrawerOpen
 * @prop { boolean } drawerOpen
 */

/** @type { React.FC<Props & WorkbenchQueryResults> } */
const WorkbenchQueryResults = React.memo(({
    queryError,
    queryResults,
    queryStartedAt,
    queryEndedAt,
    queryIsRunning,
    updateQueryResult,
    pageSize,
    views,
    tables,
    schemas,
    reports,
    loadingViews,
    loadingTables,
    loadingSchemas,
    loadingReports,
    fetchViews,
    fetchReports,
    page,
    pageLoading,
    queryResultPage,
    activeResult,
    changeActiveQueryResult,
    updatePage,
    updatePageSize,
    parentQueryStatus,
    isSelectQuery,
    showSaveAsDialog,
    showVisualization,
    showCallToAction,
    saveAsDialogOpen,
    saveDialogOpen,
    toggleSaveAsDialog,
    toggleSaveDialog,
}) => {
    const {
        visualizationHeight,
        visualizationWidth,
        resizeListenerElemRef,
    } = useResizeObserver();

    const {
        drawerOpen,
        toggleDrawerOpen,
    } = useWorkbenchVisualizationDrawer();

    const {
        visualizationReport,
        onVisualizationReportChanged,
    } = useWorkbenchVisualization({
        reports,
        queryIsRunning,
        queryData: queryResultPage?.data,
    });

    const statusBarProps = {
        queryError,
        isSelectQuery,
        queryResults,
        activeResult,
        parentQueryStatus,
        changeActiveQueryResult,
        queryResultPage,
        queryStartedAt,
        queryEndedAt,
        visualizationReport,
        onVisualizationReportChanged,
        updateQueryResult,
        toggleSaveAsDialog,
        toggleSaveDialog,
    };

    const colorPalette = visualizationReport?.config?.colorPalette;
    const showBorders = visualizationReport?.config?.showBorders;

    const visualizationProps = queryResultPage?.data && {
        queryIsRunning,
        queryData: queryResultPage?.data,
        visualizationHeight,
        visualizationWidth,
        visualizationReport,
        autoIndex: true,
        noSort: true,
        virtualized: true,
        noBorder: !showBorders,
        color: colorPalette ? colorPalette : 'table-text',
        toggleDrawerOpen,
        drawerOpen,
    };

    const callToActionProps = activeResult?.data && {
        queryData: activeResult?.data,
    };

    const pageSizeBarProps = {
        page,
        pageSize,
        updatePageSize,
        queryResultPage,
        queryError,
        queryStartedAt,
        queryEndedAt,
        queryIsRunning,
        pageLoading,
        isSelectQuery,
        updatePage,
        activeResult,
    };

    const saveAsDialogProps = {
        saveAsDialogOpen,
        saveDialogOpen,
        toggleSaveAsDialog,
        toggleSaveDialog,
        visualizationReport,
        queryResults,
        activeQueryResult: activeResult,
        changeActiveQueryResult,
        views,
        tables,
        schemas,
        reports,
        loadingViews,
        loadingTables,
        loadingSchemas,
        loadingReports,
        fetchViews,
        fetchReports,
    };

    return (
        <ResultsContainer>
            <ContentWrapper>
                <Divider width="100" spacing="my-0" />

                <WorkbenchStatusBar {...statusBarProps} />

                <Divider width="100" spacing="my-0" />

                <ResizeListener ref={resizeListenerElemRef} id="resize-listener">
                    {showVisualization && visualizationProps && (
                        <>
                            <WorkbenchVisualization {...visualizationProps} />

                            <WorkbenchVisualizationConfigOpener toggleOpen={toggleDrawerOpen}/>
                            <WorkbenchVisualizationConfig
                                queryData={visualizationProps.queryData}
                                visualizationReport={visualizationReport}
                                toggleOpen={toggleDrawerOpen}
                                onVisualizationReportChanged={onVisualizationReportChanged}
                                open={drawerOpen}
                            />
                        </>
                    )}

                    {showCallToAction && callToActionProps && (
                        <WorkbenchCallToAction {...callToActionProps} />
                    )}
                </ResizeListener>
            </ContentWrapper>

            <WorkbenchPageSizeBar {...pageSizeBarProps} />

            {showSaveAsDialog && (
                <QueryResultSaveAsDialog {...saveAsDialogProps} />
            )}
        </ResultsContainer>
    );
});

const ResizeListener = styled('div')`
    position: relative;
    height: 100%;
    overflow: hidden;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    padding-bottom: ${pageSizeBarHeight};
`;

const ResultsContainer = styled('div')`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
`;

export default WorkbenchQueryResults;
