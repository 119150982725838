import React from 'react';
import styled from 'styled-components';

import { colors } from 'ui-components';

/**
 * @typedef { object } Props
 * @prop { (value: boolean) => void } setShowNav
 */

/** @type {React.FC<Props>} */
const ShowNavButton = ({ setShowNav }) => (
    <>
        <Clickable onClick={() => setShowNav(false)}>
            <ExpandIcon />
        </Clickable>

        <DividerIcon />
    </>
);

const ExpandIcon = () => (
    <svg width="13" height="13" fill="none">
        <rect x="0" y="10.5" width="11" height="2.5" rx="0.75" fill={colors.secondaryText} />
        <rect x="2" y="5.25" width="11" height="2.5" rx="0.75" fill={colors.secondaryText} />
        <rect x="0" y="0" width="11" height="2.5" rx="0.75" fill={colors.secondaryText} />
    </svg>
);

const DividerIcon = () => (
    <svg width="14" height="60" fill="none">
        <path d="M1 0L13 28.5L1 60" stroke={colors.secondaryInterface} />
    </svg>
);

const Clickable = styled.div`
    display: inline-flex;
    padding: 0.5rem;
    margin-left: 0.25rem;

    :hover {
        cursor: pointer;
    }
`;

export default ShowNavButton;
