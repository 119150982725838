import React, { useCallback } from 'react';
import {
    DebouncedInput,
    Layout,
} from 'ui-components';
import MarkdownRenderer from '../../../shared/markdown-renderer';

/** @typedef { import('models/sources/__types').DestinationParam } Param */
/** @typedef { import('models/sources/__types').DestinationParamValue } Value */
/** @typedef { import('models/sources/__types').FieldValidationErrors } FieldValidationErrors */

/**
 * @typedef { object } Props
 * @prop { Param } param
 * @prop { Value } value
 * @prop { (value: Value) => void } setValue
 * @prop { FieldValidationErrors } errors
 */

/** @type { React.FC<Props> } */
const DestinationParam = ({
    param,
    value,
    setValue,
    errors,
}) => {

    const { destination: error } = errors;

    const onValueChanged = useCallback((e) => {
        setValue(e.target.value);
    }, [setValue]);

    return (
        <Layout relative width="50">
            <DebouncedInput
                name="destination"
                value={value || ''}
                label={param.placeholder}
                width="100"
                spacing="p-0"
                noFloatLabel
                onChange={onValueChanged}
                helperText={error || (param.description && (
                    <MarkdownRenderer source={param.description} />
                ))}
                error={!!error}
                alwaysShowError
            />
        </Layout>
    );
};

export default DestinationParam;
