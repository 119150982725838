import {
    useState,
    useCallback,
    useEffect,
} from 'react';

import { addBreadcrumb } from '../lib/error-reporter';

const usePageVisibility = () => {
    const [pageIsVisible, setPageIsVisible] = useState(true);

    const onVisibilityChanged = useCallback(() => {
        if (typeof document.visibilityState !== 'string') {
            return;
        }
        setPageIsVisible(document.visibilityState === 'visible');
    }, []);

    useEffect(() => {
        try {
            document.addEventListener('visibilitychange', onVisibilityChanged);
            return () => document.removeEventListener('visibilitychange', onVisibilityChanged);
        } catch (e) {
            addBreadcrumb('pageVisibility API not supported');
            return () => {};
        }
    }, [onVisibilityChanged]);

    return pageIsVisible;
};

export default usePageVisibility;
