import React from 'react';

import {
    Layout,
    Typography,
} from 'ui-components';

/** @typedef { import('lib/ajax').AjaxResponse= } AjaxError */

/**
 * @typedef { object } Props
 * @prop { string | AjaxError } error
 * @prop { string } [title]
 */

/** @type { React.FC<Props> } */
const PageError = ({ error, title }) => {

    if (!error) {
        return null;
    }

    const displayTitle = title
        || 'Oops! There was an error while loading this page';

    const displayError = error.data
        ? error.data.message
        : error;

    return (
        <Layout spacing="p-8">
            <Typography variant="h6" align="center">
                {displayTitle}
            </Typography>
            <Typography variant="subtitle1" align="center">
                {displayError}
            </Typography>
        </Layout>
    );
};

export default PageError;
