/* eslint-disable complexity */
import React, { useCallback } from 'react';
import { Layout } from 'ui-components';

import FormRow from '../form-row';

import { SourceOAuth1Param, SourceOAuth2Param } from './oauth-param';
import { SourceDynamicSelectParam, SourceStaticSelectParam } from './select-param';
import { SourceDynamicListParam, SourceStaticListParam } from './list-param';
import {
    SourceStaticAutocompleteParam,
    SourceDynamicAutocompleteParam,
} from './autocomplete-param';
import SourceInputParam from './input-param';
import SourceUploadParam from './upload-param';
import ExcludeParam from './exclude-param';
import IncludeParam from './include-param';
import ParseStringParam from './parse-string-param';
import PrimaryKeyParam from './primary-key-param';
import DelimiterParam from './delimiter-param';
import DestinationParam from './destination-param';
import DestinationPrefixParam from './destination-prefix-param';
import IncrementalKeyParam from './incremental-key-param';
import TruncateTableParam from './truncate-table-param';
import PastDaysParam from './past-days-param';
import SchemaParam from './schema-param';
import CheckboxParam from './checkbox-param';
import SSLParam from './ssl-param';
import ValidationParam from './validation-param';
import DateParam from './date-param';
import SshParam from './ssh-param';
import GroupParam from './group-param';
import MatrixParam from './matrix-param';

/** @typedef { import('models/sources').Source } Source */
/** @typedef { import('models/sources').SourceType } SourceType */
/** @typedef { import('models/sources').Param } Param */
/** @typedef { import('models/sources').FlattenedParam } FlattenedParam */
/** @typedef { import('models/sources').DynamicParamArgs } DynamicParamArgs */
/** @typedef { import('models/sources').DynamicParamResponse } DynamicParamResponse */
/** @typedef { import('models/sources').ValidationParamResponse } ValidationParamResponse */
/** @typedef { import('models/sources/__types').ListParamValue } ListParamValue */
/** @typedef { import('models/sources/__types').FieldValidationErrors } FieldValidationErrors */
/** @typedef { import('models/schemas/__types').Schema } Schema */

/**
 * @typedef { object } Props
 * @prop { Source } source
 * @prop { SourceType } sourceType
 * @prop { FlattenedParam[] } flattenedParams
 * @prop { Param['category'] } category
 * @prop { (name: Param['name']) => any } getParamValue
 * @prop { (name: Param['name'], value: any) => void } setParamValue
 * @prop { (name: Param['name']) => any } getParamState
 * @prop { (name: Param['name'], newState: any) => void } setParamState
 * @prop { (param: Param) => boolean } isParamRequired
 * @prop { (name: Param['name']) => boolean } areParamDepsMet
 * @prop { (
 *  name: Param['name'],
 *  args: DynamicParamArgs
 * ) => Promise<DynamicParamResponse['data']>
 * } loadDynamicParam
 * @prop { (name: Param['name']) => Promise<ValidationParamResponse>} loadValidationParam
 * @prop { FieldValidationErrors } fieldValidationErrors
 * @prop { () => Promise<Schema[]>} loadSchemas
 */


/** @type { React.FC<Props & { param: Param }> } */
const SourceParam = ({
    source,
    sourceType,
    flattenedParams,
    param,
    isParamRequired,
    getParamValue,
    setParamValue,
    getParamState,
    setParamState,
    areParamDepsMet,
    loadDynamicParam,
    loadValidationParam,
    fieldValidationErrors,
    loadSchemas,
}) => {
    /* eslint-disable react-hooks/exhaustive-deps */
    /* linter gets angry because it cant infer what the hook deps should be */
    const loadDynamicParamItems = useCallback(
        loadDynamicParam.bind(null, param.name),
        [loadDynamicParam, param.name],
    );
    const loadValidationParamError = useCallback(
        loadValidationParam.bind(null, param.name),
        [loadValidationParam, param.name],
    );
    const setValue = useCallback(
        setParamValue.bind(null, param.name),
        [setParamValue, param.name],
    );
    const setState = useCallback(
        setParamState.bind(null, param.name),
        [setParamState, param.name],
    );
    /* eslint-enable react-hooks/exhaustive-deps */

    const state = getParamState(param.name);
    const value = getParamValue(param.name);

    const sourceTypeId = sourceType.id;

    /* eslint-disable indent-legacy, indent */
    /* Linter wants us to indent switch weirdly */
    switch (param.type) {

        case 'oauth1': {
            const props = {
                source,
                param,
                state,
                value,
                setValue,
                setState,
            };
            return <SourceOAuth1Param {...props} />;
        }

        case 'oauth2': {
            const props = {
                source,
                param,
                state,
                value,
                setValue,
                setState,
                sourceType,
            };
            return <SourceOAuth2Param {...props} />;
        }

        case 'dynamic-list': {
            const props = {
                param,
                state,
                value,
                setValue,
                setState,
                loadItems: loadDynamicParamItems,
            };
            return <SourceDynamicListParam {...props} />;
        }

        case 'static-list': {
            const props = {
                param,
                state,
                value,
                setValue,
                setState,
            };
            return <SourceStaticListParam {...props} />;
        }

        case 'dynamic-select': {
            const props = {
                param,
                state,
                value,
                setValue,
                setState,
                loadOptions: loadDynamicParamItems,
            };
            return <SourceDynamicSelectParam {...props} />;
        }

        case 'static-select': {
            const props = {
                param,
                value,
                setValue,
            };
            return <SourceStaticSelectParam {...props} />;
        }

        case 'static-autocomplete': {
            const props = {
                param,
                value,
                setValue,
            };
            return <SourceStaticAutocompleteParam {...props} />;
        }

        case 'dynamic-autocomplete': {
            const props = {
                param,
                state,
                value,
                setValue,
                setState,
                loadOptions: loadDynamicParamItems,
            };
            return <SourceDynamicAutocompleteParam {...props} />;
        }

        case 'upload': {
            const props = {
                param,
                value,
                setValue,
            };
            return <SourceUploadParam {...props} />;
        }

        case 'exclude': {
            const props = {
                param,
                value,
                setValue,
            };
            return <ExcludeParam {...props} />;
        }

        case 'include': {
            const props = {
                param,
                value,
                setValue,
            };
            return <IncludeParam {...props} />;
        }

        case 'parse-string': {
            const props = {
                param,
                value,
                setValue,
            };
            return <ParseStringParam {...props} />;
        }

        case 'primary-key': {
            const props = {
                param,
                value,
                setValue,
                errors: fieldValidationErrors,
            };
            return <PrimaryKeyParam {...props} />;
        }

        case 'destination': {
            const props = {
                param,
                value,
                setValue,
                errors: fieldValidationErrors,
            };
            return <DestinationParam {...props} />;
        }

        case 'destination-prefix': {
            const props = {
                param,
                value,
                setValue,
                errors: fieldValidationErrors,
            };
            return <DestinationPrefixParam {...props} />;
        }

        case 'delimiter': {
            const props = {
                param,
                value,
                setValue,
                errors: fieldValidationErrors,
            };
            return <DelimiterParam {...props} />;
        }

        case 'incremental-key': {
            const props = {
                param,
                value,
                setValue,
                sourceId: source.id,
            };
            return <IncrementalKeyParam {...props} />;
        }

        case 'truncate-table': {
            const props = {
                param,
                value,
                setValue,
            };
            return <TruncateTableParam {...props} />;
        }

        case 'past-days': {
            const props = {
                param,
                value,
                setValue,
            };
            return <PastDaysParam {...props} />;
        }

        case 'schema': {
            const props = {
                source,
                param,
                state,
                value,
                setValue,
                setState,
                loadSchemas,
            };
            return <SchemaParam {...props} />;
        }

        case 'checkbox': {
            const props = {
                param,
                value,
                setValue,
            };
            return <CheckboxParam {...props} />;
        }

        case 'date':
        case 'datetime': {
            const props = {
                param,
                value,
                setValue,
            };
            return <DateParam {...props} />;
        }

        case 'ssl': {
            const props = {
                param,
                value,
                setValue,
                errors: fieldValidationErrors,
            };
            return <SSLParam {...props} />;
        }

        case 'ssh': {
            const props = {
                param,
                value,
                setValue,
                errors: fieldValidationErrors,
            };
            return <SshParam {...props} />;
        }

        case 'validation': {
            const props = {
                param,
                state,
                setState,
                getParamValue,
                loadError: loadValidationParamError,
                flattenedParams,
            };
            return <ValidationParam {...props} />;
        }

        case 'group': {
            const props = {
                source,
                sourceType,
                param,
                flattenedParams,
                isParamRequired,
                getParamValue,
                setParamValue,
                getParamState,
                setParamState,
                areParamDepsMet,
                loadDynamicParam,
                loadValidationParam,
                fieldValidationErrors,
                loadSchemas,
            };
            return <GroupParam {...props} />;
        }

        case 'matrix': {
            const props = {
                param,
                value,
                setValue,
            };
            return <MatrixParam {...props} />;
        }

        case 'number':
        case 'email':
        case 'text':
        case 'password':
        case 'textarea':
        default: {
            const props = {
                param,
                value,
                setValue,
                sourceTypeId,
            };
            return <SourceInputParam {...props} />;
        }
    }
    /* eslint-enable indent-legacy, indent */
};

/** @type { React.FC<Props & { params?: Param[] }> } */
const SourceForm = (props) => {
    const { sourceType, areParamDepsMet, isParamRequired, category } = props;

    if (!sourceType.params) {
        return null;
    }

    /** @type { Param['type'][] } */
    const paramsWithoutDefaultRow = ['ssh', 'group'];

    const params = props.params || sourceType.params;
    const visibleParams = params.filter(param => (
        !param.hidden
        && areParamDepsMet(param.name)
        && param.category === category
    ));

    /** @type { (param: Param) => boolean } */
    const paramHasDefaultRow = (param) => !paramsWithoutDefaultRow.includes(param.type);

    return (
        <Layout>
            <form autoComplete="off">
                {visibleParams.map(param => (
                    paramHasDefaultRow(param) ? (
                        <FormRow
                            key={param.name}
                            label={param.title}
                            tooltipContent={param.help}
                            noMinHeight={!param.title}
                            required={isParamRequired(param)}
                            showOptionalLabel={category === 'general'}
                        >
                            <SourceParam {...props} param={param} />
                        </FormRow>
                    ) : (
                        <SourceParam {...props} key={param.name} param={param} />
                    )
                ))}
            </form>
        </Layout>
    );
};

export default SourceForm;
export { SourceParam };
