import { useEffect, useCallback, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

import { reportError } from '../../lib/error-reporter';
import * as DatabaseObjectsModel from '../../models/database-objects';
import * as ReportsModel from '../../models/reports';
import { loadColumns } from '../../models/columns';
import { useSession } from '../context-providers';

import {
    createSchemaItems,
    createReportGroup,
    populateReportItems,
    populateReportItem,
    populateSchemaChildItems,
    populateSchemaChildItem,
    populateParentChildItems,
    populateParentChildItem,
} from './tree-transformers';

/** @typedef { import('lib/query-builder').Column } Column */
/** @typedef { import('lib/query-builder').Position } Position */
/** @typedef { import('models/database-objects/__types').DatabaseObject } DatabaseObject */
/** @typedef { import('models/database-objects/__types').Table } TableObject */
/** @typedef { import('models/database-objects/__types').View } ViewObject */
/** @typedef { import('./__types').Mode } Mode */
/** @typedef { import('./__types').Table } Table */
/** @typedef { import('./__types').TreeItem } TreeItem */
/** @typedef { import('./__types').TreeNode } TreeNode */
/** @typedef { import('./__types').ViewData } ViewData */
/** @typedef { import('./__types').TableData } TableData */
/** @typedef { import('./__types').SchemaData } SchemaData */
/** @typedef { import('./__types').ReportData } ReportData */
/** @typedef { import('./__types').ViewItem } ViewItem */
/** @typedef { import('./__types').TableItem } TableItem */
/** @typedef { import('./__types').ReportItem } ReportItem */
/** @typedef { import('./__types').SchemaItem } SchemaItem */
/** @typedef { import('./__types').ColumnItem } ColumnItem */
/** @typedef { import('./__types').ReportGroup } ReportGroup */

/** @typedef { ReturnType<typeof useWorkbenchTree> } WorkbenchTree */

/**
 * @typedef { object } Params
 * @prop { (column: Column, position?: Position) => void } [addColumn]
 * @prop { boolean } [hasColumns]
 */

/**
 * @typedef { object } Props
 * @prop { boolean } dataIsReady
 * @prop { TableData[] } tables
 * @prop { ViewData[] } views
 * @prop { SchemaData[] } schemas
 * @prop { ReportData[] } reports
 * @prop { () => void } fetchTables
 * @prop { () => void } fetchViews
 * @prop { () => void } fetchReports
 */

/** @param { Props & Params } props */
function useWorkbenchTree({
    dataIsReady,
    tables,
    views,
    schemas,
    reports,
    fetchTables,
    fetchViews,
    fetchReports,
    addColumn,
    hasColumns,
}) {
    const navigate = useNavigate();

    const [session] = useSession();

    // Currently, reports are visible to those who can manage reports
    const canManageReports = session.scopes.includes('reports:manage');
    const canManageTables = session.scopes.includes('tables:manage');

    const [data, setData] = useState(/** @type { TreeItem[] } */ ([]));
    const [loading, setLoading] = useState(false);

    const cursorRef = useRef(/** @type { TreeNode } */ ({}));

    const [deleteObjectDialogOpen, setDeleteObjectDialogOpen] = useState(false);

    const [manageViewersDialogOpen, setManageViewersDialogOpen] = useState(false);

    const [deleteReportDialogOpen, setDeleteReportDialogOpen] = useState(false);

    const [objectToDelete, setObjectToDelete] = useState(
        /** @type { DatabaseObject= } */ (undefined)
    );

    const [objectToManage, setObjectToManage] = useState(
        /** @type { (TableObject | ViewObject)= } */ (undefined)
    );

    const [reportToDelete, setReportToDelete] = useState(
        /** @type { ReportItem= } */ (undefined)
    );

    const [schemaItems, setSchemaItems] = useState(
        /** @type { SchemaItem[] } */ ([])
    );

    const [reportGroup, setReportGroup] = useState(createReportGroup());

    const handleToggle = useRef(debounce(
        /**
         * @param { TreeNode } node
         * @param { boolean } toggled
         * @param { { silent?: boolean } & Params } [options]
         */
        (node, toggled, options) => {
            const { loading } = node;
            const { silent, ...params } = options || {};

            if (loading === true) {
                return;
            }

            cursorRef.current.active = false;

            if (node.children) {
                node.toggled = toggled;

                if (typeof loading === 'function') {
                    setLoading(true);
                    node.loading = true;

                    loading(node)
                        .then(children => {
                            populateParentChildItems(node, children, params);
                        })
                        .finally(() => {
                            node.loading = false;
                            setData(d => [...d]);
                            setLoading(false);
                        });
                }
            }

            node.active = true;
            cursorRef.current = node;

            if (!silent) {
                setData(d => [...d]);
            }
        },
        300,
        {
            leading: true,
            trailing: false,
            maxWait: 1000,
        }
    )).current;

    const onToggle = useCallback(
        /** @type { (node: TreeNode, toggled: boolean) => void } */
        (node, toggled) => {
            handleToggle(node, toggled, {
                addColumn,
                hasColumns,
            });
        },
        [addColumn, hasColumns]
    );

    /** @type { (node: TableItem | ViewItem | ReportItem) => void } */
    const onOpen = useCallback((node) => {
        navigate(node.type === 'table' ? (
            '/workbench/tables/' + [
                node.schema,
                node.name,
            ].map(encodeURIComponent).join('/')
        ) : (
            '/workbench/' + [
                node.type + 's',
                node.id,
            ].map(encodeURIComponent).join('/')
        ));
    }, []);

    /** @type { (object: DatabaseObject) => void } */
    const openDeleteObjectDialog = useCallback(object => {
        setObjectToDelete(object);
        setDeleteObjectDialogOpen(true);
    }, []);

    /** @type { () => void} */
    const closeDeleteObjectDialog = useCallback(() => {
        setDeleteObjectDialogOpen(false);
        setObjectToDelete(undefined);
    }, []);

    /** @type { (object: TableObject | ViewObject) => void } */
    const openManageViewersDialog = useCallback(object => {
        setObjectToManage(object);
        setManageViewersDialogOpen(true);
    }, []);

    /** @type { () => void} */
    const closeManageViewersDialog = useCallback(() => {
        setManageViewersDialogOpen(false);
        setObjectToManage(undefined);
    }, []);

    /** @type { (report: ReportItem) => void } */
    const openDeleteReportDialog = useCallback(report => {
        setReportToDelete(report);
        setDeleteReportDialogOpen(true);
    }, []);

    /** @type { () => void} */
    const closeDeleteReportDialog = useCallback(() => {
        setDeleteReportDialogOpen(false);
        setReportToDelete(undefined);
    }, []);

    /** @type { (object: DatabaseObject) => Promise<ViewObject[]> } */
    const fetchObjectDependencies = useCallback(async object => {
        if (session.database.type !== 'redshift') {
            return [];
        }
        return DatabaseObjectsModel.fetchObjectDependencies(object);
    }, [session.database.type]);

    /** @type { (object: DatabaseObject) => Promise<void> } */
    const deleteObject = useCallback(async object => {
        await DatabaseObjectsModel.deleteObject(object);

        switch (object.type) {
            case 'table':
                fetchTables();
                break;

            case 'view':
                fetchViews();
                break;
        }
    }, [fetchTables, fetchViews]);

    /** @type { (report: ReportItem) => Promise<void> } */
    const deleteReport = useCallback(async report => {
        await ReportsModel.removeReport(report.id);

        fetchReports();
    }, [fetchReports]);

    useEffect(() => {
        if (!dataIsReady) {
            return;
        }

        setSchemaItems(items => {
            const schemaItems = createSchemaItems(items, schemas);

            return populateSchemaChildItems(schemaItems, tables, views, {
                onOpen,
                openDeleteObjectDialog,
                openManageViewersDialog,
                canManageTables,
                loadColumns,
            });
        });
    }, [
        dataIsReady,
        schemas,
        tables,
        views,
        onOpen,
        openDeleteObjectDialog,
        openManageViewersDialog,
        canManageTables,
    ]);

    useEffect(() => {
        if (!dataIsReady || !canManageReports) {
            return;
        }

        setReportGroup(group => populateReportItems(group, reports, {
            onOpen,
            openDeleteReportDialog,
            canManageReports,
        }));
    }, [dataIsReady, reports, onOpen, openDeleteReportDialog, canManageReports]);

    useEffect(() => {
        if (loading || !reportGroup && !schemaItems.length) {
            return;
        }

        try {
            /** @type { TreeItem[] } */
            const items = [];

            if (reportGroup) {
                reportGroup.children.forEach(item => {
                    populateReportItem(item, {
                        onOpen,
                        openDeleteReportDialog,
                        canManageReports,
                    });
                });
                items.push(reportGroup);
            }

            schemaItems.forEach(schemaItem => {
                schemaItem.children.forEach(group => {
                    group.children.forEach(item => {
                        populateSchemaChildItem(item, {
                            onOpen,
                            openDeleteObjectDialog,
                            openManageViewersDialog,
                            canManageTables,
                        });

                        item.children?.forEach(child => {
                            populateParentChildItem(child, {
                                addColumn,
                                hasColumns,
                            });
                        });
                    });
                });
            });

            items.push(...schemaItems);

            // Be nice and toggle if we find just one schema
            if (schemaItems.length === 1) {
                handleToggle(schemaItems[0], true, {
                    silent: true,
                });
            }

            setData(items);

        } catch (/** @type { any } */ err) {
            reportError(err);
        }
    }, [
        loading,
        reportGroup,
        schemaItems,
        canManageReports,
        canManageTables,
        onOpen,
        openDeleteObjectDialog,
        openManageViewersDialog,
        openDeleteReportDialog,
        addColumn,
        hasColumns,
    ]);

    return {
        data,
        onToggle,
        objectToDelete,
        objectToManage,
        reportToDelete,
        deleteObjectDialogOpen,
        manageViewersDialogOpen,
        deleteReportDialogOpen,
        closeDeleteObjectDialog,
        closeManageViewersDialog,
        closeDeleteReportDialog,
        fetchObjectDependencies,
        deleteObject,
        deleteReport,
    };
}

export default useWorkbenchTree;
