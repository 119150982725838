/* eslint-disable complexity */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import MarkdownRenderer from '../../../shared/markdown-renderer';
import {
    colors,
    DebouncedInput,
    Select,
    SelectItem,
    Layout,
    Switch,
    DatePicker,
} from 'ui-components';


/** @typedef { import('models/sources/__types').IncrementalKeyParam } Param */
/** @typedef { import('models/sources/__types').IncrementalKeyParamValue } Value */

/**
 * @typedef { object } Props
 * @prop { Param } param
 * @prop { Value } value
 * @prop { (value: Value) => void } setValue
 * @prop { string } sourceId
*/

/** @type { React.FC<Props> } */
const IncrementalKeyRow = ({
    param,
    value,
    setValue,
    sourceId,
}) => {

    const {
        useDatepicker,
        preventDeselection,
        datepickerOptions,
    } = param || {};

    const incrementalFields = param.incrementalFields || [];

    const showDatepicker = useDatepicker === 'always'
        || (useDatepicker === 'optional' && value?.incrementalDate);

    // default datepicker type is datetime
    const datepickerType = !datepickerOptions?.type
        ? 'datetime'
        : datepickerOptions?.type;

    const disableFrom = param.datepickerOptions?.disableFrom === 'now'
        ? moment()
        : param.datepickerOptions?.disableFrom;

    const disableUntil = param.datepickerOptions?.disableUntil === 'now'
        ? moment()
        : param.datepickerOptions?.disableUntil;

    const onIncKeyChanged = useCallback((e) => {
        setValue({
            ...value,
            inckey: e.target.value,
            incval: e.target.value === value?.inckey
                ? value?.incval
                : '',
        });
    }, [setValue, value]);

    const onIncValChanged = useCallback((e) => {
        if (showDatepicker) {
            const moment = e; // Datepicker change handler gets passed a moment obj
            setValue({
                ...value,
                incval: moment.toISOString(),
            });
            return;
        }

        setValue({
            ...value,
            incval: e.target.value,
        });
    }, [setValue, value]);

    const onIncrementalDateChanged = useCallback((e) => {
        setValue({
            ...value,
            incrementalDate: e.target.checked,
        });
    }, [setValue, value]);

    const incKeyHelperText = (
        <MarkdownRenderer
            source={param.description?.incrementalKey || 'Enter a column name'}
        />
    );

    const incValHelperText = (
        <MarkdownRenderer
            source={param.description?.incrementalValue || 'Enter a column value (optional)'}
        />
    );

    return (
        <Layout flex width="100">
            <Layout width="50">
                {!incrementalFields.length && (
                    <DebouncedInput
                        value={value?.inckey || ''}
                        spacing="p-0"
                        width="100"
                        name={'inckey' + sourceId}
                        label="e.g. last_update_time"
                        noFloatLabel
                        onChange={onIncKeyChanged}
                        helperText={incKeyHelperText}
                        autoComplete={'inckey' + sourceId}
                    />
                )}

                {incrementalFields.length > 0 && (
                    <Select
                        noFloatLabel
                        name={'inckey' + sourceId}
                        spacing="p-0"
                        width="100"
                        value={value?.inckey || ''}
                        onChange={onIncKeyChanged}
                        helperText={incKeyHelperText}
                        autoComplete={'inckey' + sourceId}
                    >
                        <SelectItem disabled={!!preventDeselection} value="">
                            None
                        </SelectItem>
                        {incrementalFields.map(field => (
                            <SelectItem key={field.value} value={field.value}>
                                {field.name}
                            </SelectItem>
                        ))}
                    </Select>
                )}
            </Layout>

            {value?.inckey && (
                <Layout width="50">

                    {showDatepicker ? (
                        <DatePicker
                            value={value?.incval || ''}
                            type={datepickerType}
                            minutesStep={1}
                            spacing="p-0 ml-2"
                            width="100"
                            label="Optional starting value"
                            helperText={incValHelperText}
                            onChange={onIncValChanged}
                            maxDate={disableFrom}
                            minDate={disableUntil}
                        />
                    ) : (
                        <DebouncedInput
                            value={value?.incval || ''}
                            spacing="p-0 ml-2"
                            width="100"
                            name={'incval' + sourceId}
                            label="Optional starting value"
                            helperText={incValHelperText}
                            noFloatLabel
                            onChange={onIncValChanged}
                            autoComplete={'incval' + sourceId}
                        />
                    )}

                    {useDatepicker === 'optional' && (
                        <Layout>
                            <Switch
                                spacing="ml-3 mt-2"
                                type="checkbox"
                                name="incrementalDate"
                                checked={value?.incrementalDate || false}
                                onChange={onIncrementalDateChanged}
                                label={
                                    <>
                                        Select if value is ISODate format.{' '}
                                        <LearnMoreLink
                                            href="https://panoply.io/docs/incremental-loads#incremental-keys"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Learn&nbsp;more
                                        </LearnMoreLink>
                                    </>
                                }
                            />
                        </Layout>
                    )}
                </Layout>
            )}
        </Layout>
    );
};

const LearnMoreLink = styled('a')`
    text-decoration: underline;
    color: ${colors.text};
`;

export default IncrementalKeyRow;
