
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Autocomplete } from 'ui-components';
import MarkdownRenderer from '../../../../shared/markdown-renderer';
import useValidatedValues from '../../../../shared/source/use-validated-values';

/** @typedef { import('models/sources/__types').StaticAutocompleteParam } Param */
/** @typedef { import('models/sources/__types').AutocompleteParamItem } ParamOption */

/**
 * @typedef { object } Props
 * @prop { Param } param
 * @prop { ParamOption[] } value
 * @prop { (value: ParamOption[]) => void } setValue
 */

/** @type { React.FC<Props> } */
const StaticAutocompleteParam = ({
    param,
    value,
    setValue,
}) => {

    const { placeholder, maxSelectedItems } = param;
    const options = param.options || [];

    const {
        validatedOptions,
        validatedValues,
        invalidValues,
        isOptionSelected,
    } = useValidatedValues(value, options);

    const atMaxSelected = maxSelectedItems
        && value?.length >= maxSelectedItems;

    const maxSelectedMessage = maxSelectedItems && `
        You have selected the max allowed (${maxSelectedItems}) options.
    `;

    const onChange = useCallback((e, newValue) => {
        setValue(newValue.map(item => ({
            value: item.value,
            title: item.title,
            subtitle: item.subtitle,
        })));
    }, [setValue]);

    return (
        <FullWidthAutocomplete
            label={placeholder}
            onChange={onChange}
            error={!!invalidValues?.length}
            options={atMaxSelected ? [] : validatedOptions}
            noOptionsText={atMaxSelected ? maxSelectedMessage : 'No options match'}
            value={validatedValues || []}
            getOptionSelected={isOptionSelected}
            helperText={param.description && (
                <MarkdownRenderer source={param.description} />
            )}
            spacing="ml-0 pl-0"
            limit={5}
        />
    );
};

const FullWidthAutocomplete = styled(Autocomplete)`
    width: 100%;
`;

export default StaticAutocompleteParam;
