/* eslint-disable complexity */
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { parse } from 'qs';

import { oldTrack } from '../lib/tracking';
import {
    useSession,
    useFeedback,
    useToast,
} from '../shared/context-providers';

/** @typedef { import('./__types').PageProps } PageProps */
/** @typedef { import('./__types').PageRoute } Route */

/** @typedef { import('../lib/tracking').TrackEvent } TrackEvent */

/**
 * @typedef { object } Props
 *
 * @prop { Route['name'] } name
 * @prop { NonNullable<Route['Component']> } Component
 */

/** @type { React.FC<Props> } */
const AppPage = ({
    name,
    Component,
}) => {
    const [session] = useSession();
    const { user, database, billing } = session;
    const { sendFeedback } = useFeedback();
    const { openToast } = useToast();

    const location = useLocation();
    const params = useParams();

    useEffect(() => {
        if (name === 'home') {
            return;
        }

        /** @type { TrackEvent } */
        const event = {
            name: 'Pageview',
            type: 'pageview',
            page: {
                page_name: name,
                page_path: window.location.hash,
            },
        };

        // Available only when logged-in
        if (user.id && database.id) {
            event.user = {
                email: user.email || '',
                fname: user.fname || '',
                lname: user.lname || '',
                user_id: user.id || '',
                database_id: database.id || '',
                database_name: database.name || '',
                database_type: database.type || '',
                database_owner_id: database.owner_id || '',
                database_created_at: database.created_at || '',
                database_on_trial: !!billing.onTrial,
                plan: billing.plan?.name || 'trial',
            };
        }

        /** @deprecated */
        oldTrack(event);
    }, [name, location, user, database, billing]);

    const queryParams = parse(
        location.search,
        { ignoreQueryPrefix: true },
    );

    const otherProps = {
        openToast,
        sendFeedback,
    };

    return (
        <Component
            {...queryParams}
            {...params}
            {...otherProps}
        />
    );
};

export default AppPage;
