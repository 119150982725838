import React, { useState, useCallback } from 'react';
import {
    Typography,
    Dialog,
    Button,
} from 'ui-components';
import { useRequest } from '../../lib/ajax';

/** @typedef { import('../../models/jobs/__types').Job } Job */

/**
 * @param { () => void } [onCancelCallback]
 */
export const useJobCancelDialog = (onCancelCallback) => {

    const [jobToCancel, setJobToCancel] = useState(/** @type { Job } */ ({}));

    const onCancelJob = useCallback((/** @type { Job= } */job) => {
        if (job) {
            setJobToCancel(job);
        }
    }, []);

    const {
        go: cancelJob,
        clear: clearCancelJob,
        ...cancelJobReq
    } = useRequest(`/jobs/${jobToCancel.id}`, 'DELETE');

    const onCloseCancelDialog = useCallback(() => {
        setJobToCancel(/** @type { Job } */ ({}));
    }, []);

    const onCancelJobConfirm = useCallback(() => {
        cancelJob().then((res) => {
            if (!res.ok) {
                return;
            }
            onCloseCancelDialog();
            clearCancelJob();

            if (onCancelCallback) {
                onCancelCallback();
            }

        });
    }, [cancelJob, clearCancelJob, onCancelCallback, onCloseCancelDialog]);

    return {
        jobToCancel,
        onCancelJob,
        onCloseCancelDialog,
        onCancelJobConfirm,
        loading: cancelJobReq.loading,
        error: cancelJobReq.error,
    };
};

/**
 * @typedef { object } Props
 * @prop { Job } jobToCancel
 * @prop { () => void } onCloseCancelDialog
 * @prop { () => void } onCancelJobConfirm
 * @prop { boolean } loading
 * @prop { { message?: string } } [error]
 */

/** @type { React.FC<Props> } */
export const JobCancelDialog = ({
    jobToCancel,
    onCloseCancelDialog,
    onCancelJobConfirm,
    loading,
    error,
}) => (
    <Dialog
        title="Cancel job?"
        justifyContent="center"
        isOpen={!!jobToCancel.id}
        onClose={onCloseCancelDialog}
        actions={[
            <Button
                key={1}
                onClick={onCloseCancelDialog}
                type="plain"
            >
                No, Don’t Cancel
            </Button>,
            <Button
                key={2}
                onClick={onCancelJobConfirm}
                loading={loading}
                color="primary"
            >
                Yes, Cancel
            </Button>,
        ]}
    >
        {error ? (
            <Typography variant="subtitle1" color="error">
                {error.message}
            </Typography>
        ) : (
            <Typography variant="subtitle1">
                Data that has already been processed will be preserved.
            </Typography>
        )}
    </Dialog>
);

export default JobCancelDialog;
