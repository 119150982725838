import React from 'react';
import styled from 'styled-components';

import {
    Typography,
    Loader,
} from 'ui-components';

import LoaderLogoPath from '../../images/logo-panoply-gray.svg';


/** @type { React.FC<{}> } */
const CreatingDataWarehouse = () => {
    return (
        <>
            <MassiveLoaderWithLogo big color="secondaryText" active />

            <Typography
                variant="h5"
                align="center"
                weight="semibold"
                spacing="my-6"
            >
                Prepping your account
            </Typography>

            <Typography variant="body1" color="secondaryText" spacing="mb-6" align="center">
                Just 1 more step and you can start syncing data!
            </Typography>

            <Typography variant="body1" color="secondaryText" spacing="mb-6" align="center">
                This may take a few moments.<br/>
                Once your account is ready, this page will reload.
            </Typography>
        </>
    );
};


const MassiveLoaderWithLogo = styled(Loader)`
    background-image: url(${LoaderLogoPath});
    background-position: center;
    background-repeat: no-repeat;
    & *[role=progressbar] {
        width: 124px!important;
        height: 124px!important;
        svg circle {
            stroke-width: 2px!important;
        }
    }
`;

export default CreatingDataWarehouse;
