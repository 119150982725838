import { useOperators, OperatorType } from 'mingo/core';
import { isNumber, isEmpty, get } from 'lodash';

/** @typedef { import('mingo/core').QueryOperator } QueryOperator */

/**
 * isEmpty always returns true for numbers or false,
 * so we need to handle all possible cases we find.
 *
 * @type { (value: any) => boolean }
 */
export const isValueEmpty = (value) => {
    if (isNumber(value)) {
        return value === 0;
    }
    return typeof value !== 'boolean' && isEmpty(value);
};

/** @link {https://github.com/kofrasa/mingo/wiki/Custom-Operators} */
useOperators(OperatorType.QUERY, { // eslint-disable-line react-hooks/rules-of-hooks
    $isEmpty: /** @type { QueryOperator } */ (selector, value) => {
        return (obj) => {
            return isValueEmpty(get(obj, selector)) === value;
        };
    },
});

export { Query } from 'mingo';
