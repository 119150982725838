import React, { useMemo } from 'react';

import { Typography } from 'ui-components';

import PageLayout from '../../shared/page-layout';
import PageHeader from '../../shared/page-header';
import TeamsList from './teams-list';
import DeleteUserDialog from './delete-user-dialog';
import useUsersPage from './use-users-page';
import { useSession } from '../../shared/context-providers';
import MfaTable from './users-mfa-table';

/** @typedef { import('../../app/__types').PageProps } PageProps */

/** @type { React.FC<PageProps> } */
const UsersPage = ({ openToast }) => {
    const {
        state,
        teams,
        teamsLoading,
        updateLoading,
        resendLoading,
        teamsError,
        updateError,
        onUserDelete,
        onUserInvite,
        onCloseDeleteDialog,
        onDeleteUserConfirm,
        onResendInvite,
    } = useUsersPage({ openToast });

    const [session] = useSession();
    const mfaEnabled = session?.database?.mfa;

    const setOfUsers = useMemo(() => {
        const mapOfUsers = teams.reduce((prevState, team) => {
            team.users?.forEach(u => {
                if (!prevState[u?.id]) {
                    prevState[u?.id] = u;
                }
            });
            return prevState;
        }, {});
        return Object.values(mapOfUsers);
    }, [teams]);


    const listProps = {
        state,
        teams,
        teamsLoading,
        updateLoading,
        resendLoading,
        onUserDelete,
        onResendInvite,
        onUserInvite,
    };

    const dialogProps = {
        state,
        teams,
        updateLoading,
        updateError,
        onCloseDeleteDialog,
        onDeleteUserConfirm,
    };

    return (
        <PageLayout>
            {mfaEnabled && <MfaTable mfaUsers={setOfUsers} />}

            <PageHeader title="Teams" />

            <Typography variant="body1" spacing="-mt-4">
                Manage who has access to your data warehouse by adding or removing
                users from teams.
            </Typography>

            <Typography variant="body1" spacing="-my-1">
                Users inherit their permissions based on what team they are added to.
            </Typography>

            {!teamsError && (
                <TeamsList {...listProps} />
            )}

            <DeleteUserDialog {...dialogProps} />
        </PageLayout>
    );
};

export default UsersPage;
