import React from 'react';
import styled from 'styled-components';

import {
    Loader,
    GlobalStyleBoundary,
    Layout,
    Toast,
    breakpoints,
} from 'ui-components';

import AppHeader from '../app-header';
import SignedOutNotifier from './signed-out-notifier';
import FeedbackDialog from './feedback-dialog';
import WarningDialog from './warning-dialog';

/** @typedef { import('../__types').Feedback } Feedback */
/** @typedef { import('../__types').Toast } Toast */

/**
 * @typedef { object } Props
 * @prop { boolean } fullbleed
 * @prop { Feedback } feedback
 * @prop { Toast } toast
 * @prop { React.ReactNode } children
 */

/** @type { React.FC<Props> } */
const AppLayout = ({
    fullbleed = true,
    feedback,
    toast,
    children,
}) => (
    <GlobalStyleBoundary>
        <>
            <Container flex flexDirection="column">
                {!fullbleed && <AppHeader />}

                <View
                    relative
                    flex
                    flexDirection="column"
                    grow="1"
                    basis="0"
                    fullbleed={fullbleed}
                >
                    <Main id="main-container">
                        {children || <Loader big active />}
                    </Main>
                </View>
            </Container>

            {!fullbleed && <SignedOutNotifier />}
            {!fullbleed && <WarningDialog />}
            {!fullbleed && <FeedbackDialog {...feedback} />}
            {!fullbleed && <Toast {...toast} />}

        </>
    </GlobalStyleBoundary>
);

const Container = styled(Layout)`
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
`;

const View = styled(Layout)`
    max-height: 100vh;

    ${({ fullbleed }) => !fullbleed && `
        max-height: calc(100vh - 54px);

        @media (min-width: ${breakpoints.md}) {
            max-height: calc(100vh - 60px);
        }

        @media (min-width: ${breakpoints.xl}) {
            max-height: calc(100vh - 63px);
        }
    `}
`;

const Main = styled.div`
    display: flex;
    height: 100%;
    overflow: auto;
`;

export default AppLayout;
