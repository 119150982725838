import React from 'react';
import styled from 'styled-components';

import {
    Select,
    SelectItem,
    Typography,
} from 'ui-components';

import { useSession } from '../context-providers';

/** @typedef { import('app/__types').ChangeEvent } ChangeEvent */
/** @typedef { import('models/queries/__types').QueryResult } QueryResult */

const pageSizes = [
    500,
    1000,
    3000,
    10000,
];

/**
 * @typedef { object } WorkbenchPageSizeSelectorProps
 * @prop { number } pageSize
 * @prop { (newPage: number) => void } updatePageSize
 * @prop { string } [spacing]
 */

/** @type { React.FC<WorkbenchPageSizeSelectorProps> } */
const WorkbenchPageSizeSelector = ({
    pageSize,
    updatePageSize,
    spacing,
}) => {
    const [session] = useSession();
    const { database } = session;
    const showPageSize = database.type !== 'redshift';

    if (!showPageSize) {
        return null;
    }

    return (
        <>
            <MiniSelect
                value={String(pageSize)}
                onChange={(/** @type { ChangeEvent } */ e) => {
                    updatePageSize(Number(e.target.value));
                }}
                spacing={'p-0' + (spacing ? ` ${spacing}` : '')}
                noFloatLabel
            >
                {pageSizes.map((size) => (
                    <SelectItem key={size} value={String(size)}>
                        {size}
                    </SelectItem>
                ))}
            </MiniSelect>

            <Caption>
                Rows per page
            </Caption>
        </>
    );
};

const Caption = styled(({ children, ...props }) => (
    <Typography
        inline
        component="span"
        color="interface"
        spacing="my-0 ml-2"
        noWrap
        {...props}
    >
        {children}
    </Typography>
))`
    && {
        line-height: 1.5;
    }
`;

const MiniSelect = styled(Select)`
    &&& {
        .select {
            padding: 7px;
            padding-right: 21px;
            min-width: unset;
            font-size: 14px;
            margin: 0px;
            min-height: unset;
            line-height: 14px;
        }
        .icon {
            right: 0px;
        }
    }
`;

export default WorkbenchPageSizeSelector;
