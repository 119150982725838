import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { isPlainObject } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';

import {
    Dialog,
    Button,
    Typography,
    Divider,
    Chip,
    Layout,
    Autocomplete,
    Tooltip,
    Icon,
} from 'ui-components';

import { reportError } from '../../lib/error-reporter';
import { getFilename } from '../../lib/pdf';
import delay from '../../lib/delay';
import isValidEmail from '../../lib/is-valid-email';
import MarkdownRenderer from '../../shared/markdown-renderer';
import { fetchTeams } from '../../models/teams';
import { shareDashboard } from '../../models/dashboards';
import { getDashboardPDFData } from './dashboard-pdf';

/** @typedef { import('models/teams').Team } Team */
/** @typedef { import('models/teams').User } User */
/** @typedef { import('./types').SharingItem } SharingItem */
/** @typedef { 'primary' | 'secondary' | 'default' } Color */
/** @typedef { Option | string } OptionType */
/** @typedef { OptionType | OptionType[] | null } Value */

/**
 * @typedef { object } Option
 * @prop { string } title
 * @prop { string } [subtitle]
 * @prop { string } [category]
 * @prop { boolean } [disabled]
 * @prop { boolean } [error]
 */

/** @param { string } email */
function isSlackEmail(email) {
    return email.endsWith('slack.com');
}

/** @type { (email: string, name: string) => Color } */
function resolveColor(email, name) {
    if (isSlackEmail(email)) {
        return 'primary';
    }

    if (['Invitee', 'Email'].includes(name)) {
        return 'default';
    }

    return name ? 'secondary' : 'default';
}

/** @type { (email: string, name: string) => string } */
function resolveTooltip(email, name) {
    if (isSlackEmail(email)) {
        const username = email.split('@')[0];

        const type = username.includes('-')
            ? 'Channel'
            : 'User';

        return `Slack ${type}`;
    }

    return name;
}

/** @param { string } email */
function resolveLabel(email) {
    if (isSlackEmail(email)) {
        const username = email.split('@')[0];

        // If the username contains a dash, it's a Slack channel.
        if (username.includes('-')) {
            // Remove the last part of the username, and leave the rest.
            // Example: `panoply-apps-team-<hash>` -> `panoply-apps-team`
            return `#${username.split('-').slice(0, -1).join('-')}`;
        }

        // If the username doesn't contain a dash, it's a Slack user.
        // Unfortunately, we can't get the real name of the user, so we just
        // display the username, which is the part before the `@` symbol.
        if (username.length > 15) {
            return `@${username.slice(0, 8)}...${username.slice(-4)}`;
        }

        return `@${username}`;
    }

    return email;
}

/** @param { User } user */
function resolveName(user) {
    return `${user.fname || ''} ${user.lname || ''}`.trim() || 'User';
}

/** @type { <T extends Team>(a: T, b: T) => number } */
function sortByName(a, b) {
    return a.name.localeCompare(b.name);
}

/** @type { (name: string) => string } */
function capitalize(name) {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
}

/** @type { (value: Value) => value is Option } */
function isOptionObject(value) {
    return isPlainObject(value);
}

/**
 * @typedef { object } Props
 * @prop { boolean } shareDialogOpen
 * @prop { (sharingItem?: SharingItem) => void } toggleShareDialog
 * @prop { SharingItem } [sharingItem]
 */

/** @type { React.FC<Props> } */
const ShareDialog = ({
    shareDialogOpen,
    toggleShareDialog,
    sharingItem,
}) => {
    const { id } = useParams();
    const [uniqueId, setUniqueId] = useState(uuidv4());

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState('');
    const [status, setStatus] = useState('');

    const [recipients, setRecipients] = useState(
        /** @type { Record<string, string> } */ ({}),
    );

    const disabled = !!error
        || loading
        || !Object.keys(recipients).length;

    const {
        data: teams,
        isLoading,
        isError,
    } = useQuery({
        enabled: shareDialogOpen,
        queryKey: ['teams'],
        queryFn: fetchTeams,
    });

    const allOptions = useMemo(() => {
        if (!teams || isLoading || isError) {
            return [];
        }

        return teams.sort(sortByName).reduce((acc, team) => {
            return acc.concat(
                team.users.map(user => ({
                    title: user.email,
                    subtitle: resolveName(user),
                    category: capitalize(team.name),
                })),
                team.invites.map(invite => ({
                    title: invite.email,
                    subtitle: 'Invitee',
                    category: capitalize(team.name),
                })),
            );
        }, /** @type { Option[] } */ ([]));
    }, [teams, isLoading, isError]);

    const options = useMemo(() => {
        if (!allOptions.length) {
            return [];
        }

        return allOptions.map(option => ({
            ...option,
            disabled: !!recipients[option.title],
        }));
    }, [allOptions, recipients]);

    /** @type { (option: OptionType) => string } */
    const getOptionLabel = (option) => {
        if (isOptionObject(option)) {
            return option.title || '';
        }

        return option || '';
    };

    /**
     * @type { (
     *     event: React.ChangeEvent<HTMLInputElement | HTMLDivElement>,
     *     value: Value
     * ) => void }
     */
    const onChange = (e, newValue) => {
        e.preventDefault();
        e.stopPropagation();

        setError('');

        if (!newValue) {
            return;
        }

        const email = isOptionObject(newValue) ? newValue.title : String(newValue);

        if (!isValidEmail(email)) {
            setError('Invalid email');
            return;
        }

        const name = isOptionObject(newValue)
            ? newValue.subtitle
            : options.find(o => o.title === email)?.subtitle;

        setRecipients(recipients => ({
            ...recipients,
            [email]: name || 'Email',
        }));

        setUniqueId(uuidv4());
    };

    /** @type { (email: string) => void } */
    const onDelete = useCallback((email) => {
        setRecipients(recipients => {
            const newRecipients = { ...recipients };
            delete newRecipients[email];
            return newRecipients;
        });
    }, []);

    const share = useCallback(async () => {
        if (disabled || !sharingItem || !id) {
            return;
        }

        setLoading(true);
        setStatus('');

        await delay(300); // wait for the loading spinner to appear

        try {
            const { title, reportId } = sharingItem;

            const data = await getDashboardPDFData(title, reportId);
            const filename = getFilename(title);

            await shareDashboard(id, {
                title,
                recipients: Object.keys(recipients),
                data,
                filename,
                reportId,
            });

            setStatus('success');
            toggleShareDialog();

        } catch (/** @type { any } */ err) {
            reportError(err);
            setStatus('error');

        } finally {
            setLoading(false);
        }
    }, [disabled, id, recipients, sharingItem]);

    useEffect(() => {
        if (!shareDialogOpen) {
            return () => {};
        }

        /** @type { (e: KeyboardEvent) => void } */
        const onKeyDown = e => {
            if (e.key === 'Escape') {
                e.preventDefault();
                toggleShareDialog();
            } else if (e.key === 'Enter' && !disabled) {
                e.preventDefault();
                share();
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    }, [shareDialogOpen, disabled, toggleShareDialog, share]);

    const actions = [
        <Button
            key="cancel"
            type="plain"
            spacing="ml-0"
            onClick={() => toggleShareDialog()}
            disabled={loading}
        >
            Cancel
        </Button>,
        <Button
            key="share"
            color="primary"
            spacing="mr-0"
            onClick={share}
            disabled={disabled}
            loading={loading}
        >
            Share
        </Button>,
    ];

    if (status === 'error') {
        actions.unshift(<Error key="error" />);
    } else if (status === 'success') {
        actions.unshift(<Success key="success" />);
    }

    return (
        <Dialog
            compact
            title="Share dashboard"
            isOpen={shareDialogOpen}
            onClose={() => toggleShareDialog()}
            actions={actions}
        >
            <Divider spacing="m-0 -mx-4 mb-5" />

            <Layout flex width="100">
                <Typography
                    color="text"
                    variant="subtitle1"
                    weight="medium"
                    spacing="m-0 mt-2"
                >
                    Recipients
                </Typography>

                <Layout spacing="mt-3 ml-2 mr-3">
                    <Tooltip
                        content={
                            <MarkdownRenderer
                                source={[
                                    'Share via email with your colleagues.',
                                    'Select from the list of Panoply users',
                                    'or enter other email addresses',
                                ].join(' ')}
                            />
                        }
                        placement="top"
                        interactive={false}
                    >
                        <Icon icon="info-circle" color="secondaryText" />
                    </Tooltip>
                </Layout>

                <Layout grow="1">
                    <Tooltip
                        block
                        content={open ? 'Press Enter to add an email' : ''}
                        placement="top-start"
                        enterDelay={100}
                    >
                        <Autocomplete
                            key={`recipients-${uniqueId}`}
                            label="Enter email addresses"
                            type="email"
                            width="100"
                            spacing="p-0"
                            options={options}
                            onChange={onChange}
                            getOptionSearchableString={getOptionLabel}
                            onOpen={() => setOpen(true)}
                            onClose={() => setOpen(false)}
                            multiple={false}
                            autoSelect={false}
                            helperText={error}
                            error={!!error}
                            freeSolo
                        />
                    </Tooltip>
                </Layout>
            </Layout>

            {Object.keys(recipients).length > 0 && (
                <Layout spacing="mt-2 -mr-2">
                    {Object.entries(recipients).map(([email, name]) => (
                        <Chip
                            key={email}
                            color={resolveColor(email, name)}
                            label={resolveLabel(email)}
                            tooltipText={resolveTooltip(email, name)}
                            onDelete={() => onDelete(email)}
                            spacing="mt-2 mr-2"
                        />
                    ))}
                </Layout>
            )}
        </Dialog>
    );
};

/** @type { React.FC<{ children: React.ReactNode, spacing?: string, color?: string }> } */
const Help = ({ children, ...props }) => (
    <Typography color="secondaryText" {...props}>
        {children}
    </Typography>
);

/** @type { React.FC } */
const Error = () => (
    <Status>
        <Icon
            prefix="fas"
            icon="exclamation-circle"
            size="sm"
            color="error"
            spacing="mr-2"
        />
        <Help>
            Error, can&apos;t share
        </Help>
    </Status>
);

/** @type { React.FC } */
const Success = () => (
    <Status>
        <Icon
            icon="check"
            size="sm"
            color="secondary"
            spacing="mr-2"
        />
        <Help>
            Shared
        </Help>
    </Status>
);

/** @type { React.FC<{ children: React.ReactNode }> } */
const Status = ({ children }) => (
    <StyledLayout flex alignItems="center">
        {children}
    </StyledLayout>
);

const StyledLayout = styled(Layout)`
    && {
        padding: 0.55rem 1rem 0.45rem;
    }
`;

export default ShareDialog;
