import React, { useCallback } from 'react';

import { Layout } from 'ui-components';

import PlansListItem from './plans-list-item';

function getCardSpacing(index, total) {
    if (total === 1) {
        return null;
    }
    if (index === 0) {
        return 'mr-1';
    }
    if (index === (total - 1)) {
        return 'ml-1';
    }
    return 'mx-1';
}

const PlansList = ({ plans, onSelect }) => {
    const getPlansListItemProps = useCallback((plan, index) => ({
        plan,
        width: Math.min(33, Math.floor(100 / plans.length)),
        spacing: getCardSpacing(index, plans.length),
        onClick: () => onSelect(plan),
    }), [plans, onSelect]);

    return (
        <Layout width="100" justifyContent="center" flex>
            {plans.map((plan, index) => (
                <PlansListItem key={plan.id} {...getPlansListItemProps(plan, index)} />
            ))}
        </Layout>
    );
};

export default PlansList;
