import { useEffect, useState, useCallback } from 'react';
import md5 from 'md5';

import { head } from '../lib/ajax';

/** @typedef { import('../app/__types').Session } Session */

/**
 * @typedef { object } ReturnProps
 *
 * @prop { string } initials
 * @prop { string } avatar
 */

/** @type { (user: Session['user']) => ReturnProps } */
function useAvatar(user) {
    const [avatar, setAvatar] = useState(user.avatar || '');

    const name = user.displayname || user.fname || user.username || '';
    const initials = name.slice(0, 1).toUpperCase();

    const pingGravatar = useCallback(async (hash) => {
        try {
            return await head(`https://en.gravatar.com/${hash}.json`);
        } catch (error) {
            return error;
        }
    }, []);

    useEffect(() => {
        if (user.avatar || !user.email) {
            return;
        }

        const hash = md5(user.email.trim().toLowerCase());

        // If none is associated with the email hash,
        // returns an HTTP 404 (File Not Found) status.
        pingGravatar(hash)
            .then((res) => {
                if (!res.ok) {
                    return;
                }
                setAvatar(`https://secure.gravatar.com/avatar/${hash}`);
            });
    }, [user, pingGravatar]);

    return {
        initials,
        avatar,
    };
}

export default useAvatar;
