import React from 'react';

import { items } from './use-workbench-visualization';

/** @typedef { import('./__types').VisualizationProps } VisualizationProps */

/** @type { React.FC<VisualizationProps> } */
const WorkbenchVisualization = (props) => {
    const {
        queryData,
        visualizationReport,
    } = props;

    const { isDisabled } = items[visualizationReport.type];

    if (isDisabled(queryData)) {
        return null;
    }

    const { Component } = items[visualizationReport.type];

    return (
        <Component {...props} />
    );
};

export default WorkbenchVisualization;
