import React, { useState, useCallback } from 'react';

import {
    Button,
    Dialog,
    Typography,
} from 'ui-components';

import gotoUrl from '../../lib/goto-url';

/** @type { () => boolean } */
function isMobile() {
    return window.innerWidth < 815;
}

/** @type { React.FC } */
const WarningDialog = () => {
    const [open, setOpen] = useState(isMobile());

    const onClosed = useCallback(() => {
        if (open) {
            setOpen(false);
        }
    }, [open]);

    const onLogout = useCallback(() => {
        gotoUrl('/users/logout');
    }, []);

    return (
        <Dialog
            isOpen={open}
            onClose={onClosed}
            title="So Sorry!"
            actions={(
                <Button
                    onClick={onLogout}
                    color="error"
                    spacing="mx-0"
                >
                    Logout
                </Button>
            )}
        >
            <Typography>
                Panoply is best viewed on a desktop.
                Tiny screens lead to tiny data.
            </Typography>

            <Typography>
                Please visit us from your desktop.
            </Typography>
        </Dialog>
    );
};

export default WarningDialog;
