import React from 'react';
import { filter } from 'lodash';

import {
    Typography,
    Button,
    Dialog,
} from 'ui-components';

/** @typedef { import('./__types').User } User */
/** @typedef { import('./__types').Team } Team */
/** @typedef { import('./__types').State } State */

/** @type { (u: User, t: Team, ts: Team[]) => boolean } */
function isMemberOfOtherTeams(user, team, teams) {
    const otherTeams = filter(teams, t => t.id !== team.id);
    return otherTeams.some(t => {
        const users = t.users || [];
        return users.some(u => u.email === user.email);
    });
}

/**
 * @typedef { object } Props
 *
 * @prop { State } state
 * @prop { Team[] } teams
 * @prop { boolean } updateLoading
 * @prop { { message: string } } updateError
 * @prop { () => void } onCloseDeleteDialog
 * @prop { () => void } onDeleteUserConfirm
 */

/** @type { React.FC<Props> } */
const DeleteUserDialog = ({
    state,
    teams,
    updateLoading,
    updateError,
    onCloseDeleteDialog,
    onDeleteUserConfirm,
}) => {
    const { userToDelete, teamToUpdate } = state;

    const buttonProps = {
        onClick: onDeleteUserConfirm,
        loading: updateLoading,
    };

    const dialogProps = {
        isOpen: !!userToDelete.id,
        onClose: onCloseDeleteDialog,
        actions: [
            <Button key={1} type="outline" onClick={onCloseDeleteDialog}>Cancel</Button>,
            <Button key={2} color="primary" spacing="mr-0" {...buttonProps}>Delete</Button>,
        ],
    };

    if (updateError) {
        return (
            <Dialog title="Delete User" {...dialogProps}>
                <Typography variant="subtitle1" color="error">
                    {updateError.message}
                </Typography>
            </Dialog>
        );
    }

    if (userToDelete.token || isMemberOfOtherTeams(userToDelete, teamToUpdate, teams)) {
        return (
            <Dialog title="Delete User" {...dialogProps}>
                <Typography variant="subtitle1">
                    Are you sure you want to remove {userToDelete.email} from {teamToUpdate.name}?
                </Typography>
            </Dialog>
        );
    }

    return (
        <Dialog title="Delete User" {...dialogProps}>
            <Typography variant="subtitle1">
                Are you sure you want to remove {userToDelete.email} from the database?
            </Typography>
        </Dialog>
    );
};

export default DeleteUserDialog;
