import React, { useCallback } from 'react';
import { Switch } from 'ui-components';
import MarkdownRenderer from '../../../shared/markdown-renderer';

/** @typedef { import('models/sources/__types').CheckboxParam } Param */
/** @typedef { import('models/sources/__types').CheckboxParamValue } Value */

/**
 * @typedef { object } Props
 * @prop { Param } param
 * @prop { Value } value
 * @prop { (value: Value) => void } setValue
*/


/** @type { React.FC<Props> } */
const CheckboxParam = ({
    param,
    value,
    setValue,
}) => {
    const onChange = useCallback((e) => {
        setValue(e.target.checked);
    }, [setValue, value]);

    return (
        <>
            {/* Checkbox is built to be bigger then it visually is */}
            {/* So that its easily clickable/tapable */}
            {/* so we apply negative margin to make it line up with the rest of the elements */}
            <Switch
                spacing="m-0 -ml-2"
                checked={value || false}
                type="checkbox"
                onChange={onChange}
                name="checkbox"
                label={<MarkdownRenderer source={param.description} />}
            />
        </>
    );
};

export default CheckboxParam;
