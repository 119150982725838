import { mergeWith, isArray } from 'lodash';

/* eslint-disable complexity */

/** @typedef { import('./__types').State } State */
/** @typedef { import('./__types').Action } Action */
/** @typedef { import('./__types').Changes } Changes */
/** @typedef { import('./__types').Callback } Callback */

/** @type { State } */
export const initialState = {
    loading: false,
    deleting: false,
    saving: false,
    deleteModalOpen: false,
    renameModalOpen: false,
    scheduleModalOpen: false,
    syncModalOpen: false,
    actionsDropdownOpen: false,
    paramState: {},
    flattenedParams: [],
    pendingCallbacks: [],
    pendingChanges: {},
    historyLoading: false,
    scheduleSaving: false,
    fieldValidationErrors: {},
};

/** @type { <T>(obj: T, changes: Changes, replace?: boolean) => T } */
const applyChanges = (obj, changes, replace = false) => {
    // lodash `merge` attempts to merge arrays as objects with indexes as keys
    // We use `mergeWith` instead to disable that so we merge only actual objects
    return mergeWith(
        {},
        obj,
        changes,
        (_, b) => (isArray(b) || replace ? b : undefined)
    );
};

/** @type { (changes: Changes, replace?: boolean) => Callback } */
const createPendingCallback = (changes, replace) => {
    return obj => applyChanges(obj, changes, replace);
};

/** @type { (state: State, action: Action) => State } */
export default (state, action) => {
    switch (action.type) {

        case 'SOURCE_LOADING':
            return {
                ...state,
                loading: action.loading,
                loadingError: undefined,
            };

        case 'SOURCE_LOADED':
            return {
                ...state,
                ...initialState,
                source: action.source,
                editingSource: action.source,
                sourceDefaults: action.sourceDefaults,
                sourceType: action.sourceType,
                flattenedParams: action.flattenedParams,
                schedule: action.schedule,
                lastSavedSchedule: action.schedule,
                recipes: action.recipes,
            };

        case 'SOURCE_LOADING_ERROR':
            return {
                ...state,
                loading: false,
                loadingError: action.error,
            };

        case 'SOURCE_REFRESHING_ERROR':
            return {
                ...state,
                refreshingError: action.error,
            };

        case 'SOURCE_DELETING':
            return {
                ...state,
                deleting: true,
                deletingError: undefined,
            };

        case 'SOURCE_DELETED':
            return {
                ...state,
                deleting: false,
                deletingError: undefined,
            };

        case 'SOURCE_DELETING_ERROR':
            return {
                ...state,
                deleting: false,
                deletingError: action.error,
            };

        case 'SOURCE_SAVING':
            return {
                ...state,
                saving: true,
                savingError: undefined,
            };

        case 'SOURCE_SAVED':
            return {
                ...state,
                saving: false,
                savingError: undefined,
                pendingCallbacks: [],
                pendingChanges: {},
                source: state.editingSource,
            };

        case 'SOURCE_SAVING_ERROR':
            return {
                ...state,
                saving: false,
                savingError: action.error,
            };

        case 'SOURCE_SCHEDULE_UPDATED':
            return {
                ...state,
                schedule: action.schedule,
            };

        case 'SOURCE_SCHEDULE_SAVING':
            return {
                ...state,
                scheduleSaving: true,
                schedulingError: undefined,
            };

        case 'SOURCE_SCHEDULE_SAVED':
            return {
                ...state,
                lastSavedSchedule: action.schedule,
                schedule: action.schedule,
                scheduleSaving: false,
            };

        case 'SOURCE_SCHEDULE_SAVING_ERROR':
            return {
                ...state,
                schedulingError: action.error,
                scheduleSaving: false,
            };

        case 'SET_SOURCE':
            return {
                ...state,
                source: action.source,
                editingSource: state.pendingCallbacks.reduce(
                    (obj, cb) => cb(obj),
                    action.source
                ),
                collectingError: undefined,
                refreshingError: undefined,
            };

        case 'SOURCE_COLLECTING_ERROR':
            return {
                ...state,
                collectingError: action.error,
            };

        case 'SET_PARAM_STATE':
            return {
                ...state,
                paramState: {
                    ...state.paramState,
                    [action.name]: action.newState,
                },
            };

        case 'SET_PENDING_CHANGES':
            return {
                ...state,
                pendingCallbacks: [
                    createPendingCallback(
                        action.changes
                    ),
                ],
                pendingChanges: action.changes,
                editingSource: applyChanges(
                    state.source,
                    action.changes,
                ),
            };

        case 'ADD_PENDING_CHANGES':
            return {
                ...state,
                pendingCallbacks: [
                    ...state.pendingCallbacks,
                    createPendingCallback(
                        action.changes,
                        action.replace
                    ),
                ],
                pendingChanges: applyChanges(
                    state.pendingChanges,
                    action.changes,
                    action.replace
                ),
                editingSource: applyChanges(
                    state.editingSource,
                    action.changes,
                    action.replace
                ),
                paramState: (action.paramStatesToReset || []).reduce(
                    (ps, name) => (ps[name] ? { ...ps, [name]: {} } : ps),
                    state.paramState,
                ),
            };

        case 'OPEN_ACTIONS_DROPDOWN':
            return {
                ...state,
                actionsDropdownOpen: true,
            };

        case 'CLOSE_ACTIONS_DROPDOWN':
            return {
                ...state,
                actionsDropdownOpen: false,
            };

        case 'OPEN_DELETE_MODAL':
            return {
                ...state,
                actionsDropdownOpen: false,
                deleteModalOpen: true,
            };

        case 'CLOSE_DELETE_MODAL':
            return {
                ...state,
                actionsDropdownOpen: false,
                deleteModalOpen: false,
            };

        case 'OPEN_RENAME_MODAL':
            return {
                ...state,
                actionsDropdownOpen: false,
                renameModalOpen: true,
            };

        case 'CLOSE_RENAME_MODAL':
            return {
                ...state,
                actionsDropdownOpen: false,
                renameModalOpen: false,
            };

        case 'OPEN_SCHEDULE_MODAL':
            return {
                ...state,
                scheduleModalOpen: true,
            };

        case 'CLOSE_SCHEDULE_MODAL':
            return {
                ...state,
                scheduleModalOpen: false,
            };

        case 'OPEN_SYNC_MODAL':
            return {
                ...state,
                syncModalOpen: true,
            };

        case 'CLOSE_SYNC_MODAL':
            return {
                ...state,
                syncModalOpen: false,
            };

        case 'SOURCE_HISTORY_LOADING':
            return {
                ...state,
                historyLoading: true,
            };

        case 'SOURCE_HISTORY_LOADED':
            return {
                ...state,
                historyLoading: false,
                historyJobs: action.data,
            };

        case 'SOURCE_HISTORY_ERROR':
            return {
                ...state,
                historyLoading: false,
                historyError: action.error,
            };

        case 'SET_FIELD_VALIDATION_ERRORS':
            return {
                ...state,
                fieldValidationErrors: action.errors,
            };

        default:
            throw new Error('Unknown action type');
    }
};
