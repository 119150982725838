import { useState, useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import {
    fetchQueryResult,
    executeQuery,
    getParentQueryStatus,
    pollQueryResultUntilDone,
    pollQueryResults,
    isQueryFinished,
} from '../../models/queries';

import { reportError } from '../../lib/error-reporter';
import { useSession } from '../context-providers';

/** @typedef { import('models/queries/__types').QueryResult } QueryResult */
/** @typedef { import('models/queries/__types').QueryResultData } QueryResultData */
/** @typedef { import('models/queries/__types').Status } Status */

const LOCAL_STORAGE_KEY = 'query_content';
const DEFAULT_PAGE_SIZE = 500;

/**
 * @typedef { object } Props
 * @prop { string } [localStorageKey]
 * @prop { number } [defaultPageSize]
 */

/**
 * @param { Props } [props]
 */
function useWorkbenchQuery(props = {}) {
    const {
        localStorageKey = LOCAL_STORAGE_KEY,
        defaultPageSize = DEFAULT_PAGE_SIZE,
    } = props;

    const [session] = useSession();
    const { database, user } = session;
    const { username } = user;

    const showPageSize = database.type !== 'redshift';

    const [page, setPage] = useState(0);

    const [pageSize, setPageSize] = useState(defaultPageSize);

    const [pageToken, setPageToken] = useState(
        /** @type { QueryResultData['nextPageToken'] } */ (undefined)
    );

    const [prevPageTokens, setPrevPageTokens] = useState(
        /** @type { Array<QueryResultData['nextPageToken']> } */ ([])
    );

    const [query, setQuery] = useState(() => (
        getLocalStorageQuery(localStorageKey, username)
    ));

    const [queryError, setQueryError] = useState('');

    const [queryIsRunning, setQueryIsRunning] = useState(false);

    const [queryStartedAt, setQueryStartedAt] = useState(
        /** @type { Date= } */ (undefined)
    );

    const [queryEndedAt, setQueryEndedAt] = useState(
        /** @type { Date= } */ (undefined)
    );

    const [queryResults, setQueryResults] = useState(
        /** @type { QueryResult[] } */ ([])
    );

    const [activeQueryResult, setActiveQueryResult] = useState(
        /** @type { QueryResult= } */ (undefined)
    );

    const activeResult = getActiveResult(queryResults, activeQueryResult);

    const parentQueryStatus = useMemo(() => {
        if (queryIsRunning) {
            return /** @type { Status } */ ('Running');
        }
        return getParentQueryStatus(queryResults);
    }, [queryResults, queryIsRunning]);

    const {
        data: queryResultPage,
        isFetching: pageLoading,
    } = useQuery({
        queryKey: [
            'queryResult',
            activeResult?.id,
            activeResult?.query,
            activeResult?.status,
            pageToken,
            pageSize,
        ],
        queryFn: async () => {
            // If we have data but the page size has changed
            // we should re-request with the new page size
            const pageSizeWasChanged = activeResult?.data?.rows?.length
                && pageSize !== activeResult.data.pageSize;

            if (showPageSize && (pageSizeWasChanged || (activeResult && pageToken))) {
                const result = await fetchQueryResult(activeResult.id, pageSize, pageToken);
                return addPageToResult(result, page);
            }

            // If we are on the first page we don't need to fetch
            // because we already have the data.
            return activeResult;
        },
        enabled: !!activeResult?.id && !activeResult?.error,
        placeholderData: previousData => {
            if ((activeResult?.id || queryIsRunning) && !activeResult?.error) {
                return previousData;
            }
            return undefined;
        },
        staleTime: Infinity,
        gcTime: Infinity,
    });

    const isSelectQuery = ['SELECT', 'WITH'].includes(queryResultPage?.data?.command || '')
        || !!queryResultPage?.data?.rows?.length;

    const showVisualization = isSelectQuery && !queryResultPage?.error;
    const showCallToAction = !isSelectQuery && !activeResult?.error;

    const error = queryError
        || queryResultPage?.error?.message
        || queryResultPage?.error?.code;

    /** @type { (newPage: number) => void } */
    const updatePage = useCallback(newPage => {
        if (newPage > page) {
            setPrevPageTokens([...prevPageTokens, pageToken]);

            if (queryResultPage?.data.nextPageToken) {
                setPageToken(queryResultPage?.data.nextPageToken);
            }
        }

        if (newPage < page) {
            setPageToken(prevPageTokens[prevPageTokens.length - 1]);
            setPrevPageTokens(prevPageTokens.slice(0, -1));
        }

        setPage(newPage);
    }, [page, queryResultPage?.data?.nextPageToken, prevPageTokens, pageToken]);

    /** @type { (newPageSize: number) => void } */
    const updatePageSize = useCallback(newPageSize => {
        setPage(0);
        setPageToken(undefined);
        setPrevPageTokens([]);
        setPageSize(newPageSize);
    }, []);

    /** @type { (newQuery: string) => void } */
    const changeQuery = useCallback(newQuery => {
        setQuery(newQuery);
        setLocalStorageQuery(localStorageKey, newQuery, username);
    }, [localStorageKey, username]);

    /** @type { (newQueryError: string) => void } */
    const changeQueryError = useCallback(newQueryError => {
        setQueryError(newQueryError);
    }, []);

    /** @type { (newQueryResults: QueryResult[]) => void} */
    const changeQueryResults = useCallback(newQueryResults => {
        setQueryResults(newQueryResults);
    }, []);

    /** @type { (queryResult?: QueryResult) => void } */
    const changeActiveQueryResult = useCallback(queryResult => {
        setPage(0);
        setPageToken(undefined);
        setPrevPageTokens([]);
        setActiveQueryResult(queryResult);
    }, []);

    /** @type { (id: QueryResult['id'], updates: Partial<QueryResult>) => Promise<void> } */
    const updateQueryResult = useCallback(async (id, updates) => {
        setQueryResults(results => {
            const newResults = results.map(result => {
                if (result.id === id) {
                    return { ...result, ...updates };
                }
                return result;
            });

            return newResults;
        });
    }, []);

    /** @type { (childQuery: QueryResult) => Promise<void> } */
    const pollChildQueryUntilDone = useCallback(async childQuery => {
        const { startTime } = childQuery;
        const queryResult = await pollQueryResultUntilDone(childQuery, pageSize);

        updateQueryResult(queryResult.id, {
            ...queryResult,
            startTime,
            endTime: new Date().toISOString(),
        });
    }, [pageSize, updateQueryResult]);

    /** @type { (query: string, childQueries: QueryResult[]) => Promise<void> } */
    const pollChildQueries = useCallback(async (query, childQueries) => {
        const results = await pollQueryResults(query, childQueries, pageSize);
        const newResults = results.slice(childQueries.length);

        if (!newResults.length) {
            return;
        }

        setQueryResults(results => [...results, ...newResults]);
        await Promise.all(newResults.map(pollChildQueryUntilDone));
    }, [pageSize, pollChildQueryUntilDone]);

    /** @type { (query: string) => Promise<void> } */
    const runQuery = useCallback(async (query) => {
        const startTime = new Date();

        setPage(0);
        setPageToken(undefined);
        setPrevPageTokens([]);
        setActiveQueryResult(undefined);
        setQueryResults([]);
        setQueryEndedAt(undefined);
        setQueryStartedAt(startTime);
        setQueryIsRunning(true);
        setQueryError('');

        try {
            const results = await executeQuery(query, { limit: pageSize });

            const queryResults = results.map(result => ({
                ...result,
                startTime: startTime.toISOString(),
            }));

            setQueryResults(queryResults);
            setActiveQueryResult(queryResults[0]);

            await Promise.all([
                ...queryResults.map(pollChildQueryUntilDone),
                pollChildQueries(query, queryResults),
            ]);
        } catch (/** @type { any } */ error) {
            reportError(error);

            if (error.status === 502) {
                const message = 'Panoply\'s workbench can only run queries that '
                    + 'do not exceed 120 seconds in runtime.';
                setQueryError(message);
            } else {
                setQueryError(error.data?.message || 'Query failed');
            }

            setQueryResults(results => results.map(result => {
                if (!isQueryFinished(result)) {
                    return /** @type { QueryResult } */ ({
                        ...result,
                        status: 'Error',
                        endTime: new Date().toISOString(),
                        error: error.data,
                    });
                }
                return result;
            }));
        } finally {
            setQueryEndedAt(endedAt => endedAt || new Date());
            setQueryIsRunning(false);
        }
    }, [pageSize, pollChildQueryUntilDone]);

    return {
        query,
        queryError: error,
        queryStartedAt,
        queryEndedAt,
        queryIsRunning,
        queryResults,
        queryResultPage,
        activeResult,
        parentQueryStatus,
        runQuery,
        changeQuery,
        changeQueryError,
        changeQueryResults,
        updateQueryResult,
        changeActiveQueryResult,

        page,
        pageSize,
        pageLoading,
        updatePage,
        updatePageSize,

        isSelectQuery,
        showVisualization,
        showCallToAction,
    };
}

/** @type { (key: string, username?: string) => string } */
const getLocalStorageQuery = (key, username) => {
    try {
        const item = localStorage.getItem(key)?.trim();

        if (item) {
            const parsed = JSON.parse(item);

            if (parsed) {
                const { username: storedUsername, query } = parsed;

                if (storedUsername === username
                    && typeof query === 'string'
                    && query.trim().length > 0
                ) {
                    return query;
                }
            }
        }
    } catch (/** @type { any } */ err) {
        reportError(err);
    }

    return '';
};

/** @type { (key: string, query: string, username?: string) => void } */
const setLocalStorageQuery = (key, query, username) => {
    try {
        localStorage.setItem(key, JSON.stringify({
            username,
            query,
        }));
    } catch (/** @type { any } */ err) {
        reportError(err);
    }
};

/**
 * @param { QueryResult[] } queryResults
 * @param { QueryResult } [activeQueryResult]
 * @return { QueryResult | undefined }
 */
const getActiveResult = (queryResults, activeQueryResult) => {
    const queryResult = queryResults.find(result => (
        result.id === activeQueryResult?.id
    ));
    return queryResult || queryResults[0];
};

/** @type { (queryResult: QueryResult, page: number) => QueryResult } */
const addPageToResult = (queryResult, page) => {
    queryResult.data.page = page;
    return queryResult;
};

export default useWorkbenchQuery;
