import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { transparentize } from 'polished';

import {
    Chip,
    Button,
    Icon,
    Layout,
    Typography,
    Dropdown,
    List,
    ListItem,
    Tooltip,
    Switch,
    colors,
} from 'ui-components';

import DeleteSourceDialog from '../../../shared/source/delete-source-dialog';
import RenameSourceDialog from '../../../shared/source/rename-source-dialog';
import CloneSourceDialog from './clone-source-dialog';
import JobStatus from '../../../shared/job/job-status';
import useSourceListItem from './use-source-list-item';
import {
    trackConnectorDeSelection,
    trackConnectorSelection,
    trackOpeningConnector,
} from '../tracking';

/** @typedef { import('models/sources/__types').Source } Source */
/** @typedef { import('models/sources/__types').SourceType } SourceType */

/**
 * @typedef { object } Props
 * @prop { Source } source
 * @prop { SourceType } sourceType
 * @prop { boolean } actionsDisabled
 * @prop { (source: Source, newJob: Source['job']) => void } updateSourceJob
 * @prop { (source: Source, newTitle: string) => Promise<void> } updateSourceTitle
 * @prop { (numberOfDeleted?: number) => void } onSourceDeleted
 * @prop { (source: Source) => boolean } isSelected
 * @prop { (source: Source) => void } toggleSelected
 */

/** @type { React.FC<Props> } */
const SourceListItem = React.memo(({
    source,
    sourceType,
    actionsDisabled,
    isSelected,
    updateSourceJob,
    updateSourceTitle,
    onSourceDeleted,
    toggleSelected,
}) => {

    const {
        title,
        icon,
        lastCollect,
        schedule,
        actionsDropdownOpen,
        deleteModalOpen,
        renameModalOpen,
        deleting,
        deletingError,
        cloneModalOpen,
        deleteSource,
        toggleCloneModal,
        updateTitle,
        openActionsDropdown,
        closeActionsDropdown,
        openDeleteModal,
        closeDeleteModal,
        openRenameModal,
        closeRenameModal,
    } = useSourceListItem(
        source, sourceType, updateSourceJob, updateSourceTitle, onSourceDeleted,
    );

    /** @type { React.MouseEventHandler<HTMLDivElement> } */
    const stopPropagation = (e) => {
        e.stopPropagation();
        // preventDefault is needed to stop firefox from navigating to the link
        // but that also prevents the checkbox from being checked
        e.preventDefault();
        // so we manually do it
        toggleSelected(source);
        // and we will send a tracking event
        const newSelected = !isSelected(source);
        newSelected ? trackConnectorSelection(source.id) : trackConnectorDeSelection(source.id);
    };

    return (
        <>
            <SourceCard
                onClick={() => trackOpeningConnector(source.id)}
                to={`/sources/${source.id}`}>
                <Layout flex alignItems="center" justifyContent="space-between" spacing="px-5 py-3">

                    <NameCol flex alignItems="center" spacing="mr-2">

                        <div onClick={stopPropagation}>
                            <Switch
                                type="checkbox"
                                checked={isSelected(source)}
                                label={''}
                                onChange={() => toggleSelected(source)}
                                spacing="mr-2 -ml-1 p-1"
                            />
                        </div>

                        <TitleTooltip
                            interactive={false}
                            content={title}
                            placement="bottom-start"
                            enterDelay={700}
                        >
                            <Layout flex alignItems="center">
                                <SourceIcon src={icon} />
                                <DataSourceTitle
                                    variant="body1"
                                    color="text"
                                    spacing="mb-0 ml-3 mr-2"
                                    noWrap
                                >
                                    {title}
                                </DataSourceTitle>
                                {sourceType.beta && (
                                    <Chip size="sm" spacing="p-0 mr-3" label="New" />
                                )}
                            </Layout>
                        </TitleTooltip>
                    </NameCol>

                    <ScheduleCol schedule={schedule} />

                    <CollectCol lastCollect={lastCollect} />

                    <StatusCol job={source.job} />

                    <ActionsDropdownCol
                        actionsDropdownOpen={actionsDropdownOpen}
                        actionsDropdownDisabled={actionsDisabled}
                        openActionsDropdown={openActionsDropdown}
                        closeActionsDropdown={closeActionsDropdown}
                        openRenameModal={openRenameModal}
                        openDeleteModal={openDeleteModal}
                        openCloneModal={toggleCloneModal}
                    />
                </Layout>

            </SourceCard>

            <DeleteSourceDialog
                title={source.title}
                isOpen={deleteModalOpen}
                onClose={closeDeleteModal}
                deleteSource={deleteSource}
                error={deletingError}
                loading={deleting}
            />

            <RenameSourceDialog
                currentTitle={title}
                setTitle={updateTitle}
                isOpen={renameModalOpen}
                onClose={closeRenameModal}
            />

            <CloneSourceDialog
                open={cloneModalOpen}
                source={source}
                onClose={toggleCloneModal}
            />
        </>
    );
});


/**
 * @typedef { object } ActionsDropdownColProps
 * @prop { boolean } actionsDropdownOpen
 * @prop { boolean } actionsDropdownDisabled
 * @prop { () => void } openActionsDropdown
 * @prop { () => void } closeActionsDropdown
 * @prop { () => void } openRenameModal
 * @prop { () => void } openDeleteModal
 * @prop { () => void } openCloneModal
 */
/** @type { React.FC<ActionsDropdownColProps> } */
const ActionsDropdownCol = ({
    actionsDropdownOpen,
    actionsDropdownDisabled,
    openActionsDropdown,
    closeActionsDropdown,
    openRenameModal,
    openDeleteModal,
    openCloneModal,
}) => (
    <Layout width="10" flex justifyContent="flex-end">
        <Dropdown
            open={actionsDropdownOpen}
            opener={(
                <Button
                    square
                    type="plain"
                    spacing="m-0 p-2"
                    disabled={actionsDropdownDisabled}
                    onClick={openActionsDropdown}
                >
                    <Icon icon="ellipsis-v" />
                </Button>
            )}
            onClose={closeActionsDropdown}
        >
            <List>
                <ListItem
                    button
                    label="Rename"
                    onClick={openRenameModal}
                    iconName="magic"
                />
                <ListItem
                    button
                    label="Clone"
                    onClick={openCloneModal}
                    iconName="clone"
                />
                <ListItem
                    button
                    label="Delete"
                    onClick={openDeleteModal}
                    iconName="trash"
                />
            </List>
        </Dropdown>
    </Layout>
);


/**
 * @typedef { object } StatusColProps
 * @prop { Source['job'] } job
 */
/** @type { React.FC<StatusColProps> } */
const StatusCol = ({
    job,
}) => (
    <Layout width="15" flex alignItems="center">
        {job && (
            <JobStatus job={job} />
        )}
    </Layout>
);


/**
 * @typedef { object } CollectColProps
 * @prop { string? } lastCollect
 */
/** @type { React.FC<CollectColProps> } */
const CollectCol = ({
    lastCollect,
}) => (
    <Layout width="15">
        <Typography variant="body2" spacing="mb-0" color="secondaryText">
            {lastCollect}
        </Typography>
    </Layout>
);

/**
 * @typedef { object } ScheduleColProps
 * @prop { string | null } schedule
 */
/** @type { React.FC<ScheduleColProps> } */
const ScheduleCol = ({
    schedule,
}) => (
    <Layout width="15" flex alignItems="center">
        {schedule && (
            <>
                <Icon icon="clock" size="sm" color="secondaryText" spacing="mr-1" />
                <Typography variant="body2" spacing="mb-0" color="secondaryText">
                    {schedule}
                </Typography>
            </>
        )}
    </Layout>
);

const SourceCard = styled(Link)`
    &&& {
        border-bottom: 1px solid ${colors.secondaryInterface};

        &:hover {
            text-decoration: none;
            background-color: ${transparentize(0.95, colors.primary.main)};
        }
    }
`;

const TitleTooltip = styled(Tooltip)`
    display: block;
    min-width: 0;
`;

const NameCol = styled(Layout)`
    min-width: 200px;
    width: 45%;
`;

const DataSourceTitle = styled(Typography)`
    overflow: hidden;
    text-overflow: ellipsis;
`;

const SourceIcon = styled('img')`
  width: 24px;
  height: 24px;
  opacity: 0.8;
`;

export default SourceListItem;
