import {
    useState,
    useEffect,
    useMemo,
    useCallback,
} from 'react';

import {
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';

import {
    formatSize,
    formatRows,
} from './formatters';

import * as DatabaseObjectsModel from '../../models/database-objects';
import { useSession } from '../../shared/context-providers';
import useBatchActions from '../../shared/use-batch-actions';

/** @typedef { import('models/database-objects/__types').DatabaseObject } DatabaseObject */
/** @typedef { import('models/database-objects/__types').Table } Table */
/** @typedef { import('models/database-objects/__types').Folder } Folder */
/** @typedef { import('models/database-objects/__types').View } View */
/** @typedef { import('lib/ajax').AjaxResponse= } AjaxError */
/** @typedef { import('../../app/__types').PageProps } PageProps */

/** @typedef { 'name' | 'schema' | 'rows' | 'size' } SortKey */
/** @typedef { DatabaseObject= } OptionalDatabaseObject */
/** @typedef { Table | View } SelectableObject */
/** @typedef { {name: string, action: () => void} } BatchAction */

const ITEMS_PER_PAGE = 20;

/**
 * @typedef { object } ReturnProps
 *
 * @prop { boolean } loading
 * @prop { AjaxError } error
 * @prop { DatabaseObject[] } objects
 * @prop { DatabaseObject[] } objectsInPage
 * @prop { DatabaseObject[] } objectsToDisplay
 * @prop { string } totalSize
 * @prop { string } totalRows
 * @prop { number } page
 * @prop { number } pageSize
 * @prop { boolean } userCanManageTables
 * @prop { DatabaseObject= } objectToMove
 * @prop { SortKey } sortBy
 * @prop { boolean } sortAscending
 * @prop { DatabaseObject[] } selectedObjects
 * @prop { boolean } allSelected
 * @prop { () => void } toggleSelectAll
 * @prop { (object: SelectableObject) => void } toggleSelectedObject
 * @prop { () => void } clearSelectedObjects
 * @prop { (object: DatabaseObject) => boolean } isObjectSelected
 * @prop { BatchAction[] } batchActions
 * @prop { (newPage: number) => void } setPage
 * @prop { (newSort: string) => void } changeSort
 *
 * @prop { Folder['id'] | null } parentFolderId
 * @prop { boolean } createDialogOpen
 * @prop { () => void } closeCreateDialog
 * @prop { (f: Folder['id'] | null) => void } openCreateDialog
 * @prop { (n: Folder['name'], p: Folder['id'] | null) => Promise<Folder> } createFolder
 *
 * @prop { boolean } moveDialogOpen
 * @prop { () => void } closeMoveDialog
 * @prop { (o: DatabaseObject) => void } openMoveDialog
 * @prop { (o: DatabaseObject, f: Folder['id']) => Promise<void> } moveObjectToFolder
 *
 * @prop { SelectableObject[]= } itemsToManage
 * @prop { boolean } viewersDialogOpen
 * @prop { () => void } closeViewersDialog
 * @prop { (i: SelectableObject | SelectableObject[]) => void } openViewersDialog
 *
 * @prop { View= } viewToMaterialize
 * @prop { boolean } userCanMaterialize
 * @prop { boolean } materializeDialogOpen
 * @prop { () => void } closeMaterializeDialog
 * @prop { (v: View) => Promise<void> } materializeView
 * @prop { (v: View) => void } openMaterializeDialog
 *
 * @prop { DatabaseObject[] | undefined } batchObjectToDelete
 * @prop { OptionalDatabaseObject } objectToDelete
 * @prop { boolean } userCanMaterialize
 * @prop { boolean } deleteDialogOpen
 * @prop { boolean } batchDeleteDialogOpen
 * @prop { () => void } closeBatchDeleteDialog
 * @prop { () => void } closeDeleteDialog
 * @prop { (o: SelectableObject[]) => void } openBatchDeleteDialog
 * @prop { (o: DatabaseObject) => void } openDeleteDialog
 * @prop { (o: DatabaseObject) => Promise<View[]> } fetchObjectDependencies
 * @prop { (o: DatabaseObject) => Promise<void> } deleteObject
 *
 * @prop { string } searchTerm
 * @prop { (e: Event) => void } onChangeSearch
 */

/** @type { () => ReturnProps } */
const useTablesPage = () => {

    const queryClient = useQueryClient();
    const [session] = useSession();

    /** @type { (objects: DatabaseObject[]) => void } */
    const setObjects = (newObjects) => {
        queryClient.setQueryData(['databaseObjects'], newObjects);
    };

    const {
        data: objects, error, isLoading: loading,
    } = useQuery({
        queryKey: ['databaseObjects'],
        queryFn: () => {
            return DatabaseObjectsModel.fetchDatabaseObjects();
        },
    });

    const [sortBy, setSortBy] = useState(
        /** @type { SortKey } */ ('name')
    );

    const [searchTerm, setSearchTerm] = useState('');

    const [page, setPage] = useState(0);

    const [sortAscending, setSortAscending] = useState(true);

    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [parentFolderId, setParentFolderId] = useState(
        /** @type { Folder['id'] | null } */ (null)
    );

    const [moveDialogOpen, setMoveDialogOpen] = useState(false);
    /** @type { [OptionalDatabaseObject, React.Dispatch<OptionalDatabaseObject>] } */
    const [objectToMove, setObjectToMove] = useState();

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    /** @type { [OptionalDatabaseObject, React.Dispatch<OptionalDatabaseObject>] } */
    const [objectToDelete, setObjectToDelete] = useState();

    const [batchDeleteDialogOpen, setBatchDeleteDialogOpen] = useState(false);
    /** @type { [DatabaseObject[] | undefined, React.Dispatch<DatabaseObject[] | undefined>] } */
    const [batchObjectToDelete, setBatchObjectToDelete] = useState();

    const [viewersDialogOpen, setViewersDialogOpen] = useState(false);
    const [itemsToManage, setItemsToManage] = useState(
        /** @type { SelectableObject[]= } */ (undefined)
    );

    const [materializeDialogOpen, setMaterializeDialogOpen] = useState(false);
    /** @type { [View | undefined, React.Dispatch<View | undefined>] } */
    const [viewToMaterialize, setViewToMaterialize] = useState();

    const userCanMaterialize = session.user.admin || session.database.__materialize_ui;

    const userCanManageTables = session.scopes
        && session.scopes.includes('tables:manage');

    /** @type { (objects: DatabaseObject[]) => SelectableObject[] } */
    const getSelectableObjects = useCallback((objects) => {
        return objects.reduce((list, object) => {
            if (object.type === 'folder') {
                if (object.items) {
                    return [...list, ...getSelectableObjects(object.items)];
                }
                return list;
            }
            return [...list, object];
        }, /** @type {  SelectableObject[] } */ ([]));
    }, []);

    //
    // Search
    //
    const onChangeSearch = useCallback((e) => {
        setSearchTerm(e.target.value);
    }, []);

    const searchResults = useMemo(() => {
        if (!searchTerm) {
            return [];
        }

        /** @type { (objects: DatabaseObject[]) => DatabaseObject[]} */
        const searchList = objectsToSearch => (
            objectsToSearch.reduce((acc, object) => {
                const objectMatches = object.name.toLowerCase().includes(searchTerm.toLowerCase());
                const nestedObjects = (object.items && object.items.length)
                    ? searchList(object.items) : [];

                if (objectMatches) {
                    return [
                        ...acc,
                        object,
                        ...nestedObjects,
                    ];
                }
                return [
                    ...acc,
                    ...nestedObjects,
                ];
            }, /** @type {DatabaseObject[]} */ ([]))
        );

        return searchList(objects);

    }, [searchTerm, objects]);

    //
    //  Pagination & sorting
    //
    const showFrom = (page) * ITEMS_PER_PAGE;
    const showUntil = (page + 1) * ITEMS_PER_PAGE;

    const objectsToDisplay = (searchTerm)
        ? searchResults
        : objects || [];

    // eslint-disable-next-line no-use-before-define
    const sortedObjects = sortObjectsByProperty(objectsToDisplay, sortBy, sortAscending);

    const objectsInPage = sortedObjects.slice(showFrom, showUntil);

    const totalSize = useMemo(() => formatSize(
        getTotalSize(objectsToDisplay), // eslint-disable-line no-use-before-define
    ), [objectsToDisplay]);

    const totalRows = useMemo(() => formatRows(
        getTotalRows(objectsToDisplay), // eslint-disable-line no-use-before-define
    ), [objectsToDisplay]);

    const changeSort = useCallback((keyToSortBy) => {
        // If already sorting by this then change direction
        if (keyToSortBy === sortBy) {
            setSortAscending(!sortAscending);
            return;
        }
        setSortAscending(true);
        setSortBy(keyToSortBy);
    }, [sortBy, sortAscending]);

    const selectableObjects = getSelectableObjects(objectsInPage);


    const {
        selectedObjects,
        allSelected,
        toggleSelectedObject,
        clearSelectedObjects,
        toggleSelectAll,
        isObjectSelected,
    } = useBatchActions(selectableObjects);

    useEffect(() => {
        setPage(0);
        clearSelectedObjects();
    }, [searchTerm, sortAscending, sortBy]);

    //
    //  Create Dialog
    //
    const closeCreateDialog = useCallback(() => {
        setCreateDialogOpen(false);
        setParentFolderId('');
    }, []);

    /** @type { (folderId: Folder['id'] | null) => void} */
    const openCreateDialog = useCallback((folderId) => {
        setCreateDialogOpen(true);
        if (folderId) {
            setParentFolderId(folderId);
        }
    }, []);

    /** @type { (name: Folder['name'], parentId: Folder['id'] | null) => Promise<Folder>} */
    const createFolder = useCallback(async (name, parentId) => {
        if (session.database.type !== 'redshift') {
            throw new Error('Folders are only supported in redshift databases');
        }
        const createdFolder = await DatabaseObjectsModel.createFolder(name, parentId);
        const updatedList = DatabaseObjectsModel.addToTree(objects, createdFolder, parentId);
        setObjects(updatedList);
        return createdFolder;
    }, [objects, session.database.type]);


    //
    //  Materialize Dialog
    //
    /** @type { (viewToMaterialize: View) => Promise<void> } */
    const materializeView = useCallback(async (view) => {
        const updatedView = {
            ...view,
            materialized: !view.materialized,
        };
        await DatabaseObjectsModel.updateView(updatedView);
        const updatedObjectsTree = DatabaseObjectsModel.updateInTree(objects, updatedView);
        setObjects(updatedObjectsTree);
    }, [objects]);

    /** @type { () => void } */
    const closeMaterializeDialog = useCallback(() => {
        setMaterializeDialogOpen(false);
        setViewToMaterialize(undefined);
    }, []);

    /** @type { (viewToMaterialize: View) => void } */
    const openMaterializeDialog = useCallback((view) => {
        setMaterializeDialogOpen(true);
        setViewToMaterialize(view);
    }, []);

    //
    //  Viewers Dialog
    //
    const closeViewersDialog = useCallback(() => {
        setViewersDialogOpen(false);
        setItemsToManage(undefined);
    }, []);

    /** @type { (itemsToOpen: SelectableObject | SelectableObject[]) => void } */
    const openViewersDialog = useCallback((itemsToOpen) => {
        setViewersDialogOpen(true);

        if (Array.isArray(itemsToOpen)) {
            setItemsToManage(itemsToOpen);
            return;
        }

        setItemsToManage([itemsToOpen]);
    }, []);

    //
    // Move Dialog
    //
    /** @type { (object: DatabaseObject) => void} */
    const openMoveDialog = useCallback((object) => {
        setMoveDialogOpen(true);
        setObjectToMove(object);
    }, []);

    const closeMoveDialog = useCallback(() => {
        setMoveDialogOpen(false);
        setObjectToMove(undefined);
    }, []);

    /** @type { (object: DatabaseObject, folderId: Folder['id']) => Promise<void>} */
    const moveObjectToFolder = useCallback(async (object, folderId) => {
        if (session.database.type !== 'redshift') {
            throw new Error('Folders are only supported in redshift databases');
        }
        await DatabaseObjectsModel.moveObjectToFolder(object, folderId);
        const updatedList = DatabaseObjectsModel.moveInTree(objects, object, folderId);
        setObjects(updatedList);
    }, [objects, session.database.type]);

    //
    //  Delete Dialog
    //
    /** @type { (object: DatabaseObject) => void} */
    const openDeleteDialog = useCallback((object) => {
        setDeleteDialogOpen(true);
        setObjectToDelete(object);
    }, []);

    /** @type { () => void} */
    const closeDeleteDialog = useCallback(() => {
        setDeleteDialogOpen(false);
        setObjectToDelete(undefined);
    }, []);

    /** @type { (object: DatabaseObject) => Promise<void>} */
    const deleteObject = useCallback(async (object) => {
        await DatabaseObjectsModel.deleteObject(object);
        setObjects((prevObjects) => DatabaseObjectsModel.removeFromTree(prevObjects, object.id));
    }, []);

    /** @type { (object: DatabaseObject) => Promise<View[]>} */
    const fetchObjectDependencies = useCallback(async object => {
        if (session.database.type !== 'redshift') {
            return [];
        }
        return DatabaseObjectsModel.fetchObjectDependencies(object);
    }, [session.database.type]);

    /** @type { (batch: SelectableObject[]) => void} */
    const openBatchDeleteDialog = useCallback((batch) => {
        setBatchDeleteDialogOpen(true);
        setBatchObjectToDelete(batch);
    }, []);

    /** @type { (refreshPage?: boolean) => void} */
    const closeBatchDeleteDialog = useCallback((refreshPage) => {
        setBatchDeleteDialogOpen(false);
        setBatchObjectToDelete(undefined);
        if (refreshPage) {
            clearSelectedObjects();
        }
    }, [clearSelectedObjects]);

    return {
        loading,
        error,
        objects,
        objectsInPage,
        objectsToDisplay,
        totalSize,
        totalRows,
        page,
        userCanManageTables,
        pageSize: ITEMS_PER_PAGE,
        objectToMove,
        sortBy,
        sortAscending,
        selectedObjects,
        allSelected,
        toggleSelectAll,
        isObjectSelected,
        clearSelectedObjects,
        toggleSelectedObject,
        setPage,
        changeSort,

        createDialogOpen,
        parentFolderId,
        closeCreateDialog,
        openCreateDialog,
        createFolder,

        moveDialogOpen,
        openMoveDialog,
        closeMoveDialog,
        moveObjectToFolder,

        viewersDialogOpen,
        itemsToManage,
        openViewersDialog,
        closeViewersDialog,

        materializeDialogOpen,
        viewToMaterialize,
        userCanMaterialize,
        materializeView,
        openMaterializeDialog,
        closeMaterializeDialog,

        deleteDialogOpen,
        objectToDelete,
        openDeleteDialog,
        closeDeleteDialog,
        fetchObjectDependencies,
        deleteObject,

        batchObjectToDelete,
        batchDeleteDialogOpen,
        openBatchDeleteDialog,
        closeBatchDeleteDialog,

        searchTerm,
        onChangeSearch,
    };
};

/** @type { (objects: DatabaseObject[]) => number } */
function getTotalRows(objects) {
    return (
        objects.reduce((total, t) => total + (t.metadata.rows || 0), 0)
    );
}

/** @type { (objects: DatabaseObject[]) => number } */
function getTotalSize(objects) {
    return (
        objects.reduce((total, t) => total + (t.metadata.size || 0), 0)
    );
}


/** @type { (objects: DatabaseObject[], sortBy: SortKey, asc: boolean) => DatabaseObject[]} */
function sortObjectsByProperty(objectsToSort, sortBy, asc) {
    const sortedObjects = objectsToSort.sort((a, b) => {

        /** @type { (key: SortKey, object: DatabaseObject) => any} */
        const getValueForSortKey = (key, object) => {
            if (key === 'size' || key === 'rows') {
                return object.metadata[key] || 0;
            }
            return object[key] || '';
        };

        const aValue = getValueForSortKey(sortBy, a);
        const bValue = getValueForSortKey(sortBy, b);

        if (sortBy === 'size' || sortBy === 'rows') {
            return aValue - bValue;
        }
        return aValue.localeCompare(bValue, 'en', { sensitivity: 'base' });
    });
    return asc ? sortedObjects : sortedObjects.reverse();
}

export default useTablesPage;
