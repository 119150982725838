import React from 'react';
import styled from 'styled-components';

import {
    Layout,
    Typography,
    Icon,
    Tooltip,
} from 'ui-components';

import MarkdownRenderer from '../../shared/markdown-renderer';

/**
 * @typedef { object } Props
 *
 * @prop { string } label
 * @prop { string= } tooltipContent
 * @prop { boolean= } noMinHeight
 * @prop { boolean= } required
 * @prop { boolean= } showOptionalLabel
 * @prop { React.ReactNode } children
 */

/** @type { React.FC<Props> } */
const FormRow = ({
    label,
    tooltipContent,
    noMinHeight,
    required,
    showOptionalLabel,
    children,
}) => (
    <Layout flex alignItems="flex-start" spacing={!noMinHeight ? 'mb-3' : ''}>
        <MinHeightLayout
            isLabel
            flex
            alignItems="center"
            noMinHeight={noMinHeight}
            spacing="pr-4"
        >
            <Layout spacing="pl-1">
                <Typography spacing="m-0" variant="body1" width="100">
                    {label}
                </Typography>

                {!required && showOptionalLabel && label && (
                    <Typography spacing="m-0" variant="subtitle1" color="secondaryText" noWrap>
                        (optional)
                    </Typography>
                )}
            </Layout>

            {tooltipContent && (
                <Tooltip content={<MarkdownRenderer source={tooltipContent} />}>
                    <Icon icon="info-circle" spacing="ml-2" color="secondaryText" />
                </Tooltip>
            )}
        </MinHeightLayout>

        <MinHeightLayout
            width="100"
            flex
            alignItems="center"
            noMinHeight={noMinHeight}
        >
            {children}
        </MinHeightLayout>
    </Layout>
);

const MinHeightLayout = styled(Layout)`
    min-height: ${props => (!props.noMinHeight ? '60px' : '0')};
    ${({ isLabel }) => (isLabel ? `
        width: 220px;
        flex: 0 0 220px;
    ` : '')}
`;

export default FormRow;
