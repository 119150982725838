
import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Autocomplete, Layout, Loader, Callout } from 'ui-components';
import MarkdownRenderer from '../../../../shared/markdown-renderer';
import useValidatedValues from '../../../../shared/source/use-validated-values';

/** @typedef { import('models/sources/__types').DynamicAutocompleteParam } Param */
/** @typedef { import('models/sources/__types').AutocompleteParamItem } ParamOption */
/** @typedef { import('models/sources/__types').DynamicParamArgs } DynamicParamArgs */
/** @typedef { import('models/sources/__types').DynamicParamResponse } DynamicParamResponse */

/**
 * @typedef { object } State
 * @prop { boolean= } loading
 * @prop { string= } error
 * @prop { boolean= } invalid
 * @prop { ParamOption[] } options
 */


/**
 * @typedef { object } Props
 * @prop { Param } param
 * @prop { ParamOption[] } value
 * @prop { State } state
 * @prop { (value: State) => void } setState
 * @prop { (value: ParamOption[]) => void } setValue
 * @prop { (args: DynamicParamArgs) => Promise<DynamicParamResponse['data']>} loadOptions
 */

/** @type { React.FC<Props> } */
const DynamicAutocompleteParam = ({
    param,
    value,
    state,
    setState,
    setValue,
    loadOptions,
}) => {
    // Used to keep track of most recent req to prevent UI jitter
    const requestIdRef = useRef(0);

    const { placeholder, maxSelectedItems } = param;

    const { options = [], loading = false, error, invalid } = state;

    const shouldLoadOptions = !options.length && !loading && !error;

    const {
        validatedOptions,
        validatedValues,
        invalidValues,
        isOptionSelected,
    } = useValidatedValues(value, options);

    const atMaxSelected = maxSelectedItems
        && value
        && value.length >= maxSelectedItems;

    const maxSelectedMessage = maxSelectedItems && `
        You have selected the max allowed (${maxSelectedItems}) options.
    `;

    const onChange = useCallback((e, newValue) => {
        setValue(newValue.map(item => ({
            value: item.value,
            title: item.title,
            subtite: item.subtitle,
        })));
    }, [setValue]);

    const load = useCallback(async () => {
        setState({ ...state, loading: true, error: undefined });

        // We use this later to tell if a newer request has already been sent
        // If so we do nothing with the response in order to prevent UI jitter.
        const requestId = Date.now();
        requestIdRef.current = requestId;

        try {
            const { values } = await loadOptions({});
            if (requestId !== requestIdRef.current) {
                return;
            }
            setState({
                ...state,
                options: values,
                loading: false,
            });
        } catch (e) {
            if (requestId !== requestIdRef.current) {
                return;
            }
            setState({
                ...state,
                loading: false,
                error: e.message,
            });
        }
    }, [setState, state, loadOptions]);

    useEffect(() => {
        if (shouldLoadOptions) {
            load();
        }
    }, [shouldLoadOptions, load]);

    useEffect(() => {
        if (invalidValues.length && !invalid) {
            setState({ ...state, invalid: true });
            return;
        }
        if (invalidValues.length === 0 && invalid) {
            setState({ ...state, invalid: false });
            return;
        }
    }, [invalidValues, setState, state, invalid]);

    if (error) {
        return <Callout color="error">{error}</Callout>;
    }

    if (loading) {
        return (
            <Layout spacing="ml-4">
                <Loader active />
            </Layout>
        );
    }

    return (
        <FullWidthAutocomplete
            label={placeholder}
            onChange={onChange}
            error={!!invalidValues?.length}
            options={atMaxSelected ? [] : validatedOptions}
            noOptionsText={atMaxSelected ? maxSelectedMessage : 'No options match'}
            value={validatedValues || []}
            getOptionSelected={isOptionSelected}
            helperText={param.description && (
                <MarkdownRenderer source={param.description} />
            )}
            spacing="ml-0 pl-0"
            limit={5}
        />
    );
};

const FullWidthAutocomplete = styled(Autocomplete)`
    width: 100%;
`;

export default DynamicAutocompleteParam;
