import React from 'react';
import styled from 'styled-components';

import {
    Card,
    Layout,
    Spreadsheet,
    Loader,
    Typography,
} from 'ui-components';

/** @typedef { import('./__types').PreviewProps } PreviewProps */

/** @type { React.FC<PreviewProps> } */
const TablePreview = ({ data, loading, ...props }) => {
    if (loading) {
        return <PreviewLoading {...props} />;
    }
    if (!data.length) {
        return <PreviewNotFound {...props} />;
    }
    return (
        <PreviewLayout {...props}>
            <Spreadsheet data={data} autoIndex squareCorners />
        </PreviewLayout>
    );
};

/**
 * @param { { spacing: PreviewProps['spacing'] } } props
 * @returns { React.ReactElement }
 */
function PreviewLoading(props) {
    return (
        <PreviewLayout flex alignItems="center" {...props}>
            <Loader active big message="Loading preview" color="primary" />
        </PreviewLayout>
    );
}

/**
 * @param { { spacing: PreviewProps['spacing'] } } props
 * @returns { React.ReactElement }
 */
function PreviewNotFound(props) {
    return (
        <PreviewLayout {...props}>
            <NotFoundCard flat square>
                <Typography align="center" color="secondaryText" spacing="pt-9" italic>
                    No rows found. Table is empty.
                </Typography>
            </NotFoundCard>
        </PreviewLayout>
    );
}

const PreviewLayout = styled(Layout)`
    height: 16rem;
`;

const NotFoundCard = styled(Card)`
    height: 100%;
`;

export default TablePreview;
