/* eslint-disable complexity */
const strip = require('strip-comments');
import { v4 as uuidv4 } from 'uuid';
import { get, post } from '../../lib/ajax';
import { addBreadcrumb } from '../../lib/error-reporter';
import delay from '../../lib/delay';

/** @typedef { import('./__types').Status } Status */
/** @typedef { import('./__types').QueryResult } QueryResult */
/** @typedef { import('./__types').QueryResultData } QueryResultData */
/** @typedef { import('./__types').QueryInfo } QueryInfo */
/** @typedef { import('./__types').QueryInfoListResponse } QueryInfoListResponse */
/** @typedef { import('./__types').CancelQueryResponse } CancelQueryResponse */
/** @typedef { import('./__types').UsageStats } UsageStats */
/** @typedef { import('./__types').PageToken } PageToken */
/** @typedef { NonNullable<QueryResult['parent']> } ParentId */

/**
 * @typedef { object } ExecuteQueriesOptions
 * @property { number } [limit]
 * @property { boolean } [polling]
 */

/** @type { (query: string, options?: ExecuteQueriesOptions) => Promise<QueryResult[]> } */
export const executeQuery = async (query, options) => {
    const { limit = 100, polling = false } = options || {};

    try {
        /** @type { { data: QueryResult[] } } */
        const { data: queryResults } = await post('/engine/run', {
            body: {
                query,
                limit,
            },
        });

        if (polling) {
            return Promise.all(queryResults.map(result => (
                pollQueryResultUntilDone(result, limit)
            )));
        }

        return queryResults;

    } catch (error) {
        addBreadcrumb('Could not run query');
        throw error;
    }
};

/** @type { (pageToken?: PageToken | null) => Promise<QueryInfoListResponse> } */
export const fetchQueriesList = async (pageToken) => {
    try {
        const { data: queriesList } = await get('/engine/queries', {
            query: { pageToken },
        });
        return queriesList;
    } catch (error) {
        addBreadcrumb('Could not fetch queries list');
        throw error;
    }
};

/** @type { (id: QueryInfo['id']) => Promise<CancelQueryResponse> } */
export const cancelQuery = async (id) => {
    try {
        const { data: response } = await post('/engine/queries/cancel', {
            body: { id },
        });
        return response;
    } catch (error) {
        addBreadcrumb('Could not cancel query');
        throw error;
    }
};

/** @type { (id: QueryInfo['id'], pageSize: number, pageToken?: string) => Promise<QueryResult> } */
export const fetchQueryResult = async (id, pageSize, pageToken) => {
    try {
        const { data: response } = await get(`/engine/queries/${id}`, {
            query: { pageToken, limit: pageSize },
        });
        return response;
    } catch (error) {
        addBreadcrumb('Could not fetch query result');
        throw error;
    }
};

/** @type { (id: ParentId, limit: number) => Promise<QueryResult[]> } */
export const fetchQueryResults = async (id, limit) => {
    try {
        const { data: queryResults } = await get(`/engine/queries/${id}/results`, {
            query: { limit },
        });

        return queryResults;

    } catch (error) {
        addBreadcrumb('Could not fetch query results');
        throw error;
    }
};

/** @type { (q: QueryResult, retries?: number) => Promise<string> } */
const fetchExportJob = async (id, retries = 1) => {
    try {
        const timeoutDuration = retries * 2000 > 10000
            ? 10000
            : retries * 2000;

        await delay(timeoutDuration);

        const { data: exportResponse, status } = await get(`/workbench/export/${id}`);

        if (status === 200) {
            return exportResponse;
        }

        return fetchExportJob(id, retries + 1);
    } catch (error) {
        addBreadcrumb('Could not fetch export job');
        throw error;
    }
};

/** @type { (q: QueryResult) => Promise<string> } */
export const exportQueryResult = async (queryResult) => {
    try {
        const { data: createJobResponse } = await post('/workbench/export/', {
            body: {
                query: queryResult.query,
                queryHeaders: Object.keys(queryResult.data.rows && queryResult.data.rows[0] || {}),
            },
        });

        if (!createJobResponse.id) {
            throw new Error('Could not create export job');
        }

        const exportedFileUrl = await fetchExportJob(createJobResponse.id);
        return exportedFileUrl;
    } catch (error) {
        addBreadcrumb('Could not export query results');
        throw error;
    }
};

/** @type { (q: QueryResult) => boolean } */
export const isQueryFinished = (query) => {
    return query.status !== 'Pending'
        && query.status !== 'Running';
};

/** @type { (q: QueryResult) => boolean } */
export const isQueryFailed = (query) => {
    return ['Error', 'Canceled'].includes(query.status);
};

/** @type { (queryResult: QueryResult, showMaxLimit: boolean) => string } */
export const getQueryStatusMessage = (
    queryResult,
    showMaxLimit,
) => {

    if (queryResult.error || queryResult.status === 'Error') {
        return queryResult.error?.message || queryResult.error?.code || 'Error';
    }

    if (queryResult.status === 'Canceled') {
        return queryResult.endTime ? 'Query canceled' : 'Canceling query...';
    }

    if (!queryResult.data || queryResult.status === 'Running') {
        return 'Running...';
    }

    if (queryResult.exportingCsv) {
        return 'Exporting...';
    }

    const { maxLimit, dataExported, data } = queryResult;
    const { objectType, command, count } = data || {};

    switch (command) {
        case 'WITH':
        case 'SELECT':
            if (dataExported) {
                return `${count} row${count !== 1 ? 's' : ''} exported`;
            } else {
                return `${count} row${count !== 1 ? 's' : ''} returned`
                    + (count === maxLimit && showMaxLimit ? ' (maximum limit)' : '');
            }

        case 'UPDATE':
            return `${count} row${count !== 1 ? 's' : ''} updated`;

        case 'INSERT':
            return `${count} row${count !== 1 ? 's' : ''} inserted`;

        case 'DELETE':
            return `${count} row${count !== 1 ? 's' : ''} deleted`;

        case 'CREATE':
            return `${objectType} successfully created`;

        case 'DROP':
            return `${objectType} successfully dropped`;

        default:
            return 'Query successfully executed';
    }
};

/** @type { (results: QueryResult[]) => Status | undefined } */
export const getParentQueryStatus = results => {
    if (results.some(result => result.error || result.status === 'Error')) {
        return /** @type { Status } */ ('Error');
    }

    if (results.some(result => ['Pending', 'Running'].includes(result.status))) {
        return /** @type { Status } */ ('Running');
    }

    if (results.some(result => result.status === 'Canceled')) {
        return /** @type { Status } */ ('Canceled');
    }

    if (results.every(result => result.status === 'Done')) {
        return /** @type { Status } */ ('Done');
    }

    return undefined;
};

/** @type { (result: QueryResult, pageSize: number) => Promise<QueryResult> } */
export const pollQueryResultUntilDone = async (result, pageSize) => {
    if (isQueryFinished(result)) {
        return result;
    }

    let retryCount = 1;
    let nextResult = /** @type { QueryResult | null } */ (null);

    do {
        if (nextResult) {
            await delay(retryCount++ <= 5 ? 1000 : 5000);
        }

        nextResult = await fetchQueryResult(result.id, pageSize);
    } while (!isQueryFinished(nextResult));

    return nextResult;
};

/**
 * @param { string } query
 * @param { QueryResult[] } results
 * @param { number } pageSize
 * @returns { Promise<QueryResult[]> }
 */
export const pollQueryResults = async (query, results, pageSize) => {
    const parentId = results.find(result => result.parent)?.parent;
    const queries = splitMultiQueries(query);

    if (!parentId || results.length === queries.length || !isMultiStatement(queries)) {
        return results;
    }

    let nextResults = /** @type { QueryResult[] | null } */ (null);

    do {
        if (nextResults) {
            // Poll every second and not more because the server side also
            // has a polling interval (probing) of 1 second repeated 3 times
            // otherwise it significantly damages the user experience
            await delay(1000);
        }

        nextResults = await fetchQueryResults(parentId, pageSize);
    } while (nextResults.length !== queries.length
        && nextResults.length >= results.length
        && !nextResults.some(isQueryFailed)
    );

    return nextResults;
};

/** @type { (query: string) => string[] } */
const splitMultiQueries = query => {
    const sanitizedQuery = sanitizeQuery(query);

    // Using uuid ensures that semicolons inside string
    // literals are not considered as query separators
    const separator = `<Separator uniqueId="${uuidv4()}">`;
    const modifiedQuery = replaceQueryParts(sanitizedQuery, [';'], separator);

    // Separate multiple SQL statements using Regex
    // skipping semicolons as values: name = 'abc;de'
    return modifiedQuery.split(separator)
        .filter(q => q?.trim());
};

/** @type { (query: string, patterns: string[], replacement: string) => string} */
const replaceQueryParts = (query, patterns, replacement) => {
    return patterns.reduce((q, pattern) => {
        return q.replace(
            // Skip the matched substring if it is inside a string literal
            // Ex: SELECT " column   name " FROM table WHERE name = '   ';
            new RegExp(`("(""|[^"])*")|('(''|[^'])*')|(${pattern})`, 'gm'),
            x => (['"', "'"].every(y => !x.startsWith(y) && !x.endsWith(y))
                ? replacement
                : x
            )
        );
    }, query);
};

/** @type { (query: string) => string } */
const sanitizeQuery = query => {
    // Remove single and multiple line comments
    const strippedQuery = ['sql', 'js', 'perl'] // --, /* */, #
        .reduce((q, language) => strip(q, { language }), query);

    // Remove multiple spaces, newlines, and tabs
    const minifiedQuery = replaceQueryParts(strippedQuery, ['[\t\r\n]', '[ ]{2,}'], ' ');

    return minifiedQuery.trim();
};

/** @type { (queries: string[]) => boolean } */
const isMultiStatement = queries => {
    return queries.length > 1;
};
