import React from 'react';

import {
    Layout,
    Loader,
    ToggleButton,
} from 'ui-components';

import PageHeader from '../../shared/page-header';
import TableMetadataItem from './table-metadata-item';

/** @typedef { import('models/columns/__types').ColumnData } ColumnData */
/** @typedef { import('models/columns/__types').ColumnItem } ColumnItem */
/** @typedef { import('./__types').MetadataProps } MetadataProps */

/** @type { React.FC<MetadataProps> } */
const TableMetadata = ({
    columns,
    initialColumns,
    loadingMetadata,
    hiddenCount,
    shownItems,
    showHidden,
    showItems,
    onShowHiddenToggled,
    onSaveClicked,
    onColumnChanged,
    ...props
}) => {
    const toggleProps = {
        showHidden,
        hiddenCount,
        onShowHiddenToggled,
    };

    const itemProps = {
        columns,
        initialColumns,
        onSaveClicked,
        onColumnChanged,
    };

    return (
        <Layout {...props}>
            <ListHeader {...toggleProps} />

            <Loader
                active={!showItems}
                big
                message={`Loading ${loadingMetadata ? 'metadata' : 'primary keys'}`}
                color="primary"
            />

            {showItems && shownItems.map(item => (
                <TableMetadataItem key={item.id} item={item} {...itemProps} />
            ))}
        </Layout>
    );
};

/**
 * @param { object } props
 * @param { MetadataProps['showHidden'] } props.showHidden
 * @param { MetadataProps['hiddenCount'] } props.hiddenCount
 * @param { MetadataProps['onShowHiddenToggled'] } props.onShowHiddenToggled
 * @returns { React.ReactElement }
 */
function ListHeader({ showHidden, hiddenCount, onShowHiddenToggled }) {
    return (
        <PageHeader title="Metadata" noHelmet>
            <ToggleButton
                active={showHidden}
                onClick={onShowHiddenToggled}
                spacing="m-0 py-1 px-4"
            >
                Show Hidden {hiddenCount ? ` (${hiddenCount})` : ''}
            </ToggleButton>
        </PageHeader>
    );
}

export default TableMetadata;
