import { useState, useCallback } from 'react';

import { useRequest } from '../../lib/ajax';

/** @typedef { import('./__types').Warehouse } Warehouse */
/** @typedef { import('../../app/__types').PageProps } PageProps */

/**
 * @typedef { object } Props
 *
 * @prop { PageProps['openToast'] } openToast
 * @prop { () => void } fetchWarehouses
 */

/**
 * @typedef { object } ReturnProps
 *
 * @prop { { message: string } } unsuspendError
 * @prop { boolean } unsuspendLoading
 * @prop { Warehouse } warehouseToUnsuspend
 * @prop { () => void } onUnsuspendClose
 * @prop { () => void } onUnsuspendConfirm
 * @prop { (w: Warehouse) => void } onUnsuspend
 */

/** @type { (props: Props) => ReturnProps } */
function useUnsuspend({ openToast, fetchWarehouses }) {
    /** @type { [Warehouse, (w: Warehouse) => void ] } */
    const [warehouseToUnsuspend, setWarehouseToUnsuspend] = useState({});

    const unsuspendReq = useRequest(
        `/databases/${warehouseToUnsuspend.id}`,
        'PUT',
        warehouseToUnsuspend,
    );

    const {
        error: unsuspendError,
        loading: unsuspendLoading,
    } = unsuspendReq;

    const onUnsuspend = useCallback((warehouse) => {
        setWarehouseToUnsuspend(warehouse);
    }, []);

    const onUnsuspendClose = useCallback(() => {
        setWarehouseToUnsuspend({});
    }, []);

    const onUnsuspendConfirm = useCallback(() => {
        warehouseToUnsuspend.suspended = false;
        unsuspendReq.go().then(({ ok, data }) => {
            onUnsuspendClose();
            unsuspendReq.clear();

            if (!ok) {
                openToast(data);
                return;
            }

            openToast(`Unsuspension of ${data.name} has been scheduled.`);
            fetchWarehouses();
        });
    }, [warehouseToUnsuspend, unsuspendReq, onUnsuspendClose, openToast, fetchWarehouses]);

    return {
        unsuspendError,
        unsuspendLoading,
        warehouseToUnsuspend,
        onUnsuspendClose,
        onUnsuspendConfirm,
        onUnsuspend,
    };
}

export default useUnsuspend;
