import React from 'react';
import styled from 'styled-components';
import { Button } from 'ui-components';

import googleImagePath from '../../images/icon-google.svg';

/**
 * @typedef { object } Props
 * @prop { React.ReactNode } children
 * @prop { () => void } onClick
 * @prop { string } [width]
 * @prop { string } [spacing]
 * @prop { string } [color]
 * @prop { boolean } [loading]
 * @prop { boolean } [disabled]
 */

/** @type { React.FC<Props> } */
const GoogleButton = ({ children, ...props }) => (
    <Button width="100" spacing="m-0" {...props}>
        <StyledImage src={googleImagePath} />
        {children}
    </Button>
);

const StyledImage = styled('img')`
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
`;

export default GoogleButton;
