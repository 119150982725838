import React, { useState } from 'react';
import { Button, Callout, Dialog, Input, Layout, Typography } from 'ui-components';
import { useNavigate } from 'react-router-dom';
import { createDashboard } from '../../models/dashboards';


/** @typedef { import('models/dashboards').Dashboard } Dashboard */

/**
 * @typedef { object } CreateDashboardDialogProps
 * @property { boolean } isOpen
 * @property { () => void } onClose
 * @property { (dashboard: Dashboard) => void } addDashboardListItem
 */

/** @type { React.FC<CreateDashboardDialogProps> } */
const CreateDashboardDialog = ({
    isOpen,
    onClose,
    addDashboardListItem,
}) => {
    const [nameInput, setNameInput] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const create = async (/** @type {string} */ title) => {
        try {
            const dashboard = await createDashboard({ title });
            addDashboardListItem(dashboard);
            onClose();
            navigate(`/dashboards/${dashboard.id}`);
        } catch (err) {
            setError(err.data);
        }
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            actions={[
                <Button
                    key={'createNewDashboardDialog-cancel-btn'}
                    onClick={onClose}
                    type="plain"
                >
                    Cancel
                </Button>,
                <Button
                    key={'createNewDashboardDialog-create-btn'}
                    color="primary"
                    onClick={() => create(nameInput)}
                    disabled={!nameInput}
                >
                    Create
                </Button>,
            ]}
        >
            <Layout>
                <Typography
                    spacing="mt-1"
                    color="text"
                    variant="h6"
                    align="center"
                >
                    Create a new dashboard
                </Typography>

                {error && <Callout color="error">{error.message}</Callout>}

                <Input
                    label={'Dashboard title'}
                    onChange={(e) => setNameInput(e.target.value)}
                    value={nameInput}
                    noFloatLabel
                    width="100"
                    autoFocus
                />
            </Layout>
        </Dialog>
    );

};

export default CreateDashboardDialog;
