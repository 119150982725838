import React from 'react';
import styled from 'styled-components';
import { Image, Layout, Typography } from 'ui-components';

import cdnIcon from '../../lib/cdn-icon';
import PageLayout from '../../shared/page-layout';
import openSupportChat from '../../shared/use-support-chat';
import Billing from '../../shared/billing';

import useSession from '../../shared/context-providers/use-session';

/** @typedef { import('../../app/__types').PageProps } PageProps */

/** @type { React.FC<PageProps> } */
const ReactivatedAccountPage = () => {

    const [session] = useSession();
    const { database } = session;

    const showBilling = ['locked', 'suspended'].includes(database.status);

    return (
        <PageLayout flex alignItems="center" justifyContent="center">
            <Container flex alignItems="center" flexDirection="column" width="100">
                <Typography variant="h4" spacing="my-5">
                    Account is not active
                </Typography>

                {showBilling && (<Billing />)}

                <Typography color="secondaryText" spacing="my-5">
                    Got questions or want help?{' '}
                    <ChatLink inline component="span" onClick={() => openSupportChat()}>
                        Chat with support
                    </ChatLink>
                </Typography>

                <Typography color="secondaryText" spacing="mb-5">
                    Want to subscribe to Panoply?{' '}
                    <CalendlyLink
                        inline
                        component="span"
                        color="secondaryText"
                        onClick={openCalendlyLink}
                    >
                        Book time with the team
                    </CalendlyLink>
                </Typography>

                <Image
                    src={cdnIcon('data-team-sad@2x.png', 'ui')}
                    width="100"
                    height="340px"
                    size="contain"
                    repeat="no-repeat"
                    position="center"
                    align="center"
                />
            </Container>
        </PageLayout>
    );
};

const Container = styled(Layout)`
    min-width: 20rem;
    padding-top: 50px;
`;

const ChatLink = styled(Typography)`
    text-decoration: underline;
    cursor: pointer;
`;

const CalendlyLink = styled(Typography)`
    text-decoration: underline;
    cursor: pointer;
`;

const openCalendlyLink = () => {
    window.open('https://calendly.com/panoply/panoply-demo-website2', '_blank');
};

export default ReactivatedAccountPage;
