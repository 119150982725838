import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Card,
    Layout,
    Typography,
    Button,
} from 'ui-components';

import PageLayout from '../../shared/page-layout';
import PageHeader from '../../shared/page-header';
import DetachDialog from './detach-dialog';
import AttachDialog from './attach-dialog';
import { useSession } from '../../shared/context-providers';
import { SettingColumn, ButtonWrapper } from './shared';
import { get } from '../../lib/ajax';


/**
 * @typedef Device
 * @property { string } name
 * @property { string } lastLogin
 */

const MfaPage = () => {
    const [factor, setFactor] = useState(null); // 'totp', 'sms' ....
    const navigate = useNavigate();
    const [session] = useSession();

    const fetchFactor = async () => {
        try {
            const result = await get('/mfa/totp');
            setFactor(result.data.factor);
        } catch (err) {
            throw err.data;
        }
    };


    useEffect(() => {
        async function fetch() {
            await fetchFactor();
        }

        const isAllowedToUseMFA = !!session.database?.mfa;
        if (!isAllowedToUseMFA) {
            navigate('/');
        }

        fetch();
    }, []);

    return (
        <PageLayout key={''}>
            <PageHeader title="Multi Factor Authentication" />
            {(factor
                ? <Detach factor={factor} refetch={fetchFactor}/>
                : <Attach refetch={fetchFactor} />
            )}
        </PageLayout>
    );
};

/** @type { React.FC<NotificationItemProps> } */
const Attach = ({ refetch }) => {
    const [open, setOpen] = useState(false);

    const openModal = useCallback(() => {
        setOpen(true);
    }, []);

    const onClose = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <Card title="Attach MFA device" titleDivider>
            <Layout width="100" spacing="mb-4" flex alignItems="flex-start">

                <SettingColumn>
                    <Typography variant="subtitle1" weight="medium">
                        Authenticator app
                    </Typography>
                    <Typography variant="body1" color="secondaryText" italic>
                        Connect your authenticator app for multi factor authentication
                    </Typography>
                </SettingColumn>

                <ButtonWrapper>
                    <Button onClick={openModal}> Attach </Button>
                </ButtonWrapper>
                <AttachDialog
                    key={`attach-dialog-${open}`}
                    open={open}
                    onClose={onClose}
                    refetch={refetch}
                />
            </Layout>
        </Card>
    );
};

/** @type { React.FC<NotificationItemProps> } */
const Detach = ({ factor, refetch }) => {
    const [open, setOpen] = useState(false);

    const status = factor?.status;

    const createdAt = factor?.created_at
        ? moment(factor?.created_at).utc().format('MMM DD, YYYY HH:HH')
        : '';

    const lastLogin = factor?.last_login
        ? moment(factor?.last_login).utc().format('MMM DD, YYYY HH:HH')
        : '';

    const openModal = useCallback(() => {
        setOpen(true);
    }, []);

    const onClose = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <Card title="Attached Devices" titleDivider>
            <Layout width="100" spacing="mb-4" flex alignItems="flex-start">

                <SettingColumn>
                    <Typography variant="subtitle1" weight="medium">
                        Device
                    </Typography>
                    <Typography variant="body1" color="secondaryText" italic>
                        Authenticator app
                    </Typography>
                </SettingColumn>

                <SettingColumn>
                    <Typography variant="subtitle1" weight="medium">
                        Status
                    </Typography>
                    <Typography variant="body1" color="secondaryText" italic>
                        {status}
                    </Typography>
                </SettingColumn>

                <SettingColumn>
                    <Typography variant="subtitle1" weight="medium">
                        Last Login
                    </Typography>
                    <Typography variant="body1" color="secondaryText" italic>
                        {lastLogin}
                    </Typography>
                </SettingColumn>

                <SettingColumn>
                    <Typography variant="subtitle1" weight="medium">
                        Created
                    </Typography>
                    <Typography variant="body1" color="secondaryText" italic>
                        {createdAt}
                    </Typography>
                </SettingColumn>

                <ButtonWrapper>
                    <Button onClick={openModal}> Detach </Button>
                </ButtonWrapper>
                <DetachDialog
                    key={`detach-dialog-${open}`}
                    open={open}
                    onClose={onClose}
                    refetch={refetch}
                />

            </Layout>
        </Card>
    );
};

export default MfaPage;
