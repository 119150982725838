/* eslint-disable complexity */
import React, { useState, useEffect } from 'react';
import {
    Dialog,
    Typography,
    Button,
    Callout,
    Layout,
} from 'ui-components';

import SourceScheduling from '../../pages/source-page/source-scheduling';
import { syncSourceSchedule } from '../../models/sources';
import { reportError } from '../../lib/error-reporter';
import { trackScheduleModalSubmit } from './tracking';

/** @typedef { import('models/sources/__types').Source } Source */
/** @typedef { import('models/sources/__types').SourceType } SourceType */
/** @typedef { import('models/sources/__types').Schedule } Schedule */

/**
 * @typedef { object } Props
 * @prop { boolean } open
 * @prop { Source[] } sourcesToSchedule
 * @prop { (source: Source) => SourceType | null } getMatchingSourceType
 * @prop { (source: Source, newSchedule: Schedule) => void } onScheduled
 * @prop { () => void } onClose
 */

/** @type { React.FC<Props> } */
const BatchScheduleSourceDialog = React.memo(({
    open,
    sourcesToSchedule = [],
    getMatchingSourceType,
    onScheduled,
    onClose,
}) => {

    const [loading, setLoading] = useState(false);
    const [finished, setFinished] = useState(false);
    const [error, setError] = useState('');

    const sourceTypes = sourcesToSchedule.map((s) => getMatchingSourceType(s));

    // Actually determining if the source is collectable is expensive
    // So we just check if the source is collecting or the last collection was a success
    /** @type { (s: Source, st: SourceType | null) => boolean } */
    const isSchedulable = (s, st) => {
        const status = s.job?.status || s.status;
        const isRunning = ['pending', 'running'].includes(status);
        const isScheduled = s.schedule && s.schedule_hour;

        const batchScheduleable = Boolean(
            s.lastTimeSucceed || isRunning || isScheduled,
        );

        return !!st?.schedulable && batchScheduleable;
    };

    const schedulableSources = sourcesToSchedule.filter((s, i) => {
        return isSchedulable(s, sourceTypes[i]);
    });

    const unschedulableSources = sourcesToSchedule.filter((s, i) => {
        return !isSchedulable(s, sourceTypes[i]);
    });

    const [currentSchedule, setCurrentSchedule] = useState(/** @type { Schedule } */ ({
        dayOfWeek: null,
        hour: null,
        minute: null,
        type: 'collect',
    }));

    const scheduleSources = async () => {
        const reqs = schedulableSources.map(async (source, i) => {
            await syncSourceSchedule(
                currentSchedule,
                source,
                /** @type {SourceType} */ (sourceTypes[i]),
            );
            onScheduled(source, currentSchedule);
        });

        setLoading(true);
        try {
            await Promise.all(reqs);
        } catch (err) {
            setError(err.data.message || 'An error was encountered while scheduling a connector');
            reportError(err);
        }
        setLoading(false);
        setFinished(true);
    };

    useEffect(() => {
        if (!open) {
            return;
        }
        setFinished(false);
        setError('');
    }, [open]);

    const sourceLabel = sourcesToSchedule.length > 1 ? 'connectors' : 'connector';

    const modalTitle = finished
        ? `${schedulableSources.length} ${sourceLabel} scheduled`
        : `Schedule ${sourcesToSchedule.length} ${sourceLabel}`;

    const onScheduleSources = () => {
        trackScheduleModalSubmit('true');
        scheduleSources();
    };

    const onCancel = () => {
        trackScheduleModalSubmit('false');
        onClose();
    };

    const actions = (
        <>
            {!finished && (
                <>
                    <Button
                        onClick={onCancel}
                        type="plain"
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    {schedulableSources.length > 0 && (
                        <Button
                            loading={loading}
                            onClick={onScheduleSources}
                            color="primary"
                        >
                            Schedule {schedulableSources.length}{' '}
                            {schedulableSources.length > 1 ? 'Connectors' : 'Connector'}
                        </Button>
                    )}
                </>
            )}
            {finished && (
                <Button
                    onClick={onClose}
                >
                    Finish
                </Button>
            )}
        </>
    );

    return (
        <Dialog
            title={modalTitle}
            isOpen={open}
            onClose={onClose}
            actions={actions}
        >
            {finished && !error && (
                <>
                    <Callout
                        color="default"
                        spacing="my-4"
                    >
                        <Typography
                            color="text"
                            spacing="mb-2"
                        >
                            Updated connectors:
                        </Typography>
                        {schedulableSources.map((source, i) => {
                            return (
                                <Typography
                                    key={source.id}
                                    color="text"
                                    weight="medium"
                                >
                                    {source.title} ({sourceTypes[i]?.title})
                                </Typography>
                            );
                        })}
                    </Callout>
                </>
            )}
            {finished && error && (
                <>
                    <Typography
                        spacing="mt-1"
                        color="text"
                        variant="h6"
                        align="center"
                    >
                        Something went wrong.
                    </Typography>

                    <Callout
                        color="error"
                        spacing="mt-4"
                    >
                        {error}
                    </Callout>
                </>
            )}
            {!finished && (
                <Layout width="100">

                    {schedulableSources.length === 0 && (
                        <Typography
                            color="text"
                            spacing="mb-5"
                            variant="subtitle1"
                        >
                            Select a schedulable connector to continue.
                        </Typography>
                    )}

                    {schedulableSources.length > 0 && (
                        <SourceScheduling
                            currentSchedule={currentSchedule}
                            setCurrentSchedule={setCurrentSchedule}
                        />
                    )}

                    {unschedulableSources.length > 0 && (
                        <Callout
                            color="warning"
                            spacing="mt-4"
                        >
                            <Typography
                                color="text"
                                spacing="mb-2"
                            >
                                The following connectors cannot be
                                scheduled and will not be affected:
                            </Typography>
                            {unschedulableSources.map((source, i) => {
                                return (
                                    <Typography
                                        key={source.id}
                                        color="text"
                                        weight="medium"
                                    >
                                        {source.title}{' - '}
                                        <Typography
                                            key={source.id}
                                            color="text"
                                            inline
                                        >
                                            {!sourceTypes[i]?.schedulable && (
                                                ' Connector type is not schedulable'
                                            )}
                                            {sourceTypes[i]?.schedulable && (
                                                // eslint-disable-next-line max-len
                                                ' Connector must have a successful collection, a currently running job, or an active schedule'
                                            )}
                                        </Typography>
                                    </Typography>
                                );
                            })}
                        </Callout>
                    )}

                </Layout>
            )}

        </Dialog>
    );
});

export default BatchScheduleSourceDialog;
