import React from 'react';
import moment from 'moment';

import {
    Layout,
    Typography,
    Loader,
    Card,
} from 'ui-components';

import DatabaseConnectionDialog from '../../shared/database-connection-dialog';
import PageLayout from '../../shared/page-layout';
import Usage, { useUsageQuotas } from './usage';
import IconLink from './icon-link';

import useInsightsPage from './use-insights-page';
import PageHeader from '../../shared/page-header';

/** @typedef { import('../../app/__types').PageProps } PageProps */

const since = moment.utc().subtract(7, 'months').startOf('month').toISOString();

/** @type { React.FC<PageProps> } */
const InsightsPage = () => {
    const {
        loadingOnboarding,
        loadingMetrics,
        databaseDetailsOpen,
        toggleDatabaseDetailsModal,
    } = useInsightsPage();

    const quotas = useUsageQuotas({ since, interval: 'month' });
    const loadingQuotas = !!quotas?.loading;

    const showUsage = !quotas?.error
        && [
            quotas?.rowsExtracted,
            quotas?.storage,
            quotas?.queryBytes,
        ].some(quota => !!quota?.limit);

    const loading = loadingQuotas || loadingMetrics || loadingOnboarding;

    if (loading) {
        return (
            <PageLayout>
                <Loader big active message="Loading" />
            </PageLayout>
        );
    }

    return (
        <PageLayout title="Home">
            <PageHeader title="Home" />

            <Card spacing="mb-7">
                <Layout flex wrap>

                    <IconLink
                        link="/sources/new"
                        icon="icon-add-data-source.svg"
                        label="Add new connector"
                        spacing="mb-4"
                    />
                    <IconLink
                        link="/explore"
                        icon="icon-tables.svg"
                        label="See your collected tables"
                        spacing="mb-4"
                    />
                    <IconLink
                        onClick={toggleDatabaseDetailsModal}
                        icon="icon-bi-tool.svg"
                        label="Connect to a BI tool"
                        spacing="mb-4"
                    />
                    <IconLink
                        link="/workbench"
                        icon="icon-workbench.svg"
                        label="Query data &amp; create views"
                        spacing=""
                    />
                    <IconLink
                        link="/teams"
                        icon="icon-manage-team.svg"
                        label="Add team members"
                        spacing=""
                    />
                    <IconLink
                        link="https://panoply.io/docs"
                        icon="icon-documentation.svg"
                        label="See our documentation"
                        spacing=""
                    />

                </Layout>
            </Card>

            {showUsage && (
                <Usage quotas={quotas} />
            )}

            <Card spacing="mt-5">
                <Typography variant="subtitle1" weight="medium" spacing="mb-4">
                    Helpful Links
                </Typography>

                <Layout flex wrap>
                    <IconLink
                        link="https://panoply.io/docs/release-notes"
                        icon="icon-release-notes.svg"
                        label="Release notes"
                        spacing=""
                    />
                    <IconLink
                        link="https://github.com/panoplyio/sql-library"
                        icon="icon-sql-library.svg"
                        label="Sample queries"
                        spacing=""
                    />
                </Layout>
            </Card>

            <DatabaseConnectionDialog
                open={databaseDetailsOpen}
                onClose={toggleDatabaseDetailsModal}
            />

        </PageLayout>
    );
};


export default InsightsPage;
