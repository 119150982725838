import React, { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { reportError } from '../../../../lib/error-reporter';
import SourceListParam from './list-param';

/** @typedef { import('models/sources/__types').StaticListParam } Param */
/** @typedef { import('models/sources/__types').ListParamValue } Value */
/** @typedef { import('models/sources/__types').ListParamItem } Item */

/**
 * @typedef { object } State
 * @prop { string= } search
 * @prop { number= } page
 * @prop { boolean= } showOnlySelected
 */

/**
 * @typedef { object } Props
 * @prop { Param } param
 * @prop { Value } value
 * @prop { State } state
 * @prop { (value: Value) => void } setValue
 * @prop { (state: State) => void } setState
 */

const ITEMS_PER_PAGE = 10;
const MIN_ITEMS_FOR_SEARCH = 10;

/** @type { React.FC<Props> } */
const SourceStaticListParam = ({
    param, value, state, setValue, setState,
}) => {

    const {
        description,
        hideSelectAll,
        maxSelectedItems,
    } = param;

    const items = param.items || [];

    const { showOnlySelected = false, search = '', page = 0 } = state;

    const searchAllowed = items.length > MIN_ITEMS_FOR_SEARCH;

    const updatePage = useCallback((pageNum) => {
        setState({
            ...state,
            page: pageNum,
        });
    }, [state, setState]);

    const updateSelectAll = useCallback((checked) => {
        if (isEmpty(items)) {
            const e = new Error('"Select all" toggled with no items');
            reportError(e);
        }

        const newValue = checked ? items : [];
        setValue(newValue);
    }, [items, setValue]);

    const updateShowOnlySelected = useCallback((newValue) => {

        if (newValue && (value == null || isEmpty(value))) {
            const e = new Error('"Show only selected" toggled when no items selected');
            reportError(e);
        }

        setState({
            ...state,
            showOnlySelected: newValue,
        });
    }, [value, setState, state]);

    const updateSearch = useCallback((newValue) => {
        setState({
            ...state,
            search: newValue,
        });
    }, [state, setState]);

    const listParamBaseProps = {
        title: param.title,
        items,
        value,
        loading: false,
        selectAllAllowed: !hideSelectAll,
        maxSelectedItems,
        showOnlySelected,
        updateShowOnlySelected,
        showOnlySelectedAllowed: true,
        updateSelectAll,
        searchAllowed,
        search,
        description,
        updateSearch,
        page,
        pageSize: ITEMS_PER_PAGE,
        updatePage,
        setValue,
    };

    return (
        <SourceListParam {...listParamBaseProps} />
    );
};

export default SourceStaticListParam;
