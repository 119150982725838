import useTablePreview from './use-table-preview';
import useTableMetadata from './use-table-metadata';

/** @typedef { import('./__types').PageHookProps } PageHookProps */
/** @typedef { import('./__types').PageReturnData } PageReturnData */

/**
 * @param { PageHookProps } props
 * @returns { PageReturnData }
 */
function useTablePage({ id, openToast }) {
    const {
        preview,
        loadingPreview,
    } = useTablePreview({ id, openToast });

    const {
        table,
        columns,
        columnToSave,
        initialColumns,
        loadingMetadata,
        loadingPrimaryKeys,
        savingColumn,
        hiddenCount,
        shownItems,
        showHidden,
        showWarning,
        onShowHiddenToggled,
        onSaveCancelClicked,
        onSaveClicked,
        onColumnChanged,
        onSaveConfirmClicked,
    } = useTableMetadata({ id, openToast });

    return {
        preview,
        loadingPreview,

        table,
        columns,
        columnToSave,
        initialColumns,
        loadingMetadata,
        loadingPrimaryKeys,
        savingColumn,
        hiddenCount,
        shownItems,
        showHidden,
        showWarning,
        onShowHiddenToggled,
        onSaveCancelClicked,
        onSaveClicked,
        onColumnChanged,
        onSaveConfirmClicked,
    };
}

export default useTablePage;
