import React, { useCallback } from 'react';
import { Layout } from 'ui-components';
import SecretInput from './secret-input';
import { v4 as uuidv4 } from 'uuid';

/** @typedef { import('models/sources/__types').Source } Source */
/** @typedef { import('models/sources/__types').MatrixParam } MatrixParam */
/** @typedef { import('models/sources/__types').MatrixParamValue } MatrixParamValue */

/**
 * @typedef { object } Props
 * @prop { MatrixParamValue } value
 * @prop { MatrixParam } param
 * @prop { (v: any) => void } setValue
 */

/** @type { React.FC<Props> } */
const MatrixParam = ({
    value: v,
    param,
    setValue,
}) => {

    const value = v || [];

    /** @type { (key: string, index: number, valueToSet: string) => void } */
    const setCellValue = useCallback((key, index, valueToSet) => {
        const newValue = [...value];
        const row = newValue[index] || {};

        if (!row.__id) {
            row.__id = uuidv4();
        }

        newValue[index] = {
            ...row,
            [key]: valueToSet,
        };

        const valueWithEmptyRowsRemoved = newValue.filter((row) => {
            return Object.keys(row).some(key => {
                return row[key] !== undefined && row[key] !== '' && key !== '__id';
            });
        });

        setValue(valueWithEmptyRowsRemoved);
    }, [value, setValue]);

    /** @type { (key: string, index: number) => string } */
    const getCellValue = useCallback(
        (key, index) => {
            return value[index]?.[key] || '';
        },
        [value, setValue],
    );

    const rows = [
        ...value,
        {},
    ];

    const { columns } = param;

    return (
        <Layout width="100" spacing="mb-2">
            {rows.map((row, i) => (
                <Layout flex width="100" spacing="mb-2" key={i}>
                    {columns.map((col, colIndex) => {
                        if (col == null) {
                            return null;
                        }

                        const isLast = i === value.length;
                        const cellValue = getCellValue(col.key, i);
                        const cellSpacing = colIndex + 1 === columns.length
                            ? 'mr-0 mb-2'
                            : 'mr-2 mb-2';

                        const error = col.validator
                            ? col.validator.test(cellValue)
                            : false;

                        const key = i + row.key + col.key;

                        return (
                            <Layout
                                width="100"
                                spacing={cellSpacing}
                                key={key}
                            >
                                <SecretInput
                                    id={key}
                                    name={key}
                                    value={cellValue}
                                    onChange={e => setCellValue(col.key, i, e.target.value)}
                                    width="100"
                                    placeholder={col.title}
                                    label={col.title}
                                    spacing="p-0"
                                    error={!isLast && error}
                                    noFloatLabel
                                    alwaysShowError
                                    duration={500}
                                />
                            </Layout>
                        );
                    })}
                </Layout>
            ))}

        </Layout>
    );
};

export default MatrixParam;
