import React from 'react';
import styled from 'styled-components';

import {
    Typography,
    Loader,
    Icon,
} from 'ui-components';

import { useSession } from '../context-providers';
import { getQueryStatusMessage } from '../../models/queries';
import Timer from './timer';

/** @typedef { import('models/queries/__types').QueryResult } QueryResult */

/**
 * @typedef { object } Props
 *
 * @prop { string } [queryError]
 * @prop { Date } [queryStartedAt]
 * @prop { Date } [queryEndedAt]
 * @prop { QueryResult } [queryResultPage]
 * @prop { boolean } queryIsRunning
 * @prop { boolean } pageLoading
 * @prop { QueryResult } [activeResult]
 */

/** @type { React.FC<Props> } */
const WorkbenchStatus = React.memo(({
    queryError,
    queryStartedAt,
    queryEndedAt,
    queryResultPage,
    queryIsRunning,
    pageLoading,
    activeResult,
}) => {
    const [session] = useSession();
    const { database } = session;
    const showMaxLimit = database.type === 'redshift';

    const exporting = isQueryExporting(activeResult);
    const canceling = isQueryCanceling(activeResult);

    const running = exporting || canceling
        || isChildQueryRunning(activeResult)
        || isChildQueryRunning(queryResultPage);

    const statusProps = {
        queryStartedAt,
        queryEndedAt,
        activeResult,
        running,
    };

    if (queryError) {
        const message = capitalizeFirstLetter(queryError);

        return (
            <Status message={message} {...statusProps}>
                <Icon icon="times-circle" color="error" />
            </Status>
        );
    }

    if (isQueryCanceled(activeResult)) {
        const message = 'Query canceled';

        return (
            <Status message={message} {...statusProps}>
                <Icon icon="times-circle" color="interface" />
            </Status>
        );
    }

    if (canceling) {
        const message = 'Canceling query...';

        return (
            <Status message={message} {...statusProps}>
                <InlineLoader color="interface" active />
            </Status>
        );
    }

    if (running) {
        const message = !exporting ? 'Running...' : 'Exporting...';

        return (
            <Status message={message} {...statusProps}>
                <InlineLoader color="interface" active />
            </Status>
        );
    }

    if (isPageLoading(pageLoading, queryResultPage)) {
        const message = 'Loading page...';

        return (
            <Status message={message} {...statusProps}>
                <InlineLoader color="interface" active />
            </Status>
        );
    }

    if (hasQueryResultData(queryResultPage, activeResult)) {
        const message = getQueryStatusMessage(queryResultPage, showMaxLimit);

        return (
            <Status message={message} {...statusProps}>
                <Icon icon="check-circle" color="secondary" />
            </Status>
        );
    }

    if (queryIsRunning) {
        const message = 'Running...';

        return (
            <Status message={message} {...statusProps} running>
                <InlineLoader color="interface" active />
            </Status>
        );
    }

    return (
        <Status message="Press (Shift + Enter) to run" />
    );
});

/**
 * @typedef { object } StatusProps
 *
 * @prop { React.ReactNode } [children]
 * @prop { string } message
 * @prop { Date } [queryStartedAt]
 * @prop { Date } [queryEndedAt]
 * @prop { QueryResult } [activeResult]
 * @prop { boolean } [running]
 */

/** @type { React.FC<StatusProps> } */
const Status = ({
    children,
    message,
    queryStartedAt,
    queryEndedAt,
    activeResult,
    running,
}) => (
    <>
        {children}

        <Caption spacing={children ? 'ml-3' : ''}>
            {message}
        </Caption>

        {!!queryStartedAt && (
            <Caption color="interface">
                <Timer
                    mode="tooltip"
                    startedAt={queryStartedAt}
                    endedAt={queryEndedAt}
                    queryResult={activeResult}
                    running={running}
                />
            </Caption>
        )}
    </>
);

/**
 * @param { QueryResult } [queryResultPage]
 * @param { QueryResult } [activeResult]
 * @returns { queryResultPage is QueryResult }
 */
function hasQueryResultData(queryResultPage, activeResult) {
    if (!queryResultPage?.data || !activeResult?.id) {
        return false;
    }
    return !!queryResultPage?.data?.command
        || !!queryResultPage?.data?.rows?.length;
}

/** @type { (pageLoading: boolean, queryResultPage?: QueryResult) => boolean } */
function isPageLoading(pageLoading, queryResultPage) {
    return pageLoading
        || (queryResultPage?.status === 'Done' && !queryResultPage?.data);
}

/** @type { (activeResult?: QueryResult) => boolean } */
function isQueryExporting(activeResult) {
    return !!activeResult?.exportingCsv;
}

/** @type { (activeResult?: QueryResult) => activeResult is QueryResult } */
function isQueryCanceled(activeResult) {
    return activeResult?.status === 'Canceled'
        && !!activeResult.endTime;
}

/** @type { (activeResult?: QueryResult) => activeResult is QueryResult } */
function isQueryCanceling(activeResult) {
    return activeResult?.status === 'Canceled'
        && !activeResult.endTime;
}

/** @type { (queryResultPage?: QueryResult) => boolean } */
function isChildQueryRunning(queryResultPage) {
    return queryResultPage?.status === 'Running'
        || queryResultPage?.status === 'Pending';
}

/** @type { (str: string) => string } */
function capitalizeFirstLetter(str) {
    if (!str) {
        return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

const Caption = styled(({ children, spacing, ...props }) => (
    <Typography
        inline
        component="span"
        color="secondaryText"
        spacing={'m-0' + (spacing ? ` ${spacing}` : '')}
        noWrap
        {...props}
    >
        {children}
    </Typography>
))`
    && {
        line-height: 1.5;
    }
`;

const InlineLoader = styled(Loader)`
    && {
        align-items: flex-start;
        width: 1.3333333333rem;
        height: 1.3333333333rem;
    }
`;

export default WorkbenchStatus;
