import styled from 'styled-components';
import { transparentize } from 'polished';
import { colors } from 'ui-components';

/** @typedef { 'inner' | 'left' | 'right' | 'full' } Type */

/**
 * @typedef { import('styled-components').ThemedStyledFunction<C, any, O> } Styled
 * @template { 'span' } C
 * @template { object } [O={}]
 */

const JoinIcon = /** @type { Styled<'span', { type: Type }> } */ (styled.span)`
    display: inline-block;
    position: relative;
    width: 0.875em;
    height: 1.5em;
    margin: 0.25em 1.0625em;
    vertical-align: top;
    border-radius: 50%;
    background-color: ${({ type }) => transparentize(
        type === 'inner' ? 0.25 : (type !== 'full' ? 0.5 : 1),
        colors.primary.main
    )};

    ::before, ::after {
        content: '';
        display: block;
        position: absolute;
        width: 2em;
        height: 2em;
        top: -0.25em;
        left: -1.0625em;
        border-radius: 50%;
        border: 0.1875em solid ${colors.primary.main};
        background-color: ${({ type }) => transparentize(
            ['left', 'full'].includes(type) ? 0.5 : 1,
            colors.primary.main
        )};
    }

    ::after {
        left: auto;
        right: -1.0625em;
        background-color: ${({ type }) => transparentize(
            ['right', 'full'].includes(type) ? 0.5 : 1,
            colors.primary.main
        )};
    }
`;

export default JoinIcon;
