import React, { useState, useCallback, useEffect } from 'react';
import { isEmpty, isEqual, isObject } from 'lodash';

import {
    Typography,
    Layout,
    Switch,
} from 'ui-components';

import MarkdownRenderer from '../../../shared/markdown-renderer';
import SecretInput from './secret-input';

/** @typedef { import('models/sources/__types').SSLParam } Param */
/** @typedef { import('models/sources/__types').SSLParamValue } Value */
/** @typedef { import('models/sources/__types').FieldValidationErrors } FieldValidationErrors */

/** @type { Value } */
const initialValue = {
    cert: '',
    key: '',
    ca: '',
};

/**
 * @typedef { object } Props
 *
 * @prop { Param } param
 * @prop { Value } value
 * @prop { (value: Value) => void } setValue
 * @prop { FieldValidationErrors } errors
 */

/** @type { React.FC<Props> } */
const SSLParam = ({
    param,
    value,
    setValue,
    errors,
}) => {
    const { ssl: error } = errors;

    const {
        cert: certError = '',
        key: keyError = '',
        ca: caError = '',
    } = isObject(error) ? error : {};

    const [ssl, setSSL] = useState({ ...initialValue, ...value });
    const { cert, key, ca } = ssl;

    const onSwitchSSLChanged = useCallback((e) => {
        setValue(e.target.checked ? ssl : null);
    }, [setValue, ssl]);

    const onClientCertChanged = useCallback((e) => {
        setSSL(v => ({ ...v, cert: String(e.target.value).trim() }));
    }, [setSSL]);

    const onClientKeyChanged = useCallback((e) => {
        setSSL(v => ({ ...v, key: String(e.target.value).trim() }));
    }, [setSSL]);

    const onSSLCertChanged = useCallback((e) => {
        setSSL(v => ({ ...v, ca: String(e.target.value).trim() }));
    }, [setSSL]);

    useEffect(() => {
        if (isEmpty(value) || isEqual(ssl, value)) {
            return;
        }
        setValue(ssl);
    }, [setValue, ssl, value]);

    return (
        <Layout relative width="100">
            <Switch
                name="ssl"
                type="checkbox"
                spacing="m-0 -ml-2"
                checked={!isEmpty(value)}
                onChange={onSwitchSSLChanged}
                label={param.description && (
                    <MarkdownRenderer source={param.description} />
                )}
            />

            {!isEmpty(value) && (
                <>
                    <Layout flex width="100" spacing="mt-2">
                        <Layout width="50">
                            <Typography color="secondaryText" spacing="mb-2">
                                Client Certificate
                            </Typography>

                            <SecretInput
                                duration={300}
                                name="cert"
                                width="100"
                                spacing="p-0"
                                value={cert}
                                error={!!certError}
                                onChange={onClientCertChanged}
                                noFloatLabel
                                multiline
                            />

                            {cert && certError && (
                                <Typography spacing="mt-2 ml-3" color="error">
                                    {certError}
                                </Typography>
                            )}
                        </Layout>

                        <Layout width="50" spacing="pl-2">
                            <Typography color="secondaryText" spacing="mb-2">
                                Client Key
                            </Typography>

                            <SecretInput
                                duration={300}
                                name="key"
                                width="100"
                                spacing="p-0"
                                value={key}
                                error={!!keyError}
                                onChange={onClientKeyChanged}
                                noFloatLabel
                                multiline
                            />

                            {key && keyError && (
                                <Typography spacing="mt-2 ml-3" color="error">
                                    {keyError}
                                </Typography>
                            )}
                        </Layout>
                    </Layout>

                    <Layout width="50" spacing="mt-4">
                        <Typography color="secondaryText" spacing="mb-2">
                            SSL Certificate
                        </Typography>

                        <SecretInput
                            duration={300}
                            name="ca"
                            width="100"
                            spacing="p-0"
                            value={ca}
                            error={!!caError}
                            onChange={onSSLCertChanged}
                            noFloatLabel
                            multiline
                        />

                        {ca && caError && (
                            <Typography spacing="mt-2 ml-3" color="error">
                                {caError}
                            </Typography>
                        )}
                    </Layout>
                </>
            )}
        </Layout>
    );
};

export default SSLParam;
