import { cloneDeep } from 'lodash';

/** @typedef { import('../../app/__types').Plan } Plan */

export const pricingTypes = {
    monthToMonth: 'MtM',
    annualMonth: 'Annual',
};

/**
 * @typedef {object} Props
 * @prop {Plan?} [currentPlan]
 * @prop {Plan[]} plans
 * @prop {string} [pricingType]
 * @prop {boolean} [showAnnualSwitch]
 */

function filterPlans(/** @type {Props} */{
    currentPlan,
    plans = [],
    pricingType = pricingTypes.monthToMonth,
    showAnnualSwitch = true,
}) {
    const plansCopy = cloneDeep(plans);

    if (currentPlan && !plansCopy.find(p => p.id === currentPlan.id)) {
        plansCopy.unshift(currentPlan);
    }

    return plansCopy
        .filter(plan => {
            if (!showAnnualSwitch) {
                return true;
            }

            return plan.pricingType === pricingType;
        })
        .sort((a, b) => a.amount - b.amount);
}

function usePlanFilters() {
    return {
        filterPlans,
    };
}

export default usePlanFilters;
