import React from 'react';
import { Typography } from 'ui-components';

import AuthLayout from '../../shared/auth/auth-layout';
import AuthLinks from '../../shared/auth/auth-links';

import LoginForm from './login-form';
import LoginMfaForm from './login-mfa-form';
import useLoginPage from './use-login-page';

/** @typedef { import('../../app/__types').PageProps } PageProps */

/** @type { React.FC<PageProps> } */
const Login = ({ formProps, linksProps }) => {
    const title = 'Login';
    return (
        <AuthLayout title={title}>
            <Typography variant="h4" align="center" spacing="mb-6">
                {title}
            </Typography>

            <LoginForm {...formProps} />
            <AuthLinks {...linksProps} />
        </AuthLayout>
    );
};

/** @type { React.FC<{ void }> } */
const LoginMfa = () => {
    const title = 'Multi-Factor Authentication';
    return (
        <AuthLayout title={title}>
            <Typography variant="h4" align="center" spacing="mb-6">
                {title}
            </Typography>

            <LoginMfaForm/>

        </AuthLayout>
    );
};

/** @type { React.FC<PageProps> } */
const LoginPage = ({ email: initialEmail }) => {
    const {
        email,
        password,
        emailIsValid,
        passwordIsValid,
        loading,
        disabled,
        error,
        googleError,
        onEmailChanged,
        onPasswordChanged,
        onGoogleClicked,
        onLoginClicked,
        onEnterPressed,
        mfaRequired,
    } = useLoginPage({ initialEmail });

    const formProps = {
        email,
        password,
        emailIsValid,
        passwordIsValid,
        loading,
        disabled,
        error,
        googleError,
        onEmailChanged,
        onPasswordChanged,
        onGoogleClicked,
        onLoginClicked,
        onEnterPressed,
    };

    const linksProps = {
        showSignupLink: true,
        showForgotLink: true,
        email,
    };

    return (
        mfaRequired ? (
            <LoginMfa />
        ) : (
            <Login formProps={formProps} linksProps={linksProps} />
        )
    );
};

export default LoginPage;
