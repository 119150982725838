import {
    isArray,
    isEmpty,
    isNumber,
    isString,
    isObject,
    isPlainObject,
    has,
    forEach,
} from 'lodash';

import { getParamValue, setParamValueWithoutReset } from './model';

import { isValueEmpty } from '../../lib/mingo';

/** @typedef { import('./__types').Source } Source */
/** @typedef { import('./__types').SourceType } SourceType */
/** @typedef { import('./__types').Param } Param */
/** @typedef { import('./__types').FlattenedParam } FlattenedParam */
/** @typedef { import('./__types').DynamicParam } DynamicParam */
/** @typedef { import('./__types').DynamicParamResponse } DynamicParamResponse */
/** @typedef { import('./__types').ValidationParamResponse } ValidationParamResponse */
/** @typedef { import('./__types').ListParam } ListParam */
/** @typedef { import('./__types').ListParamItem } ListParamItem */
/** @typedef { import('./__types').SelectParam } SelectParam */
/** @typedef { import('./__types').SelectParamOption } SelectParamOption */
/** @typedef { import('./__types').IncrementalKeyParam } IncrementalKeyParam */
/** @typedef { import('./__types').ParamDefaults } ParamDefaults */
/** @typedef { import('../../app/__types').Session['database']['type'] } DbType */

/**
 * @typedef { object } ParamItemObject
 * @prop { string | number } [name]
 * @prop { string | number } [key]
 * @prop { string | number } [value]
 * @prop { string | number } [id]
 */

/** @type { (value: any) => boolean } */
export const isPrimitive = (value) => {
    return value == null || !isObject(value);
};

/** @type { (v: any) => v is ParamItemObject } */
const isValidParamItemObject = v => isObject(v) && ('name' in v || 'key' in v);

/** @type { (v: any) => ListParamItem } */
export const normalizeListParamItem = (v) => {
    if (isString(v) || isNumber(v)) {
        return {
            __clientBackCompat: { saveOnlyValue: true },
            name: String(v),
            value: v,
        };
    }

    const validObject = isValidParamItemObject(v);

    if (validObject) {
        const name = String(v.name || v.key);

        if ('value' in v) {
            return {
                ...v,
                name,
                value: v.value,
            };
        }

        if ('id' in v) {
            return {
                ...v,
                name,
                value: v.id,
            };
        }

        if ('key' in v) {
            return {
                ...v,
                name,
                value: v.key,
            };
        }

        return {
            ...v,
            name,
            value: name,
        };
    }

    throw new Error(
        `Could not normalize value into a ListParamItem. (item: ${JSON.stringify(v)})`,
    );
};

/** @type { (item: ListParamItem) => ListParamItem | string } */
export const denormalizeListParamItem = (item) => {
    if (!isValidParamItemObject(item)) {
        return item;
    }

    const { __clientBackCompat, ...others } = item;

    if (__clientBackCompat && __clientBackCompat.saveOnlyValue) {
        return others.value;
    }

    return others;
};

/** @type { (param: Param) => param is ListParam } */
const isListParam = param => ['dynamic-list', 'static-list'].includes(param.type);

/** @type { (flattenedParams: FlattenedParam[]) => boolean } */
const hasOAuth1Param = flattenedParams => {
    return flattenedParams.some(([p]) => p.type === 'oauth1');
};

/** @type { (flattenedParams: FlattenedParam[]) => boolean } */
const hasOAuth2Param = flattenedParams => {
    return flattenedParams.some(([p]) => ['oauth', 'oauth2'].includes(p.type));
};

/** @type { (src: Source, flattenedParams: FlattenedParam[]) => Source } */
const addOAuth1BackCompat = (src, flattenedParams) => {
    if (has(src, '__clientBackCompat.oauthValueKeys')) {
        return src;
    }
    if (!hasOAuth1Param(flattenedParams)) {
        return src;
    }

    return {
        ...src,
        __clientBackCompat: {
            ...(src.__clientBackCompat || {}),
            oauthValueKeys: [
                'access_token',
                'oauth_token',
                'oauth_token_secret',
                'oauth_verifier',
                'oauth_session_handle',
                'url',
            ],
        },
    };
};

/** @type { (src: Source, flattenedParams: FlattenedParam[]) => Source } */
const addOAuth2BackCompat = (src, flattenedParams) => {
    if (has(src, '__clientBackCompat.oauthValueKeys') || !hasOAuth2Param(flattenedParams)) {
        return src;
    }

    return {
        ...src,
        __clientBackCompat: {
            ...(src.__clientBackCompat || {}),
            oauthValueKeys: [
                'access_token',
                'code',
                'refresh_token',
                'url',
                'client_id',
                'client_secret',
            ],
        },
    };
};

/** @type { (source: Source, flattenedParams: FlattenedParam[]) => Source } */
const normalizeListParamValues = (src, flattenedParams) => {
    return flattenedParams
        .filter(([param]) => isListParam(param))
        .reduce((source, flattenedParam) => {
            const paramValue = getParamValue(source, flattenedParam);

            if (isEmpty(paramValue)) {
                const sourceWithEmptyArray = setParamValueWithoutReset(
                    source,
                    flattenedParam,
                    []
                );

                return sourceWithEmptyArray;
            }

            const normalizedValue = paramValue.map(normalizeListParamItem);

            const sourceWithNormalizedValue = setParamValueWithoutReset(
                source,
                flattenedParam,
                normalizedValue
            );

            return sourceWithNormalizedValue;
        }, src);
};

/** @type { (value: any, name: Param['name']) => value is { [key: string]: any } } */
export const isValueNested = (value, name) => {
    return isPlainObject(value) && name in value;
};

/** @type { (src: Source, flattenedParams: FlattenedParam[]) => Source } */
export const normalizeDefaultValues = (src, flattenedParams) => {
    const paramsWithDefaults = flattenedParams.filter(([param]) => {
        return 'default' in param
            ? !isValueEmpty(param.default)
            : false;
    });

    return paramsWithDefaults.reduce((source, flattenedParam) => {
        const [param] = flattenedParam;
        const value = getParamValue(source, flattenedParam);

        const valueIsEmpty = isValueNested(value, param.name)
            ? isValueEmpty(value[param.name])
            : isValueEmpty(value);

        const valueToSet = valueIsEmpty
                // check if allowed empty values, for incremental-key params only
                && !(param.type === 'incremental-key' && !param.preventDeselection)
                && 'default' in param
            ? param.default
            : value;

        const sourceWithDefaults = setParamValueWithoutReset(
            source,
            flattenedParam,
            valueToSet
        );

        return sourceWithDefaults;
    }, src);
};


/** @type { (sourceType: SourceType,) => SourceType } */
export const normalizeParamDefaults = (sourceType) => {

    if (sourceType.params == null) {
        return sourceType;
    }

    /** @type { ParamDefaults } */
    const paramDefaults = {
        /* eslint-disable max-len */
        /* eslint-disable quote-props */
        'schema': {
            help: `The name of the target schema where you wish to save the data.
            This cannot be changed once a source had been collected.`,
        },
        'past-days': {
            help: 'Define how many days to retrieve',
        },
        'destination': {
            help: 'Name of the target table where you wish to save the data',
            placeholder: 'my_table_name',
        },
        'destination-prefix': {
            title: 'Destination Prefix',
            help: 'Destination determines the name of the tables created. Panoply automatically selects the destination names. You may add a prefix to the destination table names. Allowed characters are "a-z", "A-Z", "0-9", "_", and "-". [Learn more](https://panoply.io/docs/data-ingestion).',
            placeholder: 'e.g. my_prefix',
            required: true,
        },
        'primary-key': {
            help: `
Primary Keys are the column(s) values that uniquely identify a row. Once identified Panoply upserts new data and prevents duplicate data.

Panoply automatically selects the Primary Key using the available ID columns. If none are available, you may configure this manually by choosing the columns to use.

[Learn more.](https://panoply.io/docs/primary-keys)
            `,
            placeholder: '{hello}_{world}',
        },
        'incremental-key': {
            help: `
The Incremental Key identifies the point from which to collect data incrementally from the source. Said differently, it identifies the point from which new records are inserted in the destination table. Configuring this is useful to speed up the collection. By default, Panoply does not use an Incremental Key. Instead Panoply extracts all of your data on each collect.

To collect incrementally enter a column, and optionally a value within that column, which identify the incremental collection point. Pay special attention to formatting as it must match what is found in the connector.

[Learn more.](https://panoply.io/docs/incremental-loads#incremental-keys)
            `,
        },
        'delimiter': {
            help: `For character-delimited files (e.g. CSV) that do not use
            comma or tab, indicate the correct delimiter to use.`,
        },
        'exclude': {
            help: `When collecting data, you may want to exclude certain data, such as names,
            addresses, or other personally identifiable information.
            Enter the column names of the data to exclude.`,
        },
        'parse-string': {
            help: `If the data to be collected contains JSON,
            include the JSON text attributes to be parsed.`,
        },
        'include': {
            help: `(Optional) list specific event keys that will be included in this collect,
            when empty all events will be collected`,
        },
        'truncate-table': {
            help: 'Truncate deletes all the current data stored in the destination tables, but not the tables themselves. Afterwards Panoply will recollect all the available data for this connector.',
            description: 'When selected, the content of the tables will be deleted prior to ingesting new data',
        },
        'ssl': {
            title: 'Connect with SSL',
            help: 'Optional. Required for SSL connection. PEM encoded. Leave empty if not applicable.',
            description: 'Connect using SSL',
        },
        'ssh': {
            title: 'Connect with SSH Tunnel',
            help: 'Optional. Required for SSH connection. PEM encoded. Leave empty if not applicable.',
            description: 'Connect using SSH Tunnel',
        },
        'group': {
            category: 'general',
            required: false,
            namespace: true,
        },
        /* eslint-enable quote-props */
        /* eslint-enable max-len */
    };

    /** @type { ParamDefaults } */
    const paramImmutables = {
        'schema': {
            name: 'schema',
        },
        'past-days': {
            name: 'pastDays',
        },
        'destination': {
            name: 'destination',
        },
        'destination-prefix': {
            name: 'destinationPrefix',
        },
        'primary-key': {
            name: 'idpattern',
        },
        'incremental-key': {
            name: 'inckey',
        },
        'delimiter': {
            name: 'delimiter',
        },
        'exclude': {
            name: 'excludes',
        },
        'parse-string': {
            name: 'stringFields',
        },
        'include': {
            name: 'keys',
        },
        'truncate-table': {
            name: 'truncateTable',
        },
        'ssl': {
            name: 'ssl',
        },
        'ssh': {
            name: 'sshTunnel',
        },
        'group': {
            category: 'general',
            required: false,
        },
    };

    return {
        ...sourceType,
        params: [
            ...sourceType.params.map((param) => {
                const defaultsForParam = paramDefaults[param.type] || {};
                const immutablesForParam = paramImmutables[param.type] || {};

                const paramWithDefaults = /** @type { Param } */ ({
                    ...defaultsForParam,
                    ...param,
                });

                /** @type { (key: string) => key is keyof paramWithDefaults } */
                const hasParamField = key => key in paramWithDefaults;

                forEach(immutablesForParam, (value, key) => {
                    const paramValue = hasParamField(key) ? paramWithDefaults[key] : null;

                    if (paramValue !== value) {
                        throw new Error(
                            `Param type ${param.type} should always have ${key} '${value}'`,
                        );
                    }
                });

                return paramWithDefaults;
            }),
        ],
    };
};

// TODO: Not added to the source module. Should be fixed in the Source model
/** @type { (source: Source) => Source } */
export const normalizeLastRanJob = source => {
    if (
        source.job
        && typeof source.job === 'object'
        && !Object.keys(source.job).length
    ) {
        delete source.job;
    }

    return source;
};

/** @type { (source: Source, flattenedParams: FlattenedParam[]) => Source } */
export const normalizeSource = (source, flattenedParams) => {
    return [
        addOAuth1BackCompat,
        addOAuth2BackCompat,
        normalizeListParamValues,
        normalizeDefaultValues,
        normalizeLastRanJob,
    ].reduce((src, fn) => fn(src, flattenedParams), source);
};

/** @type { (src: Source, flattenedParams: FlattenedParam[]) => Source } */
export const denormalizeSource = (src, flattenedParams) => {
    /** @type { Source } */
    const source = { ...src, collectConfig: undefined };

    return [
        denormalizeListParamValues,
        denormalizeDelimiterParamValues,
        denormalizeIncrementalKeyParamValues,
    ].reduce((src, fn) => fn(src, flattenedParams), source);
};

/** @type { (source: Source, flattenedParams: FlattenedParam[]) => Source } */
const denormalizeListParamValues = (source, flattenedParams) => {
    return flattenedParams
        .filter(([param]) => isListParam(param))
        .reduce((src, flattenedParam) => {
            const value = getParamValue(src, flattenedParam);
            if (isEmpty(value)) {
                return src;
            }
            const denormalizedValue = value.map(denormalizeListParamItem);
            const sourceWithDenormalizedValue = setParamValueWithoutReset(
                src,
                flattenedParam,
                denormalizedValue
            );
            return sourceWithDenormalizedValue;
        }, source);
};

// Important: legacy sources (JS) still need this
/** @type { (source: Source) => Source } */
export const denormalizeDelimiterParamValues = (source) => {
    if (!isObject(source.delimiter)) {
        return source;
    }

    /** @type { Source } */
    const flattenedSource = {
        ...source,
        // @ts-ignore
        delimiter: source.delimiter.delimiter,
        otherDelimiter: source.delimiter.otherDelimiter,
    };

    return flattenedSource;
};

// Important: legacy sources (JS) still need this
/** @type { (source: Source) => Source } */
export const denormalizeIncrementalKeyParamValues = (source) => {
    if (!isObject(source.inckey)) {
        return source;
    }

    /** @type { Source } */
    const flattenedSource = {
        ...source,
        // @ts-ignore
        inckey: source.inckey.inckey || null,
        incval: source.inckey.incval || null,
        incrementalDate: source.inckey.incrementalDate || null,
    };

    return flattenedSource;
};


/** @type { (v: any) => SelectParamOption } */
const normalizeSelectParamOption = (v) => {
    if (isString(v) || isNumber(v)) {
        return { name: String(v), value: String(v) };
    }

    if (isValidObject(v)) {
        const name = v.name || String(v.value);
        const value = String(v.value) || v.name;
        return { ...v, name, value };
    }

    throw new Error(
        `Could not normalize value into a SelectParamOption. (value: ${JSON.stringify(v)})`,
    );
};

/** @type { (v: any) => v is { name: string, value?: string | number } } */
const isValidObject = (v) => (
    isObject(v)
    && ('value' in v || 'name' in v)
);

/** @type { (sourceType: SourceType) => SourceType } */
export const normalizeSourceType = (sourceType) => (
    [
        normalizeParamDefaults,
    ].reduce((st, fn) => fn(st), sourceType)
);


// TODO: Remove this in Source module integration
// Important: legacy sources (JS) still need this
/** @type { (response: any) => ValidationParamResponse } */
export const normalizeValidationParamResponse = (response) => {
    if (response == null) {
        throw new Error('Invalid response for a validation param');
    }

    if ('ok' in response) {
        const { ok, message } = response;

        if (ok == null || (!ok && !message)) {
            throw new Error('Invalid response for a validation param');
        }

        return { ok, message };
    }

    throw new Error('Failed to normalize a validation param response');
};


/** @type { (param: DynamicParam, value: any) => any } */
const normalizeDynamicValue = (param, value) => {
    switch (param.type) {
        case 'dynamic-list': return normalizeListParamItem(value);
        case 'dynamic-select': return normalizeSelectParamOption(value);
        default: return value;
    }
};

// Important: legacy sources (JS) still need this
/** @type { (param: DynamicParam, response: any) => DynamicParamResponse } */
export const normalizeDynamicParamResponse = (param, response) => {
    if (response == null) {
        throw new Error('Invalid response for a dynamic param');
    }

    if ('data' in response) {
        const { refreshedToken } = response;
        const { metadata = {}, values = [] } = response.data;
        const normalizedValues = values.map(
            /** @type { (v: DynamicParamResponse) => any } */
            v => normalizeDynamicValue(param, v),
        );
        return {
            refreshedToken,
            data: {
                metadata,
                values: normalizedValues,
            },
        };
    }

    let refreshedToken;
    if (response[0] && response[0].refreshed_token) {
        refreshedToken = response[0].refreshed_token;
        delete response[0].refreshed_token;
    } else if (response.refreshed_token) {
        refreshedToken = response.refreshed_token;
        delete response.refreshed_token;
    }

    if (isArray(response)) {
        const normalizedValues = response.map(
            /** @type { (v: DynamicParamResponse) => any } */
            v => normalizeDynamicValue(param, v),
        );
        return {
            refreshedToken,
            data: {
                values: normalizedValues,
            },
        };
    }

    if ('values' in response) {
        const metadata = response.metadata || {};
        const values = response.values || [];
        const normalizedValues = values.map(
            /** @type { (v: DynamicParamResponse) => any } */
            v => normalizeDynamicValue(param, v),
        );
        return {
            refreshedToken,
            data: {
                metadata,
                values: normalizedValues,
            },
        };
    }

    throw new Error('Failed to normalize a dynamic param response');
};
