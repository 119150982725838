import React from 'react';
import styled from 'styled-components';
import PageHeader from '../../shared/page-header';

import {
    Button,
    Callout,
    Card,
    Layout,
    Typography,
} from 'ui-components';

/**
 * @typedef { object } Props
 * @prop { boolean } loading
 * @prop { { message: string } } error
 * @prop { () => void } onRotateServiceAccountClicked
 */

/** @type { React.FC<Props> } */
const RotateServiceAccountForm = ({
    loading,
    error,
    onRotateServiceAccountClicked,
}) => {

    const buttonProps = {
        color: 'primary',
        onClick: onRotateServiceAccountClicked,
        loading,
    };

    return (
        <>
            <PageHeader title="Rotate service account JSON file" />

            {error && (
                <Callout color="error" spacing="mb-5">
                    {error.message}
                </Callout>
            )}
            <Container flex alignItems="center" justifyContent="center">
                <Layout>
                    <Card width="100">
                        <Typography variant="body1" color="secondaryText" italic>
                            Warning: Rotating the service account JSON file will
                            revoke the existing JSON file you have.
                        </Typography>

                        <Typography spacing="mt-3" variant="body1" color="secondaryText" italic>
                            If you used the current service account JSON file in any
                            external tool that you&apos;ve connected to Panoply,
                        </Typography>

                        <Typography spacing="mt-3" variant="body1" color="secondaryText" italic>
                            you will need to replace it with the new JSON file.
                        </Typography>

                        <Typography variant="body1" color="secondaryText" italic>
                        </Typography>

                        <Typography align="center">
                            <Button spacing="m-0 mt-5" {...buttonProps}>
                                Rotate and Send
                            </Button>
                        </Typography>
                    </Card>
                </Layout>
            </Container>
        </>
    );
};

const Container = styled(Layout)`
    min-height: 100%;
`;

export default RotateServiceAccountForm;
