import { useCallback, useState, useEffect } from 'react';
import { useInput } from 'ui-components';

import { useSession } from '../../shared/context-providers';
import { useRequest } from '../../lib/ajax';

/** @typedef { import('lib/ajax').ErrorData } ErrorData */

/**
 * @typedef { object } ReturnProps
 * @prop { string } password
 * @prop { string } repeatPassword
 * @prop { boolean } passwordIsValid
 * @prop { boolean } repeatPasswordIsValid
 * @prop { boolean } passwordIsValid
 * @prop { boolean } tokenIsValid
 * @prop { boolean } validateIsLoading
 * @prop { boolean } loading
 * @prop { boolean } disabled
 * @prop { boolean } success
 * @prop { ErrorData } [error]
 * @prop { (e: Event) => void } onPasswordChanged
 * @prop { (e: Event) => void } onRepeatPasswordChanged
 * @prop { (valid: boolean) => void } onPasswordIsValidChanged
 * @prop { () => void } onChangeClicked
 * @prop { (e: KeyboardEvent) => void } onEnterPressed
 */

/**
 * @param { { token: string } } props
 * @returns { ReturnProps }
 */
function useChangePasswordPage({ token }) {
    const [, actions] = useSession();
    const { refreshSession } = actions;

    const passwordInput = useInput('Password');
    const repeatPasswordInput = useInput('Repeat Password');

    const password = passwordInput.value;
    const repeatPassword = repeatPasswordInput.value;

    const [tokenIsValid, setTokenIsValid] = useState(false);
    const [passwordIsValid, setPasswordIsValid] = useState(false);
    const repeatPasswordIsValid = password === repeatPassword;

    const { go: validate, ...validateReq } = useRequest(`/users/reset/validate/${token}`);
    const { go: change, ...changeReq } = useRequest('/users/reset', 'POST', {
        token,
        password,
        rPassword: repeatPassword,
    });

    const validateIsLoading = validateReq.loading;

    const { loading, error } = changeReq;
    const disabled = loading || !passwordIsValid || !repeatPasswordIsValid;
    const success = !error && !!changeReq.data;

    const onPasswordChanged = passwordInput.bind.onChange;
    const onRepeatPasswordChanged = repeatPasswordInput.bind.onChange;

    /** @type { (valid: boolean) => void } */
    const onPasswordIsValidChanged = useCallback((valid) => {
        setPasswordIsValid(valid);
    }, []);

    const onChangeClicked = useCallback(() => {
        if (!tokenIsValid || !passwordIsValid || !repeatPasswordIsValid) {
            return;
        }
        change().then(refreshSession);
    }, [tokenIsValid, passwordIsValid, repeatPasswordIsValid, change, refreshSession]);

    /** @type { (e: KeyboardEvent) => void } */
    const onEnterPressed = useCallback((e) => {
        if (disabled || e.key !== 'Enter') {
            return;
        }
        onChangeClicked();
    }, [disabled, onChangeClicked]);

    useEffect(() => {
        if (!token) {
            return;
        }
        validate().then((res) => {
            if (!res.ok) {
                return;
            }
            setTokenIsValid(true);
        });
    }, [token]);

    return {
        password,
        repeatPassword,
        passwordIsValid,
        repeatPasswordIsValid,
        tokenIsValid,
        validateIsLoading,
        loading,
        disabled,
        success,
        error,
        onPasswordChanged,
        onRepeatPasswordChanged,
        onPasswordIsValidChanged,
        onChangeClicked,
        onEnterPressed,
    };
}

export default useChangePasswordPage;
