import { useState, useCallback, useLayoutEffect, useEffect } from 'react';
import { useSession } from '../shared/context-providers';

/** @type { () => { fullbleed: boolean } } */
function useFullbleed() {
    const [session] = useSession();
    const { user, database } = session;

    const [fullbleed, setFullbleed] = useState(!user.id || !database.id);

    const onFullbleed = useCallback(({ detail }) => {
        setFullbleed((fullbleed) => {
            const newFullbleed = detail && detail.fullbleed;
            if (!detail || fullbleed === newFullbleed) {
                return fullbleed;
            }
            return newFullbleed;
        });
    }, []);

    useLayoutEffect(() => {
        document.addEventListener('fullbleed', onFullbleed);
        return () => {
            document.removeEventListener('fullbleed', onFullbleed);
        };
    }, [onFullbleed]);

    useEffect(() => {
        setFullbleed((fullbleed) => {
            const newFullbleed = !user.id || !database.id;
            if (!fullbleed || fullbleed === newFullbleed) {
                return fullbleed;
            }
            return newFullbleed;
        });
    }, [user, database]);

    return {
        fullbleed,
    };
}

export default useFullbleed;
