import React, { useCallback } from 'react';
import styled from 'styled-components';
import {
    Dialog,
    Button,
    Layout,
    Typography,
    Input,
    useInput,
} from 'ui-components';

import {
    page as connectorsPage,
    trackRenameModalInput as trackConnectorsRename,
    trackRenameModalInputSubmit as trackConnectorsRenameSubmit,
} from '../../pages/source-list-page/tracking';
import { getCurrentRoute } from '../../lib/get-current-route';

/**
 * @typedef { object } Props
 * @prop { string } currentTitle
 * @prop { (v: string) => void } setTitle
 * @prop { boolean } isOpen
 * @prop { () => void } onClose
 */

/** @type { React.FC<Props> } */
const RenameSourceDialog = React.memo(({
    currentTitle,
    setTitle,
    isOpen,
    onClose,
}) => {

    const renameInput = useInput('Rename Connector', '');

    const rename = useCallback(() => {
        const page = getCurrentRoute()?.name;
        switch (page) {
            case connectorsPage:
                trackConnectorsRename(String(renameInput?.value));
                trackConnectorsRenameSubmit('true');
                break;

            default:
                break;
        }

        setTitle(renameInput.value);
        onClose();
    }, [setTitle, onClose, renameInput.value]);

    const onCancel = useCallback(() => {
        onClose();
        trackConnectorsRenameSubmit('false');
    }, [onClose]);

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            actions={[
                <Button
                    onClick={onCancel}
                    type="plain"
                    key={1}
                >
                    Cancel
                </Button>,
                <Button
                    color="primary"
                    onClick={rename}
                    disabled={!renameInput.value}
                    key={2}
                >
                    Rename
                </Button>,
            ]}
        >
            <MinWidthWrapper>
                <Typography
                    spacing="mt-1"
                    color="text"
                    variant="h6"
                    align="center"
                >
                    Rename {`"${currentTitle}"`}?
                </Typography>
                <Typography
                    color="text"
                    variant="subtitle1"
                    spacing="my-4"
                    align="center"
                >
                    Rename the connector in Panoply.
                </Typography>
                <Input
                    {...renameInput.bind}
                    noFloatLabel
                    width="100"
                />
            </MinWidthWrapper>
        </Dialog>
    );
});

const MinWidthWrapper = styled(Layout)`
    min-width: 40vw;
`;

export default RenameSourceDialog;
