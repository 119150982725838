import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { pickBy } from 'lodash';

import {
    Button,
    Dropdown,
    List,
    ListItem,
    Icon,
    Layout,
    Switch,
    ColorBar,
    Typography,
    colors,
    Drawer,
    Input,
} from 'ui-components';

import { items as visualizationItems } from './use-workbench-visualization';

/** @typedef { import('models/queries').QueryResultData } QueryResultData */
/** @typedef { import('./__types').VisualizationReport } VisualizationReport */
/** @typedef { import('./__types').VisualizationItemConfig } VisualizationItemConfig */

/**
 * @typedef { import('lodash').Dictionary<T> } Dictionary
 * @template T
 */

/** @type { <T>(obj: Dictionary<T>) => Dictionary<T> } */
function removeEmpty(obj) {
    return pickBy(obj, v => v !== '');
}

/**
 * @typedef { object } Props
 * @prop { QueryResultData } queryData
 * @prop { VisualizationReport } visualizationReport
 * @prop { () => void } toggleOpen
 * @prop { (changes: Partial<VisualizationReport>) => void } onVisualizationReportChanged
 * @prop { boolean } open
 */

/** @type { React.FC<Props> } */
const WorkbenchVisualizationConfig = ({
    queryData,
    visualizationReport,
    toggleOpen,
    onVisualizationReportChanged,
    open,
}) => {
    const { config, isDisabled } = visualizationItems[visualizationReport.type];


    if (!config || isDisabled(queryData)) {
        return null;
    }

    return (
        <WideDrawer
            direction="right"
            open={open}
            size="xs"
            noOverlay
            anchorToViewport={false}
            noCloseButton={false}
            onClose={toggleOpen}
            header={<DrawerHeader onClose={toggleOpen} />}
            key={`${visualizationReport.type}-drawer-${open}`}
        >
            <VisualizationConfig
                config={config}
                onVisualizationReportChanged={onVisualizationReportChanged}
                visualizationReport={visualizationReport}
                key={`${visualizationReport.type}-config`}
            />
        </WideDrawer>
    );
};
function DrawerHeader({ onClose }) {
    return (
        <HeaderContainer>
            <HeaderIconWrapper>
                <Icon spacing="mr-1" icon="cog" size="sm" color="interface" />
            </HeaderIconWrapper>

            <Typography
                spacing="mb-0"
                id="header-text"
                color="interface"
                variant="subtitle2">
                Custom Settings
            </Typography>
            <span onClick={onClose} style={{ marginLeft: 'auto' }}>
                <Icon color="interface" icon="times" size="md" />
            </span>
        </HeaderContainer>
    );
}

const HeaderIconWrapper = styled.span`
    margin-top: auto;
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    vertical-align: middle;
`;

const WideDrawer = styled(Drawer)`
    .drawer-body {
        width: 310px;
        background-color: ${colors.secondaryBackground};
    }
    .footer-links > li > a > i{
        vertical-align:middle;
    }
`;

/**
 * @typedef  { object } ConfigColorBarProps
 * @prop { string } name
 * @prop { VisualizationItemConfig } config
 * @prop { VisualizationReport } visualizationReport
 * @prop { (changes: Partial<VisualizationReport>) => void } onVisualizationReportChanged
 */

/** @type { React.FC<ConfigColorBarProps> } */
const ConfigColorBar = ({ name, config, visualizationReport, onVisualizationReportChanged }) => {
    const [dropDownOpen, setDropDownOpen] = useState(false);

    const openDropdown = () => setDropDownOpen(true);
    const closeDropdown = () => setDropDownOpen(false);

    const colorPallettes = config?.items || {};
    const palettes = Object.keys(colorPallettes);


    const value = visualizationReport.config?.[name] || config.default;
    const colorBarKey = `${value}-${visualizationReport?.type}`;

    const selectedColors = palettes
        .find(palette => colorPallettes[palette]?.label === value)?.split('-');

    return (
        <Layout>
            <Layout flex>
                <LabelWrapper>
                    <Label variant="body2">Color Palette </Label>
                </LabelWrapper>

                <DropdownContainer>
                    <Dropdown
                        opener={
                            <ColorBarWrapper onClick={openDropdown}>
                                <ColorBar
                                    key={colorBarKey}
                                    colors={selectedColors} />
                            </ColorBarWrapper>
                        }

                        open={dropDownOpen}
                        onClose={closeDropdown}
                        noOverlay
                    >
                        <WideList>
                            {
                                palettes.map((palette) => {
                                    const paletteName = colorPallettes[palette]?.label;
                                    return (
                                        <StretchedListItem
                                            spacing="p-3"
                                            key={palette}
                                            onClick={() => {
                                                onVisualizationReportChanged({
                                                    config: removeEmpty({
                                                        ...visualizationReport.config,
                                                        [name]: paletteName,
                                                    }),
                                                });
                                                closeDropdown();
                                            }}>
                                            <Layout spacing="p-3">
                                                <ColorBar colors={palette?.split('-')} />
                                            </Layout>
                                        </StretchedListItem>);
                                })
                            }
                        </WideList>
                    </Dropdown>
                </DropdownContainer>

            </Layout>

        </Layout>
    );

};

const ColorBarWrapper = styled.span`
    width: 11em;
`;

const DropdownContainer = styled.div`
    display: flex;
    margin-left: auto;
    margin-right: 0;
`;

/**
 * @typedef { object } ConfigInputProps
 * @prop { string } name
 * @prop { VisualizationItemConfig } config
 * @prop { VisualizationReport } visualizationReport
 * @prop { (changes: Partial<VisualizationReport>) => void } onVisualizationReportChanged
 */

/** @type { React.FC<ConfigInputProps> } */
const ConfigInput = ({
    name,
    config,
    visualizationReport,
    onVisualizationReportChanged,
}) => {
    const value = visualizationReport.config?.[name] || '';

    const onChange = (e) => {
        const val = Number(e?.target?.value);

        if (isNaN(val)) {
            return;
        }
        onVisualizationReportChanged({
            config: removeEmpty({
                ...visualizationReport.config,
                [name]: val,
            }),
        });
    };

    return (
        <Layout flex>
            <LabelWrapper>
                <Label variant="body2">
                    {config?.label}
                </Label>
            </LabelWrapper>
            <Input
                transparent
                variant="plain"
                noFloatLabel
                label={config?.info}
                type="text"
                value={value}
                onChange={onChange}
                spacing="p-0"
            />
        </Layout>
    );
};


/**
 * @typedef { object } ConfigCheckboxProps
 * @prop { string } name
 * @prop { VisualizationItemConfig } config
 * @prop { VisualizationReport } visualizationReport
 * @prop { (changes: Partial<VisualizationReport>) => void } onVisualizationReportChanged
 */

/** @type { React.FC<ConfigCheckboxProps> } */
const ConfigCheckbox = ({ name, config, visualizationReport, onVisualizationReportChanged }) => {
    const value = visualizationReport.config?.[name] || false;
    const disabled = config?.disabled(visualizationReport?.config || {});

    return (
        <>
            <Switch
                spacing="pt-1"
                disabled={disabled}
                type="checkbox"
                checked={value}
                onChange={(e) => {
                    onVisualizationReportChanged({
                        config: removeEmpty({
                            ...visualizationReport.config,
                            [name]: e.target.checked,
                        }),
                    });
                }}
                label={
                    <>
                        <Typography
                            color="text"
                            spacing="mb-0"
                            variant="body2">
                            {config?.label} </Typography>
                        {
                            config?.info && <Typography
                                color="secondaryText"
                                spacing="mb-0"
                                variant="caption">
                                {config.info}
                            </Typography>
                        }
                    </>
                }
            />

        </>
    );
};


/**
 * @typedef { object } ConfigSwitchProps
 * @prop { string } name
 * @prop { VisualizationItemConfig } config
 * @prop { VisualizationReport } visualizationReport
 * @prop { (changes: Partial<VisualizationReport>) => void } onVisualizationReportChanged
 */

/** @type { React.FC<ConfigSwitchProps> } */
const ConfigSwitch = ({ name, config, visualizationReport, onVisualizationReportChanged }) => {
    const value = visualizationReport.config?.[name] || false;

    return (
        <Switch
            checked={value}
            onChange={(e) => {
                onVisualizationReportChanged({
                    config: removeEmpty({
                        ...visualizationReport.config,
                        [name]: e.target.checked,
                    }),
                });
            }}
            label={<Typography spacing="mb-0" variant="body2"> {config?.label} </Typography>}
        />
    );
};

/**
 * @typedef { object } ConfigDropdownProps
 * @prop { string } name
 * @prop { VisualizationItemConfig } config
 * @prop { VisualizationReport } visualizationReport
 * @prop { (changes: Partial<VisualizationReport>) => void } onVisualizationReportChanged
 */
/** @type { React.FC<ConfigDropdownProps> } */
const ConfigDropdown = ({
    name,
    config,
    visualizationReport,
    onVisualizationReportChanged,
}) => {
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(open => !open);
    };

    /** @type { (value: string) => void } */
    const onChange = (value) => {
        onVisualizationReportChanged({
            config: removeEmpty({
                ...visualizationReport.config,
                [name]: value,
            }),
        });
        toggleOpen();
    };

    const selectedItem = useMemo(() => {
        const value = visualizationReport.config?.[name];
        const item = config.items[value || config.default];

        return {
            ...item,
            value,
        };
    }, [name, config, visualizationReport]);

    return (
        <Layout flex>
            <LabelWrapper>
                <Label spacing="mb-0" variant="body2"> {config.label} </Label>
            </LabelWrapper>

            <Dropdown
                open={open}
                opener={(
                    <BottomBorderButton
                        type="plain"
                        onClick={toggleOpen}
                        spacing="m-0"
                    >
                        <Layout flex alignItems="center">
                            <ListItem
                                label={selectedItem.label}
                                iconName={selectedItem.icon}
                                iconSize="sm"
                                spacing="p-0"
                            />

                            <Icon
                                icon={`angle-${open ? 'up' : 'down'}`}
                                color="interface"
                                spacing="ml-1"
                                size="sm"
                            />
                        </Layout>
                    </BottomBorderButton>
                )}
                onClose={toggleOpen}
            >
                <List>
                    {Object.entries(config.items).map(([value, { icon, label }]) => (
                        <ListItem
                            key={value}
                            label={label}
                            iconName={icon}
                            iconSize="sm"
                            onClick={() => onChange(value)}
                            checked={value === selectedItem.value}
                            button
                        />
                    ))}
                </List>
            </Dropdown>
        </Layout>
    );
};

const BottomBorderButton = styled(Button)`
    border-bottom: 1px solid ${colors.secondaryText} !important;
`;

const Label = styled(Typography)`
    flex-grow: 0;
`;

const LabelWrapper = styled.span`
    margin-top: auto;
    margin-bottom: auto;
    min-width: fit-content;
`;

/**
 * @typedef { object } DrawerBodyProps
 * @prop { boolean } [showBorders]
 * @prop { () => void } toggleShowBorders
 * @prop { (color: string) => void } changeColorPalette
 * @prop { string } [colorPalette]
 */


const StretchedListItem = styled(ListItem)`
    display: contents;
`;

const WideList = styled(List)`
    width: 200px;
`;


/**
 * @typedef { object } VisualizationConfigProps
 * @prop { Record<string, VisualizationItemConfig> } config
 * @prop { (changes: Partial<VisualizationReport>) => void } onVisualizationReportChanged
 * @prop { VisualizationReport } visualizationReport
 */

/** @type { React.FC<VisualizationConfigProps>} */
const VisualizationConfig = ({ config, onVisualizationReportChanged, visualizationReport }) => {
    const componentsMap = /** @type { Record<VisualizationItemConfig['component'], React.FC> } */ ({
        'color-bar': ConfigColorBar,
        'switch': ConfigSwitch,
        'dropdown': ConfigDropdown,
        'checkbox': ConfigCheckbox,
        'input': ConfigInput,
    });

    const entries = Object.entries(config);
    return (
        <>
            {entries.map(([name, config]) => {

                const componentType = config?.component;

                const props = {
                    name,
                    config,
                    visualizationReport,
                    onVisualizationReportChanged,
                };

                const Component = componentsMap[componentType];
                return (
                    <Layout key={name} spacing="pb-2">
                        <Component key={name} {...props} />
                    </Layout>
                );
            })}
        </>
    );
};


export default WorkbenchVisualizationConfig;
