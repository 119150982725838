import { get, post, put, del } from '../../lib/ajax';
import { addBreadcrumb } from '../../lib/error-reporter';

/** @typedef { import('./types').CreateDashboard } CreateDashboard */
/** @typedef { import('./types').FetchDashboard } FetchDashboard */
/** @typedef { import('./types').FetchDashboards } FetchDashboards */
/** @typedef { import('./types').EditDashboard } EditDashboard */
/** @typedef { import('./types').RemoveDashboard } RemoveDashboard */
/** @typedef { import('./types').ShareDashboard } ShareDashboard */

/** @type { CreateDashboard } */
export const createDashboard = async (params) => {
    const { title, description } = params;

    try {
        const { data: dashboard } = await post('/dashboards', {
            body: {
                title,
                description,
            },
        });

        return dashboard;

    } catch (err) {
        addBreadcrumb('Failed to create a dashboard', { err });

        throw err;
    }
};

/** @type { FetchDashboard } */
export const fetchDashboard = async (id, params = {}) => {
    const { withItems } = params;

    const dashId = encodeURIComponent(id);

    try {
        const { data: dashboard } = await get(`/dashboards/${dashId}`, {
            query: {
                withItems,
            },
        });

        return dashboard;

    } catch (err) {
        addBreadcrumb('Failed to fetch a dashboard', { err });

        throw err;
    }
};

/** @type { FetchDashboards } */
export const fetchDashboards = async (params = {}) => {
    const { title } = params;

    try {
        const { data: dashboards } = await get('/dashboards', {
            query: {
                title,
            },
        });

        return dashboards;

    } catch (err) {
        addBreadcrumb('Failed to fetch dashboards', { err });

        throw err;
    }
};

/** @type { EditDashboard } */
export const editDashboard = async (id, params) => {
    const { title, description } = params;

    const dashId = encodeURIComponent(id);

    try {
        const { data: dashboard } = await put(`/dashboards/${dashId}`, {
            body: {
                title,
                description,
            },
        });

        return dashboard;

    } catch (err) {
        addBreadcrumb('Failed to edit a dashboard', { err });

        throw err;
    }
};

/** @type { RemoveDashboard } */
export const removeDashboard = async (id) => {
    const dashId = encodeURIComponent(id);

    try {
        await del(`/dashboards/${dashId}`);

    } catch (err) {
        addBreadcrumb('Failed to remove a dashboard', { err });

        throw err;
    }
};

/** @type { ShareDashboard } */
export const shareDashboard = async (id, params) => {
    const { title, recipients, data, filename, reportId } = params;

    const dashId = encodeURIComponent(id);

    try {
        await post(`/dashboards/${dashId}/share`, {
            body: {
                title,
                recipients,
                data,
                filename,
                reportId,
            },
        });

    } catch (err) {
        addBreadcrumb('Failed to share a dashboard', { err });

        throw err;
    }
};
