import React from 'react';
import { useSession } from '../../shared/context-providers';

import {
    LinkCard,
    Layout,
} from 'ui-components';

/** @typedef {import('./__types').FeatureToolProps} FeatureToolProps */

/** @type { React.FC<FeatureToolProps> } */
const FeaturedTool = (props) => {
    const [session] = useSession();
    const { database, user } = session;
    let { link } = props;

    if (link.includes('app.trevor.io') && !link.includes('?')) {
        link += `?database=${encodeURIComponent(database.name)}`
            + `&user=${encodeURIComponent(user.username)}`
            + `&email=${encodeURIComponent(user.email)}`
            + `&infra=${encodeURIComponent(database.type)}`;
    }

    return (
        <Layout width="25" spacing="p-2">
            <LinkCard {...props} link={link} target={props.target} />
        </Layout>
    );
};

export default FeaturedTool;
