import React from 'react';

import {
    Layout,
    Dialog,
} from 'ui-components';

import Connection from './database-connection';

/**
 * @typedef { object } Props
 * @prop { boolean } open
 * @prop { () => void } onClose
 */

/** @type { React.FC<Props> } */
const DatabaseConnectionDialog = ({ open, onClose }) => {
    return (
        <Dialog
            isOpen={open}
            onClose={onClose}
        >
            <Layout spacing="-m-4 -mb-5">
                <Connection />
            </Layout>
        </Dialog>
    );
};


export default DatabaseConnectionDialog;
