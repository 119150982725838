import { getParamValue } from './model';

/* eslint-disable complexity */
const { isEmpty, isEqual } = require('lodash');

/** @typedef { import('./__types').Source } Source */
/** @typedef { import('./__types').FieldValidationErrors } FieldValidationErrors */
/** @typedef { import('./__types').Param } Param */
/** @typedef { import('./__types').MatrixParam } MatrixParam */
/** @typedef { import('./__types').MatrixParamColumn } MatrixParamColumn */

/**
 * @typedef { import('./__types').FlattenedParam<T> } FlattenedParam
 * @template { Param } [T=Param]
 */

/** @typedef { '{' | '}' } Brace */

/** @type { (brace: Brace) => Brace } */
const getOpposite = brace => (brace === '{' ? '}' : '{');

/** @type { (braces: Brace[]) => boolean } */
const areBracesValid = braces => isEmpty(braces)
    || (
        braces[0] === '{'
        && braces.length % 2 === 0
        && braces.reduce(
            /** @type { (res: boolean, brace: Brace, i: number) => boolean } */
            (res, brace, i) => {
                const nextBrace = braces[i + 1];
                return res && (nextBrace ? isEqual(nextBrace, getOpposite(brace)) : true);
            }, true,
        )
    );

/** @type { (str: string, braces: Brace[]) => boolean } */
const hasEmptyContent = (str, braces) => {
    const braceContent = str.match(/[^{}]+(?=})/g) || [];
    return (braceContent.length !== braces.length / 2);
};

/** @type { (str: string) => boolean } */
const isMasked = (value) => {
    return value.startsWith('__S=');
};

/** @type { (char: string) => char is Brace } */
const isBrace = (char) => ['{', '}'].includes(char);

/** @type { (pattern: string) => boolean } */
export const isPatternValid = (pattern) => {
    const braces = pattern.split('').filter(isBrace);
    return areBracesValid(braces) && !hasEmptyContent(pattern, braces);
};

/** @type { (source: Source) => FieldValidationErrors | null } */
export const validatePrimaryKey = source => (
    source.idpattern && !isPatternValid(source.idpattern)
        ? { idpattern: 'Invalid pattern' }
        : null
);

/** @type { (prefix: string) => boolean } */
export const isPrefixValid = (prefix) => {
    return /^[a-zA-Z0-9_-]+$/.test(prefix);
};

/** @type { (hostOrIp: string) => boolean } */
export const isHostOrIpValid = (hostOrIp) => {
    // based on https://www.regextester.com/99895

    /* eslint-disable-next-line max-len */
    return /^(https?:\/\/(?:www\.)?)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g
        .test(hostOrIp);
};

/** @type { (value: string) => boolean } */
export const isSSLValid = (value) => {
    /**
     * 1. Must have consistent number of "-": \1 is (-+)
     * 2. Must have consistent spacing after: \2 is ( ?)
     * 3. Must have consistent cert/key name: \3 is ((?: [A-Z0-9]+)*)
     * 4. The cert/key in between can be anything for flexibility.
     */
    return /^(-+)( ?)BEGIN((?: [A-Z0-9]+)*)\2\1.+\1\2END\3\2\1$/s.test(value);
};

/** @type { (source: Source) => FieldValidationErrors | null } */
export const validateDestination = source => (
    !isEmpty(source.destination) && !isPatternValid(source.destination || '')
        ? { destination: 'Invalid pattern' }
        : null
);

/** @type { (source: Source) => FieldValidationErrors | null } */
export const validateDestinationPrefix = source => (
    !isEmpty(source.destinationPrefix) && !isPrefixValid(source.destinationPrefix || '')
        ? { destinationPrefix: 'Allowed characters: "a-z", "A-Z", "0-9", "_", and "-".' }
        : null
);

/** @type { (source: Source) => FieldValidationErrors | null } */
export const validateDelimiter = source => {

    const { delimiter, otherDelimiter } = source.delimiter || {};

    if (delimiter === 'other' && !otherDelimiter) {
        return { delimiter: 'Value required' };
    }
    return null;
};

/** @type { (source: Source) => FieldValidationErrors | null } */
export const validateSSL = source => {
    if (isEmpty(source.ssl)) {
        return null;
    }

    const { cert = '', key = '', ca = '' } = source.ssl || {};

    const errors = {};

    if (!isEmpty(cert) || !isEmpty(key)) {
        if (!isMasked(cert) && !isSSLValid(cert)) {
            errors.cert = 'Invalid Client Certificate';
        }

        if (!isMasked(key) && !isSSLValid(key)) {
            errors.key = 'Invalid Client Key';
        }
    }

    if (!isEmpty(ca) && !isMasked(ca) && !isSSLValid(ca)) {
        errors.ca = 'Invalid SSL Certificate';
    }

    return !isEmpty(errors) ? { ssl: errors } : null;
};

/** @type { (source: Source, flattenedParams: FlattenedParam[]) => FieldValidationErrors | null } */
export const validateMatrixParams = (source, flattenedParams) => {
    const matrixParams = flattenedParams.filter(isMatrixFlattenedParam);

    if (!matrixParams.length) {
        return null;
    }

    const invalidParam = matrixParams.find(flattenedParam => {
        return validateMatrixParam(source, flattenedParam);
    });

    if (invalidParam) {
        const [param] = invalidParam;
        return { [param.name]: 'Column validation error' };
    }

    return null;
};

/** @type {(s: Source, flattenedParam: FlattenedParam<MatrixParam>) => MatrixParamColumn | null } */
export const validateMatrixParam = (source, flattenedParam) => {
    const [param] = flattenedParam;

    const validatedColumns = param.columns.filter(isMatrixParamColumn);

    const value = getParamValue(source, flattenedParam);

    const invalidColumn = validatedColumns.find((col) => {
        const colValues = Array.isArray(value)
            && value.map(row => row[col.key]);

        if (!colValues || !colValues.length) {
            return false;
        }

        return !!colValues.find(value => !!col.validator?.test(value));
    });

    if (invalidColumn) {
        return invalidColumn;
    }

    return null;
};

/** @type { (column?: MatrixParamColumn) => column is MatrixParamColumn } */
const isMatrixParamColumn = column => !!column?.validator;

/** @type { (fp: FlattenedParam) => fp is FlattenedParam<MatrixParam> } */
const isMatrixFlattenedParam = fp => fp[0].type === 'matrix';

/** @type { (source: Source) => FieldValidationErrors | null } */
export const validateSSH = source => {

    if (isEmpty(source.sshTunnel)) {
        return null;
    }

    const {
        active,
        host,
        port,
        username,
        privateKey,
    } = source.sshTunnel || {};

    const errors = {};

    if (!active) {
        return null;
    }

    if (!host) {
        errors.host = 'SSH Host is required';
    }
    if (host && !isHostOrIpValid(host)) {
        errors.host = 'SSH Host is invalid';
    }

    if (port && (port < 0 || port > 65535)) {
        errors.port = 'SSH Port is invalid';
    }

    if (!username) {
        errors.username = 'SSH User is required';
    }

    if (!privateKey) {
        errors.privateKey = 'SSH Private Key is required';
    }

    return isEmpty(errors)
        ? null
        : /** @type { FieldValidationErrors } */ ({ sshTunnel: errors });
};
