import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Workbench from '../../../shared/workbench';
import NestedPageWrapper from './nested-page-wrapper';
import { useSourcePageContext } from '../use-source-page/source-page-context';

/** @typedef { import('models/sources/__types').SourceType } SourceType */
/** @typedef { { fromSideNav: boolean } } LocationState */

const Recipes = () => {
    const navigate = useNavigate();
    const { source, recipes } = useSourcePageContext();

    useEffect(() => {
        if (!source?.id) {
            navigate('/sources', { replace: true });
            return;
        }

        if (!recipes?.length) {
            navigate(`/sources/${source.id}`, { replace: true });
            return;
        }
    }, [source?.id, recipes, navigate]);

    return (
        <NestedPageWrapper spacing="my-0 px-0">
            <Workbench />
        </NestedPageWrapper>
    );
};

export default Recipes;
