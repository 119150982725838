import { isSourceCollecting } from '../../models/sources';

/** @typedef { import('../../models/sources/__types').Source } Source */
/** @typedef { import('../../models/sources/__types').SourceType } SourceType */

/** @type { (args: { source?: Source }) => string } */
export const iconSelector = ({ source }) => (
    source
        ? `https://learn.panoply.io/hubfs/Data%20Source%20Icons/${source.type}.svg`
        : ''
);

/** @type { (args: { source?: Source, sourceType?: SourceType }) => string } */
export const titleSelector = ({ source, sourceType }) => (
    (source && source.title)
    || (sourceType && sourceType.title)
    || (source && source.type)
    || ''
);

/** @type { (args: { source?: Source }) => boolean } */
export const collectingSelector = ({ source }) => (
    !!source
    && isSourceCollecting(source)
);
