import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';

import {
    ToggleButton,
    Layout,
    Dialog,
    Typography,
    Button,
} from 'ui-components';

import {
    useQuery,
} from '@tanstack/react-query';

import { fetchAllSources, fetchSourceTypes } from '../../models/sources';
import { fetchJobs, fetchJobsSummary } from '../../models/jobs';

import useDebounce from '../../shared/use-debounce';
import { useSession } from '../../shared/context-providers';
import { useRequest } from '../../lib/ajax';
import PageLayout from '../../shared/page-layout';
import PageHeader from '../../shared/page-header';
import JobsStatusCards from './jobs-status-cards';
import JobsFilters from './jobs-filters';
import JobsList from './jobs-list';

/** @typedef { import('../../app/__types').PageProps } PageProps */
/** @typedef { import('./__types').Job } Job */

/** @type { React.FC<PageProps> } */
const Jobs = () => {

    const [isAdminMode, setIsAdminMode] = useState(false);
    const [session] = useSession();
    const { user } = session;

    const {
        data: sourceTypes,
        isLoading: sourceTypesLoading,
    } = useQuery({ queryKey: ['sourceTypes'], queryFn: fetchSourceTypes });

    const {
        data: jobsSummary,
        isLoading: jobsSummaryLoading,
    } = useQuery({ queryKey: ['jobsSummary'], queryFn: fetchJobsSummary });

    const [jobToExport, setJobToExport] = useState(/** @type { Job } */ ({}));

    const {
        go: exportJob,
        clear: clearExportJob,
    } = useRequest(`/workbench/export/${jobToExport.id}`);

    useEffect(() => {
        if (!jobToExport.id) {
            return;
        }
        exportJob().then((res) => {
            if (!res.ok) {
                return;
            }
            setJobToExport(/** @type { Job } */ ({}));
            clearExportJob();
            window.location.assign(res.data);
        });
    }, [jobToExport, exportJob, clearExportJob]);

    const [showFilters, setShowFilters] = useState(false);

    /* eslint-disable quote-props */
    // Disabled because of special characters in some keys.
    const [filters, setFilters] = useState({
        'time': {
            'field': 'created_at',
            'range': '',
        },
        'type': {
            'collect': false,
            'collect-direct': false,
            'materialize': false,
            'export': false,
            'alter': false,
            'suspend-database': false,
            'unsuspend-database': false,
            'reassign-database': false,
            'archive-table': false,
            'replay-datalogs': false,
        },
        'status': {
            'pending': false,
            'running': false,
            'success': false,
            'error': false,
        },
    });
    /* eslint-enable quote-props */

    const debouncedFilters = useDebounce(filters, 750);

    const [page, setPage] = useState(0);
    const jobsPerPage = 20;

    const updatePage = useCallback((newPage) => {
        setPage(newPage);
    }, []);

    useEffect(() => {
        // Set page back to 0 if filters change
        updatePage(0);
    }, [filters, isAdminMode, updatePage]);

    const getJobsQueryData = useCallback(() => {
        const between = {};
        if (debouncedFilters.time.range) {
            const now = moment.utc();
            const until = moment.utc(now);
            const since = now.subtract(debouncedFilters.time.range, 'hours');
            between.field = debouncedFilters.time.field;
            between.bounds = [
                since.format('YYYY-MM-DD HH:mm:ss'),
                until.format('YYYY-MM-DD HH:mm:ss'),
            ];
        }
        return {
            $admin: isAdminMode,
            $sort: '-created_at',
            $between: between,
            $offset: jobsPerPage * page,
            $limit: jobsPerPage + 1,
            status: Object.keys(debouncedFilters.status)
                .filter(k => debouncedFilters.status[k]),
            type: Object.keys(debouncedFilters.type)
                .filter(k => debouncedFilters.type[k]),
        };
    }, [debouncedFilters, isAdminMode, page]);

    const [jobsQuery, setJobsQuery] = useState(getJobsQueryData);

    useEffect(() => {
        setJobsQuery(getJobsQueryData());
    }, [getJobsQueryData]);

    const {
        data: jobs,
        error: jobsError,
        isLoading: jobsLoading,
        refetch: refetchJobs,
    } = useQuery({
        queryKey: ['jobs', jobsQuery],
        queryFn: () => fetchJobs(jobsQuery),
    });

    const {
        data: sources,
        isLoading: sourcesLoading,
    } = useQuery({ queryKey: ['sources'], queryFn: fetchAllSources });

    const [jobToCancel, setJobToCancel] = useState(/** @type { Job } */ ({}));

    const {
        go: cancelJob,
        clear: clearCancelJob,
        ...cancelJobReq
    } = useRequest(`/jobs/${jobToCancel.id}`, 'DELETE');

    const onCloseCancelDialog = useCallback(() => {
        setJobToCancel(/** @type { Job } */ ({}));
    }, []);

    const onCancelJobConfirm = useCallback(() => {
        cancelJob().then((res) => {
            if (!res.ok) {
                return;
            }
            onCloseCancelDialog();
            clearCancelJob();
            refetchJobs();
        });
    }, [cancelJob, clearCancelJob, fetchJobs, onCloseCancelDialog]);

    const updateTimeField = (value) => {
        setFilters({
            ...filters,
            time: {
                ...filters.time,
                field: value,
            },
        });
    };

    const updateTimeRange = (value) => {
        setFilters({
            ...filters,
            time: {
                ...filters.time,
                range: value,
            },
        });
    };

    const updateFilters = (group, filterName) => (
        (e) => {
            const filterNames = Array.isArray(filterName) ? filterName : [filterName];
            setFilters({
                ...filters,
                [group]: {
                    ...filters[group],
                    ...filterNames.reduce((obj, key) => ({ ...obj, [key]: e.target.checked }), {}),
                },
            });
        }
    );

    return (
        <PageLayout>
            <PageHeader title="Jobs" />

            <JobsStatusCards loading={jobsSummaryLoading} summary={jobsSummary} />

            <Layout flex justifyContent="flex-end">

                {(user.admin || user.developer) && (
                    <ToggleButton
                        type="solid"
                        active={isAdminMode}
                        leftIcon="shield-alt"
                        spacing="mr-2"
                        onClick={() => setIsAdminMode(!isAdminMode)}
                    >
                        Admin Mode
                    </ToggleButton>
                )}

                <ToggleButton
                    type="solid"
                    active={showFilters}
                    leftIcon="sliders-v"
                    spacing="mx-0"
                    onClick={() => setShowFilters(!showFilters)}
                >
                    Filters
                </ToggleButton>
            </Layout>

            {showFilters && (
                <JobsFilters
                    isAdminMode={isAdminMode}
                    filters={filters}
                    updateFilters={updateFilters}
                    updateTimeRange={updateTimeRange}
                    updateTimeField={updateTimeField}
                />
            )}

            {!jobsError && (
                <JobsList
                    jobs={jobs || []}
                    sources={sources || []}
                    sourceTypes={sourceTypes || []}
                    loading={jobsLoading || sourcesLoading || sourceTypesLoading}
                    jobsPerPage={jobsPerPage}
                    updatePage={updatePage}
                    page={page}
                />
            )}

            <Dialog
                title="Are you sure?"
                isOpen={!!jobToCancel.id}
                onClose={onCloseCancelDialog}
                actions={[
                    <Button key={1} onClick={onCloseCancelDialog} type="outline">Cancel</Button>,
                    <Button
                        key={2}
                        onClick={onCancelJobConfirm}
                        loading={cancelJobReq.loading}
                        color="primary"
                    >
                        OK
                    </Button>,
                ]}
            >
                {cancelJobReq.error ? (
                    <Typography variant="subtitle1" color="error">
                        {cancelJobReq.error.message}
                    </Typography>
                ) : (
                    <Typography variant="subtitle1">
                        This will cancel the selected job. Data that has already
                        been processed will be preserved.
                    </Typography>
                )}
            </Dialog>
        </PageLayout>
    );
};

export default Jobs;
