import prettyBytes from 'pretty-bytes';

/**
 * @param { number | string } bytes
 * @param { number } [multiplier]
 */
export const formatBytes = (bytes, multiplier) => {
    try {
        let normalizedBytes = Number(bytes);
        normalizedBytes = Number.isFinite(normalizedBytes) ? normalizedBytes : 0;

        if (multiplier && Number.isFinite(multiplier)) {
            normalizedBytes *= multiplier;
        }

        return prettyBytes(normalizedBytes, { maximumFractionDigits: 2, binary: true });
    } catch (err) {
        return '';
    }
};
