
import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { DatePicker } from 'ui-components';
import MarkdownRenderer from '../../../shared/markdown-renderer';

/** @typedef { import('models/sources/__types').DateParam } DateParam */
/** @typedef { import('models/sources/__types').DatetimeParam } DatetimeParam */
/** @typedef { moment.Moment } Moment */

/**
 * @typedef { object } Props
 * @prop { DateParam | DatetimeParam } param
 * @prop { string } value
 * @prop { (val: string) => void } setValue
 */

/** @type { React.FC<Props> } */
const DateParam = ({
    param,
    value,
    setValue,
}) => {

    /** @type { (newDate: Moment) => void } */
    const onValueChanged = useCallback(newDate => {
        // Moment.toISOString automatically converts to UTC
        const valueToSet = param.type === 'date'
            ? newDate.format('YYYY-MM-DD')
            : newDate.toISOString();

        setValue(valueToSet);
    }, [setValue, param.type]);

    const disableFrom = param.disableFrom === 'now'
        ? moment()
        : param.disableFrom;

    const disableUntil = param.disableUntil === 'now'
        ? moment()
        : param.disableUntil;

    const disabled = useMemo(() => {
        if (param.disabled) {
            return true;
        }
        if (!disableFrom || !disableUntil) {
            return false;
        }
        if (moment(disableFrom).isBefore(disableUntil)) {
            return true;
        }
        if (moment(disableFrom).isSame(disableUntil)) {
            return true;
        }
        return false;
    }, [disableFrom, disableUntil, param]);

    const DatePickerProps = {
        value,
        onChange: onValueChanged,
        type: param.type,
        maxDate: disableFrom,
        minDate: disableUntil,
        minutesStep: 1,
        spacing: 'px-0',
        width: '50',
        disabled,
        label: param.placeholder,
        helperText: param.description && (
            <MarkdownRenderer source={param.description} />
        ),
    };

    return (
        <DatePicker
            {...DatePickerProps}
        />
    );
};

export default DateParam;
