/** @type { (email: string) => boolean } */
function isValidEmail(email) {
    if (!email) {
        return false;
    }

    const regexp = new RegExp(''
        + '^'
            // Ex: myname, my.name, my+name
            + '[^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*'
        + '@'
            // Ex: domain.com, domain.co.uk
            + '([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}'
        + '$');

    return email.search(regexp) !== -1;
}

export default isValidEmail;
