/* eslint curly: "error" */

import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import * as Sources from '../../../models/sources';

import {
    titleSelector,
    iconSelector,
    collectingSelector,
} from '../../../shared/source/selectors';

/** @typedef { import('./__types').State } State */
/** @typedef { import('models/sources/__types').Source } Source */
/** @typedef { import('models/sources/__types').SourceType } SourceType */
/** @typedef { import('models/sources/__types').FlattenedParam } FlattenedParam */

/** @type { (flattenedParams: FlattenedParam[], s?: Source, st?: SourceType) => boolean } */
const isSourceCollectable = (flattenedParams, source, sourceType) => {
    return !!source
        && !!sourceType
        && !sourceType.offline
        && Sources.isSourceCollectable(source, flattenedParams);
};

/** @type { (ps: State['paramState']) => boolean } */
const isParamStateValid = (paramState) => {
    return Object.values(paramState)
        .every(param => !param.loading && !param.error && !param.invalid);
};

/** @type { (s: State) => boolean } */
export const shouldSaveSelector = (state) => {
    const {
        source,
        pendingChanges,
        fieldValidationErrors,
        paramState,
    } = state;

    const paramStateValid = isParamStateValid(paramState);

    return !!source
        && !isEmpty(pendingChanges)
        && isEmpty(fieldValidationErrors)
        && paramStateValid;
};

/** @type { (state: State) => boolean } */
const collectableAfterSaveSelector = (state) => {
    const {
        sourceType,
        paramState,
        fieldValidationErrors,
        editingSource,
        flattenedParams,
    } = state;

    const fieldsValid = isEmpty(fieldValidationErrors);

    const paramStateValid = isParamStateValid(paramState);

    const isCollectable = isSourceCollectable(flattenedParams, editingSource, sourceType);

    return isCollectable && fieldsValid && paramStateValid;
};

/** @type { (s: State) => boolean } */
const collectableSelector = ({
    sourceType,
    source,
    pendingChanges,
    paramState,
    fieldValidationErrors,
    flattenedParams,
}) => {

    const fieldsValid = isEmpty(fieldValidationErrors);
    const hasNoPendingChanges = isEmpty(pendingChanges);

    const paramStateValid = isParamStateValid(paramState);

    const isCollectable = isSourceCollectable(flattenedParams, source, sourceType);

    return isCollectable && hasNoPendingChanges && fieldsValid && paramStateValid;
};

/** @type { (state: State, editingSource?: Source) => boolean } */
const previewableSelector = (state) => {
    const {
        sourceType,
        fieldValidationErrors,
        paramState,
        editingSource,
        flattenedParams,
    } = state;

    const fieldsValid = isEmpty(fieldValidationErrors);

    const paramStateValid = isParamStateValid(paramState);

    const isCollectable = isSourceCollectable(flattenedParams, editingSource, sourceType);

    return isCollectable && fieldsValid && paramStateValid;
};

/** @type { (s: State) => boolean } */
const canUseRecipesSelector = ({ source, recipes }) => {
    return !!(recipes && recipes.length) && !source?.isFirstRun;
};

/* eslint-disable react-hooks/exhaustive-deps */
/* we should probably refactor so each selector only gets passed the state it requires */
/* would make this eslint-disable unnecessary */

// eslint-disable-next-line arrow-parens
const useSelectors = (/** @type { State } */ state) => {
    return {
        shouldSave: useMemo(
            shouldSaveSelector.bind(null, state),
            [
                state.source,
                state.pendingChanges,
                state.fieldValidationErrors,
                state.paramState,
            ],
        ),
        collecting: useMemo(collectingSelector.bind(null, state), [state.source]),
        collectableAfterSave: useMemo(
            collectableAfterSaveSelector.bind(null, state),
            [
                state.sourceType,
                state.fieldValidationErrors,
                state.paramState,
                state.editingSource,
                state.flattenedParams,
            ],
        ),
        collectable: useMemo(
            collectableSelector.bind(null, state),
            [
                state.sourceType,
                state.source,
                state.pendingChanges,
                state.fieldValidationErrors,
                state.paramState,
                state.flattenedParams,
            ],
        ),
        previewable: useMemo(
            previewableSelector.bind(null, state),
            [
                state.sourceType,
                state.fieldValidationErrors,
                state.paramState,
                state.editingSource,
                state.flattenedParams,
            ],
        ),
        icon: useMemo(iconSelector.bind(null, state), [state.source]),
        title: useMemo(titleSelector.bind(null, state), [state.source, state.sourceType]),
        canUseRecipes: useMemo(
            canUseRecipesSelector.bind(null, state),
            [state.source, state.recipes],
        ),
    };
};
/* eslint-enable react-hooks/exhaustive-deps */

export default useSelectors;
