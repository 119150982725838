import { useState, useEffect } from 'react';

/**
 * Inspired by https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
 *
 * @type { <T>(value: T, delay: number) => T }
 */
function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        // Set the value after a specified delay.
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Called when the useEffect is re-called.
        // It happens only if value changes again.
        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
}

export default useDebounce;
