import React from 'react';
import styled from 'styled-components';
import { Spreadsheet } from 'ui-components';

/** @typedef { import('models/queries/__types').QueryResultData<Row> } QueryResultData */
/** @typedef { import('./__types').Column } Column */
/** @typedef { import('./__types').Position } Position */
/** @typedef { import('./__types').AutoIndex } AutoIndex */
/** @typedef { { [key: string]: any } } Node */
/** @typedef { Date | string | number | boolean | undefined | null } RawValue */
/** @typedef { { value: RawValue } } NestedValue */
/** @typedef { RawValue | NestedValue } Value */
/** @typedef { { [key: string]: Value } | Value[] } Row */
/** @typedef { 'asc' | 'desc' } Direction */

/**
 * @typedef { object } Props
 * @prop { string } [className]
 * @prop { QueryResultData } queryData
 * @prop { number } visualizationHeight
 * @prop { Column[] } [columns]
 * @prop { Direction } [sortIndex]
 * @prop { AutoIndex } [autoIndex]
 * @prop { boolean } [noBorder]
 * @prop { boolean } [noResize]
 * @prop { boolean } [noSort]
 * @prop { boolean } [virtualized]
 * @prop { 'secondary' | 'default' } [color]
 * @prop { (to?: Position, from?: Position, node?: Node) => void } [onDrop]
 */

/** @type { React.FC<Props> } */
const WorkbenchTable = React.memo(({
    className,
    queryData,
    visualizationHeight,
    columns,
    sortIndex,
    autoIndex,
    noBorder,
    noResize,
    noSort,
    virtualized,
    color,
    onDrop,
}) => {
    if (!queryData?.rows?.length) {
        return null;
    }

    const page = queryData.page || 0;
    const pageSize = queryData.pageSize || 100;
    const count = queryData.count || queryData.rows.length;

    const startIndex = page ? page * pageSize : 0;
    return (
        <StyledSpreadsheet
            className={className}
            data={queryData.rows}
            startIndex={(sortIndex === 'desc'
                ? count - startIndex - 1
                : startIndex
            )}
            onDrop={onDrop}
            squareCorners
            columns={columns}
            sortIndex={sortIndex}
            autoIndex={autoIndex}
            noBorder={noBorder}
            noResize={noResize}
            noSort={noSort}
            color={color}
            height={visualizationHeight + 'px'}
            virtualized={virtualized}
        />
    );
});

const StyledSpreadsheet = styled(Spreadsheet)`
    border-top: 0;
    border-left: 0;
    border-right: 0;
`;

export default WorkbenchTable;
