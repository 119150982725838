import React from 'react';
import styled from 'styled-components';

import {
    Button,
    Callout,
    Typography,
    ValidatedInput,
    Select,
    SelectItem,
    Tooltip,
    Icon,
} from 'ui-components';

import {
    redshiftDatabaseNameValidators,
    bigqueryDatabaseNameValidators,
} from '../../models/databases';
import { passwordValidators, usernameValidators } from '../../models/users';
import CreatingDataWarehouse from './creating-data-warehouse';

/** @typedef { import('./use-complete-signup-form').DatabaseRegionOption } DatabaseRegionOption */

/**
 * @typedef { object } Props
 * @prop { string } databaseName
 * @prop { string } password
 * @prop { string } username
 * @prop { string } databaseType
 * @prop { string } databaseRegion
 * @prop { DatabaseRegionOption[] } databaseRegionOptions
 * @prop { string } fname
 * @prop { boolean } databaseNameIsValid
 * @prop { boolean } passwordIsValid
 * @prop { boolean } usernameIsValid
 * @prop { string } passwordLabel
 * @prop { boolean } completeIsLoading
 * @prop { { message: string } } completeError
 * @prop { boolean } completeIsDisabled
 * @prop { boolean } showDatabaseType
 * @prop { boolean } showDatabaseRegion
 * @prop { () => void } toggleShowDatabaseType
 * @prop { () => void } signOut
 * @prop { (value: string) => void } onDatabaseNameChanged
 * @prop { (value: string) => void } onPasswordChanged
 * @prop { (value: string) => void } onUsernameChanged
 * @prop { (value: string) => void } onDatabaseTypeChanged
 * @prop { (value: string) => void } onDatabaseRegionChanged
 * @prop { (valid: boolean) => void } onDatabaseNameIsValidChanged
 * @prop { (valid: boolean) => void } onPasswordIsValidChanged
 * @prop { (valid: boolean) => void } onUsernameIsValidChanged
 * @prop { () => void } onCompleteSignupClicked
 * @prop { () => void } onFormEnterPressed
 */

/** @type { React.FC<Props> } */
const CompleteSignupForm = ({
    databaseName,
    password,
    username,
    databaseType,
    databaseRegion,
    databaseRegionOptions,
    fname,
    databaseNameIsValid,
    passwordIsValid,
    usernameIsValid,
    passwordLabel,
    completeIsLoading,
    completeError,
    completeIsDisabled,
    showDatabaseType,
    showDatabaseRegion,
    toggleShowDatabaseType,
    signOut,
    onDatabaseNameChanged,
    onPasswordChanged,
    onUsernameChanged,
    onDatabaseTypeChanged,
    onDatabaseRegionChanged,
    onDatabaseNameIsValidChanged,
    onPasswordIsValidChanged,
    onUsernameIsValidChanged,
    onCompleteSignupClicked,
    onFormEnterPressed,
}) => {
    const databaseNameProps = {
        label: 'Data warehouse name',
        name: 'database_name',
        value: databaseName,
        valid: databaseNameIsValid,
        validators: databaseType === 'bigquery'
            ? bigqueryDatabaseNameValidators
            : redshiftDatabaseNameValidators,
        disabled: completeIsLoading,
        onKeyDown: onFormEnterPressed,
        onChange: onDatabaseNameChanged,
        onChangeValid: onDatabaseNameIsValidChanged,
    };

    const passwordProps = {
        label: 'Password',
        name: 'password',
        type: 'password',
        value: password,
        valid: passwordIsValid,
        validators: passwordValidators,
        disabled: completeIsLoading,
        onKeyDown: onFormEnterPressed,
        onChange: onPasswordChanged,
        onChangeValid: onPasswordIsValidChanged,
    };

    const usernameProps = {
        label: 'Username',
        name: 'username',
        validators: usernameValidators,
        value: username,
        valid: usernameIsValid,
        disabled: completeIsLoading,
        onChange: onUsernameChanged,
        onKeyDown: onFormEnterPressed,
        onChangeValid: onUsernameIsValidChanged,
    };

    const databaseTypeProps = {
        value: databaseType,
        onChange: onDatabaseTypeChanged,
    };

    const databaseRegionProps = {
        value: databaseRegion,
        onChange: onDatabaseRegionChanged,
    };

    const buttonProps = {
        color: 'primary',
        onClick: onCompleteSignupClicked,
        loading: completeIsLoading,
        disabled: completeIsDisabled,
    };

    if (completeIsLoading) {
        return (
            <CreatingDataWarehouse />
        );
    }

    return (
        <>
            <Typography variant="h4" align="center" spacing="mb-6">
                {fname ? (
                    `Welcome to Panoply, ${fname}!`
                ) : (
                    'Welcome to Panoply!'
                )}
            </Typography>

            {!completeError && (
                <Typography variant="body1" color="secondaryText" spacing="mb-6" align="center">
                    Just 2 more steps and you can start syncing data.
                </Typography>
            )}

            {completeError && !completeIsLoading && (
                <>
                    <Typography variant="body1" color="secondaryText" spacing="mb-6" align="center">
                        Please check the errors below, then try launching Panoply again.
                    </Typography>
                    <Callout color="error" spacing="mb-5">
                        {completeError.message}
                    </Callout>
                </>
            )}

            <Typography variant="subtitle1" weight="medium" spacing="m-0">
                Name your data warehouse
            </Typography>

            <Typography variant="body2" color="interface" spacing="mt-1 mb-0">
                Must be unique, and cannot be changed later.
            </Typography>

            <ValidatedInput
                spacing="-mx-2 mb-5"
                noFloatLabel
                {...databaseNameProps}
            />

            <Typography variant="subtitle1" weight="medium" spacing="m-0">
                Set your Panoply username
            </Typography>

            <Typography variant="body2" color="interface" spacing="mt-1 mb-0">
                Must be unique, and cannot be changed later.
            </Typography>

            <ValidatedInput
                spacing="-mx-2 mb-5"
                noFloatLabel
                {...usernameProps}
            />

            <Typography variant="subtitle1" weight="medium" spacing="m-0">
                {passwordLabel}
            </Typography>

            <ValidatedInput
                spacing="-mx-2 mb-5"
                noFloatLabel
                {...passwordProps}
            />

            {showDatabaseRegion && (
                <>
                    <Typography variant="subtitle1" weight="medium" spacing="m-0">
                        Select your Panoply region
                    </Typography>

                    <Select spacing="px-0 mb-5" width="100" {...databaseRegionProps}>
                        {databaseRegionOptions.map(region => (
                            <SelectItem
                                key={region.value}
                                value={region.value}
                            >
                                {region.title}
                            </SelectItem>
                        ))}
                    </Select>
                </>
            )}

            {showDatabaseType && (
                <>
                    <Typography component="div" variant="subtitle1" weight="medium" spacing="m-0">
                        Choose a storage provider for your Panoply warehouse
                        <Tooltip
                            content={
                                <>
                                    <Typography variant="body2" spacing="m-0 p-0">
                                        Panoply will store your data in BigQuery&nbsp;(recommended),
                                        or Redshift.
                                    </Typography>
                                    <Typography spacing="mt-3">
                                        Benefits of choosing BigQuery include:
                                    </Typography>
                                    <Typography spacing="mt-3">
                                        &bull; Separation of storage and compute
                                    </Typography>
                                    <Typography spacing="mt-3">
                                        &bull; More free storage (1TB vs 100GB)
                                    </Typography>
                                    <Typography spacing="mt-3">
                                        &bull; Faster performance (Elastic)
                                    </Typography>
                                </>
                            }
                        >
                            <Icon icon="info-circle" spacing="ml-2" color="secondaryText" />
                        </Tooltip>
                    </Typography>

                    <Select spacing="px-0 mb-5" width="100" {...databaseTypeProps}>
                        <SelectItem value="bigquery">BigQuery (Recommended)</SelectItem>
                        <SelectItem value="redshift">Redshift</SelectItem>
                    </Select>
                </>
            )}

            <Button width="100" spacing="m-0" {...buttonProps}>
                Launch Panoply
            </Button>

            <Typography
                link
                variant="body2"
                color="secondaryText"
                onClick={signOut}
                align="center"
                spacing="mt-3"
            >
                Sign out
            </Typography>

            {!completeIsDisabled && !showDatabaseType && (
                <Typography align="center" variant="body2" color="secondaryText" spacing="mt-4">
                    Next we will create your data warehouse with our recommended settings.
                    Or, you can{' '}
                    <Underline inline component="span" onClick={toggleShowDatabaseType}>
                        choose your warehouse
                    </Underline>
                    {' '}settings.
                </Typography>
            )}
        </>
    );
};

const Underline = styled(Typography)`
    &&& {
        text-decoration: underline;
        cursor: pointer;
    }
`;

export default CompleteSignupForm;
