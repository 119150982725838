import React, { useState, useEffect } from 'react';
import {
    Dialog,
    Typography,
    Button,
    Callout,
} from 'ui-components';

import { deleteSource } from '../../models/sources';
import { reportError } from '../../lib/error-reporter';
import { deleteConnectorsModalSubmit } from './tracking';

/** @typedef { import('models/sources/__types').Source } Source */
/** @typedef { import('models/sources/__types').SourceType } SourceType */

/**
 * @typedef { object } Props
 * @prop { boolean } open
 * @prop { Source[] } sourcesToDelete
 * @prop { (source: Source) => SourceType | null } getMatchingSourceType
 * @prop { (numberOfDeleted: number) => void } onDeleted
 * @prop { () => void } onClose
 */

/** @type { React.FC<Props> } */
const BatchDeleteSourceDialog = React.memo(({
    open,
    sourcesToDelete,
    getMatchingSourceType,
    onDeleted,
    onClose,
}) => {

    const [deleting, setDeleting] = useState(false);
    const [finished, setFinished] = useState(false);
    const [error, setError] = useState('');

    const deleteSources = async () => {
        setDeleting(true);

        try {
            await Promise.all(sourcesToDelete.map((source) => {
                return deleteSource(source.id);
            }));
            if (onDeleted) {
                onDeleted(sourcesToDelete.length);
            }
        } catch (err) {
            setError(
                'There was an error when deleting sources. Some sources may have not been deleted',
            );
            reportError(err);
        }

        setDeleting(false);
        setFinished(true);
    };

    useEffect(() => {
        if (!open) {
            return;
        }
        setFinished(false);
        setError('');
    }, [open]);

    const sourceLabel = sourcesToDelete.length > 1 ? 'connectors' : 'connector';

    const onCancel = () => {
        deleteConnectorsModalSubmit('false');
        onClose();
    };

    const onDelete = () => {
        deleteConnectorsModalSubmit('true');
        deleteSources();
    };

    const actions = (
        <>
            {!finished && (
                <>
                    <Button
                        onClick={onCancel}
                        type="plain"
                        disabled={deleting}
                    >
                        Cancel
                    </Button>
                    <Button
                        loading={deleting}
                        onClick={onDelete}
                        color="error"
                    >
                        Delete {sourcesToDelete.length}{' '}
                        {sourcesToDelete.length > 1 ? 'Connectors' : 'Connector'}
                    </Button>
                </>
            )}
            {finished && (
                <Button
                    onClick={onClose}
                >
                    Finish
                </Button>
            )}
        </>
    );

    return (
        <Dialog
            isOpen={open}
            onClose={onClose}
            actions={actions}
        >
            {finished && !error && (
                <>
                    <Typography
                        spacing="mt-1"
                        color="text"
                        variant="h6"
                        align="center"
                    >
                        {sourcesToDelete.length} {sourceLabel} deleted successfully.
                    </Typography>

                    <Callout
                        color="default"
                        spacing="mt-4"
                    >
                        <Typography
                            component="span"
                            variant="subtitle1"
                            spacing="ml-2 mb-0"
                        >
                            Deleted {sourceLabel}:
                            {sourcesToDelete.map((source) => (
                                <Typography
                                    key={source.id}
                                    color="text"
                                    weight="medium"
                                >
                                    {source.title}
                                </Typography>
                            ))}
                        </Typography>
                    </Callout>
                </>
            )}
            {finished && error && (
                <>
                    <Typography
                        spacing="mt-1"
                        color="text"
                        variant="h6"
                        align="center"
                    >
                        Something went wrong.
                    </Typography>

                    <Callout
                        color="error"
                        spacing="mt-4"
                    >
                        {error}
                    </Callout>
                </>
            )}
            {!finished && (
                <>
                    <Typography
                        spacing="mt-1"
                        color="text"
                        variant="h6"
                        align="center"
                    >
                        Delete {sourcesToDelete.length} {sourceLabel}?
                    </Typography>
                    <Typography variant="body1" align="center" color="text" spacing="my-4">
                        This will remove the selected {sourceLabel}{' '}
                        and disable any scheduled collections.
                    </Typography>
                    <Typography variant="body2" align="center" italic>
                        However, any collected tables will not be removed.
                    </Typography>
                    <Callout
                        color="default"
                        spacing="mt-5"
                    >
                        {sourcesToDelete.map((source) => {
                            const sourceTypeTitle = getMatchingSourceType(source)?.title;
                            return (
                                <Typography
                                    key={source.id}
                                    color="text"
                                    weight="medium"
                                >
                                    {source.title} {sourceTypeTitle ? `(${sourceTypeTitle})` : ''}
                                </Typography>
                            );
                        })}
                    </Callout>
                </>
            )}

        </Dialog>
    );
});

export default BatchDeleteSourceDialog;
