import React from 'react';
import { Typography } from 'ui-components';

/** @type { React.FC<{}> } */
const AcceptInviteFailure = () => (
    <>
        <Typography variant="h5" weight="medium" spacing="mb-0">
            Oops! Something went wrong
        </Typography>

        <Typography spacing="my-5">
            There&apos;s something different about
            how or where this invitation was sent.
        </Typography>

        <Typography spacing="mb-4 ml-4">
            &bull; You may have already accepted this invitation
        </Typography>

        <Typography spacing="mb-4 ml-4">
            &bull; You may have received a newer invitation
        </Typography>

        <Typography color="secondaryText">
            Contact your database owner or
            Panoply for further support.
        </Typography>
    </>
);

export default AcceptInviteFailure;
