import React from 'react';
import { Link as ClickLink, Typography } from 'ui-components';

/** @type { React.FC } */
const ChangePasswordSuccess = () => (
    <>
        <Typography align="center" variant="h4" spacing="mb-6">
            Password Changed!
        </Typography>

        <Typography align="center" spacing="mb-5">
            Your password was successfully changed.
        </Typography>

        <ClickLink
            align="center"
            color="primary"
            link="/login"
        >
            Go Log In!
        </ClickLink>
    </>
);

export default ChangePasswordSuccess;
