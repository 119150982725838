import React, { useEffect, useCallback, useMemo } from 'react';
import { pickBy, template } from 'lodash';
import { parse as parseQueryString, stringify } from 'qs';
import { Button, Layout, Typography } from 'ui-components';
import { post } from '../../../../lib/ajax';
import { reportError } from '../../../../lib/error-reporter';
import MarkdownRenderer from '../../../../shared/markdown-renderer';

import BrandedButton, { getVendor } from './branded-button';
import { generateOauthState, validateOauthState } from './security';

/** @typedef { import('models/sources/__types').Source } Source */
/** @typedef { import('models/sources/__types').SourceType } SourceType */
/** @typedef { import('models/sources/__types').OAuth2Param } Param */
/** @typedef { import('models/sources/__types').OAuth2ParamValue } Value */

/**
 * @typedef { object } State
 * @prop { boolean= } loading
 * @prop { string= } error
 */

/**
 * @typedef { object } Props
 * @prop { SourceType } sourceType
 * @prop { Source } source
 * @prop { Param } param
 * @prop { Value } value
 * @prop { State } state
 * @prop { (value: Value) => void } setValue
 * @prop { (state: State) => void } setState
 */


/** @type { (st: SourceType, s: Source, p: Param) => string } */
const generateOAuthURL = (sourceType, source, param) => {
    const redirect = `${window.location.origin}/sources/callback.html`;

    /** @type { NonNullable<SourceType['config']> } */
    const config = {
        ...(sourceType.config || {}),
        redirect_uri: redirect,
    };

    const interpolate = /{([\S]+?)}/g;
    const authUrl = interpolateSource(
        param.oauthAuthorizeURL || config.oauthAuthorizeURL,
        interpolate,
        source,
    );

    const queryObject = pickBy(config, (v, k) => !k.startsWith('oauth') && !k.endsWith('URL'));
    Object.keys(queryObject).forEach(key => {
        // It loadash.template throw an ReferenceError if there is no match found
        queryObject[key] = interpolateSource(queryObject[key], interpolate, source);
        if (!queryObject[key]) {
            delete queryObject[key];
        }
    });

    let queryString = stringify(
        queryObject
    );
    if (authUrl.includes('?')) {
        queryString = '&' + queryString;
    } else {
        queryString = '?' + queryString;
    }

    const url = authUrl + queryString;

    return url;
};

/** @type { (v: string, i: RegExp, s: Source) => string} */
const interpolateSource = (value, interpolate, source) => {
    try {
        return template(value, { interpolate })(source);
    } catch (/** @type { any } */ err) {
        reportError(err, { value, sourceId: source.id });
        return '';
    }
};


/** @type { React.FC<Props> } */
const SourceOAuth2Param = ({
    sourceType,
    source,
    param,
    value,
    state,
    setValue,
    setState,
}) => {
    /** @type { (evt: MessageEvent) => void } */
    const onMessage = useCallback(async (evt) => {
        // TODO: Add a security verification https://panoply.atlassian.net/browse/APPS-219
        if (evt.data && !evt.data.search) {
            return;
        }
        if (typeof evt.data.search !== 'string') {
            return;
        }

        const params = parseQueryString(evt.data.search, { ignoreQueryPrefix: true });
        if (!params.code || !validateOauthState(params.state)) {
            const e = new Error('Bad oauth event data');
            reportError(e, {
                data: evt.data.search,
                sourceId: source.id,
            });

            setState({
                loading: false,
                error: 'Something went wrong. The connector blocked access.'
                    + ' Verify your permissions and login again',
            });

            return;
        }

        if (params.error || params.error_description) {
            const msg = String(params.error_description
                || `Something went wrong (code: ${params.error})`);
            const e = new Error('Oauth2 error');
            reportError(e, {
                error: msg,
                data: evt.data.search,
                sourceId: source.id,
            });

            setState({ loading: false, error: msg });
            return;
        }

        setState({ ...state, loading: true, error: undefined });

        try {
            const { data } = await post(
                '/sources/oauth/access_token',
                {
                    body: {
                        ...source,
                        code: params.code,
                    },
                },
            );

            setValue(data);
            setState({ ...state, loading: false, error: undefined });
        } catch (err) {
            const errMsg = err.message || err.data.message;
            reportError(err, {
                sourceId: source.id,
            });

            setState({
                loading: false,
                error: `Could not complete OAuth with the provider due to an error: ${errMsg}`,
            });
        }
    }, [source, state, setState, setValue]);


    useEffect(() => {
        window.addEventListener('message', onMessage, false);
        return () => window.removeEventListener('message', onMessage);
    }, [onMessage]);

    const { loading, error } = state;
    const connected = !!value;
    const url = useMemo(
        () => generateOAuthURL(sourceType, source, param),
        [sourceType, source, param],
    );

    const connect = useCallback(() => {
        const connectUrl = new URL(url);
        connectUrl.searchParams.set('state', generateOauthState());

        window.open(connectUrl, '_blank');
    }, [url]);

    const disconnect = useCallback(() => {
        setValue(null);
    }, [setValue]);

    const vendor = getVendor(source.type);

    return (
        <Layout width="100">
            <Layout flex alignItems="center">
                {connected ? (
                    <Button onClick={disconnect} loading={loading} spacing="ml-0">
                        Disconnect
                    </Button>
                ) : (!vendor ? (
                    // TODO: make button use <a href="..." target="_blank"> to
                    // solve: https://app.asana.com/0/711938065542210/145601129191397
                    <Button color="primary" onClick={connect} loading={loading} spacing="ml-0">
                        {param.cta_title || param.title}
                    </Button>
                ) : (
                    <BrandedButton vendor={vendor} onClick={connect} loading={loading} />
                ))}

                {error && (
                    <Typography spacing="ml-3" color="error">
                        {error}
                    </Typography>
                )}
            </Layout>
            {param.description && (
                <Typography spacing="mt-2 ml-3" color="secondaryText" variant="body2">
                    <MarkdownRenderer source={param.description} />
                </Typography>
            )}
        </Layout>
    );
};

export default SourceOAuth2Param;
