/* eslint-disable complexity */
import React, { useState, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';
import {
    Card,
    Divider,
    List,
    ListItem,
    Typography,
    Icon,
    Button,
    Layout,
    Tooltip,
    colors,
    breakpoints,
} from 'ui-components';

import NavLink from './nav-link';

import { routesMap } from './routes';

import { useSourcePageContext } from '../use-source-page/source-page-context';

import HistoryNavLink from './history-nav-link';

/** @typedef {import('models/sources/__types').CollectJob} CollectJob */

const { settings, knowledge, history, recipes: recipesRoute } = routesMap;

export const NavListItem = styled(ListItem)`
    && .typography {
        color: ${({ disabled }) => (disabled ? 'inherit' : colors.text)}
    }
`;

const SideNav = styled(Card)`
    &&& {
        height: 100%;
        z-index: 10;
        border-top: none; /* Removes the default card border-top */
        max-width: 300px;
        transition: all 0.3s;

        & ${NavListItem} {
            /* Prevent wrapping from causing jank during animations */
            white-space: nowrap;

            /* Setup fade in/out of NavListItem text */
            .typography, span {
                transition: all 0.3s;
                opacity: 0;
            }
        }

        ${({ expanded }) => (expanded ? css`
            width: 30%;
            min-width: 250px;
            overflow: visible;

            /* Fade in NavListItem text when expanded */
            & ${NavListItem} {
                .typography, span {
                    opacity: 1;
                }
            }

            /* Only show the ExpandSideBarButton on hover while expanded */
            /* Always shows when collapsed */
            & ${ExpandSidebarButton} {
                opacity: 0;
            }
            &:hover {
                & ${ExpandSidebarButton} {
                    opacity: 1;
                }
            }
        ` : `
            min-width: 54px;
            width: 54px;
            @media (min-width: ${breakpoints.xl}) {
                width: 58px;
            }
        `)}

    }
`;

const ExpandSidebarButton = styled(Button)`
    &&& {
        width: 28px;
        height: 28px;
        min-height: 28px;
        border-radius: 50px;
        transform: translateX(0px);
        margin-left: auto;
        margin-right: auto;
        span {
            opacity: 1;
        }
        &:hover {
            background-color: ${colors.primary.main};
            color: ${colors.white};
        }

        ${({ expanded }) => expanded && `
            margin-right: 0px;
            transform: translateX(14px);
        `}

    }
`;

/**
 * @typedef { object } SmallSidebarTooltipProps
 * @prop { boolean } expanded
 * @prop { React.ReactNode } content
 * @prop { React.ReactNode } children
 */

/** @type { React.FC<SmallSidebarTooltipProps> } */
const SmallSidebarTooltip = ({ expanded, content, children }) => {
    if (!expanded) {
        return (
            <Tooltip block content={content} interactive={false}>
                {children}
            </Tooltip>
        );
    }
    return <>{children}</>;
};

/** @type { React.FC } */
const SourceSideNavigation = () => {
    const {
        source,
        sourceType,
        collecting,
        collectable,
        collectableAfterSave,
        title,
        shouldSave,
        recipes,
        schedule,
        saveSource,
        collectSource,
        openRenameModal,
        openDeleteModal,
        openScheduleModal,
        openSyncModal,
        onCancelJob,
    } = useSourcePageContext();

    const location = useLocation();

    /** @type { { sourceId?: string } } */
    const { sourceId } = useParams();

    const [expanded, setExpanded] = useState(true);

    /** @type { (routePath: string) => boolean } */
    const isCurrentRoute = useCallback(routePath => (
        location.pathname === `/sources/${sourceId}/${routePath}`
    ), [location, sourceId]);

    const toggleExpanded = useCallback(async () => {
        setExpanded(!expanded);
    }, [expanded]);

    const collectOrStopCollect = useCallback(async () => {
        if (collecting) {
            return onCancelJob(source?.job);
        }

        if (shouldSave) {
            await saveSource();
        }

        return collectSource();
    }, [collecting, source, shouldSave, saveSource]);

    useEffect(() => {
        if (location.pathname.includes(recipesRoute.path)) {
            setExpanded(false);
        }
    }, [location.pathname]);

    const hasKnowledge = !(!sourceType?.links || !Object.keys(sourceType.links).length);

    const scheduled = !schedule?.disabled
        && (schedule?.dayOfWeek && schedule?.hour);

    const scheduleDialogDisabled = !scheduled
        && (!sourceType?.schedulable || !collectable || !collectableAfterSave);

    return (
        <SideNav spacing="p-0" contentSpacing="p-0" expanded={expanded} square>
            <List>
                <Layout
                    flex
                    spacing="pt-2 pr-0 pb-3"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100"
                >
                    {expanded && (
                        <Typography weight="medium" spacing="mb-0 ml-3" noWrap>
                            {title}
                        </Typography>
                    )}
                    <ExpandSidebarButton
                        onClick={toggleExpanded}
                        spacing="p-3"
                        expanded={expanded}
                        round
                    >
                        <Icon
                            icon={expanded ? 'angle-left' : 'angle-right'}
                        />
                    </ExpandSidebarButton>
                </Layout>

                <SmallSidebarTooltip content={settings.label} expanded={expanded}>
                    <NavLink to={`/sources/${sourceId}/${settings.path}`}>
                        <NavListItem
                            label={settings.label}
                            button
                            color={isCurrentRoute(settings.path) ? 'primary' : undefined}
                            selected={isCurrentRoute(settings.path)}
                            iconName="sliders-h"
                            iconColor="secondaryText"
                        />
                    </NavLink>
                </SmallSidebarTooltip>

                <SmallSidebarTooltip content={history.label} expanded={expanded}>
                    <HistoryNavLink
                        to={`/sources/${sourceId}/${history.path}`}
                        isCurrentRoute={isCurrentRoute(history.path)}
                        source={source}
                    />
                </SmallSidebarTooltip>

                <SmallSidebarTooltip content={recipesRoute.label} expanded={expanded}>
                    {/*
                    We need recipes to re-render if the user clicks again
                    while already on the page. So we add a changing query string
                    */}
                    <NavLink
                        to={`/sources/${sourceId}/${recipesRoute.path}?a=${Date.now()}`}
                        disabled={!recipes?.length || source?.isFirstRun}
                    >
                        <NavListItem
                            label={recipesRoute.label}
                            button
                            color={isCurrentRoute(recipesRoute.path) ? 'primary' : undefined}
                            selected={isCurrentRoute(recipesRoute.path)}
                            iconName="flask-round-potion"
                            iconColor="secondaryText"
                            disabled={!recipes?.length || source?.isFirstRun}
                            caption={recipes?.length === 0 ? 'Coming soon' : ''}
                        />
                    </NavLink>
                </SmallSidebarTooltip>

                {hasKnowledge && (
                    <SmallSidebarTooltip content={knowledge.label} expanded={expanded}>
                        <NavLink to={`/sources/${sourceId}/${knowledge.path}`}>
                            <NavListItem
                                label={knowledge.label}
                                button
                                color={isCurrentRoute(knowledge.path) ? 'primary' : undefined}
                                selected={isCurrentRoute(knowledge.path)}
                                iconName="question-circle"
                                iconColor="secondaryText"
                                caption={sourceType?.title}
                            />
                        </NavLink>
                    </SmallSidebarTooltip>
                )}

                <Divider />

                <SmallSidebarTooltip
                    content={collecting ? 'Stop Collecting' : 'Run a collection now'}
                    expanded={expanded}
                >
                    <NavListItem
                        label={collecting ? 'Stop Collecting' : 'Run a collection now'}
                        button
                        onClick={collectOrStopCollect}
                        iconName={collecting ? 'stop-circle' : 'sync'}
                        disabled={!(collectable || collectableAfterSave)}
                    />
                </SmallSidebarTooltip>

                {sourceType?.schedulable && (
                    <SmallSidebarTooltip content="Schedule collections" expanded={expanded}>
                        <NavListItem
                            label="Schedule collections"
                            button
                            disabled={scheduleDialogDisabled}
                            onClick={openScheduleModal}
                            iconName="clock"
                        />
                    </SmallSidebarTooltip>
                )}

                <SmallSidebarTooltip content="Copy settings" expanded={expanded}>
                    <NavListItem
                        label="Copy settings"
                        button
                        onClick={openSyncModal}
                        iconName="copy"
                        caption="Copy fields to other connectors"
                        disabled={!collectable}
                    />
                </SmallSidebarTooltip>

                <SmallSidebarTooltip content="Rename connector" expanded={expanded}>
                    <NavListItem
                        label="Rename connector"
                        button
                        onClick={openRenameModal}
                        iconName="pen"
                    />
                </SmallSidebarTooltip>

                <SmallSidebarTooltip content="Delete connector" expanded={expanded}>
                    <NavListItem
                        label="Delete connector"
                        button
                        onClick={openDeleteModal}
                        iconName="trash"
                    />
                </SmallSidebarTooltip>

            </List>
        </SideNav>
    );

};

export default SourceSideNavigation;
