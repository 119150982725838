import React from 'react';

import {
    Divider,
    Layout,
    Link as ClickLink,
    Typography,
} from 'ui-components';

import getLink from '../../lib/get-link';

function openTerms() {
    window.open('https://panoply.io/terms/', '_blank');
}

function openPrivacy() {
    window.open('https://panoply.io/privacy-policy/', '_blank');
}

/**
 * @typedef { object } Props
 * @prop { string= } email
 * @prop { boolean= } showForgotLink
 * @prop { boolean= } showTermsLink
 * @prop { boolean= } showPrivacyLink
 * @prop { boolean= } showLoginLink
 * @prop { boolean= } showSignupLink
 * @prop { string } [width]
 * @prop { string } [spacing]
 */

/** @type { React.FC<Props> } */
const AuthLinks = ({
    email,
    showForgotLink,
    showTermsLink,
    showPrivacyLink,
    showLoginLink,
    showSignupLink,
    ...props
}) => (
    <Layout {...props}>
        {showForgotLink && (
            <ClickLink
                color="primary"
                align="center"
                link={getLink('/users/reset', { email })}
                weight="normal"
            >
                Forgot Password?
            </ClickLink>
        )}

        {showTermsLink && showPrivacyLink && (
            <>
                <Typography variant="body2" color="secondaryText" align="center">
                    By signing up, you agree to Panoply&apos;s
                </Typography>

                <Typography variant="body2" color="secondaryText" align="center">
                    <ClickLink
                        inline
                        component="span"
                        spacing="mr-1"
                        color="primary"
                        onClick={openTerms}
                        weight="normal"
                    >
                        Terms of Service
                    </ClickLink>

                    and

                    <ClickLink
                        inline
                        component="span"
                        spacing="ml-1"
                        color="primary"
                        onClick={openPrivacy}
                        weight="normal"
                    >
                        Privacy Policy
                    </ClickLink>
                </Typography>
            </>
        )}

        {showLoginLink && (
            <>
                <Divider width="70" spacing="my-4" />

                <Typography variant="body2" color="secondaryText" align="center">
                    Already have an account?
                </Typography>

                <ClickLink
                    color="primary"
                    align="center"
                    link={getLink('/login', { email })}
                    weight="normal"
                >
                    Log In
                </ClickLink>
            </>
        )}

        {showSignupLink && (
            <>
                <Divider width="70" spacing="my-4" />

                <Typography variant="body2" color="secondaryText" align="center">
                    Don&apos;t have an account?
                </Typography>

                <ClickLink
                    color="primary"
                    align="center"
                    weight="normal"
                    link={getLink('/signup', { email })}
                >
                    Sign Up
                </ClickLink>
            </>
        )}
    </Layout>
);

export default AuthLinks;
