import React from 'react';
import { ToggleButton, Icon, Tooltip } from 'ui-components';

import MarkdownRenderer from '../markdown-renderer';
import { items } from './use-workbench-visualization';

/** @typedef { import('./__types').VisualizationType } VisualizationType */
/** @typedef { import('./__types').VisualizationReport } VisualizationReport */
/** @typedef { import('models/queries/__types').QueryResultData } QueryResultData */

/**
 * @typedef { object } WorkbenchVisualizationToggleProps
 *
 * @prop { QueryResultData } queryData
 * @prop { VisualizationReport } visualizationReport
 * @prop { (changes: Partial<VisualizationReport>) => void } onVisualizationReportChanged
 */

/** @type { React.FC<WorkbenchVisualizationToggleProps> } */
const WorkbenchVisualizationToggle = React.memo(({
    queryData,
    visualizationReport,
    onVisualizationReportChanged,
}) => (
    <>
        {Object.entries(items).map(([type, { icon, help, isDisabled }]) => (
            <Tooltip
                key={type}
                content={<MarkdownRenderer source={help} />}
                interactive={false}
                placement="top"
            >
                <ToggleButton
                    type="plain"
                    spacing="m-0 ml-1 p-2"
                    disabled={isDisabled(queryData)}
                    onClick={() => (
                        onVisualizationReportChanged({
                            type: /** @type { VisualizationType } */ (type),
                        })
                    )}
                    active={type === visualizationReport.type}
                    color="default"
                    square
                >
                    <Icon icon={icon} color="secondaryText" />
                </ToggleButton>
            </Tooltip>
        ))}
    </>
));

export default WorkbenchVisualizationToggle;
