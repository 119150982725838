import React, { useState, useCallback, createContext, useContext } from 'react';

/** @typedef { import('../../app/__types').Toast } Toast */
/** @typedef { import('../../app/__types').Reason } Reason */

/**
 * @typedef { object } Context
 * @prop { Toast } toast
 * @prop { (newToast: string | Toast) => void } openToast
 */

/** @type { (toast: Toast | string) => Toast } */
const normalizeToast = toast => (
    typeof toast === 'string' ? { message: toast } : toast
);

/** @type { Toast } */
const initialToast = {};

/** @type { Toast[] } */
const initialQueue = [];

/** @type { Context } */
const context = {
    toast: initialToast,
    openToast: () => {},
};

const ToastContext = createContext(context);

/** @type {React.FC<{ children: React.ReactNode }>} */
export const ToastProvider = ({ children }) => {

    const [toast, setToast] = useState(initialToast);
    const [queue, setQueue] = useState(initialQueue);

    /** @type { (e: MouseEvent, reason?: Reason) => void } */
    const onClose = useCallback((e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToast({ ...toast, open: false });
    }, [toast]);

    const onExited = useCallback(() => {
        if (queue.length > 0) {
            setTimeout(() => {
                const [nextToast, ...newQueue] = queue;
                setToast({ ...nextToast, open: true });
                setQueue(newQueue);
            }, 300);
        }
    }, [queue]);

    /** @type { (toast: string | Toast) => void } */
    const openToast = useCallback((newToast) => {
        if (toast.open || queue.length > 0) {
            setQueue([...queue, normalizeToast(newToast)]);
        } else {
            setToast({ ...normalizeToast(newToast), open: true });
        }
    }, [toast, queue]);

    return (
        <ToastContext.Provider value={{
            toast: {
                open: false,
                message: '',
                onClose,
                onExited,
                ...toast,
            },
            openToast,
        }}>
            {children}
        </ToastContext.Provider>
    );
};

const useToast = () => useContext(ToastContext);

export default useToast;
