import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Tooltip } from 'ui-components';

/** @typedef { import('./../../models/queries/__types').QueryResult } QueryResult */

/** @type { (queryResult?: QueryResult, startedAt?: Date, endedAt?: Date) => number } */
const getSecondsAgo = (queryResult, startedAt, endedAt) => {
    if (queryResult?.exportStartTime) {
        const { exportStartTime, exportEndTime } = queryResult;

        if (exportEndTime) {
            return moment(exportEndTime).diff(exportStartTime, 'seconds');
        }

        return moment().diff(exportStartTime, 'seconds');
    }

    if (!startedAt) {
        return 0;
    }

    if (endedAt) {
        return moment(endedAt).diff(startedAt, 'seconds');
    }

    return moment().diff(startedAt, 'seconds');
};

/**
 * @typedef { object } Props
 * @prop { Date } [startedAt]
 * @prop { Date } [endedAt]
 * @prop { boolean } [running]
 * @prop { QueryResult } [queryResult]
 * @prop { 'tooltip' } [mode]
 */

/** @type { React.FC<Props> } */
const Timer = ({
    startedAt: startTime,
    endedAt: endTime,
    running,
    queryResult,
    mode,
}) => {
    const startedAt = queryResult?.startTime
        ? moment(queryResult?.startTime).toDate()
        : startTime;

    const endedAt = queryResult?.endTime
        ? moment(queryResult?.endTime).toDate()
        : endTime;

    const [secondsAgo, setSecondsAgo] = useState(0);

    useEffect(() => {
        if (!startedAt) {
            return () => {};
        }

        if (!running) {
            const newSecondsAgo = getSecondsAgo(queryResult, startedAt, endedAt);
            setSecondsAgo(newSecondsAgo);
            return () => {};
        }

        const newSecondsAgo = getSecondsAgo(queryResult, startedAt);
        setSecondsAgo(newSecondsAgo);

        const interval = setInterval(() => {
            const newSecondsAgo = getSecondsAgo(queryResult, startedAt);
            setSecondsAgo(newSecondsAgo);
        }, 1000);

        return () => clearInterval(interval);
    }, [running, startedAt, endedAt, queryResult]);

    if (!startedAt) {
        return null;
    }

    const SecondsAgo = () => (
        <>
            ({secondsAgo}{'\u00A0'}second{secondsAgo === 1 ? '' : 's'})
        </>
    );

    if (mode !== 'tooltip' || running) {
        return (
            <>
                {'\u00A0'}<SecondsAgo />
            </>
        );
    }

    const time = moment(queryResult?.exportEndTime || endedAt).format('h:mm A');
    const date = moment(queryResult?.exportEndTime || endedAt).format('MMM D, YYYY @ h:mm A');

    const ReturnedIn = () => (
        <>
            Returned in <SecondsAgo /><br/>
            {date}
        </>
    );

    return (
        <Tooltip content={<ReturnedIn />} interactive={false}>
            {'\u00A0'}{time}
        </Tooltip>
    );
};


export default Timer;
