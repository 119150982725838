import React from 'react';
import { Link as ClickLink, Typography } from 'ui-components';

/** @type { React.FC } */
const CompleteSignupFailure = () => (
    <>
        <Typography variant="h5" weight="medium" spacing="mb-0">
            Oops! Something went wrong
        </Typography>

        <Typography spacing="my-5">
            There&apos;s something different about
            how or where this invitation was sent.
        </Typography>

        <Typography spacing="mb-4 ml-4">
            &bull; You may have already signed up
        </Typography>

        <Typography spacing="mb-4 ml-4">
            &bull; You may have a newer invitation in your inbox
        </Typography>

        <Typography spacing="mb-5 ml-4">
            &bull; Your invitation may be expired
        </Typography>

        <Typography color="secondaryText">
            You can

            <ClickLink
                inline
                component="span"
                color="primary"
                spacing="mx-1"
                link="/signup"
            >
                Sign Up
            </ClickLink>

            again to create an account.
        </Typography>
    </>
);

export default CompleteSignupFailure;
