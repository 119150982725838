import React from 'react';
import { Loader } from 'ui-components';

import PasswordLayout from '../../shared/auth/password-layout';

import ChangePasswordForm from './change-password-form';
import ChangePasswordFailure from './change-password-failure';
import ChangePasswordSuccess from './change-password-success';
import useChangePasswordPage from './use-change-password-page';

/** @typedef { import('../../app/__types').PageProps } PageProps */

/** @type { React.FC<PageProps> } */
const ChangePasswordPage = ({ token }) => {
    const {
        password,
        repeatPassword,
        passwordIsValid,
        repeatPasswordIsValid,
        tokenIsValid,
        validateIsLoading,
        loading,
        disabled,
        success,
        error,
        onPasswordChanged,
        onRepeatPasswordChanged,
        onPasswordIsValidChanged,
        onChangeClicked,
        onEnterPressed,
    } = useChangePasswordPage({ token });

    if (validateIsLoading) {
        return (
            <PasswordLayout>
                <Loader big active />
            </PasswordLayout>
        );
    }

    if (!tokenIsValid) {
        return (
            <PasswordLayout>
                <ChangePasswordFailure />
            </PasswordLayout>
        );
    }

    if (success) {
        return (
            <PasswordLayout>
                <ChangePasswordSuccess />
            </PasswordLayout>
        );
    }

    const formProps = {
        password,
        repeatPassword,
        passwordIsValid,
        repeatPasswordIsValid,
        loading,
        disabled,
        error,
        onPasswordChanged,
        onRepeatPasswordChanged,
        onPasswordIsValidChanged,
        onChangeClicked,
        onEnterPressed,
    };

    return (
        <PasswordLayout title="Change Password">
            <ChangePasswordForm {...formProps} />
        </PasswordLayout>
    );
};

export default ChangePasswordPage;
