import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { Layout } from 'ui-components';

/** @type { React.FC<{}> } */
const MarkdownLink = ({ children, ...props }) => (
    <a {...props} target="_blank" rel="noreferrer noopener">
        {children}
    </a>
);

const MarkdownRoot = styled(Layout)`
    p:first-child {
        margin-top: 0px;
    }
    p:last-child {
        margin-bottom: 0px;
    }
    a {
        color: inherit;
        text-decoration: underline;
    }
`;

/**
 * @typedef { object } Props
 * @prop { string= } source
 */

/** @type { React.FC<Props> } */
const MarkdownRenderer = ({ source }) => (
    <MarkdownRoot>
        <ReactMarkdown components={{ a: MarkdownLink }}>
            {source || ''}
        </ReactMarkdown>
    </MarkdownRoot>
);

export default MarkdownRenderer;
