import React, { useCallback } from 'react';
import {
    Card,
    Layout,
    Typography,
    Thumbnail,
} from 'ui-components';

import { useSourcePageContext } from '../use-source-page/source-page-context';
import Banners from '../banners';

import cdnIcon from '../../../lib/cdn-icon';
import NestedPageWrapper from './nested-page-wrapper';

/** @typedef { import('models/sources/__types').SourceType } SourceType */

const Knowledge = () => {

    /** @type {SourceType} */
    // @ts-ignore
    const sourceType = useSourcePageContext().sourceType;

    const { title, links, id } = sourceType;

    const openLink = useCallback((e) => {
        e.preventDefault();
        window.open(e.currentTarget.href, '_blank');

    }, [id]);

    const {
        docs,
        faqs,
        dashboard,
        releaseNotes,
        sampleQueries,
    } = links || {};

    /* eslint-disable max-len */
    const items = [
        {
            link: dashboard,
            title: 'Reporting Analytics',
            description: 'This connector supports instant reporting dashboards powered by Trevor.io',
            image: 'knowledge-center-reporting-analytics@2x.png',
        },
        {
            link: sampleQueries,
            title: 'Sample Queries',
            description: `Useful SQL queries and view definitions for ${title}.`,
            image: 'knowledge-center-sample-queries@2x.png',
        },
        {
            link: faqs,
            title: 'Connectors FAQs',
            description: `Frequently asked questions about using ${title}.`,
            image: 'knowledge-center-faqs.svg',
        },
        {
            link: docs,
            title: 'Setup Guide',
            description: `View the Setup Guide and Data Dictionary for ${title}.`,
            image: 'knowledge-center-setup-guide.svg',
        },
        {
            link: releaseNotes,
            title: 'Release Notes',
            description: `Version history for the ${title} connectors.`,
            image: 'knowledge-center-release-notes.svg',
        },
    ];

    return (
        <NestedPageWrapper maxWidth>
            <Typography variant="h6">Knowledge Center</Typography>

            <Banners />

            <Card spacing="my-5">
                <Layout flex wrap spacing="pt-5">

                    {items.map(item => item.link && (
                        <Thumbnail
                            key={item.title}
                            width="33.3"
                            spacing="px-4 pb-5"
                            link={item.link}
                            onClick={openLink}
                            title={item.title}
                            description={item.description}
                            imageUrl={cdnIcon(item.image, 'ui')}
                        />
                    ))}
                </Layout>
            </Card>
        </NestedPageWrapper>
    );
};

export default Knowledge;
