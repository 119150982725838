import { get } from '../../lib/ajax';
import { addBreadcrumb } from '../../lib/error-reporter';

/** @typedef { import('app/__types').Database } Database */
/** @typedef { import('./__types').Schema } Schema */

/** @type { () => Promise<Schema[]> } */
export const fetchSchemas = async () => {
    try {
        const { data: schemas } = await get('/schemas');

        return schemas;

    } catch (error) {
        addBreadcrumb('Failed to fetch tables', { error });

        throw error;
    }
};

/** @type { (databaseType: Database['type']) => Schema['name'] } */
export const getDefaultSchema = (databaseType) => (
    databaseType === 'bigquery' ? 'panoply' : 'public'
);
