import {
    useCallback, useState, useMemo, useEffect,
} from 'react';
import moment from 'moment';

import {
    titleSelector,
    iconSelector,
    collectingSelector,
} from '../../../shared/source/selectors';

import usePageVisibility from '../../../shared/use-page-visibility';
import { deleteSource as deleteSourceReq, fetchSourceJob } from '../../../models/sources';

import { reportError } from '../../../lib/error-reporter';
import {
    trackCloneModalSubmit,
    trackOpenThreeDotMenu,
    trackThreeDotMenuSelection,
} from '../tracking';

/** @typedef { import('models/sources/__types').Source } Source */
/** @typedef { import('models/sources/__types').SourceType } SourceType */

const POLLING_INTERVAL_MS = 6000;

/**
 * @param { Source } source
 * @param { SourceType } sourceType
 * @param { (source: Source, newJob: Source['job']) => void } updateSourceJob
 * @param { (source: Source, newTitle: string) => Promise<void> } updateSourceTitle
 * @param { (numberOfDeleted?: number) => void } onSourceDeleted
 */
const useSourceListItem = (
    source,
    sourceType,
    updateSourceJob,
    updateSourceTitle,
    onSourceDeleted,
) => {
    const [actionsDropdownOpen, setActionsDropdownOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [deletingError, setDeletingError] = useState('');
    const [renameModalOpen, setRenameModalOpen] = useState(false);
    const [cloneModalOpen, setCloneModalOpen] = useState(false);
    const pageIsVisible = usePageVisibility();

    const title = titleSelector({ source, sourceType });
    const icon = iconSelector({ source });
    const collecting = collectingSelector({ source });

    const closeActionsDropdown = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        setActionsDropdownOpen(false);
    }, []);

    const openActionsDropdown = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        setActionsDropdownOpen(true);
        trackOpenThreeDotMenu(source.id);
    }, [source.id]);

    const openDeleteModal = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        setActionsDropdownOpen(false);
        setDeleteModalOpen(true);
        trackThreeDotMenuSelection('Delete');
    }, []);

    const closeDeleteModal = useCallback(() => {
        setActionsDropdownOpen(false);
        setDeleteModalOpen(false);
    }, []);

    const openRenameModal = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        setRenameModalOpen(true);
        setActionsDropdownOpen(false);
        trackThreeDotMenuSelection('Rename');
    }, []);

    const closeRenameModal = useCallback(() => {
        setRenameModalOpen(false);
        setActionsDropdownOpen(false);
    }, []);

    const updateTitle = useCallback(async (newTitle) => {
        updateSourceTitle(source, newTitle);
    }, [source, updateSourceTitle]);

    const deleteSource = useCallback(async () => {
        setDeleting(true);
        try {
            await deleteSourceReq(source.id);
            if (onSourceDeleted) {
                onSourceDeleted();
            }
            setDeleteModalOpen(false);
        } catch (err) {
            setDeletingError(`There was an error when deleting ${title}`);
            reportError(err, { sourceId: source.id });
        }
        setDeleting(false);
    }, [source, onSourceDeleted, title]);

    const refreshSourceJob = useCallback(async () => {
        if (collecting && pageIsVisible && source.job) {
            try {
                const updatedJob = await fetchSourceJob(source.job.id);
                updateSourceJob(source, updatedJob);
            } catch (error) {
                // Server responds with 500 status when job errored or canceled
                if (error.data && error.data.message) {
                    updateSourceJob(source, {
                        ...source.job,
                        status: 'error',
                        error: error.data.message,
                    });
                    return;
                }
                reportError(error);
            }
        }
    }, [source, collecting, pageIsVisible, updateSourceJob]);

    const toggleCloneModal = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        setCloneModalOpen((currentValue) => !currentValue);
        setActionsDropdownOpen(false);
        const newCloneModalOpen = !cloneModalOpen;

        if (newCloneModalOpen) {
            trackThreeDotMenuSelection('Clone');
        } else {
            trackCloneModalSubmit('false');
        }
    }, [cloneModalOpen]);

    const schedule = useMemo(() => {
        if (source.schedule_disabled || !source.schedule) {
            return null;
        }
        /** @type {{[key: string]: string}} */
        const days = {
            '*': 'Every Day',
            '0': 'Sunday',
            '1': 'Monday',
            '2': 'Tuesday',
            '3': 'Wednesday',
            '4': 'Thursday',
            '5': 'Friday',
            '6': 'Saturday',
        };
        return days[source.schedule];
    }, [source.schedule, source.schedule_disabled]);

    const lastCollect = (source && source.job && source.job.starttime)
        ? moment(source.job.starttime).fromNow()
        : null;

    useEffect(() => {
        // This timeout is run consecutively
        // No need to manually call refreshSourceJob again
        // Because refreshSourceJob is in the useEffect deps
        const timeout = setTimeout(() => {
            refreshSourceJob();
        }, POLLING_INTERVAL_MS);
        return () => clearTimeout(timeout);
    }, [refreshSourceJob]);

    return {
        title,
        icon,
        lastCollect,
        collecting,
        schedule,
        actionsDropdownOpen,
        deleteModalOpen,
        renameModalOpen,
        deleting,
        deletingError,
        cloneModalOpen,
        deleteSource,
        updateTitle,
        openActionsDropdown,
        closeActionsDropdown,
        openDeleteModal,
        closeDeleteModal,
        openRenameModal,
        closeRenameModal,
        toggleCloneModal,
    };
};

export default useSourceListItem;
