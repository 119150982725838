import React from 'react';

import {
    Layout,
} from 'ui-components';

import cdnIcon from '../../lib/cdn-icon';

/**
 * @typedef { object } Props
 */

/** @type { React.FC<Props> } */
const SignupBadges = () => {

    return (
        <Layout spacing="mt-5 mb-7" width="100" flex justifyContent="space-around">
            <img src={cdnIcon('badge-g2.svg', 'ui')} />
            <img src={cdnIcon('badge-google-cloud-partner.svg', 'ui')} />
            <img src={cdnIcon('badge-aws.svg', 'ui')} />
            <img src={cdnIcon('badge-soc.svg', 'ui')} />
        </Layout>
    );
};


export default SignupBadges;
