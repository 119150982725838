import { useLocation, useParams } from 'react-router-dom';

/** @typedef { import('./__types').WorkbenchParams } WorkbenchParams */

function useModeSelector() {
    /** @type { WorkbenchParams } */
    const { id } = useParams();

    const location = useLocation();

    const isWorkbenchPage = location.pathname.startsWith('/workbench');

    const isModeSelectorAllowed = isWorkbenchPage;
    const isQueryBuilderAllowed = isModeSelectorAllowed && !id;

    return {
        isModeSelectorAllowed,
        isQueryBuilderAllowed,
    };
}

export default useModeSelector;
