import React from 'react';
import styled from 'styled-components';

import {
    Layout,
    colors,
} from 'ui-components';

import { useSession } from '../context-providers';
import WorkbenchPageSizeSelector from './workbench-page-size-selector';
import WorkbenchStatus from './workbench-status';
import WorkbenchPagination from './workbench-pagination';

/** @typedef { import('models/queries/__types').QueryResult } QueryResult */

const pageSizeBarHeight = '45px';

/**
 * @typedef { object } Props
 * @prop { number } page
 * @prop { number } pageSize
 * @prop { (newPage: number) => void } updatePageSize
 * @prop { QueryResult } [queryResultPage]
 * @prop { string } [queryError]
 * @prop { Date } [queryStartedAt]
 * @prop { Date } [queryEndedAt]
 * @prop { boolean } queryIsRunning
 * @prop { boolean } pageLoading
 * @prop { boolean } isSelectQuery
 * @prop { (newPage: number) => void } updatePage
 * @prop { QueryResult } [activeResult]
 */

/** @type { React.FC<Props> } */
const WorkbenchPageSizeBar = ({
    page,
    pageSize,
    updatePageSize,
    queryResultPage,
    queryError,
    queryStartedAt,
    queryEndedAt,
    queryIsRunning,
    pageLoading,
    isSelectQuery,
    updatePage,
    activeResult,
}) => {
    const [session] = useSession();
    const { database } = session;

    const showPagination = database.type !== 'redshift'
        && !!queryResultPage?.data?.rows?.length
        && isSelectQuery
        && !queryError;

    return (
        <PageSizeBar>
            <Layout width={showPagination ? '60' : '100'} flex alignItems="center" spacing="pl-4">
                <WorkbenchStatus
                    queryError={queryError}
                    queryStartedAt={queryStartedAt}
                    queryEndedAt={queryEndedAt}
                    queryResultPage={queryResultPage}
                    queryIsRunning={queryIsRunning}
                    pageLoading={pageLoading}
                    activeResult={activeResult}
                />

                <WorkbenchPageSizeSelector
                    pageSize={pageSize}
                    updatePageSize={updatePageSize}
                    spacing="ml-4"
                />
            </Layout>

            {showPagination && (
                <Layout
                    width="40"
                    flex
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={(document.querySelectorAll('[class^="intercom"]').length > 0
                        ? 'pr-8+2'
                        : 'pr-4'
                    )}
                >
                    <WorkbenchPagination
                        page={page}
                        pageSize={pageSize}
                        pageLoading={pageLoading}
                        queryResultPage={queryResultPage}
                        activeResult={activeResult}
                        updatePage={updatePage}
                    />
                </Layout>
            )}
        </PageSizeBar>
    );
};

const PageSizeBar = styled('div')`
    position: absolute;
    bottom: 0px;
    z-index: 3;
    height: ${pageSizeBarHeight};
    background-color: ${colors.background};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid ${colors.secondaryInterface};
`;

export { pageSizeBarHeight };

export default WorkbenchPageSizeBar;
