import React, { useState, useEffect, useCallback } from 'react';
import { reportError } from '../lib/error-reporter';

//
// Google optimize does not work locally by default
// Add a hostname alias `localhost.domain` to localhost/127.0.0.1 to enable experiments locally
//

/** @type { (experimentId: string) => number | null } */
function useGoogleOptimize(experimentID) {
    const [variant, setVariant] = useState(
        /** @type { number | null} */ (null)
    );

    /** @type { (value?: number) => void } */
    const updateVariant = useCallback((value) => {
        setVariant(value || 0);
    }, []);

    useEffect(() => {
        // @ts-ignore
        const { dataLayer, google_optimize } = window;

        const delayedInit = () => {
            dataLayer.push({ event: 'optimize.activate' });
            const value = google_optimize?.get(experimentID);
            updateVariant(value);
        };

        // Delays the optimize init until GTM things loaded
        if (dataLayer) {
            const hideEnd = dataLayer?.hide?.end;

            if (hideEnd) {
                dataLayer.hide.end = () => {
                    delayedInit();
                    hideEnd();
                };
            } else {
                delayedInit();
            }

            // Will call the callback with the selected variant once optimize loads
            dataLayer.push('event', 'optimize.callback', {
                name: experimentID,
                callback: updateVariant,
            });
        }

        // Cleanup dangling event handlers
        return () => {
            dataLayer.push('event', 'optimize.callback', {
                name: experimentID,
                callback: updateVariant,
                remove: true,
            });
        };
    }, [updateVariant]);

    return variant;
}

/**
 * @typedef { object } VariantProps
 * @prop { number } version
 * @prop { string= } name
 * @prop { React.ReactNode } children
 */

/** @type { React.FC<VariantProps> } */
const Variant = ({ children }) => <>{children}</>;


/**
 * @typedef { object } ExperimentProps
 * @prop { string } id
 * @prop { string= } name
 * @prop { React.ReactNode } children
 */

/** @type { React.FC<ExperimentProps> } */
const Experiment = ({
    id, name, children,
}) => {

    const variantIndex = useGoogleOptimize(id);

    /** @type {(elem: any) => boolean } */
    const isVariant = (elem) => elem.type === Variant;

    const variants = React.Children.toArray(children).filter(isVariant);

    const selectedVariant = variants.find((elem) => {
        // @ts-ignore I cant find a way to type this instantated Variant
        return Number(elem.props.version) === Number(variantIndex);
    });

    // Fallback to first defined variant
    const fallback = variants[0] || null;

    useEffect(() => {

        if (!selectedVariant && variantIndex) {
            reportError(new Error('Selected test variant not found in DOM'), {
                testId: id,
                chosenVariant: variantIndex,
                name,
            });
        }

    }, [selectedVariant, variantIndex, id, name]);

    if (variantIndex === null) {
        return <>null</>;
    }

    return (
        <>{selectedVariant || fallback}</>
    );

};

export { Variant };
export default Experiment;
