/* eslint-disable complexity */
import React from 'react';
import styled from 'styled-components';

import {
    Dialog,
    Typography,
    Button,
    Layout,
    Card,
    Switch,
    Callout,
} from 'ui-components';

import {
    getParamValue,
    getFlattenedParams,
    isGroupParam,
    isSyncableParam,
    copyParamValues,
} from '../../../models/sources';

import useSyncSourceDialog, { formatValue } from './use-sync-source-dialog';

/** @typedef { import('models/sources/__types').Source } Source */
/** @typedef { import('models/sources/__types').SourceType } SourceType */
/** @typedef { import('models/sources/__types').Param } Param */


/**
 * @typedef { object } Props
 * @prop { boolean } open
 * @prop { Source } source
 * @prop { SourceType } sourceType
 * @prop { () => void } onClose
 */


/** @type { React.FC<Props> } */
const SyncSourceDialog = React.memo(({
    open,
    source,
    sourceType,
    onClose,
}) => {

    const {
        masterSource,
        error,
        loading,
        currentStep,
        selectedSources,
        syncableSources,
        selectableParams,
        selectedParams,
        selectedCcParams,
        collectConfigParams,
        toggleSelectedCcParam,
        isCcParamSelected,
        toggleSelectedSources,
        isSourceSelected,
        toggleSelectedParam,
        nextStep,
        isParamDepsMet,
        isSourceDisabled,
        syncSources,
    } = useSyncSourceDialog(open, source, sourceType);

    const finished = currentStep === 3;

    const totalSelectedParamsLength = selectedParams.length + selectedCcParams.length;

    return (
        <Dialog
            isOpen={open}
            onClose={onClose}
            actions={
                <>
                    {!finished && !error && (
                        <Button
                            onClick={onClose}
                            type="plain"
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                    )}
                    {currentStep === 0 && (
                        <Button
                            loading={loading}
                            onClick={() => nextStep()}
                            disabled={selectedSources.length === 0}
                            color="primary"
                            spacing="mr-0"
                        >
                            Next
                        </Button>
                    )}
                    {currentStep === 1 && (
                        <Button
                            loading={loading}
                            onClick={() => nextStep()}
                            disabled={totalSelectedParamsLength === 0}
                            color="primary"
                            spacing="mr-0"
                        >
                            Next
                        </Button>
                    )}
                    {currentStep === 2 && (
                        <Button
                            loading={loading}
                            onClick={syncSources}
                            color="primary"
                            spacing="mr-0"
                        >
                            Copy
                        </Button>
                    )}
                    {currentStep === 3 && (
                        <Button
                            loading={loading}
                            onClick={onClose}
                            color="primary"
                            spacing="mr-0"
                        >
                            Finish
                        </Button>
                    )}
                </>
            }
        >

            {currentStep === 0 && !error && (
                <>
                    <Typography
                        spacing="mt-1"
                        color="text"
                        variant="h6"
                        align="center"
                    >
                        Copy Settings
                    </Typography>

                    <Typography
                        spacing="mt-1"
                        color="secondaryText"
                        variant="subtitle1"
                        align="center"
                    >
                        Choose connectors to copy fields from &quot;{masterSource.title}&quot; to
                    </Typography>


                    <Layout spacing="mt-5">

                        {!syncableSources.length && (
                            <Callout flat color="warning" spacing="my-2 mx-2">
                                You must have other connectors of the
                                same type to sync settings.
                            </Callout>
                        )}

                        {syncableSources.map((source) => {
                            const label = isSourceDisabled(source)
                                ? source.title + ' (Collecting)'
                                : source.title;

                            return (
                                <CardWithDisabled
                                    flat
                                    spacing="my-2"
                                    contentSpacing="m-0 p-2"
                                    disabled={isSourceDisabled(source)}
                                    key={source.id}
                                >
                                    <Layout flex alignItems="center">
                                        <Switch
                                            type="checkbox"
                                            checked={isSourceSelected(source)}
                                            disabled={isSourceDisabled(source)}
                                            label={label}
                                            onChange={() => toggleSelectedSources(source)}
                                        />
                                    </Layout>
                                </CardWithDisabled>
                            );
                        })}
                    </Layout>
                </>
            )}

            {currentStep === 1 && !error && (
                <>
                    <Typography
                        spacing="mt-1"
                        color="text"
                        variant="h6"
                        align="center"
                    >
                        Choose Fields
                    </Typography>

                    <Typography
                        spacing="mt-1"
                        color="secondaryText"
                        variant="subtitle1"
                        align="center"
                    >
                        Choose which fields to copy from &quot;{masterSource.title}&quot;
                    </Typography>

                    <Layout spacing="mt-5">
                        {selectableParams.map((param) => {
                            const paramSelected = selectedParams.includes(param);

                            const depsMet = isParamDepsMet(param);

                            const syncableParams = getFlattenedParams([param])
                                .filter(([param]) => isSyncableParam(param));

                            const value = isGroupParam(param)
                                ? copyParamValues(
                                    masterSource,
                                    /** @type { Source } */ ({}),
                                    syncableParams,
                                )
                                : getParamValue(masterSource, [param, []]);

                            const formattedValue = formatValue(value, param);

                            const cardColor = !depsMet && paramSelected
                                ? 'warning'
                                : 'secondaryInterface';

                            const depsWarningWeight = paramSelected ? 'medium' : 'light';

                            return (
                                <CardWithDisabled
                                    flat
                                    spacing="my-2"
                                    contentSpacing="m-0 p-2"
                                    color={cardColor}
                                    key={param.name}
                                >
                                    <Switch
                                        type="checkbox"
                                        checked={paramSelected}
                                        onChange={() => toggleSelectedParam(param)}
                                        label={
                                            <Layout spacing="ml-2">
                                                <Typography>
                                                    {param.title}
                                                    {!depsMet && (
                                                        <Typography
                                                            inline
                                                            weight={depsWarningWeight}
                                                        >
                                                            {' - '}
                                                            Field dependencies not selected.
                                                        </Typography>
                                                    )}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    color="secondaryText"
                                                    spacing="m-0"
                                                >
                                                    &bull;&nbsp;
                                                    {formattedValue}
                                                </Typography>
                                            </Layout>
                                        }
                                    />
                                </CardWithDisabled>
                            );
                        })}
                        {collectConfigParams.map((param) => {
                            if (param.hidden) {
                                return null;
                            }
                            const paramSelected = isCcParamSelected(param);
                            const formattedValue = formatValue(param.value);
                            return (
                                <CardWithDisabled
                                    flat
                                    spacing="my-2"
                                    contentSpacing="m-0 p-2"
                                    color="secondaryInterface"
                                    key={param.title}
                                >
                                    <Switch
                                        type="checkbox"
                                        checked={paramSelected}
                                        onChange={() => toggleSelectedCcParam(param)}
                                        label={
                                            <Layout spacing="ml-2">
                                                <Typography>
                                                    {param.title}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    color="secondaryText"
                                                    spacing="m-0"
                                                >
                                                    &bull;&nbsp;
                                                    {formattedValue}
                                                </Typography>
                                            </Layout>
                                        }
                                    />
                                </CardWithDisabled>
                            );
                        })}
                    </Layout>
                </>
            )}
            {currentStep === 2 && !error && (
                <>
                    <Typography
                        spacing="mt-1"
                        color="text"
                        variant="h6"
                        align="center"
                    >
                        Are you sure?
                    </Typography>

                    <Typography
                        spacing="mt-1"
                        color="secondaryText"
                        variant="subtitle1"
                        align="center"
                    >
                        This operation will copy{' '}
                        {totalSelectedParamsLength}{' '}
                        {totalSelectedParamsLength > 1 ? 'fields' : 'field'}{' '}
                        from &quot;{masterSource.title}&quot; to {selectedSources.length} data{' '}
                        {selectedSources.length > 1 ? 'sources' : 'source'}
                    </Typography>

                    <Callout
                        spacing="mb-5 mt-4"
                        align="center"
                        color="warning"
                        variant="subtitle1"
                        weight="medium"
                    >
                        <Typography color="text">
                            Copying partial or incorrect fields might
                            cause the connectors to stop working as expected
                        </Typography>
                    </Callout>

                    <Typography
                        spacing="mt-4"
                        color="text"
                    >
                        Selected Connectors:
                    </Typography>
                    {selectedSources.map((source) => {
                        return (
                            <Card flat spacing="my-2" contentSpacing="m-0 p-2" key={source.id}>
                                <Typography
                                    key={source.id}
                                    color="text"
                                    weight="medium"
                                >
                                    {source.title}
                                </Typography>
                            </Card>
                        );
                    })}

                    <Typography spacing="mt-4" color="text">
                        Selected Fields:
                    </Typography>
                    {selectedParams.map((selectedParam) => {
                        return (
                            <Card
                                flat
                                spacing="my-2"
                                contentSpacing="m-0 p-2"
                                key={selectedParam.name}
                            >
                                <Typography
                                    color="text"
                                    weight="medium"
                                >
                                    {selectedParam.title}
                                </Typography>
                            </Card>
                        );
                    })}
                    {selectedCcParams.map((selectedParam) => {
                        return (
                            <Card
                                flat
                                spacing="my-2"
                                contentSpacing="m-0 p-2"
                                key={selectedParam.name}
                            >
                                <Typography
                                    color="text"
                                    weight="medium"
                                >
                                    {selectedParam.title}
                                </Typography>
                            </Card>
                        );
                    })}

                </>
            )}

            {(currentStep === 3 && !error) && (
                <>
                    <Typography
                        spacing="mt-1"
                        color="text"
                        variant="h6"
                        align="center"
                    >
                        Fields copied!
                    </Typography>

                    <Typography
                        spacing="mt-1"
                        color="secondaryText"
                        variant="subtitle1"
                        align="center"
                    >
                        Successfully copied{' '}
                        {totalSelectedParamsLength}{' '}
                        {totalSelectedParamsLength > 1 ? 'fields' : 'field'}{' '}
                        from &quot;{masterSource.title}&quot;{' '}
                        to {selectedSources.length} data{' '}
                        {selectedSources.length > 1 ? 'connectors' : 'connector'}
                    </Typography>
                </>
            )}

            {error && (
                <>
                    <Typography
                        spacing="mt-1"
                        color="text"
                        variant="h6"
                        align="center"
                    >
                        Oops, something went wrong!
                    </Typography>

                    <Typography
                        spacing="mt-1"
                        color="secondaryText"
                        variant="subtitle1"
                        align="center"
                    >
                        Some of the selected connectors have not been synced.
                    </Typography>

                    <Callout color="error" spacing="mt-5">
                        {error}
                    </Callout>
                </>
            )}

        </Dialog>
    );
});

const CardWithDisabled = styled(Card)`
    ${({ disabled }) => disabled && `
        opacity: 0.5;
    `}
`;

export default SyncSourceDialog;
