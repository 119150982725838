import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { reverse, mean, max } from 'lodash';

import {
    Layout,
    Loader,
    Icon,
    Select,
    SelectItem,
    Switch,
    Drawer,
    LineChart,
    colors,
} from 'ui-components';

import {
    BIBYTE,
    KIBIBYTE,
    MEBIBYTE,
    GIBIBYTE,
    TEBIBYTE,
    BIBYTE_UNITS,
} from '../../../shared/constants';

import { useSession } from '../../../shared/context-providers';
import useUsageQuotas from './use-usage-quotas';

/** @typedef { import('app/__types').Database } Database */
/** @typedef { import('../__types').UsageParams } UsageParams */
/** @typedef { import('../__types').ReportKey } ReportKey */
/** @typedef { import('../__types').ChangeEvent } ChangeEvent */

/** @type { (num: number) => [number, string] } */
const getUnitProps = (num) => {
    const [B, KiB, MiB, GiB, TiB] = BIBYTE_UNITS;

    if (num <= KIBIBYTE) {
        return [BIBYTE, B];
    }
    if (num <= MEBIBYTE) {
        return [KIBIBYTE, KiB];
    }
    if (num <= GIBIBYTE) {
        return [MEBIBYTE, MiB];
    }
    if (num <= TEBIBYTE) {
        return [GIBIBYTE, GiB];
    }

    return [TEBIBYTE, TiB];
};

/**
 * @typedef { object } ReportProps
 * @prop { number } id
 * @prop { ReportKey } key
 * @prop { string } label
 * @prop { string } icon
 * @prop { string } legend
 * @prop { boolean } accrued
 * @prop { (unit: string) => string } getUnit
 * @prop { (num: number, unit: number) => number } format
 * @prop { (dbType: Database['type']) => boolean } isHidden
 */

/** @type { Record<number, ReportProps> } */
export const reports = {
    1: {
        id: 1,
        key: 'rowsExtracted',
        label: 'Rows Extracted',
        legend: 'Rows Extracted',
        icon: 'sync',
        accrued: true,
        format: num => num,
        getUnit: () => 'short',
        isHidden: () => false,
    },
    2: {
        id: 2,
        key: 'storage',
        label: 'Storage Used',
        legend: 'Storage',
        icon: 'database',
        accrued: false,
        format: (num, unit) => num / unit,
        getUnit: unit => `#,### ${unit}`, // eslint-disable-line no-irregular-whitespace
        isHidden: dbType => dbType !== 'bigquery',
    },
    3: {
        id: 3,
        key: 'queryBytes',
        label: 'Query Bytes Used',
        legend: 'Query Bytes',
        icon: 'tasks-alt',
        accrued: true,
        format: (num, unit) => num / unit,
        getUnit: unit => `#,### ${unit}`, // eslint-disable-line no-irregular-whitespace
        isHidden: dbType => dbType !== 'bigquery',
    },
};

/**
 * @typedef { object } OtherPeriodProps
 * @prop { number } id
 * @prop { string } key
 * @prop { string } legend
 * @prop { (reportKey: ReportKey) => string } getLabel
 * @prop { (reportKey: ReportKey) => boolean } isHidden
 */

/** @typedef { UsageParams & OtherPeriodProps } PeriodProps */

/** @type { Record<number, PeriodProps> } */
const periods = {
    1: {
        id: 1,
        key: 'thisMonth',
        legend: 'Daily',
        getLabel: () => 'Per day, this month',
        isHidden: () => false,
        since: moment.utc().startOf('month').toISOString(),
        interval: 'day',
    },
    2: {
        id: 2,
        key: 'lastMonth',
        legend: 'Daily',
        getLabel: () => 'Per day, last month',
        isHidden: () => false,
        since: moment.utc().subtract(1, 'months').startOf('month').toISOString(),
        until: moment.utc().subtract(1, 'months').endOf('month').toISOString(),
        interval: 'day',
    },
    3: {
        id: 3,
        key: '12Months',
        legend: 'Monthly',
        getLabel: () => 'Per month, last 12 months',
        isHidden: reportKey => reportKey === 'storage',
        since: moment.utc().subtract(12, 'months').startOf('month').toISOString(),
        interval: 'month',
    },
};

/** @type { (report: number, dbType: Database['type']) => ReportProps } */
const getReportProps = (report, dbType) => {
    const reportProps = reports[report] || reports[1];

    if (reportProps.isHidden(dbType)) {
        const props = Object.values(reports).find(r => !r.isHidden(dbType));

        if (props) {
            return props;
        }
    }

    return reportProps;
};

/** @type { (period: number, reportKey: ReportKey) => PeriodProps } */
const getPeriodProps = (period, reportKey) => {
    const periodProps = periods[period] || periods[1];

    if (periodProps.isHidden(reportKey)) {
        const props = Object.values(periods).find(r => !r.isHidden(reportKey));

        if (props) {
            return props;
        }
    }

    return periodProps;
};

/**
 * @typedef {object} ReportsDrawerProps
 * @prop { number } month
 * @prop { number } report
 * @prop { () => void } onReportChanged
 * @prop { () => void } onReportCleared
 */

/** @type { React.FC<ReportsDrawerProps> } */
const ReportsDrawer = ({
    month,
    report,
    onReportChanged,
    onReportCleared,
}) => {
    const [period, setPeriod] = useState(0);
    const [detail, setDetail] = useState(false);
    const [isLocalDateTime, setIsLocalDateTime] = useState(false);

    const showChartRef = useRef(false);

    const onPeriodChanged = useCallback((/** @type { ChangeEvent } */ e) => {
        setPeriod(Number(e.target.value));
    }, []);

    const onDetailToggled = useCallback(() => {
        setDetail(a => !a);
    }, []);

    const onIsLocalDateTimeToggled = useCallback(() => {
        setIsLocalDateTime(a => !a);
    }, []);

    useEffect(() => {
        setPeriod(month < 2 ? month + 1 : 3);
    }, [month]);

    useEffect(() => {
        if (!period) {
            return;
        }
        showChartRef.current = true;
    }, [period]);

    return (
        <Drawer
            open={report > 0}
            size="xl"
            direction="bottom"
            contentSpacing="p-0"
            onClose={onReportCleared}
            header={(
                <ReportsDrawerHeader
                    period={period}
                    report={report}
                    detail={detail}
                    isLocalDateTime={isLocalDateTime}
                    onPeriodChanged={onPeriodChanged}
                    onDetailToggled={onDetailToggled}
                    onReportChanged={onReportChanged}
                    onIsLocalDateTimeToggled={onIsLocalDateTimeToggled}
                />
            )}
        >
            <Content>
                {showChartRef.current && (
                    <ReportsChart
                        period={period}
                        report={report}
                        detail={detail}
                        isLocalDateTime={isLocalDateTime}
                    />
                )}
            </Content>
        </Drawer>
    );
};

const Content = styled.div`
    height: calc(70vh - 62px);
    overflow:  hidden;
`;

/**
 * @typedef {object} ReportsChartProps
 * @prop { number } period
 * @prop { number } report
 * @prop { boolean } detail
 * @prop { boolean } isLocalDateTime
 */

/** @type { React.FC<ReportsChartProps> } */
const ReportsChart = ({ period, report, detail, isLocalDateTime }) => {
    const [session] = useSession();
    const { database } = session;
    const dbType = database.type;

    const reportProps = useMemo(() => getReportProps(report, dbType), [report, dbType]);
    const { key: reportKey } = reportProps;

    const periodProps = useMemo(() => getPeriodProps(period, reportKey), [period, reportKey]);

    const quotas = useUsageQuotas(periodProps);

    const [rows, unit] = useMemo(() => {
        /** @type { Array<string[] | [Date, number | null, number, ...number[]]> } */
        const rows = [
            [
                'Date',
                `Actual ${reportProps.legend}`,
                `${periodProps.legend} Average`,
                ...(detail ? [
                    ...(reportProps.accrued ? ['Accrued'] : []),
                ] : []),
            ],
        ];

        const usagePoints = reverse([...(quotas?.[reportProps.key]?.used || [])]);
        const quantities = usagePoints.map(u => Number(u.quantity));

        const [multiplier, unit] = getUnitProps(max(quantities) || 0);
        const average = reportProps.format(mean(quantities) || 0, multiplier);

        const now = moment.utc();
        let accrued = 0;

        usagePoints.map(usagePoint => {
            const date = moment.utc(usagePoint.startTime);
            const num = Number(usagePoint.quantity);

            const quantity = reportProps.format(num, multiplier);

            if (detail) {
                if (reportProps.accrued) {
                    accrued += quantity;
                }
            }

            rows.push([
                isLocalDateTime ? date.toDate() : new Date(date.toISOString().replace('Z', '')),
                now.isSameOrAfter(date) ? quantity : null,
                average,
                ...(detail ? [
                    ...(reportProps.accrued ? [accrued] : []),
                ] : []),
            ]);
        });

        return [rows, unit];
    }, [report, quotas, detail, isLocalDateTime]);

    if (quotas?.loading) {
        return <Loader active message="Loading data" />;
    }

    return (
        <LineChart
            data={rows}
            vAxisFormat={reportProps.getUnit(unit)}
            width="100%"
            height="100%"
            curveType="none"
            series={{
                0: { color: colors.primary.main },
                1: { color: colors.secondaryText, lineDashStyle: [4, 4] },
                2: { color: colors.secondary.main },
            }}
        />
    );
};

/**
 * @typedef {object} ReportsDrawerHeaderProps
 * @prop { number } period
 * @prop { number } report
 * @prop { boolean } detail
 * @prop { boolean } isLocalDateTime
 * @prop { () => void } onPeriodChanged
 * @prop { () => void } onReportChanged
 * @prop { () => void } onDetailToggled
 * @prop { () => void } onIsLocalDateTimeToggled
 */

/** @type { React.FC<ReportsDrawerHeaderProps> } */
const ReportsDrawerHeader = ({
    period,
    report,
    detail,
    isLocalDateTime,
    onPeriodChanged,
    onReportChanged,
    onDetailToggled,
    onIsLocalDateTimeToggled,
}) => {
    const [session] = useSession();
    const { database } = session;
    const dbType = database.type;

    const reportProps = useMemo(() => getReportProps(report, dbType), [report, dbType]);
    const { key: reportKey, accrued } = reportProps;

    const periodProps = useMemo(() => getPeriodProps(period, reportKey), [period, reportKey]);
    const { id: periodId } = periodProps;

    /**
     * @todo once we add hourly based reports, their since and until
     * date times should be also timezone aware to fetch correct data.
     */
    const showLocalDateTimeToggle = periodProps.interval === 'hour';

    return (
        <Layout flex alignItems="center" justifyContent="space-between">
            <Layout flex alignItems="center">
                <ReportsSelect
                    report={report}
                    onReportChanged={onReportChanged}
                    plain
                />

                <Select
                    value={String(periodId)}
                    onChange={onPeriodChanged}
                    spacing="p-0 mx-3"
                    noFloatLabel
                >
                    <SelectItem value="0" disabled>
                        <Placeholder>Periods</Placeholder>
                    </SelectItem>

                    {Object.values(periods).map(
                        props => !props.isHidden(reportKey) && (
                            <SelectItem key={props.key} value={String(props.id)}>
                                {props.getLabel(reportKey)}
                            </SelectItem>
                        ),
                    )}
                </Select>

                <Switch
                    type="switch"
                    checked={detail}
                    disabled={!accrued}
                    label={<Label>Additional details</Label>}
                    onChange={onDetailToggled}
                    spacing="mx-3"
                    reverse
                />
            </Layout>

            {showLocalDateTimeToggle && (
                <Switch
                    type="switch"
                    checked={isLocalDateTime}
                    label={(
                        <Label>
                            <Label color={isLocalDateTime ? 'secondaryText' : 'inherit'}>
                                UTC
                            </Label>
                            {' '}
                            <Label color="interface">/</Label>
                            {' '}
                            <Label color={!isLocalDateTime ? 'secondaryText' : 'inherit'}>
                                GMT{moment().format('Z')}
                            </Label>
                        </Label>
                    )}
                    onChange={onIsLocalDateTimeToggled}
                    reverse
                />
            )}

            {!showLocalDateTimeToggle && (
                <Layout flex alignItems="center" spacing="pr-2">
                    <Label color="secondaryText">
                        {isLocalDateTime ? `GMT${moment().format('Z')}` : 'UTC'}
                    </Label>
                </Layout>
            )}
        </Layout>
    );
};

/**
 * @typedef { object } ReportsSelectProps
 * @prop { number } report
 * @prop { boolean } [disabled]
 * @prop { boolean } [plain]
 * @prop { () => void } onReportChanged
 */

/** @type { React.FC<ReportsSelectProps> } */
export const ReportsSelect = ({ report, disabled, plain, onReportChanged }) => {
    const [session] = useSession();
    const { database } = session;
    const dbType = database.type;

    const reportProps = useMemo(() => getReportProps(report, dbType), [report, dbType]);
    const { id: reportId } = reportProps;

    return (
        <Select
            value={String(report ? reportId : 0)}
            disabled={disabled}
            plain={plain}
            onChange={onReportChanged}
            spacing="p-0"
            noFloatLabel
        >
            <SelectItem value="0" disabled>
                <Placeholder>Reports</Placeholder>
            </SelectItem>

            {Object.values(reports).map(
                props => !props.isHidden(dbType) && (
                    <SelectItem key={props.key} value={String(props.id)}>
                        <Icon
                            icon={props.icon}
                            spacing="mr-3"
                            color={!plain ? 'secondaryText' : null}
                        />
                        {props.label}
                    </SelectItem>
                ),
            )}
        </Select>
    );
};

const Label = styled.span`
    user-select: none;

    ${({ color }) => color && `
        color: ${colors[color]};
    `}
`;

// for default placeholder color
const Placeholder = styled.span`
    color: ${colors.interface};
`;

export default ReportsDrawer;
