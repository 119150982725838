import React, { useState } from 'react';
import {
    Button,
    Callout,
    Input,
} from 'ui-components';
import gotoUrl from '../../lib/goto-url';
import { useSession } from '../../shared/context-providers';
import { post } from '../../lib/ajax';
import styled from 'styled-components';

const onlyContainsNumbers = (str) => /^\d+$/.test(str);

/** @type { React.FC<void> } */
const LoginMfaForm = () => {
    const [, sessionActions] = useSession();
    const { refreshSession } = sessionActions;
    const [error, setError] = useState(null);
    const [code, setCode] = useState('');

    const changeCodeState = (e) => {
        const newCode = e.target.value;
        setError(null);
        setCode(newCode);
        if (newCode.length > 0 && !onlyContainsNumbers(newCode)) {
            setError('Code must contain only numbers');
        }
    };

    const isCodeValid = code.length === 6 && onlyContainsNumbers(code);

    const validateCode = async () => {
        try {
            await post(
                '/users/login/mfa',
                {
                    body: {
                        type: 'totp',
                        code,
                    },
                },
            );
            await refreshSession();
        } catch (err) {
            setError(err.data);
        }
    };

    const goBack = () => {
        gotoUrl('/users/logout');
    };

    return (
        <>
            {error && (
                <Callout color="error" spacing="mb-5">
                    {error.message}
                </Callout>
            )}

            <NumberInputNoArrows
                onChange={changeCodeState}
                width="100"
                spacing="p-0 mb-5"
                label="6 Digits MFA Code"
                type ="number"
                noFloatLabel={true}
                autoFocus={true}
            />

            <Button
                onClick={validateCode}
                disabled={!isCodeValid}
                color="primary"
                width="100"
                spacing="m-0 mb-5"
            >
                Log In
            </Button>

            <Button
                width="100"
                spacing="m-0 mb-5"
                onClick={goBack}
            >
                Go Back
            </Button>
        </>
    );
};

const NumberInputNoArrows = styled(Input)`
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
           -webkit-appearance: none;
            margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
`;
export default LoginMfaForm;
