
import { extend } from 'lodash';
import development from './development';
import staging from './staging';
import production from './production';
import release from './release';

const { href } = window.location;

/**
 * WARNING: PLEASE DO NOT CHANGE THIS LINE OF CODE
 * ===============================================
 *
 * This is a hack to get the NODE_ENV variable from the webpack config
 * and pass it to the client side. This is needed because the NODE_ENV
 * variable is not available in the client side.
 *
 * We consider not relying on the NODE_ENV variable in the client side
 * in the future. For now, we need it to be able to use the same
 * webpack config for both the client and the server.
 *
 * Webpack will replace the NODE_ENV variable with the actual value
 * during the build process. This is why we need to use the
 * `process.env.NODE_ENV` syntax instead of `NODE_ENV`.
 *
 * +----------------------------------------+---------------+
 * + const NODE_ENV = process.env.NODE_ENV; | works fine    |
 * +----------------------------------------+---------------+
 * | const { NODE_ENV } = process.env;      | does not work |
 * +----------------------------------------+---------------+
 */
const NODE_ENV = process.env.NODE_ENV || development.env; // eslint-disable-line no-undef

let config = development;

if (production.hostname.some(x => href.match(x))) {
    config = extend(development, production);
} else if (staging.hostname.some(x => href.match(x))) {
    config = extend(development, staging);
} else if (![production.env, staging.env].includes(NODE_ENV)) {
    config = extend(development, { env: NODE_ENV });
}

export default {
    isProd: [NODE_ENV, config.env].every(x => x === production.env),
    release,
    ...config,
};
