import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import {
    Dialog,
    Button,
    Typography,
    Layout,
} from 'ui-components';

import gotoUrl from '../../lib/goto-url';
import cdnIcon from '../../lib/cdn-icon';
import { useSession } from '../../shared/context-providers';

const SESSION_TIMEOUT_MS = 3 * 24 * 60 * 60 * 1000; // 3 days

/** @type { React.FC } */
const SignedOutNotifier = () => {

    const location = useLocation();
    const [session, actions] = useSession();
    const [userIsSignedOut, setUserIsSignedOut] = useState(false);
    const [inactiveAccount, setInactiveAccount] = useState(false);

    const { refreshSession } = actions;

    const signOut = useCallback(() => {
        gotoUrl('/users/logout');
    }, []);

    const refresh = useCallback(() => {
        const segments = location.pathname.split('/').filter(v => !!v);

        // If there is multiple path segments they are probably on a db-specific path
        // so redirect to homepage
        // If it is a generic path like `/sources` we can just reload the same path
        if (segments.length > 1) {
            window.location.href = '/';
            return;
        }

        window.location.reload();
    }, [location.pathname]);

    // Just to satisfy propTypes for Dialog
    // Since this is a special case where we dont want the user to close the dialog.
    const noop = useCallback(() => {}, []);

    // Attach a listener for all ajax events to the panoply server
    useEffect(() => {

        /** @type { ReturnType<typeof setTimeout> } */
        let sessionTimer;

        /** @type {(e: ProgressEvent<XMLHttpRequestEventTarget>) => void} */
        const onAjaxComplete = ({ target }) => {
            if (!target) {
                return;
            }
            // @ts-ignore
            const targetUrl = new URL(target.responseURL);
            const reqIsToPanoply = window.location.hostname === targetUrl.hostname;
            const pathSegments = targetUrl.pathname.split('/');
            const reqIsToSource = reqIsToPanoply
                && pathSegments[1] === 'sources'
                && pathSegments.length > 3;

            // @ts-ignore
            const reqIsUnauthorized = target.status === 401;
            // @ts-ignore
            const reqIsPaymentRequired = target.status === 402;

            if (!reqIsToPanoply || reqIsToSource) {
                return;
            }

            if (reqIsUnauthorized) {
                // Assume the session has expired and prompt them to sign in again
                setUserIsSignedOut(true);
            } else if (reqIsPaymentRequired) {
                // Assume the account is not active and prompt them to refresh the page
                setInactiveAccount(true);
                refreshSession().then(() => setInactiveAccount(false));
            } else {
                // Reset the session timer
                clearTimeout(sessionTimer);
                sessionTimer = setTimeout(() => {
                    setUserIsSignedOut(true);
                }, SESSION_TIMEOUT_MS);
            }
        };

        // Store a reference to the native method
        const open = XMLHttpRequest.prototype.open;

        /**
         * Attach our listener to the native method
         * @type {(
         * method: string, url: any, async?: boolean, username?: string, password?: string
         * ) => void}
         */
        XMLHttpRequest.prototype.open = function (...args) {
            this.addEventListener('load', onAjaxComplete, false);
            // Call the native method with same arguments
            // So other components can do ajax normally without being aware of this
            open.apply(this, args);
        };
    }, []);

    const open = inactiveAccount || userIsSignedOut || session.outdated || session.databaseSwitched;

    const icon = session.databaseSwitched ? 'icon-database.svg' : 'icon-security.svg';

    return (
        <Dialog
            isOpen={open}
            onClose={noop}
        >
            <Layout flex flexDirection="column" alignItems="center" spacing="pt-3">
                <img src={cdnIcon(icon)} />

                {session.databaseSwitched && (
                    <>
                        <Typography variant="subtitle1" weight="medium" spacing="mt-4 mb-2">
                            Database Changed
                        </Typography>
                        <Typography spacing="mb-3">
                            You have switched databases to
                            <Typography inline weight="semibold">
                                {' ' + session.database.name + ' '}
                            </Typography>
                            in a different tab.
                        </Typography>
                        <Typography spacing="mb-5">
                            Push continue to use Panoply with the new database.
                        </Typography>
                        <Button color="primary" onClick={refresh}>
                            Continue
                        </Button>
                    </>
                )}

                {session.outdated && (
                    <>
                        <Typography variant="subtitle1" weight="medium" spacing="mt-4 mb-2">
                            Your session has expired
                        </Typography>
                        <Typography spacing="mb-3">
                            You have logged in to a different Panoply account in another tab.
                        </Typography>
                        <Typography spacing="mb-5">
                            Refresh to continue using Panoply as the new user.
                        </Typography>
                        <Button color="primary" onClick={refresh}>
                            Continue
                        </Button>
                    </>
                )}

                {userIsSignedOut && (
                    <>
                        <Typography variant="subtitle1" weight="medium" spacing="mt-4 mb-2">
                            Your session has expired
                        </Typography>
                        <Typography spacing="mb-5">
                            Log back in to continue using Panoply
                        </Typography>
                        <Button color="primary" onClick={signOut}>
                            Log In
                        </Button>
                    </>
                )}

                {inactiveAccount && (
                    <>
                        <Typography variant="subtitle1" weight="medium" spacing="mt-4 mb-2">
                            Session ended
                        </Typography>
                        <Typography>
                            Refresh to continue using Panoply.
                        </Typography>
                    </>
                )}
            </Layout>
        </Dialog>
    );
};

export default SignedOutNotifier;
