import React from 'react';
import {
    DebouncedInput,
    Select,
    SelectItem,
    Switch,
} from 'ui-components';

import { useSession } from '../../shared/context-providers';

/** @typedef { import('./__types').Field } Field */

const useTableMetadataFields = () => {

    const [session] = useSession();
    const dbType = session.database.type;

    /** @type { Field } */
    const typeField = dbType === 'bigquery' ? {
        name: 'type',
        label: 'Type',
        value: 'VARCHAR',
        Component: DebouncedInput,
        disabled: true,
    } : {
        name: 'type',
        label: 'Type',
        value: 'VARCHAR',
        Component: Select,
        disabled: false,
        children: [
            {
                name: 'text',
                label: 'Varchar',
                value: 'VARCHAR',
                Component: SelectItem,
            },
            {
                name: 'date',
                label: 'Time',
                value: 'TIME',
                Component: SelectItem,
            },
            {
                name: 'boolean',
                label: 'Boolean',
                value: 'boolean',
                Component: SelectItem,
            },
            {
                name: 'number',
                label: 'Int',
                value: 'INT',
                Component: SelectItem,
            },
        ],
    };

    /** @type { Field[] } */
    const fields = [
        {
            name: 'current_name',
            label: 'Name',
            value: '',
            Component: DebouncedInput,
            disabled: dbType === 'bigquery',
        },
        {
            name: 'description',
            label: 'Description',
            value: '',
            Component: DebouncedInput,
        },
        typeField,
        {
            name: 'isPrimary',
            label: 'Primary Key',
            value: 'false',
            Component: Select,
            children: [
                {
                    name: 'no',
                    label: 'No',
                    value: 'false',
                    Component: SelectItem,
                },
                {
                    name: 'yes',
                    label: 'Yes',
                    value: 'true',
                    Component: SelectItem,
                },
            ],
            help: 'Is this column is part of the table\'s unique primary name',
        },
        {
            name: 'nest',
            label: 'Nested',
            value: 'subtable',
            Component: Select,
            hidden: dbType === 'bigquery',
            children: [
                {
                    name: 'subtable',
                    label: 'Create one-to-many tables',
                    value: 'subtable',
                    Component: SelectItem,
                },
                {
                    name: 'flatten',
                    label: 'Flatten on parent table',
                    value: 'flatten',
                    Component: SelectItem,
                },
                {
                    name: 'skip',
                    label: 'Skip',
                    value: 'skip',
                    Component: SelectItem,
                },
            ],
            help: 'How to handle nested arrays or objects',
        },
        {
            name: 'keyValue',
            label: 'Key:Value',
            value: 'false',
            Component: Select,
            hidden: dbType === 'bigquery',
            children: [
                {
                    name: 'no',
                    label: 'No',
                    value: 'false',
                    Component: SelectItem,
                },
                {
                    name: 'yes',
                    label: 'Yes',
                    value: 'true',
                    Component: SelectItem,
                },
            ],
            help: 'Does this column contain an object that should be parsed '
                + 'into two columns based on name and value pairing',
        },
        {
            name: 'skip_parse',
            label: 'Skip Parse',
            value: false,
            Component: SkipParse,
        },
    ];

    return fields;

};

const SkipParse = ({ value, spacing, ...props }) => (
    <Switch
        checked={value}
        label="Enable Skip Parse"
        spacing={`-ml-2 ${spacing || ''}`}
        {...props}
    />
);

export default useTableMetadataFields;
