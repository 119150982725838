import { useState, useCallback, useEffect } from 'react';
import { assign, isEqual } from 'lodash';

import { useRequest } from '../../lib/ajax';
import { useSession } from '../../shared/context-providers';
import useDebounce from '../../shared/use-debounce';

/** @typedef { import('../../app/__types').PageProps } PageProps */
/** @typedef { import('./__types').Notifications } Notifications */

/**
 * @typedef { object } Props
 *
 * @prop { PageProps['openToast'] } openToast
 */

/**
 * @typedef { object } ReturnProps
 *
 * @prop { Notifications } notifications
 * @prop { (e: Event) => void } onChange
 */

/** @type { (props: Props) => ReturnProps } */
function useNotifications({ openToast }) {
    const [session] = useSession();
    const { user, database } = session;
    const [notifications, setNotifications] = useState({
        sources_collect_first: true,
        sources_collect_manual: false,
        sources_collect_schedule: false,
        sources_collect_summary: false,
        ...(user.notifications && database.id
            ? user.notifications[database.id]
            : {}
        ),
    });

    const debouncedNotifications = useDebounce(notifications, 750);

    const { go: updateNotifications } = useRequest('/users/settings', 'PUT', user);

    const onChange = useCallback((e) => {
        setNotifications(n => ({ ...n, [e.target.value]: e.target.checked }));
    }, []);

    useEffect(() => {
        if (!user.notifications || !database.id) {
            return;
        }

        if (isEqual(user.notifications[database.id], debouncedNotifications)) {
            return;
        }

        assign(user.notifications, { [database.id]: debouncedNotifications });

        updateNotifications().then(({ ok, data }) => {
            if (!ok) {
                openToast(data);
            }
        });
    }, [user, database, debouncedNotifications, updateNotifications, openToast]);

    return {
        notifications,
        onChange,
    };
}

export default useNotifications;
