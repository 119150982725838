import React from 'react';

import PageLayout from '../../shared/page-layout';
import Billing from '../../shared/billing';

/** @typedef { import('app/__types').PageProps } PageProps */

/** @type { React.FC<PageProps> } */
const BillingPage = () => {
    return (
        <PageLayout title="Billing">
            <Billing />
        </PageLayout>
    );
};

export default BillingPage;
