/* eslint-disable complexity */
import React, { useEffect, useCallback } from 'react';
import { isEmpty, isEqual, isNumber } from 'lodash';
import styled from 'styled-components';
import { transparentize } from 'polished';
import {
    Layout,
    Switch,
    SearchInput,
    Card,
    Loader,
    Divider,
    Typography,
    Callout,
    Tooltip,
    Icon,
    colors,
} from 'ui-components';
import Pagination from '../../../../shared/pagination';
import MarkdownRenderer from '../../../../shared/markdown-renderer';

/** @typedef { import('models/sources/__types').ListParamValue } ParamValue */
/** @typedef { import('models/sources/__types').ListParamItem } Item */
/** @typedef { import('models/sources/__types').ListParamTotalItems } TotalItems */


/**
 * @typedef { object } Props
 * @prop { string } title
 * @prop { ParamValue= } items
 * @prop { ParamValue } value
 * @prop { boolean= } loading
 * @prop { string= } error
 * @prop { boolean= } showOnlySelectedAllowed
 * @prop { boolean= } showOnlySelected
 * @prop { boolean= } selectAllAllowed
 * @prop { number= } maxSelectedItems
 * @prop { boolean= } searchAllowed
 * @prop { string } search
 * @prop { string= } description
 * @prop { number } page
 * @prop { number } pageSize
 * @prop { TotalItems= } totalItems
 * @prop { number= } searchDebounceDuration
 * @prop { (value: string) => void } updateSearch
 * @prop { (value: boolean) => void } updateShowOnlySelected
 * @prop { (checked: boolean) => void } updateSelectAll
 * @prop { (pageNumber: number) => void } updatePage
 * @prop { (value: ParamValue) => void } setValue
 */


/** @type { (showOnlySelected?: boolean) => boolean } */
export const isSearchEnabled = showOnlySelected => !showOnlySelected;

/** @type { (searchTerm: string, value: ParamValue) => boolean } */
export const isShowOnlySelectedEnabled = (searchTerm, value) => (
    isEmpty(searchTerm) && value && value.length > 0
);

/**
 * @param { string } searchTerm
 * @param { ParamValue } items
 * @param { ParamValue } value
 * @param { boolean= } allSelected
 * @param { boolean= } showOnlySelected
 * @param { number= } maxSelectedItems
 * @returns { boolean }
 */
export const isSelectAllEnabled = (
    searchTerm,
    items,
    value,
    allSelected,
    showOnlySelected,
    maxSelectedItems,
) => {
    if (showOnlySelected || !isEmpty(searchTerm) || !items.length) {
        return false;
    }

    const someItemsDisabled = value.some(item => !!item.disabled)
        || items.some(item => !!item.disabled);

    if (someItemsDisabled) {
        return false;
    }

    if (allSelected) {
        return true;
    }

    return maxSelectedItems == null || maxSelectedItems >= items.length;
};

/**
 * @param { Item } item
 * @param { ParamValue } value
 * @param { number= } maxSelectedItems
 * @returns { boolean }
 */
export const isItemDisabled = (item, value, maxSelectedItems) => {
    if (!item || !value || item.disabled) {
        return true;
    }

    const selectedItem = value.find(x => isEqual(x.value, item.value));
    if (selectedItem) {
        return !!selectedItem.disabled;
    }

    return maxSelectedItems != null && maxSelectedItems <= value.length;
};

/** @type { (item: Item, value: ParamValue) => boolean } */
export const isItemSelected = (item, value) => {
    if (!item || !value || !value.length) {
        return false;
    }
    return value.some(x => isEqual(x.value, item.value));
};

/** @type { (items: ParamValue, value: ParamValue) => boolean } */
export const areAllItemsSelected = (items, value) => {
    if (!items || !value || !items.length || !value.length) {
        return false;
    }
    if (value.length < items.length) {
        return false;
    }
    return items.every(item => isItemSelected(item, value));
};

/** @type { (items: ParamValue, searchTerm: string) => ParamValue } */
export const getItemsMatchingSearch = (items, searchTerm) => (
    items.filter(item => (
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    ))
);

/** @type { (items: ParamValue, page: Number, pageSize: Number) => ParamValue } */
export const getItemsInPage = (items, page, pageSize) => {
    const showItemsFrom = page * pageSize;
    const showItemsUntil = (page + 1) * pageSize;
    return items.slice(showItemsFrom, showItemsUntil) || [];
};

/** @type { (i: ParamValue, t?: TotalItems, o?: boolean) => number | null } */
export function getTotalItems(itemsToDisplay, totalItems, showOnlySelected) {
    if (showOnlySelected) {
        return itemsToDisplay.length;
    }

    if (totalItems === null || isNumber(totalItems)) {
        return totalItems;
    }

    return itemsToDisplay.length;
}

/** @type { (selectedItems: ParamValue) => boolean } */
export function searchDuplicatedItems(selectedItems) {
    return selectedItems
        // items -> list of values
        .map((item) => JSON.stringify(item.value))

        // Check if some duplicated
        .some((value, index, values) => {
            return values.indexOf(value) !== index;
        });
}

/** @type { (selectedItems: ParamValue) => ParamValue } */
export function filterDuplicatedItems(selectedItems) {
    const uniqueItems = selectedItems
        // [{ name: 'x', value: 'x' }] -> { x: { name: 'x', value: 'x' } }
        .reduce((obj, item) => {
            // The newly duplicated item overrides the old,
            // happens when files are renamed, for example.
            const key = JSON.stringify(item.value);
            return { ...obj, [key]: item };
        }, {});

    return Object.values(uniqueItems);
}

/** @type { (selectedItem: Item, item: Item) => boolean } */
export function isItemRenamed(selectedItem, item) {
    return isEqual(item.value, selectedItem.value)
        && !isEqual(item.name, selectedItem.name);
}

/** @type { (selectedItems: ParamValue, items: ParamValue) => boolean } */
export function searchRenamedItems(selectedItems, items) {
    return selectedItems
        // Check if some renamed
        .some((selectedItem) => {
            return !!items.find(item => {
                return isItemRenamed(selectedItem, item);
            });
        });
}

/** @type { (selectedItems: ParamValue, items: ParamValue) => ParamValue } */
export function replaceRenamedItems(selectedItems, items) {
    return selectedItems
        // Replaces renamed items
        .map((selectedItem) => {
            const renamedItem = items.find(item => {
                return isItemRenamed(selectedItem, item);
            });
            return renamedItem || selectedItem;
        });
}

/** @type { React.FC<Props> } */
const SourceListParam = React.memo(({
    title,
    items = [],
    value,
    loading,
    error,
    showOnlySelectedAllowed,
    showOnlySelected,
    selectAllAllowed,
    maxSelectedItems,
    searchAllowed,
    search,
    description,
    page,
    pageSize,
    totalItems,
    searchDebounceDuration,
    updateSearch,
    updateShowOnlySelected,
    updateSelectAll,
    updatePage,
    setValue,
}) => {
    const itemsToDisplay = showOnlySelected ? value : getItemsMatchingSearch(items, search);
    const itemsInPage = getItemsInPage(itemsToDisplay, page, pageSize);

    const allSelected = areAllItemsSelected(items, value);
    const selectAllEnabled = !loading
        && isSelectAllEnabled(
            search,
            items,
            value,
            allSelected,
            showOnlySelected,
            maxSelectedItems,
        );
    const showOnlySelectedEnabled = !loading && isShowOnlySelectedEnabled(search, value);
    const searchEnabled = !loading && isSearchEnabled(showOnlySelected);
    const invalidItems = items.filter(item => item.error);

    const toggleShowOnlySelected = useCallback(
        e => updateShowOnlySelected(e.target.checked),
        [updateShowOnlySelected],
    );

    /** @type { (e: React.ChangeEvent<HTMLInputElement>) => void } */
    const onSearchChanged = useCallback((e) => {
        updateSearch(e.target.value);
    }, [updateSearch]);

    /** @type { (e: React.ChangeEvent<HTMLInputElement>) => void } */
    const onSelectAllChanged = useCallback((e) => {
        updateSelectAll(e.target.checked);
    }, [updateSelectAll]);

    /** @type { (item: Item, checked: boolean) => void } */
    const onItemSelectionChanged = useCallback((item, checked) => {
        const withoutItem = (value && value.filter(x => !isEqual(x.value, item.value))) || [];
        const newValues = checked ? [...withoutItem, item] : withoutItem;
        setValue(newValues);
    }, [value, setValue]);

    /** @type { () => void } */
    const deselectInvalid = useCallback(() => {
        const valueWithoutInvalid = value.filter(({ error }) => !error);
        setValue(valueWithoutInvalid);
    }, [value, setValue]);

    useEffect(() => {
        if (page !== 0) {
            updatePage(0);
        }
    }, [search, showOnlySelected]);

    useEffect(() => {
        if (value && value.length) {
            const itemsDuplicated = searchDuplicatedItems(value);
            const itemsRenamed = searchRenamedItems(value, items);
            if (itemsDuplicated || itemsRenamed) {
                const uniqueItems = filterDuplicatedItems(value);
                setValue(replaceRenamedItems(uniqueItems, items));
            }
            return;
        }
        if (!showOnlySelected) {
            return;
        }
        updateShowOnlySelected(false);
    }, [value, showOnlySelected, items, setValue, updateShowOnlySelected]);

    if (error) {
        return <Callout color="error">{error}</Callout>;
    }

    const invalidItemsTooltip = 'Some items selected cannot be found. '
        + 'to collect your data, deselect missing items and save changes';

    return (
        <Layout width="100">
            {!loading && !!items.length && !!invalidItems.length && (
                <Layout flex alignItems="center" spacing="mb-3">
                    <Tooltip content={invalidItemsTooltip}>
                        <Layout flex alignItems="center">
                            <Icon spacing="mr-2" icon="question-circle" color="error" />
                            <Typography color="error" variant="body1" spacing="m-0">
                                {invalidItems.length} Missing
                            </Typography>
                        </Layout>
                    </Tooltip>
                    <DeselectInvalid
                        color="secondaryText"
                        variant="body1"
                        spacing="m-0 ml-2"
                        onClick={deselectInvalid}
                    >
                        (Deselect)
                    </DeselectInvalid>
                </Layout>
            )}
            {description && (
                <Typography component="div" spacing="mb-2" color="secondaryText" variant="body2">
                    <MarkdownRenderer source={description} />
                </Typography>
            )}
            <Card flat contentSpacing="p-0" width="100">
                <Loader
                    dark
                    big
                    absolute
                    overlay
                    active={loading && !page}
                    message={`Loading ${title}`}
                />

                <ListHeader
                    flex
                    spacing="px-3 py-0"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Layout flex alignItems="center">
                        {selectAllAllowed && (
                            <Switch
                                type="checkbox"
                                label="Select All"
                                checked={allSelected}
                                disabled={!selectAllEnabled}
                                onChange={onSelectAllChanged}
                                spacing="mr-5"
                            />
                        )}
                        {searchAllowed && (
                            <SearchInput
                                label="Search"
                                value={search}
                                disabled={!searchEnabled}
                                onChange={onSearchChanged}
                                noFloatLabel
                                debounceDuration={searchDebounceDuration}
                                spacing="pl-0"
                            />
                        )}
                    </Layout>
                    {showOnlySelectedAllowed && (
                        <Switch
                            checked={!!showOnlySelected}
                            disabled={!showOnlySelectedEnabled}
                            label="Show only selected"
                            onChange={toggleShowOnlySelected}
                            spacing="mr-0"
                            reverse
                        />
                    )}
                </ListHeader>

                <Divider spacing="m-0" />

                <ListContent>
                    <ItemsContainer width="100" spacing="p-3 pb-2">
                        <Layout flex alignItems="flex-start" wrap>
                            {!loading && itemsInPage.map(item => (
                                <ListItemSwitch
                                    key={item.value}
                                    item={item}
                                    checked={isItemSelected(item, value)}
                                    disabled={isItemDisabled(item, value, maxSelectedItems)}
                                    onItemSelectionChanged={onItemSelectionChanged}
                                />
                            ))}

                            {!loading && !itemsInPage.length && (
                                <Typography align="center" width="100" spacing="pt-3">
                                    No {title} {search && `match "${search}"`}
                                </Typography>
                            )}
                        </Layout>
                    </ItemsContainer>

                    <Divider spacing="m-0" />

                    <Layout spacing="mx-3 pb-3">
                        <Pagination
                            page={page}
                            pageSize={pageSize}
                            updatePage={updatePage}
                            totalItems={getTotalItems(itemsToDisplay, totalItems, showOnlySelected)}
                            loading={loading}
                            compact
                        />
                    </Layout>
                </ListContent>
            </Card>
        </Layout>
    );
});

/**
 * @typedef { object } SwitchProps
 * @prop { Item } item
 * @prop { boolean } checked
 * @prop { boolean } disabled
 * @prop { (item: Item, checked: boolean) => void } onItemSelectionChanged
 */

/** @type { React.FC<SwitchProps> } */
const ListItemSwitch = ({ item, checked, disabled, onItemSelectionChanged }) => {

    const onSwitchChanged = useCallback(e => (
        onItemSelectionChanged(item, e.target.checked)
    ), [onItemSelectionChanged, item]);

    const tooltipContent = item.error
        ? `"${item.name}" cannot be found.`
            + 'To collect your data, deselect missing items and save changes.'
        : item.name;

    return (
        <SwitchWrapper width="100" mdWidth="50" id="item" spacing="mb-1">
            <Tooltip
                content={tooltipContent}
                interactive={false}
                enterDelay={1000}
                placement="bottom-start"
            >
                <Switch
                    type="checkbox"
                    label={
                        <SwitchLabel
                            color={item.error ? 'error' : 'text'}
                        >
                            {item.name}
                            {item.error && (
                                <Icon spacing="ml-1" icon="question-circle" color="error" />
                            )}
                        </SwitchLabel>
                    }
                    checked={checked}
                    disabled={disabled}
                    color={item.error ? 'error' : 'primary'}
                    onChange={onSwitchChanged}
                />
            </Tooltip>
        </SwitchWrapper>
    );
};

const SwitchWrapper = styled(Layout)`
    overflow: hidden;
`;

const DeselectInvalid = styled(Typography)`
    cursor: pointer;
    text-decoration: underline;
`;

const ListHeader = styled(Layout)`
    min-height: 55px;
    background-color: ${transparentize(0.80, colors.interface)};
`;

const ListContent = styled(Layout)`
    background-color: ${transparentize(0.93, colors.interface)};
`;

const ItemsContainer = styled(Layout)`
    min-height: 142px;
`;
const SwitchLabel = styled(Typography)`
    &&&&&& {
        word-break: break-word;
        word-wrap: break-word;
    }
`;

export default SourceListParam;
