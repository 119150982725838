import moment from 'moment';

import {
    createPDF,
    getFormat,
    getOrientation,
    getFilename,
    getCoordinates,
    getDimensions,
} from '../../lib/pdf';

/** @typedef { import('lib/pdf').Options } Options */
/** @typedef { import('lib/pdf').PDF } PDF */
/** @typedef { import('lib/pdf').Coordinates } Coordinates */
/** @typedef { NonNullable<Options['page']> } Page */
/** @typedef { Page & { watermark?: Coordinates } } PageOptions */

/** @type { (date: string) => string } */
function getFormattedUTCDate(date) {
    return moment.utc(date).format('MMM DD, YYYY [@] HH:mm [UTC]');
}

/** @type { (doc: Document) => HTMLTimeElement[] } */
function setFormattedUTCDate(doc) {
    const times = Array.from(doc.getElementsByTagName('time'));

    times.forEach(time => {
        const date = time.getAttribute('datetime');

        if (!date) {
            return;
        }

        time.replaceChildren(getFormattedUTCDate(date));

        time.style.width = '100%';
        time.style.maxWidth = '100%';

        const typography = time.closest('span');

        if (typography) {
            typography.style.width = '100%';
            typography.style.maxWidth = '100%';
        }
    });

    return times;
}

/** @type { (doc: Document, title: string) => HTMLElement | null } */
function setPageTitle(doc, title) {
    const pageTitle = doc.getElementById('page-title');

    if (pageTitle) {
        pageTitle.style.width = '100%';
        pageTitle.style.maxWidth = '100%';

        const typography = pageTitle.querySelector('div');

        if (typography) {
            typography.style.width = '100%';
            typography.style.maxWidth = '100%';

            const text = typography.querySelector('span');

            if (text) {
                text.textContent = title;
            }
        }
    }

    return pageTitle;
}

/** @type { (doc: Document, width: number) => HTMLElement | null } */
function getPageHeader(doc, width) {
    const pageHeader = doc.getElementById('page-header');

    if (pageHeader) {
        pageHeader.style.backgroundColor = 'transparent';
        pageHeader.style.width = `${width}px`;
        pageHeader.style.marginRight = 'auto';
        pageHeader.style.marginLeft = 'auto';
    }

    return pageHeader;
}

/** @type { (doc: Document, ...elements: Array<Element | null>) => HTMLElement | null } */
function getPageContent(doc, ...elements) {
    const pageContent = doc.getElementById('page-content');

    if (pageContent) {
        pageContent.style.backgroundColor = 'transparent';
        pageContent.style.height = 'auto';
        pageContent.style.minHeight = 'auto';
        pageContent.style.paddingBottom = '24px';

        pageContent.replaceChildren(...elements.map(cloneNode));
    }

    return pageContent;
}

/** @type { (doc: Document, reportId?: string) => HTMLElement | null } */
function getGridLayout(doc, reportId) {
    if (reportId) {
        return doc.getElementById(reportId);
    }

    /** @type { HTMLElement | null } */
    return doc.querySelector('.react-grid-layout');
}

/** @type { (doc: Document, width: number) => HTMLSpanElement } */
function getWatermark(doc, width) {
    const watermark = doc.createElement('span');

    watermark.replaceChildren('Powered by Panoply');

    const typography = doc.getElementsByTagName('time')[0]?.closest('span');

    if (typography) {
        const className = typography.getAttribute('class');
        const style = typography.getAttribute('style');

        if (className) {
            watermark.setAttribute('class', className);
        }

        if (style) {
            watermark.setAttribute('style', style);
        }
    }

    watermark.style.width = `${width}px`;
    watermark.style.height = '24px';
    watermark.style.margin = '8px 0 auto 0';
    watermark.style.lineHeight = '24px';
    watermark.style.textAlign = 'right';

    return watermark;
}

/** @type { (doc: Document, element: HTMLElement | null) => void } */
function setDocumentBody(doc, element) {
    doc.documentElement.style.height = 'auto';
    doc.documentElement.style.minHeight = 'auto';

    doc.body.style.backgroundColor = 'transparent';
    doc.body.style.height = 'auto';
    doc.body.style.minHeight = 'auto';

    doc.body.replaceChildren(cloneNode(element));
}

/** @type { (element: Element | null) => Node | string } */
function cloneNode(element) {
    return element?.cloneNode(true) || '';
}

/** @type { (element: Element | null) => DOMRect } */
function getBoundingClientRect(element) {
    if (!element) {
        return new DOMRect();
    }

    return element.getBoundingClientRect();
}

/** @type { (reportId?: string) => PageOptions } */
function getPageOptions(reportId) {
    const pageContent = document.getElementById('page-content');

    if (!pageContent) {
        return {};
    }

    const docRect = document.documentElement.getBoundingClientRect();

    const EXTRA_PADDING = 24; // should be removed from the bottom

    const pageRect = pageContent.getBoundingClientRect();

    const width = Math.max(docRect.width, pageRect.width);
    const height = Math.max(docRect.height, pageRect.height);

    const format = getFormat(
        width,
        height
    );

    /** @type { PageOptions } */
    const options = {
        format,
        orientation: getOrientation(format),
        watermark: getCoordinates(
            pageRect.width - EXTRA_PADDING + 3,
            pageRect.height - (EXTRA_PADDING * 2) + 9,
        ),
    };

    if (!reportId) {
        return options;
    }

    const reportCard = document.getElementById(reportId);
    const gridLayout = document.querySelector('.react-grid-layout');

    if (!reportCard || !gridLayout) {
        return options;
    }

    const reportRect = reportCard.getBoundingClientRect();
    const gridRect = gridLayout.getBoundingClientRect();

    const gridHeight = height - gridRect.height + reportRect.height;

    const newHeight = Math.max(
        docRect.height,
        gridHeight,
    );

    const newFormat = getFormat(width, newHeight);

    return {
        format: newFormat,
        orientation: getOrientation(newFormat),
        watermark: getCoordinates(
            (pageRect.width - reportRect.width) / 2 + reportRect.width + 3,
            pageRect.height - gridRect.height + reportRect.height - (EXTRA_PADDING * 2) + 9,
        ),
    };
}

/** @type { (title: string, page: Options['page'], reportId?: string) => Options } */
function getOptions(title, page, reportId) {
    return {
        method: 'build',
        page,
        overrides: {
            canvas: {
                // Clone Options
                ignoreElements: (element) => {
                    return [
                        'anchor',
                        'anchor-wrap',
                        'button',
                        'tooltip',
                        'tooltip-wrap',
                        'dialog',
                        'actions',
                    ].some(className => element.classList.contains(className));
                },
                onclone: (doc) => {
                    setPageTitle(doc, title);
                    setFormattedUTCDate(doc);

                    const gridLayout = getGridLayout(doc, reportId);
                    const { width } = getBoundingClientRect(gridLayout);

                    const pageHeader = getPageHeader(doc, width);
                    const watermark = getWatermark(doc, width);

                    const pageContent = getPageContent(
                        doc,
                        pageHeader,
                        gridLayout,
                        watermark,
                    );

                    setDocumentBody(doc, pageContent);
                },
            },
        },
    };
}

/** @type { (title: string, reportId?: string) => Promise<PDF> } */
export async function createDashboardPDF(title, reportId) {
    const { watermark, ...page } = getPageOptions(reportId) || {};
    const pdf = await createPDF(getOptions(title, page, reportId));

    const { w, h } = getDimensions(54, 24);
    const { x = w, y = h } = watermark || {};

    pdf.link(x - w, y - h, w, h, {
        url: 'https://panoply.io',
    });

    return pdf;
}

/** @type { (title: string, reportId?: string) => Promise<void> } */
export async function downloadDashboardPDF(title, reportId) {
    const pdf = await createDashboardPDF(title, reportId);
    await pdf.save(getFilename(title), { returnPromise: true });
}

/** @type { (title: string, reportId?: string) => Promise<string> } */
export async function getDashboardPDFData(title, reportId) {
    const pdf = await createDashboardPDF(title, reportId);
    return pdf.output('datauristring');
}
