/** @type { (email: string) => boolean } */
function isEmailAllowed(email) {
    if (!email) {
        return false;
    }

    // we block gmail.com, hotmail.com, yahoo.com, mailinator.com, outlook.com

    const notAllowdEmails = new RegExp(
        /(\W|^)[\w.-]{0,25}@(yahoo|hotmail|gmail|mailinator|outlook)\.com(\W|$)/,
    );

    return !notAllowdEmails.test(email.toLowerCase());
}

export default isEmailAllowed;
