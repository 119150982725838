import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { reportError } from '../../lib/error-reporter';
import { loadColumns } from '../../models/columns';

/** @typedef { import('./edit-join-dialog').JoinColumn } JoinColumn */
/** @typedef { import('./edit-join-dialog').Option } Option */
/** @typedef { import('./edit-join-dialog').Table } Table */

/** @type { (table: Table, options: Option[]) => Option | undefined } */
export const findTableOption = (table, options) => {
    const title = [table.schema, table.name].join('.');
    return options.find(o => o.title === title);
};

/**
 * @param { Option[] } tableOptions
 * @param { JoinColumn } [column]
 */
function useJoinColumns(tableOptions, column) {
    const { schema, table: name } = column || {};

    const { data: columns = [] } = useQuery({
        queryKey: ['columns', schema, name],
        queryFn: async () => {
            if (!schema || !name) {
                return [];
            }

            const table = { schema, name };
            const option = findTableOption(table, tableOptions);
            const type = option?.table?.type || 'table';

            try {
                const columns = await loadColumns({ ...table, type });

                return columns;

            } catch (/** @type { any } */ err) {
                reportError(err);
            }

            return [];
        },
        staleTime: Infinity,
        gcTime: Infinity,
    });

    const options = useMemo(() => {
        return columns.map(column => {
            const title = column.name;

            return {
                title,
                tooltip: title,
                category: 'Fields',
                column,
            };
        });
    }, [columns]);

    return {
        options,
    };
}

export default useJoinColumns;
