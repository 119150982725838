import React from 'react';
import styled from 'styled-components';

import {
    Image,
    Layout,
    Link as ClickLink,
    colors,
    spacings,
} from 'ui-components';

import gotoUrl from '../../lib/goto-url';

import PageLayout from '../page-layout';

import panoplyLogoPath from '../../images/logo-panoply-horizontal-dark.svg';

function gotoHome() {
    gotoUrl('https://panoply.io/');
}

/**
 * @typedef { object } Props
 * @prop { React.ReactNode } children
 * @prop { string } [title]
 * @prop { string } [spacing]
 */

/** @type { React.FC<Props> } */
const AuthLayout = ({
    children,
    title,
}) => (
    <PageLayout title={title} bgColor={colors.white} fullbleed>
        <Container
            width="100"
            flex
            alignItems="center"
            justifyContent="center"
            spacing="pt-9 pb-5"
        >
            <Logo />
            <ContentArea>
                {children}
            </ContentArea>
        </Container>
    </PageLayout>
);

function Logo() {
    return (
        <LogoContainer component="span" onClick={gotoHome}>
            <Image
                src={panoplyLogoPath}
                width="100"
                height="57px"
                size="contain"
                repeat="no-repeat"
                position="left"
            />
        </LogoContainer>
    );
}

const LogoContainer = styled(ClickLink)`
    display: block;
    position: absolute;
    top: ${spacings[3]}rem;
    left: ${spacings[4]}rem;
    width: 180px;
`;

const Container = styled(Layout)`
    min-height: 100%;
`;

const ContentArea = styled(Layout)`
    max-width: 500px;
    width: 80%;
    min-width: 220px;
`;

export default AuthLayout;
