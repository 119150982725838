import isEmailAllowed from '../../lib/is-email-allowed';

export const passwordValidators = [
    { test: v => /^.{8,32}$/.test(v), message: '8-32 characters long' },
    { test: v => /^.*[A-Z].*$/.test(v), message: 'Contain uppercase characters' },
    { test: v => /^.*[a-z].*$/.test(v), message: 'Contain lowercase characters' },
    { test: v => /^.*[0-9].*$/.test(v), message: 'Contain a number' },
    { test: v => /^[^'"/ \\@\s]+$/.test(v), message: 'Not use \', ", /, \\, @ or space' },
];

export const usernameValidators = [
    { test: v => /^.{3,60}$/.test(v), message: '3-60 characters long' },
    { test: v => /^[a-z].*/.test(v), message: 'Begin with a letter' },
    { test: v => /^[^A-Z]+$/.test(v), message: 'Only lowercase letters' },
    {
        test: v => /^(?!.*?\.\.)[a-z0-9_.-]+$/.test(v),
        message: 'Characters allowed: a-z, 0-9, ., -, _',
    },
];

export const allowedEmailValidators = [
    {
        test: isEmailAllowed,
        message: 'Please use your work email to sign up',
    },
];
