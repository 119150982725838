/* eslint-disable complexity */
import React from 'react';
import styled from 'styled-components';

import {
    Layout,
    Typography,
    ExpansionPanel,
    Icon,
    Button,
} from 'ui-components';

import Feature from '../../shared/feature';

const WarehousesListItem = React.memo(({
    user, warehouse, onSwitch, onSuspend, onUnsuspend, onReassign,
}) => {
    const isSuspended = !!warehouse.suspended || !!warehouse.suspending;
    const isActive = !isSuspended && !!warehouse.host;
    const isLoading = !!warehouse.suspending || !!warehouse.unsuspending
      || !!warehouse.reassigning;

    const isSuspendable = !isLoading && isActive;
    const isUnsuspendable = !isLoading && isSuspended && warehouse.host === null;
    const isSwitchable = !isLoading && isActive && !warehouse.selected;
    const isReassignable = !isLoading && isActive;

    const getHost = () => {
        if (isSuspended) {
            return 'Suspended - Contact support';
        }
        return warehouse.host ? warehouse.host + ':' + warehouse.port : '';
    };

    const makeExpansionPanelLabel = () => {
        const icon = warehouse.selected ? 'check-square' : 'square';
        const color = warehouse.selected ? 'primary' : 'default';

        const switchButtonProps = {
            onClick: () => onSwitch(warehouse),
            disabled: !isSwitchable,
            color,
        };

        return (
            <Layout width="100" flex alignItems="center" justifyContent="space-between">
                <SwitchColumn spacing="-m-1">
                    <SwitchButton type="plain" spacing="m-0 py-1" {...switchButtonProps}>
                        <Icon icon={icon} />
                    </SwitchButton>
                </SwitchColumn>

                <Feature databaseType="redshift">
                    <Layout width="30" spacing="pr-3" flex>
                        <Typography noWrap color="text">
                            {warehouse.name}
                        </Typography>
                    </Layout>

                    <Layout width="30" spacing="pr-3">
                        <Typography noWrap>
                            {getHost()}
                        </Typography>
                    </Layout>

                    <Layout width="30" spacing="pr-3">
                        <Typography noWrap>
                            {warehouse.owner}
                        </Typography>
                    </Layout>
                </Feature>

                <Feature not databaseType="redshift">
                    <Layout width="30" spacing="pr-3" flex>
                        <Typography noWrap color="text">
                            {warehouse.name}
                        </Typography>
                    </Layout>

                    <Layout width="60" spacing="pr-3">
                        <Typography noWrap>
                            {warehouse.owner}
                        </Typography>
                    </Layout>
                </Feature>
            </Layout>
        );
    };

    const suspendButtonProps = {
        leftIcon: 'pause-circle',
        onClick: () => onSuspend(warehouse),
        disabled: !isSuspendable,
        loading: warehouse.suspending,
    };

    const unsuspendButtonProps = {
        leftIcon: 'play-circle',
        onClick: () => onUnsuspend(warehouse),
        disabled: !isUnsuspendable,
        loading: warehouse.unsuspending,
    };

    const reassignButtonProps = {
        leftIcon: 'sync',
        onClick: () => onReassign(warehouse),
        disabled: !isReassignable,
        loading: warehouse.reassigning,
    };

    const expansionPanelProps = {
        key: warehouse.id,
        labelComponent: makeExpansionPanelLabel(),
        locked: !user.admin,
        actions: user.admin && [
            <Button key="suspend" spacing="ml-0" {...suspendButtonProps}>Suspend</Button>,
            <Button key="unsuspend" {...unsuspendButtonProps}>Unsuspend</Button>,
            <Button key="reassign" spacing="mr-0" {...reassignButtonProps}>Reassign</Button>,
        ],
    };

    return (
        <ExpansionPanel {...expansionPanelProps}>
            <Layout flex={false} width="100" spacing="py-2">
                <Layout spacing="ml-3" flex>
                    <DetailsKey spacing="mr-3">Name</DetailsKey>
                    <Typography color="secondaryText">
                        {warehouse.name}
                    </Typography>
                </Layout>

                <Feature databaseType="redshift">
                    {warehouse.host && (
                        <>
                            <Layout spacing="mt-4 ml-3" flex>
                                <DetailsKey spacing="mr-3">Host</DetailsKey>
                                <Typography color="secondaryText">
                                    {warehouse.host}
                                </Typography>
                            </Layout>
                            <Layout spacing="mt-4 ml-3" flex>
                                <DetailsKey spacing="mr-3">Port</DetailsKey>
                                <Typography color="secondaryText">
                                    {warehouse.port}
                                </Typography>
                            </Layout>
                        </>
                    )}
                </Feature>

                {warehouse.owner && (
                    <Layout spacing="mt-4 ml-3" flex>
                        <DetailsKey spacing="mr-3">Owner</DetailsKey>
                        <Typography color="secondaryText">
                            {warehouse.owner}
                        </Typography>
                    </Layout>
                )}
            </Layout>
        </ExpansionPanel>
    );
});

const SwitchColumn = styled(Layout)`
    min-width: 56px;
`;

const DetailsKey = styled(Typography)`
    min-width: 160px;
`;

const SwitchButton = styled(Button)`
    min-width: 56px;
`;

export default WarehousesListItem;
