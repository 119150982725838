import React from 'react';

import {
    Loader,
    Typography,
    Button,
    Layout,
    Icon,
} from 'ui-components';

import mailImagePath from '../../images/icon-mail-sent.svg';

/**
 * @typedef { object } Props
 * @prop { string } email
 * @prop { boolean } resendIsSuccess
 * @prop { boolean } loading
 * @prop { () => void } onResendClicked
 */

/** @type { React.FC<Props> } */
const SignupSuccess = ({
    email, resendIsSuccess, loading, onResendClicked,
}) => {
    if (loading) {
        return <Loader big active />;
    }
    return (
        <Layout flex flexDirection="column" alignItems="center">
            <img src={mailImagePath} />

            <Typography
                variant="h5"
                align="center"
                weight="medium"
                spacing="my-6"
            >
                Verify Your Email Address
            </Typography>

            <Typography color="secondaryText" variant="body1" align="center">
                You’re just 2 steps away from syncing your data!
            </Typography>

            <Typography spacing="my-6" variant="body1" weight="normal" align="center">
                We’ve {resendIsSuccess && 're-'}sent a message to {email}.
                To complete signup, click the verification link in your email.
            </Typography>

            <Button
                type="plain"
                color="primary"
                onClick={onResendClicked}
                spacing="mb-4"
            >
                I never received an email, please send again
                <Icon spacing="ml-2" size="sm" icon="arrow-right" />
            </Button>
        </Layout>
    );
};

export default SignupSuccess;
