import React from 'react';
import { useParams } from 'react-router-dom';

import {
    Divider,
    ResizableContainer,
    ResizableSplitter,
    ResizableElement,
    breakpoints,
} from 'ui-components';

import WorkbenchMenu from './workbench-menu';
import WorkbenchEditor from './workbench-editor';
import WorkbenchQueryBuilder from './workbench-query-builder';
import WorkbenchQueryResults from './workbench-query-results';
import useWorkbenchEditor from './use-workbench-editor';
import useWorkbenchQueryResults from './use-workbench-query-results';
import useWorkbenchQuery from './use-workbench-query';

/** @typedef { import('lib/query-builder').Column } Column */
/** @typedef { import('lib/query-builder').Position } Position */
/** @typedef { import('lib/query-builder').Filter } Filter */
/** @typedef { import('lib/query-builder').Order } Order */
/** @typedef { import('lib/query-builder').MainTable } MainTable */
/** @typedef { import('lib/query-builder').JoinTable } JoinTable */
/** @typedef { import('lib/query-builder').Tables } Tables */
/** @typedef { import('lib/query-builder').Join } Join */
/** @typedef { import('./__types').Mode } Mode */
/** @typedef { import('./__types').ViewData } ViewData */
/** @typedef { import('./__types').TableData } TableData */
/** @typedef { import('./__types').SchemaData } SchemaData */
/** @typedef { import('./__types').ReportData } ReportData */
/** @typedef { import('./__types').WorkbenchParams } WorkbenchParams */
/** @typedef { import('./use-workbench-data').WorkbenchData } WorkbenchData */

/**
 * @typedef { object } Params
 * @prop { Column[] } allColumns
 * @prop { Tables } allTables
 * @prop { Omit<Column, 'id'> } [pendingColumn]
 * @prop { boolean } hideHidden
 * @prop { boolean } someHidden
 * @prop { string } builtQuery
 * @prop { { where: Filter[], having: Filter[] } } queryFilter
 * @prop { Order[] } queryOrder
 * @prop { number | undefined } queryLimit
 * @prop { boolean } hasUndoChange
 * @prop { boolean } hasRedoChange
 * @prop { (column: Omit<Column, 'id'>, position?: Position) => void } addColumn
 * @prop { (position: Position) => void } removeColumn
 * @prop { (from: Position, to: Position) => void } moveColumn
 * @prop { (position: Position) => void } toggleColumn
 * @prop { (params: Omit<JoinTable, 'alias'>) => void } addJoinTable
 * @prop { (position: number, join: Join) => void } changeJoinTable
 * @prop { (position: number) => void } removeJoinTable
 * @prop { (position: Position, alias: NonNullable<Column['alias']>) => void } changeAlias
 * @prop { (position: Position, newFunction: Column['function']) => void } changeFunction
 * @prop { (where: Filter[], having: Filter[]) => void } changeFilter
 * @prop { (orderBy: Order[]) => void } changeOrder
 * @prop { (limit?: number) => void } changeLimit
 * @prop { () => void } clearColumns
 * @prop { () => void } toggleHidden
 * @prop { () => void } undoChange
 * @prop { () => void } redoChange
 */

/**
 * @typedef { object } Props
 *
 * @prop { Mode } mode
 * @prop { (newMode: Mode) => void } onModeChanged
 *
 * @prop { boolean } dataIsLoaded
 * @prop { ViewData[] } views
 * @prop { TableData[] } tables
 * @prop { SchemaData[] } schemas
 * @prop { ReportData[] } reports
 * @prop { boolean } loadingViews
 * @prop { boolean } loadingTables
 * @prop { boolean } loadingSchemas
 * @prop { boolean } loadingReports
 * @prop { WorkbenchData['fetchViews'] } fetchViews
 * @prop { WorkbenchData['fetchTables'] } fetchTables
 * @prop { WorkbenchData['fetchSchemas'] } fetchSchemas
 * @prop { WorkbenchData['fetchReports'] } fetchReports
 *
 * @prop { boolean } leftMenuIsOpen
 * @prop { boolean } dropDownIsOpen
 * @prop { boolean } menuIsExpanded
 * @prop { () => void } onLeftMenuToggled
 * @prop { () => void } onDropDownToggled
 * @prop { () => void } clearConfig
 */

/** @type { React.FC<Props & Params> } */
const WorkbenchMain = React.memo(({
    mode,
    onModeChanged,

    dataIsLoaded,
    views,
    tables,
    reports,
    schemas,
    loadingViews,
    loadingTables,
    loadingReports,
    loadingSchemas,
    fetchTables,
    fetchViews,
    fetchSchemas,
    fetchReports,
    clearConfig,

    leftMenuIsOpen,
    dropDownIsOpen,
    menuIsExpanded,
    onLeftMenuToggled,
    onDropDownToggled,

    allColumns,
    allTables,
    pendingColumn,
    hideHidden,
    someHidden,
    builtQuery,
    queryFilter,
    queryOrder,
    queryLimit,
    hasUndoChange,
    hasRedoChange,
    addColumn,
    removeColumn,
    moveColumn,
    toggleColumn,
    addJoinTable,
    changeJoinTable,
    removeJoinTable,
    changeAlias,
    changeFunction,
    changeFilter,
    changeOrder,
    changeLimit,
    clearColumns,
    toggleHidden,
    undoChange,
    redoChange,
}) => {
    /** @type { WorkbenchParams } */
    const { id, type, schema } = useParams();

    const {
        query,
        queryError,
        queryStartedAt,
        queryEndedAt,
        queryIsRunning,
        queryResults,
        queryResultPage,
        activeResult,
        parentQueryStatus,
        runQuery,
        changeQuery,
        changeQueryError,
        changeQueryResults,
        updateQueryResult,
        changeActiveQueryResult,

        page,
        pageSize,
        pageLoading,
        updatePage,
        updatePageSize,

        isSelectQuery,
        showVisualization,
        showCallToAction,
    } = useWorkbenchQuery();

    const {
        dbType,
        onQueryChanged,
        onQuerySelectionChanged,
        onRunClicked,
        diagnostics,
        highlight,
        onNewClicked,
        onFormatClicked,
    } = useWorkbenchEditor({
        id,
        schema,
        type,
        views,
        tables,
        reports,
        fetchViews,
        fetchTables,
        fetchSchemas,
        builtQuery,
        query,
        queryResults,
        activeResult,
        runQuery,
        changeQuery,
        changeQueryError,
        changeQueryResults,
    });

    const {
        showSaveAsDialog,
        saveAsDialogOpen,
        saveDialogOpen,
        toggleSaveAsDialog,
        toggleSaveDialog,
    } = useWorkbenchQueryResults();

    const queryBuilderProps = {
        queryIsRunning,
        queryError,
        queryData: queryResultPage?.data,
        hideHidden,
        someHidden,
        allColumns,
        queryFilter,
        queryOrder,
        addColumn,
        removeColumn,
        moveColumn,
        toggleColumn,
        clearColumns,
        toggleHidden,
        changeAlias,
        changeFunction,
        changeFilter,
        changeOrder,
    };

    const menuProps = {
        mode,
        onModeChanged,
        tables,
        views,
        opening: !dataIsLoaded,
        queryIsEmpty: !query?.trim(),
        queryIsRunning,
        hasQueryError: !!queryError,
        hasQueryResults: !!queryResults.length,
        exportingCsv: queryResults.some(qr => !!qr.exportingCsv),
        activeResult,
        onRunClicked,
        onNewClicked,
        onFormatClicked,
        onQueryChanged,
        updateQueryResult,
        leftMenuIsOpen,
        dropDownIsOpen,
        menuIsExpanded,
        onLeftMenuToggled,
        onDropDownToggled,
        builtQuery,
        allColumns,
        allTables,
        pendingColumn,
        queryFilter,
        queryOrder,
        queryLimit,
        hasUndoChange,
        hasRedoChange,
        addJoinTable,
        changeJoinTable,
        removeJoinTable,
        changeFilter,
        changeOrder,
        changeLimit,
        undoChange,
        redoChange,
        clearConfig,
        hideHidden,
        someHidden,
        toggleHidden,
    };

    const editorProps = {
        contents: query,
        onChange: onQueryChanged,
        onSelectionChange: onQuerySelectionChanged,
        schemas,
        views,
        tables,
        diagnostics,
        dbType,
        highlight,
    };

    const resultsProps = {
        queryError,
        queryIsRunning,
        queryStartedAt,
        queryEndedAt,
        queryResults,
        updateQueryResult,
        pageSize,
        views,
        tables,
        schemas,
        reports,
        loadingViews,
        loadingTables,
        loadingSchemas,
        loadingReports,
        fetchViews,
        fetchReports,
        page,
        pageLoading,
        queryResultPage,
        activeResult,
        changeActiveQueryResult,
        updatePage,
        updatePageSize,
        parentQueryStatus,
        isSelectQuery,
        showSaveAsDialog,
        showVisualization,
        showCallToAction,
        saveAsDialogOpen,
        saveDialogOpen,
        toggleSaveAsDialog,
        toggleSaveDialog,
    };

    return (
        <>
            <WorkbenchMenu {...menuProps} />

            <Divider width="100" spacing="my-0" />

            <ResizableContainer orientation="horizontal">
                <ResizableElement {...getTopElementProps()}>
                    {mode === 'query-builder' ? (
                        <WorkbenchQueryBuilder {...queryBuilderProps} />
                    ) : (
                        <WorkbenchEditor {...editorProps} />
                    )}
                </ResizableElement>

                <ResizableSplitter />

                <ResizableElement {...getBottomElementProps()}>
                    <WorkbenchQueryResults {...resultsProps} />
                </ResizableElement>
            </ResizableContainer>
        </>
    );
});

/** @type { () => { minSize: number } } */
const getTopElementProps = () => ({
    minSize: Math.floor(parseInt(breakpoints.sm) / 10),
});

/** @type { () => { minSize: number, style: object } } */
const getBottomElementProps = () => ({
    minSize: Math.floor(parseInt(breakpoints.sm) / 10),
    style: {
        display: 'flex',
        flexDirection: 'column',
    },
});

export default WorkbenchMain;
