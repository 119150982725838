/**
 * @param { string } [subject]
 * @param { () => any } [fallback]
 */
const openSupportChat = (subject, fallback) => {
    // @ts-ignore
    const intercom = window.Intercom;

    if (intercom) {
        intercomHandler(intercom, subject);
    } else if (fallback) {
        fallback();
    }
};

/**
 * @param { any } intercom
 * @param { string } [subject]
 */
const intercomHandler = (intercom, subject) => {
    if (subject) {
        intercom('showNewMessage', subject);
    } else {
        intercom('show');
    }
};

export default openSupportChat;
