import React, { useState, useEffect, useCallback } from 'react';
import {
    Dialog,
    Layout,
    Typography,
    Button,
} from 'ui-components';

import SourceScheduling from '../../pages/source-page/source-scheduling';

/** @typedef {import('models/sources/__types').Source} Source */
/** @typedef {import('models/sources/__types').SourceType} SourceType */
/** @typedef {import('models/sources/__types').Schedule} Schedule */

/**
 * @typedef { object } Props
 * @prop { boolean } isOpen
 * @prop { () => void } onClose
 * @prop { (
 *    schedule: Schedule, source: Source, sourceType: SourceType
 *   ) => Promise<void> } saveSchedule
 * @prop { Schedule } schedule
 * @prop { Source } source
 * @prop { SourceType } sourceType
 * @prop { Boolean } collectable
 * @prop { Boolean } collectableAfterSave
 */

/** @type { React.FC<Props> } */
const ScheduleSourceDialog = ({
    isOpen,
    onClose,
    saveSchedule,
    schedule,
    source,
    sourceType,
    collectable,
    collectableAfterSave,
}) => {

    const [currentSchedule, setCurrentSchedule] = useState({
        dayOfWeek: schedule.dayOfWeek || null,
        hour: schedule.hour || null,
        minute: schedule.minute || null,
        type: schedule.type,
        disabled: schedule.disabled,
    });

    useEffect(() => {
        setCurrentSchedule(schedule);
    }, [schedule]);

    const [changed, setChanged] = useState(false);
    useEffect(() => {
        setChanged(JSON.stringify(schedule) !== JSON.stringify(currentSchedule));
    }, [schedule, currentSchedule, setChanged]);

    const saveAndClose = useCallback(() => (
        saveSchedule(currentSchedule, source, sourceType).then(onClose)
    ), [currentSchedule, source, source, saveSchedule, onClose]);

    const closeModal = useCallback(() => {
        onClose();
        setCurrentSchedule(schedule);
    }, [onClose, schedule, setCurrentSchedule]);

    const disableSchedule = useCallback(() => {
        const updatedSchedule = {
            ...schedule,
            disabled: true,
        };
        setCurrentSchedule(updatedSchedule);
        saveSchedule(updatedSchedule, source, sourceType).then(onClose);
    }, [schedule, setCurrentSchedule, saveSchedule, source, sourceType, onClose]);

    return (
        <Dialog
            isOpen={isOpen}
            onClose={closeModal}
            actions={(
                <>
                    <Button
                        onClick={closeModal}
                        type="plain"
                    >
                        Cancel
                    </Button>
                    {collectable && (
                        <Button
                            color="primary"
                            onClick={saveAndClose}
                            disabled={!changed}
                        >
                            Yes, Change Schedule
                        </Button>
                    )}
                    {!collectable && (
                        <Button
                            color="primary"
                            onClick={disableSchedule}
                        >
                            Yes, Disable Schedule
                        </Button>
                    )}
                </>
            )}
        >
            {collectable || collectableAfterSave ? (
                <>
                    <Typography
                        spacing="mt-1"
                        color="text"
                        variant="h6"
                        align="center"
                    >
                        Schedule collections for {`"${source.title}"`}?
                    </Typography>
                    <Layout spacing="px-2">
                        <SourceScheduling
                            currentSchedule={currentSchedule}
                            setCurrentSchedule={setCurrentSchedule}
                        />
                    </Layout>
                </>
            ) : (
                <>
                    <Typography
                        spacing="mt-1"
                        color="text"
                        variant="h6"
                        align="center"
                    >
                        {`"${source.title}"`} is scheduled but not collectable.
                    </Typography>
                    <Typography
                        spacing="mt-5 mb-4"
                        color="text"
                        variant="body1"
                        align="center"
                    >
                        Continue to disable the schedule.
                    </Typography>
                </>
            )}
        </Dialog>
    );
};

export default ScheduleSourceDialog;
