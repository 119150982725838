import React, { useState } from 'react';
import { isObject } from 'lodash';

import {
    Button,
    Dropdown,
    List,
    ListItem,
} from 'ui-components';

import { useToast } from '../context-providers';


/** @typedef { import('models/queries/__types').QueryResult } QueryResult */
/** @typedef { Record<string, any> } Row  */

/**
 * @typedef { object } Props
 * @prop { QueryResult } [queryResultPage]
 * @prop { boolean } isSelectQuery
 * @prop { string } [queryError]
 */

/** @type { React.FC<Props> } */
const WorkbenchCopyDropdown = ({
    queryResultPage,
    isSelectQuery,
    queryError,
}) => {
    const { openToast } = useToast();

    const canCopy = !queryResultPage?.id
        && !queryError;

    const hasResults = !!queryResultPage?.data?.rows?.length
        && isSelectQuery;

    const [copyDropdownOpen, setCopyDropdownOpen] = useState(false);

    const toggleCopyDropdown = () => {
        setCopyDropdownOpen(open => !open);
    };

    const copyQueryToClipboard = () => {
        if (!queryResultPage?.id) {
            return;
        }

        setCopyDropdownOpen(false);

        navigator.clipboard.writeText(queryResultPage.query);
        openToast('Query copied to clipboard');
    };

    const copyResultsToClipboard = () => {
        if (!queryResultPage?.id) {
            return;
        }

        setCopyDropdownOpen(false);

        const csvRows = getCsvRows(queryResultPage.data.rows);

        navigator.clipboard.writeText(csvRows.join('\n'));
        openToast('Results copied to clipboard');
    };

    return (
        <Dropdown
            open={copyDropdownOpen}
            opener={(
                <Button
                    square
                    type="plain"
                    spacing="m-0 mr-2 p-1"
                    onClick={toggleCopyDropdown}
                    disabled={canCopy}
                    leftIcon="copy"
                    prefix="fal"
                >
                    Copy
                </Button>
            )}
            onClose={toggleCopyDropdown}
        >
            <List>
                <ListItem
                    label="Copy Query"
                    onClick={copyQueryToClipboard}
                    button
                />
                <ListItem
                    label="Copy Results"
                    onClick={copyResultsToClipboard}
                    disabled={!hasResults}
                    button
                />
            </List>
        </Dropdown>
    );
};

/** @type { (rows: Row[]) => string[] } */
function getCsvRows(rows) {
    const csvRows = [];

    const headers = Object.keys(rows[0]);
    csvRows.push(headers.join('\t'));

    rows.forEach((row) => {
        const values = headers.map((header) => {
            if (isObject(row[header]) && 'value' in row[header]) {
                return row[header].value;
            }

            return row[header];
        });

        csvRows.push(values.join('\t'));
    });

    return csvRows;
}

export default WorkbenchCopyDropdown;
