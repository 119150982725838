import styled from 'styled-components';

import {
    Layout,
    colors,
} from 'ui-components';

export const FullHeightLayout = styled(Layout)`
  height: 100%;
`;

export const SourceIcon = styled('img')`
  width: 24px;
  height: 24px;
  opacity: 0.8;
  margin-right: 15px;
  margin-left: 3px;
`;

export const Scroller = styled(Layout)`
    height: 50vh;
    width: 50%;
    flex-shrink: 0;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        width: 0;
        background: transparent;
        display: none;
    }
`;

export const DashboardThumbnail = styled('img')`
    width: 74px;
    height: 49px;
    border: 1px solid ${colors.secondaryInterface};
    border-radius: 5px;
    margin-top: -5px;
    margin-bottom: -5px;
`;

export const DialogWrapper = styled(Layout)`
    width: 90vw;
    max-width: 100%;
`;
