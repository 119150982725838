import { useState, useEffect } from 'react';

import { parseDefinition, recipeMatchesSources, runRecipeQuery } from '../../../../models/recipes';

import {
    useQuery,
} from '@tanstack/react-query';

import { fetchAllSources } from '../../../../models/sources';
import { fetchRecipes } from '../../../../models/recipes';
import { fetchSchemas } from '../../../../models/schemas';
import { useSourcePageContext } from '../../use-source-page/source-page-context';

/** @typedef { import('models/sources').SourceDefaults } SourceDefaults */
/** @typedef { import('models/sources').Source } Source */
/** @typedef { import('models/sources').SourceType } SourceType */
/** @typedef { import('models/recipes').Recipe } Recipe */
/** @typedef { import('models/recipes').RunErrorData } RunErrorData */
/** @typedef { import('models/recipes/types').Output } Output */

/**
 * @typedef { object } OutputReq
 * @prop { boolean } dryRunning
 * @prop { boolean } running
 * @prop { boolean } finished
 * @prop { Output } output
 * @prop { string } parsedDefinition
 * @prop { RunErrorData= } error
 */

/**
 * @typedef { object } Props
 * @prop {() => void} onClose
 * @prop {(query: string) => void} onQueryChanged
 */

/**
 * @typedef { function } useRecipesDialog
 * @arg { Props } props
 */
export default function useRecipesDialog({
    onClose,
    onQueryChanged,
}) {
    const { source } = useSourcePageContext();

    const [selectedSources, setSelectedSources] = useState(
        /** @type { Source[] } */([])
    );
    const [selectedRecipe, setSelectedRecipe] = useState(
        /** @type { Recipe | null } */(null)
    );
    const [selectedOutputs, setSelectedOutputs] = useState(
        /** @type { Output[] } */ ([])
    );
    const [outputReqs, setOutputReqs] = useState(
        /** @type { OutputReq[] } */ ([])
    );
    const [currentStep, setCurrentStep] = useState(
        /** @type { 'pick' | 'config' | 'results' } */('pick')
    );

    const [destinationSchema, setDestinationSchema] = useState(
        /** @type { Source['schema'] } */('')
    );

    const [destinationPrefix, setDestinationPrefix] = useState(
        /** @type { Source['destinationPrefix'] } */('')
    );

    const [recipeSearchTerm, setRecipeSearchTerm] = useState(
        /** @type { string } */('')
    );
    const [sourceSearchTerm, setSourceSearchTerm] = useState(
        /** @type { string } */('')
    );

    const running = outputReqs.length > 0 && outputReqs.some(req => req.running);
    const dryRunning = outputReqs.length > 0 && outputReqs.some(req => req.dryRunning);
    const finished = outputReqs.length > 0 && outputReqs.every(req => req.finished);

    const reset = () => {
        setSelectedRecipe(null);
        setSelectedOutputs([]);
        setOutputReqs([]);
        setCurrentStep('pick');
        setRecipeSearchTerm('');
        setSourceSearchTerm('');
    };

    const deselectErroredOutputs = () => {
        setSelectedOutputs((outputs) => outputs.filter((output) => {
            return !failedQueries.find(f => f.output === output);
        }));
    };

    /** @type { (type: 'recipe' | 'source', value: string) => void } */
    const updateSearch = (type, value) => {
        if (type === 'source') {
            setSourceSearchTerm(value);
            if (!source) {
                setSelectedSources([]);
                return;
            }
            const pageSourceWithDefaults = source && sources?.filter(s => s.id === source.id);
            if (pageSourceWithDefaults) {
                setSelectedSources([...pageSourceWithDefaults]);
            }
        }
        if (type === 'recipe') {
            setRecipeSearchTerm(value);
            setSelectedRecipe(null);
            setSelectedOutputs([]);
        }
    };

    /** @type { (recipe?: Recipe) => void } */
    const resetConfig = (recipe) => {
        const recipeToUse = recipe || selectedRecipe;
        setDestinationSchema(recipeToUse?.defaults?.destination?.schema || '');
        setDestinationPrefix(recipeToUse?.defaults?.destination?.prefix || '');
    };

    const {
        data: recipes,
        isLoading: recipesLoading,
    } = useQuery({
        queryKey: ['recipes'],
        queryFn: () => fetchRecipes(),
        staleTime: 200000, // 200s
    });

    const {
        data: sources,
        isLoading: sourcesLoading,
    } = useQuery({
        queryKey: ['sources', 'includeDefaults'],
        queryFn: () => {
            return fetchAllSources({ includeDefaults: true });
        },
        staleTime: 50000, // 50s
    });

    const {
        data: schemas,
        isLoading: schemasLoading,
    } = useQuery({ queryKey: ['schemas'], queryFn: fetchSchemas });

    const selectableRecipes = recipes?.filter((recipe) => {
        if (recipeSearchTerm) {
            const matchesTitle = recipe.title.toUpperCase().includes(
                recipeSearchTerm.toUpperCase()
            );
            const matchesName = recipe.title.toUpperCase().includes(
                recipeSearchTerm.toUpperCase()
            );
            const matchesOutput = recipe.outputs.find((output) => {
                return output.name.toUpperCase().includes(recipeSearchTerm.toUpperCase());
            });
            if (!matchesTitle && !matchesName && !matchesOutput) {
                return false;
            }
        }
        return recipeMatchesSources(recipe, selectedSources);
    }) || [];

    /** @type { (source: Source) => boolean } */
    const isSourceSelected = (source) => {
        return !!selectedSources.find(s => s.id === source.id);
    };

    const selectableSources = sources?.filter((s) => {

        if (s.id === source?.id || s.isFirstRun) {
            return false;
        }

        if (isSourceSelected(s)) {
            return true;
        }

        if (sourceSearchTerm) {
            const matchesTitle = s.title.toUpperCase().includes(sourceSearchTerm.toUpperCase());
            const matchesId = s.id.toUpperCase().includes(sourceSearchTerm.toUpperCase());
            if (!matchesTitle && !matchesId) {
                return false;
            }
        }

        const matchingRecipe = recipes?.find((recipe) => {
            return recipeMatchesSources(recipe, [...selectedSources, s]);
        });

        return !!matchingRecipe;
    });

    /** @type { (source: Source) => void } */
    const toggleSource = (source) => {
        if (isSourceSelected(source)) {
            setSelectedSources(selectedSources.filter(st => st !== source));
            return;
        }
        setSelectedSources([...selectedSources, source]);
    };

    /** @type { (source: Source) => string } */
    const getSourceSchemaAndDestination = (source) => {
        const dest = source.destination || source.destinationPrefix;
        if (!dest) {
            return source.schema;
        }
        return `${source.schema}.${dest}`;
    };

    const nextStep = () => {
        if (currentStep === 'pick') {
            setCurrentStep('config');
            return;
        }
        if (currentStep === 'config') {
            setCurrentStep('results');
            return;
        }
    };

    const backToPickStep = () => {
        setCurrentStep('pick');
        return;
    };

    /** @type { (outputReqs: OutputReq[], dry: boolean) => Promise<any> } */
    const runRecipes = async (outputReqs, dry) => {

        setOutputReqs(reqs => reqs.map((req) => {
            return { ...req, ...(dry ? { dryRunning: true } : { running: true }) };
        }));

        return Promise.all(outputReqs.map(async (queryReq) => {
            try {
                const query = await runRecipeQuery(queryReq.parsedDefinition, dry);
                setOutputReqs(qr => qr.map((req) => {
                    if (req.parsedDefinition === queryReq.parsedDefinition) {
                        return {
                            ...req,
                            ...(dry ? { dryRunning: false } : { running: false, finished: true }),
                        };
                    }
                    return req;
                }));
                return query;
            } catch (/** @type { any} */ error) {
                setOutputReqs(qr => qr.map((req) => {
                    if (req.parsedDefinition === queryReq.parsedDefinition) {
                        return {
                            ...req,
                            error: {
                                message: error.data?.message
                                    || 'Something went wrong running this recipe',
                                code: error.data?.code,
                                details: error.data?.details,
                            },
                            ...(dry ? { dryRunning: false } : { running: false }),
                        };
                    }
                    return req;
                }));
                return error;
            }
        }));
    };

    const run = async () => {
        if (selectedOutputs.length === 0 || !selectedRecipe) {
            return;
        }

        /** @type { OutputReq[] } */
        const newQueryReqs = selectedOutputs.map((o) => {
            const parsedDefinition = parseDefinition(
                o.definition,
                selectedRecipe,
                selectedSources.map(s => s.defaults),
                {
                    schema: destinationSchema,
                    prefix: destinationPrefix,
                },
            );
            return {
                dryRunning: true,
                running: false,
                finished: false,
                output: o,
                parsedDefinition: parsedDefinition,
            };
        });

        setOutputReqs(newQueryReqs);

        const dryRunRequests = await runRecipes(newQueryReqs, true);

        const allDone = dryRunRequests.every((req) => {
            return req.status === 'DONE';
        });

        if (allDone) {
            nextStep();
            runRecipes(newQueryReqs, false);
        }

    };

    const open = () => {
        if (selectedOutputs.length > 1 || !selectedRecipe) {
            throw new Error('Cant open more then one recipe in workbench a time');
        }
        const parsedDefinition = parseDefinition(
            selectedOutputs[0]?.definition,
            selectedRecipe,
            selectedSources.map(s => s.defaults),
            {
                schema: destinationSchema,
                prefix: destinationPrefix,
            },
        );

        onQueryChanged(parsedDefinition);
        onClose();
    };

    const close = () => {
        if (running) {
            return;
        }
        reset();
        onClose();
    };

    /** @type { (url: string) => void } */
    const goToRecipeDashboard = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    /** @type { (output: Output) => boolean } */
    const isOutputSelected = (output) => {
        return selectedOutputs.includes(output);
    };

    /** @type { (recipe: Recipe) => boolean } */
    const isRecipeSelected = (recipe) => {
        return selectedRecipe === recipe && selectedOutputs.length > 0;
    };

    /** @type { (output: Output) => void } */
    const toggleSelectedOutput = (output) => {
        const newSelectedOutputs = [...selectedOutputs];
        const index = newSelectedOutputs.indexOf(output);

        if (index === -1) {
            newSelectedOutputs.push(output);
        } else {
            newSelectedOutputs.splice(index, 1);
        }

        const parent = recipes?.find((recipe) => recipe.outputs.includes(output));
        if (parent && parent !== selectedRecipe) {
            setSelectedRecipe(parent);
            resetConfig(parent);
        }
        setSelectedOutputs(newSelectedOutputs);
    };

    /** @type { (recipe: Recipe) => void } */
    const toggleSelectedRecipe = (recipe) => {
        if (isRecipeSelected(recipe)) {
            setSelectedOutputs([]);
            setSelectedRecipe(null);
            return;
        }
        setSelectedRecipe(recipe);
        setSelectedOutputs(recipe.outputs);
        resetConfig(recipe);
    };

    /** @type { (recipe: Recipe) => boolean } */
    const isPartiallySelected = (recipe) => {
        const selectedOutputsInRecipe = selectedOutputs.filter(
            (output) => recipe.outputs.includes(output),
        );
        if (
            selectedOutputsInRecipe.length === recipe.outputs.length
                || selectedOutputsInRecipe.length === 0
        ) {
            return false;
        }
        return true;
    };

    const runningQueryName = outputReqs.find(q => q.running)?.output.name;

    /** @type {OutputReq[]} */
    const failedQueries = outputReqs.filter(req => 'error' in req);

    /** @type {OutputReq[]} */
    const successfulQueries = outputReqs.filter(req => !req.error);

    const runDisabled = !destinationPrefix
        || !destinationSchema
        || !selectedRecipe
        || !selectedOutputs.length
        || failedQueries.length > 0;


    // Reset reqs when config changes
    useEffect(() => {
        setOutputReqs([]);
    }, [destinationSchema, destinationPrefix, selectedOutputs, selectedRecipe]);

    /**
     * When the recipes dialog loads we cant gurantee that the
     * source we are passed has loaded `defaults`
     * So we replace the source we are passed with the version we know has defaults
     */
    useEffect(() => {
        const matchingSourceWithDefaults = sources?.find(s => s.id === source?.id);
        if (matchingSourceWithDefaults) {
            setSelectedSources([matchingSourceWithDefaults]);
        }
    }, [source, sources, setSelectedSources]);

    return {
        source,
        recipes,
        selectedRecipe,
        selectedOutputs,
        outputReqs,
        running,
        dryRunning,
        finished,
        runningQueryName,
        successfulQueries,
        failedQueries,
        selectableRecipes,
        selectableSources,
        selectedSources,
        destinationPrefix,
        destinationSchema,
        runDisabled,
        sourceSearchTerm,
        recipeSearchTerm,
        loading: sourcesLoading || recipesLoading || schemasLoading,
        schemas,
        updateSearch,
        resetConfig,
        setDestinationPrefix,
        setDestinationSchema,
        reset,
        run,
        open,
        close,
        getSourceSchemaAndDestination,
        goToRecipeDashboard,
        isOutputSelected,
        isRecipeSelected,
        toggleSelectedOutput,
        toggleSelectedRecipe,
        isPartiallySelected,
        toggleSource,
        isSourceSelected,
        deselectErroredOutputs,

        currentStep,
        nextStep,
        backToPickStep,
    };
}
