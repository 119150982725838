import React from 'react';

import {
    Divider,
    Icon,
    Input,
    Layout,
    ListItem,
    Switch,
    Typography,
} from 'ui-components';

import {
    FullHeightLayout,
    Scroller,
    SourceIcon,
    DashboardThumbnail,
} from './shared';

import { cdnDataSourceIcon } from '../../../../lib/cdn-icon';

/** @typedef { import('models/sources').SourceDefaults } SourceDefaults */
/** @typedef { import('models/sources').Source } Source */
/** @typedef { import('models/recipes').Recipe } Recipe */
/** @typedef { import('models/recipes/types').Output } Output */
/** @typedef { import('./use-recipes-dialog').OutputReq } OutputReq */
/** @typedef { import('./use-recipes-dialog').default } useRecipesDialog */

/** @typedef { ReturnType<useRecipesDialog> } useRecipes */

/**
 * @typedef { object } Props
 * @prop { Source | undefined } source
 * @prop { useRecipes['getSourceSchemaAndDestination'] } getSourceSchemaAndDestination
 * @prop { useRecipes['selectableSources'] } selectableSources
 * @prop { useRecipes['isSourceSelected'] } isSourceSelected
 * @prop { useRecipes['toggleSource'] } toggleSource
 * @prop { useRecipes['selectableRecipes'] } selectableRecipes
 * @prop { useRecipes['isRecipeSelected'] } isRecipeSelected
 * @prop { useRecipes['toggleSelectedRecipe'] } toggleSelectedRecipe
 * @prop { useRecipes['isPartiallySelected'] } isPartiallySelected
 * @prop { useRecipes['isOutputSelected'] } isOutputSelected
 * @prop { useRecipes['toggleSelectedOutput'] } toggleSelectedOutput
 * @prop { useRecipes['goToRecipeDashboard'] } goToRecipeDashboard
 * @prop { useRecipes['sourceSearchTerm'] } sourceSearchTerm
 * @prop { useRecipes['recipeSearchTerm'] } recipeSearchTerm
 * @prop { useRecipes['updateSearch'] } updateSearch
 * @prop { useRecipes['selectedSources'] } selectedSources
 */

/** @type { React.FC<Props> } */
export const RecipesPickStep = ({
    source,
    selectableSources,
    selectableRecipes,
    sourceSearchTerm,
    recipeSearchTerm,
    selectedSources,
    updateSearch,
    getSourceSchemaAndDestination,
    isSourceSelected,
    toggleSource,
    isRecipeSelected,
    toggleSelectedRecipe,
    isPartiallySelected,
    isOutputSelected,
    toggleSelectedOutput,
    goToRecipeDashboard,
}) => {

    /** @type { (e: React.ChangeEvent<HTMLInputElement>) => void } */
    const onChangeSearch = (e) => {
        updateSearch('source', e.target.value);
    };

    return (
        <Layout flex>
            <Scroller width="50" spacing="-mb-5 py-3">

                <Input
                    noFloatLabel
                    width="100"
                    placeholder="Connectors"
                    value={sourceSearchTerm}
                    onChange={onChangeSearch}
                    disabled={!sourceSearchTerm && selectableSources?.length === 0}
                    spacing="p-0 pr-4 mt-1 mb-3"
                    prefix={<Icon icon="search" spacing="-mr-1 pr-0" />}
                />

                {!source && selectableSources?.length === 0 && (
                    <FullHeightLayout
                        flex
                        alignItems="center"
                        width="100"
                        height="100"
                        spacing="pb-6"
                    >
                        <Typography
                            color="secondaryText"
                            variant="body1"
                            align="center"
                            width="100"
                            spacing="mb-9 mx-3"
                        >
                            No connectors with recipes{' '}
                            {sourceSearchTerm && `matching "${sourceSearchTerm}" `}
                            found in warehouse{' '}
                        </Typography>
                    </FullHeightLayout>
                )}

                {source && (
                    <ListItem
                        label={source.title}
                        color="secondaryText"
                        spacing="pl-0 py-3"
                        key={source.id}
                    >
                        <Layout flex alignItems="center">
                            <div onClick={e => e.stopPropagation()}>
                                <Switch
                                    type="checkbox"
                                    label={''}
                                    checked={true}
                                    disabled={true}
                                />
                            </div>
                            <Layout flex alignItems="center" spacing="ml-2">
                                <SourceIcon
                                    src={cdnDataSourceIcon(source.type)}
                                />
                                <Layout>
                                    <Typography color="text" spacing="mb-0" variant="body1">
                                        {source.title}
                                    </Typography>
                                    <Typography color="secondaryText" variant="caption">
                                        {getSourceSchemaAndDestination(source)}
                                    </Typography>
                                </Layout>
                            </Layout>
                        </Layout>
                    </ListItem>
                )}

                {selectableSources?.map((source) => {
                    if (!source) {
                        return null;
                    }
                    const currentPageMatchesSourceType = source.id === source.type;
                    const selected = isSourceSelected(source);
                    const toggle = () => toggleSource(source);
                    return (
                        <ListItem
                            label={source.title}
                            color="secondaryText"
                            spacing="pl-0 py-3"
                            key={source.id}
                        >
                            <Layout flex alignItems="center">
                                <div onClick={e => e.stopPropagation()}>
                                    <Switch
                                        type="checkbox"
                                        label={''}
                                        checked={currentPageMatchesSourceType || selected}
                                        disabled={currentPageMatchesSourceType}
                                        onChange={toggle}
                                    />
                                </div>
                                <Layout flex alignItems="center" spacing="ml-2">
                                    <SourceIcon
                                        src={cdnDataSourceIcon(source.type)}
                                    />
                                    <Layout>
                                        <Typography color="text" spacing="mb-0" variant="body1">
                                            {source.title}
                                        </Typography>
                                        <Typography
                                            color="secondaryText"
                                            spacing="mb-0"
                                            variant="caption"
                                        >
                                            {getSourceSchemaAndDestination(source)}
                                        </Typography>
                                    </Layout>
                                </Layout>
                            </Layout>
                        </ListItem>
                    );
                })}
            </Scroller>

            <Divider vertical spacing="m-0 -mb-5" />

            <Scroller width="50" spacing="py-3 ml-4 -mb-5">

                {selectedSources.length ? (
                    <>
                        <Input
                            noFloatLabel
                            width="100"
                            placeholder="Recipes"
                            value={recipeSearchTerm}
                            onChange={e => updateSearch('recipe', e.target.value)}
                            spacing="p-0 pr-4 mt-1 mb-3"
                            prefix={<Icon icon="search" spacing="-mr-1 pr-0" />}
                        />

                        {selectableRecipes.length === 0 && (
                            <FullHeightLayout flex alignItems="center" width="100" height="100">
                                <Typography
                                    color="secondaryText"
                                    variant="body1"
                                    align="center"
                                    width="100"
                                    spacing="mb-7"
                                >
                                    No recipes for{' '}
                                    {selectedSources.map(s => s.title).join(' & ')}{' '}
                                    matching &quot;{recipeSearchTerm}&quot;
                                </Typography>
                            </FullHeightLayout>
                        )}

                        {selectableRecipes.map(recipe => (
                            <React.Fragment key={recipe.name}>
                                <ListItem
                                    label={recipe.title || recipe.name}
                                    color="secondaryText"
                                    spacing="pl-0 pt-1 pb-0"
                                >
                                    <div onClick={e => e.stopPropagation()}>
                                        <Switch
                                            type="checkbox"
                                            label={''}
                                            checked={isRecipeSelected(recipe)}
                                            onChange={() => toggleSelectedRecipe(recipe)}
                                            spacing="mr-1 -ml-2 p-1"
                                            indeterminate={isPartiallySelected(recipe)}
                                        />
                                    </div>
                                    <Typography color="text" variant="body1" spacing="my-0">
                                        {recipe.title || recipe.name}
                                    </Typography>
                                </ListItem>

                                {recipe.dashboards && (
                                    <DashboardCallout
                                        recipe={recipe}
                                        goToRecipeDashboard={goToRecipeDashboard}
                                    />
                                )}

                                {recipe.outputs.map(output => {
                                    const { name } = output;
                                    const id = [recipe.name, name].join('.');
                                    const selected = isOutputSelected(output);

                                    return (
                                        <ListItem
                                            key={id}
                                            label={name}
                                            spacing="pt-1 pb-0"
                                        >
                                            <div onClick={e => e.stopPropagation()}>
                                                <Switch
                                                    type="checkbox"
                                                    checked={selected}
                                                    label={''}
                                                    onChange={
                                                        () => toggleSelectedOutput(output)
                                                    }
                                                    spacing="mr-1 -ml-3 p-1"
                                                />
                                            </div>

                                            <Typography color="text">
                                                {name}
                                            </Typography>

                                        </ListItem>
                                    );
                                })}
                            </React.Fragment>
                        ))}
                    </>
                ) : (
                    <FullHeightLayout flex alignItems="center" width="100" height="100">
                        <Typography
                            color="secondaryText"
                            variant="body1"
                            align="center"
                            width="100"
                        >
                            Select one or more connectors
                        </Typography>
                    </FullHeightLayout>
                )}
            </Scroller>
        </Layout>
    );
};

/**
 * @typedef { object } DashboardCalloutProps
 * @prop { Recipe } recipe
 * @prop { (url: string) => void } goToRecipeDashboard
 */

/** @type { React.FC<DashboardCalloutProps> } */
const DashboardCallout = ({
    recipe,
    goToRecipeDashboard,
}) => {
    const firstDashboard = recipe.dashboards?.[0];
    if (!firstDashboard?.url) {
        return null;
    }
    const url = firstDashboard.url;
    return (
        <ListItem
            label={recipe.title || recipe.name}
            button
            onClick={() => goToRecipeDashboard(url)}
        >
            <Layout width="100" flex justifyContent="space-between">
                <Layout flex alignItems="center">
                    <Icon
                        icon="chart-pie-alt"
                        color="secondaryText"
                        spacing="mr-4"
                    />
                    <Layout
                        flex
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <Typography color="text">
                            {recipe.title || recipe.name}
                        </Typography>
                        <Typography
                            variant="caption"
                            color="secondaryText"
                            spacing="m-0"
                        >
                            Recipe Dashboard
                        </Typography>
                    </Layout>
                </Layout>
                <Layout>
                    <DashboardThumbnail
                        src={firstDashboard.thumbnail}
                    />
                </Layout>
            </Layout>
        </ListItem>
    );
};


export default RecipesPickStep;
