import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
    Routes,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from 'react-router-dom';
import { getCurrentRoute } from '../../lib/get-current-route';

import config from '../../../config';

/** @typedef { import('../../app/__types').Session } Session */
/** @typedef { import('./__types').Tags } Tags */
/** @typedef { 'Fatal' | 'Error' | 'Warning' | 'Log' | 'Info' | 'Debug' } Severity */
/** @typedef { import('@sentry/react').SeverityLevel } SeverityLevel */

/** @type { Record<Severity, SeverityLevel> } */
const Severity = {
    Fatal: 'fatal',
    Error: 'error',
    Warning: 'warning',
    Log: 'log',
    Info: 'info',
    Debug: 'debug',
};

const tagPrefix = 'panoply.';

/**
 * Sets up sentry before we start using it.
 */
function setupSentry() {
    const dsn = isSafari() ? undefined : config.sentry.dsn;

    Sentry.init({
        dsn,
        enabled: config.isProd,
        release: config.release,
        environment: config.env,
        attachStacktrace: true,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 0.5, // temporary
        // Control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [...config.hostname, /^\//],
        integrations: [
            Sentry.replayIntegration(),
            // See docs for support of different versions of variation of react router
            // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
                beforeStartSpan: (context) => {
                    const currentRoute = getCurrentRoute();

                    if (currentRoute?.path) {
                        return {
                            ...context,
                            name: currentRoute.path,
                        };
                    }

                    return context;
                },
            }),
        ],
        beforeSend(event, hint) {
            const ignoreError = shouldIgnoreError(
                hint.originalException,
                config.sentry.ignoreErrorsWithMessage,
            );

            if (ignoreError) {
                return null;
            }

            const eventWithTags = addDefaultTagsToEvent(event, hint);

            return eventWithTags;
        },

        // To check out what other common errors/urls can be ignored
        // please visit: https://gist.github.com/impressiver/5092952/
        denyUrls: [
            // Ignore Google flakiness
            /\/(gtm|ga|analytics)\.js/i,
        ],
    });
}

/**
 * @typedef { object } ScopeProps
 *
 * @prop { Session['user'] } user
 * @prop { Session['database'] } [database]
 */

/** @type { (props: ScopeProps) => void } */
function configureScope({ user, database }) {
    const { email } = user;
    Sentry.getCurrentScope().setUser({ email, database });
}


/** @type { <T extends Sentry.Event>(event: T, hint?: Sentry.EventHint) => T } */
function addDefaultTagsToEvent(event, hint) {
    const modifiedEvent = { ...event };
    const currentRoute = getCurrentRoute();

    // Dont use setTags here because we only want to affect this event
    // Not subsequent events
    modifiedEvent.tags = modifiedEvent.tags || {};
    if (currentRoute) {
        modifiedEvent.tags[tagPrefix + 'page'] = currentRoute.name;
        modifiedEvent.tags[tagPrefix + 'path'] = currentRoute.path || '/';
    }
    if (hint?.originalException?.status) {
        modifiedEvent.tags[tagPrefix + 'status'] = hint.originalException.status;
    }
    return modifiedEvent;
}

function isSafari() {
    const ua = navigator.userAgent.toLowerCase();

    return ua.includes('safari') && !ua.includes('chrome');
}

/** @type { (error: Error | ErrorObject, errorsToIgnore: string[]) => boolean } */
function shouldIgnoreError(error, errorsToIgnore) {
    const inIgnoreList = error
        && error.message
        && errorsToIgnore.some(msg => error.message.includes(msg));

    const status = error
        ? (error.status || error.statusCode)
        : 0;

    const hasClientError = status >= 400 && status <= 499;

    return inIgnoreList || hasClientError;
}

/**
 * Add a new breadcrumb to the trail of events
 *
 * @param {string} message
 * @param {object=} data
 */
function addBreadcrumb(message, data) {
    Sentry.addBreadcrumb({
        level: Severity.Info,
        category: 'message',
        message,
        data,
    });
}


/**
 * @typedef {object} ErrorData
 * @prop {string} message
 */

/**
 * @typedef {object} ErrorObject
 * @prop {ErrorData} data
 * @prop {boolean} ok
 * @prop {string} status
 */

/** @type { (error: Error | ErrorObject, data: object) => void } */
function captureError(error, data) {
    const errorMessage = error?.message || error?.data?.message || 'Unknown error occurred';
    Sentry.withScope((scope) => {
        scope.setExtra('data', data);

        // group errors by the current url path and
        // the first (50) characters of the error message
        scope.setFingerprint([
            createTemplateFromPath(window.location.pathname),
            errorMessage?.substring(0, 51),
        ]);

        const errorToCapture = error instanceof Error
            ? error
            : new Error(errorMessage);

        errorToCapture.status = error.status;
        Sentry.captureException(errorToCapture);
    });
}

/**
 * Tags are set for the current session
 * and will be sent with every subsequent event in that session
 * @type { (tags: Tags) => void }
 */
function setTags(tags) {
    for (const [key, value] of Object.entries(tags)) {
        Sentry.setTag(tagPrefix + key, value);
    }
}

/**
 * Create a template string from a (URL) path. The template replaces dynamic
 * parameters with the string `:id`.
 *
 * This allows us to group similar errors in sentry.
 * @example
 * If the error `Undefined variable foo` was thrown on pages `sources/a123`
 * and `sources/b456` both errors will be grouped together in sentry as both
 * pages paths fit the same template /source/:id
 * @param { string } path
 */
function createTemplateFromPath(path) {
    const idRegex = /(\d+\w+|\w+\d+)/;
    return path.replace(idRegex, ':id');
}

// We must setup the sentry client before we can use it
setupSentry();

const withProfiler = Sentry.withProfiler;
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export {
    setTags,
    captureError,
    addBreadcrumb,
    configureScope,
    withProfiler,
    SentryRoutes,
};
