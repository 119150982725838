import React, { useState } from 'react';

import {
    Card,
    Typography,
    Table,
} from 'ui-components';

import UsersTableHead from './users-table-head';
import UsersTableBody from './users-table-body';

/** @typedef { import('./__types').User } User */
/** @typedef { import('./__types').Invite } Invite */
/** @typedef { import('./__types').Team } Team */
/** @typedef { import('./__types').State } State */
/** @typedef { import('./__types').Sorting } Sorting */

const UsersNotFound = () => (
    <Typography color="secondaryText" italic>
        This team does not have any members yet.
    </Typography>
);

/**
 * @typedef { object } Props
 *
 * @prop { State } state
 * @prop { Team } team
 * @prop { (User | Invite)[] } users
 * @prop { boolean } resendLoading
 * @prop { (u: User, t: Team) => void } onUserDelete
 * @prop { (i: Invite, t: Team) => void } onResendInvite
 */

/** @type { React.FC<Props> } */
const UsersTable = ({ users, ...props }) => {

    /** @type { Sorting } */
    const initialSorting = {
        order: 'asc',
        orderBy: 'email',
    };

    const [sorting, setSorting] = useState(initialSorting);

    if (!users.length) {
        return (
            <Card>
                <UsersNotFound />
            </Card>
        );
    }

    const tableHeadProps = {
        sorting,
        setSorting,
    };

    const tableBodyProps = {
        users,
        sorting,
        ...props,
    };

    return (
        <Card contentSpacing="p-0">
            <Table>
                <UsersTableHead {...tableHeadProps} />
                <UsersTableBody {...tableBodyProps} />
            </Table>
        </Card>
    );
};

export default UsersTable;
