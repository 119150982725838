import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    Button,
    Dropdown,
    List,
    ListItem,
} from 'ui-components';

import { useSession } from './../context-providers';

/** @typedef { import('models/queries/__types').QueryResult } QueryResult */
/** @typedef { import('./__types').WorkbenchParams } WorkbenchParams */

/**
 * @typedef { object } Props
 * @prop { QueryResult } [queryResultPage]
 * @prop { boolean } isSelectQuery
 * @prop { string } [queryError]
 * @prop { () => void } toggleSaveAsDialog
 * @prop { () => void } toggleSaveDialog
 */

/** @type { React.FC<Props> } */
const WorkbenchSaveDropdown = ({
    queryResultPage,
    isSelectQuery,
    queryError,
    toggleSaveAsDialog,
    toggleSaveDialog,
}) => {
    /** @type { WorkbenchParams } */
    const { id, type, schema } = useParams();

    const [session] = useSession();
    const { scopes } = session;

    const canSave = scopes.includes('reports:manage')
        && !!queryResultPage?.data?.rows?.length
        && isSelectQuery
        && !queryError;

    const canEdit = !schema && id && type && ['reports', 'views'].includes(type);

    const [saveDropdownOpen, setSaveDropdownOpen] = useState(false);

    const toggleSaveDropdown = () => {
        setSaveDropdownOpen(open => !open);
    };

    const openSaveAsDialog = () => {
        setSaveDropdownOpen(false);
        toggleSaveAsDialog();
    };

    const openSaveDialog = () => {
        setSaveDropdownOpen(false);
        toggleSaveDialog();
    };

    return (
        <Dropdown
            open={saveDropdownOpen}
            opener={(
                <Button
                    square
                    type="plain"
                    spacing="m-0 mr-2 p-1"
                    onClick={toggleSaveDropdown}
                    disabled={!canSave}
                    leftIcon="save"
                >
                    Save
                </Button>
            )}
            onClose={toggleSaveDropdown}
        >
            <List>
                <ListItem
                    label="Save Changes"
                    disabled={!canEdit}
                    onClick={openSaveDialog}
                    button
                />
                <ListItem
                    label="Save As New"
                    onClick={openSaveAsDialog}
                    button
                />
            </List>
        </Dropdown>
    );
};

export default WorkbenchSaveDropdown;
