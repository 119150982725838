import { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handleResize = useRef(debounce(() => {
        window.requestAnimationFrame(() => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        });
    }, 300));

    useEffect(() => {
        window.addEventListener('resize', handleResize.current);

        return () => {
            window.removeEventListener('resize', handleResize.current);
        };
    }, []);

    return windowSize;
};

export default useWindowSize;
