const React = require('react');
const { useState, useEffect } = require('react');
const {
    Card,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Icon,
    Dialog,
    Typography,
    Tooltip,
} = require('ui-components');
import PageHeader from '../../shared/page-header';
import { del } from '../../lib/ajax';
import {
    HeaderWrapper,
    SuccessIconCircle,
    ErrorIconCircle,
    WarningIconCircle,
} from '../mfa-page/shared';


/**
 * @typedef { object } User
 * @prop { string } username
 * @prop { string } fname
 * @prop { string } lname
 * @prop { string } mfa
 * @prop { string } email
 * @prop { string } admin
 */


/**
 * @param { { mfaUsers: User[] } } props
 */
const MfaTable = ({ mfaUsers }) => {
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const openDialog = () => setOpen(true);
    const closeDialog = () => setOpen(false);

    const onOpen = (user) => {
        setUserToDelete(user);
        openDialog();
    };

    const onClose = (success) => {
        if (success) {
            const newUsersState = users.map(u => {
                if (u.id === userToDelete.id) {
                    u.mfa = false;
                }
                return u;
            });

            setUsers(newUsersState);
        }
        closeDialog();
    };

    useEffect(() => {
        if (mfaUsers?.length) {
            setUsers(mfaUsers);
        }
    }, [mfaUsers]);

    return (
        <>
            <PageHeader title="Multi Factor Authentication" />

            <Typography variant="body1" spacing="-mt-4">
                View MFA configuration for all users. Detach an MFA device when needed.
            </Typography>

            <Card contentSpacing="p-0">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell>Username</TableCell>
                            <TableCell>Full Name</TableCell>
                            <TableCell>MFA</TableCell>
                            <TableCell> </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {users.length && users.map(user => (
                            <TableRow key={user.id}>
                                <TableCell component="th" scope="row">{user.email}</TableCell>
                                <TableCell component="th" scope="row">
                                    {user.username}
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row">
                                    {user.fname + ' ' + user.lname}
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row">
                                    {user.mfa ? 'Enabled' : 'Disabled'}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Tooltip content="Detach MFA">
                                        <Button
                                            type="plain"
                                            spacing="m-0 py-2 -mr-2"
                                            onClick={() => onOpen(user)}
                                            disabled={!user.mfa}
                                        >
                                            <Icon icon="minus-circle" />
                                        </Button>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Card>
            <DetachDialog
                key={`detach-dialog-${open}`}
                open={open}
                onClose={onClose}
                user={userToDelete}
            />
        </>

    );
};
const DetachDialog = ({ open, onClose, user }) => {
    const [loading, setLoading] = useState(false);
    const [finished, setFinished] = useState(false);
    const [error, setError] = useState(null);
    const [hideDetach, setHideDetach] = useState(false);

    const [actualSuccess, setActualSuccess] = useState(false);

    const closeDialog = React.useCallback(() => {
        onClose(actualSuccess);
    }, [onClose, actualSuccess]);

    const deleteUserFactor = async () => {
        await del(`/mfa/totp/${user.id}`);
    };

    const detachDevice = async () => {
        try {
            setLoading(true);
            await deleteUserFactor();
            setActualSuccess(true);
        } catch (error) {
            setError(error);
            setActualSuccess(false);
        } finally {
            setHideDetach(true);
            setLoading(false);
            setFinished(true);
        }
    };


    return (
        <Dialog
            key={`detach-dialog-${open}`}
            isOpen={open}
            onClose={closeDialog}
            actions={
                (
                    <>
                        <Button
                            onClick={closeDialog}
                            type="plain"
                            disabled={loading}
                        >
                            Close
                        </Button>
                        {!hideDetach && <Button
                            onClick={detachDevice}
                            color="primary"
                            spacing="mr-0"
                            loading={loading}>
                            Detach
                        </Button>}
                    </>
                )
            }
        >
            {finished ? (
                actualSuccess ? (
                    <HeaderWrapper>
                        <SuccessIconCircle>
                            <Icon icon="check" size="lg" color="white" />
                        </SuccessIconCircle>
                        <Typography color="text" variant="h6" align="center" spacing="mb-4 mt-4">
                            Device has successfully detached
                        </Typography>
                        <Typography color="text" variant="body1" align="center">
                            The MFA for {user.email} will be disabled until a device is re-attached
                        </Typography>
                    </HeaderWrapper>
                ) : (
                    <HeaderWrapper>
                        <ErrorIconCircle>
                            <Icon icon="exclamation-circle" size="lg" color="white" />
                        </ErrorIconCircle>
                        <Typography color="text" variant="h6" align="center" spacing="mb-4 mt-4">
                            There was an error detaching the device
                        </Typography>
                        <Typography color="text" variant="body1" align="center">
                            {error?.message}
                        </Typography>
                    </HeaderWrapper>
                )
            ) : (
                <HeaderWrapper>
                    <WarningIconCircle>
                        <Icon icon="exclamation-circle" size="lg" color="white" />
                    </WarningIconCircle>
                    <Typography color="text" variant="h6" align="center" spacing="mb-4 mt-2">
                        {loading ? 'Detaching...' : 'Detach authenticator app?'}
                    </Typography>
                    <Typography color="text" variant="body1" align="center">
                        In order to attach the device again,
                        the user will have to attach it from scratch
                    </Typography>
                </HeaderWrapper>
            )}
        </Dialog>

    );
};

export default MfaTable;
