import React, { useState, useCallback } from 'react';

import styled from 'styled-components';

import {
    Layout,
    Divider,
    Button,
    Input,
    useInput,
    Typography,
    breakpoints,
    colors,
} from 'ui-components';

import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useStripe,
    useElements,
    AddressElement,
} from '@stripe/react-stripe-js';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { reportError } from '../../lib/error-reporter';
import isValidEmail from '../../lib/is-valid-email';


/** @typedef { import('@stripe/stripe-js').StripeElementChangeEvent } StripeChangeEvent */
/** @typedef { import('@stripe/stripe-js').StripeAddressElementChangeEvent } AddressChangeEvent */
/** @typedef { import('@stripe/stripe-js').StripeCardNumberElementChangeEvent } NumberChangeEvent */
/** @typedef { import('@stripe/stripe-js').StripeCardExpiryElementChangeEvent } ExpiryChangeEvent */
/** @typedef { import('@stripe/stripe-js').StripeCardCvcElementChangeEvent } CvcChangeEvent */
/** @typedef { import('./use-billing').Subscription } Subscription */
/** @typedef { import('./use-billing').Address } Address */

/**
 * @typedef { object } EmailElementProps
 * @prop { string } name
 * @prop { string } label
 * @prop { string } value
 * @prop { (e: React.ChangeEvent<HTMLInputElement>) => void } onChange
 * @prop { boolean } error
 * @prop { (e: React.KeyboardEvent<HTMLInputElement>) => void } onKeyDown
 * @prop { string } [spacing]
 */

const RECAPTCHA_ACTION = 'payment_form';

/**
 * @typedef { object } Elements
 * @prop { boolean } cardNumber
 * @prop { boolean } cardExpiry
 * @prop { boolean } cardCvc
 */

/**
 * @typedef { object } State
 * @prop { string } error
 * @prop { string } captcha
 * @prop { Elements } elements
 * @prop { boolean } loading
 */

const initialState = {
    error: '',
    captcha: '',
    elements: {
        cardNumber: false,
        cardExpiry: false,
        cardCvc: false,
    },
    loading: false,
};

const typographyProps = {
    variant: /** @type { 'subtitle2' } */ ('subtitle2'),
    component: 'label',
    weight: /** @type { 'normal' } */ ('normal'),
    color: 'text',
};

const options = {
    style: {
        base: {
            'color': '#333',
            'fontFamily': 'Roboto, sans-serif',
            'fontSize': '16px',
            'fontWeight': 400,
            'fontSmoothing': 'antialiased',
            'lineHeight': '19px',
            '::placeholder': {
                color: '#b8b8b8',
            },
        },
        invalid: {
            color: colors.error.main,
        },
    },
    classes: {
        focus: 'focus',
        empty: 'empty',
        invalid: 'invalid',
    },
};

const emailInputProps = {
    width: 100,
    spacing: 'p-0 mt-1',
    label: 'you@email.com',
    noFloatLabel: true,
};

/** @param { Address } address */
function getCardAddress(address) {
    return {
        address_country: address.country,
        address_city: address.city || '',
        address_line1: address.line1 || '',
        address_line2: address.line2 || '',
        address_state: address.state || '',
        address_zip: address.postal_code || '',
    };
}

/** @param { Elements } elements */
function hasValidCard(elements) {
    return Object.values(elements).every(Boolean);
}

/**
 * @typedef { object } NumberElementProps
 * @prop { (e: NumberChangeEvent) => void } onChange
 * @prop { string } [spacing]
 */

/** @type { React.FC<NumberElementProps> } */
const NumberElement = ({ onChange, ...props }) => (
    <Typography {...typographyProps} {...props}>
        Card number
        <StyledCardNumberElement onChange={onChange} options={options} />
    </Typography>
);

/**
 * @typedef { object } AddressElementProps
 * @prop { string } [name]
 * @prop { Address } [address]
 * @prop { (e: AddressChangeEvent) => void } onChange
 * @prop { string } [spacing]
 */

/** @type { React.FC<AddressElementProps> } */
const BillingAddressElement = ({ name, address, onChange, ...props }) => (
    <Layout>
        <Typography variant="h6" {...props}>
            Billing Information
        </Typography>
        <Typography>
            <AddressElement
                onChange={onChange}
                options={{
                    mode: 'billing',
                    defaultValues: {
                        name,
                        address,
                    },
                }}
            />
        </Typography>
    </Layout>
);

/**
 * @typedef { object } ExpiryElementProps
 * @prop { (e: ExpiryChangeEvent) => void } onChange
 * @prop { string } [spacing]
 * @prop { string } [width]
 */

/** @type { React.FC<ExpiryElementProps> } */
const ExpiryElement = ({ onChange, ...props }) => (
    <Typography {...typographyProps} {...props}>
        Expiration date
        <StyledCardExpiryElement onChange={onChange} options={options} />
    </Typography>
);

/**
 * @typedef { object } CvcElementProps
 * @prop { (e: CvcChangeEvent) => void } onChange
 * @prop { string } [spacing]
 * @prop { string } [width]
 */

/** @type { React.FC<CvcElementProps> } */
const CVCElement = ({ onChange, ...props }) => (
    <Typography {...typographyProps} {...props}>
        CVC
        <StyledCardCvcElement onChange={onChange} options={options} />
    </Typography>
);

/** @type { React.FC<EmailElementProps> } */
const EmailInput = ({ spacing, ...props }) => (
    <Typography {...typographyProps} spacing={spacing}>
        Invoice email address
        <Input {...props} {...emailInputProps} />
    </Typography>
);

/**
 * @typedef { object } ErrorMessageProps
 * @prop { string } error
 * @prop { string } [spacing]
 * @prop { 'right' } [align]
 */

/** @type { React.FC<ErrorMessageProps> } */
const ErrorMessage = ({ error, ...props }) => (
    <Typography color="error" inline {...props}>
        {error}
    </Typography>
);

const GooglePrivacy = () => (
    <a href="https://policies.google.com/privacy" target="__blank">Privacy Policy</a>
);
const GoogleTerms = () => (
    <a href="https://policies.google.com/terms" target="__blank">Terms of Service</a>
);

/**
 * @typedef { object } CaptchaMessageProps
 * @prop { string } [spacing]
 * @prop { 'left' } [align]
 */

/** @type { React.FC<CaptchaMessageProps> } */
const CaptchaMessage = props => (
    <Typography variant="caption" color="secondaryText" inline {...props}>
        This site is protected by reCAPTCHA and the
        Google&nbsp;<GooglePrivacy />&nbsp;and&nbsp;<GoogleTerms />&nbsp;apply.
    </Typography>
);

const appearance = {
    rules: {
        '.Label': {
            fontWeight: '400',
            marginBottom: '0.55rem',
            fontFamily: 'Roboto, sans-serif',
            fontSize: '0.875rem',
        },
        '.Input': {
            marginBottom: '0.5rem',
            boxShadow: 'none',
            border: '1px solid rgba(0, 0, 0, 0.23)',
        },
        '.Input:focus': {
            borderColor: `${colors.primary.main}`,
            boxShadow: 'none',
            outline: 'none',
        },
    },
};

/**
 * @typedef { object } Props
 * @prop { string } [name]
 * @prop { string } [email]
 * @prop { Address } [address]
 * @prop { () => void } onCancel
 * @prop { (subscription: Subscription) => void } onUpdate
 */

/** @type { React.FC<Props> } */
const PaymentForm = ({ name, email, address, onCancel, onUpdate }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [state, setState] = useState(initialState);
    const [hasValidAddress, setHasValidAddress] = useState(false);
    const emailInput = useInput('Invoice Email Address', email);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { loading } = state;

    // Usually we apply styles to our element using styled components.
    // however, for the AddressElement we need to apply the styles using the elements.update method.
    // https://stripe.com/docs/elements/appearance-api
    elements?.update({ appearance });

    // The email field is allowed to be empty
    const emailIsValid = emailInput.value
        ? isValidEmail(emailInput.value)
        : true;

    const disabled = !stripe || !elements || loading
        || !executeRecaptcha
        || !hasValidCard(state.elements)
        || !hasValidAddress
        || !emailIsValid;

    /** @type { (newState: Partial<typeof initialState>) => void } */
    const updateState = useCallback((newState) => {
        setState({ ...state, ...newState });
    }, [state]);

    /** @type { (e: StripeChangeEvent | AddressChangeEvent) => void } */
    const onChange = useCallback(({ elementType, complete, ...e }) => {
        updateState({
            error: 'error' in e && e.error ? e.error.message : '',
            elements: { ...state.elements, [elementType]: complete },
        });
    }, [state.elements, updateState]);

    const onClick = useCallback(async () => {
        if (!stripe || !elements || !executeRecaptcha) {
            return;
        }

        updateState({ error: '', loading: true });

        try {
            const cardElement = elements.getElement(CardNumberElement);

            if (cardElement == null) {
                throw new Error('Card number element is missing');
            }

            const addressElement = elements.getElement(AddressElement);

            if (addressElement == null) {
                throw new Error('Address element is missing');
            }

            const { value: { name, address } } = await addressElement.getValue();

            const { error, token } = await stripe.createToken(cardElement, {
                name,
                ...getCardAddress(address),
            });

            if (error) {
                updateState({ error: error.message, loading: false });
                return;
            }

            const captcha = await executeRecaptcha(RECAPTCHA_ACTION);

            onUpdate({
                name,
                card: token ? token.id : '',
                email: emailInput.value,
                address,
                captcha,
            });


        } catch (/** @type { any } */ err) {
            reportError(err);
            updateState({ error: 'An error occurred.', loading: false });
        }
    }, [elements, emailInput.value, executeRecaptcha, onUpdate, stripe, updateState]);

    const cancelProps = {
        disabled: loading,
        onClick: () => {
            updateState({ captcha: '', loading: false });
            onCancel();
        },
    };

    const updateProps = {
        color: /** @type { 'primary' } */ ('primary'),
        disabled,
        loading,
        onClick,
    };

    /** @type { EmailElementProps } */
    const inputProps = {
        ...emailInput.bind,
        error: !emailIsValid,
        onKeyDown: e => e.key === 'Enter'
            && !disabled
            && onClick(),
        onChange: e => {
            return emailInput.bind.onChange(/** @type { any } */ (e));
        },
    };

    return (
        <>
            <NumberElement spacing="mb-4" onChange={onChange} />

            <Layout width="100" flex>
                <ExpiryElement width="50" spacing="pr-1" onChange={onChange} />
                <CVCElement width="50" spacing="pl-1" onChange={onChange} />
            </Layout>

            <Divider spacing="my-5" />

            <BillingAddressElement
                spacing="mb-4"
                onChange={e => setHasValidAddress(e.complete)}
                name={name}
                address={address}
            />

            <Divider spacing="my-5" />

            <EmailInput spacing="mb-6" {...inputProps} />

            <Layout width="100" alignItems="center" justifyContent="flex-end" flex>
                {state.error ? (
                    <ErrorMessage spacing="m-0 mr-2" align="right" error={state.error} />
                ) : (
                    <CaptchaMessage spacing="m-0 mr-2" align="left" />
                )}
                <Button spacing="m-0 mr-2" {...cancelProps}>Back</Button>
                <Button spacing="m-0" {...updateProps}>Submit</Button>
            </Layout>
        </>
    );
};

const inputStyles = `
    && {
        padding: 11px 10px;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 5px;
        margin-top: 0.375rem;

        :hover {
            border-color: ${colors.interface};
        }

        &.focus {
            border-color: ${colors.primary.main};
        }

        &.invalid {
            border-color: ${colors.error.main};
        }

        @media (min-width: ${breakpoints.xl}){
            padding: 13px 10px 12px;
        }
    }
`;

const StyledCardNumberElement = styled(CardNumberElement)`
    ${inputStyles}
`;

const StyledCardExpiryElement = styled(CardExpiryElement)`
    ${inputStyles}
`;

const StyledCardCvcElement = styled(CardCvcElement)`
    ${inputStyles}
`;

export default PaymentForm;
