import React, { useState } from 'react';

import {
    Dropdown,
    ToggleButton,
    List,
    ListItem,
    Icon,
} from 'ui-components';

/**
 * @typedef { object } DashboardDropdownProps
 * @prop { () => void } toggleRenameDialog
 * @prop { () => void } toggleDeleteDialog
 * @prop { string } [spacing]
 */

/** @type { React.FC<DashboardDropdownProps> } */
const DashboardDropdown = ({
    toggleRenameDialog,
    toggleDeleteDialog,
    spacing,
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(open => !open);
    };

    const rename = () => {
        toggleRenameDialog();
        setDropdownOpen(false);
    };

    const del = () => {
        toggleDeleteDialog();
        setDropdownOpen(false);
    };

    return (
        <Dropdown
            opener={
                <ToggleButton
                    square
                    type="plain"
                    active={dropdownOpen}
                    onClick={toggleDropdown}
                    spacing={spacing}
                >
                    <Icon icon="ellipsis-v" />
                </ToggleButton>
            }
            open={dropdownOpen}
            onClose={toggleDropdown}
            placement="bottom-right"
        >
            <List>
                <ListItem
                    label="Rename"
                    onClick={rename}
                    button
                />
                <ListItem
                    label="Delete"
                    onClick={del}
                    button
                />
            </List>
        </Dropdown>
    );
};

export default DashboardDropdown;
