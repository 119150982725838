import { useCallback } from 'react';
import { useInput } from 'ui-components';

import { useRequest } from '../../lib/ajax';
import isValidEmail from '../../lib/is-valid-email';
import isEmailAllowed from '../../lib/is-email-allowed';

import {
    trackSignupStarted,
} from './tracking';

/** @typedef { import('../../app/__types').PageProps } PageProps */

/**
 * @typedef { object } Props
 * @prop { string } initialEmail
 */

/**
 * @typedef { object } ReturnProps
 * @prop { string } firstName
 * @prop { string } lastName
 * @prop { string } email
 * @prop { boolean } firstNameIsValid
 * @prop { boolean } lastNameIsValid
 * @prop { boolean } emailIsValid
 * @prop { boolean } emailIsAllowed
 * @prop { boolean } loading
 * @prop { boolean } disabled
 * @prop { { message: string, type: string } } error
 * @prop { boolean } signupIsSuccess
 * @prop { boolean } resendIsSuccess
 * @prop { boolean } showLoginWarning
 * @prop { (newFirstName: string) => void } onFirstNameChanged
 * @prop { (newLastName: string) => void } onLastNameChanged
 * @prop { (newEmail: string) => void } onEmailChanged
 * @prop { () => void } onSignupClicked
 * @prop { () => void } onResendClicked
 * @prop { () => void } onEnterPressed
 */

/** @type { (props: Props) => ReturnProps } */
function useSignupPage({ initialEmail }) {
    const firstNameInput = useInput('First name');
    const lastNameInput = useInput('Last name');
    const emailInput = useInput('Work email', initialEmail);

    const firstName = firstNameInput.value;
    const lastName = lastNameInput.value;
    const email = emailInput.value;

    const firstNameIsValid = !!firstName;
    const lastNameIsValid = !!lastName;
    const emailIsValid = isValidEmail(email);
    const emailIsAllowed = isEmailAllowed(email);

    const onFirstNameChanged = firstNameInput.bind.onChange;
    const onLastNameChanged = lastNameInput.bind.onChange;
    const onEmailChanged = emailInput.bind.onChange;

    const { go: signup, ...signupReq } = useRequest('/signups', 'POST', {
        fname: firstName,
        lname: lastName,
        email,
    });

    const { go: resend, ...resendReq } = useRequest(`/signups/${email}/renew`);

    const disabled = signupReq.loading
        || !firstNameIsValid
        || !lastNameIsValid
        || !emailIsValid
        || !emailIsAllowed;

    const signupIsSuccess = !signupReq.error && !!signupReq.data;
    const resendIsSuccess = !resendReq.error && !!resendReq.data;

    const handleResponse = useCallback((res) => {
        if (!res.ok) {
            return;
        }

        trackSignupStarted(email, firstName, lastName);
    }, [email, firstName, lastName]);

    const onSignupClicked = useCallback(() => {
        if (!firstNameIsValid
            || !lastNameIsValid
            || !emailIsValid
            || !emailIsAllowed) {
            return;
        }
        signup().then((res) => {
            if (!res.ok && res.data.type === 'signup.record-exists') {
                signupReq.clear();
                return resend();
            }
            return res;
        }).then(handleResponse);
    }, [
        firstNameIsValid,
        lastNameIsValid,
        emailIsValid,
        emailIsAllowed,
        signup,
        handleResponse,
        signupReq,
        resend,
    ]);

    const onResendClicked = useCallback(() => {
        if (!email) {
            return;
        }
        resend().then(handleResponse);
    }, [email, handleResponse, resend]);

    const onEnterPressed = useCallback((e) => {
        if (disabled || e.key !== 'Enter') {
            return;
        }
        onSignupClicked();
    }, [disabled, onSignupClicked]);

    return {
        firstName,
        lastName,
        email,
        firstNameIsValid,
        lastNameIsValid,
        emailIsValid,
        emailIsAllowed,
        loading: signupReq.loading || resendReq.loading,
        disabled,
        error: signupReq.error,
        signupIsSuccess,
        resendIsSuccess,
        showLoginWarning: signupReq.error && signupReq.error.type === 'signup.user-exists',
        onFirstNameChanged,
        onLastNameChanged,
        onEmailChanged,
        onSignupClicked,
        onResendClicked,
        onEnterPressed,
    };
}

export default useSignupPage;
