import styled from 'styled-components';
import { colors, Layout } from 'ui-components';

const Barcode = styled.div`
    width: 200px;
    height: 200px;
    margin: 0px auto;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SuccessIconCircle = styled.div`
    background-color: ${colors.primary.light};
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ErrorIconCircle = styled.div`
    background-color: ${colors.error.main};
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const WarningIconCircle = styled.div`
    background-color: ${colors.warning.main};
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SettingColumn = styled(Layout)`
    width: 36rem;
`;

const ButtonWrapper = styled.div`
    margin-bottom: auto;
    margin-top: auto;
`;

const InputWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    text-align: center;
`;

const HeaderWrapper = styled.div`
    margin-top : 2px
`;

export {
    Barcode,
    SuccessIconCircle,
    ErrorIconCircle,
    WarningIconCircle,
    SettingColumn,
    ButtonWrapper,
    InputWrapper,
    HeaderWrapper,
};
