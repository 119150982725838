import { useCallback, useState } from 'react';

import { useSession } from '../context-providers';

/** @typedef { ReturnType<typeof useWorkbenchQueryResults> } WorkbenchQueryResults */

function useWorkbenchQueryResults() {
    const [session] = useSession();

    const showSaveAsDialog = session.scopes.includes('reports:manage');

    const [saveAsDialogOpen, setSaveAsDialogOpen] = useState(false);
    const [saveDialogOpen, setSaveDialogOpen] = useState(false);

    const toggleSaveAsDialog = useCallback(() => {
        setSaveAsDialogOpen(open => !open);
        setSaveDialogOpen(false);
    }, []);

    const toggleSaveDialog = useCallback(() => {
        setSaveDialogOpen(open => !open);
        setSaveAsDialogOpen(false);
    }, []);

    return {
        showSaveAsDialog,
        saveAsDialogOpen,
        saveDialogOpen,
        toggleSaveAsDialog,
        toggleSaveDialog,
    };
}

export default useWorkbenchQueryResults;
