import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSession } from '../../shared/context-providers';
import PageLayout from '../../shared/page-layout';

/** @typedef { import('../../app/__types').PageProps } PageProps */

// This "page" exists only to redirect the user to the appropriate homepage for the user.
// This logic should probably be moved to useRouter in the future
/** @type { React.FC<PageProps> } */
const HomePage = () => {
    const navigate = useNavigate();
    const [session] = useSession();

    const { database, nav } = session;

    const redirect = useCallback(() => {
        if (nav.exclude.includes('insights')) {
            navigate('/explore');
            return;
        }

        navigate('/insights');
    }, [database, nav, navigate]);

    useEffect(() => {
        redirect();
    }, [redirect]);

    return (
        <PageLayout flex alignItems="center" justifyContent="center">
        </PageLayout>
    );
};

export default HomePage;
