import { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { breakpoints } from 'ui-components';

import { useRequest } from '../../lib/ajax';
import { useSession, useOnboarding } from '../../shared/context-providers';
import { useFullPageAlert } from '../../shared/full-page-alert';
import useWindowSize from '../../shared/use-window-size';
import { useNavBreadCrumbsState } from './nav-bread-crumbs';

/** @typedef { import('../__types').Session } Session */
/** @typedef { import('../__types').SessionActions } SessionActions */
/** @typedef { import('./__types').Database } Database */
/** @typedef { import('./__types').MenuItem } MenuItem */
/** @typedef { import('./__types').NavBreadCrumbItem } NavBreadCrumbItem */

/** @type { MenuItem[] } */
const allItems = [
    {
        id: 'insights',
        link: '/',
        selected: '/insights',
        title: 'Home',
    },
    {
        id: 'data-sources',
        link: '/sources',
        title: 'Connectors',
    },
    {
        id: 'tables',
        link: '/explore',
        title: 'Tables',
    },
    {
        id: 'analyze',
        link: '/workbench',
        title: 'Workbench',
    },
    {
        id: 'dashboards',
        link: '/dashboards',
        title: 'Dashboards',
    },
    {
        id: 'jobs',
        link: '/jobs',
        title: 'Jobs',
    },
    {
        id: 'queries',
        link: '/queries',
        title: 'Queries',
    },
    {
        id: 'connect',
        link: '/connect',
        title: 'BI Connection',
    },
    {
        id: 'teams',
        link: '/teams',
        title: 'Users',
    },
];

/**
 * @typedef { object } ReturnProps
 *
 * @prop { Session['nav'] } nav
 * @prop { Session['database'] } database
 * @prop { Session['user'] } user
 * @prop { Database[] } databases
 * @prop { Session['billing'] } billing
 * @prop { boolean } navOpen
 * @prop { string } path
 * @prop { MenuItem[] } items
 * @prop { boolean } showTrialMessage
 * @prop { boolean } skipOnboardingAlertOpen
 * @prop { () => void } toggleNavOpen
 * @prop { () => void } toggleSkipOnboardingAlertOpen
 * @prop { NavBreadCrumbItem[] } navBreadCrumbItems
 * @prop { (items: NavBreadCrumbItem[]) => void } setNavBreadCrumbItems
 * @prop { boolean } showBreadCrumbNav
 * @prop { (value: boolean) => void } setShowBreadCrumbNav
 * @prop { boolean } navIsHovered
 * @prop { (value: boolean) => void } setNavIsHovered
 * @prop { boolean } desktop
 * @prop { SessionActions['changeDatabase'] } changeDatabase
 */

/** @type { () => ReturnProps } */
function useAppHeader() {
    const [session, sessionActions] = useSession();
    const [onboarding] = useOnboarding();
    const location = useLocation();

    const {
        open: skipOnboardingAlertOpen,
        toggle: toggleSkipOnboardingAlert,
    } = useFullPageAlert();

    const {
        nav,
        user,
        database,
        billing,
    } = session;

    const windowSize = useWindowSize();
    const [desktop, setDesktop] = useState(true);
    useEffect(() => setDesktop(
        windowSize.width >= parseInt(breakpoints.md),
    ), [windowSize]);

    const [navOpen, setNavOpen] = useState(false);

    const {
        navBreadCrumbItems,
        setNavBreadCrumbItems,
        showBreadCrumbNav,
        setShowBreadCrumbNav,
        navIsHovered,
        setNavIsHovered,
    } = useNavBreadCrumbsState();

    const {
        go: fetchDatabases,
        ...databasesReq
    } = useRequest('/databases');

    const databases = databasesReq.data || [];

    const path = location.pathname;
    const items = allItems.filter(item => !nav.exclude.includes(item.id));

    const showTrialMessage = !nav.exclude.includes('upgrade')
        && !onboarding.active
        && !!billing.onTrial;

    const toggleNavOpen = useCallback(() => {
        setNavOpen(!navOpen);
    }, [navOpen]);

    const toggleSkipOnboardingAlertOpen = useCallback(() => {
        toggleSkipOnboardingAlert();
    }, [toggleSkipOnboardingAlert]);

    useEffect(() => {
        if (databasesReq.data || databasesReq.loading || databasesReq.error) {
            return;
        }
        fetchDatabases();
    }, [
        database,
        databasesReq.data,
        databasesReq.loading,
        databasesReq.error,
        fetchDatabases,
    ]);

    return {
        nav,
        user,
        database,
        databases,
        billing,
        navOpen,
        path,
        items,
        showTrialMessage,
        toggleNavOpen,
        skipOnboardingAlertOpen,
        toggleSkipOnboardingAlertOpen,
        navBreadCrumbItems,
        setNavBreadCrumbItems,
        showBreadCrumbNav,
        setShowBreadCrumbNav,
        navIsHovered,
        setNavIsHovered,
        desktop,
        changeDatabase: sessionActions.changeDatabase,
    };
}

export default useAppHeader;
