import React from 'react';

import {
    Button,
    Callout,
    Divider,
    Input,
} from 'ui-components';

import GoogleButton from '../../shared/auth/google-button';

/**
 * @typedef { object } Props
 * @prop { string } email
 * @prop { string } password
 * @prop { boolean } emailIsValid
 * @prop { boolean } passwordIsValid
 * @prop { boolean } loading
 * @prop { boolean } disabled
 * @prop { { message: string } } error
 * @prop { string } googleError
 * @prop { (newEmail: string) => void } onEmailChanged
 * @prop { (newPassword: string) => void } onPasswordChanged
 * @prop { () => void } onGoogleClicked
 * @prop { () => void } onLoginClicked
 * @prop { () => void } onEnterPressed
 */

/** @type { React.FC<Props> } */
const LoginForm = ({
    email,
    password,
    emailIsValid,
    passwordIsValid,
    loading,
    disabled,
    error,
    googleError,
    onEmailChanged,
    onPasswordChanged,
    onGoogleClicked,
    onLoginClicked,
    onEnterPressed,
}) => {

    const emailProps = {
        label: 'Email',
        id: 'panoply-account-email',
        name: 'panoply-account-email',
        autoComplete: 'email',
        value: email,
        error: !emailIsValid,
        disabled: loading,
        onKeyDown: onEnterPressed,
        onChange: onEmailChanged,
    };

    const passwordProps = {
        label: 'Password',
        id: 'panoply-account-password',
        name: 'panoply-account-password',
        autoComplete: 'current-password',
        value: password,
        error: !passwordIsValid,
        disabled: loading,
        type: 'password',
        onKeyDown: onEnterPressed,
        onChange: onPasswordChanged,
    };

    const buttonProps = {
        color: 'primary',
        onClick: onLoginClicked,
        loading,
        disabled,
    };

    return (
        <>
            <GoogleButton onClick={onGoogleClicked}>
                Use Google Account
            </GoogleButton>

            {googleError && (
                <Callout color="error" spacing="mt-4">
                    {googleError}
                </Callout>
            )}

            <Divider label="or" spacing="my-6 my-md-7" />

            {error && (
                <Callout color="error" spacing="mb-5">
                    {error.message}
                </Callout>
            )}

            <Input width="100" spacing="p-0 mb-5" noFloatLabel {...emailProps} />
            <Input width="100" spacing="p-0 mb-5" noFloatLabel {...passwordProps} />

            <Button width="100" spacing="m-0 mb-5" {...buttonProps}>
                Log In
            </Button>
        </>
    );
};

export default LoginForm;
