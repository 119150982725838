import React, { useState } from 'react';

import {
    Dropdown,
    ToggleButton,
    List,
    ListItem,
    Icon,
    Typography,
    Layout,
} from 'ui-components';

/** @typedef { import('lib/query-builder').JoinType } JoinType */

/** @type { Record<JoinType, { label: string }> } */
const types = {
    inner: {
        label: 'INNER JOIN',
    },
    left: {
        label: 'LEFT JOIN',
    },
    right: {
        label: 'RIGHT JOIN',
    },
    full: {
        label: 'FULL JOIN',
    },
};

/**
 * @typedef { object } JoinTypeSelectorDropdownProps
 * @prop { JoinType } type
 * @prop { (type: JoinType) => void } changeType
 * @prop { string } [spacing]
 */

/** @type { React.FC<JoinTypeSelectorDropdownProps> } */
const JoinTypeSelectorDropdown = ({
    type,
    changeType,
    spacing,
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(open => !open);
    };

    /** @type { (newType: JoinType) => void } */
    const updateType = (newType) => {
        setDropdownOpen(false);
        changeType(newType);
    };

    return (
        <Dropdown
            opener={
                <ToggleButton
                    type="plain"
                    color="default"
                    active={dropdownOpen}
                    onClick={toggleDropdown}
                    spacing={spacing}
                >
                    <Layout
                        flex
                        alignItems="center"
                        justifyContent="space-between"
                        width="100"
                    >
                        <Typography
                            inline
                            variant="body1"
                            color="text"
                            weight="normal"
                            spacing="mb-0"
                            noWrap
                        >
                            {types[type].label}
                        </Typography>

                        <Icon
                            prefix="fas"
                            icon="caret-down"
                            color="interface"
                            spacing="ml-1"
                        />
                    </Layout>
                </ToggleButton>
            }
            open={dropdownOpen}
            onClose={toggleDropdown}
        >
            <List>
                {Object.entries(types).map(([key, { label }]) => (
                    <ListItem
                        key={key}
                        label={label}
                        selected={key === type}
                        disabled={key === type}
                        onClick={() => updateType(/** @type { JoinType } */(key))}
                        button
                    />
                ))}
            </List>
        </Dropdown>
    );
};

export default JoinTypeSelectorDropdown;
