import React, { useState, useCallback, useEffect } from 'react';

import {
    Button,
    Dialog,
    Layout,
    Typography,
    Switch,
} from 'ui-components';

import { reportError } from '../../lib/error-reporter';

/** @typedef { import('lib/ajax').AjaxError } AjaxError */
/** @typedef { import('./__types').ReportItem } ReportItem */

/**
 * @typedef { object } Props
 * @prop { boolean } open
 * @prop { () => void } onClose
 * @prop { ReportItem= } reportToDelete
 * @prop { (report: ReportItem) => Promise<void> } deleteReport
 */

/** @type { React.FC<Props> } */
const DeleteReportDialog = ({
    open,
    onClose,
    reportToDelete,
    deleteReport,
}) => {
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(
        /** @type { AjaxError= } */ (undefined)
    );

    const [didConfirm, setDidConfirm] = useState(false);

    const onDeleteClicked = useCallback(async () => {
        if (!reportToDelete?.id) {
            return;
        }
        setLoading(true);
        try {
            await deleteReport(reportToDelete);
            onClose();
        } catch (/** @type { any } */ err) {
            reportError(err, { objectId: reportToDelete.id });
            setError(err);
        }
        setLoading(false);
    }, [reportToDelete, deleteReport, onClose]);

    /** @type { (e:  React.ChangeEvent<HTMLInputElement>) => void } */
    const onChangeConfirm = useCallback((e) => {
        setDidConfirm(e.target.checked);
    }, []);

    useEffect(() => {
        if (open) {
            return;
        }
        setError(undefined);
        setLoading(false);
        setDidConfirm(false);
    }, [open]);

    useEffect(() => {
        /** @type { (e: KeyboardEvent) => void } */
        const onKeyDown = e => {
            if (e.key === 'Escape' && !loading) {
                e.preventDefault();
                if (open) {
                    onClose();
                }
            } else if (e.key === 'Enter' && !loading && didConfirm) {
                e.preventDefault();
                if (open) {
                    onDeleteClicked();
                }
            }
        };

        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    }, [loading, didConfirm, open, onClose, onDeleteClicked]);

    if (!reportToDelete?.id) {
        return null;
    }

    return (
        <Dialog
            title="Delete report"
            isOpen={open}
            onClose={onClose}
            actions={[
                <Button
                    key="cancel"
                    onClick={onClose}
                    disabled={loading}
                >
                    Cancel
                </Button>,
                <Button
                    key="delete"
                    color="primary"
                    spacing="mr-0"
                    onClick={onDeleteClicked}
                    disabled={!didConfirm}
                    loading={loading}
                >
                    Delete
                </Button>,
            ]}
        >
            <Layout spacing="mt-0">
                <Typography variant="subtitle1" weight="medium">
                    Are you sure you want to delete report {reportToDelete.name}?
                </Typography>

                <Layout spacing="mt-0">
                    <Switch
                        type="checkbox"
                        checked={didConfirm}
                        label="Yes, permanently remove this report"
                        onChange={onChangeConfirm}
                        spacing="mt-3"
                    />

                    {didConfirm && (
                        <Typography color="error" variant="body1" spacing="mt-2">
                            Warning: This action cannot be undone.
                        </Typography>
                    )}
                </Layout>

            </Layout>

            {error && (
                <Layout spacing="mt-4">
                    <Typography variant="subtitle1" color="error">
                        Oops! an error was encountered when deleting the item.
                    </Typography>

                    <Typography variant="body1" color="error">
                        {(error.data && error.data.message) || error.status}
                    </Typography>
                </Layout>
            )}
        </Dialog>
    );
};

export default DeleteReportDialog;
