import React from 'react';
import styled from 'styled-components';
import { Layout, Typography, colors } from 'ui-components';

import PageHelmet from './page-helmet';

/** @typedef { React.ComponentProps<Layout> } LayoutProps */

/**
 * @typedef { object } Props
 * @prop { React.ReactNode } title
 * @prop { React.ReactNode= } children
 * @prop { React.ReactNode= } titleAdornment
 * @prop { LayoutProps= } titleLayoutProps
 * @prop { boolean= } noHelmet
 * @prop { boolean= } sticky
 */

/** @type { React.FC<Props> } */
const PageHeader = ({
    title,
    children,
    noHelmet,
    titleAdornment,
    titleLayoutProps,
    sticky,
}) => (
    <Sticky
        id="page-header"
        width="100"
        flex
        justifyContent="space-between"
        alignItems="center"
        spacing="mt-1 mb-4+3 py-1"
        sticky={sticky}
    >
        {typeof title === 'string' && !noHelmet && (
            <PageHelmet title={title} />
        )}

        <Layout
            id="page-title"
            flex
            alignItems="center"
            justifyContent="flex-start"
            overflow="hidden"
            {...titleLayoutProps}
        >
            <Typography variant="h5" spacing="my-0" noWrap>
                <Title>{title}</Title>
            </Typography>
            {titleAdornment}
        </Layout>

        <ActionsContainer
            flex
            className="actions"
            alignItems="center"
            justifyContent="flex-end"
            grow="1"
        >
            {children}
        </ActionsContainer>
    </Sticky>
);

const Sticky = styled(Layout)`
    ${({ sticky }) => sticky && `
        position: sticky;
        top: -1px;
        margin-left: -1px;
        margin-right: -1px;
        padding-left: 1px;
        padding-right: 1px;
        width: calc(100% + 2px);
        z-index: 1;
        background-color: ${colors.background};
    `}
`;

const Title = styled('span')`
    white-space: pre;
`;

const ActionsContainer = styled(Layout)`
    min-height: 60px;
`;

export default PageHeader;
